import { MdSearch } from 'react-icons/md';
import Checkbox from '../../common/Checkbox';
import RadioButton from '../../common/RadioButton';
import Close from '../../icons/Close';
import { AttributeValue } from '../../types';

interface SegmentValuesProps {
    values: AttributeValue[];
    onChange: (value: string) => void;
    useRadio?: boolean;
    onSelectAll: (selectAll: boolean) => void;
    search: string;
    onSearch: (searchText: string) => void;
}

export default function SegmentValues({
    values,
    onChange,
    onSelectAll,
    useRadio,
    search,
    onSearch
}: SegmentValuesProps) {
    let listHeight = window.screen.availHeight * 0.75 - 160;
    const maxRowCount = Math.floor(listHeight / 28);
    listHeight = maxRowCount * 28;
    const showSearch = values.length + 1 > maxRowCount;
    let filteredValues = values;
    if (search) {
        filteredValues = values.filter(v =>
            v.text.toLowerCase().includes(search.toLowerCase())
        );
    }
    const checkAll = filteredValues.every(v => v.checked);

    return (
        <div className="filter-dropdown__list">
            {showSearch && (
                <div className="filter-dropdown__search">
                    <MdSearch size={24} color="rgba(242, 242, 242, 0.5)" />
                    <input
                        className="large-text"
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={e => onSearch(e.target.value)}
                    />
                    {search && (
                        <Close
                            width={24}
                            className="btn-icon"
                            onClick={() => onSearch('')}
                        />
                    )}
                </div>
            )}
            {search && filteredValues.length === 0 && (
                <div className="filter-dropdown__empty-message">
                    There are no segments for the search
                </div>
            )}
            <ul style={{ maxHeight: listHeight }}>
                {!useRadio && filteredValues.length > 1 && (
                    <li>
                        <Checkbox
                            checked={checkAll}
                            onClick={(checked: boolean) => onSelectAll(checked)}
                            testId="all"
                        >
                            All
                        </Checkbox>
                    </li>
                )}
                {filteredValues.map(v => (
                    <li key={v.value}>
                        {useRadio ? (
                            <RadioButton
                                checked={v.checked}
                                onClick={() => onChange(v.value)}
                            >
                                {v.text}
                            </RadioButton>
                        ) : (
                            <Checkbox
                                testId={v.value}
                                checked={v.checked}
                                onClick={() => onChange(v.value)}
                            >
                                {v.text}
                            </Checkbox>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

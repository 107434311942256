import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={18}
            height={20}
            viewBox="0 0 18 20"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path d="M15.6665 2.4987H14.8332V0.832031H13.1665V2.4987H4.83317V0.832031H3.1665V2.4987H2.33317C1.4165 2.4987 0.666504 3.2487 0.666504 4.16536V17.4987C0.666504 18.4154 1.4165 19.1654 2.33317 19.1654H15.6665C16.5832 19.1654 17.3332 18.4154 17.3332 17.4987V4.16536C17.3332 3.2487 16.5832 2.4987 15.6665 2.4987ZM15.6665 17.4987H2.33317V8.33203H15.6665V17.4987ZM15.6665 6.66536H2.33317V4.16536H15.6665V6.66536Z" />
        </svg>
    );
}

export default SvgComponent;

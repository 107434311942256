import React, { useState, useEffect, useRef, useContext } from 'react';
import { scaleLinear } from 'd3-scale';
import { line, curveMonotoneX } from 'd3-shape';
import { min, max } from 'd3-array';
import sortBy from 'lodash/sortBy';
import Constants from '../../constants/Constants';
import getPeriod from '../../lib/getPeriod';
import getFormattedValue from '../../lib/getFormattedValue';
import AnalysisTypes from '../../constants/AnalysisTypes';
import FontFamilies from '../../constants/FontFamilies';
import measureText from '../../lib/measureText';
import CanvasFonts from '../../constants/CanvasFonts';
import produce from 'immer';
import inlineFont from '../../constants/InlineFont';
import { track } from '../../lib/segment';
import MixPanel from '../../constants/MixPanel';
import precisionRound from '../../lib/precisionRound';
import { ChartContext } from '../../contexts/ChartContext';
import { useAppSelector } from '../../store/hooks';
import { setCurrentIndex } from '../../measure/goal/reducer';
import Lines from './Lines';
import Points from './Points';
import Values from './Values';
import GoalLine from './GoalLine';
import LinearRegressionBackground from './LinearRegressionBackground';
import IndexBackground from './IndexBackground';
import CurrentIndexLabel from './CurrentIndexLabel';
import GoalValues from './GoalValues';

const AXIS_PADDING = 18;
const PADDING = 16;
const RADIUS = 4;
const ARROW_WIDTH = 8;
const ARROW_HEIGHT = 6;
const XAXIS_FONT_SIZE = 11;
const MIN_VALUE_SPACE = 17;

function getFormattedDate(
    date: Date,
    interval: string,
    showCondensed?: boolean
) {
    return getPeriod(date, interval.toLowerCase(), showCondensed);
}

interface ILineChartProps {
    children?: React.ReactNode;
    dashboardName?: string;
    reportId: string;
    title?: string;
    selectCurrentTime: any;
}

interface IContext extends ILineChartProps {
    avgValue: number;
    isFaded: any;
    lineFn: any;
    valueArray: any[];
    xScale: any;
    yScale: any;
    getFormattedDate: any;
    RADIUS: number;
    PADDING: number;
    handleMouseOverOnPoint: any;
    handleMouseOutOnPoint: any;
    setCurrentIndex: any;
    valueWidth: number;
    yAxisValues: any[];
}

export const LineChartContext = React.createContext<IContext>({} as IContext);

export default function LineChart(props: ILineChartProps) {
    const ref = useRef(null);
    const {
        onToggle,
        status,
        chartState: { chartWidth, chartHeight, mode },
        chartDispatch
    } = useContext(ChartContext);

    const {
        chartData,
        currentIndex,
        analysisType,
        interval,
        dates,
        hasInvalidValue,
        dataProp,
        goal
    } = useAppSelector(state => {
        switch (mode) {
            case 'measure': {
                return state.measure.goal;
            }
            default:
                return {} as any;
        }
    });

    const baseClass = 'linechartv2';

    const { reportId, dashboardName, title, selectCurrentTime } = props;

    let data = chartData;

    const [valueWidth, setValueWidth] = useState(0);
    const [hoverIndex, setHoverIndex] = useState<number>();
    const [hoverLabel, setHoverLabel] = useState<string>();

    const data2 = data.filter(
        (d: any) => !d.isEqualityIndex && !d.isAttritionIndex
    );
    const singleSegment = data2.length === 1;
    const isActive = status !== 'idle';
    const showIndexLine =
        isActive &&
        (analysisType === AnalysisTypes.LinearRegression ||
            analysisType === AnalysisTypes.Index);

    function isFaded(item: any) {
        const isActive = status !== 'idle';
        if (isActive) {
            return !(
                item.active ||
                item.selected ||
                item.isEqualityIndex ||
                item.isAttritionIndex
            );
        }
        return false;
    }

    function handleMouseOverOnPoint(label: string, index: number) {
        setHoverIndex(index);
        setHoverLabel(label);
    }

    function handleMouseOutOnPoint() {
        setHoverIndex(undefined);
        setHoverLabel(undefined);
    }

    function getYAxisValuesV2(minValue: number, maxValue: number): number[] {
        let deviation = 0.1;

        if (analysisType === AnalysisTypes.LinearRegression) {
            const minMax = Math.max(
                Math.max(Math.abs(minValue), Math.abs(maxValue)),
                8
            );
            const offset = minMax * deviation;
            return [-minMax - offset, minMax + offset];
        }

        deviation = maxValue - minValue;
        if (deviation === 0) {
            if (minValue === 0) {
                deviation = 0.1;
            } else {
                deviation = minValue * 0.1;
            }
        } else {
            deviation = deviation * 0.3;
        }

        let adjMinValue = Math.max(minValue - deviation, 0);
        let adjMaxValue = maxValue + deviation;

        if (analysisType === AnalysisTypes.Percentage) {
            adjMaxValue = Math.min(adjMaxValue, 100);
        }

        if (analysisType === AnalysisTypes.Index) {
            adjMinValue = 0;
            adjMaxValue = Math.max(adjMaxValue, 2);
        }

        return [adjMinValue, adjMaxValue];
    }

    if (hasInvalidValue) {
        data = produce(data, (draftState: any) => {
            draftState.forEach((ds: any) => {
                for (let i = ds.series.length - 1; i >= 0; i--) {
                    if (ds.series[i][dataProp] === Constants.InvalidValue) {
                        ds.series.splice(i, 1);
                    }
                }
            });
        });
    }

    const minArray = [];
    const maxArray = [];
    data.forEach((d: any) => {
        minArray.push(
            min(d.series, (s: any) => precisionRound(s[dataProp], 1))
        );
        maxArray.push(
            max(d.series, (s: any) => precisionRound(s[dataProp], 1))
        );
    });
    minArray.push(
        precisionRound(goal.metricTrajectoryValues[0].desiredValue, 1)
    );
    maxArray.push(
        precisionRound(goal.metricTrajectoryValues[0].desiredValue, 1)
    );

    const minValue = min(minArray) as number;
    const maxValue = max(maxArray) as number;
    const yAxisValues = getYAxisValuesV2(minValue, maxValue);

    const xScale = scaleLinear()
        .domain([dates[0], dates[dates.length - 1]])
        .range([PADDING, chartWidth - PADDING - valueWidth]);

    const yScale = scaleLinear()
        .domain(yAxisValues)
        .range([chartHeight - 2 * PADDING, 2 * PADDING]);

    const bandwidth =
        (chartWidth - 2 * PADDING - valueWidth) / (dates.length - 1);
    let intervalLength =
        measureText(getFormattedDate(dates[0], interval), CanvasFonts.Medium11)
            .width *
            1.5 +
        PADDING;
    const showCondensed = intervalLength > bandwidth;
    let skipCount = 0;
    let indexes = dates.map((_: any, i: number) => i);
    if (showCondensed) {
        intervalLength =
            measureText(
                getFormattedDate(dates[0], interval),
                CanvasFonts.Medium11
            ).width *
                1.5 +
            PADDING;
        if (intervalLength > bandwidth) {
            let intervalWidth = bandwidth;
            do {
                skipCount++;
                intervalWidth = bandwidth * (skipCount + 1);
            } while (intervalLength > intervalWidth);
            if (skipCount > 0) {
                indexes = [];
                for (let i = dates.length - 1; i >= 0; i -= skipCount + 1) {
                    if (currentIndex > i && currentIndex < i + skipCount + 1) {
                        indexes.unshift(currentIndex);
                    }
                    indexes.unshift(i);
                }
            }
        }
    }

    const avgValue = (minValue + maxValue) / 2;

    const lineFn = line()
        .x((d: any) => xScale(d.date))
        .y(d => yScale(precisionRound(d[dataProp], 1)))
        .curve(curveMonotoneX);

    let valueArray: any[] = [];
    data2.forEach((d: any) => {
        const { color, active, selected, series } = d;
        const seriesItem = series[currentIndex];
        if (seriesItem) {
            const value = seriesItem[dataProp];
            const { date } = seriesItem;
            const valueText = getFormattedValue(analysisType, value);
            const valueY = yScale(precisionRound(value, 1)) + 5;
            valueArray.push({
                label: d.label,
                valueText,
                valueY,
                date,
                color,
                active,
                selected
            });
        }
    });

    valueArray = sortBy(valueArray, va => va.valueY);

    let setBackward = false;
    for (let i = 1; i < valueArray.length; i++) {
        const { valueY } = valueArray[i];
        const { valueY: prevValueY } = valueArray[i - 1];
        if (valueY - prevValueY < MIN_VALUE_SPACE) {
            valueArray[i].valueY = prevValueY + MIN_VALUE_SPACE;
            if (
                valueArray[i].valueY >
                chartHeight - 2 * PADDING - AXIS_PADDING
            ) {
                setBackward = true;
                break;
            }
        }
    }
    if (setBackward) {
        for (let i = valueArray.length - 2; i >= 0; i--) {
            const { valueY } = valueArray[i];
            const { valueY: prevValueY } = valueArray[i + 1];
            if (prevValueY - valueY < MIN_VALUE_SPACE) {
                valueArray[i].valueY = prevValueY - MIN_VALUE_SPACE;
            }
        }
    }

    useEffect(() => {
        let maxValue = '';
        data.forEach((d: any) => {
            const seriesItem = d.series[dates.length - 1];
            if (seriesItem) {
                const value = getFormattedValue(
                    analysisType,
                    seriesItem[dataProp]
                );
                if (value.length > maxValue.length) {
                    maxValue = value;
                }
            }
        });
        const maxTextSize = measureText(
            maxValue,
            singleSegment ? CanvasFonts.Medium24 : CanvasFonts.Medium13
        );
        setValueWidth(40);
    }, [data]);

    useEffect(() => {
        chartDispatch({
            type: 'setInnerChartOffsets',
            payload: {
                left: 0,
                right: chartWidth - xScale(dates[dates.length - 1])
            }
        });
    }, [chartWidth, dates, currentIndex, valueWidth]);

    return (
        <LineChartContext.Provider
            value={{
                ...props,
                avgValue,
                isFaded,
                lineFn,
                valueArray,
                xScale,
                yScale,
                getFormattedDate,
                RADIUS,
                PADDING,
                handleMouseOverOnPoint,
                handleMouseOutOnPoint,
                setCurrentIndex,
                valueWidth,
                yAxisValues
            }}
        >
            <div
                className="linechart-wrapper"
                style={{
                    width: chartWidth,
                    height: chartHeight
                }}
            >
                <svg
                    className={baseClass}
                    width="100%"
                    height="100%"
                    id={reportId}
                    ref={ref}
                >
                    <defs>
                        <style type="text/css">{inlineFont}</style>
                    </defs>
                    <clipPath id={`${reportId}_main`}>
                        <rect
                            x={0}
                            y={0}
                            width={xScale(
                                chartData[0].series[currentIndex].date
                            )}
                            height={chartHeight}
                        />
                    </clipPath>
                    <clipPath id={`${reportId}_fade`}>
                        <rect
                            x={xScale(chartData[0].series[currentIndex].date)}
                            y={0}
                            width={
                                chartWidth -
                                xScale(chartData[0].series[currentIndex].date)
                            }
                            height={chartHeight}
                        />
                    </clipPath>

                    {analysisType === AnalysisTypes.Index && (
                        <rect
                            className="linechart__index-background"
                            x={0}
                            y={yScale(1)}
                            width={chartWidth}
                            height={chartHeight - yScale(1)}
                        />
                    )}

                    {props.children}
                </svg>
            </div>
        </LineChartContext.Provider>
    );
}

LineChart.Lines = Lines;
LineChart.Points = Points;
LineChart.Values = Values;
LineChart.GoalLine = GoalLine;
LineChart.LinearRegressionBackground = LinearRegressionBackground;
LineChart.CurrentIndexLabel = CurrentIndexLabel;
LineChart.GoalValues = GoalValues;
LineChart.IndexBackground = IndexBackground;

LineChart.defaultProps = {
    selectCurrentTime: () => {}
};

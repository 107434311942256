import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={50}
        height={51}
        viewBox="0 0 50 51"
        fill="none"
        {...props}
    >
        <path
            fill="var(--color-shade-h3)"
            d="M1 5.043a4 4 0 0 1 4-4h40a4 4 0 0 1 4 4v21.043H1V5.043Z"
        />
        <rect
            width={49}
            height={49}
            x={0.5}
            y={0.586}
            stroke="var(--color-graph-6)"
            strokeWidth={1}
            rx={4.5}
        />
        <path
            fill="var(--color-shade-h3)"
            d="M1 26.087h48v18.956a4 4 0 0 1-4 4H5a4 4 0 0 1-4-4V26.087Z"
            strokeWidth={1}
        />
        <rect
            width={22}
            height={25}
            x={37.5}
            y={14.5}
            stroke="var(--color-graph-6)"
            strokeLinejoin="round"
            rx={1.5}
            transform="rotate(90 37.5 14.5)"
        />
        <path stroke="var(--color-graph-6)" d="M26.5 15v21M17.5 15v21" />
    </svg>
);
export default SvgComponent;

import React from 'react';
import Close from '../icons/Close';
import Modal from 'antd/lib/modal';

interface InfoModalProps {
    isOpen: boolean;
    onClose?: () => void;
}

const InfoModal = ({ isOpen, onClose }: InfoModalProps) => {
    return (
        <Modal
            open={isOpen}
            width={600}
            bodyStyle={{ padding: 0 }}
            zIndex={3000}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            onCancel={onClose}
        >
            <div className="info-modal">
                <div className="info-modal__inner">
                    <div className="info-modal__header">
                        <div className="info-modal__title">Timeline</div>
                        <div>
                            <Close
                                width={24}
                                height={24}
                                className="btn-icon"
                                onClick={onClose}
                            />
                        </div>
                    </div>
                    <div
                        className="info-modal__content"
                        style={{ marginTop: 0 }}
                    >
                        <div className="info-modal__content-col">
                            <div>
                                <p>
                                    Timeline enables you to connect specific
                                    events to metrics in Dandi. By overlaying
                                    events on your time-based reports, you can
                                    measure the impact of them on your goals and
                                    initiatives.
                                </p>
                                <p>
                                    An event can have a single date or a date
                                    range. Here are some examples:
                                </p>
                                <ul>
                                    <li>Chief Diversity Officer hired</li>
                                    <li>Revamped recruiting process</li>
                                    <li>ERG launched</li>
                                    <li>Annual compensation adjustment</li>
                                </ul>
                                <p>
                                    In Explore, you can overlay these events on
                                    relevant reports. Simply click on the three
                                    dots at the top right of a generated
                                    line-graph report and choose Events - then
                                    select the events you’d like to include.
                                    These reports can be added to your
                                    dashboards as well.
                                </p>
                                <p>
                                    All admins and editors have access to the
                                    Timeline and the ability to manage events.
                                    Viewers do not have access to the Timeline
                                    or event management; however, they can see
                                    the events overlay on any reports within a
                                    dashboard that has been shared with them.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="cover-bar" />
                </div>
            </div>
        </Modal>
    );
};

export default InfoModal;

import React, { useRef, useEffect, useState } from 'react';
import StatsChartTypes from '../constants/StatsChartTypes';
import PropTypes from 'prop-types';
import formatValue from '../lib/formatValue';

export default function StatsChart(props: any) {
    const { reportId, data, componentType, minColumnWidth, rowHeight } = props;
    const ref = useRef<HTMLDivElement>(null);
    const columnCount = Math.min(data.length, 4);
    const cellHeight = data.length > 4 ? rowHeight / 2 : rowHeight;

    function calculateFontSize() {
        if (!ref.current) return;

        const stats = ref.current.querySelectorAll('.js-stat');
        const values =
            ref.current.querySelectorAll<HTMLDivElement>('.js-value');

        if (!stats.length && !values.length) return;

        let i = 16;
        let overflow = false;
        const maxSize = 180;

        const isOverflowing = (el: any) => {
            const { clientWidth, scrollWidth } = el;
            return (
                clientWidth < scrollWidth ||
                el.querySelector('.js-value').clientHeight >
                    el.clientHeight / 2.2
            );
        };

        const longestStat = Array.from(stats).reduce((acc, curr) => {
            return (acc.querySelector('.js-value')?.clientWidth || 0) >
                (curr.querySelector('.js-value')?.clientWidth || 0)
                ? acc
                : curr;
        });

        while (!overflow && i < maxSize) {
            const valEl =
                longestStat.querySelector<HTMLDivElement>('.js-value');
            if (valEl) {
                valEl.style.fontSize = `${i}px`;
            }
            overflow = isOverflowing(longestStat);
            if (!overflow) i += 8;
        }

        values.forEach(value => {
            value.style.fontSize = `${Math.max(16, i - 8)}px`;
        });
    }

    useEffect(() => {
        if (ref.current && data.length) {
            calculateFontSize();

            const observer = new ResizeObserver(() => {
                calculateFontSize();
            });

            observer.observe(ref.current);

            return () => {
                observer.disconnect();
            };
        }
    }, [data]);

    return (
        <div
            className="stats-chart"
            data-component-type={componentType}
            id={reportId}
            ref={ref}
            style={{
                '--columns': columnCount,
                '--minColumnWidth': minColumnWidth ? `${minColumnWidth}px` : '',
                '--cellHeight': `${cellHeight}px`
            }}
        >
            {data.map((item: any, index: number) => (
                <div
                    className="stats-chart__stat js-stat"
                    key={index}
                    data-vertical-divider={index > 0 && index !== 4}
                    data-horizontal-divider={data.length > 4 && index < 4}
                    style={{
                        '--accentColor':
                            componentType === StatsChartTypes.SEGMENTS &&
                            item.color,
                        '--vertical-divider-top': index > 3 ? '0px' : '1.6rem',
                        '--vertical-divider-bottom':
                            data.length < 4
                                ? '1.6rem'
                                : index < 4
                                ? '0px'
                                : '1.6rem'
                    }}
                >
                    <div className="stats-chart__inner">
                        <div
                            className="stats-chart__stat__title"
                            data-is-segment={
                                componentType === StatsChartTypes.SEGMENTS
                            }
                        >
                            {item.label}
                        </div>
                        <div className="stats-chart__value js-value">
                            {formatValue({
                                value: item.pointInTime?.subAnalyses[0].value,
                                unit: item.pointInTime?.subAnalyses[0].unit
                            })}
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
}

StatsChart.defaultProps = {
    componentType: StatsChartTypes.SEGMENTS,
    rowHeight: 312
};

const urls =
    process.env.REACT_APP_ENV !== 'prod'
        ? {
              ReportsApi: 'https://reports.dandi.tech/v2/',
              CommentApi: 'https://comments.dandi.tech/',
              AccountApi: 'https://accounts.dandi.tech/',
              NotificationsApi: 'https://notifications.dandi.tech/',
              DashboardsApi: 'https://dashboards.dandi.tech/',
              OnboardingApi: 'https://onboarding.dandi.tech/',
              CollectApi: 'https://collect.dandi.tech/',
              HomeApi: 'https://home.dandi.tech/',
              ExploreApi: 'https://explore.dandi.tech/',
              EmployeesApi: 'https://employees.dandi.tech/',
              DiscoverApi: 'https://discover.dandi.tech/',
              PinApi: 'https://pins.dandi.tech/',
              BillingApi: 'https://payments.dandi.tech/',
              BenchmarkApi: 'https://benchmark.dandi.tech/',
              DataStudioApi: 'https://datastudio.dandi.tech/',
              EventsApi: 'https://events.dandi.tech/',
              GoalsApi: 'https://goals.dandi.tech/',
              RbacApi: 'https://rbac.dandi.tech/',
              SurveyStudioApi: 'https://content.dandi.tech/',
              LoggedOutApp: 'https://campaign.dandi.tech/',
              SurveyMetricsApi: 'https://metrics.dandi.tech/',
              AiInsightsApi: 'https://ai.dandi.tech/'
          }
        : {
              ReportsApi: 'https://reports.itsdandi.com/v2/',
              CommentApi: 'https://comments.itsdandi.com/',
              AccountApi: 'https://accounts.itsdandi.com/',
              NotificationsApi: 'https://notifications.itsdandi.com/',
              DashboardsApi: 'https://dashboards.itsdandi.com/',
              OnboardingApi: 'https://onboarding.itsdandi.com/',
              CollectApi: 'https://collect.itsdandi.com/',
              HomeApi: 'https://home.itsdandi.com/',
              ExploreApi: 'https://explore.itsdandi.com/',
              EmployeesApi: 'https://employees.itsdandi.com/',
              DiscoverApi: 'https://discover.itsdandi.com/',
              PinApi: 'https://pins.itsdandi.com/',
              BillingApi: 'https://payments.itsdandi.com/',
              BenchmarkApi: 'https://benchmark.itsdandi.com/',
              DataStudioApi: 'https://datastudio.itsdandi.com/',
              EventsApi: 'https://events.itsdandi.com/',
              GoalsApi: 'https://goals.itsdandi.com/',
              RbacApi: 'https://rbac.itsdandi.com/',
              SurveyStudioApi: 'https://content.itsdandi.com/',
              LoggedOutApp: 'https://campaign.itsdandi.com/',
              SurveyMetricsApi: 'https://metrics.itsdandi.com/',
              AiInsightsApi: 'https://ai.itsdandi.com/'
          };

export default urls;

import Modal from 'antd/lib/modal';

import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import MixPanel from '../../constants/MixPanel';
import {
    applyFilterPermanently,
    closeApplyFilterModal
} from '../../dashboards/actions';
import { track } from '../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

type ApplyFilterModalProps = {
    dashboardId: string;
};

const ApplyFilterModal = ({ dashboardId }: ApplyFilterModalProps) => {
    const dispatch = useAppDispatch();
    const { applyFilterDashboardId, showApplyFilter } = useAppSelector(
        state => state.dashboards
    );
    const dashboard = useAppSelector(
        state => state.dashboards[applyFilterDashboardId]
    );

    const { reports } =
        useAppSelector(state =>
            state.dashboard.filter.filters.find(
                filter => filter.dashboardId === dashboardId
            )
        ) || {};

    function handleCancel() {
        track(MixPanel.Events.DashboardApplyFilterModalCancelClick, {
            'Dashboard Name': dashboard.name
        });
        dispatch(closeApplyFilterModal());
    }

    function handleSave() {
        track(MixPanel.Events.DashboardApplyFilterModalSaveClick, {
            'Dashboard Name': dashboard.name
        });
        if (reports) dispatch(applyFilterPermanently(dashboard, reports));
        dispatch(closeApplyFilterModal());
    }

    return (
        <Modal
            open={showApplyFilter}
            width={450}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={handleCancel}
            zIndex={3000}
            destroyOnClose
        >
            <div className="modal">
                <form onSubmit={handleSave}>
                    <div className="modal__header" style={{ marginBottom: 40 }}>
                        <div className="modal__title">
                            Apply filter permanently
                        </div>
                    </div>
                    <div className="modal__content">
                        <div className="modal__desc">
                            <p>
                                Click Update Dashboard to save this dashboard
                                with the filter applied.
                            </p>
                            <p>
                                IMPORTANT: This action will update the dashboard
                                for all users and cannot be undone.
                            </p>
                        </div>
                    </div>
                    <div className="modal__footer">
                        <div className="modal__buttons">
                            <Button
                                componentType={ButtonTypes.type.TERTIARY}
                                onClick={handleCancel}
                            >
                                Cancel
                            </Button>
                            <Button onClick={handleSave}>
                                Update Dashboard
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default ApplyFilterModal;

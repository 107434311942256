import { DynamicPermission } from '../admin/permissions/model';

export default function hasHiddenEmployeeCount(
    dynamicPermissions: DynamicPermission[]
) {
    return dynamicPermissions.some(
        dp =>
            dp.mask && dp.mask.length > 0 && dp.mask.some(m => !!m && m !== '')
    );
}

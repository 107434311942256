import { connect } from 'react-redux';

import Button from '../common/Button';
import Info from '../icons/Info';
import WithPermissions from '../common/WithPermissions';
import RbacActions from '../constants/RbacActions';
import ButtonTypes from '../constants/ButtonTypes';
import Stack from '../common/Stack';
import HeaderWrapper from '../common/HeaderWrapper';
import { showAddEventModal, setInfoModalOpen } from './actions';

interface TimelineHeaderProps {
    showAddEventModal: () => void;
    setInfoModalOpen: typeof setInfoModalOpen;
}

function TimelineHeader({
    showAddEventModal,
    setInfoModalOpen
}: TimelineHeaderProps) {
    const handleAddEvent = () => {
        showAddEventModal();
    };

    return (
        <WithPermissions actions={[RbacActions['Events/View']]}>
            <HeaderWrapper>
                <span className="font-title">Timeline</span>
                <Stack flexDirection="row" gap=".8rem">
                    <Button
                        componentType={ButtonTypes.type.SECONDARY}
                        onClick={() => setInfoModalOpen(true)}
                    >
                        <Info
                            className="btn-icon"
                            width={24}
                            height={24}
                            style={{ marginLeft: '-8px' }}
                        />
                        Learn more about Timeline
                    </Button>
                    <WithPermissions actions={[RbacActions['Events/Edit']]}>
                        <Button onClick={handleAddEvent}>Add Event</Button>
                    </WithPermissions>
                </Stack>
            </HeaderWrapper>
        </WithPermissions>
    );
}

const dispatchProps = {
    showAddEventModal,
    setInfoModalOpen
};

export default connect(null, dispatchProps)(TimelineHeader);

import React, { useState } from 'react';
import Grid from '../../../../common/Grid';
import Stack from '../../../../common/Stack';
import Map from '../../../../icons/Map';
import MapEu from '../../../../icons/MapEu';
import MapUk from '../../../../icons/MapUk';
import MapUs from '../../../../icons/MapUs';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { storageOptions } from '../../constants';
import { setStorage } from '../slice';
import { Storage as StorageTypes } from '../types';
import ChoiceItem from './ChoiceItem';
import IllustrationCard from './IllustrationCard';

import Handbook from './Handbook';
import InfoModal from './InfoModal';

const Storage = () => {
    const { storage, isPublished } = useAppSelector(
        state => state.snapshotSurveys.edit
    );
    const [hoverIndex, setHoverIndex] = useState(-1);
    const [handbookOpen, setHandbookOpen] = useState(false);
    const dispatch = useAppDispatch();

    return (
        <Stack flexDirection="column" gap="3.2rem">
            <span style={{ color: 'var(--color-ui-75)' }}>
                Dandi data storage options give you the power to store data in
                accordance with your legal and compliance needs.
            </span>
            <Stack flexDirection="row" gap="4.8rem">
                <Grid
                    base={1}
                    gap="1.6rem"
                    style={{
                        flex: 1
                    }}
                >
                    {storageOptions.map((option, index) => (
                        <Grid.Item key={option.id}>
                            <ChoiceItem
                                {...option}
                                selected={storage === option.id}
                                selectionExists={!!storage}
                                onClick={() => dispatch(setStorage(option.id))}
                                hoverIndex={hoverIndex}
                                setHoverIndex={setHoverIndex}
                                index={index}
                                readOnly={isPublished}
                            />
                        </Grid.Item>
                    ))}
                </Grid>
                <div
                    style={{
                        flex: 2,
                        position: 'relative'
                    }}
                >
                    <MapDisplay storage={storage} hoverIndex={hoverIndex} />
                </div>
            </Stack>
            <IllustrationCard onClick={() => setHandbookOpen(true)} />
            <InfoModal
                onClose={() => setHandbookOpen(false)}
                isOpen={handbookOpen}
                width={744}
            >
                <Handbook
                    stage="storage"
                    onClose={() => setHandbookOpen(false)}
                />
            </InfoModal>
        </Stack>
    );
};

const MapDisplay = ({
    storage,
    hoverIndex
}: {
    storage: any;
    hoverIndex: number;
}) => {
    return (
        <React.Fragment>
            <InteractiveMap
                selected={storage === StorageTypes.iowa}
                hovered={hoverIndex === 0}
                isHovering={hoverIndex !== -1 && hoverIndex !== 0}
            >
                <MapUs />
            </InteractiveMap>
            <InteractiveMap
                selected={storage === StorageTypes.frankfurt}
                hovered={hoverIndex === 1}
                isHovering={hoverIndex !== -1 && hoverIndex !== 1}
            >
                <MapEu />
            </InteractiveMap>
            <InteractiveMap
                selected={storage === StorageTypes.london}
                hovered={hoverIndex === 2}
                isHovering={hoverIndex !== -1 && hoverIndex !== 2}
            >
                <MapUk />
            </InteractiveMap>
            {!storage && hoverIndex === -1 && (
                <div
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%'
                    }}
                >
                    <Map />
                </div>
            )}
        </React.Fragment>
    );
};

const InteractiveMap = ({
    children,
    selected,
    hovered,
    isHovering
}: {
    children: any;
    selected: boolean;
    hovered: boolean;
    isHovering: boolean;
}) => {
    const getIsActive = (selected: boolean, hovered: boolean) => {
        if (!selected && !hovered) {
            return false;
        }
        if (!hovered && !isHovering) {
            return selected;
        }
        return hovered;
    };

    const isActive = getIsActive(selected, hovered);

    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                opacity: isActive ? 1 : 0,
                transition: 'opacity 0.2s'
            }}
        >
            {children}
        </div>
    );
};

export default Storage;

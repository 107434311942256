import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={20}
        height={20}
        viewBox="0 0 20 20"
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M3.333 16.667v-5.833h.834v4.426L15.26 4.167h-4.426v-.833h5.833v5.833h-.834V4.741L4.742 15.834h4.426v.833H3.334Z"
        />
    </svg>
);
export default SvgComponent;

import { PropsWithChildren } from 'react';

import Grid from '../common/Grid';
import Stack from '../common/Stack';
import CaretDown from '../icons/CaretDown';

interface RowProps extends React.HTMLAttributes<HTMLDivElement> {
    children: any;
    base: number;
    withoutBorder?: boolean;
}

interface CellProps extends React.HTMLAttributes<HTMLDivElement> {
    children: any;
    span: number;
    justifyContent?: string;
}

interface SortableProps {
    children: any;
    onClick: any;
    sortActive: boolean;
    sortDirection: string;
}

const Table = ({ children }: PropsWithChildren) => {
    return <Stack className="user-list">{children}</Stack>;
};

const HeadRow = (props: RowProps) => {
    const { children, base } = props;

    return (
        <div className="user-list__head-row">
            <Grid base={base}>{children}</Grid>
        </div>
    );
};

const Row = (props: RowProps) => {
    const { children, base, withoutBorder, ...rest } = props;

    return (
        <div
            className="user-list__row"
            {...rest}
            style={{
                borderBottom: withoutBorder
                    ? '0'
                    : '1px solid var(--color-ui-10)',
                ...rest.style
            }}
        >
            <Grid base={base}>{children}</Grid>
        </div>
    );
};

const Cell = (props: CellProps) => {
    const { children, span, justifyContent, ...rest } = props;
    return (
        <Grid.Item span={span} {...rest}>
            <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent={justifyContent}
                height="100%"
                gap=".8rem"
            >
                {children}
            </Stack>
        </Grid.Item>
    );
};

const Sortable = (props: SortableProps) => {
    const { children, onClick, sortActive, sortDirection } = props;

    return (
        <div
            onClick={onClick}
            style={{
                display: 'flex',
                alignItems: 'center',
                cursor: 'pointer',
                gap: '.8rem',
                color: sortActive ? 'var(--color-ui-100)' : 'var(--color-ui-75)'
            }}
        >
            {children}
            <span
                style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    transform: !sortActive
                        ? 'rotate(0deg)'
                        : sortDirection === 'asc'
                        ? 'rotate(0deg)'
                        : 'rotate(180deg)',
                    transition: 'transform .2s ease-in-out',
                    transformOrigin: 'center'
                }}
            >
                <CaretDown />
            </span>
        </div>
    );
};

Table.Row = Row;
Table.HeadRow = HeadRow;
Table.Cell = Cell;
Table.Sortable = Sortable;

export default Table;

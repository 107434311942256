import { Date } from '../types';

type Predicate = {
    column: string;
    terms: string[];
};

export type Criteria = {
    dateColumn?: string;
    endDate?: string;
    predicates: Predicate[];
    startDate?: string;
};

type AudienceMeta = {
    createdAt: Date;
    createdBy: string;
    uiElements: any[];
    updatedAt: Date;
};

type AudienceSyncStatus = {
    error: string;
    startedAt: Date;
    status: string;
    stoppedAt: Date;
};

type AudienceUser = {
    email: string;
    employeeId: string;
    fullName: string;
};

export enum AudienceType {
    'STATIC' = 'STATIC',
    'DYNAMIC' = 'DYNAMIC'
}

export interface Audience {
    audienceId: string;
    enterpriseId: string;
    activePercentage: number;
    changePercentage: number;
    criteria: Criteria;
    description: string;
    filePath: string;
    meta: AudienceMeta;
    size: number;
    syncStatus: AudienceSyncStatus;
    title: string;
    type: AudienceType;
    users: AudienceUser[];
}

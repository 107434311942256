import React from 'react';

const Close = (props: any) => (
    <svg viewBox="0 0 28 28" fill="currentColor" {...props}>
        <path d="M21.7 7.7l-1.4-1.4-6.3 6.3-6.3-6.3-1.4 1.4 6.3 6.3-6.3 6.3 1.4 1.4 6.3-6.3 6.3 6.3 1.4-1.4-6.3-6.3z" />
        <path fill="none" d="M0 0h28v28H0z" />
    </svg>
);

export default Close;

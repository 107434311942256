import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CommentBar from '../comments/CommentBar';
import ChartContainer from '../common/ChartContainer';
import GenericTable from '../common/GenericTable';
import LottieAnimation from '../common/LottieAnimation';
import MessageBar from '../common/MessageBar';
import WithPermissions from '../common/WithPermissions';
import { addMessage } from '../common/actions';
import useInteractiveChart from '../common/useInteractiveChart';
import ChartTypes from '../constants/ChartTypes';
import Constants from '../constants/Constants';
import MixPanel from '../constants/MixPanel';
import RbacActions from '../constants/RbacActions';
import { page, track } from '../lib/segment';
import Loader from '../lottie/graph-loader.json';
import { getEvents } from '../timeline/actions';
import DetailSection from './DetailSection';
import {
    closeDeleteSearchModal,
    closeEditSearchModal,
    createSearch,
    getSearches,
    removeSearch
} from './actions';
import Chart from './chart/Chart';
import TableChart from './chart/TableChart';
import {
    clearActiveLabel,
    clearSelection,
    setActiveLabel,
    toggleLockedSelection
} from './chart/actions';
import DeleteSearch from './dashboard/DeleteSearch';
import EditSearch from './dashboard/EditSearch';
import IntervalSection from './dashboard/IntervalSection';
import EmptyStateImage from './filter/EmptyStateImage';
import EmptyStateText from './filter/EmptyStateText';
import SearchLibrary from './searches/SearchLibrary';

function Explore({
    location,
    data,
    showSubtable,
    chartType,
    getSearches,
    searches,
    showDelete,
    reportToDelete,
    showEdit,
    reportToEdit,
    removeSearch,
    closeDeleteSearchModal,
    closeEditSearchModal,
    createSearch,
    searchesPending,
    noFilters,
    addMessage,
    allSearches,
    clearSelection,
    setActiveLabel,
    clearActiveLabel,
    toggleLabel,
    toggleLockedSelection,
    hoveredItem: globalHoveredItem,
    status: globalStatus,
    selection: globalSelection,
    events,
    getEvents,
    generatePending,
    generateNoData,
    generateError
}) {
    const [show, setShow] = useState(true);
    const { onHover, onHoverEnd, onToggle, hoveredItem, selection, status } =
        useInteractiveChart({
            mode: 'explore',
            status: globalStatus,
            hoveredItem: globalHoveredItem,
            selection: globalSelection,
            setActiveLabel,
            toggleLabel,
            lockedSelection: chartType === ChartTypes.Funnel,
            toggleLockedSelection,
            clearActiveLabel
        });

    function handleScroll() {
        setShow(document.documentElement.scrollTop <= Constants.ShadowScroll);
    }

    useEffect(() => {
        page(MixPanel.Pages.Explore);
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const getReportFromId = id => {
        const report = allSearches.searches.find(r => {
            return id === r.reportId;
        });

        if (report) return report.name;
        return '';
    };

    const handleDelete = () => {
        removeSearch(reportToDelete);
        closeDeleteSearchModal();

        const reportName = getReportFromId(reportToDelete);

        addMessage(`Search "${reportName}" deleted successfully`);
        track(MixPanel.Events.ExploreSavedSearchReportDelete, {
            'Report Name': reportName
        });
    };

    const handleCloseDeleteModal = () => {
        closeDeleteSearchModal();
    };

    const handleEdit = newName => {
        const searchToEdit = JSON.parse(JSON.stringify(findSearchToEdit()));
        if (newName !== searchToEdit.name) {
            if (!searchToEdit) return;

            searchToEdit.name = newName;
            createSearch(searchToEdit);
        }
        closeEditSearchModal();

        addMessage(`Search renamed successfully to "${newName}"`);
        track(MixPanel.Events.ExploreSavedSearchReportRename, {
            'New Report Name': newName
        });
    };
    const handleCloseEditModal = () => {
        closeEditSearchModal();
    };

    const findSearchToEdit = () => {
        return searches.find(search => search.reportId === reportToEdit);
    };

    const findOldName = () => {
        const searchToEdit = findSearchToEdit();
        if (searchToEdit) return searchToEdit.name;
    };

    useEffect(() => {
        if (searches.length === 0) {
            getSearches();
        }
        if (events === 0) {
            getEvents();
        }
    }, []);

    return (
        <WithPermissions
            actions={[
                RbacActions['Explore/Generate'],
                RbacActions['Dashboards/Explore']
            ]}
            showMessage={true}
        >
            <div className="explore">
                <WithPermissions
                    actions={[
                        RbacActions['Dashboards/ViewAll'],
                        RbacActions['Explore/SaveReport']
                    ]}
                >
                    {!searchesPending ? (
                        <>
                            <SearchLibrary data={searches} />
                            {searches.length === 0 && (
                                <>
                                    <EmptyStateText
                                        queryString={location.search}
                                    />
                                    <EmptyStateImage
                                        queryString={location.search}
                                    />
                                </>
                            )}
                        </>
                    ) : noFilters ? (
                        <div className="dashboards__empty">
                            <LottieAnimation
                                animation={Loader}
                                autoplay={true}
                                loop={true}
                                width={100}
                                height={100}
                            ></LottieAnimation>
                        </div>
                    ) : null}
                </WithPermissions>
                <IntervalSection />
                <Chart />
                {!showSubtable &&
                    chartType !== ChartTypes.Stats &&
                    chartType !== ChartTypes.ArithmeticTable &&
                    chartType !== ChartTypes.Funnel &&
                    !generatePending &&
                    !generateNoData &&
                    !generateError && (
                        <Fragment>
                            <TableChart />
                            <DetailSection queryString={location.search} />
                        </Fragment>
                    )}
                {chartType === ChartTypes.Funnel &&
                    !generatePending &&
                    !generateNoData &&
                    !generateError && (
                        <Fragment>
                            <div
                                className="explore-chart"
                                onClick={clearSelection}
                                style={{
                                    paddingTop: '1.6rem'
                                }}
                            >
                                <ChartContainer>
                                    <GenericTable
                                        data={data}
                                        mode="explore"
                                        template="funnel"
                                        onHover={onHover}
                                        onHoverEnd={onHoverEnd}
                                        onToggle={onToggle}
                                        hoveredItem={hoveredItem}
                                        selection={selection}
                                        status={status}
                                    />
                                </ChartContainer>
                            </div>
                        </Fragment>
                    )}
                <CommentBar />
                <DeleteSearch
                    isOpen={showDelete}
                    onCancel={handleCloseDeleteModal}
                    onDelete={handleDelete}
                />
                <EditSearch
                    isOpen={showEdit}
                    onCancel={handleCloseEditModal}
                    onSubmit={handleEdit}
                    oldName={findOldName()}
                />
                <MessageBar />
            </div>
        </WithPermissions>
    );
}

Explore.propTypes = {
    role: PropTypes.string,
    location: PropTypes.object,
    history: PropTypes.object,
    showSubtable: PropTypes.bool,
    chartType: PropTypes.string,
    data: PropTypes.array,
    clearSelection: PropTypes.func,
    setActiveLabel: PropTypes.func,
    clearActiveLabel: PropTypes.func,
    toggleLockedSelection: PropTypes.func,
    events: PropTypes.array,
    generatePending: PropTypes.bool,
    generateNoData: PropTypes.bool
};

const dispatchProps = {
    getSearches,
    getEvents,
    createSearch,
    closeDeleteSearchModal,
    removeSearch,
    closeEditSearchModal,
    addMessage,
    clearSelection,
    setActiveLabel,
    clearActiveLabel,
    toggleLockedSelection
};

function mapState(state) {
    const { showSubtable } = state.explore.filter;
    const {
        searches,
        showDelete,
        showEdit,
        pending: searchesPending
    } = state.explore.searches;
    const allSearches = state.explore.searches;

    return {
        ...state.explore.chart,
        role: state.auth.role,
        showSubtable,
        searches,
        events: state.timeline.events,
        showDelete,
        reportToDelete: state.explore.searches.deleteReportId,
        showEdit,
        reportToEdit: state.explore.searches.editReportId,
        searchesPending,
        allSearches,
        noFilters: state.explore.filter.analysisName === ''
    };
}

export default connect(mapState, dispatchProps)(Explore);

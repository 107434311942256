import React, { ChangeEvent } from 'react';
import produce from 'immer';
import { excludeWhenType } from '../configuration/model';
import StringListEdit from './StringListEdit';

function getExcludeWhenString(obj: excludeWhenType) {
    return obj ? obj.strings.join(', ') : '';
}

interface ExcludeWhenProps {
    excludeWhen?: excludeWhenType[];
    setExcludeWhen: any;
}

export default function ExcludeWhen({
    excludeWhen,
    setExcludeWhen
}: ExcludeWhenProps) {
    function handleChange(excludeWhenStr: string, i: number) {
        const strings = excludeWhenStr.split(',').map(e => e.trim());
        setExcludeWhen((excludeWhen: any) =>
            produce(excludeWhen, (draftState: any) => {
                draftState[i] = {
                    strings
                };
            })
        );
    }

    function handleNew() {
        setExcludeWhen((excludeWhen: any) =>
            produce(excludeWhen, (draftState: any) => {
                draftState.push({
                    strings: []
                });
            })
        );
    }

    function handleDelete(i: number) {
        setExcludeWhen((excludeWhen: any) =>
            produce(excludeWhen, (draftState: any) => {
                draftState.splice(i, 1);
            })
        );
    }

    return (
        <StringListEdit
            label="Exclude when"
            data={excludeWhen?.map(e => e.strings.join(', ')) ?? []}
            onNew={handleNew}
            onEdit={handleChange}
            onDelete={handleDelete}
        />
    );
}

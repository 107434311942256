import CanvasFonts from '../constants/CanvasFonts';

let canvas = document.createElement('canvas');
let ctx = canvas.getContext('2d');

export default function measureText(text: any, font = CanvasFonts.Regular10) {
    if (!ctx) {
        canvas = document.createElement('canvas');
        ctx = canvas.getContext('2d');
    }

    ctx!.font = font;
    return ctx!.measureText(text);
}

import React from 'react';
import { Link } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import MeasureCard from '../../components/MeasureCard';
import TrendIcon from '../../icons/TrendIcon';
import { IGoal, Trend } from '../../types';
import {
    getSymbolFromValueType,
    getIntervalDescription,
    determineTrend,
    getColor,
    getValue,
    getChangeValue,
    getDescriptionFromSegment,
    goalCategoryFromStatus
} from '../../utils';
import GoalCardMore from './GoalCardMore';
import { setGoal } from '../../goal/reducer';
import { track } from '../../../lib/segment';
import MixPanel from '../../../constants/MixPanel';
import _ from 'lodash';
import usePermissions from '../../../hooks/usePermissions';
import RbacActions from '../../../constants/RbacActions';

interface GoalCardProps extends React.HTMLAttributes<HTMLDivElement> {
    goal: IGoal;
    highlighted?: boolean;
}

const GoalCard = ({ goal, highlighted, ...rest }: GoalCardProps) => {
    const { toEdit, toDelete } = useAppSelector(state => state.measure.goals);
    const { attributes } = useAppSelector(state => state.editor.filter);
    const dispatch = useAppDispatch();
    const allowEdit = usePermissions({
        actions: [RbacActions['Goals/Edit']]
    });

    const metrics = goal.metricRegistrationRequest.metrics;
    const trajectories = metrics[0].trajectories;
    const values = goal.metricTrajectoryValues[0];

    const [menuOpen, setMenuOpen] = React.useState(false);
    const [goalTrend, setGoalTrend] = React.useState<Trend>(
        determineTrend({
            currentValue: values?.currentValue,
            desiredValue: values?.desiredValue,
            initialValue: values?.initialValue,
            previousValue: values?.previousValue,
            trajectory: trajectories[0].trajectory,
            goalStatus: goal.goalStatus
        })
    );

    const handleClickGoal = () => {
        dispatch(setGoal(goal));
        track(MixPanel.Events.GoalSelect);
    };

    React.useEffect(() => {
        setGoalTrend(
            determineTrend({
                currentValue: values?.currentValue,
                desiredValue: values?.desiredValue,
                initialValue: values?.initialValue,
                previousValue: values?.previousValue,
                trajectory: trajectories[0].trajectory,
                goalStatus: goal.goalStatus
            })
        );
    }, [goal]);

    React.useEffect(() => {
        if (toEdit || toDelete) {
            setMenuOpen(false);
        }
    });

    const generageGoalInfo = () => {
        let info = '';

        info += goal.metricRegistrationRequest.metrics[0].analysisName;
        const mainVariables =
            goal.metricRegistrationRequest.metrics[0].mainVariables;

        Object.keys(mainVariables).forEach((variable, index) => {
            if (index !== 0) {
                info += ', ';
            }
            const description = getDescriptionFromSegment(
                {
                    name: _.startCase(
                        (mainVariables as any)[variable].originalName
                    ),
                    values: [(mainVariables as any)[variable].value]
                },
                attributes
            );
            if (description) {
                if (index === 0) {
                    info += ' - ';
                }
                info += description;
            }
        });

        info += ' (';
        if (goal.goalEndPeriod.month) {
            info += goal.goalEndPeriod.month + ' ';
        }
        if (goal.goalEndPeriod.quarter) {
            info += goal.goalEndPeriod.quarter + ' ';
        }
        info += goal.goalEndPeriod.year;

        info += ')';

        return info;
    };

    return (
        <Link to={`/measure/${goal.goalId}`}>
            <MeasureCard
                highlighted={highlighted}
                onClick={handleClickGoal}
                name={goal.goalName}
                goalInfo={generageGoalInfo()}
                color={getColor(goalTrend)}
                colorGoal={
                    goalCategoryFromStatus(goal.goalStatus) === 'completed' ||
                    goalTrend === 'complete'
                }
                icon={
                    <TrendIcon
                        size={24}
                        color={getColor(goalTrend)}
                        trend={goalTrend}
                    />
                }
                status={getValue(values.currentValue)}
                change={getChangeValue(
                    values.previousValue,
                    values.currentValue
                )}
                goal={getValue(values.desiredValue)}
                unit={getSymbolFromValueType(
                    trajectories[trajectories.length - 1].initialValue.valueType
                )}
                menu={
                    goalCategoryFromStatus(goal.goalStatus) === 'active' &&
                    allowEdit && (
                        <GoalCardMore
                            open={menuOpen}
                            setOpen={setMenuOpen}
                            goal={goal}
                            goalId={goal.goalId}
                        />
                    )
                }
                {...rest}
            />
        </Link>
    );
};

export default GoalCard;

import React from 'react';
import Chart from './Chart';
import MoreMenuWrapper from './MoreMenuWrapper';
import { ChartType } from './types';

interface SearchProps extends React.HTMLProps<HTMLDivElement> {
    reportId?: string;
    title?: string;
    type: ChartType;
    count?: number;
    analysisString?: string;
    sectionTag?: string;
    highlighted?: boolean;
    hovered?: boolean;
    onDelete?: () => void;
}

/**
 * Main wrapper of the Searches
 */
const Search = ({
    title,
    type,
    count,
    highlighted,
    hovered,
    analysisString,
    sectionTag,
    onDelete,
    reportId,
    ...rest
}: SearchProps) => {
    const [moreOpen, setMoreOpen] = React.useState(false);
    const [scale, setScale] = React.useState(1);

    React.useEffect(() => {
        setScale(hovered ? 1.02 : 1);
    }, [hovered]);

    return (
        <div
            className="thumbnail__container"
            style={{
                opacity: highlighted ? 1 : 0.4,
                transform: `scale(${scale})`
            }}
            {...rest}
        >
            <div className="thumbnail__header">
                <h1 className="thumbnail__title">
                    {title ? title : titleCase(type)}
                </h1>
                <MoreMenuWrapper
                    moreOpen={moreOpen}
                    setMoreOpen={setMoreOpen}
                    reportId={reportId as string}
                />
            </div>
            <Chart
                type={type}
                count={count}
                style={{ borderRadius: '.4rem' }}
            ></Chart>
        </div>
    );
};
/**
 * Converts a string that is in either kebab or snake case to title case
 */
const titleCase = (s: string) =>
    s
        .replace(/^[-_]*(.)/, (_, c) => c.toUpperCase()) // Initial char (after -/_)
        .replace(/[-_]+(.)/g, (_, c) => ' ' + c.toUpperCase()); // First char after each -/_

export default Search;

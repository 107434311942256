import classNames from 'classnames';
import moment from 'moment';
import PropTypes from 'prop-types';
import qs from 'qs';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import BenchmarkMenu from '../benchmark/BenchmarkMenu';
import BenchmarkModal from '../benchmark/BenchmarkModal';
import {
    closeBenchmarkModal,
    deleteBenchmark,
    openBenchmarkModal
} from '../benchmark/actions';
import DatePicker from '../common/DatePicker';
import DeleteModal from '../common/DeleteModal';
import Dropdown from '../common/Dropdown';
import EventsModal from '../common/EventsModal';
import Popover from '../common/Popover';
import { closeDeleteModal, openDeleteModal } from '../common/actions';
import AnalysisTypes from '../constants/AnalysisTypes';
import ChartTypes from '../constants/ChartTypes';
import GridConstants from '../constants/GridConstants';
import MixPanel from '../constants/MixPanel';
import { getReport } from '../dashboard/view/actions';
import { getDashboards } from '../dashboards/actions';
import DateRangeSelection from '../explore/dashboard/DateRangeSelection';
import BenchmarkIcon from '../icons/Benchmark';
import Timeline from '../icons/Timeline';
import getDatePickerLabel from '../lib/getDatePickerLabel';
import { page, track } from '../lib/segment';
import { getTemplateFromAnalysisName } from '../store/actionUtils';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import SaveButton from './buttons/SaveButton';
import {
    closeEventsModalInEditor,
    openEventsModalInEditor,
    removeBenchmark,
    setBenchmark,
    setChartData,
    setChartTypeInEditor,
    setDateInEditor,
    setHidePie,
    setIndexTypeInEditor,
    setYAxisTypeInEditor
} from './chart/actions';
import {
    setIntervalCountInEditor,
    setIntervalInEditor,
    setIntervalRangeInEditor
} from './filter/actions';
import Grid from './grid/Grid';
import ChartType from './options/ChartType';
import Interval from './options/Interval';
import TableOptions from './options/TableOptions';
import { setTitle, setCustom } from './titleSlice';
import './styles.scss';
import constants from '../constants/Constants';

function capitalizeFirstLetter(str) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}

function Editor({
    editMode,
    report,
    reportData,
    getDashboards,
    getTemplateFromAnalysisName,
    setChartData,
    getReport,
    interval,
    intervalCount,
    startDate,
    endDate,
    setIntervalInEditor,
    setIntervalCountInEditor,
    setIntervalRangeInEditor,
    generatePending,
    generateNoData,
    generated,
    dashboardName,
    setDateInEditor,
    date,
    completed,
    dates,
    benchmark,
    setBenchmark,
    removeBenchmark,
    showBenchmarkIcon,
    setHidePie,
    deleteBenchmarkModalOpen,
    deleteBenchmarkPending,
    isBenchmarkModalOpen,
    deleteBenchmark,
    openBenchmarkModal,
    closeBenchmarkModal,
    openDeleteModal,
    closeDeleteModal,
    chartType,
    setChartTypeInEditor,
    closeEventsModalInEditor,
    openEventsModalInEditor,
    setYAxisTypeInEditor,
    defaultName,
    analysisType
}) {
    const { title, custom } = useAppSelector(state => state.editor.title);
    const [length, setLength] = useState(GridConstants.MinGraphLength);
    const [showSinglePeriod, setShowSinglePeriod] = useState(false);
    const [changeOption, setChangeOption] = useState();
    const [colWidth, setColWidth] = useState();
    const gridRef = useRef();
    const [benchmarkMenuOpen, setBenchmarkMenuOpen] = useState(false);
    const [benchmarkEdit, setBenchmarkEdit] = useState(false);
    const [benchmarkToEdit, setBenchmarkToEdit] = useState();
    const dispatch = useAppDispatch();
    const { allowYAxisToggle, yAxisType, indexType, allowIndexToggle } =
        useAppSelector(state => state.editor.chart);
    const [arithmeticDropdownOpen, setArithmeticDropdownOpen] = useState(false);

    useEffect(() => {
        dispatch(setCustom(false));
    }, []);

    useEffect(() => {
        if (
            chartType === ChartTypes.Matrix ||
            chartType === ChartTypes.HorizontalBar
        ) {
            setLength(GridConstants.ColumnCount);
        }
    }, [chartType]);

    useEffect(() => {
        if (!custom) {
            dispatch(
                setTitle(
                    report?.name || defaultName || constants.DefaultReportName
                )
            );
        }
    }, [defaultName, report, custom]);

    useEffect(() => {
        // prevents executing this effect when we set report data from editor
        if (completed) return;
        if (report) {
            const chartTypeConfig = report.configuration.find(
                c => c.name === 'chartType'
            );
            const yAxisTypeConfig = report.configuration.find(
                c => c.name === 'yAxisType'
            );
            const indexTypeConfig = report.configuration.find(
                c => c.name === 'indexType'
            );
            if (indexTypeConfig) {
                setIndexTypeInEditor(indexTypeConfig.value);
            }
            if (yAxisTypeConfig) {
                setYAxisTypeInEditor(yAxisTypeConfig.value);
            }

            if (chartTypeConfig) {
                setChartTypeInEditor(chartTypeConfig.value);
            }
            const lengthConfig = report.configuration.find(
                c => c.name === 'length'
            );
            if (lengthConfig) {
                setLength(parseInt(lengthConfig.value));
            }
            const singlePeriodConfig = report.configuration.find(
                c => c.name === 'showSinglePeriod'
            );
            if (singlePeriodConfig) {
                setShowSinglePeriod(singlePeriodConfig.value === 'true');
            }
            const changeOptionConfig = report.configuration.find(
                c => c.name === 'changeOption'
            );
            if (changeOptionConfig && changeOptionConfig.value) {
                setChangeOption(changeOptionConfig.value);
            }
            getTemplateFromAnalysisName(report.analysisName);
        } else if (editMode) {
            getDashboards();
        }
    }, [report, completed]);

    useEffect(() => {
        // prevents executing this effect when we set report data from editor
        if (completed) return;
        if (editMode) {
            if (!reportData) {
                if (report) {
                    getReport(report);
                }
            } else {
                setChartData(reportData);
            }
        }
    }, [report, reportData, completed]);

    useEffect(() => {
        page(MixPanel.Pages.ReportEditor);
        if (gridRef.current) {
            const width = gridRef.current.clientWidth;
            const colWidth =
                (width -
                    GridConstants.ColumnGap * (GridConstants.ColumnCount - 1)) /
                GridConstants.ColumnCount;
            setColWidth(colWidth);
        }
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (benchmark) {
            setChartTypeInEditor('Pie');
            setLength(GridConstants.ColumnCount);
        }
    }, [benchmark]);

    function getOptions() {
        const options = {};
        if (chartType === ChartTypes.Table) {
            Object.assign(options, {
                showSinglePeriod,
                changeOption
            });
        }
        return options;
    }

    function handleIntervalChange(interval) {
        track(MixPanel.Events.ReportEditorSelectInterval, {
            'Dashboard Name': dashboardName,
            'Report Name': title,
            Interval: interval
        });
        setIntervalInEditor(interval, chartType);
    }

    function handleIntervalCountChange(intervalCount) {
        track(MixPanel.Events.ReportEditorSelectIntervalCount, {
            'Dashboard Name': dashboardName,
            'Report Name': title,
            'Interval Count': intervalCount
        });
        setIntervalCountInEditor(intervalCount, chartType);
    }

    function handleIntervalRangeChange(startDate, endDate) {
        track(MixPanel.Events.ReportEditorSelectIntervalRange, {
            'Dashboard Name': dashboardName,
            'Report Name': title,
            'Start Date': startDate,
            'End Date': endDate
        });
        setIntervalRangeInEditor(startDate, endDate, chartType);
    }

    function handleDateChange(date) {
        track(MixPanel.Events.ReportEditorSelectDate, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
        setDateInEditor(date);
        setArithmeticDropdownOpen(false);
    }

    function handleChartTypeChange(chartType) {
        track(MixPanel.Events.ReportEditorSelectChartType, {
            'Dashboard Name': dashboardName,
            'Report Name': title,
            'Chart Type': chartType
        });
        setChartTypeInEditor(chartType);
    }

    function handleLengthChange(length) {
        track(MixPanel.Events.ReportEditorResize, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
        setLength(length);
    }

    function handleShowSinglePeriodChange(showSinglePeriod) {
        if (showSinglePeriod) {
            track(MixPanel.Events.ReportEditorSelectShowLastPeriod, {
                'Dashboard Name': dashboardName,
                'Report Name': title
            });
        }
        setShowSinglePeriod(showSinglePeriod);
    }

    function handleChangeOptionChange(changeOption) {
        if (changeOption !== 'None') {
            track(MixPanel.Events.ReportEditorSelectPeriodOverPeriodChange, {
                'Dashboard Name': dashboardName,
                'Report Name': title,
                'Change Type': changeOption
            });
        }
        setChangeOption(changeOption);
    }

    function handleBenchmarkIconClick() {
        setBenchmarkMenuOpen(!benchmarkMenuOpen);
    }

    function handleBenchmarkSelect(benchmark) {
        track(MixPanel.Events.ReportEditorSelectBenchmark, {
            'Benchmark Name': benchmark.benchmarkName
        });
        setBenchmarkMenuOpen(false);
        setBenchmark(benchmark);
        setChartTypeInEditor('Pie');
        setLength(GridConstants.ColumnCount);
    }

    function handleBenchmarkRemove() {
        track(MixPanel.Events.ReportEditorRemoveBenchmark);
        setBenchmarkMenuOpen(false);
        removeBenchmark();
    }

    function handleShowPieChange(showPie) {
        setHidePie(!showPie);
        setBenchmarkMenuOpen(false);
    }

    function handleBenchmarkDelete() {
        track(MixPanel.Events.ReportEditorDeleteBenchmark, {
            'Benchmark Name': benchmarkToEdit.benchmarkName
        });
        closeBenchmarkModal();
        openDeleteModal();
    }

    function handleBenchmarkCreate() {
        track(MixPanel.Events.ReportEditorCreateBenchmark);
        openBenchmarkModal();
        setBenchmarkMenuOpen(false);
        setBenchmarkEdit(false);
    }

    function handleBenchmarkEdit(benchmarkToEdit) {
        track(MixPanel.Events.ReportEditorEditBenchmark, {
            'Benchmark Name': benchmarkToEdit.benchmarkName
        });
        setBenchmarkToEdit(benchmarkToEdit);
        openBenchmarkModal();
        setBenchmarkMenuOpen(false);
        setBenchmarkEdit(true);
    }

    function handleBenchmarkDeleteConfirm() {
        track(MixPanel.Events.ReportEditorDeleteBenchmarkConfirm, {
            'Benchmark Name': benchmarkToEdit.benchmarkName
        });
        deleteBenchmark(benchmarkToEdit.benchmarkId);
    }

    function handleBenchmarkDeleteCancel() {
        track(MixPanel.Events.ReportEditorDeleteBenchmarkCancel, {
            'Benchmark Name': benchmarkToEdit.benchmarkName
        });
        closeDeleteModal();
    }

    const options = getOptions();
    const hasBenchmark = !!benchmark;
    let isOther = false;
    if (!benchmark && benchmarkToEdit) {
        isOther = true;
    }
    if (benchmark && benchmarkToEdit) {
        isOther = benchmark.benchmarkId !== benchmarkToEdit.benchmarkId;
    }

    return (
        <div className="editor">
            <div className="editor__buttons"></div>
            <div className="editor__grid" ref={gridRef}>
                <Grid
                    title={title}
                    setTitle={title => {
                        dispatch(setTitle(title));
                        dispatch(setCustom(true));
                    }}
                    chartType={chartType}
                    length={GridConstants.ColumnCount}
                    setLength={handleLengthChange}
                    options={options}
                    generatePending={generatePending}
                    generated={generated}
                    colWidth={colWidth}
                    hasBenchmark={hasBenchmark}
                />
            </div>
            {(generated || generateNoData) && (
                <div className="editor__desc">
                    <div className="editor__desc__left">
                        {chartType !== ChartTypes.Matrix &&
                        chartType !== ChartTypes.HorizontalBar &&
                        analysisType !== AnalysisTypes.Arithmetic ? (
                            <div className="editor__desc__interval">
                                <h3 className="editor__label editor__label--interval">
                                    Date Range
                                </h3>

                                <DateRangeSelection
                                    interval={interval}
                                    selectInterval={handleIntervalChange}
                                    intervalCount={intervalCount}
                                    selectIntervalCount={
                                        handleIntervalCountChange
                                    }
                                    startDate={startDate}
                                    endDate={endDate}
                                    selectIntervalRange={
                                        handleIntervalRangeChange
                                    }
                                    dates={dates}
                                    fromEditor
                                />
                            </div>
                        ) : analysisType === AnalysisTypes.Arithmetic ? (
                            <div className="editor__desc__interval">
                                <h3 className="editor__label editor__label--interval">
                                    Date
                                </h3>
                                <DatePicker
                                    placeholder="Month"
                                    disabled={false}
                                    isOpen={arithmeticDropdownOpen}
                                    label={
                                        date
                                            ? moment(date).format('MMMM yyyy')
                                            : 'Month'
                                    }
                                    onClick={() => {
                                        setArithmeticDropdownOpen(
                                            !arithmeticDropdownOpen
                                        );
                                    }}
                                    selectsRange={false}
                                    onDateSelect={handleDateChange}
                                    formatType={'Month'}
                                />
                            </div>
                        ) : (
                            <div className="editor__desc__interval">
                                <h3 className="editor__label editor__label--interval">
                                    Interval
                                </h3>

                                <Interval
                                    interval={interval}
                                    setIntervalInEditor={handleIntervalChange}
                                />
                            </div>
                        )}

                        {(chartType === ChartTypes.Matrix ||
                            chartType === ChartTypes.HorizontalBar) && (
                            <div className="editor__desc__interval">
                                <h3 className="editor__label editor__label--date">
                                    Date
                                </h3>
                                <DatePicker
                                    placeholder={interval}
                                    disabled={false}
                                    isOpen={arithmeticDropdownOpen}
                                    label={getDatePickerLabel(date, interval)}
                                    onClick={() => {
                                        setArithmeticDropdownOpen(
                                            !arithmeticDropdownOpen
                                        );
                                    }}
                                    selectsRange={false}
                                    onDateSelect={handleDateChange}
                                    formatType={interval}
                                />
                            </div>
                        )}

                        {chartType !== ChartTypes.Funnel &&
                            chartType !== ChartTypes.ArithmeticTable &&
                            chartType !== ChartTypes.Stats && (
                                <div>
                                    <h3 className="editor__label">
                                        Chart Type
                                    </h3>
                                    <ChartType
                                        chartType={chartType}
                                        setChartType={handleChartTypeChange}
                                        hasBenchmark={!!benchmark}
                                    />
                                </div>
                            )}
                        {(allowYAxisToggle || allowIndexToggle) && (
                            <div
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: '.8rem',
                                    marginRight: '1.6rem'
                                }}
                            >
                                <h3>Display Type</h3>
                                <div
                                    style={{
                                        height: '4.8rem',
                                        display: 'flex',
                                        alignItems: 'center',
                                        borderRadius: '0.8rem',
                                        padding: '1.6rem',
                                        background: 'var(--color-shade-h3)',
                                        width: 'fit-content'
                                    }}
                                >
                                    {allowYAxisToggle && (
                                        <Dropdown
                                            disabled={!!benchmark}
                                            selectedOption={capitalizeFirstLetter(
                                                yAxisType
                                            )}
                                            options={[
                                                {
                                                    label: 'Count',
                                                    value: 'count'
                                                },
                                                {
                                                    label: 'Percentage',
                                                    value: 'percentage'
                                                }
                                            ]}
                                            buttonLarge={false}
                                            onClick={val => {
                                                dispatch(
                                                    setYAxisTypeInEditor(val)
                                                );
                                            }}
                                        />
                                    )}
                                    {allowIndexToggle && (
                                        <Dropdown
                                            disabled={false}
                                            selectedOption={capitalizeFirstLetter(
                                                indexType
                                            )}
                                            options={[
                                                {
                                                    label: 'Indexed',
                                                    value: 'indexed'
                                                },
                                                {
                                                    label: 'Percentage',
                                                    value: 'percentage'
                                                }
                                            ]}
                                            buttonLarge={false}
                                            onClick={val => {
                                                dispatch(
                                                    setIndexTypeInEditor(val)
                                                );
                                            }}
                                        />
                                    )}
                                </div>
                            </div>
                        )}

                        {showBenchmarkIcon &&
                            chartType !== ChartTypes.Matrix &&
                            chartType !== ChartTypes.HorizontalBar && (
                                <div
                                    id="benchmark"
                                    className="benchmark-section"
                                >
                                    <h3 className="editor__label">Benchmark</h3>
                                    <Popover
                                        placement="topLeft"
                                        visible={benchmarkMenuOpen}
                                        onVisibleChange={
                                            handleBenchmarkIconClick
                                        }
                                        content={
                                            <BenchmarkMenu
                                                benchmark={benchmark}
                                                onBenchmarkSelect={
                                                    handleBenchmarkSelect
                                                }
                                                onBenchmarkRemove={
                                                    handleBenchmarkRemove
                                                }
                                                showPie={
                                                    benchmark &&
                                                    !benchmark.hidePie
                                                }
                                                onShowPieChange={
                                                    handleShowPieChange
                                                }
                                                onBenchmarkCreate={
                                                    handleBenchmarkCreate
                                                }
                                                onBenchmarkEdit={
                                                    handleBenchmarkEdit
                                                }
                                            />
                                        }
                                        overlayClassName="benchmark-section__popover"
                                        getPopupContainer={() =>
                                            document.getElementById('benchmark')
                                        }
                                    >
                                        <div
                                            className={classNames(
                                                'benchmark-section__icon',
                                                {
                                                    'benchmark-section__icon--selected':
                                                        hasBenchmark
                                                }
                                            )}
                                        >
                                            <BenchmarkIcon
                                                className={classNames('icon', {
                                                    'icon--selected':
                                                        hasBenchmark
                                                })}
                                            />
                                        </div>
                                    </Popover>
                                </div>
                            )}
                        {chartType === ChartTypes.Line && (
                            <div className="events-section">
                                <h3 className="editor__label">Events</h3>
                                <div
                                    onClick={openEventsModalInEditor}
                                    className="events-section__icon"
                                >
                                    <Timeline />
                                </div>
                                <EventsModal
                                    onCancel={closeEventsModalInEditor}
                                />
                            </div>
                        )}
                    </div>
                    <div className="editor__desc__right">
                        <SaveButton
                            title={title}
                            chartType={chartType}
                            yAxisType={yAxisType}
                            indexType={indexType}
                            length={length}
                            options={options}
                        />
                    </div>
                </div>
            )}

            {generated && chartType === ChartTypes.Table && (
                <div className="editor__options">
                    <h3 className="editor__options__title">Report options</h3>
                    <TableOptions
                        showSinglePeriod={showSinglePeriod}
                        setShowSinglePeriod={handleShowSinglePeriodChange}
                        changeOption={changeOption}
                        setChangeOption={handleChangeOptionChange}
                    />
                </div>
            )}
            <BenchmarkModal
                benchmark={benchmarkEdit ? benchmarkToEdit : null}
                isOpen={isBenchmarkModalOpen}
                onCancel={closeBenchmarkModal}
                onBenchmarkDelete={handleBenchmarkDelete}
                isOther={isOther}
                fromEditor
            />
            <DeleteModal
                isOpen={deleteBenchmarkModalOpen}
                onCancel={handleBenchmarkDeleteCancel}
                onAction={handleBenchmarkDeleteConfirm}
                title="Delete Benchmark"
                pending={deleteBenchmarkPending}
            >
                <p>Click Delete to permanently delete this benchmark.</p>
            </DeleteModal>
        </div>
    );
}

Editor.propTypes = {
    dashboardId: PropTypes.string,
    dashboardName: PropTypes.string,
    reportId: PropTypes.string,
    editMode: PropTypes.bool,
    report: PropTypes.object,
    reportData: PropTypes.object,
    getDashboards: PropTypes.func,
    getTemplateFromAnalysisName: PropTypes.func,
    setChartData: PropTypes.func,
    getReport: PropTypes.func,
    interval: PropTypes.string,
    intervalCount: PropTypes.number,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    setIntervalInEditor: PropTypes.func,
    setIntervalCountInEditor: PropTypes.func,
    setIntervalRangeInEditor: PropTypes.func,
    generatePending: PropTypes.bool,
    generated: PropTypes.bool,
    role: PropTypes.string,
    setDateInEditor: PropTypes.func,
    date: PropTypes.string,
    completed: PropTypes.bool,
    dates: PropTypes.array,
    benchmark: PropTypes.object,
    setBenchmark: PropTypes.func,
    removeBenchmark: PropTypes.func,
    showBenchmarkIcon: PropTypes.bool,
    setHidePie: PropTypes.func,
    deleteBenchmarkModalOpen: PropTypes.bool,
    deleteBenchmarkPending: PropTypes.bool,
    isBenchmarkModalOpen: PropTypes.bool,
    openBenchmarkModal: PropTypes.func,
    closeBenchmarkModal: PropTypes.func,
    deleteBenchmark: PropTypes.func,
    openDeleteModal: PropTypes.func,
    closeDeleteModal: PropTypes.func,
    chartType: PropTypes.string,
    yAxisType: PropTypes.string,
    setChartTypeInEditor: PropTypes.func,
    allEvents: PropTypes.array,
    closeEventsModalInEditor: PropTypes.func,
    openEventsModalInEditor: PropTypes.func,
    defaultName: PropTypes.string
};

function mapState(state, ownProps) {
    const query = qs.parse(ownProps.location.search, {
        ignoreQueryPrefix: true
    });
    const { dashboardId, reportId } = query;
    const dashboard = state.dashboards[dashboardId];
    const dashboardName = dashboard ? dashboard.name : '';

    const editMode = !!reportId;
    let report;
    let reportData;
    if (editMode) {
        if (dashboard) {
            report = dashboard.reports.find(r => r.reportId === reportId);
        }
        reportData = state.reports[reportId];
    }
    const { analysisType, interval, intervalCount, startDate, endDate, date } =
        state.editor.filter;
    const {
        generatePending,
        generateNoData,
        generated,
        dates,
        benchmark,
        showBenchmarkIcon,
        chartType,
        yAxisType
    } = state.editor.chart;
    const { role } = state.auth;
    const { completed } = state.editor.save;

    let defaultName = state.editor.chart.lifecycle;

    if (
        state.editor.chart.mainSegments &&
        state.editor.chart.mainSegments.length > 0
    ) {
        defaultName +=
            ' - ' +
            state.editor.chart.mainSegments?.map(s => s.name).join(', ');
    }

    return {
        dashboardId,
        reportId,
        dashboardName,
        editMode,
        report,
        reportData,
        interval,
        intervalCount,
        startDate,
        endDate,
        generatePending,
        generateNoData,
        generated,
        role,
        dates,
        date,
        completed,
        benchmark,
        showBenchmarkIcon,
        chartType,
        yAxisType,
        deleteBenchmarkModalOpen: state.deleteModal.open,
        deleteBenchmarkPending: state.deleteModal.pending,
        isBenchmarkModalOpen: state.benchmarkModal.isOpen,
        allEvents: state.timeline.events,
        defaultName,
        analysisType
    };
}

const dispatchProps = {
    getDashboards,
    getTemplateFromAnalysisName,
    setChartData,
    getReport,
    setIntervalInEditor,
    setIntervalCountInEditor,
    setIntervalRangeInEditor,
    setDateInEditor,
    setBenchmark,
    removeBenchmark,
    setHidePie,
    openBenchmarkModal,
    closeBenchmarkModal,
    openDeleteModal,
    closeDeleteModal,
    deleteBenchmark,
    setChartTypeInEditor,
    closeEventsModalInEditor,
    openEventsModalInEditor,
    setYAxisTypeInEditor,
    setIndexTypeInEditor
};

export default connect(mapState, dispatchProps)(Editor);

import classNames from 'classnames';
import { scaleLinear } from 'd3-scale';
import React, { PropsWithChildren, useContext, useReducer } from 'react';
import { ChartContext } from '../../contexts/ChartContext';
import { IChartEventsProps } from '../../lib/getChartDeclaration';
import { useAppSelector } from '../../store/hooks';
import { appModes } from '../../types';
import NormalizedEvents from './NormalizedEvents';
import RegularEvents from './RegularEvents';
import stateMachine from './stateMachine';

export const EventsContext = React.createContext<any>(null);

const Events = ({ children }: PropsWithChildren) => {
    const {
        chartState: { innerChartOffsets }
    } = useContext(ChartContext);

    const { analysisType, interval, dates } = useAppSelector(state => {
        switch (state.appState.mode) {
            case appModes.explore: {
                return {
                    analysisType: state.explore.chart.analysisType,
                    interval: state.explore.chart.interval,
                    dates: state.explore.chart.dates,
                    events:
                        state.timeline.events.filter(ev =>
                            state.explore.chart.eventIds.includes(ev.eventId)
                        ) || []
                };
            }
            case appModes.measure: {
                return {
                    analysisType: state.measure.goal.analysisType,
                    interval: state.measure.goal.interval,
                    dates: state.measure.goal.dates,
                    events:
                        state.timeline.events.filter(ev =>
                            state.measure.goal.eventIds.includes(ev.eventId)
                        ) || []
                };
            }
            default: {
                return {
                    analysisType: state.editor.chart.analysisType,
                    interval: state.editor.chart.interval,
                    dates: state.editor.chart.dates,
                    events:
                        state.timeline.events.filter(ev =>
                            state.editor.chart.eventIds.includes(ev.eventId)
                        ) || []
                };
            }
        }
    });

    const xScale = scaleLinear()
        .domain([dates[0], dates[dates.length - 1]])
        .range([0, 100]);

    function getFirstOf(date: string, interval: string): Date {
        const dateConverted = new Date(date);
        switch (interval) {
            case 'Month':
                return new Date(
                    dateConverted.getFullYear(),
                    dateConverted.getMonth(),
                    1
                );
            case 'Quarter':
                const month = dateConverted.getMonth();
                return new Date(
                    dateConverted.getFullYear(),
                    Math.floor(month / 3) * 3,
                    1
                );
            case 'Year':
                return new Date(dateConverted.getFullYear(), 0, 1);
            default:
                return dateConverted;
        }
    }

    function getCorrectDate(
        event: IChartEventsProps['events'][0],
        interval: string = 'Month',
        start?: boolean
    ): Date {
        if (start) {
            return getFirstOf(event.dateStart, interval);
        } else {
            return getFirstOf(event.dateEnd, interval);
        }
    }

    function isEventHidden(event: IChartEventsProps['events'][0]) {
        const date = getCorrectDate(event, interval);

        return xScale(date) < 0;
    }

    function getLabelPosition(
        event: IChartEventsProps['events'][0]
    ): 'left' | 'right' {
        if (
            xScale(getCorrectDate(event, interval)) > 100 ||
            (xScale(getCorrectDate(event, interval)) >= 70 &&
                xScale(getCorrectDate(event, interval, true)) > 25)
        ) {
            return 'left';
        }

        return 'right';
    }
    function isSinglePoint(event: IChartEventsProps['events'][0]) {
        return event.dateStart === event.dateEnd;
    }

    const [{ status, hoverId, selection }, dispatch] = useReducer(
        stateMachine,
        {
            hoverId: null,
            selection: [],
            status: 'idle'
        }
    );

    return (
        <EventsContext.Provider
            value={{
                dispatch,
                hoverId,
                selection,
                isSinglePoint,
                getCorrectDate,
                getFirstOf,
                getLabelPosition,
                xScale,
                isEventHidden
            }}
        >
            <div
                className={classNames(
                    'chart-events',
                    `chart-events--${analysisType!
                        ?.toLowerCase()
                        .split(' ')
                        .join('-')}`
                )}
                style={
                    {
                        '--right-offset': innerChartOffsets.right + 'px'
                    } as React.CSSProperties
                }
                data-status={status}
                onClick={() => {
                    dispatch({
                        type: 'onClear'
                    });
                }}
            >
                <div className="chart-events__inner">{children}</div>
            </div>
        </EventsContext.Provider>
    );
};

Events.NormalizedEvents = NormalizedEvents;
Events.RegularEvents = RegularEvents;

export default Events;

import React from 'react';

const SvgComponent = (props: any) => (
    <svg
        xmlnsXlink="http://www.w3.org/1999/xlink"
        fill="currentColor"
        viewBox="0 0 18 18"
        {...props}
    >
        <polygon points="9,12 6,7 12,7" />
    </svg>
);

export default SvgComponent;

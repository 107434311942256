import { ChartProps } from './types';
import { getChartFromType } from './utils';

/**
 * All of the charts supported in the ExploreThumbnail component
 * @param props type and count {@link ChartProps}
 */
const Chart = (props: ChartProps) => {
    const ChartComponent = getChartFromType(props.type);
    return (
        <div className="chart__container" {...props}>
            <ChartComponent {...props} />
        </div>
    );
};

export default Chart;

import sortBy from 'lodash/sortBy';
import precisionRound from '../lib/precisionRound';

export default function getBenchmarkData(attribute: string, data: any) {
    let benchmark = [];
    if (attribute === 'Gender') {
        const { maleTotal, femaleTotal, genderValidTotal } = data;
        const male = {
            label: 'Male',
            value: precisionRound((maleTotal * 100) / genderValidTotal, 1)
        };
        const female = {
            label: 'Female',
            value: precisionRound((femaleTotal * 100) / genderValidTotal, 1)
        };
        benchmark.push(male, female);
    } else {
        const {
            americanIndianOrAlaskanNativeTotal,
            asianTotal,
            blackTotal,
            hispanicTotal,
            nativeHawaiianTotal,
            twoOrMoreRacesTotal,
            whiteTotal,
            ethnicityValidTotal
        } = data;
        const indian = {
            label: 'American Indian Or Alaskan Native',
            value: precisionRound(
                (americanIndianOrAlaskanNativeTotal * 100) /
                    ethnicityValidTotal,
                1
            )
        };
        const asian = {
            label: 'Asian',
            value: precisionRound((asianTotal * 100) / ethnicityValidTotal, 1)
        };
        const black = {
            label: 'Black',
            value: precisionRound((blackTotal * 100) / ethnicityValidTotal, 1)
        };
        const hispanic = {
            label: 'Hispanic',
            value: precisionRound(
                (hispanicTotal * 100) / ethnicityValidTotal,
                1
            )
        };
        const hawaiian = {
            label: 'Native Hawaiian',
            value: precisionRound(
                (nativeHawaiianTotal * 100) / ethnicityValidTotal,
                1
            )
        };
        const twoOrMoreRaces = {
            label: 'Two Or More Races',
            value: precisionRound(
                (twoOrMoreRacesTotal * 100) / ethnicityValidTotal,
                1
            )
        };
        const white = {
            label: 'White',
            value: precisionRound((whiteTotal * 100) / ethnicityValidTotal, 1)
        };
        benchmark.push(
            indian,
            black,
            asian,
            hispanic,
            hawaiian,
            twoOrMoreRaces,
            white
        );
    }
    benchmark = sortBy(benchmark, b => b.label);
    return benchmark;
}

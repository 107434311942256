import * as React from 'react';
const SvgComponent = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M12 16.462c.174 0 .32-.06.438-.177a.595.595 0 0 0 .177-.439.596.596 0 0 0-.177-.438.596.596 0 0 0-.438-.177.596.596 0 0 0-.438.177.596.596 0 0 0-.177.438c0 .174.059.32.177.439a.595.595 0 0 0 .438.177Zm-.5-3.308h1v-6h-1v6ZM12.003 21a8.772 8.772 0 0 1-3.51-.709 9.094 9.094 0 0 1-2.859-1.922 9.085 9.085 0 0 1-1.925-2.857A8.753 8.753 0 0 1 3 12.003c0-1.244.236-2.414.708-3.51a9.094 9.094 0 0 1 1.924-2.859 9.085 9.085 0 0 1 2.856-1.925A8.753 8.753 0 0 1 11.997 3c1.244 0 2.414.236 3.51.708a9.094 9.094 0 0 1 2.859 1.924 9.083 9.083 0 0 1 1.925 2.856A8.753 8.753 0 0 1 21 11.997a8.772 8.772 0 0 1-.709 3.51 9.093 9.093 0 0 1-1.922 2.859 9.085 9.085 0 0 1-2.857 1.925 8.754 8.754 0 0 1-3.509.709ZM12 20c2.233 0 4.125-.775 5.675-2.325C19.225 16.125 20 14.233 20 12c0-2.233-.775-4.125-2.325-5.675C16.125 4.775 14.233 4 12 4c-2.233 0-4.125.775-5.675 2.325C4.775 7.875 4 9.767 4 12c0 2.233.775 4.125 2.325 5.675C7.875 19.225 9.767 20 12 20Z"
        />
    </svg>
);
export default SvgComponent;

import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { Animate } from 'react-move';
import Constants from '../constants/Constants';
import getInterpolator from '../lib/getInterpolator';

const DropdownMenu = ({
    marginLeft,
    height,
    options,
    selectedOption,
    perfectScrollbar,
    isOpen,
    openUp,
    getOption,
    onClick
}: any) => {
    const ref = useRef(null);
    const maxHeight = height || 368;
    const dropdownHeight = Math.min(options.length * 40 + 16, maxHeight);
    return (
        <Animate
            show={isOpen}
            start={() => ({
                opacity: 0.01,
                scale: 0.8
            })}
            enter={() => ({
                opacity: [1],
                scale: [1],
                timing: {
                    duration: Constants.AnimationDuration,
                    ease: Constants.EasingFn
                }
            })}
            leave={{
                opacity: [0.01],
                scale: [0.8],
                timing: {
                    duration: Constants.AnimationDuration,
                    ease: Constants.EasingFn
                }
            }}
            interpolation={getInterpolator}
        >
            {({ opacity, scale }) => {
                return (
                    <div
                        className="menu-outer"
                        style={{
                            marginLeft,
                            opacity,
                            transform: openUp
                                ? `translateY(-${dropdownHeight}px)`
                                : undefined
                        }}
                    >
                        <div
                            className="menu"
                            style={{
                                maxHeight,
                                overflowY:
                                    opacity == 1 && !perfectScrollbar
                                        ? 'auto'
                                        : 'hidden',
                                opacity,
                                transform: `scale(${scale})`
                            }}
                            ref={ref}
                        >
                            {options.map((option: any) => (
                                <div
                                    className={
                                        getOption(option, 'value') ===
                                        getOption(selectedOption, 'value')
                                            ? 'menu-item selected'
                                            : 'menu-item'
                                    }
                                    key={getOption(option, 'value')}
                                >
                                    <a
                                        href="#"
                                        onClick={e => {
                                            onClick(option, e);
                                        }}
                                        className={
                                            getOption(option, 'label')
                                                .length === 1
                                                ? 'circle'
                                                : ''
                                        }
                                    >
                                        {getOption(option, 'label')}
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                );
            }}
        </Animate>
    );
};

DropdownMenu.propTypes = {
    marginLeft: PropTypes.number,
    height: PropTypes.number,
    options: PropTypes.array,
    selectedOption: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    perfectScrollbar: PropTypes.bool,
    isOpen: PropTypes.bool,
    openUp: PropTypes.bool,
    getOption: PropTypes.func,
    onClick: PropTypes.func
};

export default DropdownMenu;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={80}
        height={80}
        viewBox="0 0 80 80"
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M14.083 67.917V62h8.75l-.416-.417c-3.611-3.11-6.292-6.375-8.042-9.791-1.75-3.417-2.625-7.264-2.625-11.542 0-6.556 2.07-12.375 6.208-17.458 4.14-5.084 9.43-8.43 15.875-10.042v8c-4.222 1.278-7.639 3.736-10.25 7.375-2.61 3.639-3.916 7.68-3.916 12.125 0 3.278.61 6.111 1.833 8.5 1.222 2.389 2.889 4.472 5 6.25l2 1.25V47.5h5.917v20.417H14.083Zm32.167-.584V59.25c4.278-1.278 7.694-3.736 10.25-7.375s3.833-7.68 3.833-12.125c0-2.444-.625-4.944-1.875-7.5a27.44 27.44 0 0 0-4.708-6.833l-1.917-1.667v8.75h-6V12.083H66.25V18h-8.917l.5.583c3.445 3.223 6.042 6.695 7.792 10.417 1.75 3.722 2.625 7.306 2.625 10.75 0 6.556-2.056 12.389-6.167 17.5-4.11 5.111-9.389 8.472-15.833 10.083Z"
        />
    </svg>
);
export default SvgComponent;

import { max, min } from 'd3-array';
import { scaleBand, scaleLinear } from 'd3-scale';
import produce from 'immer';
import { Fragment, useEffect, useRef, useState, useMemo } from 'react';
import { Animate, NodeGroup } from 'react-move';

import * as Tooltip from '@radix-ui/react-tooltip';
import ChartTooltip from '../../common/ChartTooltip';
import AnalysisTypes from '../../constants/AnalysisTypes';
import CanvasFonts from '../../constants/CanvasFonts';
import Constants from '../../constants/Constants';
import FontFamilies from '../../constants/FontFamilies';
import inlineFont from '../../constants/InlineFont';
import MixPanel from '../../constants/MixPanel';
import getFormattedValue from '../../lib/getFormattedValue';
import getInterpolator from '../../lib/getInterpolator';
import getPeriod from '../../lib/getPeriod';
import measureText from '../../lib/measureText';
import precisionRound from '../../lib/precisionRound';
import { track } from '../../lib/segment';
import cn from '../../lib/cn';

const AXIS_PADDING = 24;
const PADDING = 16;
const Y_SMALL_PADDING = 40;
const AXIS_FONT_SIZE = 11;
const TEXT_FONT_SIZE = 11;
const LARGE_TEXT_FONT_SIZE = 13;
const TOP_BAR_HEIGHT = 0; // was 3 before
const MIN_GROUP_SPACING = 48;
const ARROW_WIDTH = 6;
const ARROW_HEIGHT = 8;
const ARROW_CIRCLE_RADIUS = 12;

const barTypes = [
    {
        type: 'large',
        min: 48,
        max: 80
    },
    {
        type: 'medium',
        min: 34,
        max: 47
    },
    {
        type: 'small',
        min: 11,
        max: 33
    }
];

const pageSizes = [0, 6, 4, 2, 1];

function getFormattedDate(date, interval = 'Month') {
    return getPeriod(date, interval.toLowerCase());
}

export default function BarChart(props) {
    const {
        width,
        height,
        lifecycle,
        analysisType,
        dates,
        interval,
        currentIndex,
        setActiveLabel,
        clearActiveLabel,
        toggleLabel,
        selectCurrentTime,
        dateSelection,
        reportId,
        dashboardName,
        title,
        dataProp,
        yAxisType,
        indexType,
        employeeCountHidden,
        columnProps,
        lightMode,
        isResizing
    } = props;

    let { data } = props;
    const isActive = data.some(d => d.active || d.selected);
    const showIndexLine =
        isActive &&
        (analysisType === AnalysisTypes.LinearRegression ||
            analysisType === AnalysisTypes.Index) &&
        indexType === 'indexed';

    // optimize?
    const data2 = useMemo(() => {
        return data.filter(d => !d.isEqualityIndex && !d.isAttritionIndex);
    }, [data]);

    const isLinearRegression = analysisType === AnalysisTypes.LinearRegression;

    const [barType, setBarType] = useState();
    const [barWidth, setBarWidth] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const firstLoadRef = useRef({ firstLoad: true });

    // optimize?
    useEffect(() => {
        const barCount = data2.length;
        const availableWidth = width - 2 * PADDING;
        let configFound = false;
        pageSizes.forEach(ps => {
            if (configFound) return;
            if (ps > dates.length) return;
            barTypes.forEach((bt, j) => {
                if (configFound) return;
                const groupCount = ps === 0 ? dates.length : ps;
                const maxGraphWidth =
                    bt.max * barCount * groupCount +
                    (groupCount - 1) * MIN_GROUP_SPACING;
                const minGraphWidth =
                    bt.min * barCount * groupCount +
                    (groupCount - 1) * MIN_GROUP_SPACING;
                let barWidth;
                if (
                    j === 0 &&
                    AnalysisTypes.LinearRegression === analysisType &&
                    maxGraphWidth * 2 < availableWidth
                ) {
                    barWidth =
                        (availableWidth -
                            (groupCount - 1) * (MIN_GROUP_SPACING * 2)) /
                        (barCount * groupCount);
                    if (groupCount === 1) {
                        barWidth = barWidth / 2;
                    }

                    configFound = true;
                } else if (j === 0 && maxGraphWidth < availableWidth) {
                    barWidth = bt.max;
                    configFound = true;
                } else if (
                    minGraphWidth <= width &&
                    maxGraphWidth > availableWidth
                ) {
                    barWidth =
                        (availableWidth -
                            (groupCount - 1) * MIN_GROUP_SPACING) /
                        (barCount * groupCount);
                    configFound = true;
                }
                if (configFound) {
                    setBarWidth(barWidth);
                    setBarType(bt.type);
                    setPageSize(ps);
                }
            });
        });
    }, [width, dates.length, data2.length]);

    useEffect(() => {
        if (barWidth > 0) {
            firstLoadRef.current.firstLoad = false;
        }
    }, [barWidth]);

    function handleToggle(label, e) {
        track(MixPanel.Events.DashboardReportColumnGraphColumnSegmentClick, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
        toggleLabel(label, e);
    }

    function handleTimeClick(index, e) {
        e.stopPropagation();
        selectCurrentTime(index);
        track(MixPanel.Events.DashboardReportColumnGraphXAxisClick, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
    }

    function getBarOpacity(item) {
        const isActive = data2.some(pd => pd.active || pd.selected);
        if (isActive && !item.active && !item.selected) {
            return 0.2;
        }
        return 1;
    }

    function getTopBarOpacity(item) {
        const isActive = data2.some(pd => pd.active || pd.selected);
        if (isActive && !item.active && !item.selected) {
            return 0.15;
        }
        return 1;
    }

    function getTextOpacity(item) {
        const isActive = data2.some(pd => pd.active || pd.selected);
        if (isActive && !item.active && !item.selected) {
            return 0;
        }
        return 1;
    }

    function getTopBarHeight(item, height) {
        if (item.active || item.selected) {
            return height;
        }
        return TOP_BAR_HEIGHT;
    }

    function getLeftArrowPoints() {
        const x = pageSize !== 1 ? PADDING : width / 2 - 60;
        const y = height - PADDING - 10;
        return getPoints(x, y);
    }

    function getRightArrowPoints() {
        const x = pageSize !== 1 ? width - PADDING : width / 2 + 60;
        const y = height - PADDING - 10;
        return getPoints(x, y, true);
    }

    function getPoints(x, y, isRight) {
        const x2 = isRight ? x - ARROW_WIDTH : x + ARROW_WIDTH;
        return `${x},${y} ${x2},${y - ARROW_HEIGHT / 2} ${x2},${
            y + ARROW_HEIGHT / 2
        }`;
    }

    function getLeftCenterX() {
        const x = pageSize !== 1 ? PADDING : width / 2 - 60;
        return x + ARROW_WIDTH / 2;
    }

    function getRightCenterX() {
        const x = pageSize !== 1 ? width - PADDING : width / 2 + 60;
        return x - ARROW_WIDTH / 2;
    }

    function getCenterY() {
        return height - PADDING - 10;
    }

    function handlePrevious(e) {
        e.stopPropagation();
        if (startIndex === 0) return;
        setStartIndex(startIndex - 1);
        track(MixPanel.Events.DashboardReportColumnGraphTimeScrollClick, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
    }

    function handleNext(e) {
        e.stopPropagation();
        if (startIndex + pageSize >= dates.length) return;
        setStartIndex(startIndex + 1);
        track(MixPanel.Events.DashboardReportColumnGraphTimeScrollClick, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
    }

    function getYAxisValues(minValue, maxValue) {
        let dev = maxValue - minValue;
        if (dev === 0) {
            if (minValue === 0) {
                dev = 0.1;
            } else {
                dev = minValue * 0.1;
            }
        } else {
            dev = dev * 0.3;
        }
        let adjMinValue = minValue - dev;
        if (
            lifecycle !== 'Compensation' &&
            analysisType !== AnalysisTypes.LinearRegression
        ) {
            if (adjMinValue < 0) {
                adjMinValue = 0;
            }
        }
        let adjMaxValue = maxValue + dev;
        if (yAxisType === 'percentage' || indexType === 'percentage') {
            if (adjMaxValue > 100) {
                adjMaxValue = 100;
            }
        }
        return [adjMinValue, adjMaxValue];
    }

    const arrowColor = lightMode ? '#111112' : '#f2f2f2';
    const arrowColorDisabled = lightMode
        ? 'rgba(17,17,18,0.24)'
        : 'rgba(242,242,242,0.24)';

    const paginatedData = useMemo(() => {
        if (pageSize === 0) return data2;
        return produce(data2, draftState => {
            draftState.forEach(item => {
                item.series = item.series.slice(
                    startIndex,
                    startIndex + pageSize
                );
            });
        });
    }, [data2, startIndex, pageSize]);

    const paginatedDates = useMemo(() => {
        if (pageSize === 0) return dates;
        return dates.slice(startIndex, startIndex + pageSize);
    }, [dates, startIndex, pageSize]);

    const { xScale, yScale } = useMemo(() => {
        const xScale = scaleBand()
            .domain(paginatedDates)
            .range([PADDING, width - PADDING]);

        let minArray = [];
        let maxArray = [];
        data.forEach(d => {
            minArray.push(min(d.series, s => s[dataProp]));
            maxArray.push(max(d.series, s => s[dataProp]));
        });
        minArray = minArray.filter(d => d !== Constants.InvalidValue);
        maxArray = maxArray.filter(d => d !== Constants.InvalidValue);
        const minValue = Math.floor(min(minArray));
        const maxValue = Math.ceil(max(maxArray));
        const yAxisValues = getYAxisValues(minValue, maxValue);

        const yScale = scaleLinear()
            .domain(yAxisValues)
            .range([
                height - PADDING - AXIS_PADDING - TOP_BAR_HEIGHT,
                barType === 'small' ? Y_SMALL_PADDING : PADDING
            ]);

        return { xScale, yScale };
    }, [
        paginatedDates,
        data,
        width,
        height,
        barType,
        PADDING,
        dataProp,
        AXIS_PADDING,
        TOP_BAR_HEIGHT,
        Y_SMALL_PADDING
    ]);

    const selectedDate = getFormattedDate(dates[currentIndex], interval);

    if (barWidth === 0 || height === 0) {
        return null;
    }

    return (
        <Tooltip.Provider>
            <svg
                className={cn('barchart')}
                data-export-type="barchart"
                width={width}
                height={height}
                id={reportId}
            >
                <defs>
                    <style type="text/css">{inlineFont}</style>
                </defs>
                {analysisType === AnalysisTypes.LinearRegression && (
                    <rect
                        className="barchart__linear-regression-background"
                        x={0}
                        y={yScale(0)}
                        width={width}
                        height={height - yScale(0)}
                    />
                )}
                {analysisType === AnalysisTypes.Index &&
                    indexType === 'indexed' && (
                        <rect
                            className="barchart__index-background"
                            x={0}
                            y={yScale(1)}
                            width={width}
                            height={height - yScale(1)}
                            fill="#1f1f21"
                        />
                    )}
                {paginatedData.map((d, i) => (
                    <g key={d.label} className="barchart__bar">
                        <NodeGroup
                            keyAccessor={d2 => `${d.label}_${d2.date}`}
                            data={d.series.slice()}
                            start={d2 => {
                                let x =
                                    xScale(d2.date) +
                                    xScale.bandwidth() / 2 -
                                    (data2.length * barWidth) / 2 +
                                    i * barWidth;
                                if (isNaN(x)) {
                                    x = -100;
                                }
                                return {
                                    x,
                                    y: isLinearRegression
                                        ? yScale(0)
                                        : height - PADDING - AXIS_PADDING,
                                    height: 0,
                                    topBarHeight: 0,
                                    barOpacity: 1,
                                    topBarOpacity: 0,
                                    textOpacity: 0
                                };
                            }}
                            enter={d2 => {
                                const delay = firstLoadRef.current.firstLoad
                                    ? 0
                                    : Constants.AnimationDuration;
                                let x =
                                    xScale(d2.date) +
                                    xScale.bandwidth() / 2 -
                                    (data2.length * barWidth) / 2 +
                                    i * barWidth;
                                if (isNaN(x)) {
                                    x = -100;
                                }
                                const barValue = precisionRound(
                                    d2[dataProp],
                                    1
                                );

                                const isValueNegative = barValue < 0;
                                const isValueZero = barValue === 0;

                                const startY = isValueZero
                                    ? yScale(0) - 1
                                    : isLinearRegression
                                    ? isValueNegative
                                        ? yScale(0)
                                        : yScale(barValue)
                                    : yScale(barValue);

                                const barHeight = isValueZero
                                    ? 1
                                    : isLinearRegression
                                    ? isValueNegative
                                        ? yScale(barValue) - yScale(0)
                                        : yScale(0) - yScale(barValue)
                                    : height - PADDING - AXIS_PADDING - startY;

                                return [
                                    {
                                        x: [x],
                                        y: [startY],
                                        height: [barHeight],
                                        topBarHeight: [
                                            getTopBarHeight(d, barHeight)
                                        ],
                                        topBarOpacity: [getTopBarOpacity(d)],
                                        timing: {
                                            delay: delay,
                                            duration:
                                                Constants.AnimationDuration,
                                            ease: Constants.EasingFn
                                        }
                                    },
                                    {
                                        textOpacity: [getTextOpacity(d)],
                                        timing: {
                                            delay: Constants.AnimationDuration,
                                            duration:
                                                Constants.AnimationDuration,
                                            ease: Constants.EasingFn
                                        }
                                    }
                                ];
                            }}
                            update={d2 => {
                                let x =
                                    xScale(d2.date) +
                                    xScale.bandwidth() / 2 -
                                    (data2.length * barWidth) / 2 +
                                    i * barWidth;
                                if (isNaN(x)) {
                                    x = -100;
                                }
                                const barValue = precisionRound(
                                    d2[dataProp],
                                    1
                                );

                                const isValueNegative = barValue < 0;
                                const isValueZero = barValue === 0;

                                const startY = isValueZero
                                    ? yScale(0) - 1
                                    : isLinearRegression
                                    ? isValueNegative
                                        ? yScale(0)
                                        : yScale(barValue)
                                    : yScale(barValue);

                                const barHeight = isValueZero
                                    ? 1
                                    : isLinearRegression
                                    ? isValueNegative
                                        ? yScale(barValue) - yScale(0)
                                        : yScale(0) - yScale(barValue)
                                    : height - PADDING - AXIS_PADDING - startY;

                                return [
                                    {
                                        x: [x],
                                        y: [startY],
                                        height: [barHeight],
                                        topBarHeight: [
                                            getTopBarHeight(d, barHeight)
                                        ],
                                        barOpacity: [getBarOpacity(d)],
                                        topBarOpacity: [getTopBarOpacity(d)],
                                        textOpacity: [getTextOpacity(d)],
                                        timing: {
                                            duration:
                                                Constants.AnimationDuration,
                                            ease: Constants.EasingFn
                                        }
                                    }
                                ];
                            }}
                            interpolation={getInterpolator}
                        >
                            {nodes => (
                                <Fragment>
                                    {nodes.map(({ state, data, key }) => {
                                        if (
                                            d.isEqualityIndex ||
                                            d.isAttritionIndex ||
                                            data[dataProp] ===
                                                Constants.InvalidValue
                                        ) {
                                            return null;
                                        }

                                        let formattedText;
                                        let xText;
                                        let yText;
                                        let translateX;
                                        let showText = false;
                                        const isValueNegative =
                                            data[dataProp] < 0;
                                        if (
                                            (barType !== 'small' &&
                                                !isResizing) ||
                                            d.active ||
                                            d.selected
                                        ) {
                                            showText = true;
                                            formattedText =
                                                yAxisType === 'count'
                                                    ? employeeCountHidden
                                                        ? ''
                                                        : data.totalCount ||
                                                          data.includedCount
                                                    : getFormattedValue(
                                                          indexType ===
                                                              'percentage'
                                                              ? AnalysisTypes.Percentage
                                                              : analysisType,
                                                          data[dataProp]
                                                      );

                                            xText =
                                                isLinearRegression &&
                                                isValueNegative
                                                    ? state.x + barWidth - 2
                                                    : state.x + 2;
                                            yText =
                                                yScale(
                                                    precisionRound(
                                                        data[dataProp],
                                                        1
                                                    )
                                                ) +
                                                (isLinearRegression &&
                                                isValueNegative
                                                    ? 12
                                                    : -4);
                                            translateX =
                                                (barWidth + TEXT_FONT_SIZE) /
                                                    2 -
                                                4;
                                        }

                                        const paleBarHeight =
                                            state.height - TOP_BAR_HEIGHT;

                                        return (
                                            <Fragment key={key}>
                                                {paleBarHeight > 0 && (
                                                    <rect
                                                        width={barWidth - 2}
                                                        x={state.x}
                                                        y={
                                                            state.y +
                                                            TOP_BAR_HEIGHT
                                                        }
                                                        height={paleBarHeight}
                                                        fill={d.color}
                                                        opacity={
                                                            state.barOpacity
                                                        }
                                                    />
                                                )}
                                                <Tooltip.Root
                                                    delayDuration={0}
                                                    open={
                                                        d.active && state.active
                                                    }
                                                >
                                                    <Tooltip.Trigger asChild>
                                                        <g>
                                                            <rect
                                                                width={
                                                                    barWidth - 2
                                                                }
                                                                x={state.x}
                                                                y={state.y}
                                                                height={
                                                                    state.topBarHeight
                                                                }
                                                                fill={d.color}
                                                                opacity={
                                                                    state.topBarOpacity
                                                                }
                                                            />

                                                            <rect
                                                                data-label="tooltip-trigger"
                                                                width={
                                                                    barWidth - 2
                                                                }
                                                                x={state.x}
                                                                y={state.y}
                                                                height={
                                                                    state.height
                                                                }
                                                                fill="transparent"
                                                                cursor="pointer"
                                                                onMouseOver={setActiveLabel.bind(
                                                                    null,
                                                                    d.label
                                                                )}
                                                                onMouseOut={clearActiveLabel.bind(
                                                                    null,
                                                                    d.label
                                                                )}
                                                                onClick={handleToggle.bind(
                                                                    null,
                                                                    d.label
                                                                )}
                                                            />
                                                        </g>
                                                    </Tooltip.Trigger>
                                                    <Tooltip.Portal>
                                                        <Tooltip.Content
                                                            side="bottom"
                                                            sideOffset={16}
                                                        >
                                                            <ChartTooltip
                                                                dataPoint={d}
                                                                seriesItem={
                                                                    data
                                                                }
                                                                columnProps={
                                                                    columnProps
                                                                }
                                                                interval={
                                                                    interval
                                                                }
                                                            />
                                                        </Tooltip.Content>
                                                    </Tooltip.Portal>
                                                </Tooltip.Root>

                                                {showText && (
                                                    <text
                                                        width={barWidth}
                                                        x={xText}
                                                        y={yText}
                                                        textAnchor={
                                                            isLinearRegression &&
                                                            isValueNegative
                                                                ? 'end'
                                                                : 'start'
                                                        }
                                                        height={state.height}
                                                        transform={
                                                            barType === 'small'
                                                                ? `rotate(-90, ${xText}, ${yText}) translate(0 ${translateX})`
                                                                : ''
                                                        }
                                                        style={{
                                                            fontFamily:
                                                                FontFamilies.Regular,
                                                            fontSize:
                                                                barType ===
                                                                'large'
                                                                    ? LARGE_TEXT_FONT_SIZE
                                                                    : TEXT_FONT_SIZE
                                                        }}
                                                        fill="var(--color-ui-100)"
                                                        opacity={
                                                            state.textOpacity
                                                        }
                                                        cursor="pointer"
                                                        onMouseOver={setActiveLabel.bind(
                                                            null,
                                                            d.label
                                                        )}
                                                        onMouseOut={clearActiveLabel.bind(
                                                            null,
                                                            d.label
                                                        )}
                                                        onClick={handleToggle.bind(
                                                            null,
                                                            d.label
                                                        )}
                                                    >
                                                        {formattedText}
                                                    </text>
                                                )}
                                            </Fragment>
                                        );
                                    })}
                                </Fragment>
                            )}
                        </NodeGroup>
                    </g>
                ))}
                <Animate
                    show={showIndexLine}
                    start={() => ({
                        opacity: 0
                    })}
                    enter={() => ({
                        opacity: [1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    update={() => ({
                        opacity: [1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    leave={() => ({
                        opacity: [0],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                >
                    {state => {
                        const indexText =
                            analysisType === AnalysisTypes.LinearRegression
                                ? 'Index (0%)'
                                : 'Index (1x)';
                        const yIndex =
                            analysisType === AnalysisTypes.LinearRegression
                                ? yScale(0)
                                : yScale(1);
                        const xIndex =
                            PADDING +
                            measureText(indexText, CanvasFonts.Medium13).width +
                            8;

                        return (
                            <Fragment>
                                <text
                                    className="barchart__index-text"
                                    x={PADDING}
                                    y={yIndex + 3}
                                    opacity={state.opacity}
                                >
                                    {indexText}
                                </text>
                                <line
                                    className="barchart__index-line"
                                    x1={xIndex}
                                    y1={yIndex}
                                    x2={width - PADDING}
                                    y2={yIndex}
                                    opacity={state.opacity}
                                />
                            </Fragment>
                        );
                    }}
                </Animate>
                <line
                    className="barchart__axis-line"
                    x1={PADDING}
                    y1={height - AXIS_PADDING - PADDING}
                    x2={width - PADDING}
                    y2={height - AXIS_PADDING - PADDING}
                />
                <NodeGroup
                    keyAccessor={d => d}
                    data={paginatedDates.slice()}
                    start={data => {
                        let x = xScale(data) + xScale.bandwidth() / 2;
                        return {
                            opacity: 0,
                            x
                        };
                    }}
                    enter={data => {
                        const formattedDate = getFormattedDate(data, interval);
                        const selected =
                            dateSelection && selectedDate === formattedDate;

                        return {
                            opacity: [selected ? 1 : 0.7],
                            timing: {
                                delay: Constants.AnimationDuration,
                                duration: Constants.AnimationDuration,
                                ease: Constants.EasingFn
                            }
                        };
                    }}
                    update={data => {
                        const formattedDate = getFormattedDate(data, interval);
                        const selected =
                            dateSelection && selectedDate === formattedDate;

                        return {
                            opacity: [selected ? 1 : 0.7],
                            x: [xScale(data) + xScale.bandwidth() / 2],
                            timing: {
                                duration: Constants.AnimationDuration,
                                ease: Constants.EasingFn
                            }
                        };
                    }}
                    interpolation={getInterpolator}
                >
                    {nodes => (
                        <g className="barchart__x-labels">
                            {nodes.map(({ state, data, key }, index) => {
                                const formattedDate = getFormattedDate(
                                    data,
                                    interval
                                );
                                const selected =
                                    dateSelection &&
                                    selectedDate === formattedDate;
                                const clickable =
                                    dateSelection &&
                                    selectedDate !== formattedDate;

                                return (
                                    <text
                                        className={cn(
                                            'barchart__x-labels__text',
                                            selected &&
                                                'barchart__x-labels__text--selected'
                                        )}
                                        key={key}
                                        x={state.x}
                                        y={
                                            height -
                                            PADDING -
                                            0.5 * AXIS_FONT_SIZE
                                        }
                                        textAnchor="middle"
                                        opacity={state.opacity}
                                        onClick={
                                            clickable
                                                ? e => handleTimeClick(index, e)
                                                : e => e.stopPropagation()
                                        }
                                    >
                                        {formattedDate}
                                    </text>
                                );
                            })}
                        </g>
                    )}
                </NodeGroup>
                {pageSize > 0 && (
                    <Fragment>
                        <polygon
                            points={getLeftArrowPoints()}
                            fill={
                                startIndex === 0
                                    ? arrowColorDisabled
                                    : arrowColor
                            }
                        />
                        <circle
                            cx={getLeftCenterX()}
                            cy={getCenterY()}
                            r={ARROW_CIRCLE_RADIUS}
                            cursor={startIndex === 0 ? 'default' : 'pointer'}
                            fill="transparent"
                            onClick={handlePrevious}
                        />
                        <polygon
                            points={getRightArrowPoints()}
                            fill={
                                startIndex + pageSize === dates.length
                                    ? arrowColorDisabled
                                    : arrowColor
                            }
                        />
                        <circle
                            cx={getRightCenterX()}
                            cy={getCenterY()}
                            r={ARROW_CIRCLE_RADIUS}
                            cursor={
                                startIndex + pageSize === dates.length
                                    ? 'default'
                                    : 'pointer'
                            }
                            fill="transparent"
                            onClick={handleNext}
                        />
                    </Fragment>
                )}
            </svg>
        </Tooltip.Provider>
    );
}

BarChart.defaultProps = {
    width: 600,
    height: 256
};

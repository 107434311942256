import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { openBenchmarkModal } from '../../benchmark/actions';
import { toggleComments } from '../../comments/actions';
import ChartTypes from '../../constants/ChartTypes';
import MixPanel from '../../constants/MixPanel';
import {
    closeEventsModal,
    openEventsModal,
    removeBenchmark,
    setBenchmark,
    setChartType,
    setHidePie
} from '../../explore/chart/actions';
import BarChartHorizontal from '../../icons/BarChartHorizontal';
import BarSelected from '../../icons/BarSelected';
import Line from '../../icons/Line';
import LineSelected from '../../icons/LineSelected';
import Pie from '../../icons/Pie';
import PieSelected from '../../icons/PieSelected';
import StackedBar from '../../icons/StackedBar';
import { IChartHeaderProps } from '../../lib/getChartDeclaration';
import getPeriod from '../../lib/getPeriod';
import { track } from '../../lib/segment';
import { showInfo } from '../actions';
import Chart from './Chart';

export const ChartHeaderContext = React.createContext({});

interface ChartHeaderProps extends IChartHeaderProps {
    showInfo: any;
    setBenchmark: any;
    removeBenchmark: any;
    openBenchmarkModal: any;
    setHidePie: any;
    interval: string;
    setChartType: any;
    subAnalysisFields: any;
    subAnalysisMapping: any;
    closeEventsModal: any;
    openEventsModal: any;
    eventsModalOpen: any;
    subtableChartType: string;
}

const ChartHeader = React.forwardRef<HTMLDivElement, ChartHeaderProps>(
    (props, forwardedRef) => {
        const {
            showBenchmarkIcon,
            setBenchmarkEdit,
            setBenchmarkToEdit,
            showInfo,
            setBenchmark,
            removeBenchmark,
            openBenchmarkModal,
            setHidePie,
            lifecycle,
            analysisType,
            data,
            dates,
            currentIndex,
            interval,
            benchmark,
            exportChart,
            exportAsCSV,
            setChartType,
            columnProps,
            chartType,
            subAnalysisFields,
            subAnalysisMapping,
            closeEventsModal,
            openEventsModal,
            eventsModalOpen,
            showSubtable,
            subtable,
            enterpriseName,
            exportDetailData,
            exportDetailDataAsCsv,
            subtableChartType
        } = props;
        const [moreKeys, setMoreKeys] = useState([]);
        const [moreOpen, setMoreOpen] = useState(false);
        const [benchmarkMenuOpen, setBenchmarkMenuOpen] = useState(false);
        const fallbackRef = useRef<HTMLDivElement>(null);

        let ref = forwardedRef || fallbackRef;

        const chartTypes = [
            {
                chartType: 'Line',
                activeIcon: LineSelected,
                inactiveIcon: Line
            },
            {
                chartType: 'Bar',
                activeIcon: BarSelected,
                inactiveIcon: BarChartHorizontal
            },
            {
                chartType: 'Pie',
                activeIcon: PieSelected,
                inactiveIcon: Pie
            },
            {
                chartType: 'StackedBar',
                activeIcon: StackedBar,
                inactiveIcon: StackedBar
            }
        ];

        function handleMoreClick() {
            setMoreOpen(!moreOpen);
            if (!moreOpen) {
                setMoreKeys([]);
            }
        }

        function handleShowPieChange(showPie: boolean) {
            setHidePie(!showPie);
            setBenchmarkMenuOpen(false);
        }

        function handleChartTypeChange(chartType: string) {
            track(MixPanel.Events.ExploreSelectChartType, {
                'Chart Type': chartType
            });
            setChartType(chartType);
        }

        function handleBenchmarkCreate() {
            track(MixPanel.Events.ExploreCreateBenchmark);
            openBenchmarkModal();
            setBenchmarkMenuOpen(false);
            setBenchmarkEdit(false);
        }

        function handleBenchmarkEdit(benchmarkToEdit: any) {
            track(MixPanel.Events.ExploreEditBenchmark, {
                'Benchmark Name': benchmarkToEdit.benchmarkName
            });
            setBenchmarkToEdit(benchmarkToEdit);
            openBenchmarkModal();
            setBenchmarkMenuOpen(false);
            setBenchmarkEdit(true);
        }

        function handleBenchmarkClick() {
            setBenchmarkMenuOpen(!benchmarkMenuOpen);
        }

        function handleBenchmarkSelect(benchmark: any) {
            track(MixPanel.Events.ExploreSelectBenchmark, {
                'Benchmark Name': benchmark.benchmarkName
            });
            setBenchmarkMenuOpen(false);
            setBenchmark(benchmark);
        }

        function handleBenchmarkRemove() {
            track(MixPanel.Events.ExploreRemoveBenchmark);
            setBenchmarkMenuOpen(false);
            removeBenchmark();
        }

        function handleMoreMenuSelect({ selectedKeys }: any) {
            setMoreKeys(selectedKeys);
        }

        function handleExport() {
            track(MixPanel.Events.ExploreExport);
            if (showSubtable && chartType === ChartTypes.Matrix) {
                const {
                    date,
                    interval,
                    data,
                    summary,
                    groupSummary,
                    lifecycle,
                    analysisType,
                    columnProps,
                    attributeName,
                    diversityAttributes,
                    sortCriteria,
                    expanded
                } = subtable;
                const period = getPeriod(date, interval);
                exportDetailData(
                    enterpriseName,
                    period,
                    data,
                    summary,
                    groupSummary,
                    null,
                    lifecycle,
                    analysisType,
                    columnProps,
                    attributeName,
                    diversityAttributes,
                    sortCriteria,
                    expanded
                );
            } else {
                exportChart({
                    lifecycle,
                    analysisType,
                    columnProps,
                    data,
                    dates,
                    currentIndex,
                    interval,
                    chartType: showSubtable ? subtableChartType : chartType,
                    cb: () => {
                        setMoreOpen(false);
                        setMoreKeys([]);
                    }
                });
            }
        }

        function handleExportAsCSV() {
            track(MixPanel.Events.ExploreExportAsCSV);
            if (showSubtable) {
                const {
                    date,
                    interval,
                    data,
                    summary,
                    groupSummary,
                    lifecycle,
                    analysisType,
                    columnProps,
                    attributeName,
                    diversityAttributes
                } = subtable;
                const period = getPeriod(date, interval);
                exportDetailDataAsCsv(
                    data,
                    summary,
                    groupSummary,
                    lifecycle,
                    analysisType,
                    columnProps,
                    attributeName,
                    diversityAttributes,
                    period
                );
            } else {
                const period = getPeriod(dates[currentIndex], interval);
                exportAsCSV({
                    lifecycle,
                    analysisType,
                    columnProps,
                    data,
                    currentIndex,
                    period,
                    benchmark,
                    chartType,
                    subAnalysisFields,
                    subAnalysisMapping
                });
            }
            setMoreOpen(false);
            setMoreKeys([]);
        }

        function handleInfo() {
            track(MixPanel.Events.ExploreInfo);
            showInfo(lifecycle, analysisType, false);
            setMoreOpen(false);
            setMoreKeys([]);
        }

        function toggleEventsModal() {
            if (eventsModalOpen) {
                closeEventsModal();
            } else {
                openEventsModal();
                setMoreOpen(false);
            }
        }

        return (
            <ChartHeaderContext.Provider
                value={{
                    ...props,
                    benchmarkMenuOpen,
                    handleBenchmarkClick,
                    handleBenchmarkSelect,
                    handleBenchmarkRemove,
                    handleBenchmarkCreate,
                    handleBenchmarkEdit,
                    handleShowPieChange,
                    handleChartTypeChange,
                    handleMoreClick,
                    handleMoreMenuSelect,
                    handleExport,
                    handleExportAsCSV,
                    handleInfo,
                    benchmark,
                    moreOpen,
                    moreKeys,
                    chartTypes,
                    showBenchmarkIcon,
                    toggleEventsModal
                }}
            >
                <div className="chart-header-wrapper" ref={ref}>
                    <Chart>
                        <Chart.Title>{lifecycle}</Chart.Title>
                        <Chart.Confidence />
                        <Chart.Actions />
                    </Chart>
                </div>
            </ChartHeaderContext.Provider>
        );
    }
);

ChartHeader.displayName = 'ChartHeader';

function mapState(state: any) {
    const { showSubtable } = state.explore.filter;
    let { lifecycle, subAnalysisFields, subAnalysisMapping } =
        state.explore.chart;
    const { lifecycle: lifecycleSubtable } = state.explore.subtable;
    if (showSubtable) {
        lifecycle = lifecycleSubtable;
    }
    return {
        interval: state.explore.filter.interval,
        memberSince: state.account.memberSince,
        ...state.chart,
        commentCount: state.comments.commentCount,
        infoModalProps: state.infoModal,
        isFilterOpen: state.explore.filter.open,
        isEmptyState:
            !state.explore.filter.analysisName &&
            state.explore.filter.mainSegments.length === 0,
        deleteBenchmarkModalOpen: state.deleteModal.open,
        deleteBenchmarkPending: state.deleteModal.pending,
        isBenchmarkModalOpen: state.benchmarkModal.isOpen,
        showSubtable,
        lifecycle,
        subAnalysisFields,
        subAnalysisMapping,
        eventsModalOpen: state.explore.chart.eventsModalOpen
    };
}

const dispatchProps = {
    showInfo,
    toggleComments,
    setChartType,
    setBenchmark,
    removeBenchmark,
    openBenchmarkModal,
    setHidePie,
    openEventsModal,
    closeEventsModal
};

export default connect(mapState, dispatchProps, null, {
    forwardRef: true
})(ChartHeader);

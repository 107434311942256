// import '../../../../../../../sass/helpers/_variables.scss';
import { IColor } from './types';

const allColors: IColor[] = [
    { name: 'default', color: 'var(--color-ui-100)' },
    { name: 'orange', color: 'var(--color-graph-1)' },
    { name: 'yellow', color: 'var(--color-graph-2)' },
    { name: 'purple', color: 'var(--color-graph-3)' },
    { name: 'pink', color: 'var(--color-graph-4)' },
    { name: 'blue', color: 'var(--color-graph-5)' },
    { name: 'teal', color: 'var(--color-graph-6)' }
];

export default allColors;

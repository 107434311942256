interface Props {
    onClick: (e?: any) => void;
    state?: 'default' | 'hoverExists' | 'selectionExists' | 'readOnly';
    selected?: boolean;
    isHovering?: boolean;
    readOnly?: boolean;
    seeminglyDisabled?: boolean;
    children?: any;
    style?: any;
    [key: string]: any;
}

function getOpacity(
    state: 'default' | 'hoverExists' | 'selectionExists' | 'readOnly',
    selected?: boolean,
    isHovering?: boolean
) {
    if (selected) return 1;

    switch (state) {
        case 'default':
            return 1;
        case 'hoverExists':
            return isHovering ? 1 : 0.4;
        case 'selectionExists':
            return selected ? 1 : isHovering ? 1 : 0.4;
        case 'readOnly':
            return selected ? 1 : 0.4;
        default:
            return 1;
    }
}

const InfoCard = ({
    onClick,
    state,
    selected,
    isHovering,
    seeminglyDisabled,
    style,
    readOnly,
    children,
    ...rest
}: Props) => {
    return (
        <div
            className="info-card"
            style={{
                height: '100%',
                transform: isHovering && !readOnly ? 'scale(1.01)' : 'scale(1)',
                transition: 'transform 0.15s ease-in-out'
            }}
            onClick={e => (readOnly ? {} : onClick(e))}
            {...rest}
        >
            <div
                style={{
                    borderRadius: '.8rem',
                    background: 'var(--color-shade-3)',
                    position: 'relative',
                    overflow: 'hidden',
                    height: '100%',
                    cursor: readOnly ? 'not-allowed' : 'pointer',
                    boxShadow: selected
                        ? '0px 0px 0px 1px var(--color-ui-100)'
                        : 'none',
                    opacity: getOpacity(
                        state || 'default',
                        selected,
                        isHovering
                    ),
                    transition: 'opacity 0.1s ease-in-out  ',
                    ...style
                }}
            >
                {children}
            </div>
        </div>
    );
};

export default InfoCard;

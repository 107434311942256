export default function getTopLeftAction(pathname: string, search: string) {
    let type: 'default' | 'back' | 'exit' = 'default';
    let navigateTo: string | undefined;

    const inDashboard = /\/dashboard\/.*/.test(pathname); // /\/dashboard\/.*/
    const inGoal = /\/measure\/.*/.test(pathname);
    const inCreateRole = /\/admin\/create-role/.test(pathname);
    const inEditRole = /\/admin\/edit-role\/.*/.test(pathname);
    const inDuplicateRole = /\/admin\/duplicate-role/.test(pathname);
    const inGroupUsers = /\admin\/groups\/.*/.test(pathname);
    const isSurveyTemplates = /\/self-id\/survey-templates/.test(pathname);
    const isCampaign = /\/self-id\/campaigns\/view\/.*/.test(pathname);
    const isCampaignEdit =
        /\/self-id\/campaigns\/edit(\/.*|\?.*)?/.test(pathname) ||
        /\/self-id\/campaigns\/create(\/.*|\?.*)?/.test(pathname);

    type =
        search ||
        inDashboard ||
        inGoal ||
        inCreateRole ||
        inEditRole ||
        inDuplicateRole ||
        inGroupUsers ||
        isSurveyTemplates ||
        isCampaign
            ? 'back'
            : type;

    if (isCampaignEdit) {
        type = 'exit';
        navigateTo = '/self-id/campaigns';
    }

    return {
        type,
        navigateTo
    };
}

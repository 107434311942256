import axios from 'axios';
import camelCase from 'lodash/camelCase';
import sortBy from 'lodash/sortBy';
import moment from 'moment';
import * as uuid from 'uuid';

import ActionTypes from '../constants/ActionTypes';
import analysisTypes from '../constants/AnalysisTypes';
import Constants from '../constants/Constants';
import GridConstants from '../constants/GridConstants';
import Urls from '../constants/Urls';
import arrifyObject from '../lib/arrifyObject';
import errorHandler from '../lib/errorHandler';
import getDates from '../lib/getDates';
import getMixPanelObjectForFilters from '../lib/getMixPanelObjectForFilters';
import hasHiddenEmployeeCount from '../lib/hasHiddenEmployeeCount';
import { track } from '../lib/segment';
import { getSegmentsFromV2Report } from './commonUtils';

export function getAllSegments({
    mainSegments,
    filterSegment,
    breakdownSegment,
    employeeAttribute
}: {
    mainSegments: any;
    filterSegment?: any;
    breakdownSegment?: any;
    employeeAttribute?: any;
}) {
    const allSegments = mainSegments.slice();
    if (filterSegment) allSegments.push(filterSegment);
    if (breakdownSegment) allSegments.push(breakdownSegment);
    if (employeeAttribute)
        allSegments.push({
            name: employeeAttribute,
            values: []
        });

    return allSegments;
}

function getFoundByVariations({
    variations,
    filterLength,
    mainSegments,
    filterSegment,
    breakdownSegment,
    employeeAttribute
}: {
    variations: any;
    filterLength: number;
    mainSegments: any;
    filterSegment?: any;
    breakdownSegment?: any;
    employeeAttribute?: any;
}) {
    let fieldObject: any = {};
    let found = false;
    for (let i = 0; i < variations.length; i++) {
        const variation = variations[i];
        const variationItems = variation.filter(Boolean);
        if (variationItems.length === filterLength) {
            fieldObject = {};
            found = true;
            variation.forEach((variationItem: any, index: number) => {
                if (variationItem && found) {
                    if (index < 3) {
                        const field = mainSegments.find(
                            (f: any) =>
                                f.name.replace(/ /g, '').toLowerCase() ===
                                variationItem.toLowerCase()
                        );
                        if (!field) {
                            found = false;
                        } else {
                            const fieldProp = `field${index + 1}`;
                            fieldObject[fieldProp] = {
                                fieldName: variationItem,
                                fieldValues: field.values
                            };
                        }
                    }
                    if (found && index === 3) {
                        let fourthSegment = filterSegment || breakdownSegment;
                        fourthSegment =
                            (fourthSegment && fourthSegment.name) ||
                            employeeAttribute;
                        if (fourthSegment) {
                            found =
                                fourthSegment
                                    .replace(/ /g, '')
                                    .toLowerCase() ===
                                variationItem.toLowerCase();
                            if (filterSegment) {
                                fieldObject.field4 = {
                                    fieldName: variationItem,
                                    fieldValues: filterSegment.values
                                };
                            } else if (
                                breakdownSegment &&
                                breakdownSegment.values.length === 1
                            ) {
                                fieldObject.field4 = {
                                    fieldName: variationItem,
                                    fieldValues: breakdownSegment.values
                                };
                            }
                        } else {
                            found = variationItem === '';
                        }
                    }
                    if (found && index === 4) {
                        const fifthSegment =
                            (breakdownSegment && breakdownSegment.name) ||
                            employeeAttribute;
                        if (fifthSegment) {
                            found =
                                fifthSegment.replace(/ /g, '').toLowerCase() ===
                                variationItem.toLowerCase();
                            if (
                                breakdownSegment &&
                                breakdownSegment.values.length === 1
                            ) {
                                fieldObject.field5 = {
                                    fieldName: variationItem,
                                    fieldValues: breakdownSegment.values
                                };
                            }
                        } else {
                            found = variationItem === '';
                        }
                    }
                }
            });
            if (found) break;
        }
    }
    return { fieldObject, found };
}

interface GenerateMixPanelProp {
    invalidVariation?: string;
    noData?: string;
}

export function generate(
    getState: any,
    dispatch: any,
    state: any,
    actionTypes: any,
    mixpanel: GenerateMixPanelProp = {}
) {
    const { enterpriseId, latestDataUpdate } = getState().account;
    const { dynamicPermissions } = getState().auth;
    const { mode } = getState().appState;

    const {
        analysisDisplayName,
        analysisName,
        attributes,
        benchmark,
        chartData,
        chartType,
        dashboardId,
        employeeAttribute,
        endDate,
        eventIds,
        indexType,
        intersectionAttributes,
        interval,
        intervalCount,
        isFilterApplied,
        reportId,
        startDate,
        subAnalyses,
        subtableChartType,
        yAxisType
    } = state;

    if (getState().reports[reportId]?.generatePending) {
        return;
    }

    let { breakdownSegment, date, filterSegment, mainSegments } = state;

    const employeeCountHidden = hasHiddenEmployeeCount(dynamicPermissions);

    let isSubtable =
        (breakdownSegment && breakdownSegment.values.length > 1) ||
        employeeAttribute;

    return dispatch(getTemplateFromAnalysisName(analysisName)).then(
        (template: any) => {
            const {
                engineName,
                mainColumn,
                analysisType,
                variations,
                supportedGraphs,
                uniqueName,
                subAnalysisMapping,
                uiFieldMapping: {
                    analysisMetaFields,
                    displayFields,
                    subAnalysisFields
                }
            } = template;

            const shouldGetPrimaryData =
                isSubtable &&
                engineName === 'Percentage' &&
                ['total', 'included'].includes(mainColumn);

            const allowYAxisToggle =
                ['total', 'included'].includes(mainColumn) &&
                !employeeCountHidden;
            const allowIndexToggle = analysisType === analysisTypes.Index;

            let columnProps: any[] = [];
            Object.keys(analysisMetaFields).forEach(fieldKey => {
                columnProps.push({
                    header: fieldKey,
                    ...analysisMetaFields[fieldKey]
                });
            });

            columnProps = sortBy(columnProps, columnProp =>
                parseInt(columnProp.order)
            );

            // allSegments for mixpanel tracking
            let allSegments = getAllSegments({
                mainSegments,
                filterSegment,
                breakdownSegment,
                employeeAttribute
            });

            let filterLength = allSegments.length;

            let { fieldObject, found } = getFoundByVariations({
                variations,
                filterLength,
                mainSegments,
                filterSegment,
                breakdownSegment,
                employeeAttribute
            });

            if (!found) {
                if (!isFilterApplied) {
                    dispatch({
                        type: actionTypes.invalidVariation,
                        isSubtable,
                        reportId,
                        employeeAttribute
                    });

                    const mixpanelObj = getMixPanelObjectForFilters(
                        analysisName,
                        allSegments,
                        attributes
                    );
                    if (mixpanel.invalidVariation) {
                        track(mixpanel.invalidVariation, mixpanelObj);
                    }
                    return Promise.resolve();
                } else {
                    dispatch({
                        type: actionTypes.invalidAppliedFilterStatus,
                        reportId
                    });

                    const dashboard = getState().dashboards[dashboardId];

                    const {
                        attributes: oldAttributes,
                        filter: oldFilter,
                        breakdown: oldBreakdown
                    } = dashboard.reports.find(
                        (report: any) => report.reportId === reportId
                    );

                    ({ mainSegments, filterSegment, breakdownSegment } =
                        getSegmentsFromV2Report(
                            oldAttributes,
                            oldFilter,
                            oldBreakdown
                        ));

                    allSegments = getAllSegments({
                        mainSegments,
                        filterSegment,
                        breakdownSegment,
                        employeeAttribute
                    });
                    filterLength = allSegments.length;
                    isSubtable =
                        (breakdownSegment &&
                            breakdownSegment.values.length > 1) ||
                        employeeAttribute;
                    ({ fieldObject } = getFoundByVariations({
                        variations,
                        filterLength,
                        mainSegments,
                        filterSegment,
                        breakdownSegment,
                        employeeAttribute
                    }));
                }
            }

            if (isSubtable) {
                dispatch({
                    type: actionTypes.subtablePending,
                    reportId,
                    analysisName,
                    employeeAttribute
                });
            } else {
                dispatch({
                    type: actionTypes.reportPending,
                    reportId,
                    analysisName
                });
            }

            let segmentField;

            if (breakdownSegment && breakdownSegment.values.length > 1) {
                segmentField = {
                    fieldName: breakdownSegment.name.replace(/ /g, ''),
                    fieldValues: []
                };
            }

            if (employeeAttribute) {
                segmentField = {
                    fieldName: employeeAttribute,
                    fieldValues: []
                };
            }

            let dates: any;
            if (!isSubtable) {
                dates = getDates(
                    latestDataUpdate,
                    interval,
                    intervalCount,
                    startDate,
                    endDate
                ).map(d => moment.utc(d).format(Constants.ApiDateFormat));
            }

            if (breakdownSegment && !date) {
                date = moment
                    .utc(latestDataUpdate)
                    .format(Constants.ApiDateFormat);
            }

            return axios
                .post(`${Urls.ExploreApi}explore`, {
                    enterpriseId,
                    interval,
                    dates:
                        isSubtable || analysisType === 'Arithmetic'
                            ? [date || dates[dates.length - 1]]
                            : dates,
                    analysisName,
                    analysisType,
                    uniqueName,
                    ...fieldObject,
                    segmentField
                })
                .then(response => {
                    const resultsProp = camelCase(analysisType) + 'Results';
                    const results = response.data.values[resultsProp];
                    if (results.length === 0) {
                        const mixpanelObj = getMixPanelObjectForFilters(
                            analysisName,
                            allSegments,
                            attributes
                        );
                        if (mixpanel.noData) {
                            track(mixpanel.noData, mixpanelObj);
                        }
                        return dispatch({
                            type: actionTypes.noData,
                            reportId,
                            isSubtable,
                            employeeAttribute,
                            analysisType
                        });
                    }
                    if (isSubtable) {
                        dispatch({
                            type: actionTypes.subtableFulfilled,
                            reportId,
                            mode,
                            results,
                            analysisName,
                            analysisType,
                            mainSegments,
                            filterSegment,
                            breakdownSegment,
                            attributes,
                            date,
                            interval,
                            employeeAttribute,
                            intersectionAttributes,
                            chartData,
                            subtableChartType,
                            columnProps,
                            shouldGetPrimaryData,
                            mainColumn
                        });
                        if (shouldGetPrimaryData) {
                            return axios.post(`${Urls.ExploreApi}explore`, {
                                enterpriseId,
                                interval,
                                dates: [date],
                                analysisName,
                                analysisType,
                                uniqueName,
                                ...fieldObject
                            });
                        }
                    } else {
                        if (!breakdownSegment) {
                            if (actionTypes.setSections) {
                                dispatch({
                                    type: actionTypes.setSections,
                                    mainSegments,
                                    filterSegment,
                                    variations,
                                    attributes,
                                    analysisType,
                                    mainColumn
                                });
                            }
                        } else {
                            if (actionTypes.clearSections) {
                                dispatch({
                                    type: actionTypes.clearSections
                                });
                            }
                        }

                        dispatch({
                            type: actionTypes.reportFulfilled,
                            reportId,
                            results,
                            interval,
                            analysisDisplayName,
                            analysisName,
                            subAnalyses,
                            analysisType,
                            mainSegments,
                            filterSegment,
                            breakdownSegment,
                            attributes,
                            startDate,
                            endDate,
                            intervalCount,
                            chartType,
                            columnProps,
                            subAnalysisFields,
                            displayFields,
                            supportedGraphs,
                            subAnalysisMapping,
                            allowYAxisToggle,
                            eventIds,
                            yAxisType,
                            indexType,
                            allowIndexToggle,
                            isFilterApplied
                        });

                        if (reportId && benchmark) {
                            dispatch({
                                type: actionTypes.setBenchmark,
                                reportId,
                                benchmark
                            });

                            return dispatch(
                                fetchBenchmarkData(reportId, benchmark.query)
                            );
                        }
                    }
                })
                .then(response => {
                    if (shouldGetPrimaryData && response && response.data) {
                        const resultsProp = camelCase(analysisType) + 'Results';
                        const results = response.data.values[resultsProp];
                        dispatch({
                            type: actionTypes.primaryDataFulfilled,
                            reportId,
                            results,
                            interval,
                            analysisName,
                            analysisType,
                            mainSegments,
                            filterSegment,
                            attributes,
                            columnProps,
                            date,
                            employeeAttribute,
                            isFilterApplied
                        });
                    }
                })
                .catch(error => {
                    errorHandler.report(error);
                    if (isSubtable) {
                        dispatch({
                            type: actionTypes.subtableRejected,
                            reportId,
                            employeeAttribute
                        });
                    } else {
                        dispatch({
                            type: actionTypes.reportRejected,
                            reportId,
                            message: error.response.data,
                            status: error.response.status
                        });
                    }
                });
        }
    );
}

export function getTemplateFromAny(analysisName: string) {
    return (dispatch: any, getState: any) => {
        const { templates } = getState().templates;

        const pending = templates.some((template: any) => template.pending);
        let mainNames: string[] = [];
        let filterNames: string[] = [];
        let breakdownNames: string[] = [];
        if (pending) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(dispatch(getTemplateFromAny(analysisName)));
                }, 500);
            });
        }

        const anyTemplate = {
            analysisName: '*',
            analysisDisplayName: 'Any',
            variations: [],
            filterNames: [],
            breakdownNames: []
        };

        templates.forEach((template: any) => {
            if (template.configurationVariables[0].additionalVariables) {
                mainNames = mainNames.concat(
                    template.configurationVariables[0].additionalVariables.map(
                        (v: any) => {
                            return v.originalName;
                        }
                    )
                );
            }
            if (template.configurationVariables[0].controlVariables) {
                filterNames = filterNames.concat(
                    template.configurationVariables[0].controlVariables.map(
                        (v: any) => {
                            return v.originalName;
                        }
                    )
                );
                breakdownNames = breakdownNames.concat(
                    template.configurationVariables[0].controlVariables.map(
                        (v: any) => {
                            return v.originalName;
                        }
                    )
                );
            }
        });

        // remove duplicates
        filterNames = [...new Set(filterNames)];
        breakdownNames = [...new Set(breakdownNames)];

        return Promise.resolve({
            ...anyTemplate,
            filterNames,
            mainNames,
            breakdownNames,
            analysisType: '',
            uniqueName: ''
        });
    };
}

export function getTemplateFromAnalysisName(analysisName: string) {
    return (dispatch: any, getState: any) => {
        const { templates } = getState().templates;
        if (analysisName === '*') {
            return dispatch(getTemplateFromAny(analysisName));
        }
        const template = templates.find(
            (t: any) => t.analysisName === analysisName
        );
        if (!template) {
            // unknown error
            return Promise.reject();
        }
        const { variations, pending, analysisType, uniqueName } = template;
        if (variations) {
            return Promise.resolve({
                ...template,
                analysisType,
                uniqueName
            });
        } else if (pending) {
            return new Promise(resolve => {
                setTimeout(() => {
                    resolve(
                        dispatch(getTemplateFromAnalysisName(analysisName))
                    );
                }, 500);
            });
        } else {
            return dispatch(getTemplateFromAnalysisName(analysisName));
        }
    };
}

export function getReportAttributes(
    mainSegments: any,
    filterSegment: any,
    breakdownSegment: any,
    allAttributes: any,
    employeeAttribute: any,
    rowFilter: string[] = [],
    columnFilter: string[] = []
) {
    const attributes = mainSegments.map((ms: any) => ({
        name: ms.name,
        value: ms.values.slice()
    }));
    let filter;
    if (filterSegment) {
        filter = {
            name: filterSegment.name,
            value: filterSegment.values[0]
        };
    }
    let breakdown;
    if (breakdownSegment) {
        breakdown = {
            name: breakdownSegment.name,
            value: breakdownSegment.values
        };
    }

    if (employeeAttribute) {
        if (employeeAttribute) {
            const attribute = allAttributes.find(
                (a: any) =>
                    a.attributeName.replace(/ /g, '').toLowerCase() ===
                    employeeAttribute.toLowerCase()
            );
            breakdown = {
                name: attribute.attributeName,
                value: attribute.attributeValues.map((v: any) => v.value)
            };
            if (rowFilter.length) {
                breakdown.value = breakdown.value.filter((v: any) =>
                    rowFilter.includes(v)
                );
            }
            if (columnFilter.length) {
                // eg, Female_Asian, Female_Black, Male_White

                const attributeValueSet: any[] = [];
                attributes.forEach(() => {
                    attributeValueSet.push(new Set<string>());
                });
                columnFilter.forEach(filter => {
                    const tokens = filter.split('_');
                    tokens.forEach((token, i) => {
                        attributeValueSet[i].add(token);
                    });
                });
                attributes.forEach((attribute: any) => {
                    let removed = false;
                    attributeValueSet.forEach(set => {
                        if (removed) {
                            return;
                        }
                        const selectedAttributeValues = Array.from(set);
                        let found = true;
                        selectedAttributeValues.forEach(
                            selectedAttributeValue => {
                                found =
                                    found &&
                                    attribute.value.includes(
                                        selectedAttributeValue
                                    );
                            }
                        );
                        if (found) {
                            // This is the set and this is the attribute to filter
                            const removableIndices: number[] = [];
                            attribute.value.forEach(
                                (attributeValue: any, i: number) => {
                                    if (
                                        !selectedAttributeValues.includes(
                                            attributeValue
                                        )
                                    ) {
                                        removableIndices.push(i);
                                    }
                                }
                            );
                            for (
                                let i = removableIndices.length - 1;
                                i >= 0;
                                i--
                            ) {
                                attribute.value.splice(removableIndices[i]);
                            }
                            removed = true;
                        }
                    });
                });
            }
        }
    }

    return {
        attributes,
        filter,
        breakdown
    };
}

function getAdditionalConfig(config: any = {}) {
    return Object.keys(config).map(key => {
        return {
            name: key,
            value:
                typeof config[key] === 'undefined' ? '' : config[key].toString()
        };
    });
}

export function getReportDef(params: any) {
    const {
        reportId = uuid.v4(),
        rowIndex,
        columnIndex,
        columnWidthPerc,
        rowHeight,
        analysisName,
        mainSegments,
        filterSegment,
        breakdownSegment,
        attributes: allAttributes,
        interval,
        intervalCount,
        startDate,
        endDate,
        employeeAttribute,
        rowFilter,
        columnFilter,
        date,
        reportRow,
        reportColumn,
        reportName,
        benchmark,
        subAnalyses,
        additionalConfig,
        eventIds,
        length = GridConstants.ColumnCount,
        yAxisType,
        indexType,
        isFilterApplied
    } = params;

    let { chartType } = params;

    const { attributes, filter, breakdown } = getReportAttributes(
        mainSegments,
        filterSegment,
        breakdownSegment,
        allAttributes,
        employeeAttribute,
        rowFilter,
        columnFilter
    );

    const isSubtable =
        !!employeeAttribute ||
        (breakdownSegment && breakdownSegment.values.length > 1);
    if (isSubtable) {
        // TODO: update this...
        // chartType = 'Matrix';
    }

    const subAnalysesConverted = subAnalyses
        .map((s: any) => ({
            name: s.name,
            source: s.source
        }))
        .map((subAnalysis: any) => ({
            configuration: arrifyObject(subAnalysis)
        }));

    let layoutConfiguration = [];

    if (rowIndex !== undefined && rowIndex !== null) {
        layoutConfiguration.push({
            name: 'rowIndex',
            value: rowIndex.toString()
        });
    }

    if (columnIndex !== undefined && columnIndex !== null) {
        layoutConfiguration.push({
            name: 'columnIndex',
            value: columnIndex.toString()
        });
    }

    if (columnWidthPerc !== undefined && columnWidthPerc !== null) {
        layoutConfiguration.push({
            name: 'columnWidthPerc',
            value: columnWidthPerc.toString()
        });
    }

    if (rowHeight) {
        layoutConfiguration.push({
            name: 'rowHeightPx',
            value: rowHeight.toString()
        });

        layoutConfiguration.push({
            name: 'layoutVersion',
            value: 'v2'
        });
    }

    const report = {
        reportId,
        version: '2',
        name: reportName,
        dateAdded: moment.utc().toISOString(),
        analysisName,
        analysis: subAnalysesConverted,
        attributes,
        filter,
        breakdown,
        isFilterApplied,
        eventIds: eventIds || [],
        configuration: [
            {
                name: 'chartType',
                value: chartType
            },
            {
                name: 'length',
                value: length.toString()
            },
            {
                name: 'interval',
                value: interval
            },
            {
                name: 'yAxisType',
                value: yAxisType
            },
            {
                name: 'indexType',
                value: indexType
            },
            ...layoutConfiguration,
            ...getAdditionalConfig(additionalConfig)
        ]
    };

    if (!isSubtable) {
        if (startDate) {
            report.configuration.push({
                name: 'startDate',
                value: startDate
            });
            report.configuration.push({
                name: 'endDate',
                value: endDate
            });
        } else {
            report.configuration.push({
                name: 'intervalCount',
                value: intervalCount.toString()
            });
        }
    }

    if (benchmark) {
        const { benchmarkName, attributes, query, hidePie } = benchmark;
        const benchmarkConfig = {
            benchmarkName,
            attributes,
            query,
            hidePie
        };
        report.configuration.push({
            name: 'benchmark',
            value: JSON.stringify(benchmarkConfig)
        });
    }

    if (date) {
        report.configuration.push({
            name: 'date',
            value: date
        });
    }

    return report;
}

export function fetchBenchmarkData(reportId: string, query: any) {
    return (dispatch: any) => {
        dispatch({
            type: ActionTypes.FetchBenchmarkDataForReportPending,
            reportId
        });
        return axios
            .post(`${Urls.BenchmarkApi}benchmark/query`, query)
            .then(response => {
                const { data } = response;
                if (data.length === 0) {
                    dispatch({
                        type: ActionTypes.FetchBenchmarkDataForReportNoData,
                        reportId
                    });
                } else {
                    dispatch({
                        type: ActionTypes.FetchBenchmarkDataForReportFulfilled,
                        reportId,
                        data: data[0].level1Results
                    });
                }
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.FetchBenchmarkDataForReportRejected,
                    reportId
                });
            });
    };
}

import gsap from 'gsap';
import { Fragment, memo, useContext, useEffect, useRef } from 'react';
import Markdown from 'react-markdown';
import { InsightsContext } from '.';
import cn from '../../lib/cn';
import Button from '../ButtonV2';
import Feedback from '../Feedback';
import MessageBubble from './MessageBubble';
import { MessageTypes } from './types';

export default function Insights() {
    const scrollToRef = useRef<any>(null);
    const { loaded, pending, error } = useContext(InsightsContext);

    useEffect(() => {
        if (scrollToRef.current && loaded) {
            scrollToRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [loaded]);

    if (!(loaded || pending)) {
        return null;
    }

    return (
        <div className={cn('flex flex-col w-avail')} ref={scrollToRef}>
            {error ? <Error /> : <Success />}
        </div>
    );
}

const Success = () => {
    const {
        loaded,
        pending,
        isSummary,
        selectedReports,
        onSave,
        aiInsights,
        regenerateMode,
        onReportUpdate,
        isGivingFeedback,
    } = useContext(InsightsContext);

    return (
        <Fragment>
            {selectedReports?.length && (
                <MessageBubble showAvatar type={MessageTypes.user} animate={false}>
                    {isSummary ? (
                        <strong>Summarize entire dashboard</strong>
                    ) : (
                        <Fragment>
                            <span>{`Generate insights for `}</span>
                            <span>
                                "
                                {selectedReports?.map((report: any, index: number) => (
                                    <span>
                                        <strong className={cn('text-ui-100')}>
                                            {report.name}
                                            {index < selectedReports.length - 1 && ', '}
                                        </strong>
                                    </span>
                                ))}
                                "
                            </span>
                        </Fragment>
                    )}
                </MessageBubble>
            )}
            <MessageBubble pending={pending} showAvatar={!!onSave} animate={false} type={MessageTypes.assistant}>
                <PlainText>{aiInsights}</PlainText>
            </MessageBubble>

            {loaded && (
                <Fragment>
                    <div className={cn('ml-[1.6rem] mt-[1rem]  w-avail')}>
                        <div className={cn('ml-[3.2rem]')}>
                            <Feedback isChat={false} messageIdx={1} />
                        </div>
                    </div>
                    {onSave && (
                        <div
                            className={cn(
                                'mt-[1rem] mb-[3.2rem] w-avail flex justify-end',
                                isGivingFeedback && 'hidden'
                            )}
                        >
                            <Button onClick={regenerateMode ? onReportUpdate : onSave}>
                                <div className={cn('flex items-center gap-[.8rem]')}>
                                    {regenerateMode ? 'Update insights' : 'Add to dashboard'}
                                </div>
                            </Button>
                        </div>
                    )}
                </Fragment>
            )}
        </Fragment>
    );
};

const Error = () => {
    const { error, selectedReports, isSummary } = useContext(InsightsContext);

    return (
        <Fragment>
            {selectedReports?.length && (
                <MessageBubble showAvatar type={MessageTypes.user} animate={false}>
                    {isSummary ? (
                        <strong>Summarize entire dashboard</strong>
                    ) : (
                        <Fragment>
                            <span>{`Generate insights for `}</span>
                            <span>
                                "
                                {selectedReports?.map((report: any, index: number) => (
                                    <span>
                                        <strong className={cn('text-ui-100')}>
                                            {report.name}
                                            {index < selectedReports.length - 1 && ', '}
                                        </strong>
                                    </span>
                                ))}
                                "
                            </span>
                        </Fragment>
                    )}
                </MessageBubble>
            )}
            <MessageBubble type={MessageTypes.assistant} showAvatar animate={false}>
                <span>{error}</span>
            </MessageBubble>
        </Fragment>
    );
};

const PlainText = memo(({ children }: { children: any }) => {
    useEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap.timeline({});

            tl.fromTo(
                '.animate-insights',
                {
                    autoAlpha: 0,
                },
                {
                    autoAlpha: 1,
                    duration: 1,
                    stagger: 0.175,
                    ease: 'power1.out',
                }
            );
        });

        return () => {
            ctx.revert();
        };
    }, []);

    return (
        <Markdown
            components={{
                h2: ({ node, children, ...props }) => (
                    <p {...props} className={cn('mb-[.8rem] animate-insights invisible')}>
                        {children}
                    </p>
                ),
                strong: ({ node, ...props }) => <span className={cn('')} {...props} />,
                ol: ({ node, ...props }) => <span className={cn('mb-[.8rem] ')}>{props.children}</span>,
                li: ({ node, ...props }) => (
                    <p className={cn('mb-[.8rem] max-w-prose animate-insights invisible')}>{props.children}</p>
                ),
            }}
        >
            {children}
        </Markdown>
    );
});

import { useState, useRef, useEffect, useCallback, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import Legend from '../../common/Legend';
import {
    deleteBenchmark,
    openBenchmarkModal,
    closeBenchmarkModal
} from '../../benchmark/actions';
import { selectCurrentTime } from '../actions';
import {
    showInfo,
    closeInfo,
    openDeleteModal,
    closeDeleteModal
} from '../../common/actions';
import InfoModal from '../../common/InfoModal';
import { toggleComments } from '../../comments/actions';
import { track } from '../../lib/segment';
import MixPanel from '../../constants/MixPanel';
import GridConstants from '../../constants/GridConstants';
import Loader from '../../lottie/graph-loader.json';
import '../../benchmark/styles.scss';
import measureText from '../../lib/measureText';
import CanvasFonts from '../../constants/CanvasFonts';
import BenchmarkModal from '../../benchmark/BenchmarkModal';
import DeleteModal from '../../common/DeleteModal';
import LottieAnimation from '../../common/LottieAnimation';
import ChartHeader from '../../common/ChartHeader';
import ChartPicker from '../../common/ChartPicker';
import ChartTypes from '../../constants/ChartTypes';
import ChartContainer from '../../common/ChartContainer';
import useChartDeclaration from '../../common/useChartDeclaration';
import useInteractiveChart from '../../common/useInteractiveChart';
import XAxis from '../../common/XAxis';
import ScrollableChartArea from '../../common/ScrollableChartArea';
import Events from '../../common/Events';
import EventsModal from '../../common/EventsModal';
import exportDetailData from '../detail/exportDetailData';
import exportDetailDataAsCsv from '../detail/exportCsv';
import WithPermissions from '../../common/WithPermissions';
import RbacActions from '../../constants/RbacActions';
import { useAppSelector } from '../../store/hooks';
import {
    setActiveLabel,
    clearActiveLabel,
    toggleLabel,
    toggleLockedSelection,
    clearSelection,
    setChartType,
    setBenchmark,
    removeBenchmark,
    setHidePie,
    closeEventsModal
} from './actions';
import exportChart from './exportChart';
import exportAsCSV from './exportAsCSV';

const COLUMN_GAP = 16;
const LEGEND_WHITESPACE = 52;
const LABEL_WHITESPACE = 16;
const BENCHMARK_BOTTOM_PADDING = 40;
const BENCHMARK_TOP_PADDING = 16;
const ROW_HEIGHTS = [44, 40, 36, 28];
const MAX_HEIGHT = 400;
const MIN_HEIGHT = 250;

function getMainColumn(state) {
    const analysisName = state.explore.chart?.lifecycle;
    if (!analysisName) return;

    const template = state.templates.dynamicTemplates.find(
        t => t.analysisName === analysisName
    );

    return template?.mainColumn;
}

function Chart({
    data,
    lifecycle,
    currentIndex,
    setActiveLabel,
    clearActiveLabel,
    toggleLabel,
    toggleLockedSelection,
    clearSelection,
    generatePending,
    generated,
    generateNoData,
    generateInvalidVariation,
    generateError,
    dates,
    interval,
    closeInfo,
    analysisType,
    infoModalProps,
    intersectionAttributes,
    chartType,
    isEmptyState,
    confidence,
    benchmark,
    showBenchmarkIcon,
    deleteBenchmark,
    deleteBenchmarkModalOpen,
    deleteBenchmarkPending,
    openDeleteModal,
    closeDeleteModal,
    isBenchmarkModalOpen,
    closeBenchmarkModal,
    columnProps,
    showSubtable,
    hasInvalidValue,
    status: globalStatus,
    hoveredItem: globalHoveredItem,
    selection: globalSelection,
    selectCurrentTime,
    allEvents,
    eventIds,
    eventsModalOpen,
    closeEventsModal,
    enterpriseName,
    subtable,
    subtableChartType,
    diversityAttributes
}) {
    const [width, setWidth] = useState(0);
    const [innerChartOffsets, setInnerChartOffsets] = useState({
        left: 0,
        right: 0
    });
    const [height, setHeight] = useState(0);
    const [containerHeight, setContainerHeight] = useState(200);
    const [multiLine, setMultiLine] = useState(false);
    const [lineWidth, setLineWidth] = useState();
    const [benchmarkRowHeight, setBenchmarkRowHeight] = useState(
        ROW_HEIGHTS[0]
    );

    const [benchmarkEdit, setBenchmarkEdit] = useState(false);
    const [benchmarkToEdit, setBenchmarkToEdit] = useState();

    const mainColumn = useAppSelector(getMainColumn);
    const { indexType } = useAppSelector(state => state.explore.chart);

    const containerComp = useRef();
    const chartComp = useRef();
    const headerComp = useRef();
    const legendComp = useRef();
    const {
        onHover,
        onHoverEnd,
        onToggle,
        onClearSelection,
        hoveredItem,
        selection,
        status
    } = useInteractiveChart({
        mode: 'explore',
        status: globalStatus,
        hoveredItem: globalHoveredItem,
        selection: globalSelection,
        setActiveLabel,
        toggleLabel,
        lockedSelection: chartType === ChartTypes.Funnel,
        toggleLockedSelection,
        clearActiveLabel,
        clearSelection
    });

    const chartDeclaration = useChartDeclaration({
        mode: 'explore',
        chartType,
        intersectionAttributes,
        confidence,
        showBenchmarkIcon,
        setBenchmarkEdit,
        setBenchmarkToEdit,
        benchmark,
        lifecycle,
        data,
        dates,
        currentIndex,
        exportAsCSV,
        exportChart,
        showSubtable,
        columnCount: GridConstants.ColumnCount,
        onHover,
        onHoverEnd,
        onToggle,
        setActiveLabel,
        clearActiveLabel,
        chartHeaderRef: headerComp,
        lineWidth,
        multiLine,
        chartWidth: width,
        chartHeight: height,
        interval,
        selectCurrentTime,
        hasInvalidValue,
        handleToggleLabel,
        columnProps,
        analysisType,
        benchmarkRowHeight,
        columnGap: COLUMN_GAP,
        containerHeight,
        benchmarkRowHeight,
        status,
        hoveredItem,
        selection,
        events: allEvents.filter(event => eventIds.includes(event.eventId)),
        innerChartOffsets,
        setInnerChartOffsets,
        enterpriseName,
        subtable,
        exportDetailData,
        exportDetailDataAsCsv,
        mainColumn
    });

    const handleResize = useCallback(() => {
        if (containerComp.current) {
            const width = containerComp.current.offsetWidth;
            setWidth(width);
            const colWidth =
                (width -
                    (GridConstants.ColumnCount - 1) * GridConstants.ColumnGap) /
                GridConstants.ColumnCount;
            let containerHeight =
                2 * colWidth +
                GridConstants.ColumnGap -
                GridConstants.ExploreHeightFix;
            let height = containerHeight;
            if (headerComp.current && !generatePending) {
                height -= headerComp.current.offsetHeight;
                height -= 32;
            }
            if (legendComp.current && !generatePending && !!lineWidth) {
                // compute height after legend is initialised
                height -= legendComp.current.offsetHeight;
            }

            // check height for high screen resolutions
            height =
                height > MAX_HEIGHT
                    ? MAX_HEIGHT
                    : height < MIN_HEIGHT
                    ? MIN_HEIGHT
                    : height;

            if (benchmark && benchmark.data && benchmark.data.length > 0) {
                // compute height and container height again!
                const tableHeight =
                    height - BENCHMARK_TOP_PADDING - BENCHMARK_BOTTOM_PADDING;
                let rowHeight;
                let extend = true;
                for (let i = 0; i < ROW_HEIGHTS.length; i++) {
                    rowHeight = ROW_HEIGHTS[i];
                    if (
                        rowHeight * (benchmark.data.length + 1) <=
                        tableHeight
                    ) {
                        extend = false;
                        break;
                    }
                }
                setBenchmarkRowHeight(rowHeight);
                if (extend) {
                    height =
                        rowHeight * (benchmark.data.length + 1) +
                        BENCHMARK_TOP_PADDING +
                        BENCHMARK_BOTTOM_PADDING;
                    if (headerComp.current) {
                        containerHeight =
                            height + headerComp.current.offsetHeight;
                    }
                }
            }
            setContainerHeight(containerHeight);
            setHeight(height);
        }
    }, [benchmark, generatePending, lineWidth, chartType]);

    useEffect(() => {
        handleResize();
        const handleResizeDebounced = debounce(handleResize, 100);
        window.addEventListener('resize', handleResizeDebounced);
        return () => {
            window.removeEventListener('resize', handleResizeDebounced);
        };
    }, [handleResize]);

    useEffect(() => {
        if (showSubtable) {
            setLineWidth(undefined);
            return;
        }
        if (data.length === 0) {
            // indicates that legend is uninitialised
            setLineWidth(0);
            setHeight(0);
            return;
        }
        let measuredWidth = 0;
        data.forEach(d => {
            if (!d.isEqualityIndex && !d.isAttritionIndex) {
                measuredWidth +=
                    measureText(d.label, CanvasFonts.Regular11).width +
                    LABEL_WHITESPACE;
            }
        });
        const legendWidth = width - LEGEND_WHITESPACE;
        const maxLineWidth = 0.7 * legendWidth;
        setMultiLine(measuredWidth > maxLineWidth);
        setLineWidth(maxLineWidth);
    }, [data, width, showSubtable]);

    function handleToggleLabel(label, e) {
        if (e) {
            e.stopPropagation();
        }
        if (chartType === ChartTypes.Funnel) {
            toggleLockedSelection(label);
        } else {
            toggleLabel(label);
        }
    }

    function handleBenchmarkDelete() {
        track(MixPanel.Events.ExploreDeleteBenchmark, {
            'Benchmark Name': benchmarkToEdit.benchmarkName
        });
        closeBenchmarkModal();
        openDeleteModal();
    }

    function handleBenchmarkDeleteConfirm() {
        track(MixPanel.Events.ExploreDeleteBenchmarkConfirm, {
            'Benchmark Name': benchmarkToEdit.benchmarkName
        });
        deleteBenchmark(benchmarkToEdit.benchmarkId);
    }

    function handleBenchmarkDeleteCancel() {
        track(MixPanel.Events.ExploreDeleteBenchmarkCancel, {
            'Benchmark Name': benchmarkToEdit.benchmarkName
        });
        closeDeleteModal();
    }

    function renderEmptyState() {
        if (generatePending) {
            return (
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)'
                    }}
                >
                    <LottieAnimation
                        autoplay={true}
                        loop={true}
                        width={60}
                        height={60}
                        animation={Loader}
                    ></LottieAnimation>
                </div>
            );
        }

        if (generateError) {
            return (
                <div>
                    Sorry, you are not authorized to view this report. Your
                    administrator controls these permissions for your account.
                </div>
            );
        } else if (generateInvalidVariation) {
            return (
                <div>
                    This report has not yet been activated by your company.
                    <br />
                    <a href="mailto: support@itsdandi.com" target="__blank">
                        Contact
                    </a>{' '}
                    your Dandi Customer Success Representative to turn it on.
                </div>
            );
        } else if (generateNoData) {
            return <div>Sorry! There is no data for this graph.</div>;
        } else if (!generated) {
            return (
                <div>
                    Select a metric and one or more attributes. Then click
                    Generate to create your report.
                </div>
            );
        }
    }

    if (isEmptyState) {
        // filters are empty
        return null;
    }

    const isEmpty =
        generatePending ||
        generateError ||
        generateNoData ||
        !generated ||
        lineWidth === 0;

    let isOther = false;
    if (!benchmark && benchmarkToEdit) {
        isOther = true;
    }
    if (benchmark && benchmarkToEdit) {
        isOther = benchmark.benchmarkId !== benchmarkToEdit.benchmarkId;
    }

    return (
        <div
            className={classNames('explore-chart', {
                'explore-chart--empty':
                    generatePending ||
                    generateError ||
                    generateNoData ||
                    !generated ||
                    lineWidth === 0,

                'explore-chart--no-data': generatePending || generateNoData
            })}
            ref={containerComp}
            height={
                (showSubtable && !generatePending) ||
                chartType === ChartTypes.Stats ||
                chartType === ChartTypes.ArithmeticTable ||
                chartType === ChartTypes.Funnel ||
                chartType === ChartTypes.Line
                    ? undefined
                    : containerHeight
            }
            onClick={onClearSelection}
        >
            <ChartContainer>
                {isEmpty ? (
                    renderEmptyState()
                ) : (
                    <Fragment>
                        {chartDeclaration.chartHeader && (
                            <ChartHeader
                                subtableChartType={subtableChartType}
                                {...chartDeclaration.chartHeader}
                            />
                        )}
                        <ScrollableChartArea>
                            {showSubtable &&
                                subtableChartType ===
                                    ChartTypes.HorizontalBar && (
                                    <Legend
                                        length={data?.length || 0}
                                        lineWidth={0}
                                        multiLine={false}
                                        data={diversityAttributes?.map(
                                            attr => ({
                                                ...attr,
                                                color: attr.segment.tokens[0]
                                                    .color
                                            })
                                        )}
                                        onHover={onHover}
                                        onHoverEnd={onHoverEnd}
                                        onToggle={onToggle}
                                        hoveredItem={hoveredItem}
                                        selection={selection}
                                        status={status}
                                    />
                                )}
                            {chartDeclaration.chartLegend && !showSubtable && (
                                <Legend
                                    {...chartDeclaration.chartLegend}
                                    ref={legendComp}
                                />
                            )}
                            {chartDeclaration.chart && (
                                <div
                                    className="explore-chart__main"
                                    ref={chartComp}
                                >
                                    <ChartPicker {...chartDeclaration.chart} />
                                </div>
                            )}
                            {chartDeclaration.events && (
                                <Events {...chartDeclaration.events} />
                            )}
                        </ScrollableChartArea>
                        {chartDeclaration.xAxis && (
                            <XAxis
                                {...chartDeclaration.xAxis}
                                indexType={indexType}
                            />
                        )}
                    </Fragment>
                )}
                <InfoModal {...infoModalProps} onCancel={closeInfo} />
                <WithPermissions actions={[RbacActions['Benchmarks/View']]}>
                    <BenchmarkModal
                        benchmark={benchmarkEdit ? benchmarkToEdit : null}
                        isOpen={isBenchmarkModalOpen}
                        onCancel={closeBenchmarkModal}
                        onBenchmarkDelete={handleBenchmarkDelete}
                        isOther={isOther}
                    />
                </WithPermissions>

                <WithPermissions actions={[RbacActions['Events/View']]}>
                    <EventsModal
                        mode="explore"
                        isOpen={eventsModalOpen}
                        onCancel={closeEventsModal}
                    />
                </WithPermissions>
                <WithPermissions actions={[RbacActions['Benchmarks/Edit']]}>
                    <DeleteModal
                        isOpen={deleteBenchmarkModalOpen}
                        onCancel={handleBenchmarkDeleteCancel}
                        onAction={handleBenchmarkDeleteConfirm}
                        title="Delete Benchmark"
                        pending={deleteBenchmarkPending}
                    >
                        <p>
                            Click Delete to permanently delete this benchmark.
                        </p>
                    </DeleteModal>
                </WithPermissions>
            </ChartContainer>
        </div>
    );
}

Chart.propTypes = {
    data: PropTypes.array,
    lifecycle: PropTypes.string,
    currentIndex: PropTypes.number,
    setActiveLabel: PropTypes.func,
    clearActiveLabel: PropTypes.func,
    toggleLabel: PropTypes.func,
    toggleLockedSelection: PropTypes.func,
    clearSelection: PropTypes.func,
    selectCurrentTime: PropTypes.func,
    generatePending: PropTypes.bool,
    generated: PropTypes.bool,
    generateError: PropTypes.bool,
    generateInvalidVariation: PropTypes.bool,
    generateNoData: PropTypes.bool,
    interval: PropTypes.string,
    dates: PropTypes.array,
    showInfo: PropTypes.func,
    closeInfo: PropTypes.func,
    commentCount: PropTypes.number,
    toggleComments: PropTypes.func,
    memberSince: PropTypes.string,
    analysisType: PropTypes.string,
    infoModalProps: PropTypes.object,
    intersectionAttributes: PropTypes.array,
    chartType: PropTypes.string,
    setChartType: PropTypes.func,
    hasInvalidValue: PropTypes.bool,
    isEmptyState: PropTypes.bool,
    confidence: PropTypes.number,
    benchmark: PropTypes.object,
    showBenchmarkIcon: PropTypes.bool,
    setBenchmark: PropTypes.func,
    removeBenchmark: PropTypes.func,
    deleteBenchmark: PropTypes.func,
    deleteBenchmarkModalOpen: PropTypes.bool,
    deleteBenchmarkPending: PropTypes.bool,
    openDeleteModal: PropTypes.func,
    closeDeleteModal: PropTypes.func,
    openBenchmarkModal: PropTypes.func,
    closeBenchmarkModal: PropTypes.func,
    isBenchmarkModalOpen: PropTypes.bool,
    setHidePie: PropTypes.func,
    role: PropTypes.string,
    showSubtable: PropTypes.bool,
    enterpriseName: PropTypes.string,
    allEvents: PropTypes.array,
    eventIds: PropTypes.array,
    eventsModalOpen: PropTypes.bool,
    closeEventsModal: PropTypes.func,
    subtableChartType: PropTypes.string,
    diversityAttributes: PropTypes.array
};

function mapState(state) {
    const { showSubtable } = state.explore.filter;
    let {
        lifecycle,
        generatePending,
        generateError,
        generateNoData,
        generateInvalidVariation,
        generated
    } = state.explore.chart;
    const {
        lifecycle: lifecycleSubtable,
        generatePending: generatePendingSubtable,
        error,
        empty,
        invalidVariation,
        generated: generatedSubtable
    } = state.explore.subtable;
    if (showSubtable) {
        lifecycle = lifecycleSubtable;
        generatePending = generatePendingSubtable;
        generateNoData = empty;
        generateInvalidVariation = invalidVariation;
        generateError = error;
    }
    generated = generated || generatedSubtable;

    return {
        interval: state.explore.filter.interval,
        memberSince: state.account.memberSince,
        ...state.explore.chart,
        commentCount: state.comments.commentCount,
        infoModalProps: state.infoModal,
        isEmptyState:
            !state.explore.filter.analysisName &&
            state.explore.filter.mainSegments.length === 0,
        deleteBenchmarkModalOpen: state.deleteModal.open,
        deleteBenchmarkPending: state.deleteModal.pending,
        isBenchmarkModalOpen: state.benchmarkModal.isOpen,
        role: state.auth.role,
        showSubtable,
        lifecycle,
        generatePending,
        generateError,
        generateInvalidVariation,
        generateNoData,
        generated,
        subtable: state.explore.subtable,
        enterpriseName: state.account.enterpriseName,
        allEvents: state.timeline.events,
        eventIds: state.explore.chart.eventIds || [],
        eventsModalOpen: state.explore.chart.eventsModalOpen,
        subtableChartType: state.explore.subtable.subtableChartType,
        diversityAttributes: state.explore.subtable.diversityAttributes
    };
}

const dispatchProps = {
    setActiveLabel,
    clearActiveLabel,
    toggleLabel,
    toggleLockedSelection,
    clearSelection,
    selectCurrentTime,
    showInfo,
    closeInfo,
    toggleComments,
    setChartType,
    setBenchmark,
    removeBenchmark,
    deleteBenchmark,
    openDeleteModal,
    closeDeleteModal,
    openBenchmarkModal,
    closeBenchmarkModal,
    closeEventsModal,
    setHidePie
};

export default connect(mapState, dispatchProps)(Chart);

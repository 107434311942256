import { configureStore } from '@reduxjs/toolkit';
import { routerMiddleware } from 'connected-react-router';
import sentryMiddleware from './middleware/sentryMiddleware';
import createRootReducer from './rootReducer';
import history from '../routes/history';

const store = configureStore({
    reducer: createRootReducer(history),
    middleware: getDefaultMiddleware => {
        const middlewares = getDefaultMiddleware().concat(
            routerMiddleware(history)
        );

        middlewares.push(sentryMiddleware);

        return middlewares;
    },
    devTools: process.env.REACT_APP_ENV !== 'prod'
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AsyncThunkConfig = {
    state: RootState;
    dispatch: AppDispatch;
};
export default store;

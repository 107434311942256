import { Menu } from 'antd';
import { useEffect, useState } from 'react';
import Button from '../../../common/Button';
import DeleteModal from '../../../common/DeleteModal';
import Grid from '../../../common/Grid';
import InfoCard from '../../../common/InfoCard';
import Popover from '../../../common/Popover';
import Stack from '../../../common/Stack';
import WithLoadingStates from '../../../common/WithLoadingStates';
import WithPermissions from '../../../common/WithPermissions';
import RbacActions from '../../../constants/RbacActions';
import More from '../../../icons/More';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ContentWrapper from '../../components/ContentWrapper';
import { setEditModeInitialState, setModalOpen } from '../edit/slice';
import { Audience } from '../types';
import EditFlow from './EditFlow';
import { deleteAudience, getAudiences } from './actions';
import { archiveAudience } from '../edit/actions';
import ConfirmationModal from '../../../common/ConfirmationModal';
import ButtonTypes from '../../../constants/ButtonTypes';

const Audiences = () => {
    const [hoverIndex, setHoverIndex] = useState(-1);
    const {
        data: activeData,
        archived: archivedData,
        loaded,
        error,
        pending
    } = useAppSelector(state => state.audiences.list);
    const [showArchived, setShowArchived] = useState(false);
    const data = showArchived ? archivedData : activeData;

    const dispatch = useAppDispatch();
    useEffect(() => {
        if (!loaded && !pending && !error) {
            dispatch(getAudiences());
        }
    }, [loaded, pending, error]);

    return (
        <WithPermissions
            actions={[RbacActions['Campaign/View']]}
            showMessage={true}
        >
            <ContentWrapper>
                <Stack
                    flexDirection="row"
                    justifyContent="flex-end"
                    gap=".8rem"
                    style={{ marginBottom: '2.4rem' }}
                >
                    <Button
                        activated={!showArchived}
                        onClick={() => setShowArchived(false)}
                        componentType={ButtonTypes.type.SECONDARY}
                    >
                        Active
                    </Button>
                    <Button
                        activated={showArchived}
                        onClick={() => setShowArchived(true)}
                        componentType={ButtonTypes.type.SECONDARY}
                    >
                        Archived
                    </Button>
                </Stack>
                <WithLoadingStates
                    isLoading={pending}
                    isError={!!error}
                    errorMessage="Sorry! We've encountered an issue in retrieving this information."
                    isEmpty={!pending && loaded && data.length === 0}
                    emptyContent={<EmptyContent archived={showArchived} />}
                >
                    <Grid base={3} gap="1.6rem">
                        {data.map((audience: Audience, index: number) => (
                            <Grid.Item key={audience.audienceId}>
                                <Card
                                    audience={audience}
                                    hoverIndex={hoverIndex}
                                    setHoverIndex={setHoverIndex}
                                    index={index}
                                    archived={showArchived}
                                />
                            </Grid.Item>
                        ))}
                    </Grid>
                </WithLoadingStates>
                <WithPermissions actions={[RbacActions['Campaign/Edit']]}>
                    <EditFlow />
                </WithPermissions>
            </ContentWrapper>
        </WithPermissions>
    );
};

const formatter = new Intl.DateTimeFormat('en-US');

const Card = ({
    audience,
    hoverIndex,
    setHoverIndex,
    index,
    selectionExists,
    archived
}: {
    audience: Audience;
    hoverIndex: number;
    setHoverIndex: any;
    index: number;
    selectionExists?: boolean;
    archived?: boolean;
}) => {
    const dispatch = useAppDispatch();
    const [menuOpen, setMenuOpen] = useState(false);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [archiveConfirmationOpen, setArchiveConfirmationOpen] =
        useState(false);
    const [archivePending, setArchivePending] = useState(false);

    function startEditFlow() {
        dispatch(setEditModeInitialState(audience));
    }

    function handleArchive() {
        return dispatch(
            archiveAudience({
                audience,
                shouldArchive: !archived
            })
        );
    }

    return (
        <InfoCard
            onClick={(e: any) => {
                e.stopPropagation();
                setMenuOpen(!menuOpen);
            }}
            state={
                selectionExists
                    ? 'selectionExists'
                    : hoverIndex !== -1
                    ? 'hoverExists'
                    : 'default'
            }
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(-1)}
            isHovering={hoverIndex === index}
        >
            <Stack
                style={{
                    padding: '1.6rem',
                    height: '100%'
                }}
                gap="1.6rem"
                justifyContent="space-between"
            >
                <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap="0.8rem"
                    justifyContent="space-between"
                >
                    <h3 className="font-subtitle">{audience.title}</h3>
                    <WithPermissions actions={[RbacActions['Campaign/Edit']]}>
                        <Popover
                            closeOnScroll={true}
                            placement="bottom"
                            overlayClassName="popover--no-padding"
                            destroyTooltipOnHide
                            showArrow={false}
                            visible={menuOpen}
                            onVisibleChange={() => setMenuOpen(!menuOpen)}
                            content={
                                <Menu
                                    className="widget-menu dashboards-menu"
                                    onMouseLeave={() => {
                                        if (menuOpen) {
                                            setMenuOpen(false);
                                        }
                                    }}
                                >
                                    {!archived && (
                                        <Menu.Item
                                            key="edit-audience"
                                            onClick={startEditFlow}
                                        >
                                            Edit
                                        </Menu.Item>
                                    )}
                                    <Menu.Item
                                        key="archive-audience"
                                        onClick={() =>
                                            setArchiveConfirmationOpen(true)
                                        }
                                    >
                                        {archived ? 'Unarchive' : 'Archive'}
                                    </Menu.Item>
                                    {!archived && (
                                        <Menu.Item
                                            key="delete-audience"
                                            onClick={() =>
                                                setDeleteConfirmationOpen(true)
                                            }
                                        >
                                            Delete
                                        </Menu.Item>
                                    )}
                                </Menu>
                            }
                        >
                            <div onClick={e => e.stopPropagation()}>
                                <More width={20} height={20} className="icon" />
                            </div>
                        </Popover>
                    </WithPermissions>
                </Stack>
                <div>{audience.description}</div>

                <Stack
                    flexDirection="row"
                    alignItems="flex-end"
                    justifyContent="space-between"
                >
                    <Stack gap=".4rem">
                        <div
                            className="font-micro-text"
                            style={{
                                color: 'var(--color-ui-50)'
                            }}
                        >
                            Count: {Number(audience.size).toLocaleString()}
                        </div>
                        <div
                            className="font-micro-text"
                            style={{
                                color: 'var(--color-ui-50)'
                            }}
                        >
                            {audience.type}
                        </div>
                    </Stack>
                    {audience.syncStatus.status !== 'COMPLETED' ? (
                        <SyncStatus status={audience.syncStatus.status} />
                    ) : (
                        <div
                            className="font-micro-text"
                            style={{
                                color: 'var(--color-ui-50)'
                            }}
                        >
                            Last updated:{' '}
                            {formatter.format(
                                new Date(audience.meta.updatedAt)
                            )}
                        </div>
                    )}
                </Stack>
            </Stack>
            <WithPermissions actions={[RbacActions['Campaign/Edit']]}>
                <DeleteModal
                    isOpen={deleteConfirmationOpen}
                    onAction={e => {
                        e.stopPropagation();
                        dispatch(deleteAudience(audience.audienceId))
                            .then(() => {
                                setDeleteConfirmationOpen(false);
                            })
                            .catch(() => {
                                setDeleteConfirmationOpen(false);
                            });
                    }}
                    onCancel={e => {
                        e.stopPropagation();
                        setDeleteConfirmationOpen(false);
                    }}
                    title="Delete audience"
                >
                    <p>Click Delete to permanently delete this audience.</p>
                    <p>IMPORTANT: This action cannot be undone.</p>
                </DeleteModal>
                <ConfirmationModal
                    title={archived ? 'Unarchive Audience' : 'Archive Audience'}
                    isOpen={archiveConfirmationOpen}
                    pending={archivePending}
                    confirmationText={archived ? 'Unarchive' : 'Archive'}
                    onAction={(e: any) => {
                        e.stopPropagation();
                        setArchivePending(true);
                        handleArchive()
                            .then(() => {
                                setArchivePending(false);
                                setArchiveConfirmationOpen(false);
                            })
                            .catch(() => {
                                setArchivePending(false);
                                setArchiveConfirmationOpen(false);
                            });
                    }}
                    onCancel={(e: any) => {
                        e.stopPropagation();
                        setArchivePending(false);
                        setArchiveConfirmationOpen(false);
                    }}
                >
                    {!archived ? (
                        <p>
                            Archiving removes the audience from the active view.
                            Do you want to archive?
                        </p>
                    ) : (
                        <p>
                            Unarchiving restores the audience to the active
                            view. Do you want to unarchive?
                        </p>
                    )}
                </ConfirmationModal>
            </WithPermissions>
        </InfoCard>
    );
};

const EmptyContent = ({ archived }: { archived: boolean }) => {
    const dispatch = useAppDispatch();
    return (
        <div
            style={{
                minHeight: '50rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1.6rem'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '.8rem'
                }}
            >
                <h3
                    className="font-subtitle"
                    style={{
                        color: 'var(--color-ui-50)',
                        height: 'fit-content',
                        maxWidth: '40ch',
                        textAlign: 'center'
                    }}
                >
                    {archived ? (
                        <span>No archived audiences yet</span>
                    ) : (
                        <span>
                            No audiences have been created. Create a new
                            audience to get started.
                        </span>
                    )}
                </h3>
                {!archived && (
                    <p>
                        <a
                            className="link link--medium"
                            href="https://support.itsdandi.com/hc/en-us/articles/18355951169687-Audience-Creation"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Learn more about audience creation.
                        </a>
                    </p>
                )}
            </div>
            {!archived && (
                <WithPermissions actions={[RbacActions['Campaign/Edit']]}>
                    <Button onClick={() => dispatch(setModalOpen(true))}>
                        Create Audience
                    </Button>
                </WithPermissions>
            )}
        </div>
    );
};

const SyncStatus = ({ status }: { status: string }) => {
    let color = 'var(--color-ui-50)';
    let text = 'Sync Started';

    switch (status) {
        case 'FAILED':
            color = 'var(--color-fail)';
            text = 'Sync failed';
            break;
        case 'STARTED':
            color = 'var(--color-graph-2)';
            text = 'Sync started';
            break;
        default:
            break;
    }

    return (
        <div className="font-micro-text" style={{ color }}>
            {text}
        </div>
    );
};

export default Audiences;

import { StaticPermission } from '../admin/permissions/model';
import RbacActions from '../constants/RbacActions';
import hasPermission from './hasPermission';

interface Props {
    actions: RbacActions[];
    cb: () => any;
    staticPermissions: StaticPermission[];
}

export default function callIfAllowed({
    actions,
    cb,
    staticPermissions
}: Props) {
    const isAllowed = hasPermission(staticPermissions, actions);

    if (isAllowed) {
        return cb();
    }
    return;
}

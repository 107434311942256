import { Category } from '../../../datastudio/survey/category/types';
import { CadenceRunType, ISnapshotSurveyEditState } from './types';

export function validatePrivacy({
    state
}: {
    state: ISnapshotSurveyEditState;
}) {
    if (!state.privacy) {
        return false;
    }

    return true;
}

export function validateDistribution({
    state
}: {
    state: ISnapshotSurveyEditState;
}) {
    if (!state.distributionType) {
        return false;
    }

    if (state.distributionType === 'link') {
        if (!state.endDate) {
            return false;
        } else {
            return true;
        }
    } else if (state.distributionType === 'email') {
        if (!state.audienceId || !state.cadenceRunType || !state.endDate) {
            return false;
        } else {
            if (
                state.cadenceRunType === CadenceRunType.n_days &&
                !state.cadenceRunEachNDays
            ) {
                return false;
            }
            return true;
        }
    }

    return false;
}

export function validateStorage({
    state
}: {
    state: ISnapshotSurveyEditState;
}) {
    if (!state.storage) {
        return false;
    }

    return true;
}
export function validateMessaging({
    state
}: {
    state: ISnapshotSurveyEditState;
}) {
    let error =
        !state.welcomeSubject ||
        state.welcomeSubject === '' ||
        !state.thankYouSubject ||
        state.thankYouSubject === '';
    if (state.distributionType === 'email') {
        error = error || !state.emailSubject || state.emailSubject === '';
    }
    return !error;
}

export function validateGlobalSurvey({
    state
}: {
    state: ISnapshotSurveyEditState;
}): boolean {
    return !!state.globalSurveyId && state.categories.length >= 1;
}

export function validateAll({
    state
}: {
    state: ISnapshotSurveyEditState;
}): boolean {
    return (
        state.steps.every(step => step.validated) &&
        validateGlobalSurvey({ state })
    );
}

export function validateStep({
    index,
    state
}: {
    index: number;
    state: ISnapshotSurveyEditState;
}) {
    switch (index) {
        case 0:
            return validatePrivacy({ state });
        case 1:
            return validateDistribution({ state });
        case 2:
            return validateStorage({ state });
        case 3:
            return validateMessaging({ state });
        default:
            return false;
    }
}

export function reconstructCategories(categories: Category[]) {
    return categories.map(category => {
        const { questions, ...rest } = category;
        return {
            ...rest,
            globalQuestionIds: questions
        };
    });
}

import axios from 'axios';
import { push } from 'connected-react-router';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import qs from 'qs';
import { Link } from 'react-router-dom';
import * as uuid from 'uuid';

import { addMessage } from '../../common/actions';
import ActionTypes from '../../constants/ActionTypes';
import Constants from '../../constants/Constants';
import MixPanel from '../../constants/MixPanel';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';
import getMixPanelObjectForFilters from '../../lib/getMixPanelObjectForFilters';
import objectifyArray from '../../lib/objectifyArray';
import { track } from '../../lib/segment';
import { generate, getAllSegments, getTemplateFromAnalysisName } from '../../store/actionUtils';
import { getSegmentsFromV1Report, getSegmentsFromV2Report } from '../../store/commonUtils';

export function getReport(report, dashboardId) {
    return (dispatch, getState) => {
        const { version, configuration } = report;
        const chartType = configuration.find((c) => c.name === 'chartType').value;
        if (chartType === 'Text') {
            return;
        }

        const { attributes: allAttributes } = getState().explore.filter;

        const { reportId, analysisName, attributes, filter, breakdown, analysis, eventIds, isFilterApplied } = report;

        const subAnalyses = analysis?.map((a) => ({
            ...objectifyArray(a.configuration),
        }));

        const intervalConfig = configuration.find((c) => c.name === 'interval');
        const interval = intervalConfig ? intervalConfig.value : Constants.DefaultInterval;
        const startDateConfig = configuration.find((c) => c.name === 'startDate');
        const startDate = startDateConfig ? startDateConfig.value : undefined;
        let endDate;
        let intervalCount;
        if (startDate) {
            const endDateConfig = configuration.find((c) => c.name === 'endDate');
            endDate = endDateConfig ? endDateConfig.value : undefined;
        } else {
            const intervalCountConfig = configuration.find((c) => c.name === 'intervalCount');
            intervalCount = intervalCountConfig ? parseInt(intervalCountConfig.value) : Constants.DefaultIntervalCount;
        }

        let employeeAttribute;
        const employeeAttributeConfig = configuration.find((c) => c.name === 'employeeAttribute');
        if (employeeAttributeConfig) {
            employeeAttribute = employeeAttributeConfig.value;
        }

        let date;
        const dateConfig = configuration.find((c) => c.name === 'date');
        if (dateConfig) {
            date = moment.utc(dateConfig.value).format('YYYY-MM-DD');
        }

        const rowFilterConfig = configuration.find((c) => c.name === 'rowFilter');
        const rowFilter = rowFilterConfig ? JSON.parse(rowFilterConfig.value) : [];
        const columnFilterConfig = configuration.find((c) => c.name === 'columnFilter');
        const columnFilter = columnFilterConfig ? JSON.parse(columnFilterConfig.value) : [];

        let benchmark;
        const benchmarkConfig = configuration.find((c) => c.name === 'benchmark');
        if (benchmarkConfig) {
            benchmark = JSON.parse(benchmarkConfig.value);
        }

        const yAxisType = configuration.find((c) => c.name === 'yAxisType')?.value;

        const indexType = configuration.find((c) => c.name === 'indexType')?.value;

        return dispatch(getTemplateFromAnalysisName(analysisName)).then((template) => {
            const { variations } = template;
            let mainSegments, filterSegment, breakdownSegment;
            if (version === '2') {
                ({ mainSegments, filterSegment, breakdownSegment } = getSegmentsFromV2Report(
                    attributes,
                    filter,
                    breakdown
                ));
            } else {
                ({ mainSegments, filterSegment, breakdownSegment } = getSegmentsFromV1Report(
                    attributes,
                    employeeAttribute,
                    rowFilter,
                    columnFilter,
                    allAttributes,
                    variations
                ));
            }

            const state = {
                analysisName,
                attributes: allAttributes,
                benchmark,
                breakdownSegment,
                chartType,
                dashboardId,
                date,
                endDate,
                eventIds,
                filterSegment,
                indexType,
                interval,
                intervalCount,
                isFilterApplied,
                mainSegments,
                reportId,
                startDate,
                subAnalyses: subAnalyses || [],
                yAxisType,
            };

            const actionTypes = {
                reportPending: ActionTypes.GetReportPending,
                reportNoPending: ActionTypes.GetReportNoPending,
                subtablePending: ActionTypes.GetMatrixPending,
                reportRejected: ActionTypes.GetReportRejected,
                subtableRejected: ActionTypes.GetMatrixRejected,
                invalidVariation: ActionTypes.GetReportInvalidVariation,
                invalidAppliedFilterStatus: ActionTypes.SetInvalidAppliedFilterStatus,
                noData: ActionTypes.GetReportNoData,
                subtableFulfilled: ActionTypes.GetMatrixFulfilled,
                reportFulfilled: ActionTypes.GetReportFulfilled,
                setBenchmark: ActionTypes.SetBenchmarkInReport,
                primaryDataFulfilled: ActionTypes.GeneratePrimaryDataFulfilledInDashboard,
            };

            return generate(getState, dispatch, state, actionTypes);
        });
    };
}

export function getReports(reports, forceGet = false, dashboardId = '') {
    return (dispatch, getState) => {
        const { reports: reportsState } = getState();
        reports.forEach((report) => {
            if (forceGet || !reportsState[report.reportId]) {
                dispatch(getReport(report, dashboardId));
            }
        });
    };
}

export function navigateToDashboards() {
    return (dispatch) => {
        const url = '/';
        dispatch(push(url));
    };
}

export function navigateToEditor(dashboardId, reportId, row) {
    return (dispatch) => {
        let url = `/editor?dashboardId=${dashboardId}`;
        if (reportId) {
            url += `&reportId=${reportId}`;
        }
        if (row !== undefined && row !== null) {
            url += `&rowIndex=${row}`;
        }
        const state = window.pageYOffset > 0 ? { scrollPos: window.pageYOffset } : {};
        dispatch(push(url, state));
    };
}

export function showDeleteReportModal(reportId) {
    return {
        type: ActionTypes.ShowDeleteReportModal,
        reportId,
    };
}

export function showSelectDashboardModal(dashboardId, reportId) {
    return {
        type: ActionTypes.ShowSelectDashboardModal,
        dashboardId,
        reportId,
    };
}

export function showCreateDashboardModalForCopy() {
    return {
        type: ActionTypes.ShowCreateDashboardModalForCopy,
    };
}

export function closeDeleteReportModal() {
    return {
        type: ActionTypes.CloseDeleteReportModal,
    };
}

export function closeSelectDashboardModal() {
    return {
        type: ActionTypes.CloseSelectDashboardModal,
    };
}

export function closeCreateDashboardModalForCopy() {
    return {
        type: ActionTypes.CloseCreateDashboardModalForCopy,
    };
}

export function deleteReport(dashboard, reportId) {
    return (dispatch) => {
        dispatch({
            type: ActionTypes.DeleteReportPending,
        });

        const newDashboard = {
            ...dashboard,
            lastUpdatedAt: moment.utc().toISOString(),
        };
        newDashboard.reports = dashboard.reports.filter((r) => r.reportId !== reportId);

        return axios
            .put(`${Urls.DashboardsApi}dashboard`, { dashboard: newDashboard })
            .then(() => {
                dispatch({
                    type: ActionTypes.DeleteReportFulfilled,
                    dashboardId: dashboard.dashboardId,
                    reportId,
                });
                dispatch(closeDeleteReportModal());
            })
            .catch((error) => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.DeleteReportRejected,
                });
            });
    };
}

export function checkVault(vaultId) {
    return (dispatch, getState) => {
        const { enterpriseId } = getState().account;

        dispatch({
            type: ActionTypes.CheckVaultPending,
        });

        return axios
            .post(`${Urls.DashboardsApi}vault`, {
                enterpriseId,
                vaultId,
            })
            .then((response) => {
                const dashboardId = response.data;
                dispatch({
                    type: ActionTypes.CheckVaultFulfilled,
                    dashboardId,
                });
                return dashboardId;
            })
            .catch((error) => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.CheckVaultRejected,
                });
            });
    };
}

export function navigateToEditMode(dashboardId) {
    return (dispatch, getState) => {
        const dashboard = getState().dashboards[dashboardId];
        if (dashboard) {
            dispatch({
                type: ActionTypes.EditDashboard,
                dashboard,
            });
            const url = `/dashboard/${dashboardId}/edit`;
            const scrollPos = window.pageYOffset;
            dispatch(
                push(url, {
                    scrollPos,
                })
            );
        }
    };
}

export function setSearchText(searchText) {
    return {
        type: ActionTypes.SetSearchTextInDashboard,
        searchText,
    };
}

export function selectCurrentTime(reportId, currentIndex) {
    return {
        type: ActionTypes.SelectCurrentTimeInReport,
        reportId,
        currentIndex,
    };
}

export function navigate(reportId, navProps, employees, employeeCountType, currentIndex) {
    return (dispatch, getState) => {
        const { attributes } = getState().explore.filter;
        const report = getState().reports[reportId];
        if (!report) {
            return;
        }

        const {
            lifecycle,
            analysisType,
            dates,
            interval,
            intervalCount,
            startDate,
            endDate,
            employeeAttribute,
            filterSegment,
        } = report;

        let { mainSegments, chartType } = report;

        // should be table
        if (chartType === 'Table') {
            chartType = 'Line';
        }

        const navPropKeys = Object.keys(navProps);
        mainSegments = mainSegments.map((ms) => {
            const navPropKey = navPropKeys.find((npk) => ms.name === npk);
            if (navPropKey) {
                const values = ms.values.filter((v) => v === navProps[navPropKey]);
                return {
                    name: ms.name,
                    values,
                };
            }
            // should not come here!
            return ms;
        });

        const queryObject = {
            lifecycle,
            analysisType,
            mainSegments,
            filterSegment,
            interval,
            chartType,
            employeeAttribute,
        };

        if (startDate) {
            queryObject.startDate = startDate;
            queryObject.endDate = endDate;
        } else {
            queryObject.intervalCount = intervalCount;
        }

        queryObject.date = moment.utc(dates[currentIndex]).format('YYYY/MM/DD');

        const allSegments = getAllSegments({
            mainSegments,
            filterSegment,
        });

        const mixPanelObject = getMixPanelObjectForFilters(lifecycle, allSegments, attributes);
        const mixPanelProps = {
            analysisType,
            ...mixPanelObject,
        };

        track(MixPanel.Events.DashboardReportTableColumnClick, mixPanelProps);

        let url = '/explore?';
        const queryString = qs.stringify(queryObject, {
            encodeValuesOnly: true,
        });
        url += queryString;
        dispatch(
            push(url, {
                source: 'report',
            })
        );
        window.scrollTo(0, 0);
    };
}

export function copyReportToDashboards(dashboardIds) {
    return (dispatch, getState) => {
        const state = getState();
        const { selectDashboardId, selectReportId } = state.reports;
        const dashboard = state.dashboards[selectDashboardId];
        const report = dashboard.reports.find((r) => r.reportId === selectReportId);
        const reportIds = [];
        const promises = [];
        dashboardIds.forEach((dashboardId) => {
            const newReport = cloneDeep(report);
            newReport.reportId = uuid.v4();
            reportIds.push({
                from: report.reportId,
                to: newReport.reportId,
            });
            const promise = dispatch(copyReportToDashboard(newReport, dashboardId));
            promises.push(promise);
        });
        Promise.all(promises).then(() => {
            dispatch({ type: ActionTypes.CloneReportData, reportIds });
        });
    };
}

function copyReportToDashboard(report, dashboardId) {
    return (dispatch, getState) => {
        const { enterpriseId } = getState().account;
        const dashboard = getState().dashboards[dashboardId];

        dispatch({ type: ActionTypes.SaveReportPending });

        let reportRow = 1;
        let reportColumn = 1;
        if (dashboard.reports.length) {
            const lastReport = dashboard.reports[dashboard.reports.length - 1];
            const lastReportRow = parseInt(lastReport.configuration.find((c) => c.name === 'row').value);
            const lastReportColumn = parseInt(lastReport.configuration.find((c) => c.name === 'column').value);
            const lastReportLength = parseInt(lastReport.configuration.find((c) => c.name === 'length').value);
            reportColumn = lastReportColumn + lastReportLength;
            reportRow = lastReportRow;
            const reportLength = parseInt(report.configuration.find((c) => c.name === 'length').value);
            if (reportColumn + reportLength > 7) {
                reportRow += 1;
                reportColumn = 1;
            }
        }

        const rowConfig = report.configuration.find((c) => c.name === 'row');
        rowConfig.value = reportRow.toString();
        const columnConfig = report.configuration.find((c) => c.name === 'column');
        columnConfig.value = reportColumn.toString();

        return axios
            .put(`${Urls.DashboardsApi}report`, {
                enterpriseId,
                dashboardId,
                report,
            })
            .then(() => {
                dispatch({
                    type: ActionTypes.SaveReportFulfilled,
                    dashboardId,
                    report,
                });
                dispatch(closeSelectDashboardModal());
                dispatch(
                    addMessage(
                        <span>
                            {`Report added to ${dashboard.name}. View in `}
                            <Link to={`/dashboard/${dashboardId}`}>Dashboard</Link>
                        </span>
                    )
                );
            })
            .catch((error) => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.SaveReportRejected,
                });
            });
    };
}

export function createDashboardAndCopyReport(dashboardName) {
    return (dispatch, getState) => {
        const { enterpriseId } = getState().account;
        const { uid, firstName, lastName } = getState().auth.userInfo;

        dispatch({
            type: ActionTypes.CreateDashboardPending,
        });

        const dashboard = {
            enterpriseId,
            dashboardId: uuid.v4(),
            name: dashboardName,
            reports: [],
            dateAdded: moment.utc().toISOString(),
            lastUpdatedAt: moment.utc().toISOString(),
            createdById: uid,
            createdByName: `${firstName} ${lastName}`,
        };

        return axios
            .put(`${Urls.DashboardsApi}dashboard`, { dashboard })
            .then(() => {
                dispatch({
                    type: ActionTypes.CreateDashboardFulfilled,
                    dashboard,
                });
                return dispatch(copyReportToDashboards([dashboard.dashboardId]));
            })
            .then(() => {
                return dispatch(closeCreateDashboardModalForCopy());
            })
            .catch((error) => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.CreateDashboardRejected,
                });
            });
    };
}

export function setRowHeightInReport(reportId, tableRowHeight) {
    return {
        type: ActionTypes.SetRowHeightInReport,
        reportId,
        tableRowHeight,
    };
}

export function setSortCriteriaInReport(reportId, sortCriteria) {
    return {
        type: ActionTypes.SetSortCriteriaInReport,
        reportId,
        sortCriteria,
    };
}

export function setExpandedInReport(reportId, expanded) {
    return {
        type: ActionTypes.SetExpandedInReport,
        reportId,
        expanded,
    };
}

export function navigateFromMatrix(reportId, lifecycle, employeeAttribute, employeeAttributeValue, navProps) {
    return (dispatch, getState) => {
        const { attributes } = getState().explore.filter;
        const report = getState().reports[reportId];
        if (!report) return;

        const { lifecycle, analysisType, interval, employeeAttribute, filterSegment } = report;

        let { breakdownSegment } = report;

        let { mainSegments, date } = report;

        const navPropKeys = Object.keys(navProps);
        mainSegments = mainSegments.map((ms) => {
            const navPropKey = navPropKeys.find((npk) => ms.name === npk);
            if (navPropKey) {
                const values = ms.values.filter((v) => v === navProps[navPropKey]);
                return {
                    name: ms.name,
                    values,
                };
            }
            // should not come here!
            return ms;
        });

        breakdownSegment = {
            name: breakdownSegment.name,
            values: [employeeAttributeValue],
        };

        date = moment.utc(date).format('YYYY/MM/DD');

        const queryObject = {
            lifecycle,
            analysisType,
            mainSegments,
            filterSegment,
            breakdownSegment,
            interval,
            employeeAttribute,
            date,
        };

        const allSegments = getAllSegments({
            mainSegments,
            filterSegment,
            breakdownSegment,
        });

        const mixPanelObject = getMixPanelObjectForFilters(lifecycle, allSegments, attributes);
        const mixPanelProps = {
            analysisType,
            ...mixPanelObject,
        };

        track(MixPanel.Events.DashboardReportSubtableColumnClick, mixPanelProps);

        let url = '/explore?';
        const queryString = qs.stringify(queryObject, {
            encodeValuesOnly: true,
        });
        url += queryString;
        dispatch(
            push(url, {
                source: 'report',
            })
        );
        window.scrollTo(0, 0);
    };
}

export function setActiveLabel(label, reportId) {
    return {
        type: ActionTypes.SetActiveLabel,
        label,
        reportId,
    };
}

export function clearActiveLabel(label, reportId) {
    return {
        type: ActionTypes.ClearActiveLabel,
        label,
        reportId,
    };
}

export function toggleLabel(label, reportId) {
    return {
        type: ActionTypes.ToggleLabel,
        label,
        reportId,
    };
}

export function toggleLockedSelection(label, reportId) {
    return {
        type: ActionTypes.ToggleLockedSelection,
        label,
        reportId,
    };
}

export function clearSelection(reportId) {
    return {
        type: ActionTypes.ClearSelection,
        reportId,
    };
}

export function showDashboardFilters() {
    return {
        type: ActionTypes.ShowDashboardFilters,
    };
}

export function setShowHiddenReportsNotification(show) {
    return {
        type: ActionTypes.SetShowHiddenReportsNotification,
        show,
    };
}

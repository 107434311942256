import React, { MouseEvent } from 'react';
import { signOut } from './actions';
import { RouteComponentProps, withRouter } from 'react-router';
import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';
import MixPanel from '../constants/MixPanel';
import { track } from '../lib/segment';
import Dropdown from '../common/Dropdown';
import { setAccount } from './actions';
import { useAppDispatch, useAppSelector } from '../store/hooks';

function PhotoPopover({ history }: RouteComponentProps<{}>) {
    const dispatch = useAppDispatch();
    const userInfo = useAppSelector(state => state.auth.userInfo);
    const { enterpriseId, enterprises = [] } = useAppSelector(
        state => state.account
    );
    const { firstName, lastName, email } = userInfo;

    function handleLogout(e: MouseEvent) {
        e.preventDefault();
        dispatch(signOut());
    }

    function handleEdit() {
        track(MixPanel.Events.EditProfile);
        history.push('/edituser');
    }

    function sortAccounts(accounts: any) {
        return accounts.sort((a: any, b: any) => {
            const aName = a.label.toUpperCase();
            const bName = b.label.toUpperCase();
            return aName < bName ? -1 : aName > bName ? 1 : 0;
        });
    }

    let options: any[] = [];

    if (enterprises) {
        options = enterprises.map((enterprise: any) => ({
            label: enterprise.enterpriseName,
            value: enterprise.enterpriseId
        }));
    }

    return (
        <div className="photo-popover">
            <div className="photo-popover__right">
                <div className="photo-popover__top">
                    <div>
                        <div className="photo-popover__name">
                            {`${firstName} ${lastName}`}
                        </div>
                        <div className="photo-popover__email">{email}</div>
                        <div className="photo-popover__accounts">
                            {options.length > 1 && (
                                <Dropdown
                                    buttonType={ButtonTypes.type.TERTIARY}
                                    buttonLarge={false}
                                    inModal={true}
                                    options={sortAccounts(options)}
                                    onClick={(enterpriseId: any) => {
                                        dispatch(
                                            setAccount(enterpriseId, true)
                                        );
                                    }}
                                    selectedOption={options.find(
                                        (f: any) => f.value === enterpriseId
                                    )}
                                />
                            )}
                        </div>
                    </div>

                    <div className="photo-popover__top-right">
                        <Button
                            componentType={ButtonTypes.type.SECONDARY}
                            onClick={handleEdit}
                            large={false}
                        >
                            Edit
                        </Button>
                        <Button onClick={handleLogout} large={false}>
                            Log out
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default withRouter(PhotoPopover);

import React from 'react';
import { IconProps } from './types';

const ArrowUpRight = ({ size, color }: IconProps) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect x="4.07422" width="19" height="4" fill={color} />
            <rect
                x="23.0742"
                width="20"
                height="4"
                transform="rotate(90 23.0742 0)"
                fill={color}
            />
            <rect
                x="22.6289"
                y="2.82812"
                width="26.6936"
                height="4"
                transform="rotate(135 22.6289 2.82812)"
                fill={color}
            />
        </svg>
    );
};

export default ArrowUpRight;

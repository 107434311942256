import classNames from 'classnames';
import { ChangeEvent, KeyboardEvent, useEffect, useRef } from 'react';

interface MessageAreaProps {
    onBlur?: () => void;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onFocus?: () => void;
    onKeyDown?: (event: KeyboardEvent) => void;
    onSubmit?: (e: KeyboardEvent<Element>) => void;
    autoFocus?: boolean;
    componentType?: string;
    danger?: boolean;
    placeholder?: string;
    readOnly?: boolean;
    rows?: number;
    value: string;
}

const MessageArea = ({
    onBlur,
    onChange,
    onFocus,
    onKeyDown,
    onSubmit,
    autoFocus,
    componentType,
    danger,
    placeholder,
    readOnly,
    rows,
    value,
}: MessageAreaProps): JSX.Element => {
    const ref = useRef<HTMLTextAreaElement>(null);

    useEffect(() => {
        if (autoFocus && ref.current) {
            ref.current.focus();
        }
    }, [autoFocus]);

    useEffect(() => {
        if (ref.current) {
            ref.current.style.height = 'auto';
            const scrollHeight = ref.current.scrollHeight;
            ref.current.style.height = `${Math.min(scrollHeight - 28, 3 * 24)}px`;
        }
    }, [value]);

    const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        onChange?.(e);
    };

    const handleKeyDown = (e: KeyboardEvent<Element>) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if (value.trim() !== '') {
                onSubmit?.(e);
            }
        } else {
            onKeyDown?.(e);
        }
    };

    return (
        <textarea
            autoFocus={autoFocus}
            ref={ref}
            className={classNames(
                'px-[20px] py-[15px] rounded-[27px] overflow-hidden',
                'hover:border',
                'text-area',
                `text-area--${componentType}`,
                {
                    'text-area--danger': danger,
                }
            )}
            onBlur={onBlur}
            onChange={handleChange}
            onFocus={onFocus}
            onKeyDown={handleKeyDown}
            placeholder={placeholder}
            readOnly={readOnly}
            rows={1}
            value={value}
            style={{
                border: '1px solid var(--color-ui-100)',
                outline: 'none',
            }}
        />
    );
};

MessageArea.defaultProps = {
    componentType: 'default',
    onBlur: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    onSubmit: () => {},
    rows: 4,
};

MessageArea.displayName = 'TextArea';

export default MessageArea;

import Tooltip from 'antd/lib/tooltip';
import AvatarIcon from '../../icons/Avatar';
import OpenText from '../../common/OpenText';
import cn from '../../lib/cn';
import Avatar from '../../common/Avatar';
import AvatarTypes from '../../constants/AvatarTypes';

interface ChartTitleProps {
    title: string;
    source: string;
    isTextChart: boolean;
    isInsightsChart: boolean;
    showTooltip: boolean;
    editMode: boolean;
    disableExploreAccess: boolean;
    setTitle: (v: string) => void;
    handleExplore: () => void;
}

const ChartTitle = ({
    disableExploreAccess,
    editMode,
    isTextChart,
    isInsightsChart,
    title,
    source,
    showTooltip,
    setTitle,
    handleExplore
}: ChartTitleProps) => {
    if (isTextChart && !isInsightsChart) {
        return null;
    }

    if (isInsightsChart) {
        return (
            <div className={cn('flex items-center gap-[8px]')}>
                <Avatar
                    type={AvatarTypes.type.DEFAULT}
                    colorCode={1}
                    size={24}
                />
                <span className={cn('text-ui-75')}>{title}</span>
            </div>
        );
    }

    if (source === 'editor') {
        return (
            <OpenText
                value={title}
                onChange={(v: string) => setTitle(v)}
                autoFocus={false}
                showCloseAlways
                expand
                contentStyle={{ minWidth: '40%' }}
            />
        );
    }

    if (showTooltip) {
        return (
            <Tooltip
                title={title}
                placement="topLeft"
                overlayClassName="editor__chart__tooltip"
            >
                <span
                    className={
                        editMode || source === 'editor' || disableExploreAccess
                            ? ''
                            : 'editor__chart__header__title__clickable'
                    }
                    onClick={
                        editMode || source === 'editor' || disableExploreAccess
                            ? undefined
                            : handleExplore
                    }
                >
                    {title}
                </span>
            </Tooltip>
        );
    }

    return (
        <span
            className={
                editMode || disableExploreAccess
                    ? ''
                    : 'editor__chart__header__title__clickable'
            }
            onClick={
                editMode || disableExploreAccess ? undefined : handleExplore
            }
        >
            {title}
        </span>
    );
};

export default ChartTitle;

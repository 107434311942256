import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Animate, NodeGroup } from 'react-move';
import { arc, pie } from 'd3-shape';
import produce from 'immer';
import sortBy from 'lodash/sortBy';
import Constants from '../../constants/Constants';
import inlineFont from '../../constants/InlineFont';
import { track } from '../../lib/segment';
import MixPanel from '../../constants/MixPanel';
import FontFamilies from '../../constants/FontFamilies';
import measureText from '../../lib/measureText';
import CanvasFonts from '../../constants/CanvasFonts';
import classNames from 'classnames';
import BenchmarkColors from '../../constants/BenchmarkColors';
import getContrastedBenchmarkColors from '../../lib/getContrastedBenchmarkColors';
import cn from '../../lib/cn';
import ChartTooltip from '../../common/ChartTooltip';
import * as Tooltip from '@radix-ui/react-tooltip';

const BOTTOM_PADDING = 40;
const TOP_PADDING = 16;
const PADDING = 16;
const TABLE_MARGIN = 32;
const FONT_SIZE = 13;
const PIE_RADIUS = 26;
const MIN_INNER_RADIUS = 4;
const MIN_VALUE = 3;

export default function BenchmarkChartInner(props) {
    const {
        benchmarkName,
        width,
        height,
        reportId,
        dashboardName,
        title,
        disableInteractions,
        rowHeight,
        showPie
    } = props;
    const [valueSpacing, setValueSpacing] = useState(100);
    const radius = (Math.min(width, height) - TOP_PADDING - BOTTOM_PADDING) / 2;
    const [pieData, setPieData] = useState(generatePieData(props.data));
    const [pieHovered, setPieHovered] = useState(false);
    const hasInteraction = pieData.some(td => td.active || td.selected);
    const isSmallSize = width < 650;

    useEffect(() => {
        const maxValue = Math.max(...props.data.map(d => d.value));
        const textWidth = measureText(
            `${maxValue}%`,
            CanvasFonts.Regular13
        ).width;
        let valueSpacing = showPie
            ? width - 2 * radius - TABLE_MARGIN - 2 * PADDING - textWidth
            : width - 2 * PADDING - textWidth;
        let maxLength = 0;
        props.data.forEach(d => {
            const currentLength = measureText(
                d.label,
                CanvasFonts.Regular14
            ).width;
            if (currentLength > maxLength) {
                maxLength = currentLength;
            }
        });
        maxLength += PADDING + 13;
        valueSpacing = Math.min(maxLength, valueSpacing);
        setValueSpacing(valueSpacing);
    }, [props.data, width, radius, showPie]);

    useEffect(() => {
        setPieData(generatePieData(props.data));
    }, [props.data]);

    function generatePieData(data) {
        const colors = getContrastedBenchmarkColors(
            BenchmarkColors,
            data.length
        );

        const sortedData = sortBy(data, d => -d.value);
        const pieLayout = pie()
            .value(d => Math.max(d.value, MIN_VALUE))
            .sort((a, b) => {
                return b.value - a.value;
            });
        const pieData = pieLayout(sortedData);
        pieData.forEach(d => {
            d.color = colors[d.index];
        });
        return pieData;
    }

    function handleLabelActive(label) {
        if (disableInteractions) return;
        setPieData(prevState => {
            return produce(prevState, draftState => {
                const item = draftState.find(d => d.data.label === label);
                if (item) {
                    item.active = true;
                }
            });
        });
    }

    function handleLabelClear(label) {
        if (disableInteractions) return;
        setPieData(prevState => {
            return produce(prevState, draftState => {
                const item = draftState.find(d => d.data.label === label);
                if (item) {
                    item.active = false;
                }
            });
        });
    }

    function handleLabelToggle(label, e) {
        if (disableInteractions) return;
        e.stopPropagation();
        track(MixPanel.Events.DashboardReportBenchmarkChartSegmentClick, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
        setPieData(prevState => {
            return produce(prevState, draftState => {
                const item = draftState.find(d => d.data.label === label);
                if (item) {
                    item.selected = !item.selected;
                }
            });
        });
    }

    function handleClickOutside(e) {
        if (disableInteractions) return;
        e.stopPropagation();
        setPieData(prevState => {
            return produce(prevState, draftState => {
                draftState.forEach(d => {
                    d.selected = false;
                });
            });
        });
    }

    function getOpacity(item) {
        if (hasInteraction && !(item.active || item.selected)) {
            return 0.24;
        }
        return 1;
    }

    function getFillRadius(item) {
        if (item.active || item.selected) {
            return MIN_INNER_RADIUS;
        }
        return radius - PIE_RADIUS;
    }

    function getRingOpacity(item) {
        if (hasInteraction) {
            if (item.active || item.selected) {
                return 1;
            }
            return 0.32;
        }
        return 1;
    }

    let x = radius;
    let y = TOP_PADDING + radius;

    if (isSmallSize) {
        x = width / 2;
        y = height / 2 - TOP_PADDING;
    }
    const yTable = TOP_PADDING + (benchmarkName ? rowHeight : 0);
    const yName = TOP_PADDING + 10 + (rowHeight - 13) / 2;
    const xFooter = width - PADDING;
    const yFooter = height - 12;

    const arcPath = arc()
        .innerRadius(radius - 2)
        .outerRadius(radius);

    const fillPathWithoutInnerRadius = arc().outerRadius(radius - 2);

    return (
        <Tooltip.Provider>
            <svg
                className="benchmark"
                width={width}
                height={height}
                id={`${reportId}-benchmark`}
                onClick={handleClickOutside}
            >
                <defs>
                    <style type="text/css">{inlineFont}</style>
                </defs>
                <g
                    className="benchmark__chart"
                    transform={`translate(${x},${y})`}
                >
                    <NodeGroup
                        data={showPie ? pieData.slice() : []}
                        keyAccessor={d => d.data.label}
                        start={({ startAngle }) => ({
                            startAngle,
                            endAngle: startAngle,
                            borderOpacity: 1,
                            ringOpacity: 1,
                            radius: radius - PIE_RADIUS
                        })}
                        enter={({ startAngle, endAngle }) => {
                            const duration =
                                (2 *
                                    Constants.AnimationDuration *
                                    (endAngle - startAngle)) /
                                (2 * Math.PI);
                            const delay =
                                (2 * Constants.AnimationDuration * startAngle) /
                                (2 * Math.PI);
                            return {
                                startAngle: [startAngle],
                                endAngle: [endAngle],
                                timing: {
                                    duration,
                                    delay
                                }
                            };
                        }}
                        update={({ startAngle, endAngle, ...item }) => {
                            const duration =
                                (2 *
                                    Constants.AnimationDuration *
                                    (endAngle - startAngle)) /
                                (2 * Math.PI);
                            const delay =
                                (2 * Constants.AnimationDuration * startAngle) /
                                (2 * Math.PI);
                            return [
                                {
                                    startAngle: [startAngle],
                                    endAngle: [endAngle],
                                    timing: {
                                        duration,
                                        delay
                                    }
                                },
                                {
                                    radius: [getFillRadius(item)],
                                    ringOpacity: [getRingOpacity(item)],
                                    timing: {
                                        duration: Constants.AnimationDuration,
                                        ease: Constants.EasingFn
                                    }
                                }
                            ];
                        }}
                    >
                        {nodes => {
                            return (
                                <g className="benchmark__chart__pies">
                                    {nodes.map(({ key, data, state }) => {
                                        return (
                                            <Fragment key={key}>
                                                <path
                                                    className={cn(
                                                        'cursor-pointer hidden'
                                                    )}
                                                    d={arcPath(state)}
                                                    fill={data.color}
                                                    opacity={state.ringOpacity}
                                                    onMouseOver={handleLabelActive.bind(
                                                        null,
                                                        data.data.label
                                                    )}
                                                    onMouseOut={handleLabelClear.bind(
                                                        null,
                                                        data.data.label
                                                    )}
                                                    onClick={handleLabelToggle.bind(
                                                        null,
                                                        data.data.label
                                                    )}
                                                />
                                                <Tooltip.Root
                                                    open={
                                                        data.data.active &&
                                                        pieHovered
                                                    }
                                                    delayDuration={0}
                                                >
                                                    <Tooltip.Trigger asChild>
                                                        <path
                                                            className={cn(
                                                                'cursor-pointer'
                                                            )}
                                                            d={fillPathWithoutInnerRadius.innerRadius(
                                                                state.radius
                                                            )(state)}
                                                            fill={data.color}
                                                            opacity={
                                                                state.ringOpacity
                                                            }
                                                            onMouseOver={() => {
                                                                setPieHovered(
                                                                    true
                                                                );
                                                                handleLabelActive(
                                                                    data.data
                                                                        .label
                                                                );
                                                            }}
                                                            onMouseOut={() => {
                                                                setPieHovered(
                                                                    false
                                                                );
                                                                handleLabelClear(
                                                                    data.data
                                                                        .label
                                                                );
                                                            }}
                                                            onClick={() => {
                                                                handleLabelToggle(
                                                                    data.data
                                                                        .label
                                                                );
                                                            }}
                                                        />
                                                    </Tooltip.Trigger>
                                                    <Tooltip.Portal>
                                                        <Tooltip.Content
                                                            side="right"
                                                            sideOffset={8}
                                                        >
                                                            <ChartTooltip
                                                                dataPoint={{
                                                                    segment: {
                                                                        text: data
                                                                            ?.data
                                                                            .label
                                                                    },
                                                                    color: data?.color
                                                                }}
                                                                seriesItem={{
                                                                    percentage:
                                                                        data
                                                                            ?.data
                                                                            .value
                                                                }}
                                                                columnProps={[
                                                                    {
                                                                        header: 'Percentage',
                                                                        source: 'percentage',
                                                                        unit: '%'
                                                                    }
                                                                ]}
                                                                isBenchmark={
                                                                    true
                                                                }
                                                            />
                                                        </Tooltip.Content>
                                                    </Tooltip.Portal>
                                                </Tooltip.Root>
                                            </Fragment>
                                        );
                                    })}
                                </g>
                            );
                        }}
                    </NodeGroup>
                </g>
                {benchmarkName && (
                    <Animate
                        start={() => ({
                            x: showPie
                                ? 2 * radius + TABLE_MARGIN + PADDING
                                : PADDING
                        })}
                        update={() => ({
                            x: [
                                showPie
                                    ? 2 * radius + TABLE_MARGIN + PADDING
                                    : PADDING
                            ],
                            timing: {
                                duration: Constants.AnimationDuration,
                                ease: Constants.EasingFn
                            }
                        })}
                    >
                        {state => (
                            <text
                                className={cn(isSmallSize && 'hidden')}
                                x={isSmallSize ? width / 2 : state.x}
                                y={yName}
                                textAnchor={isSmallSize ? 'middle' : 'start'}
                                fill={
                                    isSmallSize
                                        ? 'var(--color-ui-75)'
                                        : 'var(--color-ui-100)'
                                }
                                style={{
                                    fontFamily: FontFamilies.Regular,
                                    fontSize: FONT_SIZE
                                }}
                            >
                                {benchmarkName}
                            </text>
                        )}
                    </Animate>
                )}
                <Animate
                    start={() => ({
                        x: showPie ? 2 * radius + TABLE_MARGIN : 0
                    })}
                    update={() => ({
                        x: [showPie ? 2 * radius + TABLE_MARGIN : 0],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                >
                    {state => (
                        <foreignObject
                            x={state.x}
                            y={yTable}
                            width={width - state.x}
                            height={height - yTable - BOTTOM_PADDING}
                            className={cn(isSmallSize && 'hidden')}
                        >
                            <table className="benchmark__table">
                                <tbody className="benchmark__table__body">
                                    {pieData.map((pd, i) => (
                                        <tr
                                            className={classNames(
                                                'benchmark__table__body__row',
                                                {
                                                    'benchmark__table__body__row--odd':
                                                        i % 2 === 0
                                                }
                                            )}
                                            key={pd.data.label}
                                            style={{
                                                height: rowHeight - 2,
                                                opacity: getOpacity(pd)
                                            }}
                                            onMouseOver={() =>
                                                handleLabelActive(pd.data.label)
                                            }
                                            onMouseOut={() =>
                                                handleLabelClear(pd.data.label)
                                            }
                                            onClick={handleLabelToggle.bind(
                                                null,
                                                pd.data.label
                                            )}
                                        >
                                            <td
                                                className="benchmark__table__body__row__cell-left"
                                                style={{
                                                    width: valueSpacing
                                                }}
                                            >
                                                <div className="benchmark__table__body__row__cell-left__label">
                                                    <svg
                                                        style={{
                                                            marginRight: '8px'
                                                        }}
                                                        width="5px"
                                                        height="5px"
                                                        viewBox="0 0 10 10"
                                                    >
                                                        <circle
                                                            fill={pd.color}
                                                            r="5"
                                                            cx="5"
                                                            cy="5"
                                                        ></circle>
                                                    </svg>

                                                    <div
                                                        style={{
                                                            maxWidth:
                                                                valueSpacing -
                                                                16,
                                                            whiteSpace:
                                                                'nowrap',
                                                            overflow: 'hidden',
                                                            textOverflow:
                                                                'ellipsis'
                                                        }}
                                                    >
                                                        {pd.data.label}
                                                    </div>
                                                </div>
                                            </td>
                                            <td
                                                className="benchmark__table__body__row__cell-right"
                                                style={{
                                                    width:
                                                        width -
                                                        state.x -
                                                        valueSpacing
                                                }}
                                            >
                                                {pd.data.value}%
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </foreignObject>
                    )}
                </Animate>
                <text
                    x={xFooter}
                    y={yFooter}
                    fill="rgba(242, 242, 242, 0.5)"
                    style={{
                        fontFamily: FontFamilies.Regular,
                        fontSize: 11
                    }}
                    textAnchor="end"
                >
                    EEOC data
                </text>
            </svg>
        </Tooltip.Provider>
    );
}

BenchmarkChartInner.defaultProps = {
    data: []
};

BenchmarkChartInner.propTypes = {
    benchmarkName: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    data: PropTypes.array,
    reportId: PropTypes.string,
    dashboardName: PropTypes.string,
    title: PropTypes.string,
    disableInteractions: PropTypes.bool,
    rowHeight: PropTypes.number,
    showPie: PropTypes.bool
};

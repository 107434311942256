import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x={1.037}
                y={1.969}
                width={50}
                height={50}
                rx={5}
                fill="var(--color-shade-h3)"
                stroke="#00fcff"
                strokeWidth={2}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M40.037 26.969h2c0-8.837-7.164-16-16-16h-.025v2h.025c7.732 0 14 6.268 14 14z"
                fill="var(--color-graph-6)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.025 40.97v2c8.837 0 16-7.164 16-16v-.025h-2v.025c0 7.732-6.268 14-14 14z"
                fill="var(--color-graph-5)"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M26.012 10.969c-8.825.013-15.975 7.171-15.975 16 0 8.828 7.15 15.986 15.975 16v-2c-7.72-.014-13.975-6.276-13.975-14s6.254-13.987 13.975-14v-2z"
                fill="var(--color-graph-14)"
            />
            <path fill="var(--color-shade-h3)" d="M25.38 10.969h2v32h-2z" />
            <path fill="var(--color-graph-6)" d="M27.38 11.682h2V25.97h-2z" />
            <path fill="var(--color-graph-6)" d="M41.668 23.97v2H27.38v-2z" />
            <path fill="var(--color-shade-h3)" d="M42.036 25.97v2H25.38v-2z" />
        </svg>
    );
}

export default SvgComponent;

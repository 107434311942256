import precisionRound from './precisionRound';
import AnalysisTypes from '../constants/AnalysisTypes';
import { units } from '../types';

export default function getFormattedValue(
    analysisType: string,
    value: number | undefined,
    unit?: units
) {
    if (typeof value === 'undefined') {
        return '---';
    }

    value = precisionRound(value, 1);

    if (analysisType === AnalysisTypes.Index) {
        return value + 'x';
    }
    if (analysisType === AnalysisTypes.LinearRegression) {
        if (value > 0) {
            return '+' + value + '%';
        }
    }

    if (!unit) {
        return value + '%';
    }

    return value + unit;
}

import FunnelChart from '../../common/FunnelChart';
import GridConstants from '../../constants/GridConstants';
import GenericTable from '../../common/GenericTable';
import StatsChart from '../../common/StatsChart';
import Matrix from '../../explore/detail/Table';
import ChartTypes from '../../constants/ChartTypes';
import HorizontalBarChart from '../../common/HorizontalBarChart';
import getDynamicDataProp from '../../lib/getDynamicDataProp';
import BarChart from './BarChart';
import BenchmarkChart from './BenchmarkChart';
import LineChart from './LineChart';
import PieChart from './PieChart';
import SingleTimeChart from './SingleTimeChart';
import StackedBarChart from './StackedBarChart';
import Table from './Table';
import TextChart from './TextChart';
import { useContext } from 'react';
import { GraphSizeContext } from '../../common/GraphWrapper';

const COLUMN_GAP = 16;
export default function ChartPicker({
    isResizing,
    sortCriteria,
    disableLinks,
    useMaxHeightOnTable,
    navigate,
    tableRowHeight,
    tableData,
    employeeAttribute,
    benchmarkContainerHeight,
    benchmarkRowHeight,
    employeeAttributes,
    groupSummary,
    showGroupSummary,
    generateNoData,
    attributeName,
    expanded,
    generatePending,
    data,
    chartType,
    title,
    reportId,
    options,
    editMode,
    textChartDispatch,
    textChartState,
    role,
    chartContainerBoundingBox,
    yAxisType,
    indexType,
    columnProps,
    dashboardName,
    dates,
    scrollAbleAreaHeight,
    interval,
    lifecycle,
    analysisType,
    handleLabelClear,
    handleLabelActive,
    handleLabelToggle,
    employeeCountHidden,
    onHover,
    onHoverEnd,
    source,
    onToggle,
    adjustedChartHeight,
    currentIndex,
    disableInteractions,
    selectCurrentTime,
    hasInvalidValue,
    setInnerChartOffsets,
    lightMode,
    benchmark,
    colWidth,
    diversityAttributes,
    hoveredItem,
    selection,
    status,
    handleNavigateToDetail,
    handleToggleExpansion,
    handleSort,
    summary
}: any) {
    const { width, height } = useContext(GraphSizeContext);

    if (
        data.length === 0 &&
        chartType !== ChartTypes.Text &&
        chartType !== ChartTypes.InsightsText
    ) {
        return (
            <div className="flex items-center text-center p-4 ">
                <div className="editor__chart__text">
                    There is no data for the selected filter
                </div>
            </div>
        );
    }

    if (
        chartType === ChartTypes.Text ||
        chartType === ChartTypes.InsightsText
    ) {
        return (
            <TextChart
                reportId={reportId}
                options={options}
                textChartDispatch={textChartDispatch}
                textChartState={textChartState}
                chartContainerBoundingBox={chartContainerBoundingBox}
                isInsights={chartType === ChartTypes.InsightsText}
            />
        );
    }

    const chartWidth = width;
    const dataProp = getDynamicDataProp(yAxisType, indexType, columnProps);

    if (chartType === ChartTypes.Line) {
        if (dates.length === 1) {
            return (
                <SingleTimeChart
                    dashboardName={dashboardName}
                    reportId={reportId}
                    title={title}
                    width={editMode ? chartWidth - 2 : chartWidth}
                    height={
                        editMode
                            ? scrollAbleAreaHeight - 2
                            : scrollAbleAreaHeight
                    }
                    data={data}
                    date={dates[0]}
                    interval={interval}
                    lifecycle={lifecycle}
                    analysisType={analysisType}
                    dataProp={dataProp}
                    setActiveLabel={handleLabelActive}
                    clearActiveLabel={handleLabelClear}
                    toggleLabel={handleLabelToggle}
                    yAxisType={yAxisType}
                    indexType={indexType}
                    employeeCountHidden={employeeCountHidden}
                    columnProps={columnProps}
                />
            );
        } else {
            return (
                <LineChart
                    dashboardName={dashboardName}
                    reportId={source === 'dashboard' ? reportId : source}
                    title={title}
                    editMode
                    width={chartWidth}
                    height={adjustedChartHeight}
                    data={data}
                    dates={dates}
                    interval={interval}
                    lifecycle={lifecycle}
                    analysisType={analysisType}
                    currentIndex={currentIndex}
                    selectCurrentTime={
                        disableInteractions
                            ? undefined
                            : source === 'dashboard'
                            ? selectCurrentTime.bind(null, reportId)
                            : selectCurrentTime
                    }
                    dataProp={dataProp}
                    setActiveLabel={handleLabelActive}
                    clearActiveLabel={handleLabelClear}
                    toggleLabel={handleLabelToggle}
                    status={status}
                    hasInvalidValue={hasInvalidValue}
                    setInnerChartOffsets={setInnerChartOffsets}
                    yAxisType={yAxisType}
                    indexType={indexType}
                    employeeCountHidden={employeeCountHidden}
                    columnProps={columnProps}
                    isResizing={isResizing}
                />
            );
        }
    }

    if (chartType === ChartTypes.Bar) {
        return (
            <BarChart
                dashboardName={dashboardName}
                title={title}
                reportId={reportId}
                editMode
                width={editMode ? chartWidth - 2 : chartWidth}
                height={
                    editMode ? adjustedChartHeight - 2 : adjustedChartHeight
                }
                data={data}
                dates={dates}
                interval={interval}
                lifecycle={lifecycle}
                analysisType={analysisType}
                currentIndex={currentIndex}
                length={length}
                dataProp={dataProp}
                setActiveLabel={handleLabelActive}
                clearActiveLabel={handleLabelClear}
                toggleLabel={handleLabelToggle}
                yAxisType={yAxisType}
                indexType={indexType}
                employeeCountHidden={employeeCountHidden}
                columnProps={columnProps}
                lightMode={lightMode}
                isResizing={isResizing}
            />
        );
    }

    if (chartType === ChartTypes.Pie && !benchmark) {
        return (
            <PieChart
                dashboardName={dashboardName}
                title={title}
                reportId={reportId}
                width={editMode ? chartWidth - 2 : chartWidth}
                height={height}
                data={data}
                dates={dates}
                interval={interval}
                lifecycle={lifecycle}
                analysisType={analysisType}
                currentIndex={currentIndex}
                setActiveLabel={handleLabelActive}
                toggleLabel={handleLabelToggle}
                clearActiveLabel={handleLabelClear}
                length={length}
                colWidth={colWidth}
                selectCurrentTime={
                    disableInteractions
                        ? undefined
                        : source === 'dashboard'
                        ? selectCurrentTime.bind(null, reportId)
                        : selectCurrentTime
                }
                dataProp={dataProp}
                yAxisType={yAxisType}
                employeeCountHidden={employeeCountHidden}
                columnProps={columnProps}
            />
        );
    }

    if (chartType === ChartTypes.StackedBar && !benchmark) {
        return (
            <StackedBarChart
                dashboardName={dashboardName}
                title={title}
                reportId={reportId}
                width={editMode ? chartWidth - 2 : chartWidth}
                height={
                    editMode ? adjustedChartHeight - 2 : adjustedChartHeight
                }
                data={data}
                dates={dates}
                interval={interval}
                lifecycle={lifecycle}
                analysisType={analysisType}
                currentIndex={currentIndex}
                setActiveLabel={handleLabelActive}
                toggleLabel={handleLabelToggle}
                clearActiveLabel={handleLabelClear}
                length={length}
                colWidth={colWidth}
                selectCurrentTime={
                    disableInteractions
                        ? undefined
                        : source === 'dashboard'
                        ? selectCurrentTime.bind(null, reportId)
                        : selectCurrentTime
                }
                dataProp={dataProp}
                yAxisType={yAxisType}
                employeeCountHidden={employeeCountHidden}
                columnProps={columnProps}
            />
        );
    }

    if (benchmark) {
        const colWidth =
            (width - (GridConstants.ColumnCount - 1) * COLUMN_GAP) /
            GridConstants.ColumnCount;
        return (
            <BenchmarkChart
                reportId={reportId}
                width={width}
                height={height}
                containerHeight={benchmarkContainerHeight}
                data={data}
                dates={dates}
                interval={interval}
                lifecycle={lifecycle}
                analysisType={analysisType}
                currentIndex={currentIndex}
                length={GridConstants.ColumnCount}
                colWidth={colWidth}
                selectCurrentTime={selectCurrentTime.bind(null, reportId)}
                rowHeight={benchmarkRowHeight}
                benchmarkData={benchmark}
                showPie={!benchmark.hidePie}
                disableInteractions={disableInteractions}
                dataProp={dataProp}
                setActiveLabel={handleLabelActive}
                clearActiveLabel={handleLabelClear}
                toggleLabel={handleLabelToggle}
                columnProps={columnProps}
            />
        );
    }

    if (chartType === ChartTypes.Table) {
        return (
            <Table
                dashboardName={dashboardName}
                title={title}
                reportId={reportId}
                width={editMode ? width - 2 : width}
                height={editMode ? height - 2 : height}
                data={tableData}
                dates={dates}
                interval={interval}
                lifecycle={lifecycle}
                analysisType={analysisType}
                currentIndex={currentIndex}
                pending={generatePending}
                disableLinks={disableLinks}
                tableRowHeight={tableRowHeight}
                options={options}
                useMaxHeightOnTable={useMaxHeightOnTable}
                navigate={navigate}
                expanded={expanded}
                toggleExpansion={handleToggleExpansion}
                sortCriteria={sortCriteria}
                onSort={handleSort}
                columnProps={columnProps}
                setActiveLabel={handleLabelActive}
                clearActiveLabel={handleLabelClear}
                toggleLabel={handleLabelToggle}
            />
        );
    }

    if (chartType === ChartTypes.Stats) {
        return <StatsChart data={data} />;
    }

    if (chartType === ChartTypes.ArithmeticTable) {
        return (
            <GenericTable
                data={data}
                mode={
                    source === 'editor'
                        ? 'editor'
                        : editMode
                        ? 'dashboard-edit'
                        : 'dashboard-view'
                }
                onHover={onHover}
                onHoverEnd={onHoverEnd}
                onToggle={onToggle}
                status={status}
                hoveredItem={hoveredItem}
                selection={selection}
            />
        );
    }

    if (chartType === ChartTypes.Funnel) {
        return (
            <FunnelChart
                mode={
                    source === 'editor'
                        ? 'editor'
                        : editMode
                        ? 'dashboard-edit'
                        : 'dashboard-view'
                }
                data={data}
                reportId={reportId}
                onHover={onHover}
                onHoverEnd={onHoverEnd}
                onToggle={onToggle}
                status={status}
                hoveredItem={hoveredItem}
                selection={selection}
            />
        );
    }

    if (chartType === ChartTypes.Matrix) {
        return (
            <Matrix
                lifecycle={lifecycle}
                analysisType={analysisType}
                employeeAttribute={employeeAttribute}
                attributeName={attributeName}
                employeeAttributes={employeeAttributes}
                diversityAttributes={diversityAttributes}
                data={data}
                summary={summary}
                showGroupSummary={showGroupSummary}
                groupSummary={groupSummary}
                pending={generatePending}
                emptyState={generateNoData}
                expanded={expanded}
                toggleExpansion={handleToggleExpansion}
                onSort={handleSort}
                sortCriteria={sortCriteria}
                navigateToDetail={handleNavigateToDetail}
                disableLinks={role === 'Viewer'}
                columnProps={columnProps}
                setActiveLabel={handleLabelActive}
                clearActiveLabel={handleLabelClear}
                toggleLabel={handleLabelToggle}
            />
        );
    }
    if (chartType === ChartTypes.HorizontalBar) {
        return (
            <HorizontalBarChart
                data={data}
                diversityAttributes={diversityAttributes}
                columnProps={columnProps}
                onHover={onHover}
                onHoverEnd={onHoverEnd}
                onToggle={onToggle}
                status={status}
                hoveredItem={hoveredItem}
                selection={selection}
            />
        );
    }

    return null;
}

import React, { useState, useEffect, useCallback } from 'react';
import { connect } from 'react-redux';
import Tooltip from 'antd/lib/tooltip';

import { deleteActor, grant } from '../actions';
import { updateActor } from '../../../auth/actions';
import Trash from '../../../icons/Trash';
import DeleteModal from '../DeleteModal';
//import RoleDropdown from './RoleDropdown';
import { track } from '../../../lib/segment';
import MixPanel from '../../../constants/MixPanel';
import Loader from '../../../lottie/graph-loader.json';
import LottieAnimation from '../../../common/LottieAnimation';
import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import { Actor } from '../models';
import Table from '../../Table';
import UserRoleHandler from './UserRoleHandler';
import { useAppSelector, useAppDispatch } from '.././../../store/hooks';
import User from './User';
import WithPermissions from '../../../common/WithPermissions';
import useFilteredAndSortedItems from '../../useFilteredAndSortedUsers';
import useSortable from '../../useSortable';
import RbacActions from '../../../constants/RbacActions';

interface UserListProps {}

function UserList(props: UserListProps) {
    const dispatch = useAppDispatch();
    const { allUsers, usersLoaded, deleteActorPending } = useAppSelector(
        state => state.admin.users
    );
    const { searchText } = useAppSelector(state => state.admin.adminConfig);
    const { rolesLoaded } = useAppSelector(state => state.admin.roles);
    const { actorId: currentActorId } = useAppSelector(
        state => state.auth.userInfo
    );
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [actorId, setActorId] = useState<string>();
    const [userName, setUserName] = useState<string>();
    const [email, setEmail] = useState<string>();
    const { requestSort, sortConfig } = useSortable();
    const [keepUserOnTop, setKeepUserOnTop] = useState(true);

    const usersCopy = useCallback(
        (keepUserOnTop?: boolean) => {
            let usersCopy: any[] = allUsers.slice();

            usersCopy = usersCopy.map(user => ({
                ...user,
                displayName: user.firstName
                    ? `${user.firstName} ${user.lastName}`.toLowerCase()
                    : user.email.toLowerCase(),

                searchValue: user.firstName
                    ? `${user.firstName} ${user.lastName}`.toLowerCase()
                    : user.email.toLowerCase()
            }));

            return usersCopy;
        },
        [allUsers]
    );

    const filteredAndSortedUsers = useFilteredAndSortedItems({
        items: usersCopy(keepUserOnTop),
        searchText,
        sortConfig,
        keepOnTop: keepUserOnTop ? currentActorId : undefined
    });

    function getUserNameFromActorId(actorId: string) {
        const actor = allUsers.find(a => a.actorId === actorId);
        return actor && actor.firstName
            ? `${actor.firstName} ${actor.lastName}`
            : '';
    }

    function handleDelete(actorId: string, email: string) {
        if (actorId !== currentActorId) {
            const userName = getUserNameFromActorId(actorId);
            track(MixPanel.Events.AdminClickDelete, { userName });
            setShowDeleteModal(true);
            setActorId(actorId);
            setUserName(userName);
            setEmail(email);
        }
    }

    function handleAction() {
        dispatch(deleteActor(actorId as string, email as string)).then(() => {
            track(MixPanel.Events.AdminDeleteUser, { userName });
            setShowDeleteModal(false);
        });
    }

    function handleCancel() {
        const userName = getUserNameFromActorId(actorId as string);
        track(MixPanel.Events.AdminDeleteUserCancel, { userName });
        setShowDeleteModal(false);
    }

    React.useEffect(() => {
        requestSort('displayName', 'string');
    }, []);

    return usersLoaded ? (
        <Table>
            {searchText && filteredAndSortedUsers.length === 0 && (
                <div className="user-table__empty">
                    Sorry, no users match your search query
                </div>
            )}
            {filteredAndSortedUsers.length > 0 && (
                <Table.HeadRow base={10}>
                    <Table.Cell span={3}>
                        <Table.Sortable
                            onClick={() => {
                                requestSort('displayName', 'string');
                                setKeepUserOnTop(false);
                            }}
                            sortActive={sortConfig.key === 'displayName'}
                            sortDirection={sortConfig.direction}
                        >
                            <span>Name</span>
                        </Table.Sortable>
                    </Table.Cell>
                    <Table.Cell span={6}>
                        <WithPermissions actions={[RbacActions['Roles/View']]}>
                            <span>Role</span>
                        </WithPermissions>
                    </Table.Cell>
                    <Table.Cell span={1}> {''}</Table.Cell>
                </Table.HeadRow>
            )}
            {filteredAndSortedUsers.map(actor => (
                <Table.Row base={10} key={actor.actorId}>
                    <Table.Cell span={3}>
                        <User currentActorId={currentActorId} actor={actor} />
                    </Table.Cell>
                    <Table.Cell span={6}>
                        <UserRoleHandler
                            actor={actor}
                            currentActorId={currentActorId}
                        />
                    </Table.Cell>
                    <Table.Cell span={1} justifyContent="flex-end">
                        <WithPermissions actions={[RbacActions['Users/Edit']]}>
                            <Tooltip
                                title={
                                    currentActorId === actor.actorId
                                        ? 'You cannot delete your own account'
                                        : 'Delete'
                                }
                                placement="bottom"
                            >
                                <Button
                                    componentType={ButtonTypes.type.TERTIARY}
                                    circle={true}
                                    onClick={() =>
                                        handleDelete(actor.actorId, actor.email)
                                    }
                                    disabled={currentActorId === actor.actorId}
                                >
                                    <Trash width={24} />
                                </Button>
                            </Tooltip>
                        </WithPermissions>
                    </Table.Cell>
                </Table.Row>
            ))}
            <WithPermissions actions={[RbacActions['Users/Edit']]}>
                <DeleteModal
                    deleteActorPending={deleteActorPending}
                    isOpen={showDeleteModal}
                    onAction={handleAction}
                    onCancel={handleCancel}
                />
            </WithPermissions>
        </Table>
    ) : (
        <div className="user-table--loading">
            <LottieAnimation
                autoplay={true}
                loop={true}
                width={30}
                height={30}
                animation={Loader}
            ></LottieAnimation>
        </div>
    );
}

UserList.defaultProps = {
    actors: []
};

export default UserList;

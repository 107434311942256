import { useCallback } from 'react';
import getChartDeclaration, {
    IChartDeclarationProps,
    IChartDeclaration
} from '../lib/getChartDeclaration';

const useChartDeclaration = (
    props: IChartDeclarationProps
): IChartDeclaration => {
    const chartDeclaration = useCallback(() => {
        return getChartDeclaration(props);
    }, [props]);

    return chartDeclaration();
};

export default useChartDeclaration;

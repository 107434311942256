import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 25 25"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <circle cx={5.929} cy={12.414} r={2} />
            <circle cx={12.929} cy={12.414} r={2} />
            <circle cx={19.929} cy={12.414} r={2} />
        </svg>
    );
}

export default SvgComponent;

import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
    const fill = props.fill || '#20B049';

    return (
        <svg
            width="88"
            height="88"
            viewBox="0 0 88 88"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <path
                d="M44 51.3334C46.0167 51.3334 47.7431 50.6153 49.1792 49.1792C50.6153 47.7431 51.3334 46.0167 51.3334 44C51.3334 41.9834 50.6153 40.257 49.1792 38.8209C47.7431 37.3848 46.0167 36.6667 44 36.6667C41.9834 36.6667 40.257 37.3848 38.8209 38.8209C37.3847 40.257 36.6667 41.9834 36.6667 44C36.6667 46.0167 37.3847 47.7431 38.8209 49.1792C40.257 50.6153 41.9834 51.3334 44 51.3334ZM29.3334 66H58.6667V63.8917C58.6667 62.425 58.2695 61.0806 57.475 59.8584C56.6806 58.6362 55.5806 57.7195 54.175 57.1084C52.5861 56.4362 50.9514 55.9167 49.2709 55.55C47.5903 55.1834 45.8334 55 44 55C42.1667 55 40.4097 55.1834 38.7292 55.55C37.0486 55.9167 35.4139 56.4362 33.825 57.1084C32.4195 57.7195 31.3195 58.6362 30.525 59.8584C29.7306 61.0806 29.3334 62.425 29.3334 63.8917V66ZM67.8334 80.6667H20.1667C18.7 80.6667 17.4167 80.1167 16.3167 79.0167C15.2167 77.9167 14.6667 76.6334 14.6667 75.1667V12.8334C14.6667 11.3667 15.2167 10.0834 16.3167 8.98337C17.4167 7.88337 18.7 7.33337 20.1667 7.33337H51.425L73.3334 29.2417V75.1667C73.3334 76.6334 72.7834 77.9167 71.6834 79.0167C70.5834 80.1167 69.3 80.6667 67.8334 80.6667Z"
                fill={fill}
            />
        </svg>
    );
};
export default SvgComponent;

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Tooltip from 'antd/lib/tooltip';
import measureText from '../lib/measureText';
import CanvasFonts from '../constants/CanvasFonts';

interface LongTextProps {
    text: string;
    tooltipClassName: string;
    fontSize?: string;
    refreshKey: string | boolean;
}

export default function LongText({
    text,
    tooltipClassName,
    fontSize,
    refreshKey,
    ...props
}: LongTextProps) {
    const [showTooltip, setShowTooltip] = useState(false);
    const textRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (textRef.current) {
            const expectedWidth = measureText(text, fontSize).width;
            const actualWidth = textRef.current.offsetWidth;
            setShowTooltip(expectedWidth > actualWidth);
        }
    }, [text, refreshKey]);

    return (
        <div className="long-text" ref={textRef} {...props}>
            {showTooltip ? (
                <Tooltip title={text} overlayClassName={tooltipClassName}>
                    {text}
                </Tooltip>
            ) : (
                text
            )}
        </div>
    );
}

LongText.defaultProps = {
    fontSize: CanvasFonts.Regular14
};

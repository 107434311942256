import Papa from 'papaparse';
import { saveAs } from 'file-saver';
import Constants from '../../constants/Constants';
import getFormattedValue from '../../lib/getFormattedValue';
import getPeriod from '../../lib/getPeriod';
import ChartTypes from '../../constants/ChartTypes';
import {
    generateDefaultTemplate,
    generateFunnelTemplate
} from '../../common/GenericTable/transformations';
import blankEmployeeCount from '../../lib/blankEmployeeCount';

function getFields(columnProps, dates, interval) {
    const fields = ['Segment'];
    dates.forEach(d => {
        const period = getPeriod(d, interval);
        columnProps.forEach(columnProp => {
            fields.push(`${period} ${columnProp.header}`);
        });
    });
    return fields;
}

function getData(analysisType, columnProps, seriesItem) {
    const primaryField = columnProps[0].source;
    if (!seriesItem || seriesItem[primaryField] === Constants.InvalidValue) {
        return columnProps.map(() => '---');
    }
    return columnProps.map((columnProp, i) => {
        let val = seriesItem[columnProp.source];
        if (i === 0) {
            val = getFormattedValue(analysisType, val);
        }
        return val;
    });
}

export default function exportCsv({
    dashboardName,
    reportName,
    lifecycle,
    analysisType,
    columnProps,
    data,
    dates,
    interval,
    benchmark,
    shouldReturnCsv,
    subAnalysisFields,
    subAnalysisMapping,
    chartType
}) {
    let fields = [];
    let dataArray = [];
    let csv;

    if (
        chartType === ChartTypes.ArithmeticTable ||
        chartType === ChartTypes.Stats
    ) {
        const tableData = generateDefaultTemplate(data, null, []);
        fields.push('SubAnalysis');
        fields.push(...tableData.rightTable.head.map(column => column.label));

        tableData.leftTable.body.forEach((row, index) => {
            let rowData = [];
            rowData.push(row.columns[0].label);
            rowData.push(
                ...tableData.rightTable.body[index].columns.map(
                    column => column.label
                )
            );
            dataArray.push(rowData);
        });

        csv = Papa.unparse({
            fields,
            data: dataArray
        });
    } else if (chartType === ChartTypes.Funnel) {
        const tableData = generateFunnelTemplate(
            data,
            subAnalysisFields,
            subAnalysisMapping,
            null,
            []
        );
        fields.push('Stages');
        tableData.rightTable.head.forEach(column => {
            fields.push(column.label);
            fields.push('');
        });
        let subHeadRow = [''];
        tableData.rightTable.subHead.forEach(column => {
            subHeadRow.push(column.label);
        });
        dataArray.push(subHeadRow);

        tableData.leftTable.body.forEach((row, index) => {
            let rowData = [];
            rowData.push(row.columns[0].label);
            rowData.push(
                ...tableData.rightTable.body[index].columns.map(c => c.label)
            );

            dataArray.push(rowData);
        });

        csv = Papa.unparse({
            fields,
            data: dataArray
        });
    } else {
        fields = getFields(columnProps, dates, interval);

        dataArray = data.map(d => {
            let rowData = [d.segment.text];
            dates.forEach(dt => {
                const period = getPeriod(dt, interval);
                const seriesItem = d.series.find(
                    s => getPeriod(s.date, interval) === period
                );
                rowData = rowData.concat(
                    getData(analysisType, columnProps, seriesItem)
                );
                // hide employee count when -1
                rowData = rowData.map(blankEmployeeCount);
            });
            return rowData;
        });
        if (benchmark) {
            let data = [fields];
            data = data.concat(dataArray);
            data.push([]);
            data.push(['Benchmark']);
            data.push(['Segment', 'Representation']);
            const benchmarkData = benchmark.data.map(d => [
                d.label,
                d.value + '%'
            ]);
            data = data.concat(benchmarkData);
            csv = Papa.unparse(data);
        } else {
            csv = Papa.unparse({
                data: dataArray,
                fields
            });
        }
    }

    let filename = `${dashboardName}_${reportName}.csv`;
    filename = filename.replace(/ /g, '_');

    if (shouldReturnCsv) {
        return {
            filename,
            csv
        };
    }

    const blob = new Blob([csv]);
    saveAs(blob, filename);
}

const canvasFonts = {
    Regular8: '8px Dandi Grotesk Regular',
    Regular10: '10px Dandi Grotesk Regular',
    Regular11: '11px Dandi Grotesk Regular',
    Regular12: '12px Dandi Grotesk Regular',
    Regular14: '14px Dandi Grotesk Regular',
    Regular16: '16px Dandi Grotesk Regular',
    Regular18: '18px Dandi Grotesk Regular',
    Regular24: '24px Dandi Grotesk Regular',
    Regular32: '32px Dandi Grotesk Regular',
    Regular36: '36px Dandi Grotesk Regular',
    Regular40: '40px Dandi Grotesk Regular',
    Regular48: '48px Dandi Grotesk Regular',

    Medium11: '11px Dandi Grotesk Medium',
    Medium12: '12px Dandi Grotesk Medium',
    Medium13: '13px Dandi Grotesk Medium',
    Medium14: '14px Dandi Grotesk Medium',
    Medium18: '18px Dandi Grotesk Medium',
    Medium24: '24px Dandi Grotesk Medium'
};

export default canvasFonts;

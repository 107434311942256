import { DropdownAction, DropdownState, DropdownActions } from './types';

function renderLabel(state: DropdownState, newLookup: any) {
    const label = Object.keys(newLookup)
        .filter(key => newLookup[key].checked)
        .map(key => {
            if (key === 'Any' || !state.data.length) {
                return key;
            }
            if (state.multiLevel) {
                if (newLookup[key].activeIds?.length > 1) {
                    return `${key} (${newLookup[key].activeIds.length})`;
                } else {
                    const selectedValue = state.data
                        .find(d => d[state.level1.typeId] === key)
                        ?.attributeValues.find(
                            (v: any) => v.value === newLookup[key].activeIds[0]
                        )?.text;

                    return `${key} (${selectedValue})`;
                }
            } else {
                return key;
            }
        })
        .join(', ');

    return label;
}

const handleClear = (state: DropdownState) => {
    const newLookup: any = resetLookup(state.data, state.level1.typeId);

    runReduxSideEffect(state, newLookup);

    return {
        ...state,
        status: 'idle',
        label: undefined,
        lookup: newLookup
    };
};

const resetLookup = (data: any[], typeId: string) => {
    const lookup = data.reduce((acc: any, item: any) => {
        acc[item[typeId]] = {
            checked: false,
            activeIds: []
        };
        return acc;
    }, {});

    return {
        ...lookup,
        Any: {
            checked: false
        }
    };
};

const setLookupBasedOnSelection = (
    data: any[],
    typeId: string,
    existingSelection: any[]
) => {
    const isAny =
        existingSelection?.length === 1 &&
        (existingSelection[0][typeId] === '*' ||
            existingSelection[0]?.name === '*');

    const lookup = data.reduce((acc: any, item: any) => {
        const existing = existingSelection?.find(
            (s: any) => (s[typeId] || s?.name) === item[typeId]
        );

        acc[item[typeId]] = {
            checked: !!existing,
            activeIds: existing?.values || [],
            isAll: existing?.values?.length === item?.attributeValues?.length
        };
        return acc;
    }, {});

    return {
        ...lookup,
        Any: {
            checked: isAny
        }
    };
};

const handleToggleAny = (state: DropdownState, action: DropdownAction) => {
    const isAny = state.lookup.Any?.checked;

    const newLookup = resetLookup(state.data, state.level1.typeId);

    const label = isAny ? undefined : 'Any';

    runReduxSideEffect(state, {
        ...newLookup,
        Any: {
            ...newLookup.Any,
            checked: !isAny
        }
    });

    return {
        ...state,
        activeId: undefined,
        lookup: {
            ...newLookup,
            Any: {
                ...newLookup.Any,
                checked: !isAny
            }
        },
        label
    };
};

const convertToReduxStructureForAnalysises = (
    state: DropdownState,
    lookup: any
) => {
    const { data } = state;
    const newAnalysises: any[] = [];

    if (lookup.Any?.checked) {
        newAnalysises.push({
            analysisName: '*'
        });
    } else {
        data.forEach((template: any) => {
            if (lookup[template.analysisName]?.checked) {
                newAnalysises.push({
                    analysisName: template.analysisName,
                    analysisType: template.analysisType
                });
            }
        });
    }

    return newAnalysises;
};

const convertToReduxStructure = (state: DropdownState, lookup: any) => {
    const { data } = state;

    const newStructure: any[] = [];
    if (lookup.Any?.checked) {
        newStructure.push({
            name: '*'
        });
    } else {
        data.forEach((attribute: any) => {
            if (lookup[attribute.attributeName]?.checked) {
                const values: string[] = [];

                lookup[attribute.attributeName].activeIds.forEach(
                    (id: string) => {
                        values.push(
                            attribute.attributeValues.find(
                                (av: any) => av.value === id
                            )?.value
                        );
                    }
                );

                newStructure.push({
                    name: attribute.attributeName,
                    values
                });
            }
        });
    }

    return newStructure;
};

const runReduxSideEffect = (state: DropdownState, newLookup: any) => {
    let reduxStructure: any = [];
    if (state.level1.typeId === 'analysisName') {
        reduxStructure = convertToReduxStructureForAnalysises(state, newLookup);
    } else {
        reduxStructure = convertToReduxStructure(state, newLookup);
    }

    state.reduxAction(state.parentId, reduxStructure);
};

const handleToggleItem = (state: DropdownState, action: DropdownAction) => {
    const checked = !state.lookup[action.payload].checked;
    const dataItem = state.data.find(
        (d: any) => d[state.level1.typeId] === action.payload
    );
    const activeIds =
        checked && state.multiLevel
            ? dataItem.attributeValues.map((v: any) => v.value)
            : [];

    const isAll = activeIds.length === dataItem?.attributeValues?.length;

    const newLookup: any = {
        ...state.lookup,
        [action.payload]: {
            ...state.lookup[action.payload],
            checked,
            activeIds,
            isAll
        }
    };

    const label = renderLabel(state, newLookup);
    runReduxSideEffect(state, newLookup);

    return {
        ...state,
        activeId: checked ? action.payload : undefined,
        lookup: newLookup,
        label
    };
};

const handleToggleItemValue = (
    state: DropdownState,
    action: DropdownAction
) => {
    const { item, value } = action.payload;
    let newActiveIds = [...state.lookup[item].activeIds];
    const dataItem = state.data.find(
        (d: any) => d[state.level1.typeId] === item
    );

    if (newActiveIds.includes(value)) {
        newActiveIds = newActiveIds.filter((id: string) => id !== value);
    } else {
        newActiveIds.push(value);
    }

    const isAll = newActiveIds.length === dataItem.attributeValues.length;

    const newLookup: any = {
        ...state.lookup,
        [item]: {
            ...state.lookup[item],
            checked: newActiveIds.length > 0,
            isAll,
            activeIds: newActiveIds
        }
    };

    const label = renderLabel(state, newLookup);
    runReduxSideEffect(state, newLookup);

    return {
        ...state,
        label,
        lookup: newLookup
    };
};

const handleToggleItemValueAll = (
    state: DropdownState,
    action: DropdownAction
) => {
    const dataItem = state.data.find(
        (d: any) => d[state.level1.typeId] === action.payload
    );
    const prevIsAll = state.lookup[action.payload]?.isAll;
    const activeIds: any = prevIsAll
        ? []
        : dataItem.attributeValues.map((v: any) => v.value);

    const newLookup: any = {
        ...state.lookup,
        [action.payload]: {
            ...state.lookup[action.payload],
            checked: activeIds.length > 0,
            isAll: !prevIsAll,
            activeIds
        }
    };

    const label = renderLabel(state, newLookup);
    runReduxSideEffect(state, newLookup);

    return {
        ...state,
        label,
        lookup: newLookup
    };
};

const handleToggleActiveItem = (
    state: DropdownState,
    action: DropdownAction
) => {
    const shouldToggleOff = state.activeId === action.payload;
    return {
        ...state,
        activeId: shouldToggleOff ? undefined : action.payload
    };
};

export default function reducer(state: DropdownState, action: DropdownAction) {
    switch (state.status) {
        case 'idle':
            switch (action.type) {
                case DropdownActions.TOGGLE_ITEM: {
                    return handleToggleItem(state, action);
                }
                case DropdownActions.TOGGLE_ACTIVE_ITEM: {
                    return handleToggleActiveItem(state, action);
                }
                case DropdownActions.TOGGLE_ITEM_VALUE: {
                    return handleToggleItemValue(state, action);
                }
                case DropdownActions.TOGGLE_ITEM_VALUE_ALL: {
                    return handleToggleItemValueAll(state, action);
                }
                case DropdownActions.CLEAR: {
                    return handleClear(state);
                }
                case DropdownActions.TOGGLE_ANY: {
                    return handleToggleAny(state, action);
                }
                case DropdownActions.SET_DATA: {
                    return {
                        ...state,
                        data: action.payload,
                        lookup: resetLookup(
                            action.payload,
                            state.level1.typeId
                        ),
                        label: undefined
                    };
                }
                case DropdownActions.SET_INITIAL_STATE: {
                    const newLookup = action.payload.existingSelection
                        ? setLookupBasedOnSelection(
                              action.payload.data,
                              state.level1.typeId,
                              action.payload.existingSelection
                          )
                        : resetLookup(action.payload.data, state.level1.typeId);

                    const label = renderLabel(
                        {
                            ...state,
                            // on first render, data has not been set yet so we use the action.payload
                            data: action.payload.data
                        },
                        newLookup
                    );

                    return {
                        ...state,
                        initialized: true,
                        data: action.payload.data,
                        lookup: newLookup,
                        label
                    };
                }
                case DropdownActions.SET_IS_OPEN: {
                    return {
                        ...state,
                        isOpen: action.payload
                    };
                }
                default:
                    return state;
            }
        case 'hasSelection':
            switch (action.type) {
                case DropdownActions.TOGGLE_ITEM:
                    return handleToggleItem(state, action);
                case DropdownActions.TOGGLE_ACTIVE_ITEM: {
                    return handleToggleActiveItem(state, action);
                }

                case DropdownActions.TOGGLE_ANY:
                    return handleToggleAny(state, action);
                case DropdownActions.SET_IS_OPEN: {
                    return {
                        ...state,
                        isOpen: action.payload
                    };
                }
                case DropdownActions.CLEAR: {
                    return handleClear(state);
                }
                default:
                    return state;
            }

        default:
            return state;
    }
}

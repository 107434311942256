import Menu from 'antd/lib/menu';
import classNames from 'classnames';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { MdMenu } from 'react-icons/md';
import { StaticContext } from 'react-router';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { closeComments } from '../comments/actions';
import AiAvatar from '../common/AiAvatar';
import Button from '../common/Button';
import ListItem from '../common/ListItem';
import Switch from '../common/Switch';
import WithPermissions from '../common/WithPermissions';
import ButtonTypes from '../constants/ButtonTypes';
import LSKeys from '../constants/LSKeys';
import ListItemTypes from '../constants/ListItemTypes';
import MixPanel from '../constants/MixPanel';
import RbacActions from '../constants/RbacActions';
import { setAiInsightsModelOpen } from '../dashboard/aiInsights/reducer';
import CreateDashboardModal from '../dashboards/CreateDashboardModal';
import Admin from '../icons/Admin';
import Campaign from '../icons/Campaign';
import Collect from '../icons/Collect';
import Explore from '../icons/Explore';
import Home from '../icons/Home';
import MoonIcon from '../icons/Moon';
import SunIcon from '../icons/Sun';
import Timeline from '../icons/Timeline';
import TrackChanges from '../icons/TrackChanges';
import cn from '../lib/cn';
import hasPermission from '../lib/hasPermission';
import { track } from '../lib/segment';
import shouldShowBanner from '../lib/shouldShowBanner';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import Notifications from './Notifications';
import Photo from './Photo';
import TopLeftAction from './TopLeftAction';
import { setActorInfo } from './actions';

const DEFAULT_KEY = 'home';

const menu = [
    {
        sectionName: 'Dandi AI',
        route: '/ai',
        key: 'ai',
        icon: Admin,
        routeParts: ['/ai'],
        actions: [RbacActions['Explore/View']],
        featureFlagName: 'aiChat',
    },
    {
        route: '/',
        sectionName: 'Dashboards',
        key: 'home',
        routeParts: [],
        icon: Home,
        actions: [RbacActions['Dashboards/ViewAll'], RbacActions['Dashboards/ViewShared']],
    },
    {
        sectionName: 'Explore',
        route: '/explore',
        routeParts: ['/explore', '/employees'],
        key: 'explore',
        icon: Explore,
        actions: [RbacActions['Explore/Generate']],
    },
    {
        sectionName: 'Self-ID',
        routeParts: ['/self-id'],
        route: '/self-id/campaigns',
        key: 'self-id',
        icon: Campaign,
        actions: [RbacActions['Campaign/View'], RbacActions['Campaign/Edit']],
        featureFlagName: 'selfid',
    },
    {
        sectionName: 'Goals',
        route: '/measure',
        routeParts: ['/measure'],
        key: 'measure',
        icon: TrackChanges,
        actions: [RbacActions['Goals/View']],
    },
    {
        sectionName: 'Timeline',
        route: '/timeline',
        routeParts: ['/timeline'],
        key: 'timeline',
        icon: Timeline,
        actions: [RbacActions['Events/View']],
    },
    {
        sectionName: 'Collect',
        routeParts: ['/collect'],
        route: '/collect',
        key: 'collect',
        icon: Collect,
        actions: [RbacActions['Collect/View']],
    },
    {
        sectionName: 'Admin',
        route: '/admin',
        routeParts: ['/admin'],
        key: 'admin',
        icon: Admin,
        actions: [
            RbacActions['Users/View'],
            RbacActions['Roles/View'],
            RbacActions['Integrations/View'],
            RbacActions['Billing/View'],
            RbacActions['Users/Edit'],
            RbacActions['Settings/View'],
        ],
    },
];

function getSelectedKeys(url: string) {
    let selectedKeys = [DEFAULT_KEY];
    const menuItem = menu.find((m) => {
        return m.routeParts.some((routePart) => url.indexOf(routePart) === 0);
    });
    if (menuItem) {
        selectedKeys = [menuItem.key];
    }
    return selectedKeys;
}

interface LeftNavProps extends RouteComponentProps<{}, StaticContext, { scrollPos?: number }> {}

function TopMenu(props: LeftNavProps) {
    const {
        location: { search, state },
    } = props;
    const dispatch = useAppDispatch();
    const { staticPermissions, userInfo } = useAppSelector<any>((state) => state.auth);
    let { latestDataUpdate, enterpriseName, featureFlags } = useAppSelector((state) => state.account);
    latestDataUpdate = moment.utc(latestDataUpdate).format('MM/DD/YY');

    const showCreate = useAppSelector((state) => state.dashboards.showCreate);

    const showAiInsights = /\/dashboard\/.*/.test(location.pathname);

    const [selectedKeys, setSelectedKeys] = useState(getSelectedKeys(props.location.pathname));

    const [lightModeChecked, setLightModeChecked] = useState(localStorage.getItem(LSKeys.LightMode) === 'true');

    const [collapsible, setCollapsible] = useState(window.innerWidth < 960);
    const [collapsed, setCollapsed] = useState(collapsible);

    useEffect(() => {
        const selectedKeys = getSelectedKeys(props.location.pathname);
        setSelectedKeys(selectedKeys);
    }, [props.location.pathname]);

    useEffect(() => {
        setLightModeChecked(userInfo?.lightMode || false);
    }, [userInfo]);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        //zendesk init
        if (window.zE && typeof window.zE === 'function') {
            window.zE(() => {
                setTimeout(() => {
                    window.zE.identify({
                        name: `${userInfo?.firstName} ${userInfo?.lastName}`,
                        email: userInfo?.email,
                        organization: enterpriseName,
                    });
                    window.zE.show();
                }, 2000);
            });
        }

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function handleResize() {
        const shouldCollapse = window.innerWidth < 960;
        setCollapsible(shouldCollapse);
        setCollapsed(shouldCollapse);
    }

    function handleCollapsedToggle() {
        setCollapsed(!collapsed);
    }

    function handleSelect({ key }: any) {
        dispatch(closeComments());
        navigate(key);
        setCollapsed(true);
    }

    function navigate(key: any) {
        const menuItem: any = menu.find((m) => m.key === key);
        const { route, sectionName } = menuItem;
        track(MixPanel.Events.NavBarClick, {
            sectionName,
        });
        const { pathname } = props.location;
        if (route !== pathname) {
            props.history.push(route);
            setSelectedKeys(getSelectedKeys(route));
            if (route === '/admin') {
                window.scrollTo(0, 0);
            }
        } else if (route === '/explore') {
            props.history.push(route);
        }
    }

    const filteredMenu = menu.filter((m) => {
        let allowed = hasPermission(staticPermissions, m.actions);
        if (allowed && m.featureFlagName) {
            allowed = !!(featureFlags as any)[m.featureFlagName];
        }
        return allowed;
    });

    return (
        <div
            className={classNames('layout__nav', 'nav', {
                'layout__nav--banner': shouldShowBanner(),
            })}
        >
            <div className="nav__header">
                {collapsible ? (
                    <MdMenu size={24} style={{ cursor: 'pointer' }} onClick={handleCollapsedToggle} />
                ) : (
                    <div className="nav__header__inner">
                        <TopLeftAction scrollPos={state?.scrollPos} />
                    </div>
                )}
            </div>
            {collapsible && !collapsed && (
                <div className="nav__menu nav__menu__collapsible">
                    <Menu selectedKeys={selectedKeys} onClick={handleSelect}>
                        {filteredMenu.map((m) => (
                            <Menu.Item key={m.key}>
                                <ListItem activated={selectedKeys.includes(m.key)} type={ListItemTypes.type.DEFAULT}>
                                    {m.sectionName}
                                </ListItem>
                            </Menu.Item>
                        ))}
                        <div
                            style={{
                                backgroundColor: 'var(--color-shade-h2)',
                                height: '100vh',
                            }}
                        ></div>
                    </Menu>
                </div>
            )}
            {!collapsible && (
                <div className="nav__menu">
                    <Menu selectedKeys={selectedKeys} onClick={handleSelect} mode="horizontal">
                        {filteredMenu.map((m) => (
                            <Menu.Item key={m.key}>
                                <ListItem activated={selectedKeys.includes(m.key)} type={ListItemTypes.type.DEFAULT}>
                                    {m.sectionName}
                                </ListItem>
                            </Menu.Item>
                        ))}
                    </Menu>
                </div>
            )}
            <div className="nav__links">
                {featureFlags.aiInsights && showAiInsights && (
                    <WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
                        <Button
                            onClick={() => dispatch(setAiInsightsModelOpen(true))}
                            componentType={ButtonTypes.type.SECONDARY}
                            style={{
                                paddingLeft: '0px',
                                paddingTop: '0px',
                                paddingBottom: '0px',
                            }}
                        >
                            <div className={cn('flex items-center gap-[.8rem]')}>
                                <AiAvatar size={35} />
                                Dandi AI (Preview)
                            </div>
                        </Button>
                    </WithPermissions>
                )}
                <div className="nav__menu__toggle">
                    <Switch
                        checked={lightModeChecked}
                        onChange={() =>
                            dispatch(
                                setActorInfo({
                                    ...userInfo,
                                    lightMode: !userInfo?.lightMode,
                                })
                            )
                        }
                        toggleIcon={lightModeChecked ? <SunIcon /> : <MoonIcon />}
                    />
                </div>
                <Notifications />
                <Photo />
            </div>
            <CreateDashboardModal isOpen={showCreate} />
        </div>
    );
}

export default withRouter(TopMenu);

import ChartTypes from '../constants/ChartTypes';
import AnalysisTypes from '../constants/AnalysisTypes';
import exportDetailData from '../explore/detail/exportDetailData';

export interface IChartHeaderProps {
    mode: 'explore' | 'dashboard-view' | 'dashboard-edit' | 'editor';
    componentType: 'primary' | 'secondary';
    allowChartTypeChange: boolean;
    intersectionAttributes: any[];
    confidence: any;
    showBenchmarkIcon: boolean;
    setBenchmarkEdit: any;
    setBenchmarkToEdit: any;
    lifecycle: string;
    analysisType: AnalysisTypes;
    data: any[];
    dates: Date[];
    currentIndex: number;
    benchmark: any;
    chartType: ChartTypes;
    exportChart: any;
    exportAsCSV: any;
    columnProps: any;
    allowEvents: boolean;
    ref: any;
    exportDetailData?: any;
    showSubtable: boolean;
    enterpriseName?: string;
    subtable?: any;
    exportDetailDataAsCsv?: any;
    mainColumn: string;
}

export interface IChartLegendProps {
    length: number;
    lineWidth: number | string;
    multiLine: boolean;
    data: any;
    onHover: any;
    onHoverEnd: any;
    onToggle: any;
    hoveredItem?: string;
    selection: string[];
    status: 'idle' | 'activated' | 'activatedLocked';
}

interface IChartProps {
    width: number;
    height: number;
    showSubtable: boolean;
    chartType: ChartTypes;
    data: any[];
    dates: Date[];
    columnProps: any[];
    interval: string;
    lifecycle: string;
    setActiveLabel: any;
    clearActiveLabel: any;
    handleToggleLabel: any;
    analysisType: AnalysisTypes;
    currentIndex: number;
    selectCurrentTime: any;
    hasInvalidValue: boolean;
    columnGap: number;
    benchmark: any;
    containerHeight?: number;
    benchmarkRowHeight?: number;
    onHover: any;
    onHoverEnd: any;
    onToggle: any;
    status: 'idle' | 'activated' | 'activatedLocked';
    hoveredItem?: string;
    selection: string[];
    setInnerChartOffsets: any;
}

export interface IChartEventsProps {
    dates: Date[];
    events: {
        eventId: string;
        name: string;
        description: string;
        dateStart: string;
        dateEnd: string;
        color: string;
    }[];
    innerChartOffsets: {
        left: number;
        right: number;
    };
    interval: string;
    analysisType: AnalysisTypes;
}

export interface IChartXAxisProps {
    dashboardName?: string;
    title?: string;
    dates: Date[];
    interval: string;
    currentIndex: number;
    selectCurrentTime: any;
    innerChartOffsets: {
        left: number;
        right: number;
    };
    analysisType: AnalysisTypes;
    indexType?: 'indexed' | 'percentage';
}

interface IChartYAxisProps {
    data: any[];
}

export interface IChartDeclarationProps {
    mode: 'explore' | 'dashboard-view' | 'dashboard-edit' | 'editor';
    chartType: ChartTypes;
    intersectionAttributes?: any[];
    confidence: any;
    showBenchmarkIcon?: boolean;
    setBenchmarkEdit?: any;
    setBenchmarkToEdit?: any;
    lifecycle: string;
    analysisType: AnalysisTypes;
    data: any[];
    dates: Date[];
    currentIndex: number;
    benchmark: any;
    exportChart: any;
    exportAsCSV: any;
    chartHeaderRef: any;
    showSubtable?: boolean;
    columnCount: number;
    setActiveLabel: any;
    clearActiveLabel: any;
    lineWidth: number;
    multiLine: boolean;
    chartWidth: number;
    chartHeight: number;
    interval: string;
    handleToggleLabel: any;
    selectCurrentTime: any;
    hasInvalidValue: boolean;
    columnProps: any[];
    columnGap: number;
    containerHeight?: number;
    benchmarkRowHeight?: number;
    onHover: any;
    onHoverEnd: any;
    onToggle: any;
    status: 'idle' | 'activated' | 'activatedLocked';
    hoveredItem?: string;
    selection: any[];
    dashboardName?: string;
    title?: string;
    events?: any[];
    innerChartOffsets: {
        left: number;
        right: number;
    };
    setInnerChartOffsets: any;
    exportDetailData?: any;
    enterpriseName?: string;
    subtable?: any;
    exportDetailDataAsCsv?: any;
    mainColumn: string;
}

export interface IChartDeclaration {
    chartHeader: IChartHeaderProps;
    chartLegend?: IChartLegendProps;
    chart: IChartProps;
    events?: IChartEventsProps;
    xAxis?: IChartXAxisProps;
    yAxis?: IChartYAxisProps;
}

function getChartDeclaration(props: IChartDeclarationProps): IChartDeclaration {
    const {
        mode,
        columnGap,
        chartType,
        intersectionAttributes,
        confidence,
        showBenchmarkIcon = false,
        setBenchmarkEdit,
        setBenchmarkToEdit,
        lifecycle,
        analysisType,
        data,
        dates,
        currentIndex,
        benchmark,
        exportChart,
        exportAsCSV,
        chartHeaderRef,
        showSubtable = false,
        columnCount,
        setActiveLabel,
        clearActiveLabel,
        columnProps,
        lineWidth,
        multiLine,
        chartWidth,
        chartHeight,
        interval,
        handleToggleLabel,
        selectCurrentTime,
        hasInvalidValue,
        containerHeight,
        benchmarkRowHeight,
        onHover,
        onHoverEnd,
        onToggle,
        status,
        hoveredItem,
        selection,
        dashboardName,
        title,
        events,
        innerChartOffsets,
        setInnerChartOffsets,
        exportDetailData,
        enterpriseName,
        subtable,
        exportDetailDataAsCsv,
        mainColumn
    } = props;

    switch (mode) {
        case 'explore': {
            return {
                chartHeader: {
                    componentType: 'primary',
                    allowChartTypeChange:
                        chartType === ChartTypes.Stats ||
                        chartType === ChartTypes.ArithmeticTable ||
                        chartType === ChartTypes.Funnel
                            ? false
                            : true,
                    mode,
                    intersectionAttributes: intersectionAttributes as any[],
                    confidence,
                    showBenchmarkIcon,
                    setBenchmarkEdit,
                    setBenchmarkToEdit,
                    lifecycle,
                    analysisType,
                    data,
                    dates,
                    currentIndex,
                    benchmark,
                    chartType,
                    mainColumn,
                    exportChart,
                    exportAsCSV,
                    columnProps,
                    ref: chartHeaderRef,
                    allowEvents:
                        chartType === ChartTypes.Line && dates?.length > 1,
                    showSubtable,
                    enterpriseName,
                    subtable,
                    exportDetailData,
                    exportDetailDataAsCsv
                },
                chartLegend:
                    chartType !== ChartTypes.Pie &&
                    chartType !== ChartTypes.Stats &&
                    chartType !== ChartTypes.ArithmeticTable &&
                    !showSubtable
                        ? {
                              length: columnCount,
                              lineWidth,
                              multiLine,
                              onHover,
                              onHoverEnd,
                              onToggle,
                              data,
                              hoveredItem,
                              selection,
                              status
                          }
                        : undefined,
                chart: {
                    width: chartWidth,
                    height: chartHeight,
                    columnGap,
                    onHover,
                    onHoverEnd,
                    onToggle,
                    showSubtable,
                    chartType,
                    data,
                    dates,
                    columnProps,
                    interval,
                    lifecycle,
                    setActiveLabel,
                    clearActiveLabel,
                    handleToggleLabel,
                    analysisType,
                    currentIndex,
                    selectCurrentTime,
                    hasInvalidValue,
                    benchmark,
                    containerHeight,
                    benchmarkRowHeight,
                    status,
                    hoveredItem,
                    selection,
                    setInnerChartOffsets
                },
                events:
                    chartType === ChartTypes.Line && events && dates?.length > 1
                        ? {
                              dates,
                              events,
                              innerChartOffsets,
                              interval,
                              analysisType
                          }
                        : undefined,
                xAxis:
                    chartType === ChartTypes.Line &&
                    dates?.length > 1 &&
                    !showSubtable
                        ? {
                              dashboardName,
                              title,
                              dates,
                              interval,
                              currentIndex,
                              selectCurrentTime,
                              innerChartOffsets,
                              analysisType
                          }
                        : undefined,
                yAxis: undefined
            };
        }
        default: {
            return {
                chartHeader: {
                    componentType: 'primary',
                    allowChartTypeChange:
                        chartType === ChartTypes.Stats ||
                        chartType === ChartTypes.ArithmeticTable ||
                        chartType === ChartTypes.Funnel
                            ? false
                            : true,
                    mode,
                    intersectionAttributes: intersectionAttributes as any[],
                    confidence,
                    showBenchmarkIcon,
                    setBenchmarkEdit,
                    setBenchmarkToEdit,
                    lifecycle,
                    analysisType,
                    data,
                    dates,
                    currentIndex,
                    benchmark,
                    chartType,
                    exportChart,
                    exportAsCSV,
                    columnProps,
                    mainColumn,
                    allowEvents:
                        chartType === ChartTypes.Line && dates?.length > 1,
                    ref: chartHeaderRef,
                    exportDetailData,
                    enterpriseName,
                    showSubtable,
                    subtable,
                    exportDetailDataAsCsv
                },
                chartLegend:
                    !(chartType === ChartTypes.Pie && length >= 3) &&
                    !(
                        chartType === ChartTypes.Table ||
                        chartType === ChartTypes.Matrix ||
                        chartType === ChartTypes.ArithmeticTable ||
                        chartType === ChartTypes.Stats
                    )
                        ? {
                              length: columnCount,
                              lineWidth,
                              multiLine,
                              onHover,
                              onHoverEnd,
                              onToggle,
                              data,
                              hoveredItem,
                              selection,
                              status
                          }
                        : undefined,
                chart: {
                    width: chartWidth,
                    height: chartHeight,
                    columnGap,
                    onHover,
                    onHoverEnd,
                    onToggle,
                    showSubtable,
                    chartType,
                    data,
                    dates,
                    columnProps,
                    interval,
                    lifecycle,
                    setActiveLabel,
                    clearActiveLabel,
                    handleToggleLabel,
                    analysisType,
                    currentIndex,
                    selectCurrentTime,
                    hasInvalidValue,
                    benchmark,
                    containerHeight,
                    benchmarkRowHeight,
                    status,
                    hoveredItem,
                    selection,
                    setInnerChartOffsets
                },
                events:
                    chartType === ChartTypes.Line && events && dates?.length > 1
                        ? {
                              dates,
                              events,
                              innerChartOffsets,
                              interval,
                              analysisType
                          }
                        : undefined,
                xAxis:
                    chartType === ChartTypes.Line &&
                    dates?.length > 1 &&
                    !showSubtable
                        ? {
                              dashboardName,
                              title,
                              dates,
                              interval,
                              currentIndex,
                              selectCurrentTime,
                              innerChartOffsets,
                              analysisType
                          }
                        : undefined,
                yAxis: undefined
            };
        }
    }
}

export default getChartDeclaration;

import orderBy from 'lodash/orderBy';
import ActionTypes from '../constants/ActionTypes';
import PeriodTypes from '../constants/PeriodTypes';
import { AnalysisName } from '../types';

function getInitialState() {
    return {
        enterpriseId: '',
        enterpriseName: '',
        latestDataUpdate: '',
        periodType: PeriodTypes.Monthly,
        featureFlags: {},
    };
}

export interface Enterprise {
    enterpriseId: string;
    enterpriseName: string;
    logoURL: string;
    logoLightURL: string;
}

export interface FeatureFlags {
    selfid?: boolean;
    aiInsights?: boolean;
    aiChat?: boolean;
}

interface AccountState {
    isDataReady?: boolean;
    latestDataUpdate: string;
    enterpriseName: string;
    logoURL?: string;
    logoLightURL?: string;
    enterpriseId: string;
    periodType: string;
    enterprises?: Enterprise[];
    templates?: any[];
    analysisNamesTree?: AnalysisName[];
    customColors?: string[];
    loaded?: boolean;
    memberSince?: any;
    updateAccountPending?: boolean;
    featureFlags: FeatureFlags;
}

export default function accountReducer(state: AccountState = getInitialState(), action: any): AccountState {
    switch (action.type) {
        case ActionTypes.GetAccountFulfilled: {
            let { enterpriseName, latestDataUpdate, logoURL, logoLightURL, isDataReady, memberSince, customColors } =
                action;
            return {
                ...state,
                enterpriseName,
                latestDataUpdate,
                logoURL,
                logoLightURL,
                loaded: true,
                isDataReady,
                memberSince,
                customColors,
            };
        }
        case ActionTypes.UpdateAccountPending:
            return {
                ...state,
                updateAccountPending: true,
            };
        case ActionTypes.UpdateAccountFulfilled: {
            const { enterpriseId, enterpriseName, logoURL, logoLightURL, customColors } = action;

            let { enterprises } = state;
            const index = enterprises?.findIndex((e) => e.enterpriseId === enterpriseId) ?? -1;
            if (enterprises && index > -1) {
                enterprises = enterprises.slice();
                enterprises[index] = {
                    ...enterprises[index],
                    enterpriseName,
                    logoURL,
                    logoLightURL,
                };
            }
            return {
                ...state,
                enterpriseName,
                logoURL,
                logoLightURL,
                enterprises,
                customColors,
                updateAccountPending: false,
            };
        }
        case ActionTypes.UpdateAccountRejected:
            return {
                ...state,
                updateAccountPending: false,
            };
        case ActionTypes.CreateAccountFulfilled:
            return {
                ...state,
                enterpriseId: action.enterpriseId,
            };
        case ActionTypes.SetEnterpriseId:
            return {
                ...state,
                enterpriseId: action.enterpriseId,
            };
        case ActionTypes.SetEnterprises:
            return {
                ...state,
                enterprises: action.enterprises,
            };

        case ActionTypes.GetAnalysisInfoFulfilled: {
            let { templates } = action;
            templates = templates.filter(
                (t: any) =>
                    t.analysisName !== 'Missing Data' &&
                    t.analysisName !== 'Metadata' &&
                    t.analysisName !== 'Missing Data ATS' &&
                    t.analysisName !== 'Metadata ATS'
            );
            templates = orderBy(templates, (t) => t.analysisName);
            return {
                ...state,
                templates,
            };
        }

        case ActionTypes.GetAnalysisTreeFulfilled: {
            return {
                ...state,
                analysisNamesTree: action.payload.tree,
            };
        }
        case ActionTypes.GetFeatureFlagsFulfilled: {
            return {
                ...state,
                featureFlags: action.payload,
            };
        }
        case ActionTypes.GetFeatureFlagsNoData:
        case ActionTypes.GetFeatureFlagsRejected:
            return {
                ...state,
                featureFlags: {},
            };
        case 'templates/setDynamicTemplates/fulfilled': {
            const analysisNames = action.payload.map((template: any) => template.analysisName);

            let filteredAnalysisNamesTree = state.analysisNamesTree;

            if (!analysisNames.includes('*')) {
                filteredAnalysisNamesTree = filteredAnalysisNamesTree?.reduce((acc: AnalysisName[], analysis) => {
                    if (analysis.children) {
                        const children = analysis.children.filter((child: any) => analysisNames.includes(child.name));

                        if (children.length > 0) {
                            acc.push({
                                ...analysis,
                                children,
                            });
                        }
                    } else if (analysisNames.includes(analysis.name)) {
                        acc.push(analysis);
                    }

                    return acc;
                }, []);
            }

            return {
                ...state,
                analysisNamesTree: filteredAnalysisNamesTree,
            };
        }
        case ActionTypes.GetAnalysisTreeNoData:
            return {
                ...state,
                analysisNamesTree: [],
            };
        case ActionTypes.SignOutFulfilled:
            return getInitialState();
        default:
            return state;
    }
}

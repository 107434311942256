import React, { useRef } from 'react';
import { connect } from 'react-redux';
import ScrollArrows from '../../common/ScrollArrows';
import useTableData from './useTableData';
import useTableFeatures from './useTableFeatures';
import { ITableProps } from './types';
import Graph from './Graph';

export const GenericTableContext = React.createContext<any>({});

const defaultProps = {
    theme: 'default',
    template: 'default',
    subAnalysisFields: [],
    subAnalysisMapping: {}
};

const GenericTable = (props: ITableProps & typeof defaultProps) => {
    const tableRef = useRef<HTMLDivElement | null>(null);
    const {
        data,
        template,
        subAnalysisFields,
        subAnalysisMapping,
        hoveredItem,
        selection,
        onHover,
        onHoverEnd,
        onToggle
    } = props;

    const tableData = useTableData({
        data,
        template,
        subAnalysisFields,
        subAnalysisMapping,
        hoveredItem,
        selection
    });

    const { showLeft, showRight } = useTableFeatures(
        tableRef,
        tableData.configuration
    );

    const stopPropagationWrapper = (handler: any) => (event: any) => {
        event.stopPropagation();
        handler(event);
    };

    return (
        <GenericTableContext.Provider
            value={{
                ...props,
                tableData,
                showLeft,
                showRight
            }}
        >
            <Graph>
                <Graph.LeftTable>
                    <Graph.TableHead>
                        <Graph.TableRow>
                            <Graph.TableHeadCell />
                        </Graph.TableRow>
                    </Graph.TableHead>
                    <Graph.TableBody>
                        {tableData.leftTable?.body?.map((row, rowIndex) => (
                            <Graph.TableRow key={rowIndex}>
                                {row.columns.map((cell, columnIndex) => (
                                    <Graph.TableBodyCell
                                        key={columnIndex}
                                        isHovered={cell.hovered}
                                        isActive={cell.selected}
                                        color={cell.color}
                                    >
                                        {cell.label}
                                    </Graph.TableBodyCell>
                                ))}
                            </Graph.TableRow>
                        ))}
                    </Graph.TableBody>
                </Graph.LeftTable>
                <div>
                    <ScrollArrows
                        showLeft={showLeft}
                        showRight={showRight}
                        paddingTop={0}
                        scrollRef={tableRef}
                        scrollWidth={135}
                    />
                    <Graph.RightTable ref={tableRef}>
                        <Graph.TableHead>
                            <Graph.TableRow segmentRow={true}>
                                {tableData.rightTable.head.map(
                                    (cell, columnIndex) => (
                                        <Graph.TableHeadCell
                                            key={columnIndex}
                                            onMouseEnter={stopPropagationWrapper(
                                                () =>
                                                    onHover(
                                                        template === 'default'
                                                            ? JSON.stringify({
                                                                  segment:
                                                                      cell.segment
                                                              })
                                                            : cell.segment
                                                    )
                                            )}
                                            onMouseLeave={stopPropagationWrapper(
                                                () => onHoverEnd()
                                            )}
                                            onClick={stopPropagationWrapper(
                                                () =>
                                                    template === 'default'
                                                        ? onToggle(
                                                              JSON.stringify({
                                                                  segment:
                                                                      cell.segment
                                                              })
                                                          )
                                                        : onToggle(cell.segment)
                                            )}
                                            isActive={cell.selected}
                                            isHovered={cell.hovered}
                                            color={cell.color}
                                        >
                                            <p>{cell.label}</p>
                                        </Graph.TableHeadCell>
                                    )
                                )}
                            </Graph.TableRow>
                            {tableData.rightTable.subHead && (
                                <Graph.TableRow>
                                    {tableData.rightTable.subHead.map(
                                        (cell, cellIndex) => (
                                            <Graph.TableHeadSubCell
                                                key={cellIndex}
                                                isActive={cell.selected}
                                                isHovered={cell.hovered}
                                            >
                                                {cell.label}
                                            </Graph.TableHeadSubCell>
                                        )
                                    )}
                                </Graph.TableRow>
                            )}
                        </Graph.TableHead>
                        <Graph.TableBody>
                            {tableData.rightTable.body.map((row, rowIndex) => (
                                <Graph.TableRow key={rowIndex}>
                                    {row.columns.map((cell, columnIndex) => (
                                        <Graph.TableBodyCell
                                            key={columnIndex}
                                            onMouseEnter={stopPropagationWrapper(
                                                () => {
                                                    if (template !== 'funnel') {
                                                        onHover(
                                                            template ===
                                                                'default'
                                                                ? JSON.stringify(
                                                                      {
                                                                          segment:
                                                                              cell.segment,
                                                                          subAnalysis:
                                                                              tableData
                                                                                  .leftTable
                                                                                  ?.body?.[
                                                                                  rowIndex
                                                                              ]
                                                                                  .columns[0]
                                                                                  .label
                                                                      }
                                                                  )
                                                                : cell.segment
                                                        );
                                                    }
                                                }
                                            )}
                                            onMouseLeave={stopPropagationWrapper(
                                                () => onHoverEnd()
                                            )}
                                            onClick={stopPropagationWrapper(
                                                () =>
                                                    template === 'default'
                                                        ? onToggle(
                                                              JSON.stringify({
                                                                  segment:
                                                                      cell.segment,
                                                                  subAnalysis:
                                                                      tableData
                                                                          .leftTable
                                                                          ?.body?.[
                                                                          rowIndex
                                                                      ]
                                                                          .columns[0]
                                                                          .label
                                                              })
                                                          )
                                                        : onToggle(cell.segment)
                                            )}
                                            color={cell.color}
                                            isHovered={cell.hovered}
                                            isActive={cell.selected}
                                        >
                                            {cell.label}
                                        </Graph.TableBodyCell>
                                    ))}
                                </Graph.TableRow>
                            ))}
                        </Graph.TableBody>
                    </Graph.RightTable>
                </div>
            </Graph>
        </GenericTableContext.Provider>
    );
};

GenericTable.defaultProps = defaultProps;

function mapState(state: any, ownProps: any) {
    switch (ownProps.mode) {
        case 'explore': {
            return {
                ...state.explore.chart,
                status: ownProps.status,
                hoveredItem: ownProps.hoveredItem,
                selection: ownProps.selection
            };
        }
        case 'dashboard-view': {
            const reportData = state.reports[ownProps.reportId];
            return {
                ...(reportData || {})
            };
        }
        case 'dashboard-edit': {
            const reportData = state.reports[ownProps.reportId];
            return {
                ...(reportData || {})
            };
        }
        case 'editor': {
            return {
                ...state.editor.chart
            };
        }
        default:
            return {};
    }
}

const dispatchProps = {};

export default connect(mapState, dispatchProps)(GenericTable);

import * as Portal from '@radix-ui/react-portal';
import { DndContext } from '@dnd-kit/core';
import cn from '../../lib/cn';

interface ModalProps {
    children: any;
    setPosition: any;
}

export default function Modal({ children, setPosition }: ModalProps) {
    return (
        <DndContext
            onDragEnd={event => {
                const { delta } = event;
                setPosition((prevPosition: any) => ({
                    x: prevPosition.x + delta.x,
                    y: prevPosition.y + delta.y
                }));
            }}
        >
            <Portal.Root
                className={cn(
                    'fixed inset-0 flex items-start mt-[72px] mb-[16px] justify-end z-[99999] pr-[2rem] pointer-events-none'
                )}
            >
                {children}
            </Portal.Root>
        </DndContext>
    );
}

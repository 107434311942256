import Calendar from './Calendar';
import Dropdown from './Dropdown';

export default function DatePicker({
    buttonLarge,
    disabled,
    endDate,
    formatType,
    isOpen,
    label,
    onClick,
    onDateSelect,
    placeholder,
    selectsRange = true,
    startDate,
    allowCancel,
    onClearSelection
}: {
    buttonLarge: boolean;
    disabled: boolean;
    endDate?: string;
    formatType: 'Day' | 'Month' | 'Quarter' | 'Year';
    isOpen: boolean;
    label: string;
    onClick: any;
    onDateSelect: any;
    placeholder: string;
    selectsRange?: boolean;
    startDate?: string;
    allowCancel?: boolean;
    onClearSelection?: () => void;
}) {
    return (
        <Dropdown
            placeholder={placeholder}
            buttonLarge={buttonLarge}
            isOpen={isOpen}
            disabled={disabled}
            label={label}
            onClick={onClick}
            selected={!!startDate || !!endDate}
            allowCancel={allowCancel}
            onClearSelection={onClearSelection}
        >
            <Calendar
                onApply={onDateSelect}
                startDate={startDate}
                endDate={endDate}
                formatType={formatType}
                selectsRange={selectsRange}
            />
        </Dropdown>
    );
}

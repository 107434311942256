import React, { useState, useEffect } from 'react';
import DropdownMenuWrapper, {
    DropdownMenuWrapperProps
} from '../../../common/DropdownMenuWrapper';
import TextField from '../../../common/TextField';
import RadioButton from '../../../common/RadioButton';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';

interface ScopeMenuProps extends DropdownMenuWrapperProps {
    enterpriseId: string;
    onDone: (enterpriseId: string) => void;
}

export default function ScopeMenu(props: ScopeMenuProps) {
    const [search, setSearch] = useState('');
    const [enterpriseId, setEnterpriseId] = useState(props.enterpriseId);
    const enterprises = useAppSelector(
        state => state.datastudio.enterprises.data
    );

    let filteredEnterprises = enterprises;
    if (search) {
        filteredEnterprises = enterprises?.filter(e =>
            e.enterpriseName?.toLowerCase().includes(search.toLowerCase())
        );
    }

    useEffect(() => {
        setEnterpriseId(props.enterpriseId);
    }, [props.enterpriseId]);

    useEffect(() => {
        props.onDone(enterpriseId);
        props.onClose?.();
    }, [enterpriseId]);

    return (
        <DropdownMenuWrapper {...props}>
            <div className="scope">
                <div className="scope__title">Select scope</div>
                <div className="scope__search">
                    <TextField
                        placeholder="Search"
                        isSearch
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        showClose
                        onClose={() => setSearch('')}
                    />
                </div>
                <ul className="list scope__list">
                    <li>
                        <RadioButton
                            checked={!enterpriseId}
                            onClick={() => setEnterpriseId('')}
                        >
                            Global
                        </RadioButton>
                    </li>
                    {filteredEnterprises?.map(e => (
                        <li key={e.enterpriseId}>
                            <RadioButton
                                checked={enterpriseId === e.enterpriseId}
                                onClick={() => setEnterpriseId(e.enterpriseId)}
                            >
                                {e.enterpriseName}
                            </RadioButton>
                        </li>
                    ))}
                </ul>
                <div className="scope__actions">
                    <Button
                        componentType={ButtonTypes.type.SECONDARY}
                        onClick={() => props.onClose?.()}
                    >
                        Cancel
                    </Button>
                    <Button
                        componentType={ButtonTypes.type.PRIMARY}
                        onClick={() => {
                            props.onDone(enterpriseId);
                            props.onClose?.();
                        }}
                    >
                        Done
                    </Button>
                </div>
            </div>
        </DropdownMenuWrapper>
    );
}

import React, { useEffect } from 'react';
import TextField from '../../../common/TextField';
import TextArea from '../../../common/TextArea';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import {
    setTitle,
    setDescription,
    setEnterpriseId,
    setImage,
    setEmailSubject,
    setEmailTitle,
    setEmailBody,
    setWelcomeTitle,
    setWelcomeDesc,
    setThankTitle,
    setThankDesc,
    setThankImage
} from './editReducer';
import DropdownWrapper from '../../../common/DropdownWrapper';
import ScopeMenu from '../common/ScopeMenu';
import qs from 'qs';
import { getEnterprises } from '../../enterprise/actions';
import FileUpload from '../../../common/FileUpload';

export default function SurveyDetails() {
    const dispatch = useAppDispatch();
    const {
        title,
        description,
        enterpriseId,
        emailSubject,
        emailTitle,
        emailBody,
        welcomeTitle,
        welcomeDesc,
        thankTitle,
        thankDesc,
        image,
        thankImage,
        imageUrl,
        thankImageUrl
    } = useAppSelector(
        state => state.datastudio.survey.surveyEdit.surveyToEdit
    );
    const activeTab = useAppSelector(
        state => state.datastudio.survey.surveyEdit.activeTab
    );

    const { data: enterprises, loaded: enterprisesLoaded } = useAppSelector(
        state => state.datastudio.enterprises
    );

    const { globalSurveyId } = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const enterpriseName =
        enterprises?.find(e => e.enterpriseId === enterpriseId)
            ?.enterpriseName ?? 'Global';

    useEffect(() => {
        if (!enterprisesLoaded) {
            dispatch(getEnterprises());
        }
    }, []);

    function handleUploadError(message: string) {}

    function handleImageSave(image: string) {
        dispatch(setImage(image));
    }

    function handleThankImageSave(image: string) {
        dispatch(setThankImage(image));
    }

    return (
        <div className="survey-details">
            <div className="survey-details__left">
                <div className="survey-details__title">
                    Campaign information
                </div>
                <div className="ds-form-group">
                    <div className="ds-label">Campaign name</div>
                    <div className="ds-input">
                        <TextField
                            value={title}
                            onChange={e => dispatch(setTitle(e.target.value))}
                            showClose
                            onClose={() => dispatch(setTitle(''))}
                        />
                    </div>
                </div>
                <div className="ds-form-group">
                    <div className="ds-label">Description (optional)</div>
                    <div className="ds-input">
                        <TextField
                            value={description}
                            onChange={e =>
                                dispatch(setDescription(e.target.value))
                            }
                            showClose
                            onClose={() => dispatch(setDescription(''))}
                        />
                    </div>
                </div>
                <div className="ds-form-group">
                    <div className="ds-label">Thumbnail</div>
                    <div className="ds-input">
                        <FileUpload
                            name="thumbnail"
                            imageUrl={imageUrl}
                            image={image}
                            onError={handleUploadError}
                            onUpload={handleImageSave}
                        />
                    </div>
                </div>
                <div className="ds-form-group">
                    <div className="ds-label">Scope</div>
                    <div className="ds-input ds-input--scope">
                        <DropdownWrapper
                            placeholder="Select"
                            selectedText={enterpriseName}
                        >
                            <ScopeMenu
                                enterpriseId={enterpriseId}
                                onDone={enterpriseId =>
                                    dispatch(setEnterpriseId(enterpriseId))
                                }
                            />
                        </DropdownWrapper>
                    </div>
                </div>
            </div>
            <div className="survey-details__right">
                <div className="survey-details__title">Email message</div>
                <div className="ds-form-group">
                    <div className="ds-label">Subject</div>
                    <div className="ds-input">
                        <TextField
                            value={emailSubject}
                            onChange={e =>
                                dispatch(setEmailSubject(e.target.value))
                            }
                            showClose
                            onClose={() => dispatch(setEmailSubject(''))}
                        />
                    </div>
                </div>
                <div className="ds-form-group">
                    <div className="ds-label">Title</div>
                    <div className="ds-input">
                        <TextArea
                            value={emailTitle}
                            onChange={e =>
                                dispatch(setEmailTitle(e.target.value))
                            }
                        />
                    </div>
                </div>
                <div className="ds-form-group">
                    <div className="ds-label">Body</div>
                    <div className="ds-input">
                        <TextArea
                            value={emailBody}
                            onChange={e =>
                                dispatch(setEmailBody(e.target.value))
                            }
                        />
                    </div>
                </div>
                <div className="survey-details__title">Welcome message</div>
                <div className="ds-form-group">
                    <div className="ds-label">Title</div>
                    <div className="ds-input">
                        <TextField
                            value={welcomeTitle}
                            onChange={e =>
                                dispatch(setWelcomeTitle(e.target.value))
                            }
                            showClose
                            onClose={() => dispatch(setWelcomeTitle(''))}
                        />
                    </div>
                </div>
                <div className="ds-form-group">
                    <div className="ds-label">Description (optional)</div>
                    <div className="ds-input">
                        <TextArea
                            value={welcomeDesc}
                            onChange={e =>
                                dispatch(setWelcomeDesc(e.target.value))
                            }
                        />
                    </div>
                </div>
                <div className="survey-details__title">Thank you message</div>
                <div className="ds-form-group">
                    <div className="ds-label">Title</div>
                    <div className="ds-input">
                        <TextField
                            value={thankTitle}
                            onChange={e =>
                                dispatch(setThankTitle(e.target.value))
                            }
                            showClose
                            onClose={() => dispatch(setThankTitle(''))}
                        />
                    </div>
                </div>
                <div className="ds-form-group">
                    <div className="ds-label">Description (optional)</div>
                    <div className="ds-input">
                        <TextArea
                            value={thankDesc}
                            onChange={e =>
                                dispatch(setThankDesc(e.target.value))
                            }
                        />
                    </div>
                </div>
                <div className="ds-form-group">
                    <div className="ds-label">Image (optional)</div>
                    <div className="ds-input">
                        <FileUpload
                            name="thankImage"
                            image={thankImage}
                            imageUrl={thankImageUrl}
                            onError={handleUploadError}
                            onUpload={handleThankImageSave}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

import { PropsWithChildren } from 'react';

const InfoSection = ({ children }: PropsWithChildren) => {
    return <div className="info-section">{children}</div>;
};

const LeftBlock = ({ children }: PropsWithChildren) => {
    return <div className="info-section__left-block">{children}</div>;
};

const RightBlock = ({ children }: PropsWithChildren) => {
    return <div className="info-section__right-block">{children}</div>;
};

const Columns = ({ children }: PropsWithChildren) => {
    return <div className="info-section__columns">{children}</div>;
};

const Column = ({ children }: PropsWithChildren) => {
    return <div className="info-section__column">{children}</div>;
};

const Stat = ({ children }: PropsWithChildren) => {
    return <div className="info-section__stat">{children}</div>;
};

InfoSection.LeftBlock = LeftBlock;
InfoSection.RightBlock = RightBlock;
InfoSection.Columns = Columns;
InfoSection.Column = Column;
InfoSection.Stat = Stat;

export default InfoSection;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import * as uuid from 'uuid';

import Urls from '../../constants/Urls';
import { AsyncThunkConfig } from '../../store/store';
// import { ChatInvokeRequest, ChatMessage } from '../aiTypes';
import { push } from 'connected-react-router';
import { FeedbackData } from '../aiTypes';
import { setChatConversationMetadata } from '../conversations/actions';
import { addConversationId } from '../conversations/reducer';
import { addUserMessage, setConversationId } from './reducer';

export const getChatInputSchema = createAsyncThunk<any, void, AsyncThunkConfig>(
    'ai/chat/getChatInputSchema',
    async () => {
        const response = await axios.get(`${Urls.AiInsightsApi}chat/input_schema`);
        return response.data;
    }
);

export const getChatOutputSchema = createAsyncThunk<any, void, AsyncThunkConfig>(
    'ai/chat/getChatOutputSchema',
    async () => {
        const response = await axios.get(`${Urls.AiInsightsApi}chat/output_schema`);
        return response.data;
    }
);

export const getChatConfigSchema = createAsyncThunk<any, void, AsyncThunkConfig>(
    'ai/chat/getChatConfigSchema',
    async () => {
        const response = await axios.get(`${Urls.AiInsightsApi}chat/config_schema`);
        return response.data;
    }
);

export const invokeChat = createAsyncThunk<
    any,
    {
        prompt: string;
        conversationId?: string;
    },
    AsyncThunkConfig
>('ai/chat/invoke', async ({ prompt, conversationId }, { getState, dispatch }) => {
    const { enterpriseId } = getState().account;
    const { uid } = getState().auth.userInfo;

    const shouldRedirect = !conversationId;
    const conversation_id = conversationId || uuid.v4();

    if (!conversation_id) {
        throw new Error('No conversation id');
    }

    if (shouldRedirect) {
        dispatch(push(`/ai/${conversation_id}`));
    }

    dispatch(setConversationId(conversation_id));

    dispatch(addUserMessage(prompt));

    const response = await axios.post(`${Urls.AiInsightsApi}chat/invoke`, {
        input: {
            input: prompt,
        },
        config: {
            configurable: {
                conversation_id,
                enterprise_id: enterpriseId,
                user_id: uid,
            },
        },
    });

    if (shouldRedirect) {
        dispatch(
            setChatConversationMetadata({
                conversationId: conversation_id,
                metadata: { name: prompt },
            })
        );
        dispatch(addConversationId(conversation_id));
    }

    dispatch(
        getChatMessagesList({
            conversationId: conversation_id,
        })
    );

    return response.data;
});

export const batchInvokeChat = createAsyncThunk<any, { inputs: any[] }, AsyncThunkConfig>(
    'ai/chat/batchInvoke',
    async ({ inputs }) => {
        const response = await axios.post(`${Urls.AiInsightsApi}chat/batch`, {
            inputs,
        });
        return response.data;
    }
);

export const streamInvokeChat = createAsyncThunk<any, { input: any }, AsyncThunkConfig>(
    'ai/chat/streamInvoke',
    async ({ input }) => {
        const response = await axios.post(`${Urls.AiInsightsApi}chat/stream`, {
            input,
        });
        return response.data;
    }
);

export const streamLogChat = createAsyncThunk<any, { input: any }>('ai/chat/streamLog', async ({ input }) => {
    const response = await axios.post(`${Urls.AiInsightsApi}chat/stream_log`, { input });
    return response.data;
});

export const streamEventsChat = createAsyncThunk<any, { input: any }>('ai/chat/streamEvents', async ({ input }) => {
    const response = await axios.post(`${Urls.AiInsightsApi}chat/stream_events`, { input });
    return response.data;
});

export const getChatMessagesList = createAsyncThunk<any, { conversationId: string }>(
    'ai/chat/getChatMessagesList',
    async ({ conversationId }) => {
        const response = await axios.post(`${Urls.AiInsightsApi}chat/messages`, {
            conversation_id: conversationId,
        });
        return response.data;
    }
);

export const deleteChatMessage = createAsyncThunk<any, { conversationId: string; messageIdx: number }>(
    'ai/chat/deleteChatMessage',
    async ({ conversationId, messageIdx }) => {
        const response = await axios.delete(`${Urls.AiInsightsApi}chat/messages`, {
            data: { conversation_id: conversationId, message_idx: messageIdx },
        });
        return response.data;
    }
);

export const provideChatFeedbackMessage = createAsyncThunk<
    any,
    { conversationId: string; messageIdx: number; feedback: FeedbackData }
>('ai/chat/provideChatFeedbackMessage', async ({ conversationId, messageIdx, feedback }) => {
    const response = await axios.put(`${Urls.AiInsightsApi}chat/feedback`, {
        conversation_id: conversationId,
        message_idx: messageIdx,
        feedback,
    });
    return response.data;
});

export const deleteChatFeedbackMessage = createAsyncThunk<any, { conversationId: string; messageIdx: number }>(
    'ai/chat/deleteChatFeedbackMessage',
    async ({ conversationId, messageIdx }) => {
        const response = await axios.delete(`${Urls.AiInsightsApi}chat/feedback`, {
            data: {
                conversation_id: conversationId,
                message_idx: messageIdx,
            },
        });
        return response.data;
    }
);

export const addChatMessageMetadata = createAsyncThunk<
    any,
    { conversationId: string; messageIdx: number; metadata: any }
>('ai/chat/addChatMessageMetadata', async ({ conversationId, messageIdx, metadata }) => {
    const response = await axios.put(`${Urls.AiInsightsApi}chat/message/metadata`, {
        conversation_id: conversationId,
        message_idx: messageIdx,
        metadata,
    });
    return response.data;
});

export const removeChatMessageMetadata = createAsyncThunk<any, { conversationId: string; messageIdx: number }>(
    'ai/chat/removeChatMessageMetadata',
    async ({ conversationId, messageIdx }) => {
        const response = await axios.delete(`${Urls.AiInsightsApi}chat/message/metadata`, {
            data: {
                conversation_id: conversationId,
                message_idx: messageIdx,
            },
        });
        return response.data;
    }
);

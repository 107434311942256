import { useState } from 'react';

type SortConfig = {
    key: string;
    direction: 'asc' | 'desc';
    type: 'string' | 'number';
};

export default function useSortable(initialConfig?: SortConfig) {
    const [sortConfig, setSortConfig] = useState<SortConfig>({
        key: '',
        direction: 'asc',
        type: 'string',
        ...initialConfig
    });

    function requestSort(key: string, type: 'string' | 'number') {
        let direction: 'asc' | 'desc' = 'asc';
        if (sortConfig.key === key && sortConfig.direction === 'asc') {
            direction = 'desc';
        }
        setSortConfig({ key, direction, type });
    }

    return {
        requestSort,
        sortConfig
    };
}

import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Filters from './filter/Filters';
import WithPermissions from '../common/WithPermissions';
import RbacActions from '../constants/RbacActions';

function ExploreHeader(props) {
    const {
        location: { search }
    } = props;

    return (
        <WithPermissions
            actions={[
                RbacActions['Explore/Generate'],
                RbacActions['Dashboards/Explore']
            ]}
        >
            <div className="explore-header">
                <div className="explore__filters">
                    <Filters show value={search} />
                </div>
            </div>
        </WithPermissions>
    );
}

ExploreHeader.propTypes = {
    back: PropTypes.func,
    setScrollPosition: PropTypes.func,
    location: PropTypes.object
};

const dispatchProps = {};

export default connect(null, dispatchProps)(ExploreHeader);

import { createAsyncThunk } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '../../store/store';
import Urls from '../../constants/Urls';
import axios from 'axios';
import { fireDb } from '../../lib/firebase';

export const getRoles = createAsyncThunk<any, void, AsyncThunkConfig>(
    'roles/getRoles',
    async (_, { getState }) => {
        const { enterpriseId } = getState().account;

        const roles = await axios.post(`${Urls.RbacApi}roles`, {
            enterpriseId
        });

        return roles.data;
    }
);

export const deleteRole = createAsyncThunk<any, string, AsyncThunkConfig>(
    'roles/deleteRole',
    async (roleId, { getState }) => {
        const { enterpriseId } = getState().account;

        try {
            const roles = await axios.put(`${Urls.RbacApi}roles/delete`, {
                enterpriseId,
                roleId
            });

            const docRef = fireDb.collection('ui_create_role').doc(roleId);

            await docRef
                .delete()
                .then(() => {})
                .catch(err => {
                    console.log(err);
                });

            return roles.data;
        } catch (err) {
            console.log(err);
            return null;
        }
    }
);

import React from 'react';
import FontFamilies from '../constants/FontFamilies';

const CommentActive = (props: any) => (
    <svg viewBox="0 0 28 28" fill="currentColor" {...props}>
        <path d="M7 19h10.1l5.9 4V7c0-1.1-.9-2-2-2H7c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2z" />
        <path fill="none" d="M0 0h28v28H0z" />
        <text
            x="14"
            y="15"
            textAnchor="middle"
            fontSize="10"
            fontFamily={FontFamilies.Medium}
            fill="black"
        >
            {props.count}
        </text>
    </svg>
);

export default CommentActive;

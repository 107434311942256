import * as uuid from 'uuid';
import {
    generateAnalysisPermissions,
    generateFeaturePermissions
} from '../../admin/rolespermissions/CreateRole/utils';
import { FeaturePermission, IStateAnalysisPermission } from './models';

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { StaticPermission } from '../../admin/permissions/model';
import { Role } from '../../admin/roles/model';
import Urls from '../../constants/Urls';
import { AsyncThunkConfig } from '../../store/store';
import { Attribute } from '../../types';
import constructDynamicPermissionsForPayload from './utils';
import { fireDb } from '../../lib/firebase';

interface PolicyPayload {
    action: string;
    resource: string;
    name: string;
    scope: string;
}

interface StaticPermissionPayload extends PolicyPayload {
    id: string;
    requiredFlags: any[];
}

function getPoliciesPayload(
    action: string,
    featureName: string,
    staticPermissions: StaticPermission[]
): PolicyPayload[] {
    const payloads: PolicyPayload[] = [];

    if (action === '*') {
        staticPermissions.forEach((staticPermission: StaticPermission) => {
            staticPermission.policies.forEach(policy => {
                policy.actions.forEach(a => {
                    if (a.includes(featureName)) {
                        payloads.push({
                            action: a,
                            resource: policy.resource,
                            name: policy.name,
                            scope: staticPermission.scope
                        });
                    }
                });
            });
        });
    } else {
        staticPermissions.forEach((staticPermission: StaticPermission) => {
            staticPermission.policies.forEach(policy => {
                policy.actions.forEach(a => {
                    if (action === a) {
                        payloads.push({
                            action: a,
                            resource: policy.resource,
                            name: policy.name,
                            scope: staticPermission.scope
                        });
                    }
                });
            });
        });
    }

    return payloads;
}

function generateRoleRequestObject({
    enterpriseId,
    name,
    description,
    flags,
    featurePermissions,
    analysisPermissions,
    staticPermissions,
    hideEmployeeCount,
    attributes
}: {
    enterpriseId: string;
    attributes: Attribute[];
    name: string;
    description: string;
    flags: any;
    featurePermissions: FeaturePermission[];
    analysisPermissions: IStateAnalysisPermission[];
    staticPermissions: StaticPermission[];
    hideEmployeeCount: boolean;
}) {
    return {
        enterpriseId,
        name,
        description,
        flags,
        staticPermissions: featurePermissions
            .map((fp: FeaturePermission) => {
                let staticPermissionPayloads: StaticPermissionPayload[] = [];
                fp.actions.forEach(action => {
                    getPoliciesPayload(
                        action,
                        fp.featureName,
                        staticPermissions
                    ).forEach((p: any) => {
                        staticPermissionPayloads.push({
                            ...p,
                            requiredFlags: [],
                            id: uuid.v4()
                        });
                    });
                });

                return staticPermissionPayloads.flat(1);
            })
            .flat(1),
        dynamicPermissions: constructDynamicPermissionsForPayload({
            analysisPermissions,
            hideEmployeeCount,
            attributes
        })
    };
}

export const saveRole = createAsyncThunk<any, void, AsyncThunkConfig>(
    'createRole/saveRole',
    async (_, { getState }) => {
        const {
            name,
            featurePermissions,
            analysisPermissions,
            id,
            hideEmployeeCount
        } = getState().roles.newRole;
        const { attributes } = getState().editor.filter;
        const { staticPermissions } = getState().admin.permissions;
        const { enterpriseId } = getState().account;
        const { newRole } = getState().roles;

        const req = generateRoleRequestObject({
            enterpriseId,
            name,
            description: '',
            flags: {},
            staticPermissions: staticPermissions,
            featurePermissions: featurePermissions,
            analysisPermissions: analysisPermissions,
            hideEmployeeCount,
            attributes
        });

        const roleId = await axios.put(`${Urls.RbacApi}roles/save`, {
            ...req,
            enterpriseId,
            id
        });

        function cleanseData(obj: any) {
            Object.keys(obj).forEach(key => {
                if (obj[key] && typeof obj[key] === 'object') {
                    cleanseData(obj[key]); // Recursively cleanse nested objects
                } else if (obj[key] === undefined) {
                    delete obj[key]; // Remove if the value is undefined
                }
            });
            return obj;
        }

        const docRef = fireDb.collection('ui_create_role').doc(roleId.data.id);

        const newRoleCopy = { ...newRole };

        await docRef
            .set({
                ...cleanseData(newRoleCopy)
            })
            .then(() => {})
            .catch(e => {
                console.log(e);
            });

        return { req, id: roleId.data };
    }
);

export const previewRole = createAsyncThunk<any, void, AsyncThunkConfig>(
    'createRole/previewRole',
    async (_, { getState }) => {
        const {
            name,
            featurePermissions,
            analysisPermissions,
            id,
            hideEmployeeCount
        } = getState().roles.newRole;
        const { attributes } = getState().editor.filter;
        const { staticPermissions } = getState().admin.permissions;
        const { enterpriseId } = getState().account;

        const req = generateRoleRequestObject({
            enterpriseId,
            name,
            description: '',
            flags: {},
            staticPermissions: staticPermissions,
            featurePermissions: featurePermissions,
            analysisPermissions: analysisPermissions,
            hideEmployeeCount,
            attributes
        });

        const response = await axios.put(`${Urls.RbacApi}roles/preview`, {
            ...req,
            enterpriseId,
            id
        });

        localStorage.setItem('previewToken', response.data.token);

        window.open('/?preview=true', '_blank');
        return response.data;
    }
);

export const setRole = createAsyncThunk<any, string, AsyncThunkConfig>(
    'createRole/setRole',
    async (roleId, { getState }) => {
        const docRef = fireDb.collection('ui_create_role').doc(roleId);

        const uiState = await docRef.get().then(doc => {
            return doc.data();
        });

        return {
            ...uiState,
            id: roleId
        };
    }
);

export const copyRole = createAsyncThunk<
    any,
    {
        copyRoleId: string;
        name: string;
    },
    AsyncThunkConfig
>('createRole/copyRole', async (params, { getState }) => {
    const { copyRoleId, name } = params;
    const docRef = fireDb.collection('ui_create_role').doc(copyRoleId);

    const uiState = await docRef.get().then(doc => {
        return doc.data();
    });

    return {
        ...uiState,
        id: undefined,
        name
    };
});

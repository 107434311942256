import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 49 49"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill="var(--color-shade-h3)"
                d="M.943 4.031a4 4 0 014-4h40a4 4 0 014 4v21.044h-48V4.03z"
            />
            <path
                fill="var(--color-shade-h1)"
                d="M.943 25.074h48v18.957a4 4 0 01-4 4h-40a4 4 0 01-4-4V25.074z"
            />
            <path d="M10.412 25.074h2V48.03h-2zM23.943 11.31h2v36.721h-2zM37.474 33.065h2v14.967h-2z" />
        </svg>
    );
}

export default SvgComponent;

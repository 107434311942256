import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M11.2 4v16C7.144 19.6 4 16.168 4 12s3.144-7.6 7.2-8zm1.624 0v7.192H20C19.624 7.4 16.608 4.376 12.824 4zm0 8.808V20c3.792-.376 6.8-3.4 7.176-7.192h-7.176z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgComponent;

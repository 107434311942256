import produce from 'immer';
import intersection from 'lodash/intersection';
import orderBy from 'lodash/orderBy';
import sortBy from 'lodash/sortBy';
import startCase from 'lodash/startCase';
import uniq from 'lodash/uniq';
import moment from 'moment';

import {
    default as AnalysisTypes,
    default as analysisTypes
} from '../constants/AnalysisTypes';
import ChartTypes from '../constants/ChartTypes';
import Constants from '../constants/Constants';
import benchmarkAllowed from '../lib/benchmarkAllowed';
import { clear, getChartColor } from '../lib/getChartColor';
import getDate from '../lib/getDate';
import getPeriod from '../lib/getPeriod';
import getSegment from '../lib/getSegment';
import precisionRound from '../lib/precisionRound';
import { IGoal } from '../measure/types';
import { Attribute, Fields, ISegmentData, Labels } from '../types';
import { getAllSegments } from './actionUtils';

const DATE_FORMAT = 'YYYY/MM/DD';

function getNavProps(result: any, intersectionAttributes: any) {
    const navProps: any = {};
    intersectionAttributes.forEach((ia: any) => {
        const propKey = result[ia.attributeTypeProp];
        navProps[propKey] = result[ia.attributeValueProp];
    });
    return navProps;
}

export function getIntersectionAttributes(result: any, filters: any) {
    const intersectionFilters = filters
        .filter((f: any) => f.values.length > 1)
        .map((f: any) => f.name);
    if (intersectionFilters.length === 0) {
        return [
            {
                attributeTypeProp: 'main1Type',
                attributeValueProp: 'main1'
            }
        ];
    }
    const intersectionAttributes = [
        {
            attributeTypeProp: 'main1Type',
            attributeValueProp: 'main1'
        },
        {
            attributeTypeProp: 'main2Type',
            attributeValueProp: 'main2'
        },
        {
            attributeTypeProp: 'main3Type',
            attributeValueProp: 'main3'
        },
        {
            attributeTypeProp: 'entityType1',
            attributeValueProp: 'entity1'
        },
        {
            attributeTypeProp: 'entityType2',
            attributeValueProp: 'entity2'
        }
    ];

    return intersectionAttributes.filter(ia => {
        return intersectionFilters.some((f: any) => {
            if (result[ia.attributeTypeProp]) {
                return (
                    f.replace(/ /g, '').replace(/-/g, '').toLowerCase() ===
                    result[ia.attributeTypeProp].toLowerCase()
                );
            }
            return false;
        });
    });
}

export function getLabel(result: any, intersectionAttributes: any) {
    return intersectionAttributes
        .map((ia: any) => result[ia.attributeValueProp])
        .join('_');
}

export function updateDisabledState(
    state: any,
    mainSegments: any,
    filterSegment: any,
    breakdownSegment: any
) {
    let { variations, mainNames, filterNames, breakdownNames } = state;

    const mainItems = mainSegments.map((s: any) =>
        s.name.replace(/ /g, '').toLowerCase()
    );
    const filterItem = filterSegment
        ? filterSegment.name.replace(/ /g, '').toLowerCase()
        : '';

    const breakdownItem = breakdownSegment
        ? breakdownSegment.name.replace(/ /g, '').toLowerCase()
        : '';

    const mainSet = new Set();
    const filterSet = new Set();
    const breakdownSet = new Set();

    variations.forEach((variation: any) => {
        const items = variation.map((v: any) => v.toLowerCase());
        let found = true;
        if (mainItems.length > 0) {
            const mainItems2 = items.slice(0, 3);
            found =
                intersection(mainItems, mainItems2).length === mainItems.length;
        }
        if (found) {
            mainSet.add(items[0]);
            mainSet.add(items[1]);
            mainSet.add(items[2]);
            if (breakdownItem && breakdownItem === items[4]) {
                filterSet.add(items[3]);
            } else if (!breakdownItem) {
                filterSet.add(items[3]);
            }
            if (!filterItem) {
                breakdownSet.add(items[3]);
            } else if (filterItem === items[3]) {
                breakdownSet.add(items[4]);
            }
        }
    });

    filterSet.delete(breakdownItem);
    mainSet.delete('');
    filterSet.delete('');
    breakdownSet.delete('');

    mainNames = produce(mainNames, (mainNames: any) => {
        mainNames.forEach((n: any) => {
            n.disabled = !mainSet.has(n.name.toLowerCase());
        });
    });

    filterNames = produce(filterNames, (filterNames: any) => {
        filterNames.forEach((n: any) => {
            n.disabled = !filterSet.has(n.name.toLowerCase());
        });
    });

    breakdownNames = produce(breakdownNames, (breakdownNames: any) => {
        breakdownNames.forEach((n: any) => {
            n.disabled = !breakdownSet.has(n.name.toLowerCase());
        });
    });

    return {
        ...state,
        mainNames,
        filterNames,
        breakdownNames,
        mainSegments,
        filterSegment,
        breakdownSegment,
        generateDisabled: false
    };
}

function getFields(meta: any, columnProps: any) {
    const fields: any = {};
    columnProps.forEach((columnProp: any) => {
        if (!columnProp.calculated) {
            fields[columnProp.source] = parseFloat(meta[columnProp.source]);
        }
    });

    let expr = '';
    Object.keys(meta).forEach(k => {
        expr += `const ${k} = ${parseFloat(meta[k])};`;
    });

    const regex = /calc\(([^\)]+)\)/;
    columnProps
        .filter((columnProp: any) => columnProp.calculated)
        .forEach((columnProp: any) => {
            const matches = regex.exec(columnProp.source)!;
            expr += `${matches[1]};`;
            fields[columnProp.source] = eval(expr);
        });

    return fields;
}

function getSubAnalysesMapping({ meta, subAnalysisFields, subAnalyses }: any) {
    return meta.metricsLabels
        .reduce((acc: any, label: any, index: number) => {
            acc.push({
                label,
                value: meta.metricsValues[index]
            });
            return acc;
        }, [])
        .filter((metric: any) => {
            return subAnalyses.some((sa: any) => sa.source === metric.label);
        })
        .map((metric: any) => {
            const labelDisplayName = Object.keys(subAnalysisFields).find(
                field => subAnalysisFields[field].source === metric.label
            );

            return {
                label: labelDisplayName,
                unit: subAnalysisFields[labelDisplayName!].unit,
                value: metric.value
            };
        });
}

export function getStateForGenerateReport(state: any, action: any) {
    const {
        results,
        interval,
        attributes,
        mainSegments,
        filterSegment,
        breakdownSegment,
        analysisDisplayName,
        analysisName,
        subAnalyses,
        supportedGraphs,
        subAnalysisMapping,
        analysisType,
        columnProps,
        allowYAxisToggle,
        allowIndexToggle,
        subAnalysisFields,
        intervalCount,
        startDate,
        endDate,
        eventIds,
        isFilterApplied
    } = action;
    let { chartType } = action;
    let { currentIndex, currentDate, benchmark, date } = state;

    const yAxisType = action.yAxisType || state.yAxisType;
    const indexType = action.indexType || state.indexType;

    clear();

    let breakdownValue: any;
    let breakdownValueProp: any;
    const filters = mainSegments.slice();
    if (filterSegment) {
        filters.push(filterSegment);
    }
    if (breakdownSegment) {
        breakdownValue = breakdownSegment.values[0];
        breakdownValueProp = filterSegment ? 'entity2' : 'entity1';
        filters.push(breakdownSegment);
    }

    const intersectionAttributes = getIntersectionAttributes(
        results[0],
        filters
    );

    const uniqueDates = new Map<string, Date>();
    const data: any = [];

    results.forEach((r: any) => {
        if (breakdownValue !== r[breakdownValueProp]) {
            return;
        }
        const date = getDate(r.date, interval);
        uniqueDates.set(r.date, date);
        const label = getLabel(r, intersectionAttributes);
        const navProps = getNavProps(r, intersectionAttributes);
        let dataItem = data.find((d: any) => d.label === label);
        if (!dataItem) {
            const segment = getSegment(r, attributes, intersectionAttributes);
            dataItem = {
                label,
                text: segment.text,
                navProps,
                color: getChartColor(label),
                segment,
                series: [],
                pointInTime: {}
            };
            data.push(dataItem);
        }

        let meta = r.percentageMeta;
        if (analysisType === AnalysisTypes.LinearRegression) {
            meta = r.compMeta;
        }
        if (analysisType === AnalysisTypes.Index) {
            meta = r.indexMeta;
        }
        if (analysisType === AnalysisTypes.Arithmetic) {
            meta = r.arithmeticMeta;
        }

        let fields = {};

        if (analysisType === AnalysisTypes.Arithmetic) {
            const subAnalyses_ = getSubAnalysesMapping({
                meta,
                subAnalysisFields,
                subAnalyses
            });
            dataItem.pointInTime = {
                date,
                subAnalyses: subAnalyses_
            };
        } else {
            fields = getFields(meta, columnProps);
            let nonIndexFields = {};
            if (analysisType === AnalysisTypes.Index) {
                nonIndexFields = {
                    percentage: Number(meta.percentage) * 100
                };
            }
            dataItem.series.push({
                date,
                ...fields,
                ...nonIndexFields
            });
        }
    });

    let dates = Array.from(uniqueDates.values());
    dates = orderBy(dates);
    const dateForCurrentIndex = currentDate || date;
    let foundDate = false;
    if (dateForCurrentIndex) {
        dates.forEach((d, i) => {
            if (moment.utc(d).format(DATE_FORMAT) === dateForCurrentIndex) {
                currentIndex = i;
                foundDate = true;
            }
        });
    }

    if (!foundDate) {
        currentIndex = dates.length - 1;
    }

    let primaryField = columnProps.length ? columnProps[0].source : null;
    const periods = dates.map(d => getPeriod(d, interval));
    let hasInvalidValue = false;

    data.forEach((d: any) => {
        for (let i = 0; i <= periods.length - 1; i++) {
            let currentPeriod;
            if (d.series[i]) {
                currentPeriod = getPeriod(d.series[i].date, interval);
            }
            if (currentPeriod !== periods[i]) {
                d.series.splice(i, 0, {
                    [primaryField]: Constants.InvalidValue,
                    date: dates[i]
                });
                hasInvalidValue = true;
            }
        }
    });

    if (analysisType === AnalysisTypes.LinearRegression) {
        const equalityIndexData = {
            label: 'Equity Index',
            segment: { text: 'Equity Index', html: 'Equity Index' },
            isEqualityIndex: true,
            series: undefined
        };
        equalityIndexData.series = data[0].series.map((s: any) => ({
            date: s.date,
            [primaryField]: 0
        }));
        data.unshift(equalityIndexData);
    }
    if (analysisType === AnalysisTypes.Index) {
        const attritionIndexData = {
            label: `${analysisName} Index`,
            segment: {
                text: `${analysisName} Index`,
                html: `${analysisName} Index`
            },
            isAttritionIndex: true,
            series: undefined
        };
        attritionIndexData.series = data[0].series.map((s: any) => ({
            date: s.date,
            [primaryField]: 1
        }));
        data.unshift(attritionIndexData);
    }

    if (!chartType) {
        chartType = ChartTypes.Line;
    }

    if (analysisType === AnalysisTypes.Arithmetic) {
        if (supportedGraphs?.includes('funnel')) {
            chartType = ChartTypes.Funnel;
        } else if (subAnalyses.length === 1 && data.length <= 8) {
            chartType = ChartTypes.Stats;
        } else {
            chartType = ChartTypes.ArithmeticTable;
        }
    }

    if (chartType === ChartTypes.Pie || chartType === ChartTypes.StackedBar) {
        if (
            analysisType === AnalysisTypes.LinearRegression ||
            analysisType === AnalysisTypes.Index ||
            state.lifecycle === 'Retention' ||
            (intersectionAttributes && intersectionAttributes.length > 1)
        ) {
            chartType = ChartTypes.Line;
        }
    }
    if (chartType === ChartTypes.Matrix) {
        chartType = ChartTypes.Table;
    }

    const showBenchmarkIcon = benchmarkAllowed({
        segments: mainSegments.map((s: any) => s.name),
        analysisName
    });
    if (!showBenchmarkIcon && benchmark) {
        chartType = ChartTypes.Line;
        benchmark = undefined;
    }

    let confidence;
    if (analysisType === AnalysisTypes.LinearRegression && results.length) {
        const r2 = parseFloat(results[0].compMeta.r2);
        confidence = precisionRound(r2 * 100, 1);
    }

    return {
        ...state,
        lifecycle: analysisName,
        analysisDisplayName,
        generatePending: false,
        generated: true,
        subAnalysisMapping,
        subAnalysisFields,
        mainSegments,
        filterSegment,
        breakdownSegment,
        data,
        intersectionAttributes,
        dates,
        currentIndex,
        analysisType,
        hasInvalidValue,
        allowYAxisToggle,
        chartType,
        confidence,
        showBenchmarkIcon,
        benchmark,
        interval,
        columnProps,
        intervalCount,
        startDate,
        endDate,
        status: 'idle',
        selection: [],
        hoveredItem: undefined,
        eventIds: eventIds || [],
        yAxisType,
        indexType,
        allowIndexToggle,
        isFilterApplied
    };
}

export function getData(label: any, columnProps: any, meta: any) {
    label = label.toLowerCase();
    label = label.replace(/ /g, '-');
    const fields = getFields(meta, columnProps);
    const data: any = {};
    columnProps.forEach((columnProp: any) => {
        data[label + '-' + columnProp.source] = fields[columnProp.source];
    });
    return data;
}

export function getSummary(
    diversityAttributes: any,
    columnProps: any,
    data: any,
    shouldGetPrimaryData = false,
    total = 1
) {
    const summary: any = {};
    diversityAttributes.forEach((da: any) => {
        const label = da.label.toLowerCase().replace(/ /g, '-');

        const summaryData: any = {};
        columnProps.forEach((columnProp: any) => {
            summaryData[columnProp.source] = 0;
        });
        let length = 0;
        data.forEach((d: any) => {
            let empTotal = 0;
            columnProps.forEach((columnProp: any, i: number) => {
                const propLabel = `${label}-${columnProp.source}`;
                summaryData[columnProp.source] += d[propLabel] || 0;
                if (i !== 0) {
                    empTotal += d[propLabel];
                }
            });
            if (empTotal) {
                length++;
            }
        });
        const primaryProp = columnProps[0].source;
        const employeesProp = columnProps[1].source;
        if (shouldGetPrimaryData && total > 0) {
            summaryData[primaryProp] =
                (100 * summaryData[employeesProp]) / total;
        } else {
            if (length) {
                summaryData[primaryProp] = summaryData[primaryProp] / length;
            }
        }
        summary[label] = summaryData;
    });

    return summary;
}

export function getAttributeName(variationName: any, initialAttributes: any) {
    const initialAttribute = initialAttributes.find((ia: any) => {
        return (
            ia.attributeName
                .replace(/ /g, '')
                .replace(/-/g, '')
                .toLowerCase() === variationName.toLowerCase()
        );
    });
    return initialAttribute ? initialAttribute.attributeName : variationName;
}

function getStateForSubtableInner(
    columnProps: any,
    lifecycle: any,
    analysisType: any,
    attributes: any,
    intersectionAttributes: any,
    employeeAttribute: any,
    date: any,
    interval: any,
    results: any,
    rowFilter: any,
    columnFilter?: any
) {
    const attrProp =
        results[0].entityType1 === employeeAttribute ? 'entity1' : 'entity2';

    const attributeName = getAttributeName(employeeAttribute, attributes);

    let metaProp = 'compMeta';
    if (analysisType === AnalysisTypes.Index) {
        metaProp = 'indexMeta';
    }
    if (analysisType === AnalysisTypes.Percentage) {
        metaProp = 'percentageMeta';
    }

    let confidence;
    if (analysisType === AnalysisTypes.LinearRegression) {
        confidence = precisionRound(
            parseFloat(results[0][metaProp].r2) * 100,
            1
        );
    }

    const employeeAttributes: any = [];
    let diversityAttributes: any = [];
    let data: any = [];

    results.forEach((r: any) => {
        const employeeAttributeObject = employeeAttributes.find(
            (ea: any) => ea.value === r[attrProp]
        );
        if (!employeeAttributeObject) {
            const filteredResults = results.filter(
                (r2: any) => r[attrProp] === r2[attrProp]
            );
            const shouldAdd = filteredResults.some(
                (r2: any) =>
                    parseInt(r2[metaProp].totalExcludedCount) > 0 ||
                    parseInt(r2[metaProp].totalIncludedCount) > 0 ||
                    parseInt(r2[metaProp].totalCount) > 0 ||
                    parseInt(r2[metaProp].totalCount) === -1
            );

            if (shouldAdd) {
                const attribute = attributes.find(
                    (a: any) =>
                        a.attributeName.replace(/ /g, '') === employeeAttribute
                );
                if (attribute) {
                    const attributeValue = attribute.attributeValues.find(
                        (av: any) => av.value === r[attrProp]
                    );
                    if (attributeValue) {
                        employeeAttributes.push({
                            value: r[attrProp],
                            text: attributeValue.text
                        });
                        const item = {
                            id: r[attrProp],
                            label: attributeValue.text
                        };
                        data.push(item);

                        filteredResults.forEach((r2: any, index: number) => {
                            const label = getLabel(r2, intersectionAttributes);

                            const dataProps = getData(
                                label,
                                columnProps,
                                r2[metaProp]
                            );

                            Object.assign(item, dataProps);

                            let diversityAttributeObject =
                                diversityAttributes.find(
                                    (d: any) => d.id === label
                                );
                            if (!diversityAttributeObject) {
                                const navProps = getNavProps(
                                    r2,
                                    intersectionAttributes
                                );
                                diversityAttributeObject = {
                                    id: label,
                                    label,
                                    navProps,
                                    segment: getSegment(
                                        r2,
                                        attributes,
                                        intersectionAttributes,
                                        employeeAttribute,
                                        index
                                    )
                                };
                                diversityAttributes.push(
                                    diversityAttributeObject
                                );
                            }
                        });
                    }
                }
            }
        }
    });

    diversityAttributes = diversityAttributes.filter((da: any) => !!da.segment);
    diversityAttributes = orderBy(diversityAttributes, d => d.label);
    const unfilteredDiversityAttributes = diversityAttributes.slice();

    if (columnFilter && columnFilter.length) {
        diversityAttributes = diversityAttributes.filter((d: any) =>
            columnFilter.includes(d.label)
        );
    }

    if (employeeAttributes.length === 0) {
        return {
            empty: true
        };
    }

    const unfilteredData = data.slice();
    let showGroupSummary = false;

    if (rowFilter && rowFilter.length) {
        const attribute = attributes.find(
            (a: any) => a.attributeName.replace(/ /g, '') === employeeAttribute
        );
        showGroupSummary =
            attribute.attributeValues.length !== rowFilter.length;

        if (showGroupSummary) {
            data = data.filter((d: any) => rowFilter.includes(d.id));
        }
    }

    if (data.length === 0) {
        return {
            empty: true
        };
    }

    data = sortBy(data, d => d.label);

    let summary;
    let groupSummary;

    summary = getSummary(diversityAttributes, columnProps, unfilteredData);

    if (showGroupSummary) {
        groupSummary = getSummary(diversityAttributes, columnProps, data);
    }

    return {
        lifecycle,
        analysisType,
        columnProps,
        attributeName,
        date,
        interval,
        data,
        unfilteredData,
        summary,
        groupSummary,
        showGroupSummary,
        diversityAttributes,
        unfilteredDiversityAttributes,
        employeeAttributes,
        confidence,
        generated: true,
        intersectionAttributes
    };
}

export function getStateForDynamicSection(state: any, action: any) {
    const {
        intersectionAttributes,
        results,
        employeeAttribute,
        analysisName,
        analysisType,
        date,
        interval,
        attributes,
        columnProps,
        shouldGetPrimaryData
    } = action;

    const { rowFilter = [] } = state;

    if (results.length === 0) {
        return {
            ...state,
            open: true,
            pending: false,
            empty: true
        };
    }

    const newState = getStateForSubtableInner(
        columnProps,
        analysisName,
        analysisType,
        attributes,
        intersectionAttributes,
        employeeAttribute,
        date,
        interval,
        results,
        rowFilter
    );

    if (newState.empty) {
        return {
            ...state,
            open: true,
            pending: false,
            empty: true
        };
    }

    return {
        ...state,
        ...newState,
        open: true,
        pending: shouldGetPrimaryData,
        // clear column filter on fetch
        columnFilter: [],
        shouldGetPrimaryData
    };
}

function shouldAllowChartToggle(
    mainColumn: string,
    analysisType: analysisTypes
) {
    return (
        (mainColumn === 'total' || mainColumn === 'included') &&
        analysisType === analysisTypes.Percentage
    );
}

export function getStateForSubtable(state: any, action: any) {
    const {
        results,
        mainSegments,
        filterSegment,
        breakdownSegment,
        analysisName,
        analysisType,
        date,
        interval,
        attributes,
        columnProps,
        shouldGetPrimaryData,
        subtableChartType,
        mode,
        mainColumn
    } = action;

    const chartTypeConfig =
        mode === 'editor'
            ? {
                  chartType: ChartTypes.Matrix
              }
            : {
                  subtableChartType: subtableChartType || ChartTypes.Matrix
              };

    const allowChartToggle = shouldAllowChartToggle(mainColumn, analysisType);

    if (results.length === 0) {
        return {
            ...state,
            empty: true,
            generatePending: false,
            ...chartTypeConfig,
            allowChartToggle
        };
    }

    const employeeAttribute = breakdownSegment.name.replace(/ /g, '');

    const filters = getAllSegments({
        mainSegments,
        filterSegment
    });
    const intersectionAttributes = getIntersectionAttributes(
        results[0],
        filters
    );

    const newState = getStateForSubtableInner(
        columnProps,
        analysisName,
        analysisType,
        attributes,
        intersectionAttributes,
        employeeAttribute,
        date,
        interval,
        results,
        breakdownSegment && breakdownSegment.values,
        state.columnFilter
    );

    if (newState.empty) {
        return {
            ...state,
            empty: true,
            generatePending: false,
            ...chartTypeConfig,
            allowChartToggle
        };
    }

    return {
        ...state,
        ...newState,
        mainSegments,
        filterSegment,
        breakdownSegment,
        intersectionAttributes,
        generatePending: shouldGetPrimaryData,
        generated: true,
        ...chartTypeConfig,
        shouldGetPrimaryData,
        allowChartToggle
    };
}

export function getSegments(filters: any, variations: any) {
    const segments: any = {};
    let filterTypes = filters.map((f: any) =>
        f.type.replace(/ /g, '').replace(/-/g, '').toLowerCase()
    );
    filterTypes = uniq(filterTypes);
    variations.forEach((v: any) => {
        const variation = v
            .map((vi: any) => vi.toLowerCase())
            .filter((vi: any) => !!vi);
        let shouldInclude = variation.length === filterTypes.length + 1;
        if (shouldInclude) {
            filterTypes.forEach((ft: any) => {
                shouldInclude = shouldInclude && variation.includes(ft);
            });
        }
        if (shouldInclude) {
            v.forEach((vi: any, index: number) => {
                if (
                    vi &&
                    index >= 3 &&
                    !filterTypes.includes(vi.toLowerCase())
                ) {
                    segments[vi] = true;
                }
            });
        }
    });
    return Object.keys(segments);
}

export function getAttributes(fields: Fields, labels: Labels) {
    const { accountFields, customGroups } = fields;

    // The text for these are hard-coded in account/meta
    const { departments, jobTitles, ageRanges } = labels;
    const textMap: any = {};
    departments.forEach(d => {
        textMap[d.departmentId] = d.departmentName;
    });
    jobTitles.forEach(jt => {
        textMap[jt.jobTitleId] = jt.jobTitleName;
    });

    let attributes = accountFields.map(f => {
        const attributeValues: any = [];
        f.values.forEach(v => {
            if (
                ['Business Unit', 'Department', 'Job Title'].includes(f.field)
            ) {
                const text = textMap[v];
                if (text) {
                    attributeValues.push({
                        value: v,
                        text
                    });
                }
            } else {
                attributeValues.push({
                    value: v,
                    text: v
                });
            }
        });
        return {
            attributeName: f.field,
            attributeValues
        };
    });

    const customAttributes = customGroups.map(cg => {
        if (cg.groupLabel === 'Age') {
            return {
                attributeName: cg.groupLabel,
                attributeValues: cg.groupValues.map((gv, i) => ({
                    value: gv,
                    text: ageRanges[i]
                }))
            };
        } else {
            return {
                attributeName: cg.groupLabel,
                attributeValues: cg.groupValues.map(gv => ({
                    value: gv,
                    text: gv
                }))
            };
        }
    });
    attributes = attributes.concat(customAttributes);
    attributes = sortBy(attributes, a => a.attributeName);
    attributes.forEach(a => {
        a.attributeValues = sortBy(a.attributeValues, av => av.text);
    });

    return attributes;
}

// This call is only for getting percentage based analysis types data
// required for subtable summary
export function getPrimaryData(
    results: any,
    intersectionAttributes: any,
    columnProps: any
) {
    const data: any = [];
    results.forEach((r: any) => {
        const label = getLabel(r, intersectionAttributes);
        let dataItem = data.find((d: any) => d.label === label);
        if (!dataItem) {
            dataItem = {
                label
            };
            data.push(dataItem);
        }
        const meta = r.percentageMeta;
        const fields = getFields(meta, columnProps);
        Object.assign(dataItem, fields);
    });

    let summary: any = {};

    const percentageColumnProp = columnProps.find((c: any) => c.order === '1');
    const percentageProp = percentageColumnProp.source;

    const employeesColumnProp = columnProps.find((c: any) => c.order === '2');
    const employeesProp = employeesColumnProp.source;

    let total = 0;
    data.forEach((d: any) => {
        summary[d.label.toLowerCase().replace(/ /g, '-')] = {
            [percentageProp]: d[percentageProp],
            [employeesProp]: d[employeesProp] || 0
        };
        total += d[employeesProp] || 0;
    });

    return { summary, total };
}

export function getStateForPrimaryData(state: any, action: any) {
    const { results, columnProps, isFilterApplied } = action;
    let {
        intersectionAttributes,
        diversityAttributes,
        data,
        showGroupSummary
    } = state;
    const { total, summary } = getPrimaryData(
        results,
        intersectionAttributes,
        columnProps
    );

    showGroupSummary = showGroupSummary && total > 0;
    let groupSummary;
    if (showGroupSummary) {
        groupSummary = getSummary(
            diversityAttributes,
            columnProps,
            data,
            true,
            total
        );
    }

    return {
        ...state,
        summary,
        groupSummary,
        total,
        generated: true,
        generatePending: false,
        pending: false,
        showGroupSummary,
        isFilterApplied
    };
}

export function getStateForGoalData(
    goalData: IGoal,
    dataProp: 'percentage' | 'gap',
    attributes: Attribute[]
): ISegmentData[] {
    function getAttributeName(
        attributes: Attribute[],
        variable: {
            originalName: string;
            value: string;
        }
    ): string {
        const found = attributes.find(
            a => a.attributeName === startCase(variable.originalName)
        );

        if (!found) {
            return '';
        }

        const name =
            found.attributeValues.find(av => av.value === variable.value)
                ?.text ?? '';

        return name;
    }

    function getTokens(
        metrics: IGoal['metricRegistrationRequest']['metrics']['0']
    ) {
        const tokens = [];

        tokens.push(
            getAttributeName(attributes, metrics.mainVariables.mv1!) ||
                metrics.mainVariables.mv1!.value
        );

        if (metrics.mainVariables?.mv2) {
            tokens.push(
                getAttributeName(attributes, metrics.mainVariables.mv2) ||
                    metrics.mainVariables.mv2.value
            );
        }

        if (metrics.mainVariables?.mv3) {
            tokens.push(
                getAttributeName(attributes, metrics.mainVariables.mv3) ||
                    metrics.mainVariables.mv3.value
            );
        }

        if (metrics?.controlVariables?.cv1) {
            const cv1 = metrics.controlVariables.cv1;
            const name = getAttributeName(attributes, cv1) || cv1.value;
            tokens.push(name);
        }

        return tokens;
    }

    const metrics = goalData.metricRegistrationRequest.metrics[0];
    const color = 'var(--color-graph-4)';
    const tokens = getTokens(metrics);

    return [
        {
            active: true,
            selected: true,
            label: tokens.join('-'),
            color,
            segment: {
                html: `<span class="segment-label">${tokens.join('-')}</span>`,
                text: tokens.join('-'),
                tokens: tokens.map(t => ({
                    text: t,
                    color
                }))
            },
            series: goalData.metricTrajectoryValues[0].values.map(v => ({
                date: new Date(v.timestamp.seconds * 1000),
                [dataProp]: v.currentValue
            })),
            pointInTime: {
                date: new Date(),
                subAnalyses: []
            }
        }
    ];
}

import Menu from 'antd/lib/menu';
import classNames from 'classnames';
import { Fragment, PropsWithChildren, useContext } from 'react';
import BenchmarkMenu from '../../benchmark/BenchmarkMenu';
import AnalysisTypes from '../../constants/AnalysisTypes';
import ButtonTypes from '../../constants/ButtonTypes';
import ChartHeaderTypes from '../../constants/ChartHeaderTypes';
import RbacActions from '../../constants/RbacActions';
import { setIndexType, setYAxisType } from '../../explore/chart/actions';
import usePermissions from '../../hooks/usePermissions';
import Comment from '../../icons/Comment';
import CommentActive from '../../icons/CommentActive';
import HMoreVertical from '../../icons/HMoreVertical';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Dropdown from '../Dropdown';
import Button from '../Button';
import Popover from '../Popover';
import WithPermissions from '../WithPermissions';
import { ChartHeaderContext } from './index';
import ChartTypes from '../../constants/ChartTypes';

const baseClass = 'chart-header';

function capitalizeFirstLetter(str: string) {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
}

const Chart = ({ children }: PropsWithChildren) => {
    const { componentType, withDivider } = useContext<any>(ChartHeaderContext);

    return (
        <div
            className={classNames(baseClass, `${baseClass}--${componentType}`, {
                [`${baseClass}--${componentType}--with-divider`]: withDivider
            })}
        >
            {children}
        </div>
    );
};

const Title = ({ children }: PropsWithChildren) => {
    return <div className={`${baseClass}__title`}>{children}</div>;
};

const Confidence = ({}) => {
    const { lifecycle, analysisType, confidence } =
        useContext<any>(ChartHeaderContext);

    return (
        <div className={`${baseClass}__confidence`}>
            {(lifecycle === 'Compensation' ||
                analysisType === AnalysisTypes.LinearRegression) &&
                confidence && <span>Confidence {confidence}%</span>}
        </div>
    );
};

const Actions = () => {
    const {
        showBenchmarkIcon,
        benchmarkMenuOpen,
        handleBenchmarkClick,
        benchmark,
        handleBenchmarkCreate,
        handleBenchmarkSelect,
        handleBenchmarkRemove,
        handleBenchmarkEdit,
        handleShowPieChange,
        handleMoreClick,
        handleMoreMenuSelect,
        handleExportAsCSV,
        handleExport,
        handleInfo,
        moreKeys,
        componentType,
        showSubtable,
        allowChartTypeChange,
        chartTypes,
        analysisType,
        intersectionAttributes,
        chartType,
        handleChartTypeChange,
        commentCount,
        toggleComments,
        moreOpen,
        toggleEventsModal,
        mainColumn
    } = useContext<any>(ChartHeaderContext);
    const { yAxisType, allowYAxisToggle, indexType, allowIndexToggle } =
        useAppSelector(state => state.explore.chart);
    const dispatch = useAppDispatch();

    const eventsAllowed = usePermissions({
        actions: [RbacActions['Events/View']]
    });
    const exportAllowed = usePermissions({
        actions: [RbacActions['Explore/View'], RbacActions['Explore/Generate']]
    });

    function renderMoreMenu() {
        return (
            <Menu
                className="widget-menu"
                selectedKeys={moreKeys}
                onSelect={handleMoreMenuSelect}
            >
                {eventsAllowed && (
                    <Menu.Item
                        key="events"
                        onClick={toggleEventsModal}
                        data-test="explore-event-modal"
                    >
                        Events
                    </Menu.Item>
                )}
                {exportAllowed && (
                    <Fragment>
                        <Menu.Item key="csv" onClick={handleExportAsCSV}>
                            Export as CSV
                        </Menu.Item>
                        <Menu.Item key="image" onClick={handleExport}>
                            Export as Image
                        </Menu.Item>
                    </Fragment>
                )}
                <Menu.Item key="math" onClick={handleInfo}>
                    What&apos;s the Math?
                </Menu.Item>
            </Menu>
        );
    }

    return (
        <div
            className={`${baseClass}__icons`}
            onClick={e => e.stopPropagation()}
        >
            {allowYAxisToggle && !showSubtable && (
                <div
                    style={{
                        marginRight: '.8rem'
                    }}
                >
                    <Dropdown
                        disabled={!!benchmark}
                        buttonType={ButtonTypes.type.SECONDARY}
                        buttonLarge={false}
                        selectedOption={capitalizeFirstLetter(yAxisType)}
                        // selected={true}
                        onClick={(val: any) => {
                            dispatch(setYAxisType(val.toLowerCase()));
                        }}
                        options={[
                            { label: 'Count', value: 'count' },
                            { label: 'Percentage', value: 'percentage' }
                        ]}
                    />
                </div>
            )}
            {allowIndexToggle && (
                <div
                    style={{
                        marginRight: '.8rem'
                    }}
                >
                    <Dropdown
                        buttonType={ButtonTypes.type.SECONDARY}
                        buttonLarge={false}
                        selectedOption={capitalizeFirstLetter(indexType)}
                        onClick={(val: any) => {
                            dispatch(setIndexType(val.toLowerCase()));
                        }}
                        options={[
                            { label: 'Indexed', value: 'indexed' },
                            { label: 'Percentage', value: 'Percentage' }
                        ]}
                    />
                </div>
            )}
            <WithPermissions actions={[RbacActions['Benchmarks/View']]}>
                {!showSubtable && showBenchmarkIcon && (
                    <Popover
                        placement="bottomRight"
                        visible={benchmarkMenuOpen}
                        onVisibleChange={handleBenchmarkClick}
                        content={
                            <BenchmarkMenu
                                benchmark={benchmark}
                                onBenchmarkCreate={handleBenchmarkCreate}
                                onBenchmarkSelect={handleBenchmarkSelect}
                                onBenchmarkRemove={handleBenchmarkRemove}
                                onBenchmarkEdit={handleBenchmarkEdit}
                                showPie={benchmark && !benchmark.hidePie}
                                onShowPieChange={handleShowPieChange}
                            />
                        }
                        overlayClassName="popover--no-padding"
                    >
                        <Button
                            large={false}
                            componentType={ButtonTypes.type.SECONDARY}
                            selected={!!benchmark}
                            style={{
                                marginRight: '1rem'
                            }}
                        >
                            Benchmark
                        </Button>
                    </Popover>
                )}
            </WithPermissions>

            {componentType !== ChartHeaderTypes.type.SECONDARY &&
                !showSubtable &&
                allowChartTypeChange && (
                    <span className={`${baseClass}__icon-group`}>
                        {chartTypes.map((ct: any, i: number) => {
                            const isPie = ct.chartType === 'Pie';
                            const isStackedBar = ct.chartType === 'StackedBar';
                            const isLinearOrIndexAnalysis =
                                analysisType ===
                                    AnalysisTypes.LinearRegression ||
                                analysisType === AnalysisTypes.Index;

                            const hasMultipleIntersectionAttributes =
                                intersectionAttributes &&
                                intersectionAttributes.length > 1;

                            const isLineOrBar =
                                ct.chartType === 'Line' ||
                                ct.chartType === 'Bar';

                            let disabled = false;

                            if (isPie) {
                                disabled =
                                    isLinearOrIndexAnalysis ||
                                    (analysisType ===
                                        AnalysisTypes.Percentage &&
                                        mainColumn === 'excluded') ||
                                    hasMultipleIntersectionAttributes;
                            } else if (isStackedBar) {
                                disabled = !(
                                    analysisType === AnalysisTypes.Percentage &&
                                    (mainColumn === 'total' ||
                                        mainColumn === 'included')
                                );
                            } else if (benchmark && isLineOrBar) {
                                disabled = true;
                            }

                            return ct.chartType === chartType ? (
                                <ct.activeIcon
                                    key={ct.chartType}
                                    className={classNames(
                                        'explore-chart__header__icon',
                                        'explore-chart__header__icon--selected',
                                        {
                                            'explore-chart__header__icon--last':
                                                i === chartTypes.length - 1
                                        }
                                    )}
                                />
                            ) : (
                                <ct.inactiveIcon
                                    key={ct.chartType}
                                    className={classNames(
                                        'explore-chart__header__icon',
                                        {
                                            'explore-chart__header__icon--disabled':
                                                disabled,
                                            icon: !disabled,
                                            'explore-chart__header__icon--last':
                                                i === chartTypes.length - 1
                                        }
                                    )}
                                    onClick={
                                        disabled
                                            ? undefined
                                            : () =>
                                                  handleChartTypeChange(
                                                      ct.chartType
                                                  )
                                    }
                                />
                            );
                        })}
                    </span>
                )}
            <WithPermissions actions={[RbacActions['Comments/View']]}>
                {commentCount ? (
                    <CommentActive
                        count={commentCount}
                        width={24}
                        height={24}
                        className="explore-chart__header__icon icon icon--selected"
                        onClick={toggleComments}
                    />
                ) : (
                    <Comment
                        width={24}
                        height={24}
                        className="explore-chart__header__icon icon"
                        onClick={toggleComments}
                    />
                )}
            </WithPermissions>
            <Popover
                placement="bottomRight"
                visible={moreOpen}
                onVisibleChange={handleMoreClick}
                content={renderMoreMenu()}
                overlayClassName="popover--no-padding"
            >
                <HMoreVertical
                    className="icon"
                    width={24}
                    height={24}
                    data-test="explore-more-menu"
                />
            </Popover>
        </div>
    );
};

Chart.Title = Title;
Chart.Confidence = Confidence;
Chart.Actions = Actions;

export default Chart;

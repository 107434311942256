import { SortCriteria } from '../../common/SortableHead';
import { GoalCategory, MeasureType } from '../types';
import { createSlice } from '@reduxjs/toolkit';

const defaultSortCriteria: SortCriteria = {
    columnName: 'createdAt',
    ascending: false
};

interface ISettingsState {
    activeTab: MeasureType;
    goalTab: GoalCategory;
    sortCriteria: SortCriteria;
    addGoalModalOpen: boolean;
    addTrackerModalOpen: boolean;
    measureInfoModalOpen: boolean;
}

const initialState: ISettingsState = {
    activeTab: 'goal',
    goalTab: 'active',
    sortCriteria: defaultSortCriteria,
    addGoalModalOpen: false,
    addTrackerModalOpen: false,
    measureInfoModalOpen: false
};

const settingsSlice = createSlice({
    name: 'settings',
    initialState: initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
        openModal: (state, action) => {
            const tab = action.payload;

            switch (tab) {
                case 'goal':
                    state.addGoalModalOpen = true;
                    break;
                case 'tracker':
                    state.addTrackerModalOpen = true;
                    break;
            }
        },
        closeModal: (state, action) => {
            const tab = action.payload;
            switch (tab) {
                case 'goal':
                    state.addGoalModalOpen = false;
                    break;
                case 'tracker':
                    state.addTrackerModalOpen = false;
                    break;
            }
        },
        openInfoModal: state => {
            state.measureInfoModalOpen = true;
        },
        closeInfoModal: state => {
            state.measureInfoModalOpen = false;
        },
        setActiveGoalTab: (state, action) => {
            state.goalTab = action.payload;
        }
    },
    extraReducers: builder => {}
});

export const {
    setActiveTab,
    openModal,
    closeModal,
    closeInfoModal,
    openInfoModal,
    setActiveGoalTab
} = settingsSlice.actions;
export default settingsSlice.reducer;

import {
    clearFilters,
    generateInEditor,
    setAnalysisName,
    setFiltersFromReport,
    updateBreakdownSegment,
    updateFilterSegment,
    updateMainSegments
} from '../../../../editor/filter/actions';
import {
    deleteAnalysisPermission,
    updateAnalysises,
    updateSegments,
    updateFilters,
    updateBreakdowns
} from '../../../../roles/createRole/reducer';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';

import React, { useEffect, useRef, useState } from 'react';
import { MdDelete } from 'react-icons/md';
import { connect } from 'react-redux';
import RadioButton from '../../../../common/RadioButton';
import RbacActions from '../../../../constants/RbacActions';
import { getInitialAnalysisNames } from '../../../../explore/filter/Filters';
import usePermissions from '../../../../hooks/usePermissions';
import useDropdown from './useDropdown';
import { DropdownActions } from './useDropdown/types';
import Checkbox from '../../../../common/Checkbox';
import FilterDropdown from './FilterDropdown';
import Radio from '../../../../common/RadioButton';
import DropdownArrow from '../../../../icons/DropdownArrow';
import classNames from 'classnames';
import { Template } from '../../../../templates/models';
import AnalysisDropdown from './AnalysisDropdown';
import { getAttributesByAnalysises } from './utils';

interface FiltersProps {
    // props
    showBreakdown: boolean;
    useRadio: boolean[];
    editMode?: boolean;

    // state
    analysisName: string;
    analysisDisplayName: string;
    analysisType: string;
    mainSegments: any[];
    filterSegment: any;
    breakdownSegment: any;
    mainNames: any[];
    filterNames: any[];
    breakdownNames: any[];
    attributes: any[];
    generatePending: boolean;
    generateDisabled: boolean;
    report?: any;
    completed: boolean;
    initialAnalysisNames: any[];
    filteredAnalysisNames?: string[];
    allFilterNames: string[];
    index: number;

    // actions
    onFiltersClear: any;
    setFiltersFromReport: any;
    setAnalysisName: any;
    updateMainSegments: any;
    updateBreakdownSegment: any;
    updateFilterSegment: any;
    generateInEditor: any;
    updateAnalysises: any;
    updateSegments: any;
    updateFilters: any;
    updateBreakdowns: any;
}

const Filters = ({
    showBreakdown,
    attributes,
    editMode,
    index,
    updateAnalysises,
    updateSegments,
    updateFilters,
    updateBreakdowns
}: FiltersProps) => {
    const containerRef = useRef<HTMLDivElement>(null);
    const dispatch = useAppDispatch();
    const { analysisPermissions } = useAppSelector(
        state => state.roles.newRole
    );
    const [id] = useState(analysisPermissions[index].id);

    const [analysisDropdownState, analysisDropdownDispatch] = useDropdown({
        parentId: id,
        multiLevel: false,
        label: undefined,
        level1: {
            type: 'multi',
            typeId: 'analysisName'
        },
        reduxAction: (id: string, analysises: any[]) =>
            updateAnalysises({
                id,
                analysises
            })
    });
    const [segmentsDropdownState, segmentsDropdownDispatch] = useDropdown({
        parentId: id,
        multiLevel: true,
        label: undefined,
        level1: {
            type: 'multi',
            typeId: 'attributeName'
        },
        level2: {
            type: 'multi'
        },
        reduxAction: (id: string, segments: any[]) =>
            updateSegments({
                id,
                segments
            })
    });
    const [filtersDropdownState, filtersDropdownDispatch] = useDropdown({
        parentId: id,
        multiLevel: true,
        label: undefined,
        level1: {
            type: 'multi',
            typeId: 'attributeName'
        },
        level2: {
            type: 'multi'
        },
        reduxAction: (id: string, filters: any[]) =>
            updateFilters({
                id,
                filters
            })
    });

    const [breakdownsDropdownState, breakdownsDropdownDispatch] = useDropdown({
        parentId: id,
        multiLevel: false,
        label: undefined,
        level1: {
            type: 'multi',
            typeId: 'attributeName'
        },
        reduxAction: (id: string, breakdowns: any[]) =>
            updateBreakdowns({
                id,
                breakdowns
            })
    });

    const { templates } = useAppSelector(state => state.templates);
    const canEdit = usePermissions({ actions: [RbacActions['Roles/Edit']] });

    function handleDelete() {
        dispatch(deleteAnalysisPermission(id));
    }

    function setSegmentsInitialState() {
        const newAttributes = getAttributesByAnalysises(
            Object.keys(analysisDropdownState.lookup).filter(
                (key: string) => analysisDropdownState.lookup[key]?.checked
            ),
            templates,
            attributes,
            'mainNames'
        );

        segmentsDropdownDispatch({
            type: DropdownActions.SET_INITIAL_STATE,
            payload: {
                data: newAttributes,
                existingSelection:
                    editMode && !segmentsDropdownState.initialized
                        ? analysisPermissions[index].segments
                        : null
            }
        });
    }

    function setFiltersInitialState() {
        const newAttributes = getAttributesByAnalysises(
            Object.keys(analysisDropdownState.lookup).filter(
                (key: string) => analysisDropdownState.lookup[key]?.checked
            ),
            templates,
            attributes,
            'filterNames'
        );

        filtersDropdownDispatch({
            type: DropdownActions.SET_INITIAL_STATE,
            payload: {
                data: newAttributes,
                existingSelection:
                    editMode && !filtersDropdownState.initialized
                        ? analysisPermissions[index].filters
                        : null
            }
        });
    }

    function setBreakdownsInitialState() {
        const newAttributes = getAttributesByAnalysises(
            Object.keys(analysisDropdownState.lookup).filter(
                (key: string) => analysisDropdownState.lookup[key]?.checked
            ),
            templates,
            attributes,
            'breakdownNames'
        );

        breakdownsDropdownDispatch({
            type: DropdownActions.SET_INITIAL_STATE,
            payload: {
                data: newAttributes,
                existingSelection:
                    editMode && !breakdownsDropdownState.initialized
                        ? analysisPermissions[index].breakdowns
                        : null
            }
        });
    }

    useEffect(() => {
        if (!analysisDropdownState.initialized) {
            analysisDropdownDispatch({
                type: DropdownActions.SET_INITIAL_STATE,
                payload: {
                    data: templates.map((template: Template) => ({
                        analysisName: template.analysisName,
                        analysisType: template.analysisType
                    })),
                    existingSelection: editMode
                        ? analysisPermissions[index].analysises
                        : null
                }
            });
        } else {
            setSegmentsInitialState();
            setFiltersInitialState();
            setBreakdownsInitialState();
        }
    }, [
        editMode,
        attributes,
        templates,
        analysisDropdownState.initialized,
        analysisDropdownState.lookup
    ]);

    return (
        <div className="analysis-permissions__filters" ref={containerRef}>
            <div className="filters">
                <div className="filters__dropdowns">
                    <AnalysisDropdown
                        placeholder="Analysis"
                        onClick={() =>
                            analysisDropdownDispatch({
                                type: DropdownActions.SET_IS_OPEN,
                                payload: true
                            })
                        }
                        isOpen={analysisDropdownState.isOpen}
                        label={analysisDropdownState.label}
                        setIsOpen={(isOpen: boolean) =>
                            analysisDropdownDispatch({
                                type: DropdownActions.SET_IS_OPEN,
                                payload: isOpen
                            })
                        }
                        onClearSelection={() => {
                            analysisDropdownDispatch({
                                type: DropdownActions.CLEAR
                            });
                        }}
                        disabled={!canEdit}
                        allowCancel
                        style={{
                            width: '100%',
                            display: 'flex',
                            flexShrink: 1,
                            minWidth: 0
                        }}
                    >
                        <div className="filter-dropdown__list">
                            <ul>
                                <li key="Any">
                                    <RadioButton
                                        onClick={() => {
                                            analysisDropdownDispatch({
                                                type: DropdownActions.TOGGLE_ANY
                                            });
                                        }}
                                        checked={
                                            analysisDropdownState.lookup.Any
                                                ?.checked
                                        }
                                    >
                                        Any
                                    </RadioButton>
                                </li>
                                <div className="filter-dropdown__divider"></div>
                                {analysisDropdownState.data.map((t: any) => {
                                    return (
                                        <li
                                            key={t.analysisName}
                                            style={{ minWidth: 0 }}
                                        >
                                            <Checkbox
                                                disabled={
                                                    analysisDropdownState.lookup
                                                        .Any?.checked
                                                }
                                                onClick={() => {
                                                    analysisDropdownDispatch({
                                                        type: DropdownActions.TOGGLE_ITEM,
                                                        payload: t.analysisName
                                                    });
                                                }}
                                                checked={
                                                    analysisDropdownState
                                                        .lookup[t.analysisName]
                                                        .checked
                                                }
                                                style={{ minWidth: 0 }}
                                            >
                                                <span className="long-text">
                                                    {t.analysisName}
                                                </span>
                                            </Checkbox>
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </AnalysisDropdown>
                    {/* SEGMENTS DROPDOWN */}
                    <FilterDropdown
                        isSecondary={true}
                        label={segmentsDropdownState.label}
                        placeholder="Segments"
                        isOpen={segmentsDropdownState.isOpen}
                        setIsOpen={() => {
                            segmentsDropdownDispatch({
                                type: DropdownActions.SET_IS_OPEN,
                                payload: !segmentsDropdownState.isOpen
                            });
                        }}
                        disabled={
                            !canEdit ||
                            Object.keys(analysisDropdownState.lookup).every(
                                k => !analysisDropdownState.lookup[k].checked
                            )
                        }
                        allowCancel={true}
                        onClearSelection={() => {
                            segmentsDropdownDispatch({
                                type: DropdownActions.CLEAR
                            });
                        }}
                    >
                        <div className="filter-dropdown__list">
                            <ul>
                                <li
                                    key={'Any'}
                                    onClick={e => {
                                        e.stopPropagation();
                                        segmentsDropdownDispatch({
                                            type: DropdownActions.TOGGLE_ANY
                                        });
                                    }}
                                    className={classNames({
                                        'l-active':
                                            segmentsDropdownState.lookup.Any
                                                ?.checked
                                    })}
                                >
                                    <Radio
                                        checked={
                                            segmentsDropdownState.lookup.Any
                                                ?.checked
                                        }
                                        onClick={() => {}}
                                    />
                                    <span>Any</span>
                                </li>

                                <div className="filter-dropdown__divider"></div>
                                {segmentsDropdownState.data?.map(
                                    (segment: any) => (
                                        <li
                                            key={segment.attributeName}
                                            onClick={() => {
                                                segmentsDropdownDispatch({
                                                    type: DropdownActions.TOGGLE_ACTIVE_ITEM,
                                                    payload:
                                                        segment.attributeName
                                                });
                                            }}
                                            className={classNames({
                                                'l-active':
                                                    segmentsDropdownState
                                                        .lookup[
                                                        segment.attributeName
                                                    ]?.checked,
                                                'l-disabled':
                                                    segmentsDropdownState.lookup
                                                        .Any?.checked,
                                                'l-seemingly-disabled':
                                                    segmentsDropdownState.lookup
                                                        .Any?.checked
                                            })}
                                        >
                                            <Checkbox
                                                checked={
                                                    segmentsDropdownState
                                                        .lookup[
                                                        segment.attributeName
                                                    ]?.checked
                                                }
                                                onClick={() => {
                                                    segmentsDropdownDispatch({
                                                        type: DropdownActions.TOGGLE_ITEM,
                                                        payload:
                                                            segment.attributeName
                                                    });
                                                }}
                                                disabled={
                                                    segmentsDropdownState.lookup
                                                        .Any?.checked
                                                }
                                            />
                                            <span>{segment.attributeName}</span>
                                            {segmentsDropdownState.lookup[
                                                segment.attributeName
                                            ]?.activeIds.length > 0 ||
                                            segmentsDropdownState.lookup[
                                                segment.attributeName
                                            ]?.isAll ? (
                                                <span style={{ marginLeft: 4 }}>
                                                    (
                                                    {
                                                        segmentsDropdownState
                                                            .lookup[
                                                            segment
                                                                .attributeName
                                                        ]?.activeIds.length
                                                    }
                                                    )
                                                </span>
                                            ) : (
                                                segmentsDropdownState.lookup.Any
                                                    ?.checked && (
                                                    <span
                                                        style={{
                                                            marginLeft: 4
                                                        }}
                                                    >
                                                        (Any)
                                                    </span>
                                                )
                                            )}
                                            <span className="arrow">
                                                <DropdownArrow
                                                    width={18}
                                                    height={18}
                                                    style={
                                                        segmentsDropdownState.activeId ===
                                                        segment.attributeName
                                                            ? {
                                                                  transform:
                                                                      'rotate(-90deg)'
                                                              }
                                                            : {}
                                                    }
                                                />
                                            </span>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                        {!!segmentsDropdownState.activeId && (
                            <div className="filter-dropdown__list">
                                <ul>
                                    <li
                                        key={'All'}
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}
                                        className={classNames({
                                            'l-active':
                                                segmentsDropdownState.lookup[
                                                    segmentsDropdownState
                                                        .activeId
                                                ]?.isAll
                                        })}
                                    >
                                        <Checkbox
                                            checked={
                                                segmentsDropdownState.lookup[
                                                    segmentsDropdownState
                                                        .activeId
                                                ]?.isAll
                                            }
                                            onClick={() => {
                                                segmentsDropdownDispatch({
                                                    type: DropdownActions.TOGGLE_ITEM_VALUE_ALL,
                                                    payload:
                                                        segmentsDropdownState.activeId
                                                });
                                            }}
                                        />
                                        <span>All</span>
                                    </li>

                                    <div className="filter-dropdown__divider"></div>
                                    {segmentsDropdownState?.data
                                        ?.find(
                                            (d: any) =>
                                                d.attributeName ===
                                                segmentsDropdownState.activeId
                                        )
                                        ?.attributeValues?.map((v: any) => (
                                            <li key={v.value}>
                                                <Checkbox
                                                    testId={v.value}
                                                    checked={segmentsDropdownState.lookup[
                                                        segmentsDropdownState
                                                            .activeId
                                                    ].activeIds.includes(
                                                        v.value
                                                    )}
                                                    onClick={() => {
                                                        segmentsDropdownDispatch(
                                                            {
                                                                type: DropdownActions.TOGGLE_ITEM_VALUE,
                                                                payload: {
                                                                    item: segmentsDropdownState.activeId,
                                                                    value: v.value
                                                                }
                                                            }
                                                        );
                                                    }}
                                                >
                                                    {v.text}
                                                </Checkbox>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        )}
                    </FilterDropdown>
                    {/* FILTER DROPDOWN */}
                    <FilterDropdown
                        isSecondary={true}
                        label={filtersDropdownState.label}
                        placeholder="Filters"
                        isOpen={filtersDropdownState.isOpen}
                        setIsOpen={() => {
                            filtersDropdownDispatch({
                                type: DropdownActions.SET_IS_OPEN,
                                payload: !filtersDropdownState.isOpen
                            });
                        }}
                        disabled={
                            !canEdit ||
                            Object.keys(segmentsDropdownState.lookup).every(
                                k => !segmentsDropdownState.lookup[k]?.checked
                            )
                        }
                        allowCancel={true}
                        onClearSelection={() => {
                            filtersDropdownDispatch({
                                type: DropdownActions.CLEAR
                            });
                        }}
                    >
                        <div className="filter-dropdown__list">
                            <ul>
                                <li
                                    key={'Any'}
                                    onClick={e => {
                                        e.stopPropagation();
                                        filtersDropdownDispatch({
                                            type: DropdownActions.TOGGLE_ANY
                                        });
                                    }}
                                    className={classNames({
                                        'l-active':
                                            filtersDropdownState.lookup.Any
                                                ?.checked
                                    })}
                                >
                                    <Radio
                                        checked={
                                            filtersDropdownState.lookup.Any
                                                ?.checked
                                        }
                                    />
                                    <span>Any</span>
                                </li>

                                <div className="filter-dropdown__divider"></div>
                                {filtersDropdownState.data?.map(
                                    (segment: any) => (
                                        <li
                                            key={segment.attributeName}
                                            onClick={() => {
                                                filtersDropdownDispatch({
                                                    type: DropdownActions.TOGGLE_ACTIVE_ITEM,
                                                    payload:
                                                        segment.attributeName
                                                });
                                            }}
                                            className={classNames({
                                                'l-active':
                                                    filtersDropdownState.lookup[
                                                        segment.attributeName
                                                    ]?.checked,
                                                'l-disabled':
                                                    filtersDropdownState.lookup
                                                        .Any?.checked,
                                                'l-seemingly-disabled':
                                                    filtersDropdownState.lookup
                                                        .Any?.checked
                                            })}
                                        >
                                            <Checkbox
                                                checked={
                                                    filtersDropdownState.lookup[
                                                        segment.attributeName
                                                    ]?.checked
                                                }
                                                onClick={() => {
                                                    filtersDropdownDispatch({
                                                        type: DropdownActions.TOGGLE_ITEM,
                                                        payload:
                                                            segment.attributeName
                                                    });
                                                }}
                                                disabled={
                                                    filtersDropdownState.lookup
                                                        .Any?.checked
                                                }
                                            />
                                            <span>{segment.attributeName}</span>
                                            {filtersDropdownState.lookup[
                                                segment.attributeName
                                            ]?.activeIds.length > 0 ||
                                            filtersDropdownState.lookup[
                                                segment.attributeName
                                            ]?.isAll ? (
                                                <span style={{ marginLeft: 4 }}>
                                                    (
                                                    {
                                                        filtersDropdownState
                                                            .lookup[
                                                            segment
                                                                .attributeName
                                                        ]?.activeIds.length
                                                    }
                                                    )
                                                </span>
                                            ) : (
                                                filtersDropdownState.lookup.Any
                                                    ?.checked && (
                                                    <span
                                                        style={{
                                                            marginLeft: 4
                                                        }}
                                                    >
                                                        (Any)
                                                    </span>
                                                )
                                            )}
                                            <span className="arrow">
                                                <DropdownArrow
                                                    width={18}
                                                    height={18}
                                                    style={
                                                        filtersDropdownState.activeId ===
                                                        segment.attributeName
                                                            ? {
                                                                  transform:
                                                                      'rotate(-90deg)'
                                                              }
                                                            : {}
                                                    }
                                                />
                                            </span>
                                        </li>
                                    )
                                )}
                            </ul>
                        </div>
                        {!!filtersDropdownState.activeId && (
                            <div className="filter-dropdown__list">
                                <ul>
                                    <li
                                        key={'All'}
                                        onClick={e => {
                                            e.stopPropagation();
                                        }}
                                        className={classNames({
                                            'l-active':
                                                filtersDropdownState.lookup[
                                                    filtersDropdownState
                                                        .activeId
                                                ]?.isAll
                                        })}
                                    >
                                        <Checkbox
                                            checked={
                                                filtersDropdownState.lookup[
                                                    filtersDropdownState
                                                        .activeId
                                                ]?.isAll
                                            }
                                            onClick={() => {
                                                filtersDropdownDispatch({
                                                    type: DropdownActions.TOGGLE_ITEM_VALUE_ALL,
                                                    payload:
                                                        filtersDropdownState.activeId
                                                });
                                            }}
                                        />
                                        <span>All</span>
                                    </li>

                                    <div className="filter-dropdown__divider"></div>
                                    {filtersDropdownState.data
                                        .find(
                                            (d: any) =>
                                                d.attributeName ===
                                                filtersDropdownState.activeId
                                        )
                                        ?.attributeValues?.map((v: any) => (
                                            <li key={v.value}>
                                                <Checkbox
                                                    testId={v.value}
                                                    checked={filtersDropdownState.lookup[
                                                        filtersDropdownState
                                                            .activeId
                                                    ].activeIds.includes(
                                                        v.value
                                                    )}
                                                    onClick={() => {
                                                        filtersDropdownDispatch(
                                                            {
                                                                type: DropdownActions.TOGGLE_ITEM_VALUE,
                                                                payload: {
                                                                    item: filtersDropdownState.activeId,
                                                                    value: v.value
                                                                }
                                                            }
                                                        );
                                                    }}
                                                >
                                                    {v.text}
                                                </Checkbox>
                                            </li>
                                        ))}
                                </ul>
                            </div>
                        )}
                    </FilterDropdown>
                    {showBreakdown && (
                        <FilterDropdown
                            isSecondary={true}
                            label={breakdownsDropdownState.label}
                            placeholder="Breakdown"
                            isOpen={breakdownsDropdownState.isOpen}
                            setIsOpen={() => {
                                breakdownsDropdownDispatch({
                                    type: DropdownActions.SET_IS_OPEN,
                                    payload: !breakdownsDropdownState.isOpen
                                });
                            }}
                            disabled={
                                !canEdit ||
                                Object.keys(segmentsDropdownState.lookup).every(
                                    k =>
                                        !segmentsDropdownState.lookup[k]
                                            ?.checked
                                )
                            }
                            allowCancel={true}
                            onClearSelection={() => {
                                breakdownsDropdownDispatch({
                                    type: DropdownActions.CLEAR
                                });
                            }}
                        >
                            <div className="filter-dropdown__list">
                                <ul>
                                    <li
                                        key={'Any'}
                                        onClick={e => {
                                            e.stopPropagation();
                                            breakdownsDropdownDispatch({
                                                type: DropdownActions.TOGGLE_ANY
                                            });
                                        }}
                                        className={classNames({
                                            'l-active':
                                                breakdownsDropdownState.lookup
                                                    .Any?.checked
                                        })}
                                    >
                                        <Radio
                                            checked={
                                                breakdownsDropdownState.lookup
                                                    .Any?.checked
                                            }
                                        />
                                        <span>Any</span>
                                    </li>

                                    <div className="filter-dropdown__divider"></div>
                                    {breakdownsDropdownState.data?.map(
                                        (segment: any) => (
                                            <li
                                                key={segment.attributeName}
                                                onClick={() => {
                                                    breakdownsDropdownDispatch({
                                                        type: DropdownActions.TOGGLE_ACTIVE_ITEM,
                                                        payload:
                                                            segment.attributeName
                                                    });
                                                }}
                                                className={classNames({
                                                    'l-active':
                                                        breakdownsDropdownState
                                                            .lookup[
                                                            segment
                                                                .attributeName
                                                        ]?.checked,
                                                    'l-disabled':
                                                        breakdownsDropdownState
                                                            .lookup.Any
                                                            ?.checked,
                                                    'l-seemingly-disabled':
                                                        breakdownsDropdownState
                                                            .lookup.Any?.checked
                                                })}
                                            >
                                                <Checkbox
                                                    checked={
                                                        breakdownsDropdownState
                                                            .lookup[
                                                            segment
                                                                .attributeName
                                                        ]?.checked
                                                    }
                                                    onClick={() => {
                                                        breakdownsDropdownDispatch(
                                                            {
                                                                type: DropdownActions.TOGGLE_ITEM,
                                                                payload:
                                                                    segment.attributeName
                                                            }
                                                        );
                                                    }}
                                                    disabled={
                                                        breakdownsDropdownState
                                                            .lookup.Any?.checked
                                                    }
                                                />
                                                <span>
                                                    {segment.attributeName}
                                                </span>
                                                {breakdownsDropdownState.lookup[
                                                    segment.attributeName
                                                ]?.activeIds.length > 0 ||
                                                breakdownsDropdownState.lookup[
                                                    segment.attributeName
                                                ]?.isAll ? (
                                                    <span
                                                        style={{
                                                            marginLeft: 4
                                                        }}
                                                    ></span>
                                                ) : (
                                                    breakdownsDropdownState
                                                        .lookup.Any
                                                        ?.checked && (
                                                        <span
                                                            style={{
                                                                marginLeft: 4
                                                            }}
                                                        >
                                                            (Any)
                                                        </span>
                                                    )
                                                )}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </div>
                        </FilterDropdown>
                    )}
                </div>
                {canEdit && (
                    <div
                        onClick={handleDelete}
                        style={{
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center'
                        }}
                    >
                        <MdDelete />
                    </div>
                )}
            </div>
        </div>
    );
};

const dispatchProps = {
    onFiltersClear: clearFilters,
    setFiltersFromReport,
    setAnalysisName,
    updateMainSegments,
    updateBreakdownSegment,
    updateFilterSegment,
    generateInEditor,
    updateAnalysises,
    updateSegments,
    updateFilters,
    updateBreakdowns
};

function mapState(state: any) {
    const { analysisNamesTree } = state.account;
    const { templates } = state.templates;
    const initialAnalysisNames = getInitialAnalysisNames(
        analysisNamesTree,
        templates
    );
    const allFilterNames = state.templates.templates
        .map((template: any) => {
            return template.filterNames;
        })
        .flat()
        .filter((filterName: string) => filterName)
        .map((filter: string) => ({
            name: filter
        }));

    const {
        analysisDisplayName,
        analysisName,
        analysisType,
        attributes,
        breakdownNames,
        breakdownSegment,
        filterNames,
        filterSegment,
        generateDisabled,
        mainNames,
        mainSegments
    } = state.editor.filter;

    return {
        analysisDisplayName,
        analysisName,
        analysisType,
        attributes,
        breakdownSegment,
        breakdownNames,
        filterNames,
        filterSegment,
        generateDisabled,
        mainNames,
        mainSegments,
        allFilterNames,
        initialAnalysisNames,
        generatePending: state.explore.chart.generatePending,
        completed: state.editor.save.completed
    };
}

export default connect(mapState, dispatchProps)(Filters);

import ActionTypes from '../constants/ActionTypes';

export default function deleteModalReducer(state = {}, action: any) {
    switch (action.type) {
        case ActionTypes.OpenDeleteModal:
            return {
                ...state,
                open: true
            };
        case ActionTypes.CloseDeleteModal:
            return {
                ...state,
                open: false
            };
        case ActionTypes.DeleteBenchmarkPending: {
            return {
                ...state,
                pending: true,
                error: false
            };
        }
        case ActionTypes.DeleteBenchmarkFulfilled: {
            return {
                ...state,
                pending: false,
                open: false
            };
        }
        case ActionTypes.DeleteBenchmarkRejected: {
            return {
                ...state,
                pending: false,
                error: true
            };
        }
        default:
            return state;
    }
}

import { useContext, useEffect, useRef, useState } from 'react';
import mixPanel from '../constants/MixPanel';
import { addDefaultTextReport } from '../editor/buttons/actions';
import cn from '../lib/cn';
import { track } from '../lib/segment';
import { useAppDispatch } from '../store/hooks';
import { DashboardLayoutContext } from './DashboardLayout';
import { navigateToEditor } from './view/actions';
import InsightsReportIcon from '../icons/InsightsReport';
import TextReportIcon from '../icons/TextReport';

export default function AddReport({ rowIndex }: { rowIndex: number }) {
    const { dashboard } = useContext(DashboardLayoutContext);
    const ref = useRef<HTMLDivElement | null>(null);
    const [isActive, setIsActive] = useState(false);
    const dispatch = useAppDispatch();

    function handleAdd() {
        track(mixPanel.Events.EditDashboardAddReportClick, {
            'Dashboard Name': dashboard?.name
        });
        dispatch(navigateToEditor(dashboard?.dashboardId, null, rowIndex));
        setIsActive(false);
    }

    function handleAddText() {
        track(mixPanel.Events.DashboardEditDashboard, {
            'Dashboard Name': dashboard?.name
        });
        dispatch(addDefaultTextReport(dashboard?.dashboardId, rowIndex));
        setIsActive(false);
    }

    const handleClickOutside = (event: any) => {
        if (!ref.current) return;

        if (!ref.current.contains(event.target)) {
            setIsActive(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, [isActive]);
    return (
        <div className="relative" ref={ref}>
            <button
                onClick={() => setIsActive(!isActive)}
                className={cn(
                    // reset the button styles
                    'appearance-none border-none',
                    'outline-none focus:outline-none focus:ring-0 focus:ring-offset-0 focus:ring-offset-transparent',
                    'rounded-[2rem] p-[1.6rem]  mx-[10px] w-avail flex items-center ',
                    'cursor-pointer',
                    isActive
                        ? 'bg-ui-100 text-ui-100-inverted'
                        : 'bg-shade-h3 text-ui-50 hover:text-ui-100 hover:bg-shade-h4'
                )}
            >
                <span className={cn('')}>+ Add content </span>
            </button>
            <div
                className={cn(
                    'mt-[10px] shadow-default',
                    'flex flex-col  items-start ',
                    'rounded-[2rem] overflow-hidden  mx-[10px] w-avail bg-shade-h3',
                    isActive ? 'opacity-100 visible' : 'opacity-0 invisible',
                    'transition-all duration-200'
                )}
            >
                <div
                    onClick={handleAdd}
                    className={cn(
                        'flex gap-4 items-center w-full px-[1.6rem] py-[1rem] ',
                        'cursor-pointer',
                        'hover:bg-shade-h4'
                    )}
                >
                    <InsightsReportIcon />
                    <div className={cn('flex flex-col gap-0 items-start')}>
                        <div className="text-ui-100">Report</div>
                    </div>
                </div>

                <div
                    onClick={handleAddText}
                    className={cn(
                        'flex gap-4 items-center',
                        'cursor-pointer w-full px-[1.6rem] py-[1rem] ',
                        'hover:bg-shade-h4'
                    )}
                >
                    <TextReportIcon />
                    <div className={cn('flex flex-col gap-0 items-start')}>
                        <div className="text-ui-100">Text</div>
                    </div>
                </div>
            </div>
        </div>
    );
}

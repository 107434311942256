import axios from 'axios';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';
import ActionTypes from '../../constants/ActionTypes';

export function checkout(source: string) {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;
        const { email } = getState().auth.userInfo;

        dispatch({
            type: ActionTypes.SavePaymentMethodPending
        });

        return axios
            .put(`${Urls.BillingApi}customer/save`, {
                enterpriseId,
                email,
                source
            })
            .then(() => {
                dispatch({
                    type: ActionTypes.SavePaymentMethodFulfilled
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.SavePaymentMethodRejected
                });
            });
    };
}

export function fetchBillingInfo() {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;
        dispatch({ type: ActionTypes.FetchBillingInfoPending });

        return axios
            .post(`${Urls.BillingApi}customer`, {
                enterpriseId
            })
            .then(response => {
                dispatch({
                    type: ActionTypes.FetchBillingInfoFulfilled,
                    data: response.data
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({ type: ActionTypes.FetchBillingInfoRejected });
            });
    };
}

export function deletePaymentMethod(paymentMethodId: string) {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;
        dispatch({ type: ActionTypes.DeletePaymentMethodPending });

        return axios
            .post(`${Urls.BillingApi}remove/payment`, {
                enterpriseId,
                paymentMethodId
            })
            .then(() => {
                dispatch({
                    type: ActionTypes.DeletePaymentMethodFulfilled,
                    paymentMethodId
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({ type: ActionTypes.DeletePaymentMethodRejected });
            });
    };
}

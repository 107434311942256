import { useState } from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal';
import validator from 'email-validator';

import ButtonTypes from '../../constants/ButtonTypes';
import TextFieldTypes from '../../constants/TextFieldTypes';
import Button from '../Button';
import Stack from '../Stack';
import TextField from '../TextField';

interface SendTestEmailModalProps {
    isOpen: boolean;
    onAction: (emails: string[]) => void;
    onCancel: (e: any) => void;
}

export default function SendTestEmailModal({
    isOpen,
    onAction,
    onCancel
}: SendTestEmailModalProps) {
    const [emails, setEmails] = useState<string[]>([]);
    const [error, setError] = useState('');
    const [inputValue, setInputValue] = useState<string>('');

    const handleInputChange = (e: any): void => {
        setInputValue(e.target.value);
    };

    const handleClear = (): void => {
        setEmails([]);
    };

    function handleDeselect(email: string) {
        const index = emails.findIndex(e => e === email);
        if (index !== -1) {
            const newEmails = emails.slice();
            newEmails.splice(index, 1);
            setEmails(newEmails);
        }
    }

    function handleEmailEnter() {
        const emailInputs = inputValue.split(',');
        const validEmails: string[] = [];
        const invalidEmails: string[] = [];
        emailInputs.forEach(emailInput => {
            const emailTrimmed = emailInput.trim();
            if (emailTrimmed) {
                if (validator.validate(emailTrimmed)) {
                    validEmails.push(emailTrimmed);
                } else {
                    invalidEmails.push(emailTrimmed);
                }
            }
        });
        if (validEmails.length > 0) {
            let newEmails = emails.slice();
            newEmails = newEmails.concat(validEmails);
            setEmails(newEmails);
        }
        if (invalidEmails.length === 0) {
            setInputValue('');
            setError('');
        } else if (invalidEmails.length === 1) {
            setError(`${invalidEmails[0]} is not a valid email`);
            setInputValue(invalidEmails[0]);
        } else {
            setError(`${invalidEmails.join(', ')} are not valid emails`);
            setInputValue(invalidEmails.join(', '));
        }
    }

    function handleSend() {
        onAction(emails);
    }

    return (
        <Modal
            open={isOpen}
            width={600}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={onCancel}
            destroyOnClose={true}
            zIndex={3000}
        >
            <div
                className="modal send-test-email-modal"
                onClick={e => e.stopPropagation()}
            >
                <div className="modal__header">
                    <div className="modal__title">Send test email</div>
                </div>
                <div className="modal__content">
                    <p>
                        Sending a test email allows you and members of your team
                        to review the campaign email and ensure it looks as
                        expected. Enter the recipients that should receive the
                        email.
                    </p>
                    <div className="send-test-email-modal__email">
                        <TextField
                            componentType={TextFieldTypes.type.TAGS}
                            autoFocus={true}
                            large={true}
                            placeholder="Enter email address(es)"
                            value={inputValue}
                            tags={emails}
                            onChange={handleInputChange}
                            onBlur={handleEmailEnter}
                            onRemoveTag={handleDeselect}
                            onKeyDown={e => {
                                if (e.key === 'Enter') {
                                    handleEmailEnter();
                                } else if (
                                    e.key === 'Backspace' &&
                                    inputValue === ''
                                ) {
                                    handleDeselect(emails[emails.length - 1]);
                                }
                            }}
                        ></TextField>
                    </div>
                    <div className="send-test-email-modal__info">
                        Use commas to separate multiple emails
                    </div>
                    {error && (
                        <div className="send-test-email-modal__error">
                            {error}
                        </div>
                    )}
                </div>
                <div className="modal__footer">
                    <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="space-between"
                        style={{ width: '100%' }}
                    >
                        <Button
                            componentType={ButtonTypes.type.TERTIARY}
                            onClick={onCancel}
                        >
                            Cancel
                        </Button>
                        <Stack flexDirection="row" gap=".8rem">
                            <Button
                                componentType={ButtonTypes.type.TERTIARY}
                                onClick={handleClear}
                            >
                                Clear
                            </Button>
                            <Button
                                onClick={handleSend}
                                disabled={!emails.length}
                            >
                                Send
                            </Button>
                        </Stack>
                    </Stack>
                </div>
            </div>
        </Modal>
    );
}

SendTestEmailModal.propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    onAction: PropTypes.func,
    pending: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node
};

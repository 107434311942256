import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { IPermissionsState } from './model';
import { AsyncThunkConfig } from '../../store/store';
import axios from 'axios';
import Urls from '../../constants/Urls';
import { generateFeaturePermissions } from '../rolespermissions/CreateRole/utils';

const initialState: IPermissionsState = {
    staticPermissions: [],
    staticPermissionsByFeature: [],
    permissionsLoaded: false,
    pending: false,
    error: null
};

export const fetchPermissions = createAsyncThunk<any, void, AsyncThunkConfig>(
    'policies/fetchPolicies',
    async (_, { getState, dispatch }) => {
        const policies = await axios.post(`${Urls.RbacApi}policies`);

        dispatch(
            setPermissionsByFeature(generateFeaturePermissions(policies.data))
        );

        return policies.data;
    }
);

const permissionsSlice = createSlice({
    name: 'policies',
    initialState,
    reducers: {
        setPermissionsByFeature: (state, action) => {
            state.staticPermissionsByFeature = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchPermissions.pending, (state, _) => {
            state.pending = true;
        });
        builder.addCase(fetchPermissions.fulfilled, (state, action) => {
            state.staticPermissions = action.payload;
            state.pending = false;
            state.permissionsLoaded = true;
        });
        builder.addCase(fetchPermissions.rejected, (state, action) => {
            state.error = action.payload;
            state.pending = false;
        });
    }
});

export const { setPermissionsByFeature } = permissionsSlice.actions;
export default permissionsSlice.reducer;

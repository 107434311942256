import React, { useState } from 'react';
import WithPermissions from '../../common/WithPermissions';
import CardLogos from '../../constants/CardLogos';
import RbacActions from '../../constants/RbacActions';
import Trash from '../../icons/Trash';
import DeleteModal from './DeleteModal';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { deletePaymentMethod } from './actions';

export default function PaymentMethod() {
    const dispatch = useAppDispatch();
    const { paymentMethod, deletePending } = useAppSelector(
        state => state.admin.billing
    );
    const [showModal, setShowModal] = useState(false);

    function handleDelete() {
        setShowModal(true);
    }

    function handleCancel() {
        setShowModal(false);
    }

    function handleAction() {
        dispatch(deletePaymentMethod(paymentMethod.stripePaymentMethodId));
    }

    return (
        <div className="payment-method">
            <div className="payment-method__logo">
                <img
                    src={CardLogos[paymentMethod.cardBrand]}
                    alt={paymentMethod.cardBrand}
                />
            </div>
            <div className="payment-method__label">Card Number</div>
            <div className="payment-method__value">
                {paymentMethod.cardLast4}
            </div>
            <div className="payment-method__label">Expiry</div>
            <div className="payment-method__value">
                {paymentMethod.expiryMonth}/{paymentMethod.expiryYear}
            </div>
            <WithPermissions actions={[RbacActions['Billing/Edit']]}>
                <div className="payment-method__delete">
                    <Trash
                        className="btn-icon"
                        width={24}
                        onClick={handleDelete}
                    />
                </div>
                <DeleteModal
                    isOpen={showModal}
                    onAction={handleAction}
                    onCancel={handleCancel}
                    pending={deletePending ?? false}
                />
            </WithPermissions>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import TextField from '../../../common/TextField';
import Dropdown from '../../../common/Dropdown';
import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import range from 'lodash/range';
import Trash from '../../../icons/Trash';
import MoveVertical from '../../../common/MoveVertical';
import { QuestionValue } from './types';
import Stack from '../../../common/Stack';
import TextArea from '../../../common/TextArea';
import classNames from 'classnames';

interface AnswerEditProps {
    answer: QuestionValue;
    index: number;
    answerCount: number;
    moveUp: () => void;
    moveDown: () => void;
    updateValue: (value: string) => void;
    updateScore: (score: number) => void;
    updateDesc: (desc: string) => void;
    deleteAnswer: () => void;
}

const scoreOptions = range(11).map(n => n.toString().padStart(2));

export default function AnswerEdit({
    index,
    answer,
    answerCount,
    moveUp,
    moveDown,
    updateValue,
    updateScore,
    updateDesc,
    deleteAnswer
}: AnswerEditProps) {
    const [showDesc, setShowDesc] = useState(!!answer.description);

    return (
        <tr
            className={classNames({
                'has-desc': showDesc
            })}
        >
            <td>
                <div className="ds-input">
                    <MoveVertical
                        hideUp={index === 0}
                        hideDown={index === answerCount - 1}
                        moveUp={moveUp}
                        moveDown={moveDown}
                    />
                </div>
            </td>
            <td>
                <div className="ds-input ds-input--value">
                    <TextField
                        value={answer.value}
                        onChange={e => updateValue(e.target.value)}
                        showClose
                        onClose={() => updateValue('')}
                    />
                </div>
            </td>
            <td>
                <div className="ds-input">
                    <Dropdown
                        placeholder="Select"
                        options={scoreOptions}
                        selectedOption={answer.score.toString().padStart(2)}
                        onClick={(value: any) => updateScore(Number(value))}
                    />
                </div>
            </td>
            <td>
                <div className="ds-input">
                    <Button
                        componentType={ButtonTypes.type.TERTIARY}
                        circle={true}
                        onClick={() => deleteAnswer()}
                    >
                        <Trash width={24} height={24} />
                    </Button>
                </div>
            </td>
            <td>
                {showDesc ? (
                    <Stack gap="1.6rem" style={{ marginBottom: '1.6rem' }}>
                        <TextArea
                            value={answer.description ?? ''}
                            onChange={e => {
                                updateDesc(e.target.value);
                            }}
                        />
                        <Stack alignItems="flex-end">
                            <Button
                                componentType={ButtonTypes.type.TERTIARY}
                                onClick={() => {
                                    setShowDesc(false);
                                    updateDesc('');
                                }}
                                style={{
                                    color: 'var(--color-fail)',
                                    width: '16rem'
                                }}
                            >
                                Remove description
                            </Button>
                        </Stack>
                    </Stack>
                ) : (
                    <Button
                        componentType={ButtonTypes.type.TERTIARY}
                        onClick={() => setShowDesc(true)}
                    >
                        + Add description
                    </Button>
                )}
            </td>
        </tr>
    );
}

import React, { MouseEvent } from 'react';
import SadFace from '../icons/SadFace';
import { RouteComponentProps } from 'react-router-dom';

export default function NotFound({ history } : RouteComponentProps<{}>) {
    function handleNavigate(e: MouseEvent) {
        e.preventDefault();
        history.push('/');
    }

    return (
        <div className="forbidden">
            <div className="forbidden__content">
                <SadFace />
                <div className="forbidden__title">Invalid page (404 Error)</div>
                <div className="forbidden__desc">
                    Sorry! The page you&rsquo;re trying to access does not
                    exist. You can head back to the home page by clicking&nbsp;
                    <a
                        className="link link--large"
                        href="#"
                        onClick={handleNavigate}
                    >
                        here
                    </a>
                    .
                </div>
            </div>
        </div>
    );
}


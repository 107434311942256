import ActionTypes from '../../constants/ActionTypes';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';
import axios from 'axios';

export function assignRole(roleId: string, groupId: string) {
    return async (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;
        const { allRoles } = getState().admin.roles;
        const { allGroups } = getState().admin.groups;

        dispatch({
            type: ActionTypes.AssignGroupRoleOptimistic,
            roleId,
            groupId,
            allRoles
        });

        dispatch({
            type: ActionTypes.AssignGroupRolePending
        });

        return axios
            .put(`${Urls.RbacApi}groups/role/assign`, {
                enterpriseId,
                roleId,
                entityId: groupId
            })
            .then(response => {
                dispatch({
                    type: ActionTypes.AssignGroupRoleFulfilled,
                    roleId: response.data.roleId,
                    groupId: response.data.entityId,
                    allRoles,
                    roleName: allRoles.find(
                        (role: any) => role?.id === response.data.roleId
                    ).name,
                    groupName: allGroups.find(
                        (group: any) =>
                            group?.groupId === response.data.entityId
                    ).name
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.AssignGroupRoleRejected
                });
                dispatch({
                    type: ActionTypes.UnassignGroupRoleOptimistic,
                    roleId,
                    groupId
                });
            });
    };
}

export function unassignRole(roleId: string, groupId: string) {
    return async (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;
        const { allRoles } = getState().admin.roles;
        const { allGroups } = getState().admin.groups;

        dispatch({
            type: ActionTypes.UnassignGroupRoleOptimistic,
            roleId,
            groupId
        });
        dispatch({
            type: ActionTypes.UnassignGroupRolePending
        });
        return axios
            .put(`${Urls.RbacApi}groups/role/unassign`, {
                enterpriseId,
                roleId,
                entityId: groupId
            })
            .then(response => {
                dispatch({
                    type: ActionTypes.UnassignGroupRoleFulfilled,
                    roleId: response.data.roleId,
                    groupId: response.data.entityId,
                    roleName: allRoles.find(
                        (role: any) => role?.id === response.data.roleId
                    ).name,
                    groupName: allGroups.find(
                        (group: any) =>
                            group?.groupId === response.data.entityId
                    ).name
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.UnassignGroupRoleRejected
                });
                dispatch({
                    type: ActionTypes.AssignGroupRoleOptimistic,
                    roleId,
                    groupId,
                    allRoles
                });
            });
    };
}

export function switchRoles(
    oldRoleId: string,
    newRoleId: string,
    groupId: string
) {
    return (dispatch: any, getState: any) => {
        dispatch(unassignRole(oldRoleId, groupId));
        dispatch(assignRole(newRoleId, groupId));
    };
}

import * as Tooltip from '@radix-ui/react-tooltip';
import { Fragment, useEffect, useRef, useState } from 'react';

import precisionRound from '../../lib/precisionRound';
import ChartTooltip from '../ChartTooltip';
import Stack from '../Stack';

const getStartingPercentage = (
    diversityAttributes: any[],
    item: any,
    index: number
) => {
    return diversityAttributes.slice(0, index).reduce((acc: any, attr: any) => {
        let label = attr.label.toLowerCase();
        label = label.replace(/ /g, '-');
        const perc = item[label + '-' + 'percentage'];
        return acc + perc;
    }, 0);
};

export default function HorizontalBarChart({
    data,
    diversityAttributes,
    columnProps,
    onHover,
    onHoverEnd,
    onToggle,
    selection,
    status,
    hoveredItem
}: any) {
    return (
        <Tooltip.Provider delayDuration={0}>
            <div
                style={{
                    padding: '1.6rem',
                    width: '100%'
                }}
                data-export-type="horizontal-bar"
            >
                <div
                    style={{
                        '--row-height': '4rem',
                        display: 'grid',
                        gridTemplateColumns: 'auto 1fr',
                        columnGap: '2.4rem',
                        rowGap: '.2rem'
                    }}
                >
                    {data.map((item: any) => (
                        <Fragment key={item.id}>
                            <Label>
                                <span className="font-micro-text">
                                    {item.label}
                                </span>
                            </Label>
                            <Bars>
                                {diversityAttributes.map(
                                    (attr: any, index: number) => (
                                        <Bar
                                            item={item}
                                            attr={attr}
                                            onHover={onHover}
                                            onHoverEnd={onHoverEnd}
                                            onToggle={onToggle}
                                            startingPercentage={getStartingPercentage(
                                                diversityAttributes,
                                                item,
                                                index
                                            )}
                                            selection={selection}
                                            hoveredItem={hoveredItem}
                                            hovered={hoveredItem === attr.label}
                                            status={status}
                                            columnProps={columnProps}
                                        />
                                    )
                                )}
                            </Bars>
                        </Fragment>
                    ))}
                </div>
            </div>
        </Tooltip.Provider>
    );
}

const Label = ({ children }: any) => {
    return (
        <Stack
            justifyContent="center"
            style={{
                height: 'var(--row-height)'
            }}
        >
            {children}
        </Stack>
    );
};

const Bars = ({ children }: any) => {
    return (
        <div
            style={{
                position: 'relative',
                width: '100%',
                height: 'var(--row-height)'
            }}
        >
            {children}
        </div>
    );
};

const Bar = ({
    item,
    attr,
    status,
    selection,
    hoveredItem,
    onHover,
    onHoverEnd,
    onToggle,
    startingPercentage,
    columnProps
}: any) => {
    const ref = useRef<any>();
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const [labelOutside, setLabelOutside] = useState(false);
    let label = attr.label.toLowerCase();
    label = label.replace(/ /g, '-');
    const idle = status === 'idle';
    const color = attr?.segment?.tokens[0]?.color;
    const perc = item[label + '-' + 'percentage'];
    const labelOutsideLeft = startingPercentage > 90;
    const selectionExists = selection.length > 0;
    const selected = selection.includes(attr.label);
    const hovered = hoveredItem === attr.label;

    useEffect(() => {
        const handleResize = () => {
            if (!ref.current) return;
            const width = ref.current.offsetWidth;
            if (width <= 50) {
                setLabelOutside(true);
            } else {
                setLabelOutside(false);
            }
        };
        const observer = new ResizeObserver(handleResize);
        observer.observe(ref.current);

        return () => {
            observer.disconnect();
        };
    }, []);

    const shouldShowLabel = () => {
        const isZero = perc === 0;
        const isSingleSelection = selection.length === 1;
        if (isZero) return false;
        if (labelOutside) {
            if (selectionExists) {
                if (isSingleSelection) {
                    if (
                        selected &&
                        (selection.includes(hoveredItem) || !hoveredItem)
                    ) {
                        return true;
                    }
                }
                return false;
            }
            if (hovered) {
                return true;
            }
            return false;
        }
        return true;
    };

    const showLabel = shouldShowLabel();

    function generateSeriesItem(item: any, attr: any, columnProps: any[]) {
        const validSources = ['percentage', 'totalCount', 'includedCount'];

        let seriesItem = {
            date: null
        };

        columnProps.forEach((cp: any) => {
            if (validSources.includes(cp.source)) {
                const label = attr.label.toLowerCase().replace(/ /g, '-');
                // @ts-ignore
                seriesItem[cp.source] = item[label + '-' + cp.source];
            }
        });

        return seriesItem;
    }

    return (
        <Tooltip.Root open={tooltipOpen}>
            <Tooltip.Trigger asChild>
                <div
                    ref={ref}
                    onMouseEnter={() => {
                        onHover(attr.label);
                        setTooltipOpen(true);
                    }}
                    onMouseLeave={() => {
                        onHoverEnd();
                        setTooltipOpen(false);
                    }}
                    onClick={e => {
                        e.stopPropagation();
                        e.preventDefault();
                        onToggle(attr.label);
                    }}
                    style={{
                        cursor: 'pointer',
                        position: 'relative',
                        display: 'inline-block',
                        width: `${perc}%`,
                        height: '100%',
                        opacity: selected || hovered || idle ? 1 : 0.1,
                        background: color,
                        transition: 'all .1s ease-in-out',
                        animation:
                            '.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) horizontal-reveal',
                        transformOrigin: 'left'
                    }}
                >
                    <div
                        style={{
                            display: showLabel ? 'block' : 'none',
                            position: 'absolute',
                            top: '50%',
                            right:
                                labelOutsideLeft && labelOutside ? 'unset' : 0,
                            left:
                                labelOutsideLeft && labelOutside ? 0 : 'unset',
                            transform: `translate(${
                                labelOutside
                                    ? labelOutsideLeft
                                        ? 'calc(-100% - .8rem)'
                                        : 'calc(100% + 0.8rem)'
                                    : '-.8rem'
                            }, -50%)`,
                            color: labelOutside
                                ? 'var(--color-ui-100)'
                                : 'var(--color-ui-100-inverted)'
                            // animation:
                            // '.4s 1s cubic-bezier(0.785, 0.135, 0.15, 0.86) fade-in'
                        }}
                    >
                        {precisionRound(perc, 1)}%
                    </div>
                </div>
            </Tooltip.Trigger>
            <Tooltip.Portal>
                <Tooltip.Content side="bottom" sideOffset={16}>
                    <ChartTooltip
                        dataPoint={{
                            ...attr,
                            color: attr.segment.tokens[0]?.color
                        }}
                        seriesItem={generateSeriesItem(item, attr, columnProps)}
                        columnProps={columnProps}
                        interval={'Month'}
                    ></ChartTooltip>
                </Tooltip.Content>
            </Tooltip.Portal>
        </Tooltip.Root>
    );
};

import WithPermissions from '../../../../common/WithPermissions';
import RbacActions from '../../../../constants/RbacActions';
import HeaderWrapper from '../../../../common/HeaderWrapper';

const Header = () => {
    return (
        <WithPermissions actions={[RbacActions['Campaign/Edit']]}>
            <HeaderWrapper>
                <h3 className="font-title">Select Self ID campaign template</h3>
            </HeaderWrapper>
        </WithPermissions>
    );
};

export default Header;

const defaultColors = {
    ui: {
        100: {
            light: '#F2F2FB',
            dark: '#F2F2FB'
        }
    },
    graph: [
        {
            name: 'graph-1',
            colors: {
                light: '#F56700',
                dark: '#FF9133'
            }
        },
        {
            name: 'graph-2',
            colors: {
                light: '#F1AE00',
                dark: '#FFE99A'
            }
        },
        {
            name: 'graph-3',
            colors: {
                light: '#A140CE',
                dark: '#C050F5'
            }
        },
        {
            name: 'graph-4',
            colors: {
                light: '#CF47CF',
                dark: '#FFAFFF'
            }
        },
        {
            name: 'graph-5',
            colors: {
                light: '#3258F8',
                dark: '#335AFF'
            }
        },
        {
            name: 'graph-6',
            colors: {
                light: '#01B9BB',
                dark: '#50E5E7'
            }
        },
        {
            name: 'graph-7',
            colors: {
                light: '#0B812C',
                dark: '#2CC056'
            }
        },
        {
            name: 'graph-8',
            colors: {
                light: '#15B742',
                dark: '#BCFF9C'
            }
        },
        {
            name: 'graph-9',
            colors: {
                light: '#E22A40',
                dark: '#FF5F72'
            }
        },
        {
            name: 'graph-10',
            colors: {
                light: '#FFA9B3',
                dark: '#D95565'
            }
        },
        {
            name: 'graph-11',
            colors: {
                light: '#DA3400',
                dark: '#F35410'
            }
        },
        {
            name: 'graph-12',
            colors: {
                light: '#EC5F4C',
                dark: '#FF9979'
            }
        },
        {
            name: 'graph-13',
            colors: {
                light: '#C53B96',
                dark: '#DA3BA4'
            }
        },
        {
            name: 'graph-14',
            colors: {
                light: '#E165B7',
                dark: '#FD8ED7'
            }
        },
        {
            name: 'graph-15',
            colors: {
                light: '#298C8C',
                dark: '#189797'
            }
        },
        {
            name: 'graph-16',
            colors: {
                light: '#38BA8B',
                dark: '#86EAC6'
            }
        }
    ]
};

export default defaultColors;

import { combineReducers } from 'redux';
import chartReducer from './chart/reducer';
import filterReducer from './filter/reducer';
import subtableReducer from './subtable/reducer';
import detailReducer from './detail/reducer';
import searchesReducer from './searches/reducer';
import aiInsightsReducer from './aiInsights/reducer';

export default combineReducers({
    filter: filterReducer,
    chart: chartReducer,
    subtable: subtableReducer,
    detail: detailReducer,
    searches: searchesReducer,
    aiInsights: aiInsightsReducer
});

import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { MdAddAPhoto } from 'react-icons/md';
import Button from '../common/Button';
import TextField from '../common/TextField';
import sanitize from '../lib/sanitize';
import { setLightMode } from '../auth/actions';
import ThemeCard from '../common/ThemeCard';
import ThemeCardTypes from '../constants/ThemeCardTypes';
import LSKeys from '../constants/LSKeys';

class UserInfoForm extends Component {
    constructor() {
        super();
        this.state = { firstName: '', lastName: '', lightMode: false };
    }

    handleDone(e) {
        e.preventDefault();
        const { firstName, lastName, image, lightMode } = this.state;
        this.props.onDone(
            sanitize(firstName).trim(),
            sanitize(lastName).trim(),
            image,
            lightMode
        );
    }

    handleFirstNameChange(e) {
        const firstName = e.target.value;
        this.setState({ firstName });
    }

    handleLastNameChange(e) {
        const lastName = e.target.value;
        this.setState({ lastName });
    }

    handlePhotoChange() {
        if (this.fileUpload.files.length) {
            const file = this.fileUpload.files[0];
            const regex = /(.*?)\.(jpg|bmp|jpeg|png|tif|tiff|gif)$/;
            if (!file.name.match(regex)) {
                this.props.addMessage('Please select an image', true);
                return;
            }
            if (file.size > 10000000) {
                this.props.addMessage('Selected image is too large', true);
                return;
            }
            const reader = new FileReader();
            reader.onload = e => {
                this.setState({ image: e.target.result });
            };
            reader.readAsDataURL(file);
        }
    }

    handleLightModeChange(lightMode) {
        this.setState({ lightMode });
        this.props.setLightMode(lightMode);
        if (lightMode) {
            localStorage.setItem(LSKeys.LightMode, 'true');
        } else {
            localStorage.removeItem(LSKeys.LightMode);
        }
    }

    render() {
        const { firstName, lastName, image } = this.state;
        const { pending } = this.props;
        const disabled =
            !sanitize(firstName).trim() || !sanitize(lastName).trim();

        return (
            <form className="user-form" onSubmit={e => e.preventDefault()}>
                <div className="user-form__top">
                    <div className="user-form__title">Enter information</div>
                    <div className="user-form__subtitle">
                        Before you get started, please enter the information
                        below.
                    </div>
                </div>
                <div className="user-form__fields">
                    <div className="user-form__fields-left">
                        <div className="user-form__fname">
                            <TextField
                                placeholder="First Name"
                                value={firstName}
                                onChange={this.handleFirstNameChange.bind(this)}
                                autoFocus={true}
                                showClose={true}
                            />
                        </div>
                        <div className="user-form__lname">
                            <TextField
                                placeholder="Last Name"
                                value={lastName}
                                onChange={this.handleLastNameChange.bind(this)}
                                showClose={true}
                            />
                        </div>
                    </div>
                    <div className="user-form__fields-right">
                        <div className="user-form__photo">
                            <label
                                className="user-form__photo--label"
                                htmlFor="photoUpload"
                            >
                                {image ? (
                                    <img
                                        src={image}
                                        alt="photo"
                                        className="user-form__phot0o--img"
                                    />
                                ) : (
                                    <MdAddAPhoto size={24} color="#ccc" />
                                )}
                            </label>
                            <input
                                type="file"
                                id="photoUpload"
                                ref={c => {
                                    this.fileUpload = c;
                                }}
                                className="user-form__photo--upload"
                                accept="image/*"
                                onChange={this.handlePhotoChange.bind(this)}
                            />
                        </div>
                    </div>
                </div>
                <div className="user-form__theme">
                    <div className="user-form__theme-title">
                        Please choose color mode
                    </div>
                    <div className="user-form__theme-options">
                        <ThemeCard
                            cardType={ThemeCardTypes.DARK}
                            onClick={() =>
                                this.handleLightModeChange(false)
                            }
                            lightMode={this.state.lightMode}
                        />
                        <ThemeCard
                            cardType={ThemeCardTypes.LIGHT}
                            onClick={() =>
                                this.handleLightModeChange(true)
                            }
                            lightMode={this.state.lightMode}
                        />
                    </div>
                </div>

                <div className="user-form__bottom">
                    <Button
                        loading={pending}
                        onClick={this.handleDone.bind(this)}
                        disabled={disabled}
                    >
                        Done
                    </Button>
                </div>
            </form>
        );
    }
}

UserInfoForm.propTypes = {
    location: PropTypes.object,
    onDone: PropTypes.func,
    pending: PropTypes.bool,
    addMessage: PropTypes.func,
    userInfo: PropTypes.object
};

function mapStateToProps(state) {
    return {
        userInfo: state.auth.userInfo
    };
}

const dispatchProps = {
    setLightMode
};

export default withRouter(
    connect(mapStateToProps, dispatchProps)(UserInfoForm)
);

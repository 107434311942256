import React from 'react';
import { ChartProps } from '../types';
import { getSVG } from './utils';

const StackedBar = ({ type, count = 6 }: ChartProps) => {
    const StackedBarChart = getSVG(type, count);
    return (
        <svg
            width="100%"
            height="100%"
            viewBox="0 0 304 86"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect
                x="10"
                y="10"
                width="82"
                height="3"
                fill="var(--color-ui-50)"
                fill-opacity="0.2"
                className="animated__horizontal-bar"
            />
            <svg
                width="100%"
                height="100%"
                viewBox="-10 -20 304 56"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className="animated__stacked-bar"
            >
                {StackedBarChart}
            </svg>
        </svg>
    );
};

export default StackedBar;

function removeAnimations(node: any): void {
    let children = node.children;

    node.style.transition = 'none';
    node.style.animation = 'none';

    for (let i = 0; i < children.length; i++) {
        removeAnimations(children[i]);
    }
}

export default removeAnimations;

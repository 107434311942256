import { KeyValuePair } from '../types';

const logos: KeyValuePair = {
    Gusto: '/images/gusto-logo.svg',
    Workday: '/images/workday-logo.png',
    CSV: '/images/csv-logo.svg',
    Trinet: '/images/trinet-logo.svg',
    Zenefits: '/images/zenefits-logo.svg',
    Hibob: '/images/hibob-logo.svg',
    Ultipro: '/images/ultipro-logo.png',
    ADP: '/images/adp-logo.svg',
    Namely: '/images/namely.png',
    BambooHR: '/images/bamboo-logo.png'
};

export default logos;

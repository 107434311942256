import { appModes } from '../types';

export default function modeSelector(pathname: string) {
    if (pathname === '/') {
        return appModes.dashboards;
    }
    if (pathname.startsWith('/explore')) {
        return appModes.explore;
    }
    if (pathname.startsWith('/editor')) {
        return appModes.editor;
    }
    if (pathname.startsWith('/timeline')) {
        return appModes.timeline;
    }
    if (pathname.startsWith('/measure')) {
        return appModes.measure;
    }
    if (pathname.startsWith('/collect')) {
        return appModes.collect;
    }

    if (pathname.startsWith('/admin')) {
        return appModes.admin;
    }

    if (pathname.startsWith('/dashboard')) {
        return appModes.dashboard;
    }

    return '';
}

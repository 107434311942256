import React from 'react';
import { CSSTransition } from 'react-transition-group';
import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';
import Close from '../icons/Close';
import DropdownArrow from '../icons/DropdownArrow';

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
    onClick: (option: any) => void;
    placeholder?: string;
    buttonLarge?: boolean;
    allowCancel?: boolean;
    disabled?: boolean;
    isOpen?: boolean;
    label?: string;
    children?: React.ReactNode;
    onClearSelection?: () => void;
    onMouseLeaveDropdown?: () => void;
    limitHeight?: number;
    alignDropdownRight?: boolean;
}

const Dropdown = ({
    onClick,
    placeholder,
    allowCancel,
    disabled,
    isOpen,
    label,
    onClearSelection,
    buttonLarge,
    children,
    onMouseLeaveDropdown,
    limitHeight,
    alignDropdownRight,
    ...rest
}: DropdownProps) => {
    const dropdownRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                onMouseLeaveDropdown?.();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleClickOutside, false);
        return () => {
            document.removeEventListener('keydown', handleClickOutside, false);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="filter-dropdown" {...rest}>
            <Button
                type="button"
                componentType={ButtonTypes.type.SECONDARY}
                large={buttonLarge}
                onClick={onClick}
                activated={isOpen}
                disabled={disabled}
                selected={!!label}
            >
                <span className="long-text">{label || placeholder}</span>
                <div style={{ width: 4 }} />
                {allowCancel && label ? (
                    <Close
                        width={12}
                        height={12}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            if (!disabled) {
                                onClearSelection?.();
                            }
                        }}
                    />
                ) : (
                    <DropdownArrow width={18} height={18} />
                )}
            </Button>
            <CSSTransition
                in={isOpen}
                timeout={300}
                mountOnEnter
                unmountOnExit
                classNames="fade-scale"
            >
                <div
                    className="filter-dropdown__menu"
                    data-test={`dropdown-menu-${placeholder}`}
                    onMouseLeave={onMouseLeaveDropdown}
                    ref={dropdownRef}
                    style={{
                        maxHeight: limitHeight ?? '50vh',
                        overflowY: 'auto',
                        ...(alignDropdownRight && {
                            right: '-3.2rem'
                        })
                    }}
                >
                    {children}
                </div>
            </CSSTransition>
        </div>
    );
};

export default Dropdown;

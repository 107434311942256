import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Legend from '../common/Legend';

function LegendStateWrapper({ data, type, length, multiLine, lineWidth }) {
    const [testData, setTestData] = useState(data);

    function setActiveLabel(label) {
        setTestData(
            data.map(item => {
                if (item.label === label) {
                    return { ...item, active: true };
                }
                return { ...item, active: false };
            })
        );
    }

    function handleToggle(label) {
        setTestData(
            data.map(item => {
                if (item.label === label) {
                    return { ...item, selected: !item.selected };
                }
                return item;
            })
        );
    }

    function clearActiveLabel() {
        setTestData(
            data.map(item => {
                return {
                    ...item,
                    active: false
                };
            })
        );
    }

    return (
        <Legend
            type={type}
            data={testData}
            length={length}
            multiLine={multiLine}
            lineWidth={lineWidth}
            setActiveLabel={setActiveLabel}
            clearActiveLabel={clearActiveLabel}
            handleToggle={handleToggle}
        ></Legend>
    );
}

LegendStateWrapper.propTypes = {
    data: PropTypes.array,
    length: PropTypes.number,
    multiLine: PropTypes.bool,
    lineWidth: PropTypes.number,
    type: PropTypes.string
};

export default LegendStateWrapper;

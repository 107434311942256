import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import Info from '../../icons/Info';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import WithPermissions from '../../common/WithPermissions';

import {
    setActiveTab,
    openModal,
    openInfoModal,
    setActiveGoalTab
} from '../settings/reducer';
import { MeasureType } from '../types';
import { capitalize } from '../utils';
import RbacActions from '../../constants/RbacActions';
import HeaderWrapper from '../../common/HeaderWrapper';
import Stack from '../../common/Stack';

const TABS_TO_SHOW: MeasureType[] = ['goal'];

const MeasureHeader = () => {
    const { activeTab, goalTab } = useAppSelector(
        state => state.measure.settings
    );
    const dispatch = useAppDispatch();

    const handleTabChange = (tab: MeasureType) => {
        dispatch(setActiveTab(tab));
    };

    const handleAddMeasure = () => {
        dispatch(openModal(activeTab));
    };

    return (
        <WithPermissions actions={[RbacActions['Goals/View']]}>
            <HeaderWrapper>
                <Stack flexDirection="row" gap=".8rem">
                    <Button
                        selected={goalTab === 'active'}
                        componentType={ButtonTypes.type.LIST_ITEM}
                        selectedStyle={ButtonTypes.selectedStyle.UNDERLINE}
                        onClick={() => {
                            dispatch(setActiveGoalTab('active'));
                        }}
                        navigation
                        large={false}
                    >
                        Active
                    </Button>
                    <Button
                        selected={goalTab === 'completed'}
                        componentType={ButtonTypes.type.LIST_ITEM}
                        selectedStyle={ButtonTypes.selectedStyle.UNDERLINE}
                        onClick={() => {
                            dispatch(setActiveGoalTab('completed'));
                        }}
                        navigation
                        large={false}
                    >
                        Completed
                    </Button>
                </Stack>
                <div className="measure-header" data-test="measure-header">
                    <Stack flexDirection="row" gap=".8rem">
                        <div
                            className={
                                TABS_TO_SHOW.length > 1
                                    ? 'measure-header__tabs'
                                    : 'measure-header__tabs__title'
                            }
                        >
                            {TABS_TO_SHOW.length > 1 ? (
                                TABS_TO_SHOW.map(tab => (
                                    <Button
                                        onClick={() => handleTabChange(tab)}
                                        componentType={
                                            ButtonTypes.type.TERTIARY
                                        }
                                        activated={activeTab === tab}
                                    >
                                        {tab.charAt(0).toUpperCase() +
                                            tab.slice(1) +
                                            's'}
                                    </Button>
                                ))
                            ) : (
                                <div className="measure-header__title">
                                    {/* {TABS_TO_SHOW[0].charAt(0).toUpperCase() +
                                        TABS_TO_SHOW[0].slice(1) +
                                        's'} */}
                                </div>
                            )}
                        </div>
                        <Button
                            componentType={ButtonTypes.type.SECONDARY}
                            onClick={() => dispatch(openInfoModal())}
                        >
                            <Info
                                className="btn-icon measure-header__info-icon"
                                width={24}
                                height={24}
                            />
                            Learn more about Goals
                        </Button>
                        <WithPermissions actions={[RbacActions['Goals/Edit']]}>
                            <Button
                                onClick={handleAddMeasure}
                                data-test="add-measure-element"
                            >
                                Add {capitalize(activeTab)}
                            </Button>
                        </WithPermissions>
                    </Stack>
                </div>
            </HeaderWrapper>
        </WithPermissions>
    );
};

export default MeasureHeader;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={19}
        height={19}
        viewBox="0 0 19 19"
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M8.906 13.38h1.188V8.292H8.906v5.086ZM9.5 6.986c.19 0 .35-.065.48-.194a.651.651 0 0 0 .193-.48c0-.19-.065-.35-.194-.479a.651.651 0 0 0-.48-.193c-.19 0-.35.064-.479.193a.651.651 0 0 0-.193.48c0 .19.064.35.193.479a.651.651 0 0 0 .48.194Zm0 10.41c-1.847-.462-3.365-1.534-4.552-3.216-1.188-1.682-1.782-3.526-1.782-5.532v-4.71L9.5 1.564l6.333 2.375v4.71c0 2.006-.594 3.85-1.781 5.532-1.188 1.682-2.705 2.754-4.552 3.216Zm0-1.227c1.517-.502 2.754-1.448 3.71-2.84.957-1.392 1.436-2.953 1.436-4.681v-3.88L9.5 2.83 4.354 4.77v3.879c0 1.729.478 3.289 1.435 4.68.957 1.393 2.193 2.34 3.71 2.84Z"
        />
    </svg>
);
export default SvgComponent;

import React from 'react';
import { TabProps } from '../types';

interface KPIsProps extends TabProps {}

const KPIs = (props: KPIsProps) => {
    return <div>KPIs</div>;
};

export default KPIs;

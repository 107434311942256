import produce from 'immer';

import ActionTypes from '../constants/ActionTypes';

interface ReducerState {
    [key: string]: any;
    showCreate?: boolean;
    showTiles: boolean;
}

export default function dashboardsReducer(
    state: ReducerState = { showTiles: true },
    action: any
): ReducerState {
    switch (action.type) {
        case ActionTypes.GetSharedDashboardsFulfilled:
        case ActionTypes.GetDashboardsFulfilled: {
            const { dashboards } = action;
            return produce(state, draftState => {
                dashboards.forEach((d: any) => {
                    draftState[d.dashboardId] = d;
                });
            });
        }
        case ActionTypes.GetDashboardFulfilled: {
            const { dashboard } = action;
            return produce(state, draftState => {
                draftState[dashboard.dashboardId] = dashboard;
            });
        }
        case ActionTypes.SaveReportFulfilled: {
            const { dashboardId, report } = action;
            return produce(state, draftState => {
                const dashboard = draftState[dashboardId];
                if (dashboard) {
                    const reportIndex = dashboard.reports.findIndex(
                        (r: any) => r.reportId === report.reportId
                    );
                    if (reportIndex !== -1) {
                        dashboard.reports[reportIndex] = report;
                    } else {
                        dashboard.reports.push(report);
                    }
                }
            });
        }
        case ActionTypes.ShowCreateDashboardModal:
            return {
                ...state,
                showCreate: true
            };
        case ActionTypes.CloseCreateDashboardModal:
            return {
                ...state,
                showCreate: false
            };
        case ActionTypes.ShowListView:
            return {
                ...state,
                showTiles: false
            };
        case ActionTypes.ShowTilesView:
            return {
                ...state,
                showTiles: true
            };
        case ActionTypes.DuplicateDashboardFulfilled:
        case ActionTypes.SetDashboard:
        case ActionTypes.CreateDashboardFulfilled: {
            const { dashboard } = action;

            return produce(state, draftState => {
                draftState[dashboard.dashboardId] = dashboard;
            });
        }
        case ActionTypes.UpdateDashboardFulfilled: {
            const { dashboard } = action;
            return produce(state, draftState => {
                const updatedDashboard = draftState[dashboard.dashboardId];
                if (updatedDashboard) {
                    updatedDashboard.name = dashboard.name;
                    updatedDashboard.reports = dashboard.reports;
                }
            });
        }
        case ActionTypes.DeleteReportFulfilled: {
            const { dashboardId, reportId } = action;
            return produce(state, draftState => {
                const dashboard = draftState[dashboardId];
                if (dashboard) {
                    dashboard.reports = dashboard.reports.filter(
                        (r: any) => r.reportId !== reportId
                    );
                }
            });
        }
        case ActionTypes.ShowDeleteDashboardModal:
            return {
                ...state,
                showDelete: true,
                deleteDashboardId: action.dashboardId
            };
        case ActionTypes.CloseDeleteDashboardModal:
            return {
                ...state,
                showDelete: false,
                deleteDashboardId: undefined
            };
        case ActionTypes.DeleteDashboardPending:
            return {
                ...state,
                deletePending: true
            };
        case ActionTypes.DeleteDashboardRejected:
            return {
                ...state,
                deletePending: false
            };
        case ActionTypes.DeleteDashboardFulfilled: {
            const { dashboardId } = action;
            return produce(state, draftState => {
                draftState[dashboardId] = undefined;
                draftState.deletePending = false;
            });
        }
        case ActionTypes.InsertReportFulfilled:
        case ActionTypes.UpdateAllReportsFulfilled:
        case ActionTypes.UpdateReportFulfilled: {
            const { dashboardId, dashboard } = action;
            return produce(state, draftState => {
                draftState[dashboardId] = dashboard;
            });
        }
        case ActionTypes.ShowShareModal:
            return {
                ...state,
                showShare: true,
                shareDashboardId: action.dashboardId
            };
        case ActionTypes.CloseShareModal:
            return {
                ...state,
                showShare: false,
                shareDashboardId: undefined
            };
        case ActionTypes.ShowDuplicateModal:
            return {
                ...state,
                showDuplicate: true,
                duplicateDashboardId: action.dashboardId
            };
        case ActionTypes.CloseDuplicateModal:
            return {
                ...state,
                showDuplicate: false,
                duplicateDashboardId: undefined
            };
        case ActionTypes.ShowApplyFilterModal:
            return {
                ...state,
                showApplyFilter: true,
                applyFilterDashboardId: action.dashboardId
            };
        case ActionTypes.CloseApplyFilterModal:
            return {
                ...state,
                showApplyFilter: false,
                applyFilterDashboardId: undefined
            };
        case ActionTypes.GetSharedUsersPending:
            return {
                ...state,
                sharedUsers: []
            };
        case ActionTypes.GetSharedUsersFulfilled: {
            const { sharedUsers } = action;
            return {
                ...state,
                sharedUsers
            };
        }
        case ActionTypes.GetVaultFulfilled: {
            const { dashboardId, vaultId } = action;
            return produce(state, draftState => {
                if (!draftState.vaultMap) {
                    draftState.vaultMap = {};
                }
                draftState.vaultMap[dashboardId] = vaultId;
            });
        }
        default:
            return state;
    }
}

import { Fragment, useContext, useState } from 'react';
import { DashboardLayoutContext, LayoutRow } from './DashboardLayout';
import Droppable from './Droppable';
import Row from './Row';
import WithPermissions from '../common/WithPermissions';
import RbacActions from '../constants/RbacActions';
import usePermissions from '../hooks/usePermissions';
import ResizeOverlay from './ResizeOverlay';
import AddReport from './AddReport';
import RowSizeHandle from './RowSizeHandle';

const MIN_ROW_HEIGHT = 200;
const MAX_ROW_HEIGHT = 800;

function throttle(callback: any, limit: any) {
    let inThrottle: any;
    return function (...args: any) {
        if (!inThrottle) {
            callback(...args);
            inThrottle = true;
            setTimeout(() => (inThrottle = false), limit);
        }
    };
}

export default function Rows() {
    const hasEditAccess = usePermissions({
        actions: [RbacActions['Dashboards/Edit']]
    });
    const { setShouldAutosave, globalLayoutState, setGlobalLayoutState } =
        useContext(DashboardLayoutContext);

    const [isDragging, setIsDragging] = useState(false);

    const handleMouseDown = (key: string, event: any) => {
        setIsDragging(true);
        const startY = event.clientY;
        const startHeight = globalLayoutState.find(
            (row: LayoutRow) => row.rowId === key
        )?.rowSize;

        if (!startHeight) {
            return;
        }

        const handleMouseMove = throttle((moveEvent: any) => {
            window.requestAnimationFrame(() => {
                const newHeight = Math.min(
                    Math.max(
                        MIN_ROW_HEIGHT,
                        startHeight + (moveEvent.clientY - startY)
                    ),
                    MAX_ROW_HEIGHT
                );
                setGlobalLayoutState((currentRows: LayoutRow[]) => {
                    const newRows = currentRows.map((row: LayoutRow) => {
                        if (row.rowId === key) {
                            return {
                                ...row,
                                rowSize: newHeight
                            };
                        }
                        return row;
                    });
                    return newRows;
                });
                setShouldAutosave(true);
            });
        }, 25);

        const handleMouseUp = () => {
            setIsDragging(false);
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
        };

        document.addEventListener('mousemove', handleMouseMove);
        document.addEventListener('mouseup', handleMouseUp);
    };

    return (
        <div className="grid grid-cols-1">
            <ResizeOverlay isDragging={isDragging} />
            <Droppable id={`newRow_top`} direction="horizontal">
                <div
                    className={`relative  h-[20px] mx-[10px]`}
                    style={{
                        width: '-webkit-fill-available',
                        cursor: 'row-resize'
                    }}
                    data-name="row-resizer"
                />
            </Droppable>

            {globalLayoutState.map((row: LayoutRow, index: number) => {
                const visibleItems = row.columns.filter(
                    (col: any) => !col.isHidden
                );
                const isEmpty = visibleItems.length === 0;
                const isLocked =
                    row.columns.some(col => col.isHidden) || !hasEditAccess;

                return (
                    <Fragment key={row.rowId}>
                        <div
                            data-empty={isEmpty}
                            style={{
                                height: isEmpty ? 0 : row.rowSize + 'px'
                            }}
                            className="relative"
                        >
                            <Row
                                items={visibleItems.map((col: any) => col.id)}
                                id={row.rowId}
                                rowIndex={index}
                                isLocked={isLocked}
                                rowIsResizing={isDragging}
                            />
                        </div>
                        {isEmpty ? (
                            <div />
                        ) : (
                            <RowSizeHandle
                                handleMouseDown={handleMouseDown}
                                row={row}
                                isDragging={isDragging}
                                disabled={isLocked}
                            />
                        )}
                    </Fragment>
                );
            })}
            <WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
                <AddReport rowIndex={globalLayoutState.length} />
            </WithPermissions>
        </div>
    );
}

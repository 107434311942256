import React from 'react';
import Wink from '../lottie/wink.json';
import LottieAnimation from '../common/LottieAnimation';

interface WaitPageProps {
    wink: boolean;
}

export default function WaitPage({ wink } : WaitPageProps) {
    return (
        <div className="wait-page">
            {wink ? (
                <LottieAnimation
                    width={200}
                    height={200}
                    animation={Wink}
                    autoplay={true}
                ></LottieAnimation>
            ) : (
                <LottieAnimation
                    width={200}
                    height={200}
                    animation={Wink}
                    autoplay={true}
                ></LottieAnimation>
            )}
        </div>
    );
}


import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 49 49"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x={0.724}
                y={0.031}
                width={48}
                height={48}
                rx={4}
                fill="var(--color-shade-h3)"
            />
            <path fill="var(--color-ui-75)" d="M16.744 14.031h14v2h-14z" />
            <path d="M16.744 32.031h10v2h-10z" />
            <path fill="var(--color-ui-75)" d="M34.744 14.031h14v2h-14z" />
            <path d="M34.744 32.031h14v2h-14z" />
            <path fill="var(--color-ui-75)" d="M6.724 14.031h4v2h-4z" />
            <path d="M6.724 32.031h4v2h-4zM16.744 20.031h7v2h-7zM34.744 20.031h13.98v2h-13.98zM6.724 20.031h5v2h-5z" />
            <path
                fill="var(--color-ui-75)"
                d="M16.744 26.031h13v2h-13zM34.744 26.031h14v2h-14zM6.724 26.031h3v2h-3z"
            />
        </svg>
    );
}

export default SvgComponent;

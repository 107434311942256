import React from 'react';
import MoreMenu from './MoreMenu';
import HMore from '../../../../../icons/HMore';
import Popover from '../../../../../common/Popover';

interface MoreMenuWrapperProps {
    reportId: string;
    moreOpen?: boolean;
    setMoreOpen?: (open: boolean) => void;
}

const MoreMenuWrapper = ({
    reportId,
    moreOpen,
    setMoreOpen
}: MoreMenuWrapperProps) => {
    return (
        <Popover
            placement="bottomRight"
            visible={moreOpen}
            overlayClassName="popover--no-padding"
            destroyTooltipOnHide
            onVisibleChange={() => {
                setMoreOpen?.(!moreOpen);
            }}
            content={<MoreMenu setMoreOpen={setMoreOpen} reportId={reportId} />}
        >
            <div onClick={e => e.stopPropagation()}>
                <HMore className="icon" />
            </div>
        </Popover>
    );
};

export default MoreMenuWrapper;

import { useAppSelector } from '../../store/hooks';
import WithPermissions from '../../common/WithPermissions';
import RbacActions from '../../constants/RbacActions';

const GoalHeader = () => {
    const goal = useAppSelector(state => state.measure.goal?.goal);

    return (
        <WithPermissions actions={[RbacActions['Goals/View']]}>
            <div className="font-title">{capitalize(goal?.goalName || '')}</div>
        </WithPermissions>
    );
};

function capitalize(str: string) {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export default GoalHeader;

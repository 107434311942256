export default function getMixPanelObjectForFilters(analysisName: string, segments: any, attributes: any) {
    const obj: any = {
        Metric: analysisName,
    };
    segments.forEach((s: any) => {
        const valuesArray = Array.isArray(s.values) ? s.values : [s.values];

        const labels = valuesArray.map((v: any) => {
            const attribute = attributes.find((a: any) => a.attributeName === s.name);
            if (attribute) {
                const attributeValue = attribute.attributeValues.find((av: any) => av.value === v);
                if (attributeValue) {
                    return attributeValue.text;
                }
            }
            return v;
        });
        obj[s.name] = labels.join(', ');
    });
    return obj;
}

import { push } from 'connected-react-router';
import moment from 'moment';
import qs from 'qs';
import ActionTypes from '../../constants/ActionTypes';
import ChartTypes from '../../constants/ChartTypes';
import mixPanel from '../../constants/MixPanel';
import getMixPanelObjectForFilters from '../../lib/getMixPanelObjectForFilters';
import { track } from '../../lib/segment';
import { generate } from '../../store/actionUtils';

export function fetchBySegment(employeeAttribute: string) {
    return (dispatch: any, getState: any) => {
        const {
            analysisName,
            mainSegments,
            filterSegment,
            attributes,
            interval,
            intervalCount,
            startDate,
            endDate
        } = getState().explore.filter;
        const { data, dates, currentIndex, intersectionAttributes } =
            getState().explore.chart;
        const date = moment.utc(dates[currentIndex]).format('YYYY-MM-DD');

        const state = {
            interval,
            intervalCount,
            startDate,
            endDate,
            date,
            analysisName,
            mainSegments,
            filterSegment,
            employeeAttribute,
            attributes,
            intersectionAttributes,
            chartData: data
        };

        const actionTypes = {
            subtablePending: ActionTypes.FetchBySegmentPending,
            subtableRejected: ActionTypes.FetchBySegmentRejected,
            invalidVariation: ActionTypes.InvalidVariation,
            noData: ActionTypes.FetchBySegmentNoData,
            subtableFulfilled: ActionTypes.FetchBySegmentFulfilled,
            primaryDataFulfilled:
                ActionTypes.GeneratePrimaryDataFulfilledInDetailSection
        };

        return generate(getState, dispatch, state, actionTypes);
    };
}

export function closeSegment(employeeAttribute: string) {
    return {
        type: ActionTypes.CloseSegment,
        employeeAttribute
    };
}

export function navigateToDetail(
    lifecycle: string,
    employeeAttribute: string,
    employeeAttributeValue: any,
    navProps: any
) {
    return (dispatch: any, getState: any) => {
        let {
            analysisName,
            mainSegments,
            filterSegment,
            attributes,
            interval,
            intervalCount,
            startDate,
            endDate
        } = getState().explore.filter;
        const { analysisType, dates, currentIndex } = getState().explore.chart;

        const navPropKeys = Object.keys(navProps);

        mainSegments = mainSegments.map((s: any) => {
            const navPropKey = navPropKeys.find(
                npk =>
                    npk.toLowerCase() === s.name.replace(/ /g, '').toLowerCase()
            );

            if (navPropKey) {
                return {
                    name: s.name,
                    values: [navProps[navPropKey]]
                };
            }

            return s;
        });

        const segments = mainSegments.slice();

        let breakdownSegment;
        if (!filterSegment) {
            filterSegment = {
                name: employeeAttribute,
                values: [employeeAttributeValue]
            };
            segments.push(filterSegment);
        } else {
            breakdownSegment = {
                name: employeeAttribute,
                values: [employeeAttributeValue]
            };
            segments.push(filterSegment);
            segments.push(breakdownSegment);
        }

        const mixPanelObj = getMixPanelObjectForFilters(
            analysisName,
            segments,
            attributes
        );
        track(mixPanel.Events.ExploreSelectSubtableCell, mixPanelObj);

        const date = moment.utc(dates[currentIndex]).format('YYYY/MM/DD');

        const queryObject = {
            lifecycle,
            analysisType,
            mainSegments,
            filterSegment,
            breakdownSegment,
            date,
            interval,
            intervalCount,
            startDate,
            endDate
        };

        let url = '/explore?';
        const queryString = qs.stringify(queryObject, {
            encodeValuesOnly: true
        });
        url += queryString;
        dispatch(push(url));
        window.scrollTo(0, 0);
    };
}

export function toggleExpansion(employeeAttribute: string) {
    return {
        type: ActionTypes.ToggleExpansion,
        employeeAttribute
    };
}

export function setRowFilter(employeeAttribute: string, rowFilter: any) {
    return {
        type: ActionTypes.SetRowFilter,
        employeeAttribute,
        rowFilter
    };
}

export function setColumnFilter(employeeAttribute: string, columnFilter: any) {
    return {
        type: ActionTypes.SetColumnFilter,
        employeeAttribute,
        columnFilter
    };
}

export function clearFilter(employeeAttribute: string) {
    return {
        type: ActionTypes.ClearFilterInDetailSection,
        employeeAttribute
    };
}

export function setSortCriteria(employeeAttribute: string, columnName: any) {
    return {
        type: ActionTypes.SetSortCriteriaInDetailSection,
        employeeAttribute,
        columnName
    };
}

export function toggleLabel(employeeAttribute: string, label: string) {
    return {
        type: ActionTypes.ToggleLabelInDetailSection,
        employeeAttribute,
        label
    };
}

export function hoverLabel(employeeAttribute: string, label: string) {
    return {
        type: ActionTypes.HoverLabelInDetailSection,
        employeeAttribute,
        label
    };
}

export function setChartTypeInDetailSection(
    employeeAttribute: string,
    chartType: ChartTypes
) {
    return {
        type: ActionTypes.SetChartTypeInDetailSection,
        employeeAttribute,
        chartType
    };
}

export function unhoverLabel(employeeAttribute: string, label: string) {
    return {
        type: ActionTypes.UnhoverLabelInDetailSection,
        label,
        employeeAttribute
    };
}

import DropdownArrow from '../../../icons/DropdownArrow';
import Close from '../../../icons/Close';
import React, { useEffect, useRef } from 'react';
import RadioButton from '../../../common/RadioButton';
import { CSSTransition } from 'react-transition-group';
import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import { ActionTypes, Option } from './useSegmentPicker/types';
import Grid from '../../../common/Grid';
import Checkbox from '../../../common/Checkbox';

const SegmentPicker = ({
    state,
    dispatch,
    onActivate
}: {
    state: any;
    dispatch: any;
    onActivate: any;
}) => {
    return (
        <Grid
            base={2}
            style={{
                flex: 1
            }}
        >
            <Grid.Item>
                <Dropdown
                    onClick={() => {
                        if (!state.segmentDropdownOpen) onActivate();
                        dispatch({
                            type: ActionTypes.TOGGLE_SEGMENT_DROPDOWN
                        });
                    }}
                    isOpen={state.segmentDropdownOpen}
                    label={state.segment}
                    placeholder="Select Segment"
                    onMouseLeaveDropdown={() => {
                        dispatch({
                            type: ActionTypes.SET_SEGMENT_DROPDOWN_OPEN,
                            payload: false
                        });
                    }}
                >
                    <div className="filter-dropdown__list">
                        <ul>
                            {state.options?.map(
                                (option: Option, index: number) => (
                                    <li key={index}>
                                        <RadioButton
                                            onClick={() =>
                                                dispatch({
                                                    type: ActionTypes.SELECT_SEGMENT,
                                                    payload: option.name
                                                })
                                            }
                                            checked={
                                                state.segment === option.name
                                            }
                                        >
                                            {option.name}
                                        </RadioButton>
                                    </li>
                                )
                            )}
                        </ul>
                    </div>
                </Dropdown>
            </Grid.Item>
            <Grid.Item>
                <Dropdown
                    onClick={() => {
                        if (!state.valuesDropdownOpen) onActivate();
                        dispatch({
                            type: ActionTypes.TOGGLE_VALUES_DROPDOWN
                        });
                    }}
                    disabled={state.status === 'idle'}
                    isOpen={state.valuesDropdownOpen}
                    label={
                        state.values.length
                            ? state.allValuesSelected
                                ? 'All'
                                : state.values.length > 2
                                ? `(${state.values.length}) Values`
                                : state.values.join(', ')
                            : ''
                    }
                    placeholder="Select Values"
                    onMouseLeaveDropdown={() => {
                        dispatch({
                            type: ActionTypes.SET_VALUES_DROPDOWN_OPEN,
                            payload: false
                        });
                    }}
                >
                    <div className="filter-dropdown__list">
                        <ul>
                            {state.segment !== 'Hire Date' && (
                                <React.Fragment>
                                    <li key={'All'}>
                                        <Checkbox
                                            onClick={() =>
                                                dispatch({
                                                    type: ActionTypes.TOGGLE_ALL_VALUES
                                                })
                                            }
                                            checked={state.allValuesSelected}
                                        >
                                            All
                                        </Checkbox>
                                    </li>
                                    <div className="filter-dropdown__divider"></div>
                                </React.Fragment>
                            )}
                            {state.options
                                .find((o: any) => o.name === state.segment)
                                ?.values?.map(
                                    (value: string, index: number) => (
                                        <li key={index}>
                                            {state.segment === 'Hire Date' ? (
                                                <RadioButton
                                                    onClick={() =>
                                                        dispatch({
                                                            type: ActionTypes.SELECT_VALUE,
                                                            payload: value
                                                        })
                                                    }
                                                    checked={state.values.includes(
                                                        value
                                                    )}
                                                >
                                                    {value}
                                                </RadioButton>
                                            ) : (
                                                <Checkbox
                                                    onClick={() =>
                                                        dispatch({
                                                            type: ActionTypes.TOGGLE_VALUE,
                                                            payload: value
                                                        })
                                                    }
                                                    checked={state.values.includes(
                                                        value
                                                    )}
                                                >
                                                    {value}
                                                </Checkbox>
                                            )}
                                        </li>
                                    )
                                )}
                        </ul>
                    </div>
                </Dropdown>
            </Grid.Item>
        </Grid>
    );
};

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
    onClick: (option: any) => void;
    placeholder?: string;
    buttonLarge?: boolean;
    allowCancel?: boolean;
    disabled?: boolean;
    isOpen?: boolean;
    label?: string;
    children?: React.ReactNode;
    onClearSelection?: () => void;
    onMouseLeaveDropdown?: () => void;
    limitHeight?: number;
    alignDropdownRight?: boolean;
}

const Dropdown = ({
    placeholder,
    allowCancel,
    disabled,
    isOpen,
    label,
    buttonLarge,
    children,
    limitHeight,
    alignDropdownRight,
    onClick,
    onClearSelection,
    onMouseLeaveDropdown,
    ...rest
}: DropdownProps) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: any) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                onMouseLeaveDropdown?.();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleClickOutside, false);
        return () => {
            document.removeEventListener('keydown', handleClickOutside, false);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="filter-dropdown" {...rest}>
            <Button
                type="button"
                componentType={ButtonTypes.type.SECONDARY}
                large={buttonLarge}
                onClick={onClick}
                activated={isOpen}
                disabled={disabled}
                selected={!!label}
            >
                <span className="long-text">{label || placeholder}</span>
                <div style={{ width: 4 }} />
                {allowCancel && label ? (
                    <Close
                        width={12}
                        height={12}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            if (!disabled) {
                                onClearSelection?.();
                            }
                        }}
                    />
                ) : (
                    <DropdownArrow width={18} height={18} />
                )}
            </Button>
            <CSSTransition
                in={isOpen}
                timeout={300}
                mountOnEnter
                unmountOnExit
                classNames="fade-scale"
            >
                <div
                    className="filter-dropdown__menu"
                    data-test={`dropdown-menu-${placeholder}`}
                    onMouseLeave={() => {}}
                    ref={dropdownRef}
                    style={{
                        maxHeight: limitHeight ?? '50vh',
                        overflowY: 'auto',
                        ...(alignDropdownRight && {
                            right: '-3.2rem'
                        })
                    }}
                >
                    {children}
                </div>
            </CSSTransition>
        </div>
    );
};

export default SegmentPicker;

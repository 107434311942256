import React, { useEffect } from 'react';
import TextField from '../../../common/TextField';
import Dropdown from '../../../common/Dropdown';
import {
    setTitle,
    setGlobalCategoryId,
    setFieldName,
    setQuestionType,
    setInputType,
    addAnswer,
    updateValue,
    updateScore,
    updateDesc,
    deleteAnswer,
    moveUp,
    moveDown,
    setDescription,
    setEnterpriseId,
    setValues,
    clearData
} from './editReducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import qs from 'qs';
import { RouteComponentProps } from 'react-router';
import { fetchQuestions } from './reducer';
import { fetchCategories } from '../category/reducer';
import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import DropdownWrapper from '../../../common/DropdownWrapper';
import ScopeMenu from '../common/ScopeMenu';
import { getEnterprises } from '../../enterprise/actions';
import { questionTypeOptions, inputTypeOptions } from './editReducer';
import AnswerEdit from './AnswerEdit';
import { QuestionValue } from './types';

export default function QuestionEdit({ location }: RouteComponentProps) {
    const dispatch = useAppDispatch();
    const { data, loaded } = useAppSelector(
        state => state.datastudio.survey.questions
    );
    const { data: categoryData, loaded: categoryLoaded } = useAppSelector(
        state => state.datastudio.survey.categories
    );
    const {
        title,
        description,
        globalCategoryId,
        fieldName,
        questionType,
        inputType,
        values,
        enterpriseId
    } = useAppSelector(
        state => state.datastudio.survey.questionEdit.questionToEdit
    );
    const { data: enterprises, loaded: enterprisesLoaded } = useAppSelector(
        state => state.datastudio.enterprises
    );

    const { globalQuestionId } = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const enterpriseName =
        enterprises?.find(e => e.enterpriseId === enterpriseId)
            ?.enterpriseName ?? 'Global';

    useEffect(() => {
        if (!loaded) {
            dispatch(fetchQuestions());
        }
        if (!categoryLoaded) {
            dispatch(fetchCategories());
        }
        if (!enterprisesLoaded) {
            dispatch(getEnterprises());
        }

        dispatch(clearData());
        if (globalQuestionId) {
            const question = data.find(
                d => d.globalQuestionId === globalQuestionId
            );
            if (question) {
                const answerDescs = JSON.parse(
                    question.meta?.uiElements.find(u => u.key === 'answerDescs')
                        ?.value ?? '[]'
                );
                dispatch(setTitle(question.title));
                dispatch(setGlobalCategoryId(question.globalCategoryId));
                dispatch(setFieldName(question.fieldName));
                dispatch(
                    setValues(
                        question.values.map(v => {
                            const description = answerDescs.find(
                                (a: any) => a.value === v.value
                            )?.desc;
                            return {
                                value: v.value,
                                score: v.score ?? 0,
                                description
                            } as QuestionValue;
                        })
                    )
                );
                dispatch(setEnterpriseId(question.enterpriseId));
                const description =
                    question.meta?.uiElements.find(u => u.key === 'description')
                        ?.value ?? '';
                dispatch(setDescription(description));
                const questionType =
                    question.meta?.uiElements.find(
                        u => u.key === 'questionType'
                    )?.value ?? 'Single-select';
                dispatch(setQuestionType(questionType));
                const inputType =
                    question.meta?.uiElements.find(u => u.key === 'inputType')
                        ?.value ?? 'Radio';
                dispatch(setInputType(inputType));
            }
        }
    }, [globalQuestionId, data]);

    function handleNewValue() {
        dispatch(addAnswer());
    }

    const categoryOptions = categoryData.map(c => ({
        label: c.title,
        value: c.globalCategoryId
    }));
    const selectedCategory = categoryOptions.find(
        c => c.value === globalCategoryId
    );

    return (
        <div className="survey-content">
            <div className="question-edit">
                <div className="ds-form-group">
                    <div className="ds-label">Question</div>
                    <div className="ds-input">
                        <TextField
                            value={title}
                            onChange={e => dispatch(setTitle(e.target.value))}
                            showClose
                            onClose={() => dispatch(setTitle(''))}
                        />
                    </div>
                </div>
                <div className="ds-form-group">
                    <div className="ds-label">Description (optional)</div>
                    <div className="ds-input">
                        <TextField
                            value={description}
                            onChange={e =>
                                dispatch(setDescription(e.target.value))
                            }
                            showClose
                            onClose={() => dispatch(setDescription(''))}
                        />
                    </div>
                </div>
                <div className="ds-flex ds-flex--question">
                    <div className="ds-form-group">
                        <div className="ds-label">Category</div>
                        <div className="ds-input">
                            <Dropdown
                                placeholder="Select"
                                options={categoryOptions}
                                selectedOption={selectedCategory}
                                onClick={(value: any) =>
                                    dispatch(setGlobalCategoryId(value))
                                }
                                disabled={!!globalQuestionId}
                            />
                        </div>
                    </div>
                    <div className="ds-form-group">
                        <div className="ds-label">Field</div>
                        <div className="ds-input">
                            <TextField
                                value={fieldName}
                                onChange={e =>
                                    dispatch(setFieldName(e.target.value))
                                }
                                showClose
                                onClose={() => dispatch(setFieldName(''))}
                            />
                        </div>
                    </div>
                    <div className="ds-form-group">
                        <div className="ds-label">Question type</div>
                        <div className="ds-input">
                            <Dropdown
                                placeholder="Select"
                                options={questionTypeOptions}
                                selectedOption={questionType}
                                onClick={(value: any) => {
                                    dispatch(setQuestionType(value));
                                    dispatch(
                                        setInputType(inputTypeOptions[value][0])
                                    );
                                }}
                            />
                        </div>
                    </div>
                    <div className="ds-form-group">
                        <div className="ds-label">Input type</div>
                        <div className="ds-input">
                            <Dropdown
                                placeholder="Select"
                                options={inputTypeOptions[questionType]}
                                selectedOption={inputType}
                                onClick={(value: any) =>
                                    dispatch(setInputType(value))
                                }
                            />
                        </div>
                    </div>
                </div>

                <table className="question-edit__values">
                    <tr>
                        <td></td>
                        <td>
                            <div className="ds-label">Values</div>
                        </td>
                        <td>
                            <div className="ds-label">Score</div>
                        </td>
                        <td></td>
                        <td></td>
                    </tr>

                    {values.map((v, i) => (
                        <AnswerEdit
                            key={`${values.length}_${i}`}
                            answer={v}
                            index={i}
                            answerCount={values.length}
                            moveUp={() => dispatch(moveUp(i))}
                            moveDown={() => dispatch(moveDown(i))}
                            updateValue={value =>
                                dispatch(
                                    updateValue({
                                        index: i,
                                        value
                                    })
                                )
                            }
                            updateScore={score =>
                                dispatch(
                                    updateScore({
                                        index: i,
                                        score
                                    })
                                )
                            }
                            updateDesc={desc =>
                                dispatch(
                                    updateDesc({
                                        index: i,
                                        desc
                                    })
                                )
                            }
                            deleteAnswer={() => dispatch(deleteAnswer(i))}
                        />
                    ))}
                </table>

                <div className="ds-input">
                    <Button
                        componentType={ButtonTypes.type.SECONDARY}
                        style={{ width: 60 }}
                        onClick={handleNewValue}
                    >
                        +
                    </Button>
                </div>

                <div className="ds-form-group">
                    <div className="ds-label">Scope</div>
                    <div className="ds-input ds-input--scope">
                        <DropdownWrapper
                            placeholder="Select"
                            selectedText={enterpriseName}
                        >
                            <ScopeMenu
                                enterpriseId={enterpriseId}
                                onDone={enterpriseId =>
                                    dispatch(setEnterpriseId(enterpriseId))
                                }
                            />
                        </DropdownWrapper>
                    </div>
                </div>
            </div>
        </div>
    );
}

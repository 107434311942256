import { SVGProps } from 'react';
import InfoCard from '../../../../common/InfoCard';
import RadioButton from '../../../../common/RadioButton';
import Stack from '../../../../common/Stack';
import RadioButtonTypes from '../../../../constants/RadioButtonTypes';

const ChoiceItem = ({
    title,
    description,
    onClick,
    selected,
    index,
    hoverIndex,
    setHoverIndex,
    largeTitle = false,
    readOnly,
    selectionExists,
    iconConfig
}: {
    title: string;
    description: string;
    onClick: () => void;
    selected: boolean;
    defaultState?: boolean;
    index: number;
    hoverIndex: number;
    setHoverIndex: any;
    largeTitle?: boolean;
    readOnly?: boolean;
    selectionExists?: boolean;
    iconConfig?: {
        icon: (props: SVGProps<SVGSVGElement>) => JSX.Element;
        color: string;
        backgroundColor: string;
        width?: number;
        height?: number;
    };
}) => {
    const Icon = iconConfig?.icon;

    if (Icon) {
        return (
            <InfoCard
                onClick={onClick}
                state={
                    selectionExists
                        ? 'selectionExists'
                        : hoverIndex !== -1
                        ? 'hoverExists'
                        : 'default'
                }
                selected={selected}
                onMouseEnter={() => setHoverIndex(index)}
                onMouseLeave={() => setHoverIndex(-1)}
                isHovering={hoverIndex === index}
                readOnly={readOnly}
            >
                <Stack flexDirection="row" gap="1.6rem" alignItems="center">
                    <div
                        style={{
                            backgroundColor:
                                selectionExists && !selected
                                    ? 'var(--color-ui-10)'
                                    : iconConfig?.backgroundColor,
                            minWidth: 86,
                            minHeight: 86,
                            flexGrow: 0,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}
                    >
                        <Icon
                            width={iconConfig?.width}
                            height={iconConfig?.height}
                            fill={
                                selectionExists && !selected
                                    ? 'var(--color-ui-30)'
                                    : iconConfig?.color
                            }
                        />
                    </div>
                    <Stack gap=".8rem">
                        <span className={largeTitle ? 'font-subtitle' : ''}>
                            {title}
                        </span>
                        <span
                            style={{
                                color: 'var(--color-ui-50)'
                            }}
                        >
                            {description}
                        </span>
                    </Stack>
                </Stack>
            </InfoCard>
        );
    }

    return (
        <InfoCard
            onClick={onClick}
            state={
                selectionExists
                    ? 'selectionExists'
                    : hoverIndex !== -1
                    ? 'hoverExists'
                    : 'default'
            }
            selected={selected}
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(-1)}
            isHovering={hoverIndex === index}
            readOnly={readOnly}
        >
            <Stack
                flexDirection="row"
                gap="1.6rem"
                style={{
                    padding: '1.6rem'
                }}
            >
                <div
                    style={{
                        paddingTop: '.3rem'
                    }}
                >
                    <RadioButton
                        checked={selected}
                        componentType={RadioButtonTypes.type.LARGE}
                    />
                </div>
                <Stack gap=".8rem">
                    <span className={largeTitle ? 'font-subtitle' : ''}>
                        {title}
                    </span>
                    <span
                        style={{
                            color: 'var(--color-ui-50)'
                        }}
                    >
                        {description}
                    </span>
                </Stack>
            </Stack>
        </InfoCard>
    );
};

export default ChoiceItem;

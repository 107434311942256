import { SVGProps } from 'react';

interface AnonymousPersonProps extends SVGProps<SVGSVGElement> {
    secondaryFill?: string;
}

const SvgComponent = (props: AnonymousPersonProps) => {
    const fill = props.fill || '#C050F5';
    const secondaryFill = props.secondaryFill || '#F9D2FF';
    const width = props.width || '20';
    const height = props.height || '20';

    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 88 88"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <path
                d="M36.6667 51.3333C33.6111 51.3333 31.0139 50.2638 28.875 48.125C26.7361 45.9861 25.6667 43.3888 25.6667 40.3333C25.6667 38.9888 25.8806 37.7208 26.3084 36.5291C26.7361 35.3375 27.3778 34.2527 28.2334 33.275C27.9889 32.6638 27.8056 32.0222 27.6834 31.35C27.5611 30.6777 27.5 30.0055 27.5 29.3333C27.5 27.0111 28.1264 24.9486 29.3792 23.1458C30.632 21.343 32.2667 20.0138 34.2834 19.1583C35.5056 17.7527 36.9417 16.6527 38.5917 15.8583C40.2417 15.0638 42.0445 14.6666 44 14.6666C45.9556 14.6666 47.7584 15.0638 49.4084 15.8583C51.0584 16.6527 52.4945 17.7527 53.7167 19.1583C55.7334 20.0138 57.3681 21.343 58.6209 23.1458C59.8736 24.9486 60.5 27.0111 60.5 29.3333C60.5 30.0055 60.4389 30.6777 60.3167 31.35C60.1945 32.0222 60.0111 32.6638 59.7667 33.275C60.6222 34.2527 61.2639 35.3375 61.6917 36.5291C62.1195 37.7208 62.3334 38.9888 62.3334 40.3333C62.3334 43.3888 61.2639 45.9861 59.125 48.125C56.9861 50.2638 54.3889 51.3333 51.3334 51.3333H36.6667ZM14.6667 80.6666V72.05C14.6667 69.7277 15.2472 67.7416 16.4084 66.0916C17.5695 64.4416 19.0667 63.1889 20.9 62.3333C24.9945 60.5 28.9209 59.125 32.6792 58.2083C36.4375 57.2916 40.2111 56.8333 44 56.8333C47.7889 56.8333 51.5472 57.3069 55.275 58.2541C59.0028 59.2013 62.9139 60.5611 67.0084 62.3333C68.9028 63.1889 70.4306 64.4416 71.5917 66.0916C72.7528 67.7416 73.3334 69.7277 73.3334 72.05V80.6666H14.6667Z"
                fill={fill}
            />
            <path
                d="M38.648 31.24C38.648 31.176 38.672 31.008 38.72 30.736C38.768 30.448 38.856 30.112 38.984 29.728C39.128 29.328 39.328 28.912 39.584 28.48C39.84 28.032 40.176 27.624 40.592 27.256C41.024 26.888 41.56 26.584 42.2 26.344C42.84 26.088 43.6 25.96 44.48 25.96C45.296 25.96 46.032 26.088 46.688 26.344C47.36 26.584 47.928 26.92 48.392 27.352C48.872 27.768 49.24 28.248 49.496 28.792C49.752 29.336 49.88 29.912 49.88 30.52C49.88 31.32 49.76 31.976 49.52 32.488C49.296 33 49.008 33.44 48.656 33.808C48.32 34.16 47.944 34.472 47.528 34.744C47.128 35 46.752 35.28 46.4 35.584C46.064 35.872 45.776 36.216 45.536 36.616C45.312 37 45.2 37.504 45.2 38.128V38.56H42.92V37.48C42.92 36.872 43.024 36.36 43.232 35.944C43.456 35.512 43.728 35.128 44.048 34.792C44.368 34.44 44.712 34.128 45.08 33.856C45.448 33.568 45.792 33.28 46.112 32.992C46.432 32.688 46.696 32.36 46.904 32.008C47.128 31.656 47.24 31.24 47.24 30.76C47.24 30.424 47.176 30.112 47.048 29.824C46.92 29.52 46.736 29.256 46.496 29.032C46.256 28.808 45.96 28.632 45.608 28.504C45.272 28.36 44.896 28.288 44.48 28.288C43.712 28.288 43.104 28.44 42.656 28.744C42.208 29.048 41.864 29.384 41.624 29.752C41.384 30.12 41.224 30.464 41.144 30.784C41.08 31.088 41.048 31.24 41.048 31.24H38.648ZM42.68 40.168H45.512V43H42.68V40.168Z"
                fill={secondaryFill}
            />
        </svg>
    );
};
export default SvgComponent;

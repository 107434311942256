import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../common/Checkbox';
import Dropdown from '../../common/Dropdown';

const COLUMN_OPTIONS = [
    {
        value: 'None',
        label: 'None'
    },
    {
        value: 'Percentage',
        label: 'Percentage Change'
    },
    {
        value: 'Value',
        label: 'Value Change'
    }
];

export default function TableOptions({
    showSinglePeriod,
    setShowSinglePeriod,
    changeOption,
    setChangeOption
}) {
    const selectedOption = changeOption
        ? COLUMN_OPTIONS.find(co => co.value === changeOption)
        : COLUMN_OPTIONS[0];

    return (
        <Fragment>
            <div className="editor__option">
                <Checkbox
                    checked={showSinglePeriod}
                    onClick={setShowSinglePeriod}
                    style={{
                        paddingLeft: 0
                    }}
                >
                    Show latest period only
                </Checkbox>
            </div>
            {!showSinglePeriod && (
                <div className="editor__option">
                    <div className="editor__option__label">
                        Show period over period change
                    </div>
                    <div className="editor__option__value">
                        <Dropdown
                            onClick={setChangeOption}
                            selectedOption={selectedOption}
                            options={COLUMN_OPTIONS}
                        />
                    </div>
                </div>
            )}
        </Fragment>
    );
}

TableOptions.propTypes = {
    showSinglePeriod: PropTypes.bool,
    setShowSinglePeriod: PropTypes.func,
    changeOption: PropTypes.string,
    setChangeOption: PropTypes.func
};

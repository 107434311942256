import ChartTypes from '../constants/ChartTypes';
import GridConstants from '../constants/GridConstants';
import BarChart from '../editor/chart/BarChart';
import BenchmarkChart from '../editor/chart/BenchmarkChart';
import LineChart from '../editor/chart/LineChart';
import PieChart from '../editor/chart/PieChart';
import SingleTimeChart from '../editor/chart/SingleTimeChart';
import StackedBarChart from '../editor/chart/StackedBarChart';
import Subtable from '../explore/subtable/Subtable';
import getDynamicDataProp from '../lib/getDynamicDataProp';
import hasHiddenEmployeeCount from '../lib/hasHiddenEmployeeCount';
import { useAppSelector } from '../store/hooks';
import FunnelChart from './FunnelChart';
import GenericTable from './GenericTable';
import StatsChart from './StatsChart';

const ChartPicker = ({
    width,
    height,
    showSubtable,
    data,
    chartType,
    columnProps,
    dates,
    interval,
    lifecycle,
    setActiveLabel,
    clearActiveLabel,
    handleToggleLabel,
    analysisType,
    currentIndex,
    selectCurrentTime,
    hasInvalidValue,
    columnGap,
    benchmark,
    containerHeight,
    benchmarkRowHeight,
    onHover,
    onHoverEnd,
    onToggle,
    status,
    hoveredItem,
    selection,
    setInnerChartOffsets
}: any) => {
    const { yAxisType, indexType } = useAppSelector(
        state => state.explore.chart
    );

    const {
        dynamicPermissions,
        userInfo: { lightMode }
    } = useAppSelector(state => state.auth);
    const employeeCountHidden = hasHiddenEmployeeCount(dynamicPermissions);
    if (height === 0) {
        return <div className="explore-chart__empty"></div>;
    }

    if (showSubtable) {
        return (
            <Subtable
                onHover={onHover}
                onHoverEnd={onHoverEnd}
                hoveredItem={hoveredItem}
                selection={selection}
                status={status}
                onToggle={onToggle}
            />
        );
    }

    if (data.length === 0) {
        return (
            <div className="explore-chart__empty" style={{ width, height }}>
                <div className="explore-chart__empty__text">
                    There is no data for the selected filter
                </div>
            </div>
        );
    }

    const dataProp = getDynamicDataProp(yAxisType, indexType, columnProps);

    if (dates.length === 1 && chartType === ChartTypes.Line) {
        return (
            <SingleTimeChart
                reportId="explore-chart"
                width={width}
                height={height}
                data={data}
                date={dates[0]}
                interval={interval}
                lifecycle={lifecycle}
                setActiveLabel={setActiveLabel}
                clearActiveLabel={clearActiveLabel}
                toggleLabel={handleToggleLabel}
                analysisType={analysisType}
                dataProp={dataProp}
                yAxisType={yAxisType}
                indexType={indexType}
                employeeCountHidden={employeeCountHidden}
                columnProps={columnProps}
            />
        );
    }

    if (chartType === ChartTypes.Line) {
        return (
            <LineChart
                reportId="explore-chart"
                width={width}
                height={height}
                data={data}
                dates={dates}
                interval={interval}
                lifecycle={lifecycle}
                analysisType={analysisType}
                currentIndex={currentIndex}
                selectCurrentTime={selectCurrentTime}
                setActiveLabel={setActiveLabel}
                clearActiveLabel={clearActiveLabel}
                toggleLabel={handleToggleLabel}
                hasInvalidValue={hasInvalidValue}
                dataProp={dataProp}
                status={status}
                hoveredItem={hoveredItem}
                selection={selection}
                setInnerChartOffsets={setInnerChartOffsets}
                yAxisType={yAxisType}
                indexType={indexType}
                employeeCountHidden={employeeCountHidden}
                columnProps={columnProps}
            />
        );
    } else if (chartType === ChartTypes.Bar) {
        return (
            <BarChart
                reportId="explore-chart"
                width={width}
                height={height}
                data={data}
                dates={dates}
                interval={interval}
                lifecycle={lifecycle}
                analysisType={analysisType}
                currentIndex={currentIndex}
                selectCurrentTime={selectCurrentTime}
                setActiveLabel={setActiveLabel}
                clearActiveLabel={clearActiveLabel}
                toggleLabel={handleToggleLabel}
                dateSelection
                hasInvalidValue={hasInvalidValue}
                dataProp={dataProp}
                yAxisType={yAxisType}
                indexType={indexType}
                employeeCountHidden={employeeCountHidden}
                columnProps={columnProps}
                lightMode={lightMode}
            />
        );
    } else if (chartType === ChartTypes.Pie && !benchmark) {
        const colWidth =
            (width - (GridConstants.ColumnCount - 1) * columnGap) /
            GridConstants.ColumnCount;
        return (
            <PieChart
                reportId="explore-chart"
                width={width}
                height={height}
                data={data}
                dates={dates}
                interval={interval}
                lifecycle={lifecycle}
                analysisType={analysisType}
                currentIndex={currentIndex}
                length={GridConstants.ColumnCount}
                colWidth={colWidth}
                selectCurrentTime={selectCurrentTime}
                setActiveLabel={setActiveLabel}
                clearActiveLabel={clearActiveLabel}
                toggleLabel={handleToggleLabel}
                dataProp={dataProp}
                yAxisType={yAxisType}
                indexType={indexType}
                employeeCountHidden={employeeCountHidden}
                columnProps={columnProps}
            />
        );
    } else if (chartType === ChartTypes.StackedBar && !benchmark) {
        const colWidth =
            (width - (GridConstants.ColumnCount - 1) * columnGap) /
            GridConstants.ColumnCount;
        return (
            <StackedBarChart
                reportId="explore-chart"
                width={width}
                height={height}
                data={data}
                dates={dates}
                interval={interval}
                lifecycle={lifecycle}
                analysisType={analysisType}
                currentIndex={currentIndex}
                length={GridConstants.ColumnCount}
                colWidth={colWidth}
                selectCurrentTime={selectCurrentTime}
                setActiveLabel={setActiveLabel}
                clearActiveLabel={clearActiveLabel}
                toggleLabel={handleToggleLabel}
                dataProp={dataProp}
                yAxisType={yAxisType}
                dateSelection
                employeeCountHidden={employeeCountHidden}
                columnProps={columnProps}
            />
        );
    } else if (chartType === ChartTypes.Stats) {
        return <StatsChart data={data} />;
    } else if (chartType === ChartTypes.ArithmeticTable) {
        return (
            <GenericTable
                data={data}
                mode="explore"
                onHover={onHover}
                onHoverEnd={onHoverEnd}
                onToggle={onToggle}
                status={status}
                hoveredItem={hoveredItem}
                selection={selection}
            />
        );
    } else if (chartType === ChartTypes.Funnel) {
        return (
            <FunnelChart
                data={data}
                mode="explore"
                onHover={onHover}
                onHoverEnd={onHoverEnd}
                onToggle={onToggle}
                status={status}
                hoveredItem={hoveredItem}
                selection={selection}
            />
        );
    } else if (benchmark) {
        const colWidth =
            (width - (GridConstants.ColumnCount - 1) * columnGap) /
            GridConstants.ColumnCount;
        return (
            <BenchmarkChart
                reportId="explore-chart"
                width={width}
                height={height}
                containerHeight={containerHeight}
                data={data}
                dates={dates}
                interval={interval}
                lifecycle={lifecycle}
                analysisType={analysisType}
                currentIndex={currentIndex}
                length={GridConstants.ColumnCount}
                colWidth={colWidth}
                selectCurrentTime={selectCurrentTime}
                setActiveLabel={setActiveLabel}
                clearActiveLabel={clearActiveLabel}
                toggleLabel={handleToggleLabel}
                rowHeight={benchmarkRowHeight}
                benchmarkData={benchmark}
                showPie={!benchmark.hidePie}
                dataProp={dataProp}
                columnProps={columnProps}
            />
        );
    }

    return null;
};

export default ChartPicker;

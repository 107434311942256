import { useState } from 'react';
import { MdSearch } from 'react-icons/md';

import RadioButton from '../../common/RadioButton';
import Close from '../../icons/Close';
import { AttributeValue } from '../../types';

interface DashboardFilterValuesProps {
    values: AttributeValue[];
    onChange: (value: string) => void;
}

export default function DashboardFilterValues({
    values,
    onChange
}: DashboardFilterValuesProps) {
    const [search, setSearch] = useState('');
    let listHeight = window.screen.availHeight * 0.75 - 160;
    const maxRowCount = Math.floor(listHeight / 28);
    listHeight = maxRowCount * 28;
    const showSearch = values.length + 1 > maxRowCount;
    let filteredValues = values;
    if (search) {
        filteredValues = values.filter(v =>
            v.text.toLowerCase().includes(search.toLowerCase())
        );
    }

    return (
        <div className="filter-dropdown__list">
            {showSearch && (
                <div className="filter-dropdown__search">
                    <MdSearch size={24} color="rgba(242, 242, 242, 0.5)" />
                    <input
                        className="large-text"
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                    />
                    {search && (
                        <Close
                            width={24}
                            className="btn-icon"
                            onClick={() => setSearch('')}
                        />
                    )}
                </div>
            )}
            {search && filteredValues.length === 0 && (
                <div className="filter-dropdown__empty-message">
                    There are no segments for the search
                </div>
            )}
            <ul style={{ maxHeight: listHeight }}>
                {filteredValues.map(v => (
                    <li key={v.value}>
                        <RadioButton
                            checked={v.checked}
                            onClick={() => onChange(v.value)}
                        >
                            {v.text}
                        </RadioButton>
                    </li>
                ))}
            </ul>
        </div>
    );
}

import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import FilterDropdown from './FilterDropdown';
import SegmentList, {
    getSegmentCount,
    getSegmentSelection,
    isSegmentChecked
} from './SegmentList';
import SegmentValues from './SegmentValues';

export default function SegmentDropdown({
    attributes,
    segmentNames,
    placeholder,
    update,
    selection = [],
    disabled,
    useRadioOnSegment,
    useRadioOnValue,
    isSecondary = true,
    shouldOpen,
    setShouldOpen
}) {
    const [isOpen, setIsOpen] = useState(false);
    const [segments, setSegments] = useState(cloneDeep(attributes));
    const [search, setSearch] = useState('');

    let segmentValues = [];
    const activeSegment = segments.find(s => s.active);
    if (activeSegment) {
        segmentValues = activeSegment.attributeValues;
    }

    const parts = [];
    segments.forEach(s => {
        if (isSegmentChecked(s)) {
            const count = getSegmentCount(s);
            const text = count > 1 ? count.toString() : getSegmentSelection(s);
            const part = `${s.attributeName} (${text})`;
            if (count > 0) {
                parts.push(part);
            }
        }
    });
    const label = parts.join(', ');

    useEffect(() => {
        if (shouldOpen) {
            setIsOpen(true);
            // set it back to false immediately
            setShouldOpen(false);
        }
    }, [shouldOpen]);

    useEffect(() => {
        setSegments(segments => {
            return produce(segments, segments => {
                segments.forEach(s => {
                    s.checked = false;
                    s.attributeValues.forEach(av => {
                        av.checked = false;
                    });
                    if (segmentNames) {
                        const segmentName = segmentNames.find(
                            sn => sn.name === s.attributeName.replace(/ /g, '')
                        );

                        s.removed = !segmentName;
                        s.disabled = segmentName && segmentName.disabled;
                        if (s.disabled || s.removed) {
                            s.active = false;
                        }
                    }
                });
                selection.forEach(sel => {
                    const segment = segments.find(
                        s => s.attributeName === sel.name
                    );
                    if (segment) {
                        segment.disabled = false;
                        sel.values.forEach(v => {
                            const value = segment.attributeValues.find(
                                av => av.value === v
                            );
                            if (value) {
                                value.checked = true;
                                segment.checked = true;
                            }
                        });
                    }
                });
                if (disabled) {
                    const segment = segments.find(s => s.active);
                    if (segment) {
                        segment.active = false;
                    }
                }
            });
        });
    }, [selection, segmentNames]);

    function updateSelection(segments) {
        const fields = [];
        segments.forEach(s => {
            const values = s.attributeValues
                .filter(av => av.checked)
                .map(av => av.value);
            if (values.length > 0) {
                fields.push({
                    name: s.attributeName,
                    values
                });
            }
        });
        update(fields);
    }

    function handleSegmentChange(segmentName, boxClicked) {
        const newSegments = produce(segments, segments => {
            const activeSegment = segments.find(s => s.active);
            if (activeSegment) {
                activeSegment.active = false;
            }
            if (useRadioOnSegment && boxClicked) {
                const checkedSegment = segments.find(s => s.checked);
                if (
                    checkedSegment &&
                    checkedSegment.attributeName !== segmentName
                ) {
                    checkedSegment.checked = false;
                    if (useRadioOnValue) {
                        const attributeValue =
                            checkedSegment.attributeValues.find(
                                av => av.checked
                            );
                        if (attributeValue) {
                            attributeValue.checked = false;
                        }
                    } else {
                        checkedSegment.attributeValues.forEach(av => {
                            av.checked = false;
                        });
                    }
                }
            }
            const segment = segments.find(s => s.attributeName === segmentName);
            segment.active = true;
            if (boxClicked) {
                segment.checked = !segment.checked;
                if (useRadioOnValue) {
                    if (segment.checked) {
                        segment.attributeValues[0].checked = true;
                    } else {
                        const attributeValue = segment.attributeValues.find(
                            av => av.checked
                        );
                        attributeValue.checked = false;
                    }
                } else {
                    segment.attributeValues.forEach(av => {
                        av.checked = segment.checked;
                    });
                }
            }
        });
        setSegments(newSegments);
        if (boxClicked) {
            updateSelection(newSegments);
        }
    }

    function handleValueChange(value) {
        const newSegments = produce(segments, segments => {
            const segment = segments.find(s => s.active);
            if (
                useRadioOnSegment &&
                useRadioOnValue &&
                selection.length === 1
            ) {
                const prevSegment = segments.find(
                    s => s.attributeName === selection[0].name
                );
                const checkedValue = prevSegment.attributeValues.find(
                    av => av.checked
                );
                if (prevSegment !== segment || checkedValue.value !== value) {
                    checkedValue.checked = false;
                }
            } else if (
                !useRadioOnSegment &&
                useRadioOnValue &&
                selection.length > 0
            ) {
                // uncheck all values from this segment only!
                segment.attributeValues.forEach(v => {
                    v.checked = false;
                });
            }
            if (segment) {
                const foundValue = segment.attributeValues.find(
                    v => v.value === value
                );
                if (foundValue) {
                    foundValue.checked = !foundValue.checked;
                }
            }
        });
        setSegments(newSegments);
        updateSelection(newSegments);
    }

    function handleSelectAll(selectAll) {
        const newSegments = produce(segments, segments => {
            const segment = segments.find(s => s.active);
            if (segment) {
                let filteredValues = segment.attributeValues;
                if (search) {
                    filteredValues = segment.attributeValues.filter(av =>
                        av.text.toLowerCase().includes(search.toLowerCase())
                    );
                }
                filteredValues.forEach(v => {
                    v.checked = selectAll;
                });
            }
        });
        setSegments(newSegments);
        updateSelection(newSegments);
    }

    function handleClearSelection() {
        const newSegments = produce(segments, segments => {
            segments.forEach(s => {
                s.checked = false;
                s.attributeValues.forEach(av => {
                    av.checked = false;
                });
            });
        });
        setSegments(newSegments);
        updateSelection(newSegments);
    }

    return (
        <FilterDropdown
            label={label}
            placeholder={placeholder}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            disabled={disabled}
            isSecondary={isSecondary}
            allowCancel={true}
            onClearSelection={handleClearSelection}
        >
            <SegmentList
                useRadio={useRadioOnSegment}
                segments={segments}
                onChange={handleSegmentChange}
            />
            {activeSegment && (
                <SegmentValues
                    values={segmentValues}
                    onChange={handleValueChange}
                    useRadio={useRadioOnValue}
                    onSelectAll={handleSelectAll}
                    search={search}
                    onSearch={setSearch}
                />
            )}
        </FilterDropdown>
    );
}

SegmentDropdown.propTypes = {
    attributes: PropTypes.array,
    segmentNames: PropTypes.array,
    placeholder: PropTypes.string,
    update: PropTypes.func,
    selection: PropTypes.array,
    disabled: PropTypes.bool,
    useRadioOnSegment: PropTypes.bool,
    useRadioOnValue: PropTypes.bool,
    isSecondary: PropTypes.bool,
    shouldOpen: PropTypes.bool,
    setShouldOpen: PropTypes.func
};

SegmentDropdown.defaultProps = {
    selection: []
};

export type Option = {
    column: string;
    name: string;
    values?: string[];
};

export interface State {
    status: 'idle' | 'segmentSelected' | 'valuesSelected';
    segmentDropdownOpen: boolean;
    valuesDropdownOpen: boolean;
    segment: string;
    values: string[];
    allValuesSelected: boolean;
    options?: Option[];
}

export enum ActionTypes {
    'SELECT_SEGMENT' = 'SELECT_SEGMENT',
    'TOGGLE_SEGMENT_DROPDOWN' = 'TOGGLE_SEGMENT_DROPDOWN',
    'SET_SEGMENT_DROPDOWN_OPEN' = 'SET_SEGMENT_DROPDOWN_OPEN',
    'TOGGLE_ALL_VALUES' = 'SELECT_ALL_VALUES',
    'TOGGLE_VALUE' = 'TOGGLE_VALUE',
    'SELECT_VALUE' = 'SELECT_VALUE',
    'TOGGLE_VALUES_DROPDOWN' = 'TOGGLE_VALUES_DROPDOWN',
    'SET_VALUES_DROPDOWN_OPEN' = 'SET_VALUES_DROPDOWN_OPEN',
    'CLOSE_ALL' = 'CLOSE_ALL',
    'SET_STATE' = 'SET_STATE',
    'RESET' = 'RESET'
}

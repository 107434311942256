import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '../../store/store';
import axios from 'axios';
import Urls from '../../constants/Urls';

interface ActionState {
    hris: string[];
}

interface AccountState {
    loaded?: boolean;
    hris: string[];
}

export const fetchHRIS = createAsyncThunk<ActionState, void, AsyncThunkConfig>(
    'fetchHRIS',
    async () => {
        const response = await axios.post(`${Urls.OnboardingApi}hris`);
        return response.data as ActionState;
    }
);

export const accountSlice = createSlice({
    name: 'account',
    initialState: {} as AccountState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchHRIS.fulfilled, (state, action) => {
            state.hris = action.payload.hris;
            state.loaded = true;
        });
        builder.addCase(fetchHRIS.pending, (state, action) => {
            state.loaded = false;
        });
        builder.addCase(fetchHRIS.rejected, (state, action) => {
            state.loaded = true;
        });
    }
});

export default accountSlice.reducer;

import { ChangeEvent, useRef, useState } from 'react';
import TextField from '../../common/TextField';
import WithPermissions from '../../common/WithPermissions';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import TextFieldTypes from '../../constants/TextFieldTypes';
import { track } from '../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSearchText } from '../config/reducer';
import CreateUserModal from './CreateUserModal';
import UserList from './UserList';
import { addUsers } from './actions';

function Users() {
    const [showCreate, setShowCreate] = useState(false);
    const textFieldRef = useRef<HTMLInputElement>(null);
    const addUsersPending = useAppSelector(
        state => state.admin.users.addUsersPending
    );
    const sso = useAppSelector(state => state.admin.integrations.sso.data);
    const { searchText } = useAppSelector(state => state.admin.adminConfig);
    const dispatch = useAppDispatch();

    function handleCreate() {
        setShowCreate(true);
        track(MixPanel.Events.AdminClickCreateUser);
    }

    function handleAction(
        emails: string[],
        roleIds: string[],
        note: string,
        sendNotification: boolean
    ) {
        dispatch(addUsers(emails, roleIds, note, sendNotification))?.then(
            () => {
                track(MixPanel.Events.CreateUser);
                setShowCreate(false);
            }
        );
    }

    function handleSearchChange(e: ChangeEvent<HTMLInputElement>) {
        dispatch(setSearchText(e.target.value));
    }

    function handleClose() {
        setShowCreate(false);
    }

    return (
        <WithPermissions actions={[RbacActions['Users/View']]}>
            <div className="user-grid">
                <div className="user-grid__search">
                    <TextField
                        type={TextFieldTypes.type.DEFAULT}
                        isSearch={true}
                        value={searchText}
                        onChange={handleSearchChange}
                        placeholder="Search users"
                        ref={textFieldRef}
                    />
                </div>

                <div>
                    <UserList />
                </div>

                <WithPermissions actions={[RbacActions['Users/Edit']]}>
                    <CreateUserModal
                        addUsersPending={addUsersPending}
                        isOpen={showCreate}
                        onAction={handleAction}
                        onCancel={handleClose}
                        sso={sso}
                    />
                </WithPermissions>
            </div>
        </WithPermissions>
    );
}

export default Users;

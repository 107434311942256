import Tooltip from 'antd/lib/tooltip';
import { Fragment } from 'react';
import Divider from '../../common/Divider';
import Popover from '../../common/Popover';
import WithPermissions from '../../common/WithPermissions';
import RbacActions from '../../constants/RbacActions';
import { generateAiInsights } from '../../dashboard/aiInsights/actions';
import {
    selectReport,
    selectReports,
    setAiInsightsModelOpen,
    setRegenerateMode
} from '../../dashboard/aiInsights/reducer';
import MoreMenu from '../../dashboard/view/MoreMenu';
import Download from '../../icons/Download';
import Explore from '../../icons/Explore';
import FilterApplied from '../../icons/FilterApplied';
import FilterNotApplied from '../../icons/FilterNotApplied';
import HMoreVertical from '../../icons/HMoreVertical';
import AiButton from '../../common/ButtonV2';
import cn from '../../lib/cn';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Button from '../../common/Button';

interface ChartActionsProps {
    dashboardId: string;
    dashboardName: string;
    disableExploreAccess: boolean;
    eventIds: string[];
    eventsHidden: boolean;
    exportOpen: boolean;
    isHovered: boolean;
    isTextChart: boolean;
    isInsightsChart: boolean;
    moreOpen: boolean;
    reportId: string;
    role: string;
    title: string;
    handleExplore: () => void;
    renderExportMenu: () => void;
    setEventsHidden: (eventsHidden: boolean) => void;
    setExportOpen: (exportOpen: boolean) => void;
    setMoreOpen: (moreOpen: boolean) => void;
    textChartDispatch: () => void;
}

const ChartActions = ({
    dashboardId,
    dashboardName,
    disableExploreAccess,
    eventIds,
    eventsHidden,
    exportOpen,
    isTextChart,
    isInsightsChart,
    moreOpen,
    reportId,
    role,
    title,
    handleExplore,
    renderExportMenu,
    setEventsHidden,
    setExportOpen,
    setMoreOpen,
    textChartDispatch
}: ChartActionsProps): JSX.Element => {
    const dispatch = useAppDispatch();

    const dashboardFilter: any = useAppSelector(state =>
        state.dashboard.filter.filters.find(
            (f: any) => f.dashboardId === dashboardId
        )
    );

    const { featureFlags } = useAppSelector(state => state.account);

    let appliedFilter: string[] = [];
    let isFilterApplied: boolean = false;

    if (dashboardFilter) {
        appliedFilter = dashboardFilter.appliedFilter;
        isFilterApplied = dashboardFilter?.reports?.find(
            (r: any) => r.reportId === reportId
        )?.isFilterApplied;
    }

    const handleAiInsights = () => {
        dispatch(selectReport(reportId));
        dispatch(generateAiInsights());
    };

    const menusOpen = moreOpen || exportOpen;

    return (
        <div
            className={cn(
                'editor__chart__header__actions relative',
                'z-20 ml-0 group-hover:ml-[1.6rem]',
                moreOpen && 'ml-[1.6rem]'
            )}
            onClick={e => e.stopPropagation()}
        >
            <Fragment>
                {!isTextChart && featureFlags.aiInsights && (
                    <AiButton
                        onClick={handleAiInsights}
                        size="sm"
                        className={cn(
                            'mr-[.8rem] hidden group-hover:block',
                            menusOpen && 'block'
                        )}
                    >
                        <div className={cn('flex items-center gap-[.4rem]')}>
                            <span className={cn('font-micro-text')}>
                                Insights
                            </span>
                        </div>
                    </AiButton>
                )}
                {!isTextChart && !!appliedFilter?.length && isFilterApplied && (
                    <Popover
                        placement="bottom"
                        trigger="hover"
                        content={
                            <div className="editor__chart__popover">
                                <div className="editor__chart__popover__title">
                                    Filtered by:
                                </div>
                                <Divider />
                                {appliedFilter?.map(filter => {
                                    return (
                                        <span className="editor__chart__popover__body">
                                            {filter}
                                            <br />
                                        </span>
                                    );
                                })}
                            </div>
                        }
                        overlayClassName="popover--no-padding"
                    >
                        <FilterApplied
                            className={cn(
                                'icon ml-[.8rem] group-hover:ml-0',
                                menusOpen && 'ml-0'
                            )}
                        />
                    </Popover>
                )}
                {!isTextChart && !!appliedFilter?.length && !isFilterApplied && (
                    <Tooltip title="Filter cannot be applied" placement="top">
                        <FilterNotApplied className="icon" />
                    </Tooltip>
                )}
                {!disableExploreAccess && !isTextChart && (
                    <Tooltip title="Explore" placement="top">
                        <Explore
                            size={24}
                            className={cn(
                                'icon invisible opacity-0 absolute group-hover:relative group-hover:visible group-hover:opacity-100',
                                menusOpen && 'opacity-100 visible relative'
                            )}
                            onClick={handleExplore}
                        />
                    </Tooltip>
                )}
                {!isTextChart && (
                    <Popover
                        placement="bottom"
                        visible={exportOpen}
                        onVisibleChange={() => setExportOpen(!exportOpen)}
                        content={renderExportMenu()}
                        overlayClassName="popover--no-padding"
                    >
                        <Download
                            size={24}
                            className={cn(
                                'icon hidden group-hover:block',
                                menusOpen && 'block'
                            )}
                        />
                    </Popover>
                )}
                {isInsightsChart && featureFlags.aiInsights && (
                    <RegenerateInsights reportId={reportId} />
                )}
            </Fragment>

            <WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
                <Popover
                    placement="bottomRight"
                    visible={moreOpen}
                    onVisibleChange={() => setMoreOpen(!moreOpen)}
                    content={
                        <MoreMenu
                            dashboardId={dashboardId}
                            dashboardName={dashboardName}
                            reportId={reportId}
                            title={title}
                            onClose={() => setMoreOpen(false)}
                            role={role}
                            isTextChart={isTextChart}
                            hasEvents={eventIds.length > 0}
                            eventsHidden={eventsHidden}
                            setEventsHidden={setEventsHidden}
                            textChartDispatch={textChartDispatch}
                        />
                    }
                    overlayClassName="popover--no-padding"
                >
                    <HMoreVertical
                        className={cn(
                            'icon hidden group-hover:block',
                            menusOpen && 'block'
                        )}
                        width={24}
                        height={24}
                    />
                </Popover>
            </WithPermissions>
        </div>
    );
};

const RegenerateInsights = ({ reportId }: { reportId: string }) => {
    const dispatch = useAppDispatch();
    const dashboardReports = useAppSelector(
        state => state.dashboard.view.reports
    );
    const { latestDataUpdate } = useAppSelector(state => state.account);

    let canRegenerate = false;
    let insightsReportIds: any;

    const currentReport = dashboardReports.find(
        (r: any) => r.reportId === reportId
    );
    const dateAdded = currentReport?.dateAdded;

    insightsReportIds = currentReport.configuration?.find(
        (c: any) => c.name === 'reportIds'
    )?.value;

    if (insightsReportIds) {
        insightsReportIds = JSON.parse(insightsReportIds);
        insightsReportIds = insightsReportIds.filter((reportId: string) =>
            dashboardReports.map((r: any) => r.reportId).includes(reportId)
        );
        if (
            insightsReportIds.length > 0 &&
            new Date(dateAdded) < new Date(latestDataUpdate)
        ) {
            canRegenerate = true;
        }
    }

    function handleRegenerate() {
        dispatch(
            setRegenerateMode({
                regenerateMode: true,
                regenerateReportId: reportId
            })
        );
        dispatch(selectReports(insightsReportIds));
        dispatch(setAiInsightsModelOpen(true));
    }

    if (!canRegenerate) {
        return null;
    }

    return (
        <div className={cn('flex items-center gap-[.8rem]')}>
            <div className={cn('text-ui-50')}>New data available</div>
            <Button large={false} onClick={handleRegenerate}>
                Regenerate
            </Button>
        </div>
    );
};

export default ChartActions;

import { forwardRef } from 'react';
import cn from '../lib/cn';

interface IChartContainerProps {
    children?: React.ReactNode;
}

export type Ref = HTMLDivElement;

const ChartContainer = forwardRef<Ref, IChartContainerProps>((props, ref) => {
    const { children } = props;

    return (
        <div ref={ref} className={cn('chart-container')}>
            {children}
        </div>
    );
});

export default ChartContainer;

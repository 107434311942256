import { ConnectedRouter } from 'connected-react-router';
import { useEffect, useState } from 'react';
import { Route, Switch } from 'react-router-dom';
import ChangePassword from '../auth/ChangePassword';
import EditUser from '../auth/EditUser';
import AuthLayout from '../auth/Layout';
import { didLogin } from '../auth/actions';
import Components from '../components/Components';
import LSKeys from '../constants/LSKeys';
import DataStudio from '../datastudio/DataStudio';
import useCustomLandingPage from '../hooks/useCustomLandingPage';
import Layout from '../layout/Layout';
import updateCssVariables from '../lib/customColors';
import firebase from '../lib/firebase';
import ExternalRedirect from './ExternalRedirect';
import PrivateRoute from './PrivateRoute';
import history from './history';
import './styles.scss';
import { useAppSelector, useAppDispatch } from '../store/hooks';

declare global {
    interface Window {
        zE: any;
    }
}

function Routes() {
    const dispatch = useAppDispatch();
    const { accountLoaded, actorLoaded, analysisLoaded, winked } =
        useAppSelector(state => state.appState);
    const loaded = accountLoaded && actorLoaded && analysisLoaded;
    const userInfo = useAppSelector(state => state.auth.userInfo);
    const customColors = useAppSelector(state => state.account.customColors);

    const [isInitialising, setInitialising] = useState(true);
    const [loggedIn, setLoggedIn] = useState(false);
    const customLandingPage = useCustomLandingPage();

    useEffect(() => {
        window.zE(() => {
            window.zE.hide();
        });
        return firebase.auth().onAuthStateChanged(user => {
            const enterpriseId = localStorage.getItem(LSKeys.EnterpriseId);
            const loggedIn = !!user;
            setLoggedIn(loggedIn);
            setInitialising(false);
            if (loggedIn && !!enterpriseId) {
                dispatch(didLogin());
            }
        });
    }, []);

    useEffect(() => {
        const local = localStorage.getItem(LSKeys.LightMode);

        if (local === 'true') {
            document.body.classList.add('light-mode');
        } else {
            document.body.classList.remove('light-mode');
        }
    }, [userInfo.lightMode]);

    useEffect(() => {
        if (customColors) {
            updateCssVariables(customColors, document);
        }
    }, [customColors]);

    if (isInitialising) {
        return <div className="fb-init-page" />;
    }

    const privateRouteProps = {
        loggedIn,
        loaded,
        winked
    };

    return (
        <ConnectedRouter history={history}>
            <Switch>
                <PrivateRoute
                    path="/"
                    exact
                    {...privateRouteProps}
                    component={Layout}
                    customRoute={customLandingPage}
                />
                <PrivateRoute
                    path="/dashboard/:dashboardId"
                    {...privateRouteProps}
                    component={Layout}
                />
                <PrivateRoute
                    path="/vault/:vaultId"
                    {...privateRouteProps}
                    component={Layout}
                />
                <PrivateRoute
                    path="/explore"
                    {...privateRouteProps}
                    component={Layout}
                />
                <PrivateRoute
                    path="/measure"
                    {...privateRouteProps}
                    component={Layout}
                />
                <PrivateRoute
                    path="/measure/:goalId"
                    {...privateRouteProps}
                    component={Layout}
                />
                <PrivateRoute
                    path="/timeline"
                    {...privateRouteProps}
                    component={Layout}
                />
                <PrivateRoute
                    path="/editor"
                    {...privateRouteProps}
                    component={Layout}
                />
                <PrivateRoute
                    path="/timeline"
                    {...privateRouteProps}
                    component={Layout}
                />

                <Route path="/login" component={AuthLayout} />
                <Route path="/password" component={AuthLayout} />
                <Route path="/forgot" component={AuthLayout} />

                <Route path="/accounts" component={AuthLayout} />
                <Route path="/welcome" component={AuthLayout} />
                <Route path="/privacy">
                    <ExternalRedirect to="https://www.itsdandi.com/privacy" />
                </Route>
                <Route path="/terms">
                    <ExternalRedirect to="https://www.itsdandi.com/terms" />
                </Route>
                <Route path="/msa">
                    <ExternalRedirect to="https://www.itsdandi.com/msa" />
                </Route>
                <Route path="/dpa">
                    <ExternalRedirect to="https://www.itsdandi.com/dpa" />
                </Route>
                <Route path="/onboarding/account" component={AuthLayout} />
                <PrivateRoute
                    path="/edituser"
                    {...privateRouteProps}
                    component={EditUser}
                />
                <PrivateRoute
                    path="/changePassword"
                    {...privateRouteProps}
                    component={ChangePassword}
                />
                <PrivateRoute
                    path="/collect"
                    {...privateRouteProps}
                    component={Layout}
                />
                <PrivateRoute
                    path="/admin"
                    {...privateRouteProps}
                    component={Layout}
                />
                <PrivateRoute
                    path="/admin/create-route"
                    {...privateRouteProps}
                    component={Layout}
                />
                <PrivateRoute
                    path="/components"
                    {...privateRouteProps}
                    component={Components}
                />
                <PrivateRoute
                    path="/datastudio"
                    {...privateRouteProps}
                    component={DataStudio}
                />
                <PrivateRoute
                    path="/ai/:conversationId"
                    {...privateRouteProps}
                    component={() => <Layout aiLayout />}
                />
                <PrivateRoute
                    path="/ai"
                    {...privateRouteProps}
                    component={() => <Layout aiLayout />}
                />

                <PrivateRoute {...privateRouteProps} component={Layout} />
            </Switch>
        </ConnectedRouter>
    );
}

export default Routes;

import React from 'react';

const Info = (props: any) => (
    <svg viewBox="0 0 28 28" fill="currentColor" {...props}>
        <path d="M14 5c-5 0-9 4-9 9s4 9 9 9 9-4 9-9-4-9-9-9zm0 16c-3.9 0-7-3.1-7-7s3.1-7 7-7 7 3.1 7 7-3.1 7-7 7z" />
        <path d="M13 12h2v7h-2zM13 9h2v2h-2z" />
        <g>
            <path fill="none" d="M0 0h28v28H0z" />
        </g>
    </svg>
);

export default Info;

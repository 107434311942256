import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import { memo, useEffect, useMemo, useRef } from 'react';
import { connect } from 'react-redux';

import AiInsightsModal from '../../common/AiInsightsModal';
import WithPermissions from '../../common/WithPermissions';
import { addMessage, setScrollPosition } from '../../common/actions';
import Constants from '../../constants/Constants';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import { selectReports, setAiInsightsModelOpen, setIsSummary } from '../../dashboard/aiInsights/reducer';
import ShareModal from '../../dashboards/ShareModal';
import { getDashboards, getSharedDashboards } from '../../dashboards/actions';
import { addInsightsTextReport, updateInsightsReport } from '../../editor/buttons/actions';
import CreateDashboardModal from '../../explore/dashboard/CreateDashboardModal';
import SelectDashboardModal from '../../explore/dashboard/SelectDashboardModal';
import usePermissions from '../../hooks/usePermissions';
import { page } from '../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import DashboardLayout from '../DashboardLayout';
import { generateAiInsights } from '../aiInsights/actions';
import { setDashboard } from '../edit/actions';
import DashboardFilters, { INTERVAL_OPTIONS } from '../filter/DashboardFilters';
import { setFilterInDashboard, setUpdatedWithFiltersReports } from '../filter/actions';
import ApplyFilterModal from './ApplyFilterModal';
import DeleteReportModal from './DeleteReportModal';
import DuplicateDashboardModal from './DuplicateDashboardModal';
import {
    closeCreateDashboardModalForCopy,
    closeDeleteReportModal,
    closeSelectDashboardModal,
    copyReportToDashboards,
    createDashboardAndCopyReport,
    deleteReport,
    getReports,
    showCreateDashboardModalForCopy,
} from './actions';
import './styles.scss';

function Dashboard({
    closeCreateDashboardModalForCopy,
    closeDeleteReportModal,
    closeSelectDashboardModal,
    copyReportToDashboards,
    createDashboardAndCopyReport,
    dashboard,
    dashboards,
    deleteReport,
    getDashboards,
    getSharedDashboards,
    reports,
    scrollPosition,
    setScrollPosition,
    showCreateDashboardModalForCopy,
}: any) {
    const dispatch = useAppDispatch();
    const {
        insights: aiInsights,
        aiInsightsModalOpen,
        pending,
        loaded,
        error,
    } = useAppSelector((state) => state.dashboard.aiInsights);
    const isDashboardFiltersShown = useAppSelector((state) => state.dashboard.view.showDashboardFilters);
    const { appliedFilter, endDate, filterSegment, interval, isApplyButtonDisabled, startDate } =
        useAppSelector((state) =>
            state.dashboard.filter.filters.find((filter) => filter.dashboardId === dashboard?.dashboardId)
        ) || {};
    const { attributes } = useAppSelector((state) => state.explore.filter);

    const tokenClientRef = useRef();
    const hasViewAllPermissions = usePermissions({
        actions: [RbacActions['Dashboards/ViewAll']],
    });
    const {
        deleteReportId,
        deletePending,
        showDelete,
        searchText,
        showSelect: selectDashboardModalIsOpen,
        showCreate: createDashboardModalIsOpen,
    } = reports;

    const { dashboardId = '' } = dashboard || {};

    useEffect(() => {
        page(MixPanel.Pages.Dashboard);
        if (!window.gapi) {
            return;
        }

        gapi.load('client', () => {
            gapi.client.init({
                apiKey: Constants.SlidesConfig.ApiKey,
                discoveryDocs: Constants.SlidesConfig.DiscoveryDocs,
            });
        });

        if (!google) {
            return;
        }

        // TODO: Check if we need this
        tokenClientRef.current = google.accounts.oauth2.initTokenClient({
            client_id: Constants.SlidesConfig.ClientId,
            scope: Constants.SlidesConfig.Scopes,
            callback: '',
        });
    }, [window.gapi]);

    useEffect(() => {
        if (scrollPosition >= 0) {
            setTimeout(() => window.scrollTo(0, scrollPosition), 0);
            setScrollPosition(-1);
        }
    }, [scrollPosition]);

    useEffect(() => {
        if (!dashboard) {
            if (hasViewAllPermissions) {
                getDashboards();
            } else {
                getSharedDashboards();
            }
        } else {
            // get called too many times.
            getReports(dashboard.reports);
            dispatch(setDashboard(dashboard));
        }
    }, [dashboard, hasViewAllPermissions]);

    const filteredReports = useMemo(() => {
        if (!dashboard) return [];

        if (!searchText) return dashboard.reports;

        const lowerCaseSearchText = searchText.toLowerCase();
        return dashboard.reports.filter((r: any) => r.name.toLowerCase().includes(lowerCaseSearchText));
    }, [dashboard, searchText]);

    useEffect(() => {
        if (!dashboard) return;

        const uniqueAnalysisNames = [...new Set<string>(filteredReports.map((report: any) => report.analysisName))];

        dispatch(setFilterInDashboard(uniqueAnalysisNames, dashboardId));
    }, [filteredReports, dashboard]);

    const handleOnApply = (): void => {
        if (!dashboard) return;

        const newUpdatedReports = dashboard.reports.map((report: any) => {
            const updatedReport = cloneDeep(report);
            let isFilterApplied = false;

            if (filterSegment) {
                const { name, value } = filterSegment;

                const attributeIndex = updatedReport.attributes.findIndex((attr: any) => attr.name === name);

                if (attributeIndex >= 0) {
                    updatedReport.attributes[attributeIndex].value = [value];
                    isFilterApplied = true;
                } else {
                    const segmentExistsInFilter = updatedReport.filter && updatedReport.filter.name === name;
                    const segmentExistsInBreakdown = updatedReport.breakdown && updatedReport.breakdown.name === name;

                    if (segmentExistsInFilter && updatedReport.filter.value !== value) {
                        updatedReport.filter.value = value;
                        isFilterApplied = true;
                    } else if (segmentExistsInBreakdown) {
                        updatedReport.breakdown.value = value;
                        isFilterApplied = true;
                    } else {
                        if (!updatedReport.filter) {
                            updatedReport.filter = { name, value };
                            isFilterApplied = true;
                        } else if (!updatedReport.breakdown) {
                            updatedReport.breakdown = { name, value: value };
                            isFilterApplied = true;
                        }
                    }
                }
            }

            const updateConfiguration = (name: string, value: any) => {
                const index = updatedReport.configuration.findIndex((item: any) => item.name === name);
                if (index >= 0) {
                    updatedReport.configuration[index].value = value;
                } else if (value) {
                    updatedReport.configuration.push({ name, value });
                }
            };

            if (interval) {
                updateConfiguration('interval', interval);
                isFilterApplied = true;

                if (startDate && endDate) {
                    updateConfiguration('startDate', startDate);
                    updateConfiguration('endDate', endDate);
                    updateConfiguration('date', endDate);
                    updateConfiguration('intervalCount', undefined);
                }
            }

            updatedReport.isFilterApplied = isFilterApplied;

            return updatedReport;
        });

        const filtersArray = [];

        if (filterSegment?.name) {
            const matchingAttribute = attributes.find(
                (attr) => attr.attributeName.replace(/ /g, '') === filterSegment.name.replace(/ /g, '')
            );
            const matchingValue = matchingAttribute?.attributeValues.find(
                (av) => av.value === filterSegment.value
            )?.text;
            filtersArray.push(`${filterSegment.name}: ${matchingValue}`);
        }
        if (interval) {
            let element2Str = INTERVAL_OPTIONS.find((option) => option.value === interval)!.label;

            if (startDate) {
                element2Str += ` / ${startDate} - ${endDate}`;
            }

            filtersArray.push(element2Str);
        }

        dispatch(getReports(newUpdatedReports, true, dashboardId));
        dispatch(setUpdatedWithFiltersReports(dashboardId, newUpdatedReports, filtersArray));
        dispatch(addMessage('Filter applied to all reports'));
    };

    const handleOnClear = (): void => {
        if (dashboard) {
            dispatch(getReports(dashboard.reports, true));
        }
    };

    const onSaveAiInsights = () => {
        dispatch(addInsightsTextReport(dashboardId));
    };

    const onUpdateInsightsReport = () => {
        dispatch(updateInsightsReport(dashboardId));
    };

    const allReportIds = Object.keys(reports).filter((id: string) => !!reports[id]?.data);

    return dashboard ? (
        <WithPermissions
            actions={[RbacActions['Dashboards/ViewAll'], RbacActions['Dashboards/ViewShared']]}
            showMessage={true}
        >
            <div
                className="dashboard-filters-wrapper"
                style={{
                    height: isDashboardFiltersShown ? '60px' : '0px',
                    transition: 'height .2s ease-in-out',
                }}
            >
                {isDashboardFiltersShown && (
                    <DashboardFilters
                        isFilterApplied={!appliedFilter?.length}
                        dashboardId={dashboardId}
                        onApply={handleOnApply}
                        onClear={handleOnClear}
                    />
                )}
            </div>
            <div className="dashboard">
                <DashboardLayout filteredReports={filteredReports} dashboard={dashboard} />

                <DeleteReportModal
                    isOpen={showDelete}
                    onCancel={closeDeleteReportModal}
                    onAction={() => deleteReport(dashboard, deleteReportId)}
                    actionPending={deletePending}
                />
                <WithPermissions actions={[RbacActions['Users/View']]}>
                    <ShareModal />
                </WithPermissions>
                <SelectDashboardModal
                    isOpen={selectDashboardModalIsOpen}
                    dashboards={dashboards}
                    onAdd={copyReportToDashboards}
                    onCreate={showCreateDashboardModalForCopy}
                    onCancel={closeSelectDashboardModal}
                    showDescription
                />
                <CreateDashboardModal
                    isOpen={createDashboardModalIsOpen}
                    onSave={createDashboardAndCopyReport}
                    onCancel={closeCreateDashboardModalForCopy}
                />
                <DuplicateDashboardModal isFilterApplied={(!!filterSegment || interval) && isApplyButtonDisabled} />
                <ApplyFilterModal dashboardId={dashboardId} />
            </div>
            {aiInsightsModalOpen && (
                <AiInsightsModal
                    onSave={onSaveAiInsights}
                    onReportUpdate={onUpdateInsightsReport}
                    onClose={() => dispatch(setAiInsightsModelOpen(false))}
                    aiInsights={aiInsights}
                    pending={pending}
                    loaded={loaded}
                    error={error}
                    onSummarize={() => {
                        dispatch(selectReports(allReportIds));
                        dispatch(setIsSummary(true));
                        dispatch(generateAiInsights());
                    }}
                    onGenerateInsights={() => dispatch(generateAiInsights())}
                />
            )}
        </WithPermissions>
    ) : null;
}

Dashboard.propTypes = {
    match: PropTypes.object,
    dashboard: PropTypes.object,
    getDashboards: PropTypes.func,
    closeDeleteReportModal: PropTypes.func,
    deleteReport: PropTypes.func,
    dashboards: PropTypes.array,
    closeSelectDashboardModal: PropTypes.func,
    copyReportToDashboards: PropTypes.func,
    showCreateDashboardModalForCopy: PropTypes.func,
    closeCreateDashboardModalForCopy: PropTypes.func,
    createDashboardAndCopyReport: PropTypes.func,
    getSharedDashboards: PropTypes.func,
    reports: PropTypes.object,
    scrollPosition: PropTypes.number,
    setScrollPosition: PropTypes.func,
};

function mapState(state: any, ownProps: any) {
    const { dashboardId } = ownProps.match.params;
    if (!dashboardId) {
        return {
            navigationError: true,
            dashboard: {},
        };
    } else {
        const dashboard = state.dashboards[dashboardId];
        let dashboards = state.myDashboards.data.map((d: any) => state.dashboards[d]);
        dashboards = dashboards.filter((d: any) => d.dashboardId !== state.reports.selectDashboardId);

        return {
            dashboard,
            dashboards,
            reports: state.reports,
            scrollPosition: state.appState.scrollPosition,
        };
    }
}

const dispatchProps = {
    getDashboards,
    getSharedDashboards,
    closeDeleteReportModal,
    deleteReport,
    closeSelectDashboardModal,
    copyReportToDashboards,
    showCreateDashboardModalForCopy,
    closeCreateDashboardModalForCopy,
    createDashboardAndCopyReport,
    setScrollPosition,
};

const MemoizedDashboard = memo(Dashboard);

export default connect(mapState, dispatchProps)(MemoizedDashboard);

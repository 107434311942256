import React from 'react';
import { Provider } from 'react-redux';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import './less/index.less';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import './sass/app.scss';
import store from './store/store';
import './lib/axios';
import Routes from './routes/Routes';

const App = () => (
    <Provider store={store}>
        <DndProvider backend={HTML5Backend}>
            <Routes />
        </DndProvider>
    </Provider>
);

export default App;

import ActionTypes from '../../constants/ActionTypes';
import qs from 'qs';
import { updateDisabledState, getAttributes } from '../../store/reducerUtils';
import Constants from '../../constants/Constants';
import { Attribute, SegmentName, Segment, Fields, Labels } from '../../types';

function getInitialState() {
    return {
        analysisName: '',
        analysisType: '',
        mainNames: [],
        filterNames: [],
        breakdownNames: [],
        mainSegments: [],
        filterSegment: undefined,
        breakdownSegment: undefined,
        variations: [],
        interval: Constants.DefaultInterval,
        intervalCount: Constants.DefaultIntervalCount,
        attributes: []
    };
}

interface ReducerState {
    analysisName: string;
    analysisType: string;
    mainNames: SegmentName[];
    filterNames: SegmentName[];
    breakdownNames: SegmentName[];
    mainSegments: Segment[];
    filterSegment?: Segment;
    breakdownSegment?: Segment;
    variations: any;
    interval: string;
    intervalCount?: number;
    analysisDisplayName?: string;
    subAnalyses?: any;
    fieldsLoaded?: boolean;
    fields?: Fields;
    attributes: Attribute[];
    labelsLoaded?: boolean;
    labels?: Labels;
    showSubtable?: boolean;
    startDate?: string;
    endDate?: string;
    date?: any;
    isSavedSearch?: boolean;
    generateDisabled?: boolean;
}

export default function filterReducer(
    state: ReducerState = getInitialState(),
    action: any
): ReducerState {
    switch (action.type) {
        case ActionTypes.SetAnalysisName: {
            let {
                analysisDisplayName,
                analysisName,
                subAnalyses,
                analysisType,
                mainNames,
                filterNames,
                breakdownNames,
                variations
            } = action;
            if (mainNames) {
                mainNames = mainNames.map((n: any) => ({ name: n }));
                filterNames = filterNames.map((n: any) => ({ name: n }));
                breakdownNames = breakdownNames.map((n: any) => ({ name: n }));

                return {
                    ...state,
                    analysisName,
                    analysisDisplayName,
                    analysisType,
                    subAnalyses,
                    mainNames,
                    filterNames,
                    breakdownNames,
                    variations,
                    filterSegment: undefined,
                    breakdownSegment: undefined
                };
            }

            return {
                ...state,
                analysisDisplayName,
                analysisName,
                analysisType,
                subAnalyses
            };
        }
        case ActionTypes.SetFilterLabels: {
            const { labels } = action;
            const { fieldsLoaded, fields } = state;
            let { attributes } = state;
            if (fieldsLoaded) {
                attributes = getAttributes(fields!, action.labels);
            }

            return {
                ...state,
                labels,
                attributes,
                labelsLoaded: true
            };
        }
        case ActionTypes.GetFieldsFulfilled: {
            const { labelsLoaded, labels } = state;
            let { attributes } = state;
            if (labelsLoaded) {
                attributes = getAttributes(action.payload, labels!);
            }
            return {
                ...state,
                fields: action.payload,
                attributes,
                fieldsLoaded: true
            };
        }
        case ActionTypes.UpdateMainSegments:
            return updateDisabledState(
                state,
                action.fields,
                state.filterSegment,
                state.breakdownSegment
            );
        case ActionTypes.UpdateFilterSegment:
            return updateDisabledState(
                state,
                state.mainSegments,
                action.segment,
                state.breakdownSegment
            );
        case ActionTypes.UpdateBreakdownSegment:
            return updateDisabledState(
                state,
                state.mainSegments,
                state.filterSegment,
                action.segment
            );
        case ActionTypes.ClearFilters: {
            return {
                ...state,
                analysisDisplayName: '',
                analysisType: '',
                analysisName: '',
                subAnalyses: [],
                mainSegments: [],
                filterSegment: undefined,
                breakdownSegment: undefined,
                mainNames: [],
                filterNames: [],
                breakdownNames: [],
                variations: []
            };
        }
        case ActionTypes.GeneratePending: {
            return {
                ...state,
                showSubtable: false
            };
        }
        case ActionTypes.GenerateFulfilled: {
            return {
                ...state,
                generateDisabled: true
            };
        }
        case ActionTypes.GenerateSubtablePending:
            return {
                ...state,
                showSubtable: true
            };
        case ActionTypes.GenerateSubtableFulfilled:
            return {
                ...state,
                generateDisabled: true
            };
        case ActionTypes.SelectIntervalInExplore:
            return {
                ...state,
                interval: action.interval,
                intervalCount: Constants.DefaultIntervalCount,
                startDate: undefined,
                endDate: undefined
            };
        case ActionTypes.SelectIntervalCountInExplore:
            return {
                ...state,
                intervalCount: action.intervalCount,
                startDate: undefined,
                endDate: undefined
            };
        case ActionTypes.SelectIntervalRangeInExplore:
            return {
                ...state,
                startDate: action.startDate,
                endDate: action.endDate,
                intervalCount: undefined
            };
        case ActionTypes.SelectDateInExplore:
            return {
                ...state,
                date: action.date
            };
        case ActionTypes.ChangeLocation: {
            const { location } = action.payload;
            if (location.search) {
                const query = qs.parse(location.search, {
                    ignoreQueryPrefix: true
                });
                let {
                    interval = Constants.DefaultInterval,
                    startDate,
                    endDate,
                    intervalCount,
                    isSavedSearch,
                    date,
                    showSubtable
                } = query;

                const formattedDate = (date as string)
                    ? (date as string).replace(/\//g, '-')
                    : undefined;

                const intervalCountValue = startDate
                    ? undefined
                    : parseInt(intervalCount as string) ||
                      Constants.DefaultIntervalCount;
                return {
                    ...state,
                    interval: interval as string,
                    intervalCount: intervalCountValue,
                    startDate: startDate as string,
                    endDate: endDate as string,
                    isSavedSearch: isSavedSearch === 'true',
                    date: formattedDate,
                    showSubtable: showSubtable === 'true'
                };
            }
            return state;
        }
        default:
            return state;
    }
}

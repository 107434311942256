import React from 'react';
import PropTypes from 'prop-types';

export default function GridColumn({ children }) {
    if (React.Children.count(children) === 0) {
        return <div className="grid__col" style={{ width }} />;
    }

    return (
        <div className="grid__col w-full">
            <div className="grid__col__inner grid__col__inner--no-border">
                {children}
            </div>
        </div>
    );
}

GridColumn.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

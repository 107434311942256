import LottieAnimation from '../../common/LottieAnimation';
import Loader from '../../lottie/graph-loader.json';
import ChartEmptyReport from './ChartEmptyReport';

interface ChartEmptyStateProps {
    employeeAttribute: string;
    generated: boolean;
    generateError: boolean;
    generateInvalidVariation: boolean;
    generateNoData: boolean;
    generateNotAllowed: boolean;
    generatePending: boolean;
    isEmptyState: boolean;
    onDelete: () => void;
    rowHeight: number;
    source: string;
    title: string;
}

const ChartEmptyState = ({
    employeeAttribute,
    generated,
    generateError,
    generateInvalidVariation,
    generateNoData,
    generateNotAllowed,
    generatePending,
    isEmptyState,
    onDelete,
    rowHeight,
    source,
    title
}: ChartEmptyStateProps): JSX.Element | null => {
    if (isEmptyState) {
        return null;
    }

    if (generatePending) {
        return (
            <LottieAnimation
                autoplay={true}
                loop={true}
                width={60}
                height={60}
                animation={Loader}
            ></LottieAnimation>
        );
    }

    if (generateError) {
        return (
            <ChartEmptyReport
                title={title}
                text={
                    generateNotAllowed
                        ? 'Sorry, you are not authorized to view this report. Your administrator controls these permissions for your account.'
                        : 'Sorry! We’ve encountered an issue displaying this graph.'
                }
                onDelete={onDelete}
                source={source}
                height={rowHeight}
            />
        );
    } else if (generateInvalidVariation) {
        return (
            <ChartEmptyReport
                title={title}
                html={
                    source === 'editor' && employeeAttribute ? (
                        <span>
                            This report has not yet been activated by your
                            company.
                            <a
                                href="mailto: support@itsdandi.com"
                                target="__blank"
                            >
                                Contact
                            </a>{' '}
                            your Dandi Customer Success Representative to turn
                            this report on.
                        </span>
                    ) : (
                        <span>
                            This report has not yet been activated by your
                            company.{' '}
                            <a
                                href="mailto: support@itsdandi.com"
                                target="__blank"
                            >
                                Contact
                            </a>{' '}
                            your Dandi Customer Success Representative to turn
                            it on.
                        </span>
                    )
                }
                onDelete={onDelete}
                source={source}
                height={rowHeight}
            />
        );
    } else if (generateNoData) {
        return (
            <ChartEmptyReport
                title={title}
                text="Sorry! There is no data for this report."
                onDelete={onDelete}
                source={source}
                height={rowHeight}
            />
        );
    } else if (!generated) {
        return <div className="editor__chart__text"></div>;
    }
    return null;
};

export default ChartEmptyState;

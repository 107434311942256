export interface DropdownState {
    parentId: string;
    multiLevel: boolean;
    level1: {
        type: 'single' | 'multi';
        typeId: string;
    };
    level2: {
        typeId: string;
        type: 'single' | 'multi';
    };
    label: string | undefined;
    initialized: boolean;
    isOpen: boolean;
    data: any[];
    activeId: string | undefined;
    status: 'idle' | 'hasSelection';
    reduxAction: any;
    lookup: {
        [key: string]: {
            checked: boolean;
            isAll: boolean;
            activeIds: string[];
        };
    };
}

export interface DropdownAction {
    type: string;
    payload: any;
}

export const DropdownActions = {
    TOGGLE_ITEM: 'TOGGLE_ITEM',
    TOGGLE_ITEM_VALUE: 'TOGGLE_ITEM_VALUE',
    TOGGLE_ITEM_VALUE_ALL: 'TOGGLE_ITEM_VALUE_ALL',
    TOGGLE_ACTIVE_ITEM: 'TOGGLE_ACTIVE_ITEM',
    TOGGLE_ANY: 'TOGGLE_ANY',
    SET_DATA: 'SET_DATA',
    SET_INITIAL_STATE: 'SET_INITIAL_STATE',
    SET_IS_OPEN: 'SET_IS_OPEN',
    CLEAR: 'CLEAR'
};

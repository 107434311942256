import orderBy from 'lodash/orderBy';
import precisionRound from '../lib/precisionRound';
import { CollectState } from './model';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import moment from 'moment';
import Urls from '../constants/Urls';
import { AsyncThunkConfig } from '../store/store';

function getCollectState(key: string, current: any, previous: any) {
    const { missingData, dataPointsPerColumn } = current;
    const previousMissingData = previous && previous.missingData;
    let attributes = missingData.filter((m: any) => m.fieldName !== 'id');
    let totalMissingPoints = 0;
    attributes.forEach((a: any) => {
        if (previousMissingData) {
            const previousMissingDataItem = previousMissingData.find(
                (pmd: any) => pmd.fieldName === a.fieldName
            );
            if (previousMissingDataItem) {
                let changePercentage =
                    a.completionPercentage -
                    previousMissingDataItem.completionPercentage;
                a.changePercentage = precisionRound(changePercentage, 1);
            }
        }
        a.fieldName = a.fieldName.replace(/_/g, ' ');
        a.completionPercentage = precisionRound(a.completionPercentage, 1);
        a.totalDataPoints = dataPointsPerColumn;
        totalMissingPoints += a.totalMissingPoints;
    });
    attributes = orderBy(attributes, 'fieldName');
    const totalDataPoints = dataPointsPerColumn * attributes.length;
    const completionPercentage = precisionRound(
        ((totalDataPoints - totalMissingPoints) / totalDataPoints) * 100,
        1
    );
    const summary = {
        totalMissingPoints,
        totalDataPoints,
        completionPercentage
    };
    const title = key.toUpperCase();
    return {
        title,
        summary,
        attributes
    };
}

export const fetchCollect = createAsyncThunk<any, void, AsyncThunkConfig>(
    'collect',
    async (_, { getState }) => {
        const { enterpriseId, latestDataUpdate } = getState().account;
        const currentResponse = await axios.post(
            `${Urls.CollectApi}v2/metrics`,
            {
                enterpriseId,
                date: moment.utc(latestDataUpdate).format('MM/DD/YYYY')
            }
        );
        const previousResponse = await axios.post(
            `${Urls.CollectApi}v2/metrics`,
            {
                enterpriseId,
                date: moment
                    .utc(latestDataUpdate)
                    .subtract(1, 'months')
                    .format('MM/DD/YYYY')
            }
        );
        return {
            current: currentResponse.data,
            previous: previousResponse.data
        };
    }
);

export const collectSlice = createSlice({
    name: 'collect',
    initialState: {} as CollectState,
    reducers: {
        demoAction: state => {
            // update state
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchCollect.fulfilled, (state, action) => {
            const { current, previous } = action.payload;
            state.packages = [];
            Object.keys(current.missingDataList).forEach(packageKey => {
                const collectState = getCollectState(
                    packageKey,
                    current.missingDataList[packageKey],
                    previous.missingDataList[packageKey]
                );
                state.packages?.push(collectState);
            });
            state.loaded = true;
        });
        builder.addCase(fetchCollect.pending, (state, action) => {
            state.loaded = false;
        });
        builder.addCase(fetchCollect.rejected, (state, action) => {
            state.loaded = true;
        });
    }
});

export const { demoAction } = collectSlice.actions;
export default collectSlice.reducer;

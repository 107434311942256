import React from 'react';

const More = (props: any) => (
    <svg
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 28 28"
        fill="currentColor"
        {...props}
    >
        <circle id="a" cx={14} cy={8} r={2} />
        <circle id="c" cx={14} cy={14} r={2} />
        <circle id="e" cx={14} cy={20} r={2} />
    </svg>
);

export default More;

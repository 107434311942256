import ActionTypes from '../../constants/ActionTypes';
import ChartTypes from '../../constants/ChartTypes';
import qs from 'qs';
import { replace } from 'connected-react-router';

export function toggleExpandedInSubtable() {
    return {
        type: ActionTypes.ToggleExpandedInSubtable
    };
}

export function sortInSubtable(columnName: string) {
    return {
        type: ActionTypes.SortInSubtable,
        columnName
    };
}

export function setSubtableChartType(chartType: ChartTypes) {
    return (dispatch: any, getState: any) => {
        const { state, search } = getState().router.location;

        const queryObject = qs.parse(search, {
            ignoreQueryPrefix: true
        });
        queryObject.subtableChartType = chartType;
        let url = '/explore?';
        const string = qs.stringify(queryObject, {
            arrayFormat: 'indices',
            encodeValuesOnly: true
        });
        url += string;
        dispatch(replace(url, { source: state?.source || 'explore' }));

        dispatch({
            type: ActionTypes.SetSubtableChartType,
            chartType
        });
    };
}

import React, { useState } from 'react';
import Popover from '../../../common/Popover';
import HMore from '../../../icons/HMoreVertical';
import Menu from 'antd/lib/menu';
import { Category } from '../category/types';
import { Question as QuestionModel } from './types';
import { push } from 'connected-react-router';
import { useAppDispatch } from '../../../store/hooks';
import { deleteQuestion } from './editReducer';
import { fetchQuestions } from './reducer';
import DeleteModal from '../../../common/DeleteModal';
import { duplicateQuestion, archiveQuestion } from './editReducer';
import ConfirmationModal from '../../../common/ConfirmationModal';

interface QuestionProps {
    categories: Category[];
    question: QuestionModel;
    archived?: boolean;
}

export default function Question({
    categories,
    question,
    archived
}: QuestionProps) {
    const dispatch = useAppDispatch();
    const [moreOpen, setMoreOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [archiveModalOpen, setArchiveModalOpen] = useState(false);
    const [archivePending, setArchivePending] = useState(false);

    function handleEdit() {
        dispatch(
            push(
                `/datastudio/survey/question/edit?globalQuestionId=${question.globalQuestionId}`
            )
        );
    }

    async function handleDelete() {
        await dispatch(deleteQuestion(question.globalQuestionId));
        await dispatch(fetchQuestions());
        setDeleteModalOpen(false);
    }

    function handleMoreClick() {
        setMoreOpen(!moreOpen);
    }

    function handleMenuClick({ domEvent }: any) {
        domEvent.stopPropagation();
    }

    function getCategoryName(globalCategoryId: string) {
        return (
            categories.find(c => c.globalCategoryId === globalCategoryId)
                ?.title ?? ''
        );
    }

    async function handleDuplicate() {
        await dispatch(
            duplicateQuestion({
                title: question.title,
                globalCategoryId: question.globalCategoryId,
                fieldName: question.fieldName,
                enterpriseId: question.enterpriseId,
                values: question.values,
                valueType: question.valueType,
                isPrivate: !!question.enterpriseId,
                uiElements: question.uiElements
            })
        );
        await dispatch(fetchQuestions());
        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    }

    async function handleArchive() {
        try {
            setArchivePending(true);
            await dispatch(
                archiveQuestion({
                    question,
                    shouldArchive: !archived
                })
            );
            dispatch(fetchQuestions());
        } finally {
            setArchiveModalOpen(false);
            setArchivePending(false);
        }
    }

    return (
        <>
            <div
                className="questions-grid__question"
                key={question.title}
                onClick={() => handleEdit()}
            >
                <div className="questions-grid__question__title">
                    {question.title}
                </div>
                <div
                    className="questions-grid__question__more"
                    onClick={e => e.stopPropagation()}
                >
                    <Popover
                        placement="bottomRight"
                        visible={moreOpen}
                        onVisibleChange={handleMoreClick}
                        content={
                            <Menu
                                className="widget-menu"
                                selectedKeys={[]}
                                onClick={handleMenuClick}
                            >
                                {!archived && (
                                    <Menu.Item key="edit" onClick={handleEdit}>
                                        Edit
                                    </Menu.Item>
                                )}
                                {!archived && (
                                    <Menu.Item
                                        key="duplicate"
                                        onClick={handleDuplicate}
                                    >
                                        Duplicate
                                    </Menu.Item>
                                )}
                                <Menu.Item
                                    key="archive"
                                    onClick={() => {
                                        setArchiveModalOpen(true);
                                    }}
                                >
                                    {archived ? 'Unarchive' : 'Archive'}
                                </Menu.Item>
                                <Menu.Item
                                    key="delete"
                                    onClick={() => {
                                        setDeleteModalOpen(true);
                                    }}
                                >
                                    Delete
                                </Menu.Item>
                            </Menu>
                        }
                        overlayClassName="popover--no-padding"
                    >
                        <HMore className="icon" width={24} height={24} />
                    </Popover>
                </div>
                <div className="questions-grid__question__category">
                    {getCategoryName(question.globalCategoryId)}
                </div>
                <div className="questions-grid__question__field">
                    {question.fieldName}
                </div>
            </div>
            <DeleteModal
                isOpen={deleteModalOpen}
                onCancel={() => setDeleteModalOpen(false)}
                onAction={handleDelete}
                title="Delete question"
            >
                <p>Click Delete to permanently delete this question.</p>
            </DeleteModal>
            <ConfirmationModal
                title={archived ? 'Unarchive Question' : 'Archive Question'}
                isOpen={archiveModalOpen}
                pending={archivePending}
                confirmationText={archived ? 'Unarchive' : 'Archive'}
                onAction={(e: any) => {
                    e.stopPropagation();
                    handleArchive();
                }}
                onCancel={(e: any) => {
                    e.stopPropagation();
                    setArchivePending(false);
                    setArchiveModalOpen(false);
                }}
            >
                {!archived ? (
                    <p>
                        Archiving removes the question from the active view. Do
                        you want to archive?
                    </p>
                ) : (
                    <p>
                        Unarchiving restores the question to the active view. Do
                        you want to unarchive?
                    </p>
                )}
            </ConfirmationModal>
        </>
    );
}

import sortBy from 'lodash/sortBy';
import ActionTypes from '../../constants/ActionTypes';
import Constants from '../../constants/Constants';
import objectifyArray from '../../lib/objectifyArray';
import {
    getConfig,
    getSegmentsFromV1Report,
    getSegmentsFromV2Report
} from '../../store/commonUtils';
import {
    getAttributeName,
    getAttributes,
    getSegments,
    updateDisabledState
} from '../../store/reducerUtils';

function getInitialState() {
    return {
        category: '',
        analysisName: '',
        mainNames: [],
        filterNames: [],
        breakdownNames: [],
        mainSegments: [],
        filterSegment: undefined,
        breakdownSegment: undefined,
        variations: [],
        interval: Constants.DefaultInterval,
        intervalCount: Constants.DefaultIntervalCount,
        labels: {},
        fields: {},
        generateDisabled: true,
        attributeOptions: []
    };
}

function getAttributeOptions(
    filters: any,
    variations: any,
    initialAttributes: any
) {
    if (!variations) return [];
    let attributeOptions = getSegments(filters, variations).map(s => ({
        value: s,
        label: getAttributeName(s, initialAttributes)
    }));
    attributeOptions = sortBy(attributeOptions, ao => ao.label);
    return attributeOptions;
}

interface ReducerState {
    analysisName: string;
    mainNames: any[];
    filterNames: any[];
    breakdownNames: any[];
    mainSegments: any;
    filterSegment: any;
    breakdownSegment: any;
    variations: any[];
    interval: string;
    intervalCount?: number;
    labels: any;
    fields: any;
    generateDisabled: boolean;
    attributeOptions: any;
    fieldsLoaded?: boolean;
    attributes?: any;
    labelsLoaded?: boolean;
    analysisDisplayName?: string;
    subAnalyses?: any;
    analysisType?: string;
    startDate?: any;
    endDate?: any;
    filters?: any;
    variationsMap?: any;
    category: string;
    initialAttributes?: any;
    date?: any;
}

export default function filterReducer(
    state: ReducerState = getInitialState(),
    action: any
): ReducerState {
    switch (action.type) {
        case ActionTypes.SetFilterLabels: {
            const { labels } = action;
            const { fieldsLoaded, fields } = state;
            let { attributes } = state;
            if (fieldsLoaded) {
                attributes = getAttributes(fields, action.labels);
            }

            return {
                ...state,
                labels,
                attributes,
                labelsLoaded: true
            };
        }
        case ActionTypes.GetFieldsFulfilled: {
            const { labelsLoaded, labels } = state;
            let { attributes } = state;
            if (labelsLoaded) {
                attributes = getAttributes(action.payload, labels);
            }
            return {
                ...state,
                fields: action.payload,
                attributes,
                fieldsLoaded: true
            };
        }
        case ActionTypes.SetAnalysisNameInEditor: {
            let {
                analysisDisplayName,
                analysisName,
                analysisType,
                subAnalyses,
                mainNames,
                filterNames,
                breakdownNames,
                variations
            } = action;
            if (mainNames) {
                mainNames = mainNames.map((n: any) => ({ name: n }));
                filterNames = filterNames.map((n: any) => ({ name: n }));
                breakdownNames = breakdownNames.map((n: any) => ({ name: n }));

                return {
                    ...state,
                    analysisDisplayName,
                    analysisName,
                    analysisType,
                    subAnalyses,
                    mainNames,
                    filterNames,
                    breakdownNames,
                    variations,
                    mainSegments: [],
                    filterSegment: undefined,
                    breakdownSegment: undefined,
                    generateDisabled: false
                };
            }

            return {
                ...state,
                analysisDisplayName,
                analysisName,
                subAnalyses
            };
        }
        case ActionTypes.UpdateMainSegmentsInEditor:
            return updateDisabledState(
                state,
                action.fields,
                state.filterSegment,
                state.breakdownSegment
            );
        case ActionTypes.UpdateFilterSegmentInEditor:
            return updateDisabledState(
                state,
                state.mainSegments,
                action.segment,
                state.breakdownSegment
            );
        case ActionTypes.UpdateBreakdownSegmentInEditor:
            return updateDisabledState(
                state,
                state.mainSegments,
                state.filterSegment,
                action.segment
            );
        case ActionTypes.ClearFiltersInEditor: {
            return {
                ...state,
                analysisDisplayName: '',
                analysisName: '',
                analysisType: '',
                subAnalyses: [],
                mainSegments: [],
                filterSegment: undefined,
                breakdownSegment: undefined,
                interval: Constants.DefaultInterval,
                intervalCount: Constants.DefaultIntervalCount,
                startDate: undefined,
                endDate: undefined,
                generateDisabled: true,
                attributeOptions: [],
                mainNames: [],
                filterNames: [],
                breakdownNames: []
            };
        }
        case ActionTypes.GeneratePendingInEditor: {
            const {
                filters,
                variationsMap = {},
                category,
                initialAttributes
            } = state;
            const variations = variationsMap[category];
            const attributeOptions = getAttributeOptions(
                filters,
                variations,
                initialAttributes
            );
            return {
                ...state,
                attributeOptions
            };
        }
        case ActionTypes.GenerateNoDataInEditor:
        case ActionTypes.GenerateFulfilledInEditor: {
            return {
                ...state,
                generateDisabled: true
            };
        }
        case ActionTypes.SetFiltersFromReport: {
            const { report, template } = action;
            const {
                analysisName,
                analysis,
                attributes,
                filter,
                breakdown,
                version,
                configuration
            } = report;

            const { variations } = template;
            let { mainNames, filterNames, breakdownNames, analysisType } =
                template;
            const { attributes: allAttributes } = state;

            const interval = getConfig(
                configuration,
                'interval',
                Constants.DefaultInterval
            );
            const intervalCount = parseInt(
                getConfig(
                    configuration,
                    'intervalCount',
                    Constants.DefaultIntervalCount
                )
            );
            const startDate = getConfig(configuration, 'startDate');
            const endDate = getConfig(configuration, 'endDate');
            const date = getConfig(configuration, 'date');

            let mainSegments, breakdownSegment, filterSegment;
            if (version === '2') {
                ({ mainSegments, breakdownSegment, filterSegment } =
                    getSegmentsFromV2Report(attributes, filter, breakdown));
            } else {
                const employeeAttribute = getConfig(
                    configuration,
                    'employeeAttribute'
                );
                const rowFilter = getConfig(configuration, 'rowFilter');
                const columnFilter = getConfig(configuration, 'columnFilter');
                ({ mainSegments, breakdownSegment, filterSegment } =
                    getSegmentsFromV1Report(
                        attributes,
                        employeeAttribute,
                        rowFilter,
                        columnFilter,
                        allAttributes,
                        variations
                    ));
            }

            mainNames = mainNames.map((n: any) => ({ name: n }));
            filterNames = filterNames.map((n: any) => ({ name: n }));
            breakdownNames = breakdownNames.map((n: any) => ({ name: n }));

            const templateState = {
                variations,
                mainNames,
                filterNames,
                breakdownNames
            };

            const segmentsState = updateDisabledState(
                templateState,
                mainSegments,
                filterSegment,
                breakdownSegment
            );

            const subAnalyses = analysis.map((a: any) => ({
                ...objectifyArray(a.configuration)
            }));

            return {
                ...state,
                analysisName,
                ...segmentsState,
                interval,
                intervalCount,
                startDate,
                endDate,
                date,
                variations,
                subAnalyses,
                analysisType,
                analysisDisplayName: analysisName
            };
        }
        case ActionTypes.SetIntervalInEditor:
            return {
                ...state,
                interval: action.interval,
                intervalCount: Constants.DefaultIntervalCount,
                startDate: undefined,
                endDate: undefined
            };
        case ActionTypes.SetIntervalCountInEditor:
            return {
                ...state,
                intervalCount: action.intervalCount,
                startDate: undefined,
                endDate: undefined
            };
        case ActionTypes.SetIntervalRangeInEditor:
            return {
                ...state,
                intervalCount: undefined,
                startDate: action.startDate,
                endDate: action.endDate
            };
        case ActionTypes.SetDateInEditor:
            return {
                ...state,
                date: action.date
            };

        default:
            return state;
    }
}

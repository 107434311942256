import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { changePassword } from './actions';
import MessageBar from '../common/MessageBar';
import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';
import FormError from '../common/FormError';
import MixPanel from '../constants/MixPanel';
import { track } from '../lib/segment';

const PasswordRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})');

class ChangePassword extends Component {
    constructor() {
        super();
        this.state = {
            currentPassword: '',
            newPassword: '',
            confirmPassword: ''
        };
    }

    handleCurrentPasswordChange(e) {
        const currentPassword = e.target.value;
        this.setState({ currentPassword });
    }

    handleNewPasswordChange(e) {
        const newPassword = e.target.value;
        this.setState({ newPassword });
    }

    handleConfirmPasswordChange(e) {
        const confirmPassword = e.target.value;
        this.setState({ confirmPassword });
    }

    handleSave(e) {
        e.preventDefault();
        const { currentPassword, newPassword, confirmPassword } = this.state;
        const passwordError = PasswordRegex.test(newPassword)
            ? ''
            : 'Password should conform to our password policy';
        const confirmError =
            newPassword !== confirmPassword ? 'Passwords do not match' : '';
        this.setState({
            passwordError,
            confirmError
        });
        if (!passwordError && !confirmError) {
            track(MixPanel.Events.SaveChangePassword);
            const { email, onPasswordChange } = this.props;
            onPasswordChange(email, currentPassword, newPassword);
        }
    }

    handleCancel(e) {
        e.preventDefault();
        track(MixPanel.Events.CancelChangePassword);
        const { history } = this.props;
        history.goBack();
    }

    render() {
        const {
            currentPassword,
            newPassword,
            confirmPassword,
            passwordError,
            confirmError
        } = this.state;
        const { pending } = this.props;
        const disabled = !currentPassword || !newPassword || !confirmPassword;

        return (
            <div className="password-container">
                <form className="password-form">
                    <div className="password-form__title">Change Password</div>
                    <div className="password-form__row mb16">
                        <input
                            type="password"
                            className="large-text"
                            placeholder="Current Password"
                            value={currentPassword}
                            onChange={this.handleCurrentPasswordChange.bind(
                                this
                            )}
                        />
                    </div>
                    <div className="password-form__row">
                        <input
                            type="password"
                            className={
                                passwordError
                                    ? 'large-text large-text--error'
                                    : 'large-text'
                            }
                            placeholder="New Password"
                            value={newPassword}
                            onChange={this.handleNewPasswordChange.bind(this)}
                        />
                        <FormError error={passwordError} />
                    </div>
                    <div className="password-form__row">
                        <input
                            type="password"
                            className={
                                confirmError
                                    ? 'large-text large-text--error'
                                    : 'large-text'
                            }
                            placeholder="Confirm New Password"
                            value={confirmPassword}
                            onChange={this.handleConfirmPasswordChange.bind(
                                this
                            )}
                        />
                        <FormError error={confirmError} />
                    </div>
                    <div className="password-form__row mb16">
                        <div className="password-form__prompt">
                            * Passwords need to consist of 8 characters, <br />
                            one number and one capital letter.
                        </div>
                    </div>
                    <div className="password-form__row--buttons">
                        <Button
                            componentType={ButtonTypes.type.TERTIARY}
                            onClick={this.handleCancel.bind(this)}
                        >
                            Cancel
                        </Button>
                        <Button
                            loading={pending}
                            disabled={disabled}
                            onClick={this.handleSave.bind(this)}
                        >
                            Save
                        </Button>
                    </div>
                </form>
                <MessageBar />
            </div>
        );
    }
}

ChangePassword.propTypes = {
    email: PropTypes.string,
    onPasswordChange: PropTypes.func,
    history: PropTypes.object,
    pending: PropTypes.bool
};

function mapStateToProps(state) {
    return {
        email: state.auth.userInfo.email,
        pending: state.auth.passwordPending
    };
}

const dispatchProps = {
    onPasswordChange: changePassword
};

export default connect(mapStateToProps, dispatchProps)(ChangePassword);

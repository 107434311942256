import { useRef, useState } from 'react';
import { PanelResizeHandle } from 'react-resizable-panels';
import cn from '../lib/cn';

export default function ColumnResizer({
    onDragging,
    id,
    disabled
}: {
    onDragging: any;
    id: string;
    disabled?: boolean;
}) {
    const ref = useRef<HTMLDivElement>(null);
    const [verticalPosition, setVerticalPosition] = useState(0);
    const [isHovering, setIsHovering] = useState(false);

    const handleMouseMove = (event: any) => {
        if (ref.current) {
            const containerTop = ref.current.getBoundingClientRect().top;
            const relativeY = event.clientY - containerTop + 140;
            setVerticalPosition(relativeY);
        }
    };

    return (
        <div className="relative w-[0px] h-full bg-transparent">
            {!disabled && (
                <PanelResizeHandle
                    onDragging={onDragging}
                    className={cn(
                        'absolute h-full left-1/2 -translate-x-1/2 w-[20px]',
                        'z-20'
                    )}
                    id={id}
                >
                    <div
                        ref={ref}
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                        onMouseMove={(e: any) => {
                            if (isHovering && !disabled) {
                                handleMouseMove(e);
                            }
                        }}
                        className={cn(
                            'absolute h-full left-1/2 -translate-x-1/2 w-[20px]'
                        )}
                    >
                        <div
                            data-name="column-resizer-handle"
                            className={`h-[40px] w-[5px] rounded-full bg-ui-100 left-1/2 absolute top-0 ${
                                isHovering
                                    ? 'opacity-100 visible'
                                    : 'opacity-0 invisible'
                            } `}
                            style={{
                                transform: `translate3d(-50%,${
                                    verticalPosition - 160
                                }px, 0)`
                            }}
                        ></div>
                    </div>
                </PanelResizeHandle>
            )}
        </div>
    );
}

import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.568 6.926a4 4 0 014-4h40a4 4 0 014 4v21.043h-48V6.926z"
                fill="var(--color-shade-h3)"
            />
            <rect
                x={1.037}
                y={1.969}
                width={50}
                height={50}
                rx={5}
                stroke="var(--color-graph-6)"
                strokeWidth={2}
            />
            <path
                d="M1.568 27.969h48v18.956a4 4 0 01-4 4h-40a4 4 0 01-4-4V27.97z"
                fill="var(--color-shade-h1)"
            />
            <circle cx={43.927} cy={19.049} r={3} fill="var(--color-graph-6)" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M1.568 42.108L17.116 26.56l10.358 10.357 17.16-17.161-1.414-1.414-15.746 15.747-10.357-10.357L1.567 39.28v2.828z"
                fill="var(--color-graph-6)"
            />
        </svg>
    );
}

export default SvgComponent;

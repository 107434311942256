import moment from 'moment';

export function getDateByInterval(date: string | Date, interval: string) {
    switch (interval) {
        case 'Month':
            return moment(date).format('MMMM YYYY');
        case 'Quarter':
            return moment(date).format('YYYY [Q]Q');
        case 'Year':
            return moment(date).format('YYYY');
        case 'Day':
            return moment(date).format('YYYY-MM-DD');
        default: {
            return date;
        }
    }
}

export function getHeaderRow(
    baseHeader: string[] = [],
    mainSegments: any[] = [],
    columnProps: any[] = [],
    additionalHeaders: string[] = []
) {
    const header = [...baseHeader];

    if (mainSegments.length > 0) {
        header.push(mainSegments.map((s: any) => s.name).join(' & ') || '');
    }
    columnProps?.forEach((c: any) => {
        header.push(c.header);
    });

    additionalHeaders?.forEach((c: any) => {
        header.push(c);
    });

    return header;
}

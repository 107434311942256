import PropTypes from 'prop-types';
import { useCallback } from 'react';
import { connect } from 'react-redux';
import { useAppSelector } from '../store/hooks';
import {
    addReportToDashboard,
    createDashboardAndAddReport
} from './dashboard/actions';
import DynamicSection from './detail/DynamicSection';
import {
    clearFilter,
    closeSegment,
    fetchBySegment,
    hoverLabel,
    navigateToDetail,
    setColumnFilter,
    setRowFilter,
    setSortCriteria,
    toggleExpansion,
    toggleLabel,
    unhoverLabel
} from './detail/actions';

function DetailSection({
    enterpriseName,
    generated,
    data,
    onFetch,
    onClose,
    date,
    interval,
    toggleExpansion,
    dynamicSections,
    navigateToDetail,
    dashboards,
    addReportToDashboard,
    createDashboardAndAddReport,
    queryString,
    role,
    setRowFilter,
    setColumnFilter,
    clearFilter,
    hoverLabel,
    unhoverLabel,
    toggleLabel,
    setSortCriteria
}) {
    const disableAll = dynamicSections.length === 0;
    const { dynamicTemplates } = useAppSelector(state => state.templates);
    const { analysisName } = useAppSelector(state => state.explore.filter);

    const getFilteredDynamicSections = useCallback(() => {
        const currentTemplate = dynamicTemplates.find(
            t => t.analysisName === analysisName
        );
        return dynamicSections?.filter(ds => {
            if (
                currentTemplate?.breakdownNames
                    .map(bn => bn.fieldName)
                    .includes(ds.employeeAttribute) ||
                currentTemplate?.breakdownNames
                    .map(bn => bn.fieldName)
                    .includes('*')
            ) {
                return true;
            }
            return false;
        });
    }, [dynamicSections, dynamicTemplates, analysisName]);

    const filterDynamicSections = getFilteredDynamicSections();

    return (
        <div className="explore__detail">
            {filterDynamicSections.map(ds => (
                <DynamicSection
                    key={ds.employeeAttribute}
                    {...ds}
                    enterpriseName={enterpriseName}
                    date={date}
                    interval={interval}
                    toggleExpansion={toggleExpansion}
                    disabled={!generated || data.length === 0 || disableAll}
                    onFetch={onFetch}
                    onClose={onClose}
                    navigateToDetail={navigateToDetail}
                    dashboards={dashboards}
                    addReportToDashboard={addReportToDashboard}
                    createDashboardAndAddReport={createDashboardAndAddReport}
                    queryString={queryString}
                    role={role}
                    setRowFilter={setRowFilter}
                    setColumnFilter={setColumnFilter}
                    clearFilter={clearFilter}
                    hoverLabel={hoverLabel}
                    unhoverLabel={unhoverLabel}
                    toggleLabel={toggleLabel}
                    setSortCriteria={setSortCriteria}
                />
            ))}
        </div>
    );
}

DetailSection.defaultProps = {};

DetailSection.propTypes = {
    enterpriseName: PropTypes.string,
    analysisType: PropTypes.string,
    data: PropTypes.array,
    onFetch: PropTypes.func,
    onClose: PropTypes.func,
    generated: PropTypes.bool,
    date: PropTypes.any,
    interval: PropTypes.string,
    navigateToDetail: PropTypes.func,
    toggleExpansion: PropTypes.func,
    dynamicSections: PropTypes.array,
    dashboards: PropTypes.array,
    addReportToDashboard: PropTypes.func,
    createDashboardAndAddReport: PropTypes.func,
    queryString: PropTypes.string,
    role: PropTypes.string
};

function mapState(state) {
    const dashboards = state.myDashboards.data.map(d => state.dashboards[d]);
    return {
        enterpriseName: state.account.enterpriseName,
        analysisType: state.explore.detail.filters.analysisType,
        generated: state.explore.chart.generated,
        data: state.explore.chart.data,
        date: state.explore.chart.dates[state.explore.chart.currentIndex],
        interval: state.explore.filter.interval,
        dynamicSections: state.explore.detail.dynamic.sections,
        role: state.auth.role,
        dashboards
    };
}

const dispatchProps = {
    onFetch: fetchBySegment,
    onClose: closeSegment,
    navigateToDetail,
    toggleExpansion,
    addReportToDashboard,
    createDashboardAndAddReport,
    setRowFilter,
    setColumnFilter,
    clearFilter,
    hoverLabel,
    unhoverLabel,
    toggleLabel,
    setSortCriteria
};

export default connect(mapState, dispatchProps)(DetailSection);

import ActionTypes from '../constants/ActionTypes';

let messageId = 1;

function addMessage(state: any, message: any) {
    const messages = state.slice();
    message.id = messageId++;
    messages.push(message);
    return messages;
}

interface Message {
    text: string;
    error?: boolean;
}

export default function messageReducer(
    state: Message[] = [],
    action: any
): Message[] {
    switch (action.type) {
        case ActionTypes.CreateActorFulfilled: {
            if (action.sendNotification) {
                return addMessage(state, {
                    text: `Account setup instructions have been sent to ${action.email}`
                });
            } else {
                return addMessage(state, {
                    text: `Account ${action.email} has been created `
                });
            }
        }
        case ActionTypes.DeleteActorFulfilled:
            return addMessage(state, {
                text: action.firstName
                    ? `${action.firstName} ${action.lastName} has been removed from this account`
                    : 'User has been removed from this account'
            });
        case ActionTypes.GrantFulfilled:
            return action.noMessage
                ? state
                : addMessage(state, {
                      text: action.firstName
                          ? `${action.firstName} ${action.lastName}'s role has been updated to ${action.role}`
                          : `User role has been updated to ${action.role}`
                  });
        case ActionTypes.CreateRoleFulfilled:
            return addMessage(state, {
                text: `${action.role} role has been created`
            });
        case ActionTypes.UpdateRoleFulfilled:
            return addMessage(state, {
                text: `${action.role} role has been updated`
            });
        case ActionTypes.CreateDashboardFulfilled:
            return addMessage(state, {
                text: `${action.dashboard.name} dashboard has been created`
            });
        case ActionTypes.DuplicateDashboardFulfilled:
            return addMessage(state, {
                text: `${action.dashboard.name} dashboard has been created`
            });
        case ActionTypes.DeleteDashboardFulfilled:
            return addMessage(state, {
                text: `${action.dashboardName} dashboard has been removed`
            });
        case ActionTypes.SendResetEmailFulfilled:
            return addMessage(state, {
                text: 'You’ll receive an email with a link to reset your password, if your account exists'
            });
        case ActionTypes.ExportFulfilled:
            return addMessage(state, {
                text: 'You’ll receive an email with a link to download your CSV export file'
            });
        case ActionTypes.SendResetEmailRejected:
            return addMessage(state, {
                text: 'You’ll receive an email with a link to reset your password, if your account exists'
            });
        case ActionTypes.LoginRejected:
            return addMessage(state, {
                text: 'You entered an incorrect email address or password',
                error: true
            });
        case ActionTypes.ChangePasswordRejected:
            return addMessage(state, {
                text: 'Unable to change password',
                error: true
            });
        case ActionTypes.ExportRejected:
            return addMessage(state, {
                text: 'Error in exporting data to CSV file',
                error: true
            });
        case ActionTypes.CloseMessage:
            if (state.length) {
                const newState = state.slice();
                newState.shift();
                return newState;
            }
            return state;
        case ActionTypes.AddMessage:
            return addMessage(state, {
                text: action.text,
                error: action.error,
                node: action.node
            });
        case ActionTypes.UpdateAccountFulfilled:
            return addMessage(state, {
                text: 'Account has been updated'
            });
        case ActionTypes.DeletePaymentMethodFulfilled:
            return addMessage(state, {
                text: `Payment method has been removed`
            });
        case ActionTypes.DeletePaymentMethodRejected:
            return addMessage(state, {
                text: `Error in removing payment method`,
                error: true
            });
        case ActionTypes.SavePaymentMethodFulfilled:
            return addMessage(state, {
                text: 'Payment methods has been saved'
            });
        case ActionTypes.SavePaymentMethodRejected:
            return addMessage(state, {
                text: 'Error in saving payment method',
                error: true
            });
        case ActionTypes.VaultLinkCopied:
            return addMessage(state, {
                text: 'Share link is copied to your clipboard'
            });
        case ActionTypes.ShareDashboardFulfilled:
            if (action.count) {
                return addMessage(state, {
                    text: `${action.dashboardName} dashboard has been shared`
                });
            }
            return state;
        case ActionTypes.UnshareDashboardFulfilled:
            if (action.count) {
                return addMessage(state, {
                    text: `${action.dashboardName} dashboard has been unshared`
                });
            }
            return state;
        case ActionTypes.SaveReportFulfilled:
            if (action.dashboardName) {
                return addMessage(state, {
                    text: `Report added to ${action.dashboardName} dashboard`
                });
            }
            return state;
        case ActionTypes.InsertReportFulfilled:
            return addMessage(state, {
                text: `Report added to ${action.dashboard.name} dashboard`
            });
        case ActionTypes.UpdateAllReportsFulfilled:
            if (!action.hideMessage) {
                return addMessage(state, {
                    text: `Reports updated in ${action.dashboard.name} dashboard`
                });
            }
            return state;
        case ActionTypes.UpdateReportFulfilled:
            if (!action.hideMessage) {
                return addMessage(state, {
                    text: `Report updated in ${action.dashboard.name} dashboard`
                });
            }
            return state;
        case ActionTypes.DeleteBenchmarkFulfilled:
            return addMessage(state, {
                text: `Benchmark has been removed`
            });
        case ActionTypes.DeleteBenchmarkRejected:
            return addMessage(state, {
                text: `Error in removing benchmark`,
                error: true
            });
        case ActionTypes.SaveConfigurationFulfilled:
            return addMessage(state, {
                text: `Template configuration ${action.configuration.templateName} has been saved`
            });
        case ActionTypes.SaveTemplateFulfilled:
            return addMessage(state, {
                text: `Template ${action.template.analysisName} has been saved`
            });
        case ActionTypes.SavePackageFulfilled:
            return addMessage(state, {
                text: `Package ${action.packageObj.packageName} has been saved`
            });
        case ActionTypes.SaveEnterpriseFulfilled:
            return addMessage(state, {
                text: `Enterprise ${action.enterpriseName} has been saved`
            });
        case 'createAccount/fulfilled':
            return addMessage(state, {
                text: `Enterprise ${action.meta.arg.enterpriseName} has been created`
            });
        case 'survey/saveCategory/fulfilled':
            return addMessage(state, {
                text: `Survey category ${action.meta.arg.title} has been saved`
            });
        case 'survey/saveCategory/rejected':
            return addMessage(state, {
                text: `Error in saving survey category ${action.meta.arg.title}`,
                error: true
            });
        case 'survey/archiveCategory/fulfilled': {
            const archiveText = action.meta.arg.shouldArchive
                ? 'archived'
                : 'unarchived';
            return addMessage(state, {
                text: `Survey category ${action.meta.arg.category.title} has been ${archiveText}`
            });
        }
        case 'survey/saveCategory/rejected': {
            const archiveText = action.meta.arg.shouldArchive
                ? 'archiving'
                : 'unarchiving';
            return addMessage(state, {
                text: `Error in ${archiveText} survey category ${action.meta.arg.category.title}`,
                error: true
            });
        }
        case 'surveys/enterprise/reminders/create/id/fulfilled': {
            return addMessage(state, {
                text: `Reminder emails sent for campaign ${action.payload}`
            });
        }
        case 'surveys/enterprise/reminders/create/id/rejected': {
            return addMessage(state, {
                text: `Error in sending reminder emails`,
                error: true
            });
        }
        case ActionTypes.AssignRoleFulfilled:
            return addMessage(state, {
                text: action.firstName
                    ? `Role ${action.roleName} has been assigned to ${action.firstName} ${action.lastName}`
                    : `Role ${action.roleName} has been assigned to ${action.email}`
            });
        case ActionTypes.UnassignRoleFulfilled:
            return addMessage(state, {
                text: action.firstName
                    ? `Role ${action.roleName} has been unassigned from ${action.firstName} ${action.lastName}`
                    : `Role ${action.roleName} has been unassigned from ${action.email}`
            });
        case ActionTypes.AssignGroupRoleFulfilled:
            return addMessage(state, {
                text: `Role ${action.roleName} has been assigned to ${action.groupName}`
            });
        case ActionTypes.UnassignGroupRoleFulfilled:
            return addMessage(state, {
                text: `Role ${action.roleName} has been unassigned from ${action.groupName}`
            });
        case 'survey/saveCategory/fulfilled':
            return addMessage(state, {
                text: `Category ${action.meta.arg.title} has been saved`
            });
        case 'survey/saveCategory/rejected':
            return addMessage(state, {
                text: `Error in saving category ${action.meta.arg.title}`,
                error: true
            });
        case 'survey/deleteCategory/fulfilled':
            return addMessage(state, {
                text: `Category has been removed`
            });
        case 'survey/deleteCategory/rejected':
            return addMessage(state, {
                text: action.error.message,
                error: true
            });
        case 'survey/saveQuestion/fulfilled':
            return addMessage(state, {
                text: `Question ${action.meta.arg.title} has been saved`
            });
        case 'survey/saveQuestion/rejected':
            return addMessage(state, {
                text: `Error in saving question ${action.meta.arg.title}`,
                error: true
            });
        case 'survey/archiveQuestion/fulfilled': {
            const archiveText = action.meta.arg.shouldArchive
                ? 'archived'
                : 'unarchived';
            return addMessage(state, {
                text: `Question ${action.meta.arg.question.title} has been ${archiveText}`
            });
        }
        case 'survey/archiveQuestion/rejected': {
            const archiveText = action.meta.arg.shouldArchive
                ? 'archiving'
                : 'unarchiving';
            return addMessage(state, {
                text: `Error in ${archiveText} question ${action.meta.arg.question.title}`,
                error: true
            });
        }
        case 'survey/duplicateQuestion/fulfilled':
            return addMessage(state, {
                text: `Question ${action.meta.arg.title} has been duplicated`
            });
        case 'survey/duplicateQuestion/rejected':
            return addMessage(state, {
                text: `Error in duplicating question ${action.meta.arg.title}`,
                error: true
            });
        case 'survey/deleteQuestion/fulfilled':
            return addMessage(state, {
                text: `Question has been removed`
            });
        case 'survey/deleteQuestion/rejected':
            return addMessage(state, {
                text: action.error.message,
                error: true
            });
        case 'survey/saveSurveyTemplate/fulfilled':
            return addMessage(state, {
                text: `Campaign template ${action.meta.arg.title} has been saved`
            });
        case 'survey/saveSurveyTemplate/rejected':
            return addMessage(state, {
                text: `Error in saving campaign template ${action.meta.arg.title}`,
                error: true
            });
        case 'survey/duplicateSurveyTemplate/fulfilled':
            return addMessage(state, {
                text: `Campaign template ${action.meta.arg.title} has been duplicated`
            });
        case 'survey/duplicateSurveyTemplate/rejected':
            return addMessage(state, {
                text: `Error in duplicating campaign template ${action.meta.arg.title}`,
                error: true
            });
        case 'survey/archiveSurveyTemplate/fulfilled': {
            const archiveText = action.meta.arg.shouldArchive
                ? 'archived'
                : 'unarchived';
            return addMessage(state, {
                text: `Campaign template ${action.meta.arg.survey.title} has been ${archiveText}`
            });
        }
        case 'survey/archiveQuestion/rejected': {
            const archiveText = action.meta.arg.shouldArchive
                ? 'archiving'
                : 'unarchiving';
            return addMessage(state, {
                text: `Error in ${archiveText} campaign template ${action.meta.arg.survey.title}`,
                error: true
            });
        }
        case 'survey/deleteSurveyTemplate/fulfilled':
            return addMessage(state, {
                text: `Campaign template has been removed`
            });
        case 'survey/deleteSurveyTemplate/rejected':
            return addMessage(state, {
                text: action.error.message,
                error: true
            });
        case 'survey/publishSurveyTemplate/fulfilled':
            const published = action.meta.arg.isPublished
                ? 'published'
                : 'unpublished';
            return addMessage(state, {
                text: `Campaign template has been ${published}`
            });
        case 'survey/publishSurveyTemplate/rejected':
            return addMessage(state, {
                text: action.error.message,
                error: true
            });

        case 'surveys/template/snapshot/create/fulfilled':
            return addMessage(state, {
                text: `Your campaign ${action.payload?.survey?.title} has been saved`
            });
        case 'surveys/template/snapshot/create/rejected':
            return addMessage(state, {
                text: action.error.message,
                error: true
            });
        case 'surveys/template/snapshot/update/fulfilled':
            return addMessage(state, {
                text: `Your campaign ${action.payload?.survey[0]?.title} has been saved`
            });
        case 'surveys/template/snapshot/update/rejected':
            return addMessage(state, {
                text: action.error.message,
                error: true
            });
        case 'surveys/template/snapshot/delete/fulfilled':
            return addMessage(state, {
                text: `Your campaign ${action.payload?.title} has been deleted`
            });
        case 'surveys/template/snapshot/delete/rejected':
            return addMessage(state, {
                text: action.error.message,
                error: true
            });
        case 'surveys/template/snapshot/archive/fulfilled': {
            const archiveText = action.meta.arg.shouldArchive
                ? 'archived'
                : 'unarchived';
            return addMessage(state, {
                text: `Your campaign ${action.meta.arg.survey.title} has been ${archiveText}`
            });
        }
        case 'surveys/template/snapshot/archive/rejected': {
            const archiveText = action.meta.arg.shouldArchive
                ? 'archiving'
                : 'unarchiving';
            return addMessage(state, {
                text: `Error in ${archiveText} campaign ${action.meta.arg.survey.title}`,
                error: true
            });
        }
        case 'surveys/template/snapshot/publish/fulfilled':
            return addMessage(state, {
                text: `Your campaign ${action.payload?.title} has been ${
                    action.meta.arg?.isPublished ? 'published' : 'unpublished'
                }`
            });
        case 'surveys/template/snapshot/publish/rejected':
            return addMessage(state, {
                text: action.error.message,
                error: true
            });
        case 'audiences/dynamic/create/fulfilled':
            return addMessage(state, {
                text: `Your audience ${action.payload?.audience?.title} has been saved`
            });
        case 'audiences/dynamic/create/rejected':
            return addMessage(state, {
                text: action.error.message,
                error: true
            });
        case 'audiences/dynamic/update/fulfilled':
            return addMessage(state, {
                text: `Your audience ${action.payload?.audience?.title} has been saved`
            });
        case 'audiences/dynamic/update/rejected':
            return addMessage(state, {
                text: action.error.message,
                error: true
            });
        case 'audiences/static/create/fulfilled':
            return addMessage(state, {
                text: `Your audience ${action.payload?.title} has been saved`
            });
        case 'audiences/static/create/rejected':
            return addMessage(state, {
                text: action.error.message,
                error: true
            });
        case 'audiences/static/update/fulfilled':
            return addMessage(state, {
                text: `Your audience ${action.payload?.title} has been saved`
            });
        case 'audiences/static/update/rejected':
            return addMessage(state, {
                text: action.error.message,
                error: true
            });
        case 'audiences/delete/fulfilled':
            return addMessage(state, {
                text: `You audience ${action.payload?.title} has been deleted`
            });
        case 'audiences/delete/rejected':
            return addMessage(state, {
                text: `You cannot delete audience because it's used in an active campaign`,
                error: true
            });
        case 'audiences/archive/fulfilled': {
            const archiveText = action.meta.arg.shouldArchive
                ? 'archived'
                : 'unarchived';
            return addMessage(state, {
                text: `You audience ${action.meta.arg.audience.title} has been ${archiveText}`
            });
        }
        case 'audiences/archive/rejected': {
            const archiveText = action.meta.arg.shouldArchive
                ? 'archiving'
                : 'unarchiving';
            return addMessage(state, {
                text: `Error in ${archiveText} audience ${action.meta.arg.audience.title}`,
                error: true
            });
        }
        default:
            return state;
    }
}

import React from 'react';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import produce from 'immer';
import FieldMapEdit from './FieldMapEdit';
import sortBy from 'lodash/sortBy';

export default function FieldMap({
    label,
    uiFieldMapping,
    setUiFieldMapping,
    fieldMapType
}: any) {
    function handleNew(fieldMapType: string) {
        setUiFieldMapping(
            produce(uiFieldMapping, (draftState: any) => {
                draftState[fieldMapType]['New'] = {
                    source: '',
                    order: '',
                    calculated: false,
                    unit: ''
                };
            })
        );
    }

    const sortedKeys = sortBy(
        Object.keys(uiFieldMapping?.[fieldMapType] || {}),
        key => parseInt(uiFieldMapping[fieldMapType][key].order)
    );

    return (
        <div>
            <div className="ds-label" style={{ marginBottom: 8 }}>
                <span style={{ marginRight: 16 }}>{label}</span>
                <Button
                    componentType={ButtonTypes.type.SECONDARY}
                    onClick={() => handleNew(fieldMapType)}
                    large={false}
                >
                    Add new
                </Button>
            </div>
            {sortedKeys.map((key, i) => {
                const field = uiFieldMapping[fieldMapType][key];
                return (
                    <FieldMapEdit
                        key={i}
                        defaultDisplayName={key}
                        field={field}
                        fieldMapType={fieldMapType}
                        setUiFieldMapping={setUiFieldMapping}
                    />
                );
            })}
            <div style={{ marginBottom: 8 }} />
        </div>
    );
}

import FileSaver from 'file-saver';
import AnalysisTypes from '../../constants/AnalysisTypes';
import CanvasFonts from '../../constants/CanvasFonts';
import getPeriod from '../../lib/getPeriod';
import getFormattedValue from '../../lib/getFormattedValue';
import precisionRound from '../../lib/precisionRound';
import loadImage from '../../lib/loadImage';
import truncateHeader from '../../lib/truncateHeader';
import Constants from '../../constants/Constants';
import blankEmployeeCount from '../../lib/blankEmployeeCount';

const xPadding = 16;
const yPadding = 16;
const padding = 24;
const yTable = 58;
const colWidth = 135;
const largeColWidth = 270;

export function getComputedCssValue(variable) {
    return window.getComputedStyle(document.body).getPropertyValue(variable);
}

export function getCssVariable(variable) {
    const regex = /--[^)]*/;
    const match = variable.match(regex);

    if (match) {
        return match[0];
    }
    return '';
}

function getColumnCount(columnProps, expanded) {
    if (columnProps.length > 2) {
        return expanded ? columnProps.length : 1;
    }
    return columnProps.length;
}

function getFieldNames(columnProps, expanded) {
    if (columnProps.length > 2 && !expanded) {
        return [columnProps[0].source];
    } else {
        return columnProps.map(columnProp => columnProp.source);
    }
}

function getHeaderNames(columnProps, expanded) {
    if (columnProps.length > 2 && !expanded) {
        return [
            truncateHeader(columnProps[0].header, Constants.MaxHeaderLength)
        ];
    } else {
        return columnProps.map(columnProp =>
            truncateHeader(columnProp.header, Constants.MaxHeaderLength)
        );
    }
}

function drawArrow(
    context,
    xCurrent,
    yCurrent,
    columnName,
    propName,
    sortCriteria
) {
    const textWidth = context.measureText(columnName).width;
    context.font = CanvasFonts.Regular8;
    const arrow =
        sortCriteria.columnName === propName && !sortCriteria.ascending
            ? '\u{25b2}'
            : '\u{25bc}';
    context.fillText(arrow, xCurrent + textWidth + 4, yCurrent);
}

export default async function exportTable(
    title,
    confidence,
    data,
    lifecycle,
    analysisType,
    columnProps,
    dates,
    interval,
    rowHeight,
    sortCriteria,
    expanded,
    options,
    shouldReturnBlob,
    shouldReturnData
) {
    const headerRows = options.showSinglePeriod === 'true' ? 1 : 2;
    if (options.showSinglePeriod === 'true') {
        expanded = true;
    }
    const canvas = document.createElement('canvas');
    const columnCount = getColumnCount(columnProps, expanded);
    const canvasWidth =
        headerRows === 2
            ? 2 * xPadding +
              largeColWidth +
              dates.length * columnCount * colWidth
            : 2 * xPadding + largeColWidth + columnCount * colWidth;
    const canvasHeight =
        yTable + rowHeight * headerRows + data.length * rowHeight + yPadding;
    canvas.width = canvasWidth;
    canvas.height = canvasHeight;

    const context = canvas.getContext('2d');
    context.fillStyle = getComputedCssValue('--color-shade-h3');
    context.fillRect(0, 0, canvas.width, canvas.height);

    context.fillStyle = getComputedCssValue('--color-ui-100');
    context.font = CanvasFonts.Medium18;
    context.fillText(title, xPadding, yPadding + 18);

    if (confidence) {
        const confidenceText = `Confidence ${confidence}%`;
        context.fillStyle = getComputedCssValue('--color-ui-50');
        context.font = CanvasFonts.Regular14;
        const xText =
            canvas.width -
            xPadding -
            context.measureText(confidenceText).width -
            100;
        context.fillText(confidenceText, xText, yPadding + 18);
    }

    let yText = rowHeight / 2 + 4;
    let xCurrent = xPadding + largeColWidth + xPadding;
    let yCurrent = yTable + yText;

    context.fillStyle = getComputedCssValue('--color-ui-50');
    context.font = CanvasFonts.Regular14;

    if (headerRows === 2) {
        dates.forEach(d => {
            const period = getPeriod(d, interval);
            context.fillText(period, xCurrent, yCurrent);
            xCurrent += colWidth * columnCount;
        });
    }

    xCurrent = xPadding;
    yCurrent = yTable + rowHeight * (headerRows - 1) + yText;
    if (sortCriteria.columnName === 'label') {
        context.fillStyle = getComputedCssValue('--color-ui-100');
    }
    context.fillText('Segment', xCurrent, yCurrent);
    drawArrow(context, xCurrent, yCurrent, 'Segment', 'label', sortCriteria);

    const fieldNames = getFieldNames(columnProps, expanded);
    const headerNames = getHeaderNames(columnProps, expanded);

    xCurrent = xPadding + largeColWidth + xPadding;
    if (headerRows === 2) {
        dates.forEach(date => {
            const label = getPeriod(date, interval.toLowerCase());
            const prefix = label.toLowerCase().replace(/ /g, '-');

            fieldNames.forEach((fieldName, i) => {
                context.font = CanvasFonts.Regular14;
                context.fillStyle = getComputedCssValue('--color-ui-50');
                const propName = prefix + '-' + fieldName;
                if (sortCriteria.columnName === propName) {
                    context.fillStyle = getComputedCssValue('--color-ui-100');
                }
                context.fillText(headerNames[i], xCurrent, yCurrent);
                drawArrow(
                    context,
                    xCurrent,
                    yCurrent,
                    headerNames[i],
                    propName,
                    sortCriteria
                );
                xCurrent += colWidth;
            });
        });
    }
    if (headerRows === 1) {
        const label = getPeriod(
            dates[dates.length - 1],
            interval.toLowerCase()
        );
        const prefix = label.toLowerCase().replace(/ /g, '-');
        fieldNames.forEach((fieldName, i) => {
            context.font = CanvasFonts.Regular14;
            context.fillStyle = getComputedCssValue('--color-ui-50');
            const propName = prefix + '-' + fieldName;
            if (sortCriteria.columnName === propName) {
                context.fillStyle = getComputedCssValue('--color-ui-100');
            }
            context.fillText(headerNames[i], xCurrent, yCurrent);
            drawArrow(
                context,
                xCurrent,
                yCurrent,
                headerNames[i],
                propName,
                sortCriteria
            );
            xCurrent += colWidth;
        });
    }

    context.font = CanvasFonts.Regular14;
    xCurrent = 0;
    yCurrent = yTable + headerRows * rowHeight;
    data.forEach((d, rowIndex) => {
        const odd = rowIndex % 2 === 0;
        if (d.selected) {
            context.fillStyle = getComputedCssValue('--color-shade-h4');
            context.fillRect(xCurrent, yCurrent, canvasWidth, rowHeight);
        } else if (odd) {
            context.fillStyle = getComputedCssValue('--color-shade-3');
            context.fillRect(xCurrent, yCurrent, canvasWidth, rowHeight);
        }

        xCurrent = xPadding;
        if (d.isEqualityIndex || d.isAttritionIndex) {
            context.fillStyle = getComputedCssValue('--color-ui-50');
            context.fillText(d.label, xCurrent, yCurrent + yText);
        } else {
            d.segment.tokens.forEach(t => {
                if (typeof t === 'string') {
                    context.fillStyle = getComputedCssValue('--color-ui-50');
                    context.fillText(t, xCurrent, yCurrent + yText);
                    xCurrent += context.measureText(t).width + 4;
                } else {
                    context.beginPath();
                    context.arc(
                        xCurrent + 2,
                        yCurrent + yText - 2,
                        2,
                        0,
                        2 * Math.PI
                    );
                    context.fillStyle = getComputedCssValue(
                        getCssVariable(t.color)
                    );
                    context.fill();

                    context.fillStyle = getComputedCssValue('--color-ui-100');
                    context.fillText(t.text, xCurrent + 16, yCurrent + yText);
                    xCurrent += context.measureText(t.text).width + 20;
                }
            });
        }

        xCurrent = xPadding + largeColWidth;

        if (headerRows === 2) {
            dates.forEach((date, index2) => {
                if (dates.length !== 1 && index2 % 2 === 0) {
                    context.fillStyle = getComputedCssValue('--color-shade-3');
                    if (d.selected) {
                        context.fillStyle =
                            getComputedCssValue('--color-shade-h5');
                    }
                    context.fillRect(
                        xCurrent,
                        yCurrent,
                        colWidth * columnCount,
                        rowHeight
                    );
                }
                const period = getPeriod(date, interval);
                const seriesIndex = d.series.findIndex(
                    s => getPeriod(s.date, interval) === period
                );
                if (seriesIndex > -1) {
                    const seriesItem = d.series[seriesIndex];
                    let previousItem;
                    if (options.changeOption && seriesIndex > 0) {
                        previousItem = d.series[seriesIndex - 1];
                    }
                    fieldNames.forEach((fieldName, i) => {
                        let dataValue = seriesItem[fieldName];
                        if (typeof dataValue === 'undefined') {
                            dataValue = '';
                        }
                        if (i === 0) {
                            dataValue = getFormattedValue(
                                analysisType,
                                dataValue
                            );
                        }

                        let change = '';
                        let sign = '';
                        if (
                            i === 0 &&
                            previousItem &&
                            options.changeOption &&
                            !d.isEqualityIndex &&
                            !d.isAttritionIndex
                        ) {
                            if (options.changeOption === 'Value') {
                                change =
                                    seriesItem[fieldName] -
                                    previousItem[fieldName];
                                change = precisionRound(change, 1);
                                if (change > 0) sign = '+';
                            }
                            if (options.changeOption === 'Percentage') {
                                if (previousItem[fieldName] === 0) {
                                    change = '---';
                                } else {
                                    change =
                                        ((seriesItem[fieldName] -
                                            previousItem[fieldName]) *
                                            100) /
                                        previousItem[fieldName];
                                    change = precisionRound(change, 1);
                                    if (change > 0) sign = '+';
                                    change += '%';
                                }
                            }
                            change = `(${sign}${change})`;
                        }
                        context.fillStyle =
                            getComputedCssValue('--color-ui-50');
                        context.fillText(
                            blankEmployeeCount(dataValue),
                            xCurrent + xPadding,
                            yCurrent + yText
                        );
                        if (change) {
                            context.fillStyle =
                                getComputedCssValue('--color-ui-50');
                            context.fillText(
                                change,
                                xCurrent +
                                    xPadding +
                                    context.measureText(dataValue).width +
                                    4,
                                yCurrent + yText
                            );
                        }
                        xCurrent += colWidth;
                    });
                } else {
                    xCurrent += colWidth * columnCount;
                }
            });
        }

        if (headerRows === 1) {
            const date = dates[dates.length - 1];
            const period = getPeriod(date, interval);
            const seriesIndex = d.series.findIndex(
                s => getPeriod(s.date, interval) === period
            );

            if (seriesIndex > -1) {
                const seriesItem = d.series[seriesIndex];
                fieldNames.forEach((fieldName, i) => {
                    let dataValue = seriesItem[fieldName];
                    if (typeof dataValue === 'undefined') {
                        dataValue = '';
                    }
                    if (i === 0) {
                        dataValue = getFormattedValue(analysisType, dataValue);
                    }
                    context.fillStyle = getComputedCssValue('--color-ui-50');
                    context.fillText(
                        dataValue,
                        xCurrent + xPadding,
                        yCurrent + yText
                    );
                    xCurrent += colWidth;
                });
            }
        }

        xCurrent = 0;
        yCurrent += rowHeight;
    });

    context.strokeStyle = getComputedCssValue('--color-ui-50');
    if (headerRows === 2) {
        dates.forEach((_, index) => {
            fieldNames.forEach((_, index2) => {
                context.beginPath();
                if (index2 === 0) {
                    context.moveTo(
                        xPadding +
                            largeColWidth +
                            index * colWidth * columnCount,
                        yTable
                    );
                } else {
                    context.moveTo(
                        xPadding +
                            largeColWidth +
                            (index * columnCount + index2) * colWidth,
                        yTable + rowHeight
                    );
                }
                context.lineTo(
                    xPadding +
                        largeColWidth +
                        (index * columnCount + index2) * colWidth,
                    canvasHeight - yPadding
                );
                context.stroke();
            });
        });
    }

    if (headerRows === 1) {
        fieldNames.forEach((_, index2) => {
            context.beginPath();
            context.moveTo(
                xPadding + largeColWidth + index2 * colWidth,
                yTable
            );
            context.lineTo(
                xPadding + largeColWidth + index2 * colWidth,
                canvasHeight - yPadding
            );
            context.stroke();
        });
    }

    let image = await loadImage('/images/watermark.svg');
    context.drawImage(
        image,
        canvas.width - padding - image.width,
        yPadding + 20 - image.height
    );

    if (shouldReturnData) {
        return {
            dataURL: canvas.toDataURL(),
            width: canvas.width,
            height: canvas.height
        };
    } else {
        const blob = await new Promise(resolve => canvas.toBlob(resolve));
        if (shouldReturnBlob) {
            return {
                blob,
                width: canvas.width,
                height: canvas.height
            };
        } else {
            FileSaver.saveAs(blob, `${title}.png`);
        }
    }
}

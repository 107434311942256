import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import Stack from '../../common/Stack';
import AnalysisTypes from '../../constants/AnalysisTypes';
import ChartTypes from '../../constants/ChartTypes';
import BarActive from '../../icons/BarActive';
import BarInactive from '../../icons/BarInactive';
import LineActive from '../../icons/LineActive';
import LineInactive from '../../icons/LineInactive';
import PieActive from '../../icons/PieActive';
import PieInactive from '../../icons/PieInactive';
import StackedBarActive from '../../icons/StackedBarActive';
import StackedBarInactive from '../../icons/StackedBarInactive';
import StackedHorizontalBarEditor from '../../icons/StackedHorizontalBarEditor';
import StackedHorizontalBarEditorInactive from '../../icons/StackHorizontalBarEditorInactive';
import TableActive from '../../icons/TableActive';
import TableInactive from '../../icons/TableInactive';
import { useAppSelector } from '../../store/hooks';

const chartTypes = [
    {
        chartType: ChartTypes.Line,
        activeIcon: LineActive,
        inactiveIcon: LineInactive
    },
    {
        chartType: ChartTypes.Bar,
        activeIcon: BarActive,
        inactiveIcon: BarInactive
    },
    {
        chartType: ChartTypes.Pie,
        activeIcon: PieActive,
        inactiveIcon: PieInactive
    },
    {
        chartType: ChartTypes.StackedBar,
        displayName: 'Stacked bar',
        activeIcon: StackedBarActive,
        inactiveIcon: StackedBarInactive
    },
    {
        chartType: ChartTypes.Table,
        activeIcon: TableActive,
        inactiveIcon: TableInactive
    },
    {
        chartType: ChartTypes.Matrix,
        displayName: 'Breakdown Table',
        activeIcon: TableActive,
        inactiveIcon: TableInactive
    },
    {
        chartType: ChartTypes.HorizontalBar,
        displayName: 'Horizontal Bar',
        activeIcon: StackedHorizontalBarEditor,
        inactiveIcon: StackedHorizontalBarEditorInactive
    }
];

function getMainColumn(state) {
    const analysisName = state.editor.chart?.lifecycle;
    if (!analysisName) return;

    const template = state.templates.dynamicTemplates.find(
        t => t.analysisName === analysisName
    );

    return template?.mainColumn;
}

function isChartTypeDisabled(
    ct,
    chartType,
    analysisType,
    mainColumn,
    intersectionAttributes,
    hasBenchmark
) {
    const isSubtable =
        chartType === ChartTypes.Matrix ||
        chartType === ChartTypes.HorizontalBar;

    const isLineOrBar =
        ct.chartType === ChartTypes.Line || ct.chartType === ChartTypes.Bar;

    const hasMultipleIntersectionAttributes =
        intersectionAttributes && intersectionAttributes.length > 1;

    const isLinearOrIndexAnalysis =
        analysisType === AnalysisTypes.LinearRegression ||
        analysisType === AnalysisTypes.Index;

    switch (ct.chartType) {
        case ChartTypes.Line:
            return (hasBenchmark && isLineOrBar) || isSubtable;
        case ChartTypes.Bar:
            return (hasBenchmark && isLineOrBar) || isSubtable;
        case ChartTypes.Pie:
            return (
                isLinearOrIndexAnalysis ||
                (analysisType === AnalysisTypes.Percentage &&
                    mainColumn === 'excluded') ||
                hasMultipleIntersectionAttributes ||
                isSubtable
            );
        case ChartTypes.StackedBar:
            return (
                !(
                    analysisType === AnalysisTypes.Percentage &&
                    (mainColumn === 'total' || mainColumn === 'included')
                ) || isSubtable
            );

        case ChartTypes.Table:
            return isSubtable;
        case ChartTypes.Matrix:
            return !isSubtable;
        case ChartTypes.HorizontalBar:
            return !isSubtable;
        default:
            return true;
    }
}

function ChartType({ chartType, setChartType, hasBenchmark }) {
    const { analysisType, lifecycle, intersectionAttributes } = useAppSelector(
        state => state.editor.chart
    );
    const mainColumn = useAppSelector(getMainColumn);
    const [activeChartType, setActiveChartType] = useState('');
    useEffect(() => {
        const disabled =
            (chartType === ChartTypes.Pie ||
                chartType === ChartTypes.StackedBar) &&
            (analysisType === AnalysisTypes.LinearRegression ||
                analysisType === AnalysisTypes.Index ||
                (analysisType === AnalysisTypes.Percentage &&
                    mainColumn === 'excluded') ||
                (intersectionAttributes && intersectionAttributes.length > 1));
        if (disabled) {
            setChartType(ChartTypes.Line);
        }
    }, [lifecycle, analysisType, intersectionAttributes]);

    return (
        <div className="editor__type">
            {chartTypes.map(ct => {
                const disabled = isChartTypeDisabled(
                    ct,
                    chartType,
                    analysisType,
                    mainColumn,
                    intersectionAttributes,
                    hasBenchmark
                );
                let selected = ct.chartType === chartType;
                const active = ct.chartType === activeChartType;

                return (
                    <div
                        key={ct.chartType}
                        className={classNames('editor__type__item', {
                            'editor__type__item--disabled': disabled
                        })}
                        onClick={
                            disabled
                                ? undefined
                                : () => setChartType(ct.chartType)
                        }
                        onMouseEnter={() => setActiveChartType(ct.chartType)}
                        onMouseLeave={() => setActiveChartType('')}
                    >
                        {selected ? (
                            <ct.activeIcon className="editor__type__icon editor__type__icon--selected" />
                        ) : (
                            <ct.inactiveIcon
                                className={classNames('editor__type__icon', {
                                    'editor__type__icon--active':
                                        active && !disabled
                                })}
                            />
                        )}
                        <Stack alignItems="center">
                            <div
                                className={classNames('editor__type__text', {
                                    'editor__type__text--highlight': selected,
                                    'editor__type__text--disabled':
                                        !selected && disabled,
                                    'editor__type__text--active':
                                        !selected && !disabled && active
                                })}
                            >
                                {ct.displayName ? ct.displayName : ct.chartType}
                            </div>
                        </Stack>
                    </div>
                );
            })}
        </div>
    );
}

ChartType.propTypes = {
    chartType: PropTypes.string,
    setChartType: PropTypes.func,
    hasBenchmark: PropTypes.bool
};

export default ChartType;

import { useContext, useState } from 'react';
import { ColumnContext } from './Draggable';
import DragAndDrop from '../icons/DragAndDrop';
import * as Tooltip from '@radix-ui/react-tooltip';
import cn from '../lib/cn';

export default function DraggingTrigger({
    fillContainer,
    isLocked,
    ...props
}: {
    fillContainer?: boolean;
    isLocked?: boolean;
    [key: string]: any;
}) {
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const { attributes, listeners, ref } = useContext(ColumnContext);

    const conditionalProps = !isLocked ? { ...listeners, ...attributes } : {};

    return (
        <Tooltip.Provider>
            <div
                ref={ref}
                {...conditionalProps}
                {...props}
                className={cn(
                    'group absolute top-0 left-0 w-full hover:bg-shade-3  z-20 pt-[24px] pl-[10px] rounded-t-[2rem]',
                    fillContainer ? 'h-full rounded-b-[2rem]' : 'h-[56px]'
                )}
                style={{
                    cursor: isLocked ? 'default' : 'grab'
                }}
            >
                <Tooltip.Root open={tooltipOpen}>
                    <Tooltip.Trigger asChild>
                        <div
                            onMouseEnter={() => setTooltipOpen(true)}
                            onMouseLeave={() => setTooltipOpen(false)}
                            className="group-hover:opacity-100 opacity-0 transition-opacity duration-100 linear"
                            style={{
                                width: 'fit-content'
                            }}
                        >
                            <DragAndDrop className="block" />
                        </div>
                    </Tooltip.Trigger>
                    <Tooltip.Content
                        style={{
                            padding: '.8rem',
                            borderRadius: '.8rem',
                            backgroundColor: 'var(--color-shade-h2)',
                            boxShadow: '0px 20px 30px 0px rgba(0, 0, 0, 0.2)'
                        }}
                    >
                        Click and drag to move
                    </Tooltip.Content>
                </Tooltip.Root>
            </div>
        </Tooltip.Provider>
    );
}

import ChartColors from '../constants/ChartColors';

let colorMap: any = {};

export function getChartColor(label: string) {
    if (!colorMap[label]) {
        colorMap[label] =
            ChartColors[Object.keys(colorMap).length % ChartColors.length];
    }
    return colorMap[label];
}

export function clear() {
    colorMap = {};
}

import { useContext, Fragment } from 'react';
import { LineChartContext } from './LineChart';
import { NodeGroup } from 'react-move';
import { ChartContext } from '../../contexts/ChartContext';
import { useAppSelector } from '../../store/hooks';
import Constants from '../../constants/Constants';
import getInterpolator from '../../lib/getInterpolator';

const Lines = () => {
    const {
        onHover,
        onHoverEnd,
        onToggle,
        chartState: { mode }
    } = useContext(ChartContext);

    const { chartData: data, dataProp } = useAppSelector(state => {
        switch (mode) {
            case 'measure': {
                return state.measure.goal;
            }
            default:
                return {} as any;
        }
    });

    const { avgValue, isFaded, lineFn, reportId } =
        useContext(LineChartContext);

    const baseClass = 'linechartv2';

    return (
        <NodeGroup
            keyAccessor={d => d.label}
            data={data}
            start={d => {
                const initial = d.series.map(({ date }: any) => ({
                    [dataProp]: avgValue,
                    date
                }));
                return {
                    opacity: 1,
                    series: initial
                };
            }}
            enter={d => {
                return {
                    series: [d.series],
                    timing: {
                        duration: Constants.AnimationDuration,
                        ease: Constants.EasingFn
                    }
                };
            }}
            update={d => {
                return {
                    series: [d.series],
                    opacity: [isFaded(d) ? 0.05 : 1],
                    timing: {
                        duration: Constants.AnimationDuration,
                        ease: Constants.EasingFn
                    }
                };
            }}
            interpolation={getInterpolator}
        >
            {nodes => (
                <g className={`${baseClass}__lines`}>
                    {nodes.map(
                        ({ state, data, key }) =>
                            !data.isEqualityIndex &&
                            !data.isAttritionIndex && (
                                <Fragment key={key}>
                                    <path
                                        className={`${baseClass}__line`}
                                        d={lineFn(state.series)}
                                        strokeOpacity={state.opacity}
                                        stroke={data.color}
                                        clipPath={`url(#${reportId}_main)`}
                                        onMouseOver={onHover.bind(
                                            null,
                                            data.label
                                        )}
                                        onMouseOut={onHoverEnd.bind(
                                            null,
                                            data.label
                                        )}
                                        onClick={onToggle.bind(
                                            null,
                                            data.label
                                        )}
                                    />
                                    <path
                                        className={`${baseClass}__line`}
                                        d={lineFn(state.series)}
                                        strokeOpacity={0.05}
                                        stroke={data.color}
                                        clipPath={`url(#${reportId}_fade)`}
                                    />
                                </Fragment>
                            )
                    )}
                </g>
            )}
        </NodeGroup>
    );
};

export default Lines;

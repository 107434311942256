import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
    const fill = props.fill || '#F35410';
    return (
        <svg
            width="88"
            height="88"
            viewBox="0 0 88 88"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <path
                d="M44.0158 46.75C47.4886 46.75 50.4472 45.5226 52.8917 43.0676C55.3361 40.6127 56.5583 37.6488 56.5583 34.1759C56.5583 30.7031 55.3308 27.7445 52.8759 25.3C50.421 22.8556 47.4571 21.6334 43.9842 21.6334C40.5114 21.6334 37.5528 22.8608 35.1083 25.3158C32.6639 27.7707 31.4417 30.7346 31.4417 34.2075C31.4417 37.6803 32.6691 40.6389 35.1241 43.0834C37.579 45.5278 40.5429 46.75 44.0158 46.75ZM44 80.575C34.9555 78.2528 27.8055 73.2863 22.55 65.6754C17.2944 58.0645 14.6667 49.5255 14.6667 40.0584V18.2417L44 7.2417L73.3333 18.2417V40.0584C73.3333 49.5255 70.7055 58.0645 65.45 65.6754C60.1944 73.2863 53.0444 78.2528 44 80.575ZM44 74.8917C47.6667 73.6695 50.9667 71.8514 53.9 69.4375C56.8333 67.0236 59.3694 64.2584 61.5083 61.1417C58.7786 59.857 55.9378 58.8789 52.9859 58.2074C50.034 57.5358 47.0345 57.2 43.9873 57.2C40.9402 57.2 37.9436 57.5358 34.9974 58.2074C32.0513 58.8789 29.2161 59.857 26.4917 61.1417C28.6305 64.2584 31.1667 67.0236 34.1 69.4375C37.0333 71.8514 40.3333 73.6695 44 74.8917Z"
                fill={fill}
            />
        </svg>
    );
};
export default SvgComponent;

import React, { useState } from 'react';
import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import { RouteComponentProps } from 'react-router';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { clearSelectedQuestions } from './editReducer';

export default function QuestionsHeader({
    history,
    location
}: RouteComponentProps) {
    const dispatch = useAppDispatch();
    const selectedQuestions = useAppSelector(
        state => state.datastudio.survey.surveyEdit.surveyToEdit.questions
    );

    const disableSave = selectedQuestions.length === 0;

    function handleClear() {
        dispatch(clearSelectedQuestions());
    }

    function handleAdd() {
        history.goBack();
    }

    return (
        <div className="survey-header">
            <div className="survey-header__title">Add questions</div>
            <div className="survey-header__actions">
                <Button
                    componentType={ButtonTypes.type.TERTIARY}
                    onClick={handleClear}
                >
                    Clear
                </Button>
                <Button
                    componentType={ButtonTypes.type.PRIMARY}
                    disabled={disableSave}
                    onClick={handleAdd}
                >
                    Add questions
                </Button>
            </div>
        </div>
    );
}

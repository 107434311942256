import React from 'react';
import PropTypes from 'prop-types';
import Dropdown from '../../common/Dropdown';
import ButtonTypes from '../../constants/ButtonTypes';

const OPTIONS = [
    {
        value: 'Month',
        label: 'Monthly'
    },
    {
        value: 'Quarter',
        label: 'Quarterly'
    },
    {
        value: 'Year',
        label: 'Yearly'
    }
];

export default function Interval({ interval, setIntervalInEditor }) {
    const selectedOption = interval
        ? OPTIONS.find(o => o.value === interval)
        : OPTIONS[0];
    return (
        <div className="editor__interval">
            <Dropdown
                componentType={ButtonTypes.type.SECONDARY}
                onClick={setIntervalInEditor}
                options={OPTIONS}
                selectedOption={selectedOption}
                shouldOpenUp={false}
            ></Dropdown>
        </div>
    );
}

Interval.propTypes = {
    interval: PropTypes.string,
    setIntervalInEditor: PropTypes.func
};

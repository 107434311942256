import { createSlice } from '@reduxjs/toolkit';
import ActionTypes from '../../constants/ActionTypes';
import { Actor, ActorRole, User } from './models';

interface IUsersState {
    allUsers: User[];
    usersLoaded: boolean;
    addUsersPending: boolean;
    deleteActorPending: boolean;
    adminAccess?: boolean;
    userQuery: string;
    updateActorPending: boolean;
}

const initialState: IUsersState = {
    allUsers: [],
    usersLoaded: false,
    addUsersPending: false,
    updateActorPending: false,
    deleteActorPending: false,
    adminAccess: undefined,
    userQuery: ''
};

const usersSlice = createSlice({
    name: 'users',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(ActionTypes.GetActorsPending, (state, _) => {
            state.usersLoaded = false;
        });
        builder.addCase(
            ActionTypes.GetActorsFulfilled,
            (state, action: any) => {
                state.usersLoaded = true;
                state.allUsers = action.actors;
            }
        );
        builder.addCase(ActionTypes.AssignRolePending, (state, _) => {});
        builder.addCase(
            ActionTypes.AssignRoleFulfilled,
            (state, action: any) => {
                const index = state.allUsers.findIndex(
                    (user: User) => user.actorId === action.actorId
                );

                if (
                    !state.allUsers[index].role.some(
                        (role: Actor['role'][0]) => role.id === action.roleId
                    )
                ) {
                    const newRole = action.allRoles.find(
                        (role: ActorRole) => role.id === action.roleId
                    );

                    state.allUsers[index].role = [
                        ...state.allUsers[index].role,
                        {
                            id: newRole.id,
                            name: newRole.name,
                            inheritedFrom: newRole.inheritedFrom
                        }
                    ];
                }
            }
        );
        builder.addCase(
            ActionTypes.AssignRoleOptimistic,
            (state, action: any) => {
                const index = state.allUsers.findIndex(
                    (user: User) => user.actorId === action.actorId
                );

                const newRole = action.allRoles.find(
                    (role: ActorRole) => role.id === action.roleId
                );

                state.allUsers[index].role = [
                    ...state.allUsers[index].role,
                    {
                        id: newRole.id,
                        name: newRole.name,
                        inheritedFrom: newRole.inheritedFrom
                    }
                ];
            }
        );
        builder.addCase(ActionTypes.AssignRoleRejected, () => {});
        builder.addCase(ActionTypes.UnassignRolePending, () => {});
        builder.addCase(
            ActionTypes.UnassignRoleFulfilled,
            (state, action: any) => {
                const index = state.allUsers.findIndex(
                    (user: User) => user.actorId === action.actorId
                );
                state.allUsers[index].role = state.allUsers[index].role.filter(
                    roleId => roleId !== action.roleId
                );
            }
        );
        builder.addCase(
            ActionTypes.UnassignRoleOptimistic,
            (state, action: any) => {
                const index = state.allUsers.findIndex(
                    (user: User) => user.actorId === action.actorId
                );
                state.allUsers[index].role = state.allUsers[index].role.filter(
                    (role: ActorRole) => role.id !== action.roleId
                );
            }
        );
        builder.addCase(ActionTypes.UnassignRoleRejected, () => {});
        builder.addCase(ActionTypes.GetActorsRejected, (state, _) => {
            state.usersLoaded = true;
        });
        builder.addCase(ActionTypes.AddUsersPending, (state, _) => {
            state.addUsersPending = true;
        });
        builder.addCase(ActionTypes.AddUsersFulfilled, (state, _) => {
            state.addUsersPending = false;
        });
        builder.addCase(ActionTypes.AddUsersRejected, (state, _) => {
            state.addUsersPending = false;
        });
        builder.addCase(ActionTypes.GrantFulfilled, (state, action: any) => {
            const index = state.allUsers.findIndex(
                (a: User) => a.actorId === action.actorId
            );
            state.allUsers[index] = {
                ...state.allUsers[index],
                role: action.role
            };
        });
        builder.addCase(
            ActionTypes.CreateActorFulfilled,
            (state, action: any) => {
                const actor: User = {
                    actorId: action.actorId,
                    email: action.email,
                    lightMode: action.lightMode,
                    role: []
                };
                state.allUsers.splice(1, 0, actor);
            }
        );
        builder.addCase(ActionTypes.DeleteActorPending, (state, _) => {
            state.deleteActorPending = true;
        });
        builder.addCase(
            ActionTypes.DeleteActorFulfilled,
            (state, action: any) => {
                const index = state.allUsers.findIndex(
                    a => a.actorId === action.actorId
                );
                state.allUsers.splice(index, 1);
                state.deleteActorPending = false;
            }
        );
        builder.addCase(ActionTypes.DeleteActorRejected, (state, _) => {
            state.deleteActorPending = false;
        });
        builder.addCase(ActionTypes.SignOutFulfilled, (state, _) => {
            state.adminAccess = undefined;
        });
        builder.addCase(ActionTypes.SearchUsers, (state, action: any) => {
            state.userQuery = action.query;
        });
        builder.addCase(ActionTypes.UpdateActorPending, (state, _) => {
            state.updateActorPending = true;
        });
        builder.addCase(
            ActionTypes.UpdateActorFulfilled,
            (state, action: any) => {
                if (action.actorId) {
                    const index = state.allUsers.findIndex(
                        a => a.actorId === action.actorId
                    );
                    state.allUsers[index] = {
                        ...state.allUsers[index]
                    };
                }
                state.updateActorPending = false;
            }
        );

        builder.addCase(ActionTypes.UpdateActorRejected, (state, _) => {
            state.updateActorPending = false;
        });
    }
});

export default usersSlice.reducer;

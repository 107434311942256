import { push } from 'connected-react-router';
import { useState } from 'react';

import Button from '../common/Button';
import Popover from '../common/Popover';
import ButtonTypes from '../constants/ButtonTypes';
import HMore from '../icons/HMoreVertical';
import PlusIcon from '../icons/Plus';
import cn from '../lib/cn';
import { useAppDispatch } from '../store/hooks';
import { resetChat } from './chat/reducer';
import ConversationMenu from './ConversationMenu';
import useConversations from './useConversations';

const ConversationsList = ({ id }: any) => {
    const dispatch = useAppDispatch();
    const { conversations } = useConversations();
    const [moreOpen, setMoreOpen] = useState(false);

    const handleNavigateToConversation = (conversationId: string) => {
        dispatch(resetChat());
        dispatch(push(`/ai/${conversationId}`));
    };
    return (
        <div className={cn('flex')}>
            <button
                key={id}
                onClick={() => handleNavigateToConversation(id)}
                className={cn(
                    'w-avail pl-[1.6rem] pr-[1.6rem] h-[40px] flex items-center justify-between',
                    'bg-shade-3 text-ui-75 rounded-[1rem]',
                    'hover:bg-shade-4 hover:text-ui-100'
                )}
            >
                <span className={cn('truncate mr-[1.6rem]')}>{conversations[id]?.name || id}</span>
            </button>
            <Popover
                key={`${id}-popover`}
                placement="bottomRight"
                visible={moreOpen}
                onVisibleChange={() => {
                    setMoreOpen(!moreOpen);
                }}
                content={
                    <ConversationMenu
                        conversationId={id}
                        conversationName={conversations[id]?.name || id}
                        onClose={() => setMoreOpen(false)}
                    />
                }
                overlayClassName="popover--no-padding"
            >
                <div className={cn('absolute right-[1.6rem] mt-[1rem] rounded-full')}>
                    <HMore className="icon" />
                </div>
            </Popover>
        </div>
    );
};

const LeftPanel = () => {
    const dispatch = useAppDispatch();
    const { conversationsIds } = useConversations();

    const handleNewChat = () => {
        dispatch(resetChat());
        dispatch(push('/ai'));
    };

    return (
        <div
            className={cn('absolute top-0 left-0 w-[320px] h-full pt-[60px] px-[2rem] pb-[1.6rem] vertical gap-10')}
            style={{
                background: 'var(--color-shade-h2)',
            }}
        >
            <Button onClick={handleNewChat} componentType={ButtonTypes.type.SECONDARY} classes={[cn('justify-start')]}>
                <div className={cn('flex items-center gap-[.8rem]')}>
                    <PlusIcon className={cn('flex-shrink-0')} />
                    <span>New chat</span>
                </div>
            </Button>
            <div className={cn('h-full overflow-scroll hide-scrollbar relative')}>
                <div className={cn('font-micro-text font-medium text-ui-75 mb-[1.6rem]')}>Recent</div>
                <div className={cn('vertical gap-[.8rem]')}>
                    {conversationsIds.map((id) => (
                        <ConversationsList id={id} />
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LeftPanel;

import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import hash from 'object-hash';
import qs from 'qs';
import { useEffect, useState } from 'react';
import Button from '../../common/Button';
import AnalysisTypes from '../../constants/AnalysisTypes';
import ButtonTypes from '../../constants/ButtonTypes';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import usePermissions from '../../hooks/usePermissions';
import getMixPanelObjectForFilters from '../../lib/getMixPanelObjectForFilters';
import { track } from '../../lib/segment';
import { getAllSegments } from '../../store/actionUtils';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { Template } from '../../templates/models';
import { AnalysisName } from '../../types';
import AnalysisDropdown from './AnalysisDropdown';
import SegmentDropdown from './SegmentDropdown';
import {
    clearFilters,
    generateInExplore,
    navigate,
    setAnalysisName,
    updateBreakdownSegment,
    updateFilterSegment,
    updateMainSegments,
} from './actions';
import './styles.scss';

interface FiltersProps {
    value: string;
    show: boolean;
    docked?: boolean;
}

function Filters({ value, show, docked }: FiltersProps) {
    const dispatch = useAppDispatch();
    const { dynamicTemplates } = useAppSelector((state) => state.templates);
    const { analysisNamesTree } = useAppSelector((state) => state.account);
    const initialAnalysisNames = getInitialAnalysisNames(analysisNamesTree!, dynamicTemplates);
    const { generatePending } = useAppSelector((state) => state.explore.chart);

    const {
        analysisName,
        analysisDisplayName,
        analysisType,
        mainSegments,
        mainNames,
        filterNames,
        breakdownNames,
        filterSegment,
        breakdownSegment,
        subAnalyses,
        attributes,
        isSavedSearch,
        generateDisabled,
    } = useAppSelector((state) => state.explore.filter);

    const disabled = !usePermissions({
        actions: [RbacActions['Explore/Generate']],
    });

    const [shouldOpen, setShouldOpen] = useState(false);
    const [valueParsed, setValueParsed] = useState(!value);
    const currentTemplate = dynamicTemplates.find((t) => t.analysisName === analysisName);

    function isSubAnalysis(analysisNames: AnalysisName[]): boolean {
        return analysisNames.some((a) => {
            if (a.name === analysisName && a.isLeaf) {
                return !!a.children;
            } else if (a.children) {
                return isSubAnalysis(a.children);
            } else {
                return false;
            }
        });
    }

    useEffect(() => {
        const shouldOpen =
            !docked &&
            !!analysisName &&
            valueParsed &&
            mainSegments.length === 0 &&
            !isSubAnalysis(initialAnalysisNames) &&
            !isSavedSearch;
        setShouldOpen(shouldOpen);
    }, [docked, valueParsed, analysisName, mainSegments]);

    useEffect(() => {
        if (value) {
            const query = qs.parse(value, {
                ignoreQueryPrefix: true,
                arrayLimit: 10000,
            });
            const {
                lifecycle,
                mainSegments: newMainSegments,
                filterSegment: newFilterSegment,
                breakdownSegment: newBreakdownSegment,
                subAnalyses: newSubAnalyses,
            } = query;
            const newHash = hash([lifecycle, newMainSegments, newFilterSegment, newBreakdownSegment, newSubAnalyses]);
            const oldHash = hash([analysisName, mainSegments, filterSegment, breakdownSegment, subAnalyses]);
            if (newHash !== oldHash) {
                let analysisDisplayName;

                if (newSubAnalyses?.length) {
                    analysisDisplayName =
                        (newSubAnalyses as any).length > 1
                            ? `${lifecycle} (${newSubAnalyses.length})`
                            : (newSubAnalyses as any)[0].name;
                } else {
                    analysisDisplayName = lifecycle;
                }

                dispatch(
                    setAnalysisName({
                        analysisDisplayName,
                        analysisName: lifecycle,
                        subAnalyses: newSubAnalyses || [],
                    })
                ).then(() => {
                    dispatch(updateMainSegments(newMainSegments as any));
                    if (newFilterSegment) {
                        dispatch(updateFilterSegment([newFilterSegment as any]));
                    } else {
                        dispatch(updateFilterSegment([]));
                    }
                    if (newBreakdownSegment) {
                        dispatch(updateBreakdownSegment([newBreakdownSegment as any]));
                    } else {
                        dispatch(updateBreakdownSegment([]));
                    }
                    setValueParsed(true);
                    dispatch(generateInExplore());
                });
            } else {
                dispatch(generateInExplore());
            }
        } else if (!docked) {
            dispatch(clearFilters());
        }
    }, [value]);

    useEffect(() => {
        if (mainSegments.length > 0) {
            handleSegmentReset();
        }
    }, [analysisName]);

    function handleGenerate() {
        const segments = getAllSegments({
            mainSegments,
            filterSegment,
            breakdownSegment,
        });
        const mixpanelObj = getMixPanelObjectForFilters(analysisName, segments, attributes);
        track(MixPanel.Events.ExploreGenerate, mixpanelObj);
        dispatch(navigate());
    }

    function handleClear() {
        track(MixPanel.Events.ExploreClear);
        dispatch(clearFilters());
    }

    function handleSegmentReset() {
        dispatch(updateMainSegments([]));
    }

    return (
        <div className="filters" style={{ visibility: show ? 'visible' : 'hidden' }}>
            <div className="filters__dropdowns">
                <AnalysisDropdown
                    initialAnalysisNames={initialAnalysisNames}
                    analysisDisplayName={analysisDisplayName!}
                    analysisName={analysisName}
                    analysisType={analysisType}
                    isSavedSearch={isSavedSearch}
                    disabled={disabled}
                    shouldOpen={false}
                    setAnalysisName={(props: any) => dispatch(setAnalysisName(props))}
                />
                <SegmentDropdown
                    placeholder="Segment"
                    attributes={attributes}
                    dynamicPermissionsFilter={(currentTemplate?.mainNames as any) || []}
                    availableSegmentNames={mainNames}
                    selection={mainSegments}
                    update={(segments) => dispatch(updateMainSegments(segments))}
                    disabled={!analysisName || disabled}
                    shouldOpen={shouldOpen}
                    setShouldOpen={setShouldOpen}
                />
                <SegmentDropdown
                    placeholder="Filter"
                    attributes={attributes}
                    dynamicPermissionsFilter={(currentTemplate?.filterNames as any) || []}
                    availableSegmentNames={filterNames}
                    selection={filterSegment ? [filterSegment] : []}
                    update={(segments) => dispatch(updateFilterSegment(segments))}
                    disabled={
                        (!filterSegment && currentTemplate?.filterNames?.length === 0) ||
                        !analysisName ||
                        mainSegments.length === 0 ||
                        disabled
                    }
                    useRadioOnSegment
                    useRadioOnValue
                    isSecondary
                />
                <SegmentDropdown
                    placeholder="Breakdown"
                    attributes={attributes}
                    dynamicPermissionsFilter={(currentTemplate?.breakdownNames as any) || []}
                    availableSegmentNames={breakdownNames}
                    selection={breakdownSegment ? [breakdownSegment] : []}
                    update={(segments) => dispatch(updateBreakdownSegment(segments))}
                    disabled={
                        (!breakdownSegment && currentTemplate?.breakdownNames?.length === 0) ||
                        !analysisName ||
                        mainSegments.length === 0 ||
                        disabled ||
                        analysisType === AnalysisTypes.Arithmetic
                    }
                    useRadioOnSegment
                    isSecondary
                />
            </div>
            {!disabled && (
                <div className="filters__buttons" data-test="filters-actions">
                    <Button componentType={ButtonTypes.type.TERTIARY} onClick={handleClear} tabIndex={-1}>
                        Clear
                    </Button>
                    <Button
                        disabled={!analysisName || !mainSegments || !mainSegments.length || generateDisabled}
                        onClick={handleGenerate}
                        loading={generatePending}
                        testId="generate"
                    >
                        <span style={{ opacity: generatePending ? 0 : 1 }}>Generate</span>
                    </Button>
                </div>
            )}
        </div>
    );
}

export function getAnalysisNamesFromTree(analysisNamesTree: AnalysisName[], templates: Template[]) {
    return analysisNamesTree?.map((analysisObj) => {
        const { name } = analysisObj;
        const newObj: AnalysisName = { name };
        if (analysisObj.children) {
            newObj.children = getAnalysisNamesFromTree(analysisObj.children, templates);
        } else {
            const template = templates.find((t) => t.analysisName === name);
            newObj.isLeaf = true;
            if (template) {
                newObj.isFunnel = template.supportedGraphs?.includes('funnel');
                if (!isEmpty(template.uiFieldMapping.subAnalysisFields)) {
                    newObj.children = Object.keys(template.uiFieldMapping.subAnalysisFields).map((k) => ({
                        name: k,
                        isSubAnalysis: true,
                        source: template.uiFieldMapping.subAnalysisFields[k].source,
                    }));
                }
            }
        }
        return newObj;
    });
}

export function getInitialAnalysisNames(analysisNamesTree: AnalysisName[], templates: Template[]): AnalysisName[] {
    const initialAnalysisNames = analysisNamesTree?.length
        ? getAnalysisNamesFromTree(analysisNamesTree, templates)
        : templates.map((t) => ({
              name: t.analysisName,
              isFunnel: t.supportedGraphs?.includes('funnel'),
              isLeaf: true,
              children: !isEmpty(t.uiFieldMapping.subAnalysisFields)
                  ? sortBy(
                        Object.keys(t.uiFieldMapping.subAnalysisFields).map<AnalysisName>((k) => ({
                            name: k,
                            isSubAnalysis: true,
                            ...t.uiFieldMapping.subAnalysisFields[k],
                        })),
                        'order'
                    )
                  : undefined,
          }));
    return initialAnalysisNames;
}

export default Filters;

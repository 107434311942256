import ActionTypes from '../constants/ActionTypes';
import sortBy from 'lodash/sortBy';
import { NotificationsState } from './models';

export default function notificationsReducer(
    state: NotificationsState = { list: [], unread: 0 },
    action: any
) {
    switch (action.type) {
        case ActionTypes.GetNotificationsFulfilled:
            return {
                ...state,
                list: sortBy(
                    action.notifications,
                    n => new Date(n.createdAt)
                ).reverse(),
                unread: action.notifications.filter(
                    (n: any) => n.status === 'New'
                ).length
            };
        case ActionTypes.MarkNotificationAsReadPending: {
            let { list } = state;
            if (!list) {
                return state;
            }
            const index = list.findIndex(
                (n: any) => n.notificationId === action.notificationId
            );
            if (index !== -1) {
                list = list.slice();
                list[index] = {
                    ...list[index],
                    status: 'Read'
                };
            }
            return {
                ...state,
                list,
                unread: (state?.unread ?? 1) - 1
            };
        }
        default:
            return state;
    }
}

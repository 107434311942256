import { useEffect, useState } from 'react';
import cn from '../../lib/cn';

interface ScrollBarProps {
    containerRef: any;
}

export default function ScrollBar({ containerRef }: ScrollBarProps) {
    const [scrolled, setScrolled] = useState(0);
    const [scrollBarOffset, setScrollBarOffset] = useState(0);
    const [thumbSize, setThumbSize] = useState(0);
    const [hidden, setHidden] = useState(false);

    useEffect(() => {
        if (!containerRef.current) return;

        const onScroll = () => {
            if (!containerRef.current) return;

            const scrollTop = containerRef.current.scrollTop;
            const scrollHeight = containerRef.current.scrollHeight;
            const clientHeight = containerRef.current.clientHeight;

            const progress = scrollTop / (scrollHeight - clientHeight);

            const thumbSize = (clientHeight / scrollHeight) * clientHeight;

            setScrollBarOffset(progress * (clientHeight - thumbSize));
            setThumbSize(thumbSize);
            setScrolled(scrollTop);
            if (clientHeight >= scrollHeight) {
                setHidden(true);
            } else {
                setHidden(false);
            }
        };

        containerRef.current.addEventListener('scroll', onScroll);

        const observer = new ResizeObserver(onScroll);

        observer.observe(containerRef.current);

        onScroll();

        return () => {
            containerRef.current?.removeEventListener('scroll', onScroll);
            observer?.disconnect();
        };
    }, []);

    return (
        <div
            className={cn(
                'absolute top-0 right-0 w-[1px] h-full rounded-[2rem] bg-shade-6',
                'group-hover:visible group-hover:opacity-100 invisible opacity-0 transition-opacity duration-300 linear',
                hidden && 'hidden'
            )}
            style={{
                transform: `translateY(${scrolled}px)`
            }}
        >
            <div
                className={cn(
                    'absolute top-0 left-0 w-full rounded-[2rem] bg-ui-100'
                )}
                style={{
                    height: thumbSize + 'px',
                    transform: `translateY(${scrollBarOffset}px)`,
                    transformOrigin: 'top'
                }}
            ></div>
        </div>
    );
}

import React from 'react';

const Notifications = (props: any) => (
    <svg
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 28 28"
        fill="currentColor"
        {...props}
    >
        <clipPath id="b">
            <path d="M14 6c3.3 0 6 2.7 6 6v6.1l2 1.9H6l2-1.9V12c0-3.3 2.7-6 6-6z" />
        </clipPath>
        <path
            d="M14 6c3.3 0 6 2.7 6 6v6.1l2 1.9H6l2-1.9V12c0-3.3 2.7-6 6-6z"
            clipPath="url(#b)"
            fill="none"
            stroke="currentColor"
            strokeWidth={4}
            strokeMiterlimit={10}
        />
        <g>
            <clipPath id="d">
                <path id="c" d="M12 21h4c0 1.1-.9 2-2 2s-2-.9-2-2z" />
            </clipPath>
            <path clipPath="url(#d)" d="M7 16h14v12H7z" />
        </g>
        <g>
            <path fill="none" d="M0 0h28v28H0z" />
        </g>
    </svg>
);

export default Notifications;

import { number } from 'prop-types';
import React from 'react';
import '../../../../../../sass/helpers/_variables.scss';
import { PieChartProps } from '../types';
import { getColorArrayFromCount, getSVG } from './utils';

/**
 * Thumbnail chart for a Pie chart
 */
const Pie = ({ count = 2, type }: PieChartProps) => {
    const [ChartSVG, setChartSVG] = React.useState<JSX.Element | null>(null);

    React.useEffect(() => {
        setChartSVG(getSVG(type, count));
    }, [count]);

    return (
        <svg width="100%" height="100%" viewBox="0 0 304 86" fill="none">
            <svg
                width="66"
                height="66"
                viewBox="-10 -10 66 66"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                overflow="visible"
            >
                {ChartSVG}
            </svg>
            <rect
                x="92"
                y="34"
                width="66"
                height="3"
                fill="var(--color-ui-50)"
                fillOpacity="0.2"
            />
            <rect
                x="92"
                y="49"
                width="42"
                height="3"
                fill="var(--color-ui-50)"
                fillOpacity="0.2"
            />
            <rect
                x="191"
                y="34"
                width="28"
                height="3"
                fill="var(--color-ui-50)"
                fillOpacity="0.2"
            />
            <rect
                x="191"
                y="49"
                width="28"
                height="3"
                fill="var(--color-ui-50)"
                fillOpacity="0.2"
            />
            <rect
                x="253"
                y="34"
                width="28"
                height="3"
                fill="var(--color-ui-50)"
                fillOpacity="0.2"
            />
            <rect
                x="253"
                y="49"
                width="28"
                height="3"
                fill="var(--color-ui-50)"
                fillOpacity="0.2"
            />
            <defs>
                <clipPath id="clip0_15_33">
                    <rect
                        width="66"
                        height="66"
                        fill="white"
                        transform="translate(10 10)"
                        className="animated__vertical-bar"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default Pie;

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={5}
        height={9}
        viewBox="0 0 5 9"
        fill="none"
        {...props}
    >
        <path fill="currentColor" d="M0 0h5v1H0zM0 4h5v1H0zM0 8h5v1H0z" />
    </svg>
);
export default SvgComponent;

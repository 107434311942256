import Menu from 'antd/lib/menu';
import { push } from 'connected-react-router';
import { useState } from 'react';
import { useParams } from 'react-router';

import { useAppDispatch } from '../store/hooks';
import EditConversationNameModal from './EditConversationNameModal';
import { deleteConversationId } from './chat/reducer';
import { deleteChatConversation } from './conversations/actions';

interface ConversationMenuProps {
    conversationId: string;
    conversationName: string;
    onClose: () => void;
}

function ConversationMenu({ conversationId, conversationName, onClose }: ConversationMenuProps) {
    const dispatch = useAppDispatch();
    const { conversationId: currentId } = useParams<any>();
    const [editNameModalOpen, setEditNameModalOpen] = useState(false);

    function handleEdit() {
        setEditNameModalOpen(true);
        onClose();
    }

    function handleDelete() {
        dispatch(deleteChatConversation(conversationId));
        if (conversationId === currentId) {
            dispatch(push(`/ai`));
            dispatch(deleteConversationId());
        }
        onClose();
    }

    return (
        <Menu className="widget-menu dashboards-menu" selectedKeys={[]}>
            <Menu.Item onClick={handleEdit}>Rename</Menu.Item>
            <Menu.Item onClick={handleDelete}>Delete</Menu.Item>
            <EditConversationNameModal
                isOpen={editNameModalOpen}
                onClose={() => setEditNameModalOpen(false)}
                conversationId={conversationId}
                conversationName={conversationName}
            />
        </Menu>
    );
}

export default ConversationMenu;

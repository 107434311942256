import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { RichUtils } from 'draft-js';
import TextAlignment from '../../icons/TextAlignment';
import Popover from 'antd/lib/popover';

const EditorOptions = ({
    editorState,
    updateFontStyle,
    updateTextAlignment,
    textAlignment,
    textChartDispatch,
    readOnly,
    chartContainerBoundingBox,
    setShouldSave
}) => {
    const currentBlockType = RichUtils.getCurrentBlockType(editorState);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (readOnly) {
            setVisible(false);
        } else if (editorState.getSelection().getHasFocus()) {
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [readOnly, editorState]);

    return (
        <Popover
            key={chartContainerBoundingBox.height}
            visible={visible}
            placement="bottomLeft"
            overlayClassName="popover-editor-options"
            overlayStyle={{
                width: `${chartContainerBoundingBox.width}px`
            }}
            content={
                <div className={classNames('textchart-editor', {})}>
                    <span className="textchart-editor__blur"></span>
                    <div className="textchart-editor__block">
                        <div className="textchart-editor__option">
                            <TextAlignment
                                className={classNames(
                                    'textchart-editor__alignment-icon',
                                    'textchart-editor__alignment-icon--rotated',
                                    {
                                        'textchart-editor__alignment-icon--active':
                                            textAlignment === 'flex-start'
                                    }
                                )}
                                onMouseDown={e =>
                                    updateTextAlignment(e, 'flex-start')
                                }
                            />

                            <TextAlignment
                                className={classNames(
                                    'textchart-editor__alignment-icon',
                                    'textchart-editor__alignment-icon--lm',
                                    {
                                        'textchart-editor__alignment-icon--active':
                                            textAlignment === 'flex-end'
                                    }
                                )}
                                onMouseDown={e =>
                                    updateTextAlignment(e, 'flex-end')
                                }
                            />
                        </div>
                        <div className="textchart-editor__option">
                            <h4
                                className={classNames(
                                    'textchart-editor__text',
                                    {
                                        'textchart-editor__text--active':
                                            currentBlockType === 'header-four'
                                    }
                                )}
                                onMouseDown={event =>
                                    updateFontStyle(event, 'header-four')
                                }
                            >
                                Small
                            </h4>
                            <h4
                                className={classNames(
                                    'textchart-editor__text',
                                    'textchart-editor__text--lm',
                                    {
                                        'textchart-editor__text--active':
                                            currentBlockType === 'header-three'
                                    }
                                )}
                                onMouseDown={event =>
                                    updateFontStyle(event, 'header-three')
                                }
                            >
                                Medium
                            </h4>
                            <h4
                                className={classNames(
                                    'textchart-editor__text',
                                    'textchart-editor__text--lm',
                                    {
                                        'textchart-editor__text--active': [
                                            'header-two',
                                            'unstyled'
                                        ].includes(currentBlockType)
                                    }
                                )}
                                onMouseDown={event =>
                                    updateFontStyle(event, 'header-two')
                                }
                            >
                                Large
                            </h4>
                        </div>
                    </div>
                    <div className="textchart-editor__block">
                        <h4
                            className="textchart-editor__text textchart-editor__text--primary"
                            onMouseDown={() => {
                                textChartDispatch({
                                    type: 'EXIT'
                                });
                                setShouldSave(true);
                            }}
                        >
                            Done
                        </h4>
                    </div>
                </div>
            }
        >
            <div
                className="textchart-editor-anchor"
                style={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: '1px'
                }}
            />
        </Popover>
    );
};

EditorOptions.propTypes = {
    updateFontStyle: PropTypes.func,
    editorState: PropTypes.object,
    updateTextAlignment: PropTypes.func,
    textAlignment: PropTypes.string,
    textChartDispatch: PropTypes.func,
    readOnly: PropTypes.bool,
    chartContainerBoundingBox: PropTypes.object,
    setShouldSave: PropTypes.func
};

export default EditorOptions;

import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import Menu from 'antd/lib/menu';
import { connect } from 'react-redux';
import { showDeleteDashboardModal, showShareModal } from './actions';
import { navigateToEditMode } from '../dashboard/view/actions';
import { track } from '../lib/segment';
import MixPanel from '../constants/MixPanel';
import WithPermissions from '../common/WithPermissions';
import RbacActions from '../constants/RbacActions';

function MoreMenu({
    dashboardId,
    dashboardName,
    onClose,
    showShareModal,
    showDeleteDashboardModal,
    navigateToEditMode,
    source,
}) {
    function handleShare() {
        const eventName =
            source === 'All'
                ? MixPanel.Events.MyDashboardsAllDashboardShare
                : MixPanel.Events.MyDashboardsRecentDashboardShare;
        track(eventName, {
            'Dashboard Name': dashboardName,
        });
        showShareModal(dashboardId);
        onClose();
    }

    function handleEdit() {
        const eventName =
            source === 'All'
                ? MixPanel.Events.MyDashboardsAllDashboardEdit
                : MixPanel.Events.MyDashboardsRecentDashboardEdit;
        track(eventName, {
            'Dashboard Name': dashboardName,
        });
        navigateToEditMode(dashboardId);
        onClose();
    }

    function handleDelete() {
        const eventName =
            source === 'All'
                ? MixPanel.Events.MyDashboardsAllDashboardDelete
                : MixPanel.Events.MyDashboardsRecentDashboardDelete;
        track(eventName, {
            'Dashboard Name': dashboardName,
        });
        showDeleteDashboardModal(dashboardId);
        onClose();
    }

    return (
        <WithPermissions actions={[RbacActions['Dashboards/Edit'], RbacActions['Dashboards/Share']]}>
            <Menu className="widget-menu dashboards-menu" selectedKeys={[]}>
                <WithPermissions actions={[RbacActions['Dashboards/Share']]}>
                    <WithPermissions actions={[RbacActions['Users/View']]}>
                        <WithPermissions actions={[RbacActions['Users/Edit']]}>
                            <Menu.Item onClick={handleShare}>Share</Menu.Item>
                        </WithPermissions>
                    </WithPermissions>
                </WithPermissions>
                <WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
                    <Menu.Item onClick={handleEdit}>Edit</Menu.Item>
                    <Menu.Item data-test="dashboard-delete" onClick={handleDelete}>
                        Delete
                    </Menu.Item>
                </WithPermissions>
            </Menu>
        </WithPermissions>
    );
}

MoreMenu.propTypes = {
    dashboardId: PropTypes.string,
    dashboardName: PropTypes.string,
    reportId: PropTypes.string,
    onClose: PropTypes.func,
    showDeleteDashboardModal: PropTypes.func,
    showShareModal: PropTypes.func,
    navigateToEditMode: PropTypes.func,
    role: PropTypes.string,
    source: PropTypes.string,
};

const dispatchProps = {
    showDeleteDashboardModal,
    showShareModal,
    navigateToEditMode,
};

export default connect(null, dispatchProps)(MoreMenu);

import ActionTypes from '../constants/ActionTypes';

interface ReducerState {
    open?: boolean;
    category?: string;
    analysisType?: string;
    singleSegment?: boolean;
}

export default function infoModalReducer(
    state: ReducerState = {},
    action: any
): ReducerState {
    switch (action.type) {
        case ActionTypes.ShowInfo: {
            const { category, analysisType, singleSegment } = action;
            return {
                open: true,
                category,
                analysisType,
                singleSegment
            };
        }
        case ActionTypes.CloseInfo:
            return {
                ...state,
                open: false
            };
        default:
            return state;
    }
}

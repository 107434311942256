import moment from 'moment';
import * as HoverCard from '@radix-ui/react-hover-card';

import Avatar from '../../../common/Avatar';
import AvatarTypes from '../../../constants/AvatarTypes';
import getAvatarFill from '../../../lib/getAvatarFill';
import Stack from '../../../common/Stack';

export function getActorName(actor: any, currentActorId: string) {
    let name = actor.firstName
        ? `${actor.firstName} ${actor.lastName}`
        : actor.email;

    if (actor.actorId === currentActorId) {
        name += ' (you)';
    }

    return name;
}
const User = ({ actor, currentActorId }: any) => {
    const actorName = getActorName(actor, currentActorId);

    return (
        <HoverCard.Root openDelay={100} closeDelay={100}>
            <HoverCard.Trigger>
                <Stack flexDirection="row" alignItems="center" gap=".8rem">
                    {actor.profilePictureURL ? (
                        <img
                            src={actor.profilePictureURL}
                            alt={actor.firstName}
                            style={{
                                '--size': '4.0rem',
                                width: 'var(--size)',
                                height: 'var(--size)',
                                borderRadius: '50%',
                                overflow: 'hidden'
                            }}
                        />
                    ) : (
                        <div
                            style={{
                                flex: '0 0 auto'
                            }}
                        >
                            <Avatar
                                type={AvatarTypes.type.DEFAULT}
                                colorCode={getAvatarFill(actor.email)}
                            />
                        </div>
                    )}

                    <span className="user-table__you">{actorName}</span>
                </Stack>
            </HoverCard.Trigger>
            <HoverCard.Content
                side="right"
                align="center"
                sideOffset={12}
                style={{
                    zIndex: '100'
                }}
            >
                <Card actor={actor} actorName={actorName} />
            </HoverCard.Content>
        </HoverCard.Root>
    );
};

export const Card = ({ actor, actorName }: any) => {
    return (
        <div
            style={{
                background: 'var(--color-shade-h4)',
                padding: '1.6rem',
                borderRadius: '.8rem',
                boxShadow:
                    '0 3px 6px -4px rgba(0, 0, 0, 0.48), 0 6px 16px 0 rgba(0, 0, 0, 0.32), 0 9px 28px 8px rgba(0, 0, 0, 0.2)'
            }}
        >
            <UserCardInfo actor={actor} actorName={actorName} />
        </div>
    );
};

export const UserCardInfo = ({ actor, actorName, avatarSize = 64 }: any) => {
    return (
        <Stack flexDirection="row" alignItems="center" gap="1.6rem">
            <Avatar
                type={AvatarTypes.type.DEFAULT}
                colorCode={getAvatarFill(actor.email)}
                size={avatarSize}
            />
            <Stack flexDirection="column" gap=".4rem">
                <span className="font-subtitle">{actorName}</span>
                <span
                    className="font-text"
                    style={{
                        color: 'var(--color-ui-50)'
                    }}
                >
                    {actor.email}
                </span>
                <div>
                    <span
                        style={{
                            color: 'var(--color-ui-50)'
                        }}
                    >
                        Last login:{' '}
                    </span>
                    <span>
                        {actor.lastSignIn
                            ? moment.utc(actor.lastSignIn).format('MM/DD/YY')
                            : 'N/A'}
                    </span>
                </div>
            </Stack>
        </Stack>
    );
};

export default User;

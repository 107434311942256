import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Mentions from 'antd/lib/mentions';
import sortBy from 'lodash/sortBy';
import measureText from '../lib/measureText';

export default class CommentMention extends Component {
    constructor(props) {
        super(props);
        this.state = {
            suggestions: props.actors
        };
        this.containerRef = React.createRef();
    }

    componentDidMount() {
        const mentionWidth = this.containerRef.current.offsetWidth - 22;
        this.setState({ mentionWidth });
    }

    handleSearchChange(value) {
        const searchValue = value.toLowerCase();
        let filtered = this.props.actors.filter((actor) => {
            const searchName =
                `${actor.firstName} ${actor.lastName}`.toLowerCase();
            return searchName.indexOf(searchValue) > -1 && actor.firstName;
        });
        filtered = sortBy(filtered, (f) => f.firstName);
        this.setState({ suggestions: filtered });
    }

    renderSuggestions() {
        const { suggestions } = this.state;
        return suggestions.map((suggestion) => (
            <Mentions.Option
                key={suggestion.actorId}
                value={suggestion.shortName}
            >
                <span>
                    {suggestion.firstName} {suggestion.lastName}
                </span>
            </Mentions.Option>
        ));
    }

    render() {
        const { type, placeholder, onChange, placement, value, mentionRef } =
            this.props;
        const { mentionWidth } = this.state;
        let rows = 4;
        if (value && mentionWidth) {
            const textWidth = measureText(value).width;
            const numberOfRows =
                Math.ceil(textWidth / mentionWidth) + (type === 'edit' ? 0 : 2);
            if (numberOfRows > rows) {
                rows = numberOfRows;
            }
        }
        return (
            <div ref={this.containerRef}>
                <Mentions
                    placeholder={placeholder}
                    placement={placement}
                    rows={rows}
                    notFoundContent="No one to tag"
                    value={value}
                    onChange={onChange}
                    onSearch={this.handleSearchChange.bind(this)}
                    getPopupContainer={() =>
                        document.getElementById('commentBar')
                    }
                    ref={mentionRef}
                >
                    {this.renderSuggestions()}
                </Mentions>
            </div>
        );
    }
}

CommentMention.defaultProps = {
    actors: [],
    onSelect: () => {},
    placement: 'bottom'
};

CommentMention.propTypes = {
    type: PropTypes.string,
    value: PropTypes.string,
    actors: PropTypes.array,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    placement: PropTypes.string,
    mentionRef: PropTypes.object
};

import { useRef, useEffect, useState } from 'react';
import { IChartXAxisProps } from '../lib/getChartDeclaration';
import getPeriod from '../lib/getPeriod';
import { scaleLinear } from 'd3-scale';
import classNames from 'classnames';
import MixPanel from '../constants/MixPanel';
import { track } from '../lib/segment';

const XAxis = (props: IChartXAxisProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [hiddenLabelIndexes, setHiddenLabelIndexes] = useState<number[]>([]);
    const {
        dates,
        interval,
        currentIndex,
        dashboardName,
        selectCurrentTime,
        title,
        innerChartOffsets,
        analysisType,
        indexType
    } = props;

    const xScale = scaleLinear<string>()
        .domain([dates[0], dates[dates.length - 1]])
        .range(['0%', '100%']);

    function getTextAlignment(index: number) {
        if (index === dates.length - 1) {
            return '-100%';
        } else if (index > 0) {
            return '-50%';
        }
        return '0%';
    }

    function handleTimeSelect(index: number, e: React.MouseEvent) {
        e.stopPropagation();
        if (index !== currentIndex) {
            track(MixPanel.Events.DashboardReportLineGraphXAxisClick, {
                'Dashboard Name': dashboardName,
                'Report Name': title
            });
            //setHoverIndex(undefined);
            selectCurrentTime(index);
        }
    }

    useEffect(() => {
        function recursivelyCalculateSkippedIndexes(
            dates: Date[],
            width: number,
            minimumLabelWidth = 100,
            skips = 0
        ): any {
            if (!dates || dates.length === 0 || skips >= 20) return;

            const newDates = dates.filter(
                (_, index) => index % (skips + 1) === 0
            );
            if (width / newDates.length >= minimumLabelWidth) return skips;

            return recursivelyCalculateSkippedIndexes(
                dates,
                width,
                minimumLabelWidth,
                skips + 1
            );
        }

        function onResize() {
            if (!dates || !ref.current) return;
            const width = ref.current.getBoundingClientRect().width;
            const skips = recursivelyCalculateSkippedIndexes(dates, width);

            const hiddenArray: any[] = [];

            if (skips > 0) {
                Array.from({ length: dates.length }, (_, i) =>
                    (i + 1) % (skips + 1) === 1 ? 1 : 0
                )
                    .reverse()
                    .forEach((visible, index) => {
                        if (!visible) hiddenArray.push(index);
                    });

                setHiddenLabelIndexes(hiddenArray);
            }
        }

        onResize();

        const observer = new ResizeObserver(onResize);
        if (ref.current) {
            observer.observe(ref.current);
        }

        return () => {
            observer.disconnect();
        };
    }, [ref, dates]);

    return (
        <div
            className={classNames('x-axis', {
                [`x-axis--${analysisType?.toLowerCase().split(' ').join('-')}`]:
                    indexType !== 'percentage'
            })}
            style={
                {
                    '--right-offset': innerChartOffsets.right + 'px'
                } as React.CSSProperties
            }
            ref={ref}
        >
            <div className="x-axis__inner">
                {dates.map((date, index) => (
                    <div
                        key={index}
                        onClick={e => handleTimeSelect(index, e)}
                        className={classNames('x-axis__label', {
                            'x-axis__label--active': index === currentIndex
                        })}
                        style={
                            {
                                '--translateX': getTextAlignment(index),
                                '--x': xScale(date)
                            } as React.CSSProperties
                        }
                        data-is-hidden={
                            hiddenLabelIndexes.includes(index) &&
                            currentIndex !== index
                        }
                    >
                        <svg
                            fill="currentColor"
                            viewBox="0 0 18 18"
                            width="18"
                            height="18"
                            className="x-axis__arrow"
                            style={
                                {
                                    '--translateX': getTextAlignment(index)
                                } as React.CSSProperties
                            }
                        >
                            <polygon points="9,12 6,7 12,7"></polygon>
                        </svg>
                        <div className="x-axis__text">
                            {getPeriod(
                                date,
                                interval.toLowerCase(),
                                hiddenLabelIndexes.length > 0
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default XAxis;

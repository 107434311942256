import React from 'react';
import PropTypes from 'prop-types';

export default function GridRow({ rowHeight, children, generatePending }) {
    return (
        <div
            className="grid__row"
            style={{
                height: generatePending ? undefined : rowHeight
            }}
        >
            {children}
        </div>
    );
}

GridRow.propTypes = {
    isTableRow: PropTypes.bool,
    generatePending: PropTypes.bool,
    rowHeight: PropTypes.number,
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ])
};

import { DynamicPermission } from '../admin/permissions/model';
import stripSpaces from '../lib/stripSpaces';
import { ConditionsAsObject } from './models';

export function getParts(condition: string): any[] {
    const parts = condition.split('.');
    return parts.slice(1, parts.length);
}

export function handleLeftRight(condition: DynamicPermission['conditions'][0]) {
    const array = [];

    if (condition.to.Left) {
        array.push(condition.to.Left.value);
    } else {
        array.push(...condition.to.Right.value);
    }

    return array;
}

export function getAllowedTemplates(
    dynamicPermissions: DynamicPermission[]
): string[] {
    return dynamicPermissions
        .map((dp: DynamicPermission) => {
            const analysisNames: any = [];

            dp.conditions.forEach(condition => {
                if (condition.propertyPath.includes('analysisName')) {
                    analysisNames.push(...handleLeftRight(condition));
                }
            });

            return analysisNames;
        })
        .flat();
}

export function getTemplateFilter(
    dynamicPermissions: DynamicPermission[]
): ConditionsAsObject {
    const mainVariables = ['field1', 'field2', 'field3'];
    const filterVariables = ['field4'];
    const breakdownVariables = ['field5', 'segmentField'];
    const combinedConditions = dynamicPermissions.reduce(
        (acc: any[], curr) => [...acc, ...curr.conditions],
        []
    );

    const grouped = combinedConditions.reduce(
        (obj, item: DynamicPermission['conditions'][0]) => {
            const parts = getParts(item.propertyPath);
            const type:
                | 'analysisName'
                | 'fieldName'
                | 'fieldValues'
                | 'segmentField' = parts[parts.length - 1];
            const name:
                | 'field1'
                | 'field2'
                | 'field3'
                | 'field4'
                | 'field5'
                | 'segmentField'
                | 'analysisName' = parts[0];

            if (type === 'analysisName') {
                obj[name] = [...(obj[name] ?? []), ...handleLeftRight(item)];
            }
            if (type === 'fieldName') {
                obj[name].fieldName = [
                    ...(obj[name]?.fieldName ?? []),
                    ...handleLeftRight(item).filter(v => v !== '')
                ];
            } else if (type === 'fieldValues') {
                obj[name].fieldValues = [
                    ...(obj[name]?.fieldValues ?? []),
                    ...handleLeftRight(item).filter(v => v !== '')
                ];
            }

            return obj;
        },
        {
            analysisName: [],
            field1: {
                fieldName: [],
                fieldValues: []
            },
            field2: {
                fieldName: [],
                fieldValues: []
            },
            field3: {
                fieldName: [],
                fieldValues: []
            },
            field4: {
                fieldName: [],
                fieldValues: []
            },
            field5: {
                fieldName: [],
                fieldValues: []
            },
            segmentField: {
                fieldName: [],
                fieldValues: []
            }
        }
    );

    const newObject = Object.keys(grouped).reduce(
        (acc: ConditionsAsObject, curr) => {
            if (mainVariables.includes(curr)) {
                acc.mainVariableFields = [
                    ...acc.mainVariableFields,
                    ...grouped[curr].fieldName?.map((fn: string) => ({
                        fieldName: stripSpaces(fn),
                        fieldValues: grouped[curr].fieldValues
                    }))
                ];
            } else if (filterVariables.includes(curr)) {
                acc.filterVariableFields = [
                    ...acc.filterVariableFields,
                    ...grouped[curr].fieldName?.map((fn: string) => ({
                        fieldName: stripSpaces(fn),
                        fieldValues: grouped[curr].fieldValues
                    }))
                ];
            } else if (breakdownVariables.includes(curr)) {
                acc.breakdownVariableFields = [
                    ...acc.breakdownVariableFields,
                    ...grouped[curr].fieldName?.map((fn: string) => ({
                        fieldName: stripSpaces(fn),
                        fieldValues: grouped[curr].fieldValues
                    }))
                ];
            } else if (curr === 'analysisName') {
                acc.analysisNames = [...acc.analysisNames, ...grouped[curr]];
            }
            return acc;
        },
        {
            analysisNames: [],
            mainVariableFields: [],
            filterVariableFields: [],
            breakdownVariableFields: []
        } as ConditionsAsObject
    );

    return newObject;
}

export function getDynamicPermissionsByName(
    name: string,
    dynamicPermissions: DynamicPermission[]
): DynamicPermission[] {
    return dynamicPermissions.filter((dynamicPermission: DynamicPermission) => {
        const analysisName = dynamicPermission.conditions.find(condition =>
            condition.propertyPath.includes('analysisName')
        );

        const analysisNamesValues = handleLeftRight(analysisName!);

        return (
            analysisNamesValues.includes(name) ||
            analysisNamesValues.includes('*')
        );
    });
}

import React from 'react';

const Edit = (props: any) => (
    <svg viewBox="0 0 28 28" fill="currentColor" {...props}>
        <path d="M7 18v3h3l9-9-3-3zM17.024 7.992l1.984-1.976 2.964 2.976-1.984 1.976z" />
        <g>
            <path fill="none" d="M0 0h28v28H0z" />
        </g>
    </svg>
);

export default Edit;

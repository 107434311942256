import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={334}
        height={334}
        viewBox="0 0 334 334"
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M81.252 246.967 41.75 207.465l7.387-7.386 31.847 31.767 60.672-60.671 7.386 7.734-67.79 68.058Zm0-100.628L41.75 106.837l7.387-7.386 31.847 31.767 60.672-60.671 7.386 7.734-67.79 68.058Zm99.932 76.328v-10.705h111.334v10.705H181.184Zm0-100.629v-10.705h111.334v10.705H181.184Z"
        />
    </svg>
);
export default SvgComponent;

import { forwardRef } from 'react';
import cn from '../lib/cn';

interface IScrollableChartAreaProps {
    children: any;
    disableOverflow?: boolean;
    className?: string;
}

const ScrollableChartArea = forwardRef<
    HTMLDivElement,
    IScrollableChartAreaProps
>(({ children, disableOverflow, className }, ref) => {
    return (
        <div
            ref={ref}
            className={cn('scrollable-area', className)}
            style={{
                overflow: disableOverflow ? 'unset' : 'auto'
            }}
        >
            {children}
        </div>
    );
});

export default ScrollableChartArea;

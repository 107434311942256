import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={24}
            height={24}
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M3.5 19.49l6-6.01 4 4L22 7.92l-1.41-1.41-7.09 7.97-4-4L2 17.99l1.5 1.5z"
                fill="currentColor"
            />
        </svg>
    );
}

export default SvgComponent;

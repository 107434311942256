import { combineReducers } from 'redux';
import chatReducer from './chat/reducer';
import conversationsReducer from './conversations/reducer';
import insightsReducer from './insights/reducer';

export default combineReducers({
    conversations: conversationsReducer, // this should hold the list of conversations that exist and be fetched all on initial load
    chat: chatReducer, // this would hold the infoformation about the *current* chat, i.e what you see in the chat.
    insights: insightsReducer,
});

import { Fragment, useEffect } from 'react';
import { useParams } from 'react-router';
import Stack from '../../../common/Stack';
import WithLoadingStates from '../../../common/WithLoadingStates';
import WithPermissions from '../../../common/WithPermissions';
import RbacActions from '../../../constants/RbacActions';
import precisionRound from '../../../lib/precisionRound';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getAudiences } from '../../audiences/list/actions';
import { Audience } from '../../audiences/types';
import ContentWrapper from '../../components/ContentWrapper';
import { cadenceOptions, privacyOptions, storageOptions } from '../constants';
import { getEnterpriseSurveys, getSurveyMetrics } from '../list/actions';
import { Survey } from '../list/types';
import moment from 'moment';
import { sortBy } from 'lodash';

const formatter = new Intl.DateTimeFormat('en-US', {});
const numberFormatter = new Intl.NumberFormat('en-US');

const roundTcr = (tcr: any) => {
    if (!tcr?.value || tcr?.value <= -1) {
        return 0;
    }

    return precisionRound(tcr.value * 100, 1);
};

const Campaign = () => {
    const dispatch = useAppDispatch();
    const params = useParams<any>();

    const { data, loaded, error, pending } = useAppSelector(
        state => state.snapshotSurveys.list
    );
    const {
        data: audiences,
        loaded: audiencesLoaded,
        pending: audiencesPending,
        error: audiencesError
    } = useAppSelector(state => state.audiences.list);

    const survey = data.find((survey: Survey) => survey.surveyId === params.id);
    const {
        tcr,
        tfcnt,
        tscnt,
        pending: metricsPending,
        loaded: metricsLoaded,
        error: metricsError
    } = useAppSelector(
        state => state.snapshotSurveys.list.metrics[params.id] || {}
    );

    useEffect(() => {
        if (!loaded && !pending && !error) {
            dispatch(getEnterpriseSurveys());
        }
    }, [data, loaded, error, pending]);

    useEffect(() => {
        if (!audiencesLoaded && !audiencesPending && !audiencesError) {
            dispatch(getAudiences());
        }
    }, [audiencesLoaded, audiencesPending, audiencesError]);

    useEffect(() => {
        if (!metricsPending && !metricsLoaded && !metricsError) {
            dispatch(getSurveyMetrics(params.id));
        }
    }, [metricsPending, metricsLoaded, metricsError]);

    if (!survey) {
        return null;
    }

    const audience: any = audiences.find(
        (audience: Audience) => audience.audienceId === survey?.audienceId
    );

    const reminders = sortBy(
        survey.meta.mailRemindersHistory,
        r => -moment(r.startedAt).valueOf()
    );

    return (
        <WithPermissions
            actions={[RbacActions['Campaign/View']]}
            showMessage={true}
        >
            <ContentWrapper>
                <WithLoadingStates
                    isEmpty={!survey}
                    isLoading={pending}
                    isError={!!error}
                >
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '3fr 2fr',
                            gap: '1.6rem'
                        }}
                    >
                        <WithPermissions
                            actions={[RbacActions['Campaign/ViewMetrics']]}
                        >
                            <Stack gap="1.6rem">
                                {!audience ? (
                                    <BigStat label="Total submissions">
                                        <div className="display-medium">
                                            {tscnt?.value && tscnt?.value > -1
                                                ? numberFormatter.format(
                                                      tscnt?.value
                                                  )
                                                : 0}
                                        </div>
                                    </BigStat>
                                ) : (
                                    <Fragment>
                                        <BigStat label="Completion rate">
                                            <div className="display-medium">
                                                {roundTcr(tcr)} %
                                            </div>
                                        </BigStat>
                                        <BigStat label="Responses">
                                            <div className="display-medium">
                                                <span>
                                                    {tscnt?.value &&
                                                    tscnt?.value > -1
                                                        ? numberFormatter.format(
                                                              tscnt?.value
                                                          )
                                                        : 0}
                                                </span>
                                            </div>
                                        </BigStat>
                                    </Fragment>
                                )}
                            </Stack>
                        </WithPermissions>
                        <Stack>
                            <SmallStat
                                label="Published"
                                value={
                                    !!survey.publishDate
                                        ? formatter.format(
                                              new Date(survey.publishDate)
                                          )
                                        : '-'
                                }
                            />
                            <SmallStat
                                label="End date"
                                value={formatter.format(
                                    new Date(survey.endDate)
                                )}
                            />
                            <SmallStat
                                label="Privacy"
                                // @ts-ignore
                                value={
                                    privacyOptions.find(
                                        // @ts-ignore
                                        o => o.id === survey.privacy.type
                                    )?.label
                                }
                            />
                            <SmallStat
                                label="Audience"
                                value={audience?.title || '-'}
                            />
                            <SmallStat
                                label="Distribution"
                                value={
                                    !audience?.audienceId
                                        ? 'Link'
                                        : 'Dandi email'
                                }
                            />
                            <SmallStat label="Reminders sent">
                                <div className="vertical-scrollbar">
                                    {reminders.map(r => (
                                        <div
                                            className="font-text"
                                            style={{
                                                color: 'var(--color-ui-100)'
                                            }}
                                        >
                                            {moment(r.startedAt).format(
                                                'MMM DD, YYYY'
                                            )}
                                        </div>
                                    ))}
                                </div>
                            </SmallStat>
                            <SmallStat
                                label="Data storage"
                                // @ts-ignore
                                value={
                                    storageOptions.find(
                                        // @ts-ignore
                                        o => o.id === survey.storage.type
                                    )?.title
                                }
                            />
                            <SmallStat
                                label="Questions"
                                value={survey.categories
                                    ?.reduce((acc, curr) => {
                                        return acc + curr.questions.length;
                                    }, 0)
                                    .toString()}
                            />
                            <SmallStat
                                label="Cadence"
                                // @ts-ignore
                                value={
                                    cadenceOptions.find(
                                        o => o.id === survey.cadence.type
                                    )?.label
                                }
                            />
                        </Stack>
                    </div>
                </WithLoadingStates>
            </ContentWrapper>
        </WithPermissions>
    );
};

const BigStat = ({ label, children }: { label: string; children: any }) => {
    return (
        <Stack
            justifyContent="space-between"
            style={{
                padding: '1.6rem',
                borderRadius: '.8rem',
                background: 'var(--color-shade-3)',
                height: '20rem',
                width: '100%'
            }}
        >
            <div>{label}</div>
            <div>{children}</div>
        </Stack>
    );
};

const SmallStat = ({
    label,
    value,
    children
}: {
    label: string;
    value?: string;
    children?: any;
}) => {
    return (
        <Stack
            flexDirection="row"
            justifyContent="space-between"
            alignItems="center"
            style={{
                borderTop: '1px solid var(--color-ui-10)',
                padding: '2.0rem 0'
            }}
        >
            <div
                style={{
                    color: 'var(--color-ui-50)'
                }}
            >
                {label}
            </div>
            {value ? <div>{value}</div> : <div>{children}</div>}
        </Stack>
    );
};

export default Campaign;

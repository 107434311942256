import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
    const fill = props.fill || 'var(--color-ui-50)';
    const width = props.width || '20';
    const height = props.height || '20';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            {...props}
        >
            <path
                fill={fill}
                d="M11.7167 9.4802L5.40319 3.16666H15.0417C15.3716 3.16666 15.6091 3.3118 15.7542 3.60208C15.8994 3.89235 15.873 4.16944 15.6751 4.43333L11.7167 9.4802ZM15.6553 17.8917L11.0834 13.3198V15.0417C11.0834 15.266 11.0075 15.454 10.8558 15.6057C10.7041 15.7575 10.516 15.8333 10.2917 15.8333H8.7084C8.48409 15.8333 8.29607 15.7575 8.14434 15.6057C7.9926 15.454 7.91673 15.266 7.91673 15.0417V10.1531L1.1084 3.34478L2.21673 2.21666L16.7834 16.7833L15.6553 17.8917Z"
            />
        </svg>
    );
};
export default SvgComponent;

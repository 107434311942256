import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import * as uuid from 'uuid';
import produce from 'immer';
import { Package } from './model';
import { getPackages, savePackage } from './actions';
import { getTemplates } from '../template/actions';
import TextField from '../../common/TextField';
import Dropdown from '../../common/Dropdown';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { TemplateFragment } from '../template/TemplateFragment';
import { Template } from '../template/model';
import { datasourceOptions } from '../constants';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

function PackageEdit({ history, match }: RouteComponentProps<{ id: string }>) {
    const dispatch = useAppDispatch();
    const id = match.params.id;
    const { loaded, data } = useAppSelector(state => state.datastudio.packages);
    const shouldFetch = !loaded;
    const packageObj = data?.find((c: any) => c.packageId === id);
    const { loaded: templatesLoaded, data: templates } = useAppSelector(
        state => state.datastudio.templates
    );
    const shouldFetchTemplates = !templatesLoaded;

    const [packageName, setPackageName] = useState(
        packageObj?.packageName || ''
    );
    const [datasource, setDatasource] = useState(packageObj?.dataSource);
    const [packageTemplates, setPackageTemplates] = useState(
        packageObj?.templates.slice() || []
    );
    const [error, setError] = useState('');
    const [templateId, setTemplateId] = useState();

    useEffect(() => {
        if (shouldFetch) {
            dispatch(getPackages());
        }
        if (shouldFetchTemplates) {
            dispatch(getTemplates());
        }
    }, [shouldFetch, shouldFetchTemplates]);

    useEffect(() => {
        if (packageObj) {
            setPackageName(packageObj.packageName);
            setDatasource(packageObj.dataSource);
            setPackageTemplates(packageObj.templates.slice());
        }
    }, [packageObj]);

    const templateOptions =
        templates?.map(t => ({
            value: t.templateId,
            label: t.templateName || t.templateId
        })) || [];

    const selectedTemplate = templateOptions.find(t => t.value === templateId);

    function handleSave() {
        if (!packageName || !datasource) {
            setError('Please enter all the fields');
            return;
        }

        const objToSave = {
            packageId: packageObj?.packageId || uuid.v4(),
            packageName,
            dataSource: datasource,
            templates: packageTemplates
        };
        dispatch(savePackage(objToSave));
        history.push(`/datastudio/packages/${objToSave.packageId}`);
    }

    function handleAddTemplate() {
        const packageTemplate = packageTemplates.find(
            t => t.templateId === templateId
        );
        if (!packageTemplate) {
            const template = templates?.find(t => t.templateId === templateId);
            if (template) {
                setPackageTemplates(
                    produce(packageTemplates, draftState => {
                        draftState.push(template);
                    })
                );
            }
        }
        setTemplateId(undefined);
    }

    function handleRemoveTemplate(templateId: string) {
        setPackageTemplates(
            produce(packageTemplates, draftState => {
                const templateIndex = draftState.findIndex(
                    t => t.templateId === templateId
                );
                if (templateIndex >= 0) {
                    draftState.splice(templateIndex, 1);
                }
            })
        );
    }

    function handleCancel() {
        history.goBack();
    }

    return (
        <div className="ds-container">
            <div className="ds-flex">
                <Button
                    componentType={ButtonTypes.type.PRIMARY}
                    onClick={handleSave}
                >
                    Save
                </Button>
                <Button
                    componentType={ButtonTypes.type.SECONDARY}
                    style={{ marginLeft: 8 }}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </div>
            {error && <div className="ds-error">{error}</div>}
            <div className="ds-label">Package id</div>
            <div className="ds-value">{packageObj?.packageId || 'New'}</div>
            <div className="ds-label">Package name</div>
            <div className="ds-input">
                <TextField
                    value={packageName}
                    onChange={e => setPackageName(e.target.value)}
                />
            </div>
            <div className="ds-label">Data Source</div>
            <div className="ds-input">
                <Dropdown
                    placeholder="Select"
                    options={datasourceOptions}
                    selectedOption={datasource}
                    onClick={(value: any) => setDatasource(value)}
                />
            </div>
            <div className="ds-label">Templates</div>
            <div className="ds-input">
                <Dropdown
                    placeholder="Select"
                    options={templateOptions}
                    selectedOption={selectedTemplate}
                    onClick={(value: any) => setTemplateId(value)}
                />
                <Button
                    componentType={ButtonTypes.type.SECONDARY}
                    onClick={handleAddTemplate}
                    classes={['ds-add-template']}
                    disabled={!templateId}
                >
                    Add Template
                </Button>
            </div>
            {packageTemplates?.map(t => (
                <TemplateFragment
                    key={t.templateId}
                    template={t}
                    expandable
                    removable
                    onRemove={() => handleRemoveTemplate(t.templateId)}
                />
            ))}
        </div>
    );
}

export default PackageEdit;

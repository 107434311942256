import { UniqueIdentifier } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';
import Draggable from './Draggable';
import Droppable from './Droppable';

interface Props {
    id: UniqueIdentifier;
    draggingFullContainer?: boolean;
    disabled?: boolean;
}

export default function DndColumn({
    children,
    id,
    disabled
}: PropsWithChildren<Props>) {
    return (
        <Droppable id={id} disabled={disabled}>
            <Draggable id={id}>
                <div className="relative h-full w-full px-[10px] pointer-events-none z-10">
                    <div className="relative w-full h-full ">
                        <div className="w-full h-full rounded-[2rem] pointer-events-auto bg-shade-h3 group overflow-hidden">
                            {children}
                        </div>
                    </div>
                </div>
            </Draggable>
        </Droppable>
    );
}

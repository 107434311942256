import firebase from './firebase';

export default async function uploadImage(
    folderName: string,
    imageName: string,
    image: string
) {
    const imageRef = firebase
        .storage()
        .ref()
        .child(folderName)
        .child(imageName);
    await imageRef.putString(image, 'data_url');
    return await imageRef.getDownloadURL();
}

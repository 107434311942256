import React from 'react';

const Download = (props: any) => (
    <svg
        width="12"
        height="15"
        viewBox="0 0 12 15"
        fill="currentColor"
        {...props}
    >
        <path
            d="M5 0v8.17L1.41 4.58 0 6l6 6 5.999-6-1.41-1.41L7 8.17V0H5zM0 15h12v-2H0v2z"
            fillRule="evenodd"
        />
    </svg>
);

export default Download;

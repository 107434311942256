import { useContext } from 'react';
import { LineChartContext } from './LineChart';
import { ChartContext } from '../../contexts/ChartContext';
import { useAppSelector } from '../../store/hooks';
import precisionRound from '../../lib/precisionRound';

const CurrentIndexLabel = () => {
    const {
        chartState: { mode }
    } = useContext(ChartContext);

    const { chartData, currentIndex, dataProp } = useAppSelector(state => {
        switch (mode) {
            case 'measure': {
                return state.measure.goal;
            }
            default:
                return {} as any;
        }
    });

    const { xScale, yScale, yAxisValues } = useContext(LineChartContext);

    const baseClass = 'linechartv2';

    return (
        <line
            x1={xScale(chartData[0].series[currentIndex].date)}
            y1={
                yScale(
                    precisionRound(
                        chartData[0].series[currentIndex][dataProp],
                        1
                    )
                ) + 4
            }
            x2={xScale(chartData[0].series[currentIndex].date)}
            y2={yScale(yAxisValues[0]) + 24}
            stroke="var(--color-ui-50)"
            strokeWidth={1}
            className={`${baseClass}__current-indicator`}
        />
    );
};

export default CurrentIndexLabel;

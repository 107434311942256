import ActionTypes from '../constants/ActionTypes';
import { AuthState } from './models';

const initialState = {
    loggedIn: false,
    userInfo: {},
    staticPermissions: [],
    dynamicPermissions: [],
    flags: {},
    role: []
};

export default function authReducer(
    state: AuthState = initialState,
    action: any
): AuthState {
    switch (action.type) {
        case ActionTypes.LoginPending:
            return {
                ...state,
                loginPending: true,
                loggedIn: false,
                loginError: false
            };
        case ActionTypes.LoginFulfilled:
            return {
                ...state,
                loggedIn: true,
                userInfo: {
                    ...state.userInfo,
                    ...action.userInfo
                },
                loginPending: false
            };
        case ActionTypes.GetAuthorizationTokenPending:
            return {
                ...state,
                authorizationTokenPending: true
            };
        case ActionTypes.GetAuthorizationTokenFulfilled:
            return {
                ...state,
                authorizationTokenPending: false
            };

        case ActionTypes.GetAuthorizationTokenRejected:
            return {
                ...state,
                authorizationTokenPending: false
            };
        case ActionTypes.LoginRejected:
            return {
                ...state,
                loggedIn: false,
                loginError: true,
                loginPending: false
            };
        case ActionTypes.CompleteSetupPending:
            return {
                ...state,
                setupPending: true
            };
        case ActionTypes.CompleteSetupFulfilled:
            return {
                ...state,
                setupPending: false
            };
        case ActionTypes.CompleteSetupRejected:
            return {
                ...state,
                setupPending: false
            };
        case ActionTypes.CompleteEditPending:
            return {
                ...state,
                editPending: true
            };
        case ActionTypes.CompleteEditFulfilled:
            return {
                ...state,
                editPending: false
            };
        case ActionTypes.CompleteEditRejected:
            return {
                ...state,
                editPending: false
            };
        case ActionTypes.ChangePasswordPending:
            return {
                ...state,
                passwordPending: true
            };
        case ActionTypes.ChangePasswordFulfilled:
            return {
                ...state,
                passwordPending: false
            };
        case ActionTypes.ChangePasswordRejected:
            return {
                ...state,
                passwordPending: false
            };
        case ActionTypes.SignOutPending:
            return {
                ...state,
                signOutPending: true
            };
        case ActionTypes.SignOutFulfilled:
            return initialState;

        case ActionTypes.GetActorInfoFulfilled:
            return {
                ...state,
                ...action.payload,
                userInfo: {
                    ...state.userInfo,
                    ...action.payload.userInfo
                }
            };
        case ActionTypes.SetActorInfoFulfilled:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    ...action.userInfo
                }
            };
        case ActionTypes.GetActorsFulfilled:
            return state;
        case ActionTypes.UpdateActorFulfilled:
            if (!action.actorId) {
                return {
                    ...state,
                    userInfo: {
                        ...state.userInfo,
                        firstName: action.firstName,
                        lastName: action.lastName,
                        profilePictureURL: action.profilePictureURL,
                        disableExploreAccess: action.disableExploreAccess
                    }
                };
            }
            return state;

        case ActionTypes.SetLightMode:
            return {
                ...state,
                userInfo: {
                    ...state.userInfo,
                    lightMode: action.lightMode
                }
            };
        default:
            return state;
    }
}

import React from 'react';
import Modal from 'antd/lib/modal';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';

interface DeleteModalProps {
    isOpen: boolean;
    deleteActorPending: boolean;
    onCancel: () => void;
    onAction: () => void;
}

function DeleteModal(props: DeleteModalProps) {
    const { isOpen, deleteActorPending, onCancel, onAction } = props;
    return (
        <Modal
            open={isOpen}
            width={400}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={onCancel}
            zIndex={3000}
        >
            <div className="modal modal--delete">
                <div className="modal__header">
                    <div className="modal__title">Delete User</div>
                </div>
                <div className="modal__content">
                    By clicking delete, you permanently remove the user from
                    Dandi.
                </div>
                <div className="modal__footer">
                    <Button
                        componentType={ButtonTypes.type.TERTIARY}
                        classes={['mr8']}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button loading={deleteActorPending} onClick={onAction}>
                        Delete
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

export default DeleteModal;

interface ChevronLeftProps {
    size?: number;
}

const ChevronLeft = ({ size = 20 }: ChevronLeftProps) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 19 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M12.2068 4.01485L6 10.2217L12.2068 16.4285"
                stroke="currentColor"
            />
        </svg>
    );
};

export default ChevronLeft;

const Tiles = ({
    fill = 'white',
    size = 19
}: {
    fill?: string;
    size?: number;
}) => (
    <svg
        width={size}
        height={size}
        viewBox="0 0 18 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            id="Tiles"
            d="M1.5 9.20833C0.947715 9.20833 0.5 8.76062 0.5 8.20833V1.5C0.5 0.947715 0.947715 0.5 1.5 0.5H7.75C8.30228 0.5 8.75 0.947715 8.75 1.5V8.20833C8.75 8.76062 8.30228 9.20833 7.75 9.20833H1.5ZM1.5 19.5C0.947715 19.5 0.5 19.0523 0.5 18.5V11.7917C0.5 11.2394 0.947715 10.7917 1.5 10.7917H7.75C8.30228 10.7917 8.75 11.2394 8.75 11.7917V18.5C8.75 19.0523 8.30228 19.5 7.75 19.5H1.5ZM11.25 9.20833C10.6977 9.20833 10.25 8.76062 10.25 8.20833V1.5C10.25 0.947715 10.6977 0.5 11.25 0.5H17.5C18.0523 0.5 18.5 0.947715 18.5 1.5V8.20833C18.5 8.76062 18.0523 9.20833 17.5 9.20833H11.25ZM11.25 19.5C10.6977 19.5 10.25 19.0523 10.25 18.5V11.7917C10.25 11.2394 10.6977 10.7917 11.25 10.7917H17.5C18.0523 10.7917 18.5 11.2394 18.5 11.7917V18.5C18.5 19.0523 18.0523 19.5 17.5 19.5H11.25Z"
            fill={fill}
        />
    </svg>
);

export default Tiles;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import qs from 'qs';

import ActionTypes from '../../constants/ActionTypes';
import AnalysisTypes from '../../constants/AnalysisTypes';
import Urls from '../../constants/Urls';
import { getStateForGoalData } from '../../store/reducerUtils';
import { AsyncThunkConfig } from '../../store/store';
import { Intervals, TimeInterval } from '../../types';
import { IGoal, Trend } from '../types';
import { determineTrend, getSymbolFromValueType } from '../utils';
import { getMonthRange, mapDataProp } from './utils';

export interface IGoalState {
    goalId: string | null;
    chartData: any[];
    goal: any;
    dates: Date[];
    generated: boolean;
    generateError: string | null;
    generateNoData: boolean;
    generatePending: boolean;
    currentIndex: number;
    interval: TimeInterval;
    intervalCount: number;
    lifecycle: string;
    analysisType: AnalysisTypes;
    eventIds: string[];
    dataProp: 'percentage' | 'gap';
    eventsModalOpen: boolean;
    trend: Trend;
    unit: string;
}

const initialState: IGoalState = {
    goalId: null,
    chartData: [],
    goal: {},
    dates: [],
    generated: false,
    generateError: null,
    generatePending: false,
    generateNoData: false,
    currentIndex: 0,
    interval: Intervals.Month,
    intervalCount: 12,
    lifecycle: 'Goal Chart',
    analysisType: AnalysisTypes.Percentage,
    eventIds: [],
    dataProp: 'percentage',
    eventsModalOpen: false,
    trend: 'flat',
    unit: '%'
};

export const fetchGoal = createAsyncThunk<any, void, AsyncThunkConfig>(
    'goal/fetchGoal',
    async (_, { getState, dispatch }) => {
        const { goalId } = getState().measure.goal;
        const { enterpriseId } = getState().account;
        const { attributes } = getState().explore.filter;

        if (!goalId) return;

        const response = await axios.post(`${Urls.GoalsApi}get/id`, {
            enterpriseId,
            goalId
        });

        const goal: IGoal = response.data;
        const metrics = goal.metricRegistrationRequest.metrics[0];
        const trajectory = metrics.trajectories[0];
        const dataProp = mapDataProp(trajectory.initialValue.valueType);
        const monthRange: Date[] = getMonthRange(
            new Date(
                goal.metricTrajectoryValues[0].values[0].timestamp.seconds *
                    1000
            ),
            new Date(
                goal.metricTrajectoryValues[0].values[
                    goal.metricTrajectoryValues[0].values.length - 1
                ].timestamp.seconds * 1000
            )
        );

        dispatch(setDataProp(dataProp));

        const generatedGoalData = getStateForGoalData(
            goal,
            dataProp,
            attributes
        );

        const template = getState().templates.templates.find(
            t => t.analysisName === metrics.analysisName
        );

        dispatch(setAnalysisType(template?.analysisType));
        dispatch(setGoal(goal));
        dispatch(setChartData(generatedGoalData));
        dispatch(setDates(monthRange));
        dispatch(
            setTrend(
                determineTrend({
                    currentValue: goal.metricTrajectoryValues[0].currentValue,
                    previousValue: goal.metricTrajectoryValues[0].previousValue,
                    initialValue: goal.metricTrajectoryValues[0].initialValue,
                    desiredValue: goal.metricTrajectoryValues[0].desiredValue,
                    trajectory: trajectory.trajectory
                })
            )
        );
        dispatch(setCurrentIndex(generatedGoalData[0].series.length - 1));
        dispatch(
            setUnit(getSymbolFromValueType(trajectory.initialValue.valueType))
        );

        return;
    }
);

const goalSlice = createSlice({
    name: 'goal',
    initialState: initialState,
    reducers: {
        setGoal: (state, action) => {
            state.goal = action.payload;
        },
        setChartData: (state, action) => {
            state.chartData = action.payload;
        },
        setDates: (state, action) => {
            state.dates = action.payload;
        },
        setCurrentIndex: (state, action) => {
            state.currentIndex = action.payload;
        },
        setInterval: (state, action) => {
            state.interval = action.payload;
        },
        setDataProp: (state, action) => {
            state.dataProp = action.payload;
        },
        setChartEventsInGoal: (state, action) => {
            state.eventIds = action.payload;
        },
        openEventsModalInGoal: (state, _) => {
            state.eventsModalOpen = true;
        },
        closeEventsModalInGoal: (state, _) => {
            state.eventsModalOpen = false;
        },
        setTrend: (state, action) => {
            state.trend = action.payload;
        },
        setAnalysisType: (state, action) => {
            state.analysisType = action.payload;
        },
        setUnit: (state, action) => {
            state.unit = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(ActionTypes.ChangeLocation, (state, action: any) => {
            const query = qs.parse(action.payload.location, {
                ignoreQueryPrefix: true
            });

            const pattern = /\/measure\/(.*)/;
            const match = pattern.exec(query.pathname as string);
            if (match) {
                state.goalId = match[1];
            } else {
                Object.assign(state, initialState);
            }
        });
        builder.addCase(fetchGoal.pending, (state, _) => {
            state.generatePending = true;
        });
        builder.addCase(fetchGoal.fulfilled, (state, _) => {
            state.generatePending = false;
            state.generated = true;
        });
        builder.addCase(fetchGoal.rejected, (state, _) => {
            state.generatePending = false;
            state.generateError = 'Error fetching goal';
        });
    }
});

export const {
    setGoal,
    setChartData,
    setDates,
    setCurrentIndex,
    setDataProp,
    setInterval,
    setChartEventsInGoal,
    openEventsModalInGoal,
    closeEventsModalInGoal,
    setTrend,
    setAnalysisType,
    setUnit
} = goalSlice.actions;
export default goalSlice.reducer;

import React from 'react';

const Home = (props: any) => (
    <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="currentColor"
        style={{ verticalAlign: 'middle' }}
        {...props}
    >
        <path d="M10 20v-6h4v6h5v-8h3L12 3 2 12h3v8z" />
        <path d="M0 0h24v24H0z" fill="none" />
    </svg>
);

export default Home;

import React from 'react';
import DropdownArrow from '../icons/DropdownArrow';
import classNames from 'classnames';

export type SortFnFunc = (data: any) => any;

export interface SortCriteria {
    columnName: string;
    ascending: boolean;
    sortFn?: SortFnFunc;
}

export type OnSortFunc = (columnName: string, sortFn?: SortFnFunc) => void;

interface SortableHeadProps {
    label: string;
    columnName: string;
    sortCriteria: SortCriteria;
    onSort: OnSortFunc;
    className?: string;
    style?: any;
    disable?: boolean;
    sortFn?: SortFnFunc;
};

export default function SortableHead(props: SortableHeadProps) {
    const {
        label,
        columnName,
        sortCriteria,
        onSort,
        style,
        className,
        disable,
        sortFn
    } = props;

    if (disable) {
        return (
            <th className={className} style={{ ...style, cursor: 'text' }}>
                <span>{label}</span>
            </th>
        );
    }
    const active = sortCriteria.columnName === columnName;
    const ascending = active ? sortCriteria.ascending : true;
    const arrowStyle: any = {};
    if (!ascending) {
        arrowStyle.transform = 'rotate(180deg)';
    }
    return (
        <th
            className={classNames('table-head', 'table__divider', {
                'table-head--active': active
            })}
            onClick={() => onSort(columnName, sortFn)}
            style={{ ...style }}
        >
            <div className="sortable-head">
                <div
                    className="sortable-head__label"
                    style={{ userSelect: 'none' }}
                >
                    {label}
                </div>
                <div className="sortable-head__icon">
                    <DropdownArrow width={18} height={18} style={arrowStyle} />
                </div>
            </div>
        </th>
    );
}

SortableHead.defaultProps = {
    style: {}
};



const stateMachine = (state: any, action: any) => {
    switch (state.status) {
        case 'idle': {
            switch (action.type) {
                case 'onHover': {
                    const eventId = action.payload;
                    return {
                        ...state,
                        status: 'hovering',
                        hoverId: eventId
                    };
                }
                default:
                    return state;
            }
        }
        case 'hovering': {
            switch (action.type) {
                case 'onLeave': {
                    return {
                        ...state,
                        status: 'idle',
                        hoverId: null
                    };
                }
                case 'onToggle': {
                    const eventId = action.payload;
                    return {
                        ...state,
                        status: 'activated',
                        selection: [eventId]
                    };
                }
                default:
                    return state;
            }
        }
        case 'activated': {
            switch (action.type) {
                case 'onToggle': {
                    const eventId = action.payload;
                    const newSelection = state.selection.includes(eventId)
                        ? state.selection.filter((id: string) => id !== eventId)
                        : [...state.selection, eventId];
                    return {
                        ...state,
                        selection: newSelection,
                        status: newSelection.length === 0 ? 'idle' : 'activated'
                    };
                }
                case 'onClear': {
                    return {
                        ...state,
                        status: 'idle',
                        selection: [],
                        hoverId: null
                    };
                }
                case 'onHover': {
                    const eventId = action.payload;
                    return {
                        ...state,
                        hoverId: eventId
                    };
                }
                case 'onLeave': {
                    return {
                        ...state,
                        hoverId: null
                    };
                }
                default:
                    return state;
            }
        }
        default:
            return state;
    }
};

export default stateMachine;

import { FeaturePermission } from '../../../roles/createRole/models';
import { DynamicPermission, StaticPermission } from '../../permissions/model';
import { RoleStaticPermission } from '../../roles/model';

export function generateFeaturePermissions(
    staticPermissions: StaticPermission[] | RoleStaticPermission[]
): FeaturePermission[] {
    if (!staticPermissions || !staticPermissions.length) {
        return [];
    }

    const lookup: any = {};

    if ('policies' in staticPermissions[0]) {
        staticPermissions.forEach((staticPermission: any) => {
            staticPermission.policies.forEach((policy: any) => {
                policy.actions.forEach((action: any) => {
                    const split = action.split('/');
                    const featureName = split[0];

                    if (!lookup[featureName]) {
                        lookup[featureName] = {
                            featureName,
                            scopes: [staticPermission.scope],
                            actions: [action]
                        };
                    } else {
                        if (!lookup[featureName].actions.includes(action)) {
                            lookup[featureName].actions.push(action);
                        }
                        if (
                            !lookup[featureName].scopes.includes(
                                staticPermission.scope
                            )
                        ) {
                            lookup[featureName].scopes.push(
                                staticPermission.scope
                            );
                        }
                    }
                });
            });
        });
    } else {
        staticPermissions.forEach((staticPermission: any) => {
            const split = staticPermission.action.split('/');
            const featureName = split[0];

            if (!lookup[featureName]) {
                lookup[featureName] = {
                    featureName,
                    scopes: [staticPermission.scope],
                    actions: [staticPermission.action]
                };
            } else {
                if (
                    !lookup[featureName].actions.includes(
                        staticPermission.action
                    )
                ) {
                    lookup[featureName].actions.push(staticPermission.action);
                }

                if (
                    !lookup[featureName].scopes.includes(staticPermission.scope)
                ) {
                    lookup[featureName].scopes.push(staticPermission.scope);
                }
            }
        });
    }

    return Object.values(lookup);
}

export function generateAnalysisPermissions(
    dynamicPermissions: DynamicPermission[]
) {
    if (!dynamicPermissions || !dynamicPermissions.length) {
        return [];
    }

    return;
}

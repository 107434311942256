import { connect } from 'react-redux';
import Chart from './Chart';
import {
    selectCurrentTime,
    navigateToExplore,
    setRowHeightInEditor,
    setSortCriteriaInEditor,
    setExpandedInEditor
} from './actions';

function mapState(state, ownProps) {
    const { isLocked, isResizing } = ownProps;
    return {
        source: 'editor',
        interval: state.editor.filter.interval,
        ...state.editor.chart,
        disableLinks: true,
        isEmptyState: !state.editor.filter.analysisName,
        allEvents: state.timeline.events,
        eventIds: state.editor.chart.eventIds || [],
        lightMode: state.auth.userInfo.lightMode,
        isLocked,
        isResizing
    };
}

const dispatchProps = {
    selectCurrentTime,
    navigateToExplore,
    setRowHeightInEditor,
    setSortCriteriaInEditor,
    setExpandedInEditor
};

export default connect(mapState, dispatchProps)(Chart);

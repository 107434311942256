const actionTypes = {
    OpenInfoModal: 'OPEN_INFO_MODAL',
    CloseInfoModal: 'CLOSE_INFO_MODAL',
    OpenComments: 'OPEN_COMMENTS',
    CloseComments: 'CLOSE_COMMENTS',
    OpenComments2: 'OPEN_COMMENTS_2',
    CloseComments2: 'CLOSE_COMMENTS_2',
    SetEnterpriseId: 'SET_ENTERPRISE_ID',
    SetEnterpriseIdComplete: 'SET_ENTERPRISE_ID_COMPLETE',
    SetEnterprises: 'SET_ENTERPRISES',
    ChangeLocation: '@@router/LOCATION_CHANGE',
    SetBackUrl: 'SET_BACK_URL',
    SetDefault: 'SET_DEFAULT',
    SetWinked: 'SET_WINKED',
    SetScrollPosition: 'SET_SCROLL_POSITION',
    OpenDeleteModal: 'OPEN_DELETE_MODAL',
    CloseDeleteModal: 'CLOSE_DELETE_MODAL',

    GetSSOOptionsPending: 'GET_SSO_OPTIONS_PENDING',
    GetSSOOptionsFulfilled: 'GET_SSO_OPTIONS_FULFILLED',
    GetSSOOptionsRejected: 'GET_SSO_OPTIONS_REJECTED',
    SetSSOOptions: 'SET_SSO_OPTIONS',
    GetTemplatesPending: 'GET_TEMPLATES_PENDING',
    GetTemplatesFulfilled: 'GET_TEMPLATES_FULFILLED',
    GetTemplatesRejected: 'GET_TEMPLATES_REJECTED',

    GetAuthorizationTokenPending: 'GET_AUTHORIZATION_TOKEN_PENDING',
    GetAuthorizationTokenFulfilled: 'GET_AUTHORIZATION_TOKEN_FULFILLED',
    GetAuthorizationTokenRejected: 'GET_AUTHORIZATION_TOKEN_REJECTED',

    GetAccountPending: 'GET_ACCOUNT_PENDING',
    GetAccountFulfilled: 'GET_ACCOUNT_FULFILLED',
    GetAccountRejected: 'GET_ACCOUNT_REJECTED',
    UpdateAccountPending: 'UPDATE_ACCOUNT_PENDING',
    UpdateAccountFulfilled: 'UPDATE_ACCOUNT_FULFILLED',
    UpdateAccountRejected: 'UPDATE_ACCOUNT_REJECTED',

    GetFieldsPending: 'GET_FIELDS_PENDING',
    GetFieldsFulfilled: 'GET_FIELDS_FULFILLED',
    GetFieldsRejected: 'GET_FIELDS_REJECTED',
    GetFieldsMetaPending: 'GET_FIELDS_META_PENDING',
    GetFieldsMetaFulfilled: 'GET_FIELDS_META_FULFILLED',
    GetFieldsMetaRejected: 'GET_FIELDS_META_REJECTED',

    GetActorsPending: 'GET_ACTORS_PENDING',
    GetActorsFulfilled: 'GET_ACTORS_FULFILLED',
    GetActorsRejected: 'GET_ACTORS_REJECTED',

    GetActorInfoPending: 'GET_ACTOR_INFO_PENDING',
    GetActorInfoFulfilled: 'GET_ACTOR_INFO_FULFILLED',
    GetActorInfoRejected: 'GET_ACTOR_INFO_REJECTED',

    SetActorInfoPending: 'SET_ACTOR_INFO_PENDING',
    SetActorInfoFulfilled: 'SET_ACTOR_INFO_FULFILLED',
    SetActorInfoRejected: 'SET_ACTOR_INFO_REJECTED',

    AddUsersPending: 'ADD_USERS_PENDING',
    AddUsersFulfilled: 'ADD_USERS_FULFILLED',
    AddUsersRejected: 'ADD_USERS_REJECTED',

    CreateActorPending: 'CREATE_ACTOR_PENDING',
    CreateActorFulfilled: 'CREATE_ACTOR_FULFILLED',
    CreateActorRejected: 'CREATE_ACTOR_REJECTED',

    UpdateActorPending: 'UPDATE_ACTOR_PENDING',
    UpdateActorFulfilled: 'UPDATE_ACTOR_FULFILLED',
    UpdateActorRejected: 'UPDATE_ACTOR_REJECTED',

    DeleteActorPending: 'DELETE_ACTOR_PENDING',
    DeleteActorFulfilled: 'DELETE_ACTOR_FULFILLED',
    DeleteActorRejected: 'DELETE_ACTOR_REJECTED',

    SearchUsers: 'SEARCH_USERS',
    ChangeUserSearchText: 'CHANGE_USER_SEARCH_TEXT',
    SearchRoles: 'SEARCH_ROLES',
    ChangeRoleSearchText: 'CHANGE_ROLE_SEARCH_TEXT',

    AssignRolePending: 'ASSIGN_ROLE_PENDING',
    AssignRoleFulfilled: 'ASSIGN_ROLE_FULFILLED',
    AssignRoleRejected: 'ASSIGN_ROLE_REJECTED',
    AssignRoleOptimistic: 'ASSIGN_ROLE_OPTIMISTIC',

    UnassignRolePending: 'UNASSIGN_ROLE_PENDING',
    UnassignRoleFulfilled: 'UNASSIGN_ROLE_FULFILLED',
    UnassignRoleRejected: 'UNASSIGN_ROLE_REJECTED',
    UnassignRoleOptimistic: 'UNASSIGN_ROLE_OPTIMISTIC',

    AssignGroupRolePending: 'ASSIGN_GROUP_ROLE_PENDING',
    AssignGroupRoleFulfilled: 'ASSIGN_GROUP_ROLE_FULFILLED',
    AssignGroupRoleRejected: 'ASSIGN_GROUP_ROLE_REJECTED',
    AssignGroupRoleOptimistic: 'ASSIGN_GROUP_ROLE_OPTIMISTIC',

    UnassignGroupRolePending: 'UNASSIGN_GROUP_ROLE_PENDING',
    UnassignGroupRoleFulfilled: 'UNASSIGN_GROUP_ROLE_FULFILLED',
    UnassignGroupRoleRejected: 'UNASSIGN_GROUP_ROLE_REJECTED',
    UnassignGroupRoleOptimistic: 'UNASSIGN_GROUP_ROLE_OPTIMISTIC',

    GetRolesPending: 'GET_ROLES_PENDING',
    GetRolesFulfilled: 'GET_ROLES_FULFILLED',
    GetRolesRejected: 'GET_ROLES_REJECTED',

    GrantPending: 'GRANT_PENDING',
    GrantFulfilled: 'GRANT_FULFILLED',
    GrantRejected: 'GRANT_REJECTED',

    CreateRolePending: 'CREATE_ROLE_PENDING',
    CreateRoleFulfilled: 'CREATE_ROLE_FULFILLED',
    CreateRoleRejected: 'CREATE_ROLE_REJECTED',

    UpdateRolePending: 'UPDATE_ROLE_PENDING',
    UpdateRoleFulfilled: 'UPDATE_ROLE_FULFILLED',
    UpdateRoleRejected: 'UPDATE_ROLE_REJECTED',

    GetCommentsPending: 'GET_COMMENTS_PENDING',
    GetCommentsFulfilled: 'GET_COMMENTS_FULFILLED',
    GetCommentsRejected: 'GET_COMMENTS_REJECTED',

    GetCommentCountPending: 'GET_COMMENT_COUNT_PENDING',
    GetCommentCountFulfilled: 'GET_COMMENT_COUNT_FULFILLED',
    GetCommentCountRejected: 'GET_COMMENT_COUNT_REJECTED',

    GetCanCommentPending: 'GET_CAN_COMMENT_PENDING',
    GetCanCommentFulfilled: 'GET_CAN_COMMENT_FULFILLED',
    GetCanCommentRejected: 'GET_CAN_COMMENT_REJECTED',

    AddCommentPending: 'ADD_COMMENT_PENDING',
    AddCommentFulfilled: 'ADD_COMMENT_FULFILLED',
    AddCommentRejected: 'ADD_COMMENT_REJECTED',

    UpdateCommentPending: 'UPDATE_COMMENT_PENDING',
    UpdateCommentFulfilled: 'UPDATE_COMMENT_FULFILLED',
    UpdateCommentRejected: 'UPDATE_COMMENT_REJECTED',

    ResolveCommentPending: 'RESOLVE_COMMENT_PENDING',
    ResolveCommentFulfilled: 'RESOLVE_COMMENT_FULFILLED',
    ResolveCommentRejected: 'RESOLVE_COMMENT_REJECTED',

    AddReplyPending: 'ADD_REPLY_PENDING',
    AddReplyFulfilled: 'ADD_REPLY_FULFILLED',
    AddReplyRejected: 'ADD_REPLY_REJECTED',

    UpdateReplyPending: 'UPDATE_REPLY_PENDING',
    UpdateReplyFulfilled: 'UPDATE_REPLY_FULFILLED',
    UpdateReplyRejected: 'UPDATE_REPLY_REJECTED',

    DeleteComment: 'DELETE_COMMENT',
    EditComment: 'EDIT_COMMENT',
    CancelEditComment: 'CANCEL_EDIT_COMMENT',

    GetNotificationsPending: 'GET_NOTIFICATIONS_PENDING',
    GetNotificationsFulfilled: 'GET_NOTIFICATIONS_FULFILLED',
    GetNotificationsRejected: 'GET_NOTIFICATIONS_REJECTED',

    MarkNotificationAsReadPending: 'MARK_NOTIFICATION_PENDING',
    MarkNotificationAsReadFulfilled: 'MARK_NOTIFICATION_FULFILLED',
    MarkNotificationAsReadRejected: 'MARK_NOTIFICATION_REJECTED',

    LoginPending: 'LOGIN_PENDING',
    LoginFulfilled: 'LOGIN_FULFILLED',
    LoginRejected: 'LOGIN_REJECTED',
    IdentifyOnLogin: 'IDENTIFY_ON_LOGIN',

    SignOutPending: 'SIGNOUT_PENDING',
    SignOutFulfilled: 'SIGNOUT_FULFILLED',
    SignOutRejected: 'SIGNOUT_REJECTED',

    CompleteSetupPending: 'COMPLETE_SETUP_PENDING',
    CompleteSetupFulfilled: 'COMPLETE_SETUP_FULFILLED',
    CompleteSetupRejected: 'COMPLETE_SETUP_REJECTED',

    CompleteEditPending: 'COMPLETE_EDIT_PENDING',
    CompleteEditFulfilled: 'COMPLETE_EDIT_FULFILLED',
    CompleteEditRejected: 'COMPLETE_EDIT_REJECTED',

    CreatePasswordPending: 'CREATE_PASSWORD_PENDING',
    CreatePasswordFulfilled: 'CREATE_PASSWORD_FULFILLED',
    CreatePasswordRejected: 'CREATE_PASSWORD_REJECTED',

    ChangePasswordPending: 'CHANGE_PASSWORD_PENDING',
    ChangePasswordFulfilled: 'CHANGE_PASSWORD_FULFILLED',
    ChangePasswordRejected: 'CHANGE_PASSWORD_REJECTED',

    UploadPhotoPending: 'UPLOAD_PHOTO_PENDING',
    UploadPhotoFulfilled: 'UPLOAD_PHOTO_FULFILLED',
    UploadPhotoRejected: 'UPLOAD_PHOTO_REJECTED',

    FetchHRISPending: 'FETCH_HRIS_PENDING',
    FetchHRISFulfilled: 'FETCH_HRIS_FULFILLED',
    FetchHRISRejected: 'FETCH_HRIS_REJECTED',

    FetchInstructionsPending: 'FETCH_INSTRUCTIONS_PENDING',
    FetchInstructionsFulfilled: 'FETCH_INSTRUCTIONS_FULFILLED',
    FetchInstructionsRejected: 'FETCH_INSTRUCTIONS_REJECTED',

    CreateAccountPending: 'CREATE_ACCOUNT_PENDING',
    CreateAccountFulfilled: 'CREATE_ACCOUNT_FULFILLED',
    CreateAccountRejected: 'CREATE_ACCOUNT_REJECTED',

    UploadCompanyPhotoPending: 'UPLOAD_COMPANY_PHOTO_PENDING',
    UploadCompanyPhotoFulfilled: 'UPLOAD_COMPANY_PHOTO_FULFILLED',
    UploadCompanyPhotoRejected: 'UPLOAD_COMPANY_PHOTO_REJECTED',

    SelectHRISPending: 'SELECT_HRIS_PENDING',
    SelectHRISFulfilled: 'SELECT_HRIS_FULFILLED',
    SelectHRISRejected: 'SELECT_HRIS_REJECTED',

    NextInstruction: 'NEXT_INSTRUCTION',
    PreviousInstruction: 'PREVIOUS_INSTRUCTION',
    ChangeInput: 'CHANGE_INPUT',

    TestConnectionPending: 'TEST_CONNECTION_PENDING',
    TestConnectionFulfilled: 'TEST_CONNECTION_FULFILLED',
    TestConnectionRejected: 'TEST_CONNECTION_REJECTED',

    SaveCredentialsPending: 'SAVE_CREDENTIALS_PENDING',
    SaveCredentialsFulfilled: 'SAVE_CREDENTIALS_FULFILLED',
    SaveCredentialsRejected: 'SAVE_CREDENTIALS_REJECTED',

    AddMessage: 'ADD_MESSAGE',
    CloseMessage: 'CLOSE_MESSAGE',

    SendResetEmailPending: 'SEND_RESET_EMAIL_PENDING',
    SendResetEmailFulfilled: 'SEND_RESET_EMAIL_FULFILLED',
    SendResetEmailRejected: 'SEND_RESET_EMAIL_REJECTED',

    IntegratePending: 'INTEGRATE_PENDING',
    IntegrateFulfilled: 'INTEGRATE_FULFILLED',
    IntegrateRejected: 'INTEGRATE_REJECTED',

    CollectPending: 'COLLECT_PENDING',
    CollectFulfilled: 'COLLECT_FULFILLED',
    CollectRejected: 'COLLECT_REJECTED',

    ExportPending: 'EXPORT_PENDING',
    ExportFulfilled: 'EXPORT_FULFILLED',
    ExportRejected: 'EXPORT_REJECTED',

    ClearFilters: 'CLEAR_FILTERS',
    SelectIntervalInExplore: 'SELECT_INTERVAL_IN_EXPLORE',
    SelectIntervalCountInExplore: 'SELECT_INTERVAL_COUNT_IN_EXPLORE',
    SelectIntervalRangeInExplore: 'SELECT_INTERVAL_RANGE_IN_EXPLORE',
    SelectDateInExplore: 'SELECT_DATE_IN_EXPLORE',
    SelectInterval: 'SELECT_INTERVAL',
    SelectIntervalCount: 'SELECT_INTERVAL_COUNT',
    SelectIntervalRange: 'SELECT_INTERVAL_RANGE',
    ResetCurrentTime: 'RESET_CURRENT_TIME',

    ClearFiltersInDashboard: 'CLEAR_FILTERS_IN_DASHBOARD',
    ClearIntervalRangeInDashboard: 'CLEAR_INTERVAL_RANGE_IN_DASHBOARD',
    SelectIntervalInDashboard: 'SELECT_INTERVAL_IN_DASHBOARD',
    SelectIntervalRangeInDashboard: 'SELECT_INTERVAL_RANGE_IN_DASHBOARD',
    SetFilterNamesInDashboard: 'SET_FILTER_NAMES_IN_DASHBOARD',
    UpdateFilterApplyState: 'UPDATE_FILTER_APPLY_STATE',
    UpdateFilterSegmentInDashboard: 'UPDATE_FILTER_SEGMENT_IN_DASHBOARD',
    UpdateReportsWithFilters: 'UPDATE_REPORTS_WITH_FILTERS',

    SelectCategoryFilterInEditor: 'SELECT_CATEGORY_FILTER_IN_EDITOR',
    ClearFiltersInEditor: 'CLEAR_FILTERS_IN_EDITOR',
    SelectIntervalInEditor: 'SELECT_INTERVAL_IN_EDITOR',
    SetChartTypeInEditor: 'SET_CHART_TYPE_IN_EDITOR',

    SelectCurrentTimeInEditor: 'SELECT_CURRENT_TIME_IN_EDITOR',
    SaveReportPending: 'SAVE_REPORT_PENDING',
    SaveReportFulfilled: 'SAVE_REPORT_FULFILLED',
    SaveReportRejected: 'SAVE_REPORT_REJECTED',
    UpdateReportPending: 'UPDATE_REPORT_PENDING',
    UpdateAllReportsFulfilled: 'UPDATE_ALL_REPORT_FULFILLED',
    UpdateReportFulfilled: 'UPDATE_REPORT_FULFILLED',
    UpdateReportRejected: 'UPDATE_REPORT_REJECTED',
    InsertReportPending: 'INSERT_REPORT_PENDING',
    InsertReportFulfilled: 'INSERT_REPORT_FULFILLED',
    InsertReportRejected: 'INSERT_REPORT_REJECTED',
    SetFiltersFromReport: 'SET_FILTERS_FROM_REPORT',
    SetChartDataInEditor: 'SET_CHART_DATA_IN_EDITOR',
    SetIntervalInEditor: 'SET_INTERVAL_IN_EDITOR',
    SetDateInEditor: 'SET_DATE_IN_EDITOR',
    SetRowHeightInEditor: 'SET_ROW_HEIGHT_IN_EDITOR',
    SetRowHeightInReport: 'SET_ROW_HEIGHT_IN_REPORT',
    SetSortCriteriaInEditor: 'SET_SORT_CRITERIA_IN_EDITOR',
    SetSortCriteriaInReport: 'SET_SORT_CRITERIA_IN_REPORT',
    SetExpandedInEditor: 'SET_EXPANDED_IN_EDITOR',
    SetExpandedInReport: 'SET_EXPANDED_IN_REPORT',
    SelectCurrentTimeInReport: 'SET_CURRENT_TIME_IN_REPORT',
    SetReportData: 'SET_REPORT_DATA',
    SetIntervalCountInEditor: 'SET_INTERVAL_COUNT_IN_EDITOR',
    SetIntervalRangeInEditor: 'SET_INTERVAL_RANGE_IN_EDITOR',
    SetAnalysisNameInEditor: 'SET_ANALYSIS_NAME_IN_EDITOR',
    UpdateMainSegmentsInEditor: 'UPDATE_MAIN_SEGMENTS_IN_EDITOR',
    UpdateFilterSegmentInEditor: 'UPDATE_FILTER_SEGMENT_IN_EDITOR',
    UpdateBreakdownSegmentInEditor: 'UPDATE_BREAKDOWN_SEGMENT_IN_EDITOR',

    SetActiveLabel: 'SET_ACTIVE_LABEL',
    ClearActiveLabel: 'CLEAR_ACTIVE_LABEL',
    ToggleLabel: 'TOGGLE_LABEL',
    ToggleLockedSelection: 'TOGGLE_LOCKED_SELECTION',
    ClearSelection: 'CLEAR_SELECTION',
    SelectCurrentTime: 'SELECT_CURRENT_TIME',
    SetFilterLabels: 'SET_FILTER_LABELS',
    SetChartType: 'SET_CHART_TYPE',
    SetYAxisType: 'SET_Y_AXIS_TYPE',
    SetIndexType: 'SET_INDEX_TYPE',
    SetYAxisTypeInEditor: 'SET_Y_AXIS_TYPE_IN_EDITOR',
    SetIndexTypeInEditor: 'SET_INDEX_TYPE_IN_EDITOR',

    GeneratePending: 'GENERATE_PENDING',
    GenerateFulfilled: 'GENERATE_FULFILLED',
    GenerateRejected: 'GENERATE_REJECTED',
    GenerateInvalidVariation: 'GENERATE_INVALID_VARIATION',
    GenerateNoData: 'GENERATE_NO_DATA',
    GenerateSubtablePending: 'GENERATE_SUBTABLE_PENDING',
    GenerateSubtableFulfilled: 'GENERATE_SUBTABLE_FULFILLED',
    GenerateSubtableRejected: 'GENERATE_SUBTABLE_REJECTED',
    ToggleExpandedInSubtable: 'TOGGLE_EXPANDED_IN_SUBTABLE',
    SortInSubtable: 'SORT_IN_SUBTABLE',
    SetSubtableChartType: 'SET_SUBTABLE_CHART_TYPE',

    GeneratePendingInEditor: 'GENERATE_PENDING_IN_EDITOR',
    GenerateFulfilledInEditor: 'GENERATE_FULFILLED_IN_EDITOR',
    GenerateRejectedInEditor: 'GENERATE_REJECTED_IN_EDITOR',
    GenerateNoDataInEditor: 'GENERATE_NO_DATA_IN_EDITOR',
    GenerateInvalidVariationInEditor: 'GENERATE_INVALID_VARIATION_IN_EDITOR',
    GenerateMatrixPendingInEditor: 'GENERATE_MATRIX_PENDING_IN_EDITOR',
    GenerateMatrixFulfilledInEditor: 'GENERATE_MATRIX_FULFILLED_IN_EDITOR',
    GenerateMatrixRejectedInEditor: 'GENERATE_MATRIX_REJECTED_IN_EDITOR',
    GenerateMatrixNoDataInEditor: 'GENERATE_MATRIX_NO_DATA_IN_EDITOR',
    GenerateMatrixInvalidVariationInEditor:
        'GENERATE_MATRIX_INVALID_VARIATION_IN_EDITOR',
    FetchBenchmarkDataPendingInEditor: 'FETCH_BENCHMARK_DATA_PENDING_IN_EDITOR',
    FetchBenchmarkDataFulfilledInEditor:
        'FETCH_BENCHMARK_DATA_FULFILLED_IN_EDITOR',
    FetchBenchmarkDataNoDataInEditor: 'FETCH_BENCHMARK_DATA_NO_DATA_IN_EDITOR',
    FetchBenchmarkDataRejectedInEditor:
        'FETCH_BENCHMARK_DATA_REJECTED_IN_EDITOR',
    SetBenchmarkInEditor: 'SET_BENCHMARK_IN_EDITOR',
    RemoveBenchmarkInEditor: 'REMOVE_BENCHMARK_IN_EDITOR',
    SetHidePieInEditor: 'SET_HIDE_PIE_IN_EDITOR',

    FetchBySegmentPending: 'FETCH_BY_SEGMENT_PENDING',
    FetchBySegmentFulfilled: 'FETCH_BY_SEGMENT_FULFILLED',
    FetchBySegmentRejected: 'FETCH_BY_SEGMENT_REJECTED',
    FetchBySegmentNoData: 'FETCH_BY_SEGMENT_NO_DATA',
    CloseSegment: 'CLOSE_SEGMENT',
    ToggleExpansion: 'TOGGLE_EXPANSION',
    SetRowFilter: 'SET_ROW_FILTER',
    SetColumnFilter: 'SET_COLUMN_FILTER',
    ClearFilterInDetailSection: 'CLEAR_FILTER_IN_DETAIL_SECTION',
    SetSortCriteriaInDetailSection: 'SET_SORT_CRITERIA_IN_DETAIL_SECTION',
    ToggleLabelInDetailSection: 'TOGGLE_LABEL_IN_DETAIL_SECTION',
    HoverLabelInDetailSection: 'HOVER_LABEL_IN_DETAIL_SECTION',
    UnhoverLabelInDetailSection: 'UNHOVER_LABEL_IN_DETAIL_SECTION',
    SetChartTypeInDetailSection: 'SET_CHART_TYPE_IN_DETAIL_SECTION',

    ShowInfo: 'SHOW_INFO',
    CloseInfo: 'CLOSE_INFO',

    FetchEmployeesDataPending: 'FETCH_EMPLOYEES_DATA_PENDING',
    FetchEmployeesDataFulfilled: 'FETCH_EMPLOYEES_DATA_FULFILLED',
    FetchEmployeesDataRejected: 'FETCH_EMPLOYEES_DATA_REJECTED',
    SortIncludedData: 'SORT_INCLUDED_DATA',
    SortExcludedData: 'SORT_EXCLUDED_DATA',

    UploadLogoPending: 'UPLOAD_LOGO_PENDING',
    UploadLogoFulfilled: 'UPLOAD_LOGO_FULFILLED',
    UploadLogoRejected: 'UPLOAD_LOGO_REJECTED',

    FetchIntegrationsPending: 'FETCH_INTEGRATIONS_PENDING',
    FetchIntegrationsFulfilled: 'FETCH_INTEGRATIONS_FULFILLED',
    FetchIntegrationsRejected: 'FETCH_INTEGRATIONS_REJECTED',

    FetchBillingInfoPending: 'FETCH_BILLING_INFO_PENDING',
    FetchBillingInfoFulfilled: 'FETCH_BILLING_INFO_FULFILLED',
    FetchBillingInfoRejected: 'FETCH_BILLING_INFO_REJECTED',
    SavePaymentMethodPending: 'SAVE_PAYMENT_METHOD_PENDING',
    SavePaymentMethodFulfilled: 'SAVE_PAYMENT_METHOD_FULFILLED',
    SavePaymentMethodRejected: 'SAVE_PAYMENT_METHOD_REJECTED',
    DeletePaymentMethodPending: 'DELETE_PAYMENT_METHOD_PENDING',
    DeletePaymentMethodFulfilled: 'DELETE_PAYMENT_METHOD_FULFILLED',
    DeletePaymentMethodRejected: 'DELETE_PAYMENT_METHOD_REJECTED',

    GetAnalysisInfoPending: 'GET_ANALYSIS_INFO_PENDING',
    GetAnalysisInfoFulfilled: 'GET_ANALYSIS_INFO_FULFILLED',
    GetAnalysisInfoRejected: 'GET_ANALYSIS_INFO_REJECTED',
    GetVariationsPending: 'GET_VARIATIONS_PENDING',
    GetVariationsFulfilled: 'GET_VARIATIONS_FULFILLED',
    GetVariationsRejected: 'GET_VARIATIONS_REJECTED',
    GetAllVariationsPending: 'GET_ALL_VARIATIONS_PENDING',
    GetAllVariationsFulfilled: 'GET_ALL_VARIATIONS_FULFILLED',
    GetAllVariationsRejected: 'GET_ALL_VARIATIONS_REJECTED',
    InvalidVariation: 'INVALID_VARIATION',
    SetDynamicSections: 'SET_DYNAMIC_SECTIONS',
    SetAttributeOptions: 'SET_ATTRIBUTE_OPTIONS',
    ClearDynamicSections: 'CLEAR_DYNAMIC_SECTIONS',

    ShowListView: 'SHOW_DASHBOARD_TILES_VIEW',
    ShowTilesView: 'SHOW_DASHBOARD_LIST_VIEW',
    ShowCreateDashboardModal: 'SHOW_CREATE_DASHBOARD_MODAL',
    CloseCreateDashboardModal: 'CLOSE_CREATE_DASHBOARD_MODAL',
    CreateDashboardPending: 'CREATE_DASHBOARD_PENDING',
    CreateDashboardFulfilled: 'CREATE_DASHBOARD_FULFILLED',
    CreateDashboardRejected: 'CREATE_DASHBOARD_REJECTED',
    UpdateDashboardPending: 'UPDATE_DASHBOARD_PENDING',
    UpdateDashboardFulfilled: 'UPDATE_DASHBOARD_FULFILLED',
    UpdateDashboardRejected: 'UPDATE_DASHBOARD_REJECTED',
    DuplicateDashboardPending: 'DUPLICATE_DASHBOARD_PENDING',
    DuplicateDashboardFulfilled: 'DUPLICATE_DASHBOARD_FULFILLED',
    DuplicateDashboardRejected: 'DUPLICATE_DASHBOARD_REJECTED',
    CloneReportData: 'CLONE_REPORT_DATA',
    GetDashboardsPending: 'GET_DASHBOARDS_PENDING',
    GetDashboardsFulfilled: 'GET_DASHBOARDS_FULFILLED',
    GetDashboardsRejected: 'GET_DASHBOARDS_REJECTED',
    GetDashboardPending: 'GET_DASHBOARD_PENDING',
    GetDashboardFulfilled: 'GET_DASHBOARD_FULFILLED',
    GetDashboardRejected: 'GET_DASHBOARD_REJECTED',
    GetSharedDashboardsPending: 'GET_SHARED_DASHBOARDS_PENDING',
    GetSharedDashboardsFulfilled: 'GET_SHARED_DASHBOARDS_FULFILLED',
    GetSharedDashboardsRejected: 'GET_SHARED_DASHBOARDS_REJECTED',
    GetReportPending: 'GET_REPORT_PENDING',
    GetReportFulfilled: 'GET_REPORT_FULFILLED',
    GetReportRejected: 'GET_REPORT_REJECTED',
    GetReportInvalidVariation: 'GET_REPORT_INVALID_VARIATION',
    GetReportNoData: 'GET_REPORT_NO_DATA',
    GetMatrixPending: 'GET_MATRIX_PENDING',
    GetMatrixFulfilled: 'GET_MATRIX_FULFILLED',
    GetMatrixRejected: 'GET_MATRIX_REJECTED',
    GetMatrixInvalidVariation: 'GET_MATRIX_INVALID_VARIATION',
    GetMatrixNoData: 'GET_MATRIX_NO_DATA',
    ShowDeleteReportModal: 'SHOW_DELETE_REPORT_MODAL',
    CloseDeleteReportModal: 'CLOSE_DELETE_REPORT_MODAL',
    DeleteReportPending: 'DELETE_REPORT_PENDING',
    DeleteReportFulfilled: 'DELETE_REPORT_FULFILLED',
    DeleteReportRejected: 'DELETE_REPORT_REJECTED',
    DeleteReportLocal: 'DELETE_REPORT_LOCAL',
    ShowDeleteDashboardModal: 'SHOW_DELETE_DASHBOARD_MODAL',
    CloseDeleteDashboardModal: 'CLOSE_DELETE_DASHBOARD__MODAL',
    DeleteDashboardPending: 'DELETE_DASHBOARD_PENDING',
    DeleteDashboardFulfilled: 'DELETE_DASHBOARD_FULFILLED',
    DeleteDashboardRejected: 'DELETE_DASHBOARD_REJECTED',
    ShowShareModal: 'SHOW_SHARE_MODAL',
    CloseShareModal: 'CLOSE_SHARE_MODAL',
    ShowDuplicateModal: 'SHOW_DUPLICATE_MODAL',
    CloseDuplicateModal: 'CLOSE_DUPLICATE_MODAL',
    GetVaultPending: 'GET_VAULT_PENDING',
    GetVaultFulfilled: 'GET_VAULT_FULFILLED',
    GetVaultRejected: 'GET_VAULT_REJECTED',
    VaultLinkCopied: 'VAULT_LINK_COPIED',
    CheckVaultPending: 'CHECK_VAULT_PENDING',
    CheckVaultFulfilled: 'CHECK_VAULT_FULFILLED',
    CheckVaultRejected: 'CHECK_VAULT_REJECTED',
    ShareDashboardPending: 'SHARE_DASHBOARD_PENDING',
    ShareDashboardFulfilled: 'SHARE_DASHBOARD_FULFILLED',
    ShareDashboardRejected: 'SHARE_DASHBOARD_REJECTED',
    UnshareDashboardPending: 'UNSHARE_DASHBOARD_PENDING',
    UnshareDashboardFulfilled: 'UNSHARE_DASHBOARD_FULFILLED',
    UnshareDashboardRejected: 'UNSHARE_DASHBOARD_REJECTED',
    EditDashboard: 'EDIT_DASHBOARD',
    MoveGraph: 'MOVE_GRAPH',
    RemoveRow: 'REMOVE_ROW',
    MoveGraphInNewRow: 'MOVE_GRAPH_IN_NEW_ROW',
    IncrementLength: 'INCREMENT_LENGTH',
    Resize: 'RESIZE',
    SetDashboard: 'SET_DASHBOARD',
    SetDashboardName: 'SET_DASHBOARD_NAME',
    SetSearchTextInMyDashboards: 'SET_SEARCH_TEXT_IN_MY_DASHBOARDS',
    SetSearchTextInDashboard: 'SET_SEARCH_TEXT_IN_DASHBOARD',
    ShowSelectDashboardModal: 'SHOW_SELECT_DASHBOARD_MODAL',
    CloseSelectDashboardModal: 'CLOSE_SELECT_DASHBOARD_MODAL',
    ShowCreateDashboardModalForCopy: 'SHOW_CREATE_DASHBOARD_MODAL_FOR_COPY',
    CloseCreateDashboardModalForCopy: 'CLOSE_CREATE_DASHBOARD_MODAL_FOR_COPY',
    GetSharedUsersPending: 'GET_SHARED_USERS_PENDING',
    GetSharedUsersFulfilled: 'GET_SHARED_USERS_FULFILLED',
    GetSharedUsersRejected: 'GET_SHARED_USERS_REJECTED',
    ShowDashboardFilters: 'SHOW_DASHBOARD_FILTERS',
    ShowApplyFilterModal: 'SHOW_APPLY_FILTER_MODAL',
    CloseApplyFilterModal: 'CLOSE_APPLY_FILTER_MODAL',
    DashboardApplyFilterPending: 'DASHBOARD_APPLY_FILTER_PENDING',
    DashboardApplyFilterFulfilled: 'DASHBOARD_APPLY_FILTER_FULFILLED',

    GetSearchesPending: 'GET_SEARCHES_PENDING',
    GetSearchesFulfilled: 'GET_SEARCHES_FULFILLED',
    GetSearchesRejected: 'GET_SEARCHES_REJECTED',
    GetSearchPending: 'GET_SEARCH_PENDING',
    GetSearchFulfilled: 'GET_SEARCH_FULFILLED',
    GetSearchRejected: 'GET_SEARCH_REJECTED',
    CreateSearchPending: 'CREATE_SEARCH_PENDING',
    CreateSearchFulfilled: 'CREATE_SEARCH_FULFILLED',
    CreateSearchRejected: 'CREATE_SEARCH_REJECTED',
    RemoveSearchPending: 'DELETE_SEARCH_PENDING',
    RemoveSearchFulfilled: 'DELETE_SEARCH_FULFILLED',
    RemoveSearchRejected: 'DELETE_SEARCH_REJECTED',
    ShowDeleteSearchModal: 'SHOW_DELETE_SEARCH_MODAL',
    CloseDeleteSearchModal: 'CLOSE_DELETE_SEARCH_MODAL',
    ShowEditSearchModal: 'SHOW_EDIT_SEARCH_MODAL',
    CloseEditSearchModal: 'CLOSE_EDIT_SEARCH_MODAL',

    GetEventsPending: 'GET_EVENTS_PENDING',
    GetEventsFulfilled: 'GET_EVENTS_FULFILLED',
    GetEventsRejected: 'GET_EVENTS_REJECTED',
    SetEditEventPending: 'SET_EDIT_EVENT_PENDING',
    SetEditEventFulfilled: 'SET_EDIT_EVENT_FULFILLED',
    SetEditEventRejected: 'SET_EDIT_EVENT_REJECTED',
    SetDeleteEvent: 'SET_DELETE_EVENT_PENDING',
    EditEventPending: 'EDIT_EVENT_PENDING',
    EditEventFulfilled: 'EDIT_EVENT_FULFILLED',
    EditEventRejected: 'EDIT_EVENT_REJECTED',
    DeleteEventPending: 'DELETE_EVENT_PENDING',
    DeleteEventFulfilled: 'DELETE_EVENT_FULFILLED',
    DeleteEventRejected: 'DELETE_EVENT_REJECTED',
    AddEventPending: 'ADD_EVENT_PENDING',
    AddEventFulfilled: 'ADD_EVENT_FULFILLED',
    AddEventRejected: 'ADD_EVENT_REJECTED',
    ShowAddEventModal: 'SHOW_ADD_EVENT_MODAL',
    CloseAddEventModal: 'CLOSE_ADD_EVENT_MODAL',
    ShowAddEventTab: 'SHOW_ADD_EVENT_TAB',
    SetEventToScrollTo: 'SET_EVENT_TO_SCROLL',
    RemoveEventToScrollTo: 'REMOVE_EVENT_TO_SCROLL',
    SetTimelineInfoModalOpen: 'SET_TIMELINE_INFO_MODAL_OPEN',

    GetGoalsPending: 'GET_GOALS_PENDING',
    GetGoalsFulfilled: 'GET_GOALS_FULFILLED',
    GetGoalsRejected: 'GET_GOALS_REJECTED',
    GetGoalPending: 'GET_GOAL_PENDING',
    GetGoalFulfilled: 'GET_GOAL_FULFILLED',
    GetGoalRejected: 'GET_GOAL_REJECTED',
    GetTrackersPending: 'GET_TRACKERS_PENDING',
    GetTrackersFulfilled: 'GET_TRACKERS_FULFILLED',
    GetTrackersRejected: 'GET_TRACKERS_REJECTED',
    AddGoalPending: 'ADD_GOAL_PENDING',
    AddGoalFulfilled: 'ADD_GOAL_FULFILLED',
    AddGoalRejected: 'ADD_GOAL_REJECTED',
    EditGoalPending: 'EDIT_GOAL_PENDING',
    EditGoalFulfilled: 'EDIT_GOAL_FULFILLED',
    EditGoalRejected: 'EDIT_GOAL_REJECTED',
    DeleteGoalPending: 'DELETE_GOAL_PENDING',
    DeleteGoalFulfilled: 'DELETE_GOAL_FULFILLED',
    DeleteGoalRejected: 'DELETE_GOAL_REJECTED',
    AddTrackerPending: 'ADD_TRACKER_PENDING',
    AddTrackerFulfilled: 'ADD_TRACKER_FULFILLED',
    AddTrackerRejected: 'ADD_TRACKER_REJECTED',
    ShowAddGoalModal: 'SHOW_ADD_EVENT_MODAL',
    CloseAddGoalModal: 'CLOSE_ADD_EVENT_MODAL',
    ShowAddTrackerModal: 'SHOW_ADD_TRACKER_MODAL',
    CloseAddTrackerModal: 'CLOSE_ADD_TRACKER_MODAL',
    SetActiveMeasureTab: 'SET_ACTIVE_TAB',
    SetGoalToEdit: 'SET_GOAL_TO_EDIT',
    SetGoalToDelete: 'SET_GOAL_TO_DELETE',

    FetchBenchmarksPending: 'FETCH_BENCHMARKS_PENDING',
    FetchBenchmarksFulfilled: 'FETCH_BENCHMARKS_FULFILLED',
    FetchBenchmarksRejected: 'FETCH_BENCHMARKS_REJECTED',
    FetchBenchmarkDataPending: 'FETCH_BENCHMARK_DATA_PENDING',
    FetchBenchmarkDataFulfilled: 'FETCH_BENCHMARK_DATA_FULFILLED',
    FetchBenchmarkDataRejected: 'FETCH_BENCHMARK_DATA_REJECTED',
    FetchBenchmarkDataNoData: 'FETCH_BENCHMARK_DATA_NO_DATA',
    SetBenchmark: 'SET_BENCHMARK',
    RemoveBenchmark: 'REMOVE_BENCHMARK',
    QueryBenchmarkPending: 'QUERY_BENCHMARK_PENDING',
    QueryBenchmarkFulfilled: 'QUERY_BENCHMARK_FULFILLED',
    QueryBenchmarkRejected: 'QUERY_BENCHMARK_REJECTED',
    QueryBenchmarkNoData: 'QUERY_BENCHMARK_NO_DATA',
    ShowPreviewInBenchmarkModal: 'SHOW_PREVIEW_IN_BENCHMARK_MODAL',
    SaveBenchmarkPending: 'SAVE_BENCHMARK_PENDING',
    SaveBenchmarkFulfilled: 'SAVE_BENCHMARK_FULFILLED',
    SaveBenchmarkRejected: 'SAVE_BENCHMARK_REJECTED',
    DeleteBenchmarkPending: 'DELETE_BENCHMARK_PENDING',
    DeleteBenchmarkFulfilled: 'DELETE_BENCHMARK_FULFILLED',
    DeleteBenchmarkRejected: 'DELETE_BENCHMARK_REJECTED',
    OpenBenchmarkModal: 'OPEN_BENCHMARK_MODAL',
    CloseBenchmarkModal: 'CLOSE_BENCHMARK_MODAL',
    FetchBenchmarkDataForReportPending:
        'FETCH_BENCHMARK_DATA_FOR_REPORT_PENDING',
    FetchBenchmarkDataForReportFulfilled:
        'FETCH_BENCHMARK_DATA_FOR_REPORT_FULFILLED',
    FetchBenchmarkDataForReportRejected:
        'FETCH_BENCHMARK_DATA_FOR_REPORT_REJECTED',
    FetchBenchmarkDataForReportNoData:
        'FETCH_BENCHMARK_DATA_FOR_REPORT_NO_DATA',
    SetBenchmarkInReport: 'SET_BENCHMARK_IN_REPORT',
    SetHidePie: 'SET_HIDE_PIE',
    OpenEventsModal: 'OPEN_EVENTS_MODAL',
    CloseEventsModal: 'CLOSE_EVENTS_MODAL',
    ToggleChartEvent: 'TOGGLE_CHART_EVENT',
    AddAllChartEvents: 'ADD_ALL_CHART_EVENTS',
    RemoveAllChartEvents: 'REMOVE_ALL_CHART_EVENTS',
    SetChartEvents: 'SET_CHART_EVENTS',
    SetInvalidAppliedFilterStatus: 'SET_INVALID_APPLIED_FILTER_STATUS',

    SetAnalysisName: 'SET_ANALYSIS_NAME',
    UpdateMainSegments: 'UPDATE_MAIN_SEGMENTS',
    UpdateFilterSegment: 'UPDATE_FILTER_SEGMENT',
    UpdateBreakdownSegment: 'UPDATE_BREAKDOWN_SEGMENT',
    SetLightMode: 'SET_LIGHT_MODE',

    GetConfigurationsPending: 'GET_CONFIGURATIONS_PENDING',
    GetConfigurationsFulfilled: 'GET_CONFIGURATIONS_FULFILLED',
    GetConfigurationsRejected: 'GET_CONFIGURATIONS_REJECTED',
    GetDSTemplatesPending: 'GET_DS_TEMPLATES_PENDING',
    GetDSTemplatesFulfilled: 'GET_DS_TEMPLATES_FULFILLED',
    GetDSTemplatesRejected: 'GET_DS_TEMPLATES_REJECTED',
    GetPackagesPending: 'GET_PACKAGES_PENDING',
    GetPackagesFulfilled: 'GET_PACKAGES_FULFILLED',
    GetPackagesRejected: 'GET_PACKAGES_REJECTED',
    GetDSEnterprisesPending: 'GET_DS_ENTERPRISES_PENDING',
    GetDSEnterprisesFulfilled: 'GET_DS_ENTERPRISES_FULFILLED',
    GetDSEnterprisesRejected: 'GET_DS_ENTERPRISES_REJECTED',
    SaveTemplatePending: 'SAVE_TEMPLATE_PENDING',
    SaveTemplateFulfilled: 'SAVE_TEMPLATE_FULFILLED',
    SaveTemplateRejected: 'SAVE_TEMPLATE_REJECTED',
    SavePackagePending: 'SAVE_PACKAGE_PENDING',
    SavePackageFulfilled: 'SAVE_PACKAGE_FULFILLED',
    SavePackageRejected: 'SAVE_PACKAGE_REJECTED',
    SaveEnterprisePending: 'SAVE_ENTERPRISE_PENDING',
    SaveEnterpriseFulfilled: 'SAVE_ENTERPRISE_FULFILLED',
    SaveEnterpriseRejected: 'SAVE_ENTERPRISE_REJECTED',
    SaveConfigurationPending: 'SAVE_CONFIGURATION_PENDING',
    SaveConfigurationFulfilled: 'SAVE_CONFIGURATION_FULFILLED',
    SaveConfigurationRejected: 'SAVE_CONFIGURATION_REJECTED',

    GeneratePrimaryDataFulfilledInExplore:
        'GENERATE_PRIMARY_DATA_FULFILLED_IN_EXPLORE',
    GeneratePrimaryDataFulfilledInDetailSection:
        'GENERATE_PRIMARY_DATA_FULFILLED_IN_DETAIL_SECTION',
    GeneratePrimaryDataFulfilledInDashboard:
        'GENERATE_PRIMARY_DATA_FULFILLED_IN_DASHBOARD',
    GeneratePrimaryDataFulfilledInEditor:
        'GENERATE_PRIMARY_DATA_FULFILLED_IN_EDITOR',

    GetAnalysisTreePending: 'GET_ANALYSIS_TREE_PENDING',
    GetAnalysisTreeFulfilled: 'GET_ANALYSIS_TREE_FULFILLED',
    GetAnalysisTreeRejected: 'GET_ANALYSIS_TREE_REJECTED',
    GetAnalysisTreeNoData: 'GET_ANALYSIS_TREE_NO_DATA',

    GetFeatureFlagsPending: 'GET_FEATURE_FLAGS_PENDING',
    GetFeatureFlagsFulfilled: 'GET_FEATURE_FLAGS_FULFILLED',
    GetFeatureFlagsRejected: 'GET_FEATURE_FLAGS_REJECTED',
    GetFeatureFlagsNoData: 'GET_FEATURE_FLAGS_NO_DATA',

    AddUserRole: 'ADD_USER_ROLE',
    RemoveUserRole: 'REMOVE_USER_ROLE',
    UpdateUserRole: 'UPDATE_USER_ROLE',

    SetFieldsMappings: 'SET_FIELDS_MAPPINGS',

    SetTopLeftAction: 'SET_TOP_LEFT_ACTION',

    SetHasHiddenReports: 'SET_HAS_HIDDEN_REPORTS',
    SetNotifyAboutHiddenReports: 'SET_NOTIFY_ABOUT_HIDDEN_REPORTS',
    SetViewDashboard: 'SET_VIEW_DASHBOARD'
};

export default actionTypes;

import PropTypes from 'prop-types';
import SortableHead from '../../common/SortableHead';
import AnalysisTypes from '../../constants/AnalysisTypes';

export default function AttrTable(props) {
    const {
        lifecycle,
        analysisType,
        attributeName,
        sortCriteria,
        onSort,
        data,
        showGroupSummary,
        setActiveLabel,
        clearActiveLabel,
        toggleLabel,
        headerHeight,
        tableActive
    } = props;

    function getSummaryLabel() {
        if (
            analysisType === AnalysisTypes.Percentage &&
            lifecycle !== 'Retention'
        ) {
            return 'Total';
        }
        return 'Average';
    }

    const handleToggle = (...args) => {
        toggleLabel(...args);
    };

    return (
        <table className="attr-table__label-table">
            <thead>
                <tr style={{ height: headerHeight }}>
                    <th
                        className="attr-table__divider"
                        style={{ cursor: 'default' }}
                    />
                </tr>
                <tr>
                    <SortableHead
                        label={attributeName}
                        columnName="label"
                        sortCriteria={sortCriteria}
                        onSort={onSort}
                        className="attr-table__divider"
                    />
                </tr>
            </thead>
            <tbody>
                {data.map(d => (
                    <tr
                        key={d.id}
                        className={
                            d.active || d.selected ? 'attr-table__selected' : ''
                        }
                        style={{
                            cursor: 'pointer',
                            opacity: tableActive
                                ? d.active || d.selected
                                    ? 1
                                    : 0.24
                                : 1
                        }}
                        onMouseOver={setActiveLabel.bind(null, d.label)}
                        onMouseOut={clearActiveLabel.bind(null, d.label)}
                        onClick={handleToggle.bind(null, d.label)}
                    >
                        <td className="attr-table__divider">{d.label}</td>
                    </tr>
                ))}
                {data.length > 0 && showGroupSummary && (
                    <tr className="attr-table__bold">
                        <td className="attr-table__divider">
                            Filtered {getSummaryLabel()}
                        </td>
                    </tr>
                )}
                {data.length > 0 && (
                    <tr className="attr-table__bold">
                        <td className="attr-table__divider">
                            {getSummaryLabel()}
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );
}

AttrTable.propTypes = {
    attributeName: PropTypes.string,
    data: PropTypes.array,
    sortCriteria: PropTypes.object,
    onSort: PropTypes.func,
    lifecycle: PropTypes.string,
    analysisType: PropTypes.string,
    showGroupSummary: PropTypes.bool,
    setActiveLabel: PropTypes.func,
    clearActiveLabel: PropTypes.func,
    toggleLabel: PropTypes.func,
    headerHeight: PropTypes.number,
    tableActive: PropTypes.bool
};

import React, { useState, useEffect, useRef } from 'react';
import Modal from 'antd/lib/modal';
import sanitize from '../lib/sanitize';
import OpenText from '../common/OpenText';
import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';
import { useAppDispatch } from '../store/hooks';
import {
    closeCreateDashboardModal,
    createDashboard
} from '../dashboards/actions';

export default function CreateDashboardModal(props) {
    const dispatch = useAppDispatch();
    const { isOpen } = props;
    const [name, setName] = useState('');
    const inputRef = useRef();

    useEffect(() => {
        if (isOpen) {
            setName('');
        }
    }, [isOpen]);

    function handleNameChange(name) {
        setName(name);
    }

    function handleSave(e) {
        e.preventDefault();
        let dashboardName = name.trim();
        dashboardName = sanitize(dashboardName);
        dispatch(createDashboard(dashboardName));
    }

    function handleCancel() {
        dispatch(closeCreateDashboardModal());
    }

    function handleKeyDown(e) {
        if (e.key === 'Tab') {
            setTimeout(() => {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }, 0);
        }
    }

    return (
        <Modal
            visible={isOpen}
            width={450}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={handleCancel}
            zIndex={3000}
            destroyOnClose
        >
            <h3 className="mb40 font-subtitle">Name your dashboard</h3>
            <form>
                <OpenText
                    value={name}
                    onChange={handleNameChange}
                    onKeyDown={handleKeyDown}
                    inputRef={inputRef}
                    placeholder="Enter name here"
                    tabIndex={2}
                />
                <div className="modal__buttons mt24">
                    <div className="flex">
                        <Button
                            onClick={handleCancel}
                            componentType={ButtonTypes.type.TERTIARY}
                            tabIndex={2}
                        >
                            Cancel
                        </Button>
                    </div>
                    <div className="flex">
                        <Button
                            tabIndex={3}
                            componentType={ButtonTypes.type.TERTIARY}
                            onClick={() => dispatch(createDashboard())}
                        >
                            Skip
                        </Button>
                        <Button
                            type="submit"
                            tabIndex={4}
                            disabled={!name}
                            onClick={handleSave}
                            classes={['ml8']}
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </form>
        </Modal>
    );
}

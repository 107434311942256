import { createSlice } from '@reduxjs/toolkit';

import {
    addMessageMetadata,
    batchInvokeInsights,
    createFeedbackFromToken,
    deleteConversation,
    deleteFeedbackMessage,
    deleteMessage,
    getConfigSchema,
    getConversationMetadata,
    getConversationsList,
    getInputSchema,
    getMessagesList,
    getOutputSchema,
    invokeInsights,
    provideInsightFeedbackMessage,
    removeConversationMetadata,
    removeMessageMetadata,
    setConversationMetadata,
    streamEventsInsights,
    streamInvokeInsights,
    streamLogInsights,
} from './actions';

interface InsightsState {
    configSchema: any;
    error: string | null;
    inputSchema: any;
    insightsData: any;
    outputSchema: any;
    pending: boolean;
}

const initialState: InsightsState = {
    configSchema: null,
    error: null,
    inputSchema: null,
    insightsData: null,
    outputSchema: null,
    pending: false,
};

const insightsSlice = createSlice({
    name: 'ai/insights',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getInputSchema.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(getInputSchema.fulfilled, (state, action) => {
                state.pending = false;
                state.inputSchema = action.payload;
            })
            .addCase(getOutputSchema.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(getOutputSchema.fulfilled, (state, action) => {
                state.pending = false;
                state.outputSchema = action.payload;
            })
            .addCase(getConfigSchema.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(getConfigSchema.fulfilled, (state, action) => {
                state.pending = false;
                state.configSchema = action.payload;
            })
            .addCase(createFeedbackFromToken.pending, (state) => {
                state.pending = true;
            })
            .addCase(createFeedbackFromToken.fulfilled, (state) => {
                state.pending = false;
            })
            .addCase(invokeInsights.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(invokeInsights.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(batchInvokeInsights.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(batchInvokeInsights.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(streamInvokeInsights.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(streamInvokeInsights.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(streamLogInsights.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(streamLogInsights.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(streamEventsInsights.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(streamEventsInsights.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(getConversationsList.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(getConversationsList.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(deleteConversation.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(deleteConversation.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(getMessagesList.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(getMessagesList.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(deleteMessage.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(deleteMessage.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(provideInsightFeedbackMessage.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(provideInsightFeedbackMessage.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(deleteFeedbackMessage.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(deleteFeedbackMessage.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(addMessageMetadata.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(addMessageMetadata.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(removeMessageMetadata.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(removeMessageMetadata.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(setConversationMetadata.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(setConversationMetadata.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(getConversationMetadata.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(getConversationMetadata.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            })
            .addCase(removeConversationMetadata.pending, (state) => {
                state.pending = true;
                state.error = null;
            })
            .addCase(removeConversationMetadata.fulfilled, (state, action) => {
                state.pending = false;
                state.insightsData = action.payload;
            });
    },
});

export default insightsSlice.reducer;

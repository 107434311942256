import { useContext, Fragment } from 'react';
import { Animate } from 'react-move';
import { LineChartContext } from './LineChart';
import Constants from '../../constants/Constants';
import CanvasFonts from '../../constants/CanvasFonts';
import measureText from '../../lib/measureText';
import precisionRound from '../../lib/precisionRound';
import { useAppSelector } from '../../store/hooks';
import { ChartContext } from '../../contexts/ChartContext';

const GoalLine = () => {
    const {
        chartState: { chartWidth, mode }
    } = useContext(ChartContext);

    const { goal, unit } = useAppSelector(state => {
        switch (mode) {
            case 'measure': {
                return state.measure.goal;
            }
            default:
                return {} as any;
        }
    });

    const { yScale, valueWidth, PADDING } = useContext(LineChartContext);

    return (
        <Animate
            show={true}
            start={() => ({
                opacity: 0
            })}
            enter={() => ({
                opacity: [0.75],
                timing: {
                    duration: Constants.AnimationDuration,
                    ease: Constants.EasingFn
                }
            })}
            update={() => ({
                opacity: [0.75],
                timing: {
                    duration: Constants.AnimationDuration,
                    ease: Constants.EasingFn
                }
            })}
            leave={() => ({
                opacity: [0],
                timing: {
                    duration: Constants.AnimationDuration,
                    ease: Constants.EasingFn
                }
            })}
        >
            {state => {
                const indexText = `Goal: ${precisionRound(
                    goal.metricTrajectoryValues[0].desiredValue,
                    1
                )}${unit}`;

                const yIndex = yScale(
                    goal.metricTrajectoryValues[0].desiredValue
                );
                const xIndex = PADDING;
                const textWidth = measureText(
                    indexText,
                    CanvasFonts.Medium13
                ).width;

                return (
                    <Fragment>
                        <text
                            className="linechartv2__goal-text"
                            x={chartWidth - PADDING - valueWidth - textWidth}
                            y={yIndex + 2}
                            opacity={state.opacity}
                        >
                            {indexText}
                        </text>
                        <line
                            className="linechartv2__goal-line"
                            x1={xIndex}
                            y1={yIndex}
                            x2={
                                chartWidth -
                                PADDING -
                                valueWidth -
                                textWidth -
                                8
                            }
                            y2={yIndex}
                            opacity={state.opacity}
                        />
                    </Fragment>
                );
            }}
        </Animate>
    );
};

export default GoalLine;

import React from 'react';
import { ColumnsDropdownProps } from './ColumnsDropdown';

interface IColumnDropdownContext {
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    selectedOption?: any;
    setSelectedOption: (selectedOption: any) => void;
}

const initialContext: IColumnDropdownContext = {
    isOpen: false,
    setIsOpen: () => null,
    selectedOption: null,
    setSelectedOption: () => null
};

export const ColumnDropdownContext =
    React.createContext<IColumnDropdownContext>(initialContext);

const ColumnDropdownContextProvider = ({
    children,
    isOpen,
    setIsOpen,
    selectedOption,
    setSelectedOption
}: ColumnsDropdownProps) => {
    return (
        <ColumnDropdownContext.Provider
            value={{
                isOpen,
                setIsOpen,
                selectedOption,
                setSelectedOption
            }}
        >
            {children}
        </ColumnDropdownContext.Provider>
    );
};

const useColumnDropdownContext = () => React.useContext(ColumnDropdownContext);

export { ColumnDropdownContextProvider, useColumnDropdownContext };

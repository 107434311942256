import { createSlice, createAction } from '@reduxjs/toolkit';
import { getEnterpriseSurveys, getSurveyMetrics } from './actions';
import { ISnapshotSurveysState } from './types';
import actionTypes from '../../../constants/ActionTypes';

const initialState: ISnapshotSurveysState = {
    data: [],
    metrics: {},
    archived: []
};

const resetAction = createAction(actionTypes.SetEnterpriseIdComplete);

const slice = createSlice({
    name: 'snapshotSurveys',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(getEnterpriseSurveys.pending, state => {
            state.pending = true;
        });
        builder.addCase(getEnterpriseSurveys.fulfilled, (state, action) => {
            state.pending = false;
            state.loaded = true;
            state.data = action.payload.data || [];
            state.archived = action.payload.archived || [];
        });
        builder.addCase(getEnterpriseSurveys.rejected, (state, action) => {
            state.pending = false;
            state.error = action.error.message;
        });
        builder.addCase(getSurveyMetrics.pending, (state, action) => {
            state.metrics[action.meta.arg] = {
                pending: true
            };
        });
        builder.addCase(getSurveyMetrics.fulfilled, (state, action) => {
            state.metrics[action.payload.surveyId] = {
                pending: false,
                loaded: true,
                ...action.payload
            };
        });
        builder.addCase(getSurveyMetrics.rejected, (state, action) => {
            state.metrics[action.meta.arg] = {
                pending: false,
                error: action.error.message
            };
        });
        builder.addCase(resetAction, (state, action) => {
            state.data = [];
            state.archived = [];
            state.metrics = {};
            state.loaded = false;
        });
    }
});

export default slice.reducer;

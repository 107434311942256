import { useCallback } from 'react';
import WithPermissions from '../../common/WithPermissions';
import RbacActions from '../../constants/RbacActions';
import { READ_ONLY_ROLE_IDS } from '../../constants/RoleIds';
import { useAppSelector } from '../../store/hooks';
import Table from '../Table';
import { Role } from '../roles/model';
import useFilteredAndSortedItems from '../useFilteredAndSortedUsers';
import useSortable from '../useSortable';
import RolesTableRow from './RolesTableRow';

interface RolesTableProps {
    roles: Role[];
    numberOfUsers: Map<string, number>;
}

const RolesTable = ({ roles, numberOfUsers }: RolesTableProps) => {
    const { searchText } = useAppSelector(state => state.admin.adminConfig);
    const { sortConfig, requestSort } = useSortable({
        key: 'name',
        direction: 'asc',
        type: 'string'
    });

    const rolesCopy = useCallback(() => {
        let rolesCopy: any[] = roles.slice();

        rolesCopy = rolesCopy.map((role: Role) => ({
            ...role,
            count: numberOfUsers.get(role.id) || 0,
            searchValue: role.name.toLowerCase()
        }));

        return rolesCopy;
    }, [roles]);

    const filteredAndSortedRoles = useFilteredAndSortedItems({
        items: rolesCopy(),
        searchText,
        sortConfig
    });

    return (
        <Table>
            {searchText && filteredAndSortedRoles.length === 0 && (
                <div className="user-table__empty">
                    Sorry, no roles match your search query
                </div>
            )}
            {filteredAndSortedRoles.length > 0 && (
                <Table.HeadRow base={10}>
                    <Table.Cell span={4}>
                        <Table.Sortable
                            onClick={() => requestSort('name', 'string')}
                            sortActive={sortConfig.key === 'name'}
                            sortDirection={sortConfig.direction}
                        >
                            <span>Name</span>
                        </Table.Sortable>
                    </Table.Cell>
                    <Table.Cell span={5}>
                        <WithPermissions actions={[RbacActions['Users/View']]}>
                            <Table.Sortable
                                onClick={() => requestSort('count', 'number')}
                                sortActive={sortConfig.key === 'count'}
                                sortDirection={sortConfig.direction}
                            >
                                <span>Count</span>
                            </Table.Sortable>
                        </WithPermissions>
                    </Table.Cell>
                    <Table.Cell span={1}> {''}</Table.Cell>
                </Table.HeadRow>
            )}
            {filteredAndSortedRoles.map((role: Role) => {
                if (READ_ONLY_ROLE_IDS.includes(role.id)) return null;
                return <RolesTableRow key={role.id} role={role} />;
            })}
        </Table>
    );
};

export default RolesTable;

import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';

import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import MixPanel from '../../../constants/MixPanel';
import {
    clearFilters,
    generateInEditor,
    setAnalysisName,
    setFiltersFromReport,
    updateFilterSegment,
    updateMainSegments,
} from '../../../editor/filter/actions';
import { getInitialAnalysisNames } from '../../../explore/filter/Filters';
import getMixPanelObjectForFilters from '../../../lib/getMixPanelObjectForFilters';
import { track } from '../../../lib/segment';
import { getAllSegments } from '../../../store/actionUtils';
import AnalysisDropdown from './AnalysisDropdown';
import SegmentDropdown from './SegmentDropdown';

interface FiltersProps {
    // props
    onGenerate: () => void;
    useRadio: boolean[];

    // state
    analysisName: string;
    analysisDisplayName: string;
    analysisType: string;
    mainSegments: any[];
    filterSegment: any;
    breakdownSegment: any;
    mainNames: any[];
    filterNames: any[];
    attributes: any[];
    generatePending: boolean;
    generateDisabled: boolean;
    report?: any;
    completed: boolean;
    initialAnalysisNames: any[];
    filteredAnalysisNames?: string[];

    // actions
    onFiltersClear: any;
    setFiltersFromReport: any;
    setAnalysisName: any;
    updateMainSegments: any;
    updateFilterSegment: any;
    generateInEditor: any;
}

const Filters = ({
    onGenerate,
    useRadio = [true, true, true],
    analysisName,
    analysisDisplayName,
    analysisType,
    mainSegments,
    filterSegment,
    breakdownSegment,
    mainNames,
    filterNames,
    attributes,
    generatePending,
    generateDisabled,
    report,
    completed,
    initialAnalysisNames,
    onFiltersClear,
    setFiltersFromReport,
    setAnalysisName,
    updateMainSegments,
    updateFilterSegment,
    generateInEditor,
    filteredAnalysisNames,
}: FiltersProps) => {
    const containerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // prevents this effect from firing when we set report data from editor
        if (completed) return;
        if (report) {
            setFiltersFromReport(report);
        } else {
            onFiltersClear();
        }

        () => {
            onFiltersClear();
        };
    }, [report, completed]);

    function handleGenerate() {
        const segments = getAllSegments({
            mainSegments,
            filterSegment,
            breakdownSegment,
        });

        const mixpanelObj = getMixPanelObjectForFilters(analysisName, segments, attributes);
        track(MixPanel.Events.ReportEditorGenerate, mixpanelObj);
        generateInEditor();
        if (onGenerate) {
            onGenerate();
        }
    }

    function handleClear() {
        track(MixPanel.Events.ReportEditorClear);
        onFiltersClear();
    }

    return (
        <div className="explore__filters__section" ref={containerRef}>
            <div className="filters">
                <div className="filters__dropdowns">
                    <AnalysisDropdown
                        initialAnalysisNames={initialAnalysisNames}
                        analysisName={analysisName}
                        analysisDisplayName={analysisDisplayName}
                        analysisType={analysisType}
                        setAnalysisName={setAnalysisName}
                        useRadio={useRadio[0]}
                        filterNames={filteredAnalysisNames}
                    />
                    <SegmentDropdown
                        placeholder="Segment"
                        attributes={attributes}
                        segmentNames={mainNames}
                        selection={mainSegments}
                        update={updateMainSegments}
                        disabled={!analysisName}
                        useRadioOnValue={useRadio[1]}
                    />
                    <SegmentDropdown
                        placeholder="Filter"
                        attributes={attributes}
                        segmentNames={filterNames}
                        selection={filterSegment ? [filterSegment] : []}
                        update={updateFilterSegment}
                        disabled={!filterSegment && (!analysisName || mainSegments.length === 0)}
                        useRadioOnSegment={useRadio[2]}
                        useRadioOnValue={useRadio[2]}
                        isSecondary
                    />
                </div>
                <div className="filters__buttons" data-test="filters-actions">
                    <Button componentType={ButtonTypes.type.TERTIARY} onClick={handleClear} tabIndex={-1}>
                        Clear
                    </Button>
                    <Button
                        disabled={!analysisName || !mainSegments || !mainSegments.length || generateDisabled}
                        onClick={handleGenerate}
                        loading={generatePending}
                    >
                        Generate
                    </Button>
                </div>
            </div>
        </div>
    );
};

const dispatchProps = {
    onFiltersClear: clearFilters,
    setFiltersFromReport,
    setAnalysisName,
    updateMainSegments,
    updateFilterSegment,
    generateInEditor,
};

function mapState(state: any) {
    const { analysisNamesTree } = state.account;
    const { templates } = state.templates;
    const initialAnalysisNames = getInitialAnalysisNames(analysisNamesTree, templates);

    const {
        analysisDisplayName,
        analysisName,
        analysisType,
        attributes,
        breakdownSegment,
        filterNames,
        filterSegment,
        generateDisabled,
        mainNames,
        mainSegments,
    } = state.editor.filter;

    return {
        analysisDisplayName,
        analysisName,
        analysisType,
        attributes,
        breakdownSegment,
        filterNames,
        filterSegment,
        generateDisabled,
        mainNames,
        mainSegments,
        initialAnalysisNames,
        generatePending: state.explore.chart.generatePending,
        completed: state.editor.save.completed,
    };
}

export default connect(mapState, dispatchProps)(Filters);

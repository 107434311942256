import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CommentMention from './CommentMention';
import SpinnerDefault from '../common/SpinnerDefault';
import sanitize from '../lib/sanitize';
import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';

class CommentInput extends Component {
    constructor() {
        super();
        this.state = {
            text: ''
        };
    }

    handleSend() {
        const { text } = this.state;
        const sanitizedText = sanitize(text);
        if (sanitizedText) {
            this.props.onSend(sanitizedText).then(() => {
                this.setState({
                    text: ''
                });
            });
        } else if (text) {
            this.setState({
                text: ''
            });
        }
    }

    render() {
        const { text } = this.state;
        const showSend = !!text;
        const {
            placeholder,
            className,
            actors,
            placement,
            pending,
            type,
            mentionRef
        } = this.props;
        return (
            <div className={className}>
                <CommentMention
                    type={type}
                    actors={actors}
                    placement={placement}
                    placeholder={placeholder}
                    value={text}
                    onChange={text => this.setState({ text })}
                    mentionRef={mentionRef}
                />
                <div className="comment-card__input__btn-wrapper">
                    <Button
                        type={ButtonTypes.type.PRIMARY}
                        onClick={this.handleSend.bind(this)}
                        large={false}
                        style={{ display: showSend ? 'flex' : 'none' }}
                    >
                        {pending ? <SpinnerDefault size={20} /> : 'Send'}
                    </Button>
                </div>
            </div>
        );
    }
}

CommentInput.defaultProps = {
    placement: 'bottom'
};

CommentInput.propTypes = {
    type: PropTypes.string,
    actors: PropTypes.array,
    onSend: PropTypes.func,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    placement: PropTypes.string,
    pending: PropTypes.bool,
    mentionRef: PropTypes.object
};

export default CommentInput;

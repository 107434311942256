import moment from 'moment';
import { Fragment, useState } from 'react';
import Button from '../../common/Button';
import DatePicker from '../../common/DatePicker';
import Dropdown from '../../common/Dropdown';
import Stack from '../../common/Stack';
import WithPermissions from '../../common/WithPermissions';
import analysisTypes from '../../constants/AnalysisTypes';
import ButtonTypes from '../../constants/ButtonTypes';
import ChartTypes from '../../constants/ChartTypes';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import MenuIcon from '../../icons/Menu';
import StackedHorizontalBarIcon from '../../icons/StackedHorizontalBar';
import getDatePickerLabel from '../../lib/getDatePickerLabel';
import { track } from '../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSubtableChartType } from '../subtable/actions';
import AddToDashboardButton from './AddToDashboardButton';
import AiButton from '../../common/ButtonV2';
import DateRangeSelection from './DateRangeSelection';
import { SaveButton, SaveModal } from './SaveSearch';
import {
    selectDate,
    selectInterval,
    selectIntervalCount,
    selectIntervalRange
} from './actions';
import { generateAiInsightsInExplore } from '../aiInsights/actions';
import { setAiInsightsModelOpen } from '../aiInsights/reducer';
import AiInsightsModal from '../../common/AiInsightsModal';
import cn from '../../lib/cn';

const OPTIONS = [
    {
        value: 'Month',
        label: 'Monthly'
    },
    {
        value: 'Quarter',
        label: 'Quarterly'
    },
    {
        value: 'Year',
        label: 'Yearly'
    }
];

function IntervalSection() {
    const dispatch = useAppDispatch();
    const { showSubtable, interval, intervalCount, startDate, endDate, date } =
        useAppSelector(state => state.explore.filter);
    const {
        insights: aiInsights,
        aiInsightsModalOpen,
        pending,
        loaded,
        error
    } = useAppSelector(state => state.explore.aiInsights);
    const chart = useAppSelector(state => state.explore.chart);

    const { analysisType, dates } = chart;
    const { latestDataUpdate, featureFlags } = useAppSelector(
        state => state.account
    );
    const subtable = useAppSelector(state => state.explore.subtable);

    const generated = showSubtable ? subtable.generated : chart.generated;
    const generatePending = showSubtable
        ? subtable.generatePending
        : chart.generatePending;
    const generateNoData = showSubtable ? subtable.empty : chart.generateNoData;
    const generateInvalidVariation = showSubtable
        ? subtable.invalidVariation
        : chart.generateInvalidVariation;

    const [arithmeticDropdownOpen, setArithmeticDropdownOpen] = useState(false);

    const { subtableChartType, allowChartToggle } = useAppSelector(
        state => state.explore.subtable
    );

    const [showSavePopup, setShowSavePopup] = useState(false);

    if (!generated && !generatePending && !generateNoData) {
        return null;
    }

    if (generateInvalidVariation) {
        return null;
    }

    function handleIntervalChange(interval: string) {
        track(MixPanel.Events.ExploreSelectInterval, {
            Interval: interval
        });
        dispatch(selectInterval(interval));
    }

    function handleIntervalCountChange(intervalCount: number) {
        track(MixPanel.Events.ExploreSelectIntervalCount, {
            'Interval Count': intervalCount
        });

        dispatch(selectIntervalCount(intervalCount));
    }

    function handleIntervalRangeChange(startDate: string, endDate: string) {
        track(MixPanel.Events.ExploreSelectIntervalRange, {
            'Start Date': startDate,
            'End Date': endDate
        });
        dispatch(selectIntervalRange(startDate, endDate));
    }

    const handleDateSelect = (d: any) => {
        selectInterval('Month');
        const formattedDate = moment(d).format('YYYY-MM-DD');
        dispatch(selectDate(formattedDate));
        setArithmeticDropdownOpen(false);
    };

    const selectedDate = date ?? moment(latestDataUpdate).format('YYYY-MM-DD');

    return (
        <>
            <div className="explore-interval-section">
                {analysisType === analysisTypes.Arithmetic ? (
                    <div style={{ marginLeft: '0.8rem' }}>
                        <DatePicker
                            buttonLarge={false}
                            placeholder={'Month'}
                            disabled={false}
                            isOpen={arithmeticDropdownOpen}
                            label={
                                selectedDate
                                    ? moment(selectedDate).format('MMMM yyyy')
                                    : 'Month'
                            }
                            onClick={() => {
                                setArithmeticDropdownOpen(
                                    !arithmeticDropdownOpen
                                );
                            }}
                            selectsRange={false}
                            onDateSelect={handleDateSelect}
                            formatType="Month"
                        />
                    </div>
                ) : showSubtable ? (
                    <Stack
                        flexDirection="row"
                        alignItems="center"
                        gap=".8rem"
                        style={{ marginLeft: '0.8rem' }}
                    >
                        <Dropdown
                            selectedStyle={ButtonTypes.selectedStyle.UNDERLINE}
                            options={OPTIONS}
                            selectedOption={interval}
                            onClick={handleIntervalChange}
                            marginLeft={-16}
                            buttonLarge={false}
                            buttonType={ButtonTypes.type.SECONDARY}
                        />
                        <DatePicker
                            buttonLarge={false}
                            placeholder={interval}
                            disabled={false}
                            isOpen={arithmeticDropdownOpen}
                            label={getDatePickerLabel(
                                selectedDate,
                                interval as any
                            )}
                            onClick={() => {
                                setArithmeticDropdownOpen(
                                    !arithmeticDropdownOpen
                                );
                            }}
                            selectsRange={false}
                            onDateSelect={handleDateSelect}
                            formatType={interval as any}
                        />
                    </Stack>
                ) : (
                    <DateRangeSelection
                        interval={interval}
                        selectInterval={handleIntervalChange}
                        intervalCount={intervalCount}
                        selectIntervalCount={handleIntervalCountChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectIntervalRange={handleIntervalRangeChange}
                        dates={dates}
                        fromEditor
                    />
                )}
                <Stack flexDirection="row" alignItems="center" gap=".8rem">
                    <WithPermissions
                        actions={[RbacActions['Explore/SaveReport']]}
                    >
                        <div className={cn('mr-[-.8rem]')}>
                            <SaveButton
                                // @ts-ignore
                                onClick={show => setShowSavePopup(show)}
                                text={'Save'}
                            />
                        </div>
                    </WithPermissions>
                    <WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
                        <AddToDashboardButton />
                        {showSubtable && allowChartToggle && (
                            <Fragment>
                                <Button
                                    componentType={
                                        subtableChartType === ChartTypes.Matrix
                                            ? ButtonTypes.type.PRIMARY
                                            : ButtonTypes.type.TERTIARY
                                    }
                                    circle={true}
                                    onClick={() =>
                                        dispatch(
                                            setSubtableChartType(
                                                ChartTypes.Matrix
                                            )
                                        )
                                    }
                                >
                                    <MenuIcon />
                                </Button>
                                <Button
                                    componentType={
                                        subtableChartType ===
                                        ChartTypes.HorizontalBar
                                            ? ButtonTypes.type.PRIMARY
                                            : ButtonTypes.type.TERTIARY
                                    }
                                    circle={true}
                                    onClick={() =>
                                        dispatch(
                                            setSubtableChartType(
                                                ChartTypes.HorizontalBar
                                            )
                                        )
                                    }
                                >
                                    <StackedHorizontalBarIcon />
                                </Button>
                            </Fragment>
                        )}
                    </WithPermissions>
                    {featureFlags.aiInsights && (
                        <AiButton
                            onClick={() => {
                                dispatch(generateAiInsightsInExplore());
                            }}
                            size="sm"
                        >
                            <div
                                className={cn('flex items-center gap-[.4rem]')}
                            >
                                <span className={cn('font-micro-text')}>
                                    Insights
                                </span>
                            </div>
                        </AiButton>
                    )}
                </Stack>
            </div>
            {showSavePopup && (
                <SaveModal
                    // @ts-ignore
                    showPopup={show => setShowSavePopup(show)}
                />
            )}
            {aiInsightsModalOpen && (
                <AiInsightsModal
                    onClose={() => dispatch(setAiInsightsModelOpen(false))}
                    aiInsights={aiInsights}
                    pending={pending}
                    loaded={loaded}
                    error={error}
                />
            )}
        </>
    );
}

export default IntervalSection;

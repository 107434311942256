import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import Checkbox from 'antd/lib/checkbox';
import CommentInput from './CommentInput';
import CommentDetail from './CommentDetail';
import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';

export default class Comment extends Component {
    constructor() {
        super();
        this.comp = React.createRef();
        this.state = {
            resolved: false
        };
    }

    componentDidMount() {
        if (this.props.shouldHighlight) {
            this.comp.current.scrollIntoView({ block: 'center' });
        }
    }

    handleResolveChange() {
        let { resolved } = this.state;
        resolved = !resolved;
        this.setState({ resolved });
        if (resolved) {
            this.props.onResolve();
            this.timeout = setTimeout(this.props.onDelete, 10000);
        }
    }

    handleUndo() {
        this.setState({ resolved: false });
        clearTimeout(this.timeout);
        this.props.onUndo();
    }

    render() {
        const {
            comment,
            actors,
            userId,
            onReply,
            onCommentUpdate,
            onReplyUpdate,
            shouldHighlight,
            addReplyPending,
            updateCommentPending,
            updateReplyPending,
            onEdit,
            onCancel,
            editId,
            showEdit
        } = this.props;
        const {
            commentId,
            text,
            actorId,
            actorInfo,
            commentDate,
            replies
        } = comment;
        const { profilePicture, firstName, lastName } = actorInfo || {};
        const { resolved } = this.state;

        return (
            <div
                className={
                    shouldHighlight
                        ? 'comment-card comment-card--highlight'
                        : 'comment-card'
                }
                ref={this.comp}
            >
                <div className="comment-card__actions">
                    <div>
                        {resolved && (
                            <Button
                                componentType={ButtonTypes.type.TERTIARY}
                                onClick={this.handleUndo.bind(this)}
                            >
                                Undo
                            </Button>
                        )}
                    </div>
                    <div>
                        <Checkbox
                            checked={resolved}
                            disabled={resolved}
                            onChange={this.handleResolveChange.bind(this)}
                        >
                            Mark as resolved
                        </Checkbox>
                    </div>
                </div>
                {!resolved && (
                    <Fragment>
                        <CommentDetail
                            editable={userId === actorId}
                            showEdit={showEdit && editId === commentId}
                            onEdit={onEdit.bind(null, commentId)}
                            onCancel={onCancel}
                            actors={actors}
                            profilePicture={profilePicture}
                            firstName={firstName}
                            lastName={lastName}
                            text={text}
                            date={commentDate}
                            onUpdate={onCommentUpdate.bind(null, commentId)}
                            updatePending={updateCommentPending}
                        />
                        {replies.map(reply => {
                            const {
                                text,
                                actorId,
                                actorInfo,
                                replyDate,
                                replyId
                            } = reply;
                            const {
                                profilePicture,
                                firstName,
                                lastName,
                                email
                            } = actorInfo;
                            return (
                                <CommentDetail
                                    key={replyId}
                                    editable={actorId === userId}
                                    showEdit={showEdit && editId === replyId}
                                    onEdit={onEdit.bind(null, replyId)}
                                    onCancel={onCancel}
                                    actors={actors}
                                    profilePicture={profilePicture}
                                    firstName={firstName}
                                    lastName={lastName}
                                    email={email}
                                    text={text}
                                    date={replyDate}
                                    onUpdate={onReplyUpdate.bind(
                                        null,
                                        commentId,
                                        replyId
                                    )}
                                    updatePending={updateReplyPending}
                                />
                            );
                        })}
                        <CommentInput
                            type="reply"
                            className="comment-card__input"
                            placeholder="Reply"
                            onSend={onReply}
                            actors={actors}
                            pending={addReplyPending}
                        />
                    </Fragment>
                )}
            </div>
        );
    }
}

Comment.propTypes = {
    userId: PropTypes.string,
    comment: PropTypes.object,
    actors: PropTypes.array,
    onResolve: PropTypes.func,
    onDelete: PropTypes.func,
    onUndo: PropTypes.func,
    onReply: PropTypes.func,
    onCommentUpdate: PropTypes.func,
    onEdit: PropTypes.func,
    onCancel: PropTypes.func,
    onReplyUpdate: PropTypes.func,
    shouldHighlight: PropTypes.bool,
    addReplyPending: PropTypes.bool,
    updateCommentPending: PropTypes.bool,
    updateReplyPending: PropTypes.bool,
    editId: PropTypes.string,
    showEdit: PropTypes.bool
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { login, showForgot } from './actions';
import Button from '../common/Button';
import TextField from '../common/TextField';
import MixPanel from '../constants/MixPanel';
import qs from 'qs';
import { page } from '../lib/segment';
import WaitPage from '../routes/WaitPage';
import { replaceRoute } from '../common/actions';
import { setWinked } from '../routes/actions';

function Password({
    loginPending,
    loginError,
    loggedIn,
    location,
    replaceRoute,
    setWinked,
    onLogin,
    onForgot
}) {
    const [username, setUsername] = useState(getEmail());
    const [password, setPassword] = useState('');
    const [disabled, setDisabled] = useState(false);
    const [wink, setWink] = useState(false);

    useEffect(() => {
        page(MixPanel.Pages.PasswordLogin);
    }, []);

    useEffect(() => {
        if (loggedIn) {
            setWink(true);
            setTimeout(() => {
                setWink(false);
                const redirectUrl = getRedirectUrl();
                replaceRoute(redirectUrl);
                setWinked();
            }, 3000);
        }
    }, [loggedIn]);

    function getEmail() {
        const { search } = location;
        let email = '';
        const query = qs.parse(search, { ignoreQueryPrefix: true });
        if (query.email) {
            email = query.email.replace(' ', '+');
        }
        return email;
    }

    function getRedirectUrl() {
        let redirectUrl = '/';
        const { search } = location;
        const query = qs.parse(search, { ignoreQueryPrefix: true });
        if (query.redirectUrl) {
            redirectUrl = decodeURIComponent(query.redirectUrl);
        }
        return redirectUrl;
    }

    function handleSubmit(e) {
        e.preventDefault();
        const redirectUrl = getRedirectUrl();
        onLogin(username, password, redirectUrl);
    }

    function handleUsernameChange(e) {
        const username = e.target.value;
        const disabled = !username || !password;
        setUsername(username);
        setDisabled(disabled);
    }

    function handlePasswordChange(e) {
        const password = e.target.value;
        const disabled = !username || !password;
        setPassword(password);
        setDisabled(disabled);
    }

    function handleForgotClick(e) {
        e.preventDefault();
        onForgot();
    }

    if (loginPending || wink) {
        return <WaitPage wink={wink} />;
    }

    return (
        <div className="password">
            <div className="password__title">Log in to Dandi</div>
            <div className="password__form">
                <form onSubmit={e => e.preventDefault()}>
                    <TextField
                        placeholder="Email"
                        autoFocus={true}
                        value={username}
                        onChange={handleUsernameChange}
                        autocomplete="email"
                        danger={loginError}
                        classes={['mb8']}
                    ></TextField>
                    <TextField
                        placeholder="Password"
                        type="password"
                        value={password}
                        onChange={handlePasswordChange}
                        autocomplete="current-password"
                        danger={loginError}
                        classes={['mb8']}
                    ></TextField>
                    <Button
                        type="submit"
                        large
                        disabled={disabled}
                        onClick={handleSubmit}
                    >
                        Log in
                    </Button>
                    <div className="password__form__forgot">
                        <a
                            className="link link--bright"
                            onClick={handleForgotClick}
                        >
                            Forgot password?
                        </a>
                    </div>
                </form>
            </div>
        </div>
    );
}

Password.propTypes = {
    onLogin: PropTypes.func,
    onForgot: PropTypes.func,
    loginPending: PropTypes.bool,
    loginError: PropTypes.bool,
    loggedIn: PropTypes.bool,
    location: PropTypes.object,
    replaceRoute: PropTypes.func,
    setWinked: PropTypes.func
};

function mapStateToProps(state) {
    return {
        loginPending: state.auth.loginPending,
        loginError: state.auth.loginError,
        loggedIn: state.auth.loggedIn
    };
}

const dispatchProps = {
    onLogin: login,
    onForgot: showForgot,
    replaceRoute,
    setWinked
};

export default connect(mapStateToProps, dispatchProps)(Password);

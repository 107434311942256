import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function Checkbox({
    type,
    onClick,
    checked,
    children,
    style,
    disabled,
    testId
}: any) {
    return (
        <label
            style={{ ...style }}
            className={classNames('checkbox', `checkbox--${type}`, {
                [`checkbox--${type}--checked`]: checked,
                [`checkbox--${type}--disabled`]: disabled
            })}
            data-test={testId}
            onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                onClick(!checked);
            }}
        >
            <span className="checkbox__input-wrapper">
                <input type="checkbox" className="checkbox__input" />
                <span className="checkbox__inner"></span>
            </span>
            <span className="checkbox__label">{children}</span>
        </label>
    );
}

Checkbox.propTypes = {
    onClick: PropTypes.func,
    type: PropTypes.string,
    checked: PropTypes.bool,
    children: PropTypes.node,
    style: PropTypes.object,
    disabled: PropTypes.bool,
    seeminglyDisabled: PropTypes.bool,
    testId: PropTypes.string
};

Checkbox.defaultProps = {
    type: 'default',
    testId: 'checkbox'
};

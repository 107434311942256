import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M3.445 10.418a5.181 5.181 0 0 1-.457-1.162 4.879 4.879 0 0 1-.154-1.223c0-1.442.502-2.669 1.505-3.681C5.343 3.34 6.563 2.833 8.001 2.833h.52L7.288 1.6 7.99.897l2.436 2.436L7.99 5.77l-.702-.702L8.52 3.833h-.52c-1.158 0-2.142.408-2.952 1.223-.81.816-1.215 1.808-1.215 2.977a3.865 3.865 0 0 0 .361 1.635l-.75.75Zm4.566 4.684-2.436-2.435 2.436-2.436.702.702-1.233 1.234H8c1.159 0 2.143-.408 2.953-1.223.81-.816 1.214-1.808 1.214-2.977a3.867 3.867 0 0 0-.361-1.635l.75-.75c.202.375.354.763.457 1.162.103.4.154.807.154 1.223 0 1.442-.501 2.669-1.505 3.681-1.003 1.012-2.224 1.519-3.661 1.519h-.52L8.712 14.4l-.702.702Z"
        />
    </svg>
);
export default SvgComponent;

import { connect } from 'react-redux';
import Chart from '../../editor/chart/Chart';
import { navigateToExplore } from '../../editor/chart/actions';
import {
    navigate,
    navigateFromMatrix,
    selectCurrentTime,
    setExpandedInReport,
    setRowHeightInReport,
    setSortCriteriaInReport,
    showDeleteReportModal
} from './actions';

function mapState(state, ownProps) {
    const { reportId, disableInteractions, isResizing } = ownProps;
    const reportData = state.reports[reportId] || {};
    return {
        ...reportData,
        source: 'dashboard',
        disableLinks: disableInteractions,
        role: state.auth.role,
        enterpriseName: state.account.enterpriseName,
        allEvents: state.timeline.events,
        eventIds: reportData.eventIds || [],
        lightMode: state.auth.userInfo.lightMode,
        isResizing
    };
}

const dispatchProps = {
    selectCurrentTime,
    navigate,
    navigateToExplore,
    navigateFromMatrix,
    showDeleteReportModal,
    setRowHeightInReport,
    setSortCriteriaInReport,
    setExpandedInReport
};

export default connect(mapState, dispatchProps)(Chart);

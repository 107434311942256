import Modal from 'antd/lib/modal/Modal';
import React from 'react';
import Button from '../../../common/Button';
import TextField from '../../../common/TextField';
import ButtonTypes from '../../../constants/ButtonTypes';
import sanitize from '../../../lib/sanitize';

interface SaveModalProps {
    isOpen?: boolean;
    onSave?: (name: string) => void;
    onCancel?: () => void;
    defaultName?: string;
}

const SaveModal = ({
    isOpen,
    onSave,
    onCancel,
    defaultName
}: SaveModalProps) => {
    const [name, setName] = React.useState(defaultName);

    React.useEffect(() => {
        if (isOpen) {
            setName(defaultName);
        }
    }, [isOpen]);

    const handleNameChange = (name: string) => {
        setName(name);
    };

    const handleSave = (e: any) => {
        e?.preventDefault();
        const templateName = sanitize(name?.trim());
        onSave?.(templateName);
    };

    return (
        <Modal
            open={isOpen}
            width={450}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={onCancel}
            zIndex={3000}
            destroyOnClose
        >
            <div className="save-template-modal modal">
                <form onSubmit={handleSave}>
                    <div className="modal__header">
                        <div className="modal__title">Name Your Report</div>
                    </div>
                    <div className="modal__content">
                        <TextField
                            value={name || ''}
                            onChange={e => handleNameChange(e.target.value)}
                            placeholder="Enter name here"
                            onClose={() => setName('')}
                            onKeyDown={(e: any) => {
                                if (e.key === 'Enter') handleSave(e);
                            }}
                            showClose
                        />
                        <div className="modal__footer">
                            <div>
                                <Button
                                    componentType={ButtonTypes.type.TERTIARY}
                                    onClick={onCancel}
                                    classes={['mr8']}
                                >
                                    Cancel
                                </Button>
                            </div>
                            <Button
                                type="submit"
                                onClick={handleSave}
                                disabled={!name}
                            >
                                Save
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Modal>
    );
};

export default SaveModal;

const divisions = [
    {
        region: 'Northeast',
        division: 'New England'
    },
    {
        region: 'Northeast',
        division: 'Middle Atlantic'
    },
    {
        region: 'Midwest',
        division: 'Eact North Central',
        label: 'East North Central'
    },
    {
        region: 'Midwest',
        division: 'West North Central'
    },
    {
        region: 'South',
        division: 'South Atlantic'
    },
    {
        region: 'South',
        division: 'Eact Southe Central',
        label: 'East South Central'
    },
    {
        region: 'South',
        division: 'West Southe Central',
        label: 'West South Central'
    },
    {
        region: 'West',
        division: 'Mountain'
    },
    {
        region: 'West',
        division: 'Pacific'
    }
];

export default divisions;

import allColors from './AddEvent/EventDetails/ColorPicker/colors';
import { ColorName } from './AddEvent/EventDetails/ColorPicker/types';
import { IEvent } from './types';

export function getPercentageFromDate(
    date: string,
    start: string,
    end: string
) {
    const startDate = new Date(start);
    const endDate = new Date(end);
    let currentDate = new Date(date);
    currentDate.setMonth(currentDate.getMonth(), 15);

    const total = endDate.getTime() - startDate.getTime();
    const current = currentDate.getTime() - startDate.getTime();

    return (current / total) * 100;
}

export function getColorFromName(name: ColorName) {
    const color = allColors.find(color =>
        name === 'white' ? color.name === 'default' : color.name === name
    );
    return color?.color;
}

export function toTitleCase(str: string) {
    if (!str) return '';
    return str.replace(/\w\S*/g, function (txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    });
}

export function findEarliestAndLatestDate(events: IEvent[]) {
    let earliestDate = new Date();
    let latestDate = new Date();

    events.forEach(event => {
        const startDate = new Date(event.dateStart);
        const endDate = new Date(event.dateEnd!);

        if (startDate < earliestDate) {
            earliestDate = startDate;
        }

        if (endDate > latestDate) {
            latestDate = endDate;
        }
    });

    return { earliestDate, latestDate };
}

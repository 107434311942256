import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../common/Checkbox';
import Tooltip from 'antd/lib/tooltip';
import sortBy from 'lodash/sortBy';
import Scrollbar from 'perfect-scrollbar';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import TextField from '../../common/TextField';
import TextFieldTypes from '../../constants/TextFieldTypes';

export default function Filter({
    employeeAttribute,
    rowData,
    rowFilter,
    columnData,
    columnFilter,
    onFilter,
    onClose
}) {
    const [search, setSearch] = useState('');
    let [selectedRows, setSelectedRows] = useState(rowFilter);
    let [selectedColumns, setSelectedColumns] = useState(columnFilter);
    const container = useRef();
    const comp = useRef();

    useEffect(() => {
        let ps = new Scrollbar(comp.current);

        return () => {
            ps.destroy();
            ps = null;
        };
    }, []);

    function handleSearch(e) {
        setSearch(e.target.value);
    }

    function handleRowCheckChange(value, checked) {
        selectedRows = selectedRows.slice();
        if (checked) {
            selectedRows.push(value);
        } else {
            const index = selectedRows.findIndex(s => s === value);
            if (index !== -1) {
                selectedRows.splice(index, 1);
            }
        }
        setSelectedRows(selectedRows);
    }

    function handleColumnCheckChange(value, checked) {
        selectedColumns = selectedColumns.slice();
        if (checked) {
            selectedColumns.push(value);
        } else {
            const index = selectedColumns.findIndex(s => s === value);
            if (index !== -1) {
                selectedColumns.splice(index, 1);
            }
        }
        setSelectedColumns(selectedColumns);
    }

    function handleSelectAll() {
        let selectedRows = rowData.map(d => d.value);
        if (search) {
            const searchRows = rowData.filter(d =>
                d.text.toLowerCase().includes(search.toLowerCase())
            );
            selectedRows = searchRows.map(d => d.value);
        }
        setSelectedRows(selectedRows);
    }

    function handleClear() {
        setSelectedRows([]);
        setSelectedColumns([]);
    }

    function handleClear2() {
        if (search) {
            const searchRows = rowData.filter(d =>
                d.text.toLowerCase().includes(search.toLowerCase())
            );
            const newSelectedRows = selectedRows.slice();
            searchRows.forEach(sr => {
                const index = newSelectedRows.findIndex(r => r === sr.value);
                if (index > -1) {
                    newSelectedRows.splice(index, 1);
                }
            });
            setSelectedRows(newSelectedRows);
        } else {
            setSelectedRows([]);
        }
    }

    let filteredData = sortBy(rowData, d => d.text);
    if (search) {
        filteredData = filteredData.filter(d =>
            d.text.toLowerCase().includes(search.toLowerCase())
        );
    }

    return (
        <div className="attr-filter" ref={container}>
            <div className="attr-filter__row">
                <div className="attr-filter__header">{employeeAttribute}</div>
                <div className="attr-filter__all">
                    <Button
                        componentType={ButtonTypes.type.TERTIARY}
                        large={false}
                        onClick={handleSelectAll}
                    >
                        Select All
                    </Button>
                    <Button
                        componentType={ButtonTypes.type.TERTIARY}
                        onClick={handleClear2}
                        large={false}
                    >
                        Clear
                    </Button>
                </div>
                <div className="attr-filter__search">
                    <TextField
                        componentType={TextFieldTypes.type.SIMPLE}
                        value={search}
                        isSearch={true}
                        onChange={handleSearch}
                        placeholder="Search"
                        type="text"
                    ></TextField>
                </div>
                <div className="attr-filter__message">
                    Empty segments are hidden from the list below
                </div>
                <div className="attr-filter__list" ref={comp}>
                    {filteredData.map(d => (
                        <div className="attr-filter__item" key={d.value}>
                            <Checkbox
                                checked={selectedRows.includes(d.value)}
                                onClick={handleRowCheckChange.bind(
                                    null,
                                    d.value
                                )}
                            >
                                {d.text.length > 30 ? (
                                    <Tooltip
                                        title={d.text}
                                        getPopupContainer={() =>
                                            container.current
                                        }
                                    >
                                        <span className="attr-filter__item__text">
                                            {d.text}
                                        </span>
                                    </Tooltip>
                                ) : (
                                    <span className="attr-filter__item__text">
                                        {d.text}
                                    </span>
                                )}
                            </Checkbox>
                        </div>
                    ))}
                </div>
                {filteredData.length === 0 && (
                    <div className="attr-filter__empty">
                        No results for that search term
                    </div>
                )}
            </div>
            <div className="attr-filter__column">
                <div className="attr-filter__header">Segments</div>
                <div className="attr-filter__list">
                    {columnData.map(d => (
                        <div className="attr-filter__item" key={d.value}>
                            <Checkbox
                                checked={selectedColumns.includes(d.value)}
                                onClick={handleColumnCheckChange.bind(
                                    null,
                                    d.value
                                )}
                            >
                                {d.selected ? (
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: d.text.html
                                        }}
                                    />
                                ) : (
                                    <span>{d.text.text}</span>
                                )}
                            </Checkbox>
                        </div>
                    ))}
                </div>
            </div>
            <div className="attr-filter__buttons">
                <Button
                    componentType={ButtonTypes.type.SECONDARY}
                    onClick={onClose}
                >
                    Cancel
                </Button>
                <div className="attr-filter__buttons__right">
                    <Button
                        componentType={ButtonTypes.type.TERTIARY}
                        onClick={handleClear}
                    >
                        Clear
                    </Button>
                    <Button
                        onClick={onFilter.bind(
                            null,
                            selectedRows,
                            selectedColumns
                        )}
                    >
                        Filter
                    </Button>
                </div>
            </div>
        </div>
    );
}

Filter.propTypes = {
    employeeAttribute: PropTypes.string,
    rowData: PropTypes.array,
    rowFilter: PropTypes.array,
    columnData: PropTypes.array,
    columnFilter: PropTypes.array,
    onFilter: PropTypes.func,
    onClose: PropTypes.func
};

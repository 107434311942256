import { Template } from '../../../../templates/models';

export function getAttributesByAnalysises(
    analysises: string[],
    templates: Template[],
    attributes: any[],
    typeId: 'mainNames' | 'filterNames' | 'breakdownNames'
) {
    if (!analysises || analysises.length === 0) {
        return [];
    }

    const variationSet = new Set();

    if (analysises.includes('Any')) {
        templates.forEach((template: Template) => {
            if (typeof template[typeId] === 'object') {
                // @ts-ignore
                template[typeId].forEach((name: string) => {
                    variationSet.add(name);
                });
            }
        });
    } else {
        analysises.forEach((analysis: string) => {
            const template = templates.find(
                (template: Template) => template.analysisName === analysis
            );

            if (template && template[typeId]) {
                // @ts-ignore
                template[typeId].forEach((name: string) => {
                    variationSet.add(name);
                });
            }
        });
    }

    const newAttributes = attributes.filter((attr: any) => {
        return variationSet.has(attr.attributeName.replace(/ /g, ''));
    });

    return newAttributes;
}

import RbacActions from '../constants/RbacActions';

export default function hasPermission(
    permissions: any[],
    actions: RbacActions[]
) {
    if (!permissions || permissions.length === 0) return false;

    const allowed = permissions.some(
        permission =>
            actions?.includes(permission.action) || permission.action === '*'
    );

    return allowed;
}

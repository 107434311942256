import { Modal } from 'antd';
import React, { ChangeEvent, useCallback, useRef, useState } from 'react';

import Button from '../../common/Button';
import LottieAnimation from '../../common/LottieAnimation';
import Stack from '../../common/Stack';
import TextField from '../../common/TextField';
import WithPermissions from '../../common/WithPermissions';
import ButtonTypes from '../../constants/ButtonTypes';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import TextFieldTypes from '../../constants/TextFieldTypes';
import { track } from '../../lib/segment';
import Loader from '../../lottie/graph-loader.json';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import Table from '../Table';
import { setSearchText } from '../config/reducer';
import useFilteredAndSortedItems from '../useFilteredAndSortedUsers';
import useSortable from '../useSortable';
import GroupRoleHandler from './GroupRoleHandler';
import GroupUserList from './GroupUserList';
import { Group } from './models';

interface GroupsProps {}

const Groups = (props: GroupsProps) => {
    const textFieldRef = useRef<HTMLInputElement>(null);
    const { searchText } = useAppSelector(state => state.admin.adminConfig);
    const dispatch = useAppDispatch();
    const { allGroups, pending } = useAppSelector(state => state.admin.groups);
    const [userModalOpen, setUserModalOpen] = useState(false);
    const [currentGroup, setCurrentGroup] = useState<Group>();
    const { sortConfig, requestSort } = useSortable({
        key: 'name',
        direction: 'asc',
        type: 'string'
    });

    const groupsCopy = useCallback(() => {
        let groupsCopy: any[] = allGroups.slice();

        groupsCopy = groupsCopy.map((group: Group) => ({
            ...group,
            searchValue: group.name.toLowerCase()
        }));

        return groupsCopy;
    }, [allGroups]);

    const filteredAndSortedGroups = useFilteredAndSortedItems({
        items: groupsCopy(),
        searchText,
        sortConfig
    });

    function handleSearchChange(e: ChangeEvent<HTMLInputElement>) {
        dispatch(setSearchText(e.target.value));
    }

    if (!allGroups || allGroups.length === 0) {
        return null;
    }

    return (
        <WithPermissions
            actions={[RbacActions['Users/View']]}
            showMessage={true}
        >
            <div className="user-grid">
                <div className="user-grid__search">
                    <TextField
                        type={TextFieldTypes.type.DEFAULT}
                        isSearch={true}
                        value={searchText}
                        onChange={handleSearchChange}
                        placeholder="Search groups"
                        ref={textFieldRef}
                    />
                </div>
                {!pending && filteredAndSortedGroups.length > 0 ? (
                    <Stack className="group-list">
                        <Table>
                            <Table.HeadRow base={10}>
                                <Table.Cell span={2}>
                                    <Table.Sortable
                                        onClick={() =>
                                            requestSort('name', 'string')
                                        }
                                        sortActive={sortConfig.key === 'name'}
                                        sortDirection={sortConfig.direction}
                                    >
                                        Identity Provider Group
                                    </Table.Sortable>
                                </Table.Cell>
                                <Table.Cell span={2}>
                                    <WithPermissions
                                        actions={[RbacActions['Roles/View']]}
                                    >
                                        Role
                                    </WithPermissions>
                                </Table.Cell>
                            </Table.HeadRow>
                        </Table>
                        {filteredAndSortedGroups.map((group: Group) => (
                            <React.Fragment key={group.groupId}>
                                <Table>
                                    <Table.Row base={10}>
                                        <Table.Cell span={2}>
                                            <span
                                                style={{
                                                    color: 'var(--color-ui-100)'
                                                }}
                                                className="long-text"
                                            >
                                                {group.name}
                                            </span>
                                        </Table.Cell>
                                        <Table.Cell span={6}>
                                            <WithPermissions
                                                actions={[
                                                    RbacActions['Roles/View']
                                                ]}
                                            >
                                                <GroupRoleHandler
                                                    groupId={group.groupId}
                                                />
                                            </WithPermissions>
                                        </Table.Cell>
                                        <Table.Cell
                                            span={2}
                                            justifyContent="flex-end"
                                        >
                                            <Button
                                                componentType={
                                                    ButtonTypes.type.SECONDARY
                                                }
                                                onClick={() => {
                                                    track(
                                                        MixPanel.Events
                                                            .AdminGroupsClickViewUsers,
                                                        {
                                                            id: group.groupId,
                                                            name: group.name
                                                        }
                                                    );
                                                    setCurrentGroup(group);
                                                    setUserModalOpen(true);
                                                }}
                                                disabled={
                                                    group.users.length === 0
                                                }
                                            >
                                                View Users
                                            </Button>
                                        </Table.Cell>
                                    </Table.Row>
                                </Table>
                            </React.Fragment>
                        ))}
                    </Stack>
                ) : pending ? (
                    <Stack
                        flexDirection="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <LottieAnimation
                            autoplay={true}
                            loop={true}
                            width={30}
                            height={30}
                            animation={Loader}
                        ></LottieAnimation>
                    </Stack>
                ) : (
                    searchText &&
                    filteredAndSortedGroups.length === 0 && (
                        <div className="user-table__empty">
                            Sorry, no groups match your search query
                        </div>
                    )
                )}
            </div>
            <Modal
                open={userModalOpen && !!currentGroup}
                onCancel={() => {
                    setUserModalOpen(false);
                }}
                closable={false}
                centered={true}
                footer={null}
                zIndex={2000}
                maskClosable={true}
                destroyOnClose={true}
                bodyStyle={{
                    maxHeight: '75vh',
                    overflowY: 'auto'
                }}
            >
                <GroupUserList group={currentGroup as Group} />
            </Modal>
        </WithPermissions>
    );
};

export default Groups;

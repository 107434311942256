import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Close from '../icons/Close';
import {
    closeComments,
    resolveComment,
    deleteComment,
    addComment,
    addReply,
    updateComment,
    updateReply,
    editComment,
    cancelEdit
} from './actions';
import Comment from './Comment';
import CommentInput from './CommentInput';
import SpinnerDefault from '../common/SpinnerDefault';
import qs from 'qs';

class CommentBar extends Component {
    constructor() {
        super();
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.primaryMentionRef = React.createRef();
    }

    componentDidMount() {
        //this.props.onCanCommentFetch();
        //window.addEventListener('keyup', this.handleKeyUp);
    }

    componentDidUpdate(prevProps) {
        if (this.props.show && !prevProps.show) {
            this.primaryMentionRef.current.focus();
        }
    }

    componentWillUnmount() {
        window.removeEventListener('keyup', this.handleKeyUp);
    }

    handleKeyUp(e) {
        if (e.keyCode == 27) {
            this.props.onCommentsClose();
        }
    }

    handleCommentsClose(e) {
        e.preventDefault();
        this.props.onCommentsClose();
    }

    render() {
        const {
            userId,
            commentId,
            editId,
            showEdit,
            show,
            loading,
            comments,
            actors,
            onResolve,
            onDelete,
            onCommentAdd,
            onReply,
            onEdit,
            onCancel,
            onCommentUpdate,
            onReplyUpdate,
            addCommentPending,
            addReplyPending,
            updateCommentPending,
            updateReplyPending
        } = this.props;
        return (
            <div
                id="commentBar"
                className={
                    show ? 'comment-bar comment-bar--visible' : 'comment-bar'
                }
            >
                <div className="comment-bar__header">
                    <div className="comment-bar__title">Comments</div>
                    <Close
                        width={24}
                        height={24}
                        className="btn-icon"
                        onClick={this.handleCommentsClose.bind(this)}
                    />
                </div>
                {loading ? (
                    <div className="no-comment">
                        <SpinnerDefault />
                    </div>
                ) : !comments || comments.length === 0 ? (
                    <div className="no-comment">
                        <div className="no-comment__inner">
                            <span className="no-comment__title">
                                No comments yet...
                            </span>
                            <div className="no-comment__message">
                                Add a comment by clicking on typing into the
                                field below. You can notify other users within
                                your comment by typing the &ldquo;@&rdquo;
                                symbol and then selecting their name from the
                                dropdown. Only users that have access to this
                                report will be shown in the list.
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="comment-bar__content">
                        {comments.map(c => (
                            <Comment
                                key={c.commentId}
                                shouldHighlight={c.commentId === commentId}
                                userId={userId}
                                comment={c}
                                actors={actors}
                                onReply={onReply.bind(null, c.commentId)}
                                onResolve={onResolve.bind(null, c.commentId)}
                                onDelete={onDelete.bind(null, c.commentId)}
                                onUndo={onResolve.bind(null, c.commentId, true)}
                                onCommentUpdate={onCommentUpdate}
                                onReplyUpdate={onReplyUpdate}
                                addReplyPending={addReplyPending}
                                updateCommentPending={updateCommentPending}
                                updateReplyPending={updateReplyPending}
                                onEdit={onEdit}
                                onCancel={onCancel}
                                editId={editId}
                                showEdit={showEdit}
                            />
                        ))}
                    </div>
                )}
                <div className="comment-bar__footer">
                    <CommentInput
                        type="primary"
                        className="comment-bar__input"
                        placeholder="Add comment"
                        onSend={onCommentAdd}
                        actors={actors}
                        pending={addCommentPending}
                        mentionRef={this.primaryMentionRef}
                    />
                </div>
            </div>
        );
    }
}

CommentBar.defaultProps = {
    comments: []
};

CommentBar.propTypes = {
    show: PropTypes.bool,
    loading: PropTypes.bool,
    userId: PropTypes.string,
    comments: PropTypes.array,
    actors: PropTypes.array,
    onCommentsClose: PropTypes.func,
    onResolve: PropTypes.func,
    onDelete: PropTypes.func,
    onCommentAdd: PropTypes.func,
    onReply: PropTypes.func,
    onCommentUpdate: PropTypes.func,
    onReplyUpdate: PropTypes.func,
    onCanCommentFetch: PropTypes.func,
    commentId: PropTypes.string,
    addCommentPending: PropTypes.bool,
    addReplyPending: PropTypes.bool,
    updateCommentPending: PropTypes.bool,
    updateReplyPending: PropTypes.bool,
    onEdit: PropTypes.func,
    onCancel: PropTypes.func,
    editId: PropTypes.string,
    showEdit: PropTypes.bool,
    scope: PropTypes.string
};

function mapStateToProps(state) {
    const {
        show,
        loading,
        comments,
        actors,
        addCommentPending,
        addReplyPending,
        updateCommentPending,
        updateReplyPending,
        editId,
        showEdit,
        departmentId
    } = state.comments;
    const { search } = state.router.location;
    const query = qs.parse(search, { ignoreQueryPrefix: true });
    const commentId = query.comments ? query.id : undefined;
    const scope = departmentId || 'company wide';
    return {
        show,
        comments,
        actors,
        loading,
        userId: state.auth.userInfo.actorId,
        commentId,
        addCommentPending,
        addReplyPending,
        updateCommentPending,
        updateReplyPending,
        editId,
        showEdit,
        scope
    };
}

const dispatchProps = {
    onCommentsClose: closeComments,
    onResolve: resolveComment,
    onDelete: deleteComment,
    onCommentAdd: addComment,
    onReply: addReply,
    onCommentUpdate: updateComment,
    onReplyUpdate: updateReply,
    onEdit: editComment,
    onCancel: cancelEdit
};

export default connect(mapStateToProps, dispatchProps)(CommentBar);

import ActionTypes from '../constants/ActionTypes';
import sortBy from 'lodash/sortBy';
import { createSlice } from '@reduxjs/toolkit';
import { setDynamicTemplates } from './actions';
import { Template } from './models';

interface ITemplateState {
    templates: Template[];
    dynamicTemplates: Template[];
    pending?: boolean;
    error?: boolean;
    loaded?: boolean;
}

const initialState: ITemplateState = {
    templates: [],
    dynamicTemplates: [],
    pending: false,
    error: false
};

const templatesSlice = createSlice({
    name: 'templates',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(ActionTypes.GetTemplatesPending, (state, _) => {
            state.pending = true;
        });
        builder.addCase(setDynamicTemplates.fulfilled, (state, action) => {
            let templates = action.payload;
            templates = sortBy(templates, t => t.analysisName);
            state.dynamicTemplates = templates;
        });
        builder.addCase(
            ActionTypes.GetTemplatesFulfilled,
            (state, action: any) => {
                let templates = action.payload.filter(
                    (t: any) =>
                        !['Missing Data', 'Metadata'].includes(t.analysisName)
                );
                templates = sortBy(templates, t => t.analysisName);
                state.pending = false;
                state.loaded = true;
                state.templates = templates;
            }
        );

        builder.addCase(ActionTypes.GetTemplatesRejected, (state, _) => {
            state.pending = false;
            state.error = true;
        });
        builder.addCase(ActionTypes.GetAllVariationsPending, state => {
            const templates = state.templates;
            state.pending = true;

            templates.forEach((t: any) => {
                t.pending = true;
            });
        });
        builder.addCase(
            ActionTypes.GetAllVariationsFulfilled,
            (state, action: any) => {
                action.payload.forEach((item: any, index: number) => {
                    if (!state.templates[index]) return;
                    state.templates[index] = {
                        ...state.templates[index],
                        ...item,
                        pending: false
                    };
                });
                state.pending = false;
                state.loaded = true;
            }
        );
        builder.addCase(ActionTypes.GetAllVariationsRejected, state => {
            const templates = state.templates;
            templates.forEach((t: any) => {
                t.pending = false;
                t.error = true;
            });
            state.pending = false;
            state.error = true;
        });
        builder.addCase(
            ActionTypes.GetVariationsPending,
            (state, action: any) => {
                const { templateConfigurationId } = action.payload;
                const index = state.templates.findIndex(
                    t => t.templateConfigurationId === templateConfigurationId
                );
                if (index !== -1) {
                    state.templates[index].pending = true;
                }
            }
        );

        builder.addCase(
            ActionTypes.GetVariationsFulfilled,
            (state, action: any) => {
                const { templateConfigurationId } = action.payload;
                const index = state.templates.findIndex(
                    t => t.templateConfigurationId === templateConfigurationId
                );
                if (index !== -1) {
                    state.templates[index] = {
                        ...state.templates[index],
                        ...action.payload,
                        pending: false
                    };
                }
            }
        );

        builder.addCase(
            ActionTypes.GetVariationsRejected,
            (state, action: any) => {
                const { templateConfigurationId } = action.payload;
                const index = state.templates.findIndex(
                    t => t.templateConfigurationId === templateConfigurationId
                );

                if (index !== -1) {
                    state.templates[index].pending = false;
                    state.templates[index].error = true;
                }
            }
        );
    }
});

export default templatesSlice.reducer;

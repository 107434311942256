import React from 'react';
import ContentAreaContainer from '../../common/ContentAreaContainer';
import WithPermissions from '../../common/WithPermissions';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import { ChartContextProvider } from '../../contexts/ChartContext';
import { track } from '../../lib/segment';
import { useAppSelector } from '../../store/hooks';
import Chart from './Chart';
import GoalInfo from './GoalInfo';

const Goal = () => {
    const { generated, goal } = useAppSelector(state => state.measure.goal);

    React.useEffect(() => {
        track(MixPanel.Events.MeasureGoalsDetailsPageView, {
            name: goal.goalName,
            id: goal.goalId
        });
    }, []);

    return (
        <ContentAreaContainer>
            <WithPermissions
                actions={[RbacActions['Goals/View']]}
                showMessage={true}
            >
                {generated && <GoalInfo />}
                <ChartContextProvider>
                    <Chart />
                </ChartContextProvider>
            </WithPermissions>
        </ContentAreaContainer>
    );
};

export default Goal;

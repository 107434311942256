function arrifyObject(obj: { [key: string]: any }): {
    name: string;
    value: any;
}[] {
    return Object.keys(obj).map(key => ({
        name: key,
        value: obj[key]
    }));
}

export default arrifyObject;

import React from 'react';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { openInfoModal } from '../../settings/reducer';
import { MeasureType } from '../../types';
import { capitalize } from '../../utils';

interface EmptyStateProps {
    activeTab: MeasureType;
}

const EmptyState = ({ activeTab }: EmptyStateProps) => {
    const dispatch = useAppDispatch();

    const handleOpenInfoModal = () => {
        dispatch(openInfoModal());
    };

    return (
        <div className="timeline__no-events" onClick={handleOpenInfoModal}>
            <div>
                {`Click “Add ${capitalize(
                    activeTab
                )}" to add your first goal and start tracking progress against it.`}
            </div>
            <div>
                {`For more information on ${capitalize(activeTab)}s, `}
                <span onClick={handleOpenInfoModal}> click here</span>.
            </div>
        </div>
    );
};

export default EmptyState;

import React, { useState } from 'react';
import TextArea from '../common/TextArea';

interface TextAreaStateWrapperProps {
    inputValue: string;
    onChange: (value: string) => void;
    placeholder: string;
    danger: boolean;
}

const TextAreaStateWrapper = ({
    inputValue,
    danger,
    placeholder
}: TextAreaStateWrapperProps) => {
    const [value, setValue] = useState(inputValue || '');
    const onChange = (e: any) => {
        setValue(e.target.value);
    };

    return (
        <TextArea
            value={value}
            onChange={onChange}
            danger={danger}
            placeholder={placeholder}
        ></TextArea>
    );
};

export default TextAreaStateWrapper;

import React, { MouseEvent } from 'react';
import SadFace from '../icons/SadFace';

interface ForbiddenProps {
    history?: any;
}

export default function Forbidden({ history }: ForbiddenProps) {
    return (
        <div className="forbidden">
            <div className="forbidden__content">
                <SadFace />
                <div className="forbidden__title">Forbidden (403 Error)</div>
                <div className="forbidden__desc">
                    Sorry! You&rsquo;ll need additional permissions to access
                    this page. You can ask an administrator to grant you access.
                </div>
            </div>
        </div>
    );
}

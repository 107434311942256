import React, { useState } from 'react';
import TextField from '../../common/TextField';
import WithPermissions from '../../common/WithPermissions';
import RbacActions from '../../constants/RbacActions';
import TextFieldTypes from '../../constants/TextFieldTypes';
import { clearNewRole } from '../../roles/createRole/reducer';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setSearchText } from '../config/reducer';
import { ActorRole } from '../users/models';
import DeleteRoleModal from './DeleteRoleModal';
import InfoText from './InfoText';
import RolesTable from './RolesTable';

interface RolesPermissionsProps {}

const ROLES_SCROLL_POSITION = 'ROLES_SCROLL_POSITION';

const RolesPermissions = (props: RolesPermissionsProps) => {
    const dispatch = useAppDispatch();
    const { allRoles: roles, toDelete } = useAppSelector(
        state => state.admin.roles
    );
    const { searchText } = useAppSelector(state => state.admin.adminConfig);
    const { allUsers: users } = useAppSelector(state => state.admin.users);

    const [scrollValue, setScrollValue] = useState(0);

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setSearchText(event.target.value));
    };

    const countUsersByRole = () => {
        const roleMap = new Map();
        users.forEach(user => {
            const roles = user.role;
            roles.forEach((role: ActorRole) => {
                roleMap.set(role.id, (roleMap.get(role.id) || 0) + 1);
            });
        });
        return roleMap;
    };

    React.useEffect(() => {
        // if (pending || !fetchingRef || !fetchingRef.current) return;
        const onScroll = (e: any) => {
            setScrollValue(e.target.documentElement.scrollTop);
        };
        const scrollPosition = sessionStorage.getItem(ROLES_SCROLL_POSITION);
        if (scrollPosition) {
            window.removeEventListener('scroll', onScroll);
            setScrollValue(parseFloat(scrollPosition));
            window.scrollTo(0, parseFloat(scrollPosition));
            sessionStorage.removeItem(ROLES_SCROLL_POSITION);
            setTimeout(() => {
                window.addEventListener('scroll', onScroll);
            }, 300);
        } else {
            window.addEventListener('scroll', onScroll);
        }

        return () => {
            window.removeEventListener('scroll', onScroll);
        };
    }, []);

    React.useEffect(() => {
        return () => {
            sessionStorage.setItem(
                ROLES_SCROLL_POSITION,
                scrollValue.toString()
            );
        };
    }, [scrollValue]);

    React.useEffect(() => {
        dispatch(clearNewRole());
    }, []);

    return (
        <WithPermissions
            actions={[RbacActions['Roles/View']]}
            showMessage={true}
        >
            <div className="roles-grid">
                <div className="roles-grid__list">
                    <TextField
                        type={TextFieldTypes.type.DEFAULT}
                        isSearch={true}
                        value={searchText}
                        onChange={handleSearchChange}
                        placeholder="Search roles"
                    />
                    <RolesTable
                        numberOfUsers={countUsersByRole()}
                        roles={roles}
                    />
                </div>

                <div className="roles-grid__text">
                    <InfoText />
                </div>
            </div>
            {toDelete && <DeleteRoleModal />}
        </WithPermissions>
    );
};

export default RolesPermissions;

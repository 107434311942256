import { combineReducers } from 'redux';

import filterReducer from '../filter/filterReducer';
import dashboardReducer from './reducer';
import aiInsightsReducer from '../aiInsights/reducer';

export default combineReducers({
    filter: filterReducer,
    view: dashboardReducer,
    aiInsights: aiInsightsReducer
});

import '../../../../../../../sass/helpers/_variables.scss';

const chartColors = [
    'var(--color-graph-1)',
    'var(--color-graph-2)',
    'var(--color-graph-3)',
    'var(--color-graph-4)',
    'var(--color-graph-5)',
    'var(--color-graph-6)'
];

export default chartColors;

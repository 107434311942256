export function hexToRgb(H: any) {
    let r: any = 0,
        g: any = 0,
        b: any = 0;
    if (H.length == 4) {
        r = '0x' + H[1] + H[1];
        g = '0x' + H[2] + H[2];
        b = '0x' + H[3] + H[3];
    } else if (H.length == 7) {
        r = '0x' + H[1] + H[2];
        g = '0x' + H[3] + H[4];
        b = '0x' + H[5] + H[6];
    }

    return {
        red: r / 255,
        green: g / 255,
        blue: b / 255
    };
}

function hexToHSL(H: any) {
    let { red, green, blue } = hexToRgb(H);

    let cmin = Math.min(red, green, blue),
        cmax = Math.max(red, green, blue),
        delta = cmax - cmin,
        h = 0,
        s = 0,
        l = 0;

    if (delta == 0) h = 0;
    else if (cmax == red) h = ((green - blue) / delta) % 6;
    else if (cmax == green) h = (blue - red) / delta + 2;
    else h = (red - green) / delta + 4;

    h = Math.round(h * 60);

    if (h < 0) h += 360;

    l = (cmax + cmin) / 2;
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
    s = +(s * 100).toFixed(1);
    l = +(l * 100).toFixed(1);

    return {
        h: h,
        s: s,
        l: l
    };
}

export function hslToHex(hsl: any) {
    let [h, s, l] = hsl.match(/[+-]?([0-9]*[.])?[0-9]+/g);

    h /= 360;
    s /= 100;
    l /= 100;
    let r, g, b;
    if (s === 0) {
        r = g = b = l; // achromatic
    } else {
        const hue2rgb = (p: any, q: any, t: any) => {
            if (t < 0) t += 1;
            if (t > 1) t -= 1;
            if (t < 1 / 6) return p + (q - p) * 6 * t;
            if (t < 1 / 2) return q;
            if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
            return p;
        };
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
        const p = 2 * l - q;
        r = hue2rgb(p, q, h + 1 / 3);
        g = hue2rgb(p, q, h);
        b = hue2rgb(p, q, h - 1 / 3);
    }
    const toHex = (x: any) => {
        const hex = Math.round(x * 255).toString(16);
        return hex.length === 1 ? '0' + hex : hex;
    };
    return `#${toHex(r)}${toHex(g)}${toHex(b)}`;
}

export function getCssVariable(variable: any) {
    const regex = /--[^)]*/;
    const match = variable.match(regex);

    if (match) {
        return match[0];
    }
    return '';
}

export function getComputedCssValue(variable: any) {
    return window.getComputedStyle(document.body).getPropertyValue(variable);
}

export function addStyle(
    childNodes: any,
    targetChildNodes: any,
    width?: number,
    height?: number
) {
    if (childNodes.length !== targetChildNodes.length) {
        return;
    }

    for (let i = 0; i < childNodes.length; i++) {
        const node = childNodes[i];
        const targetNode = targetChildNodes[i];

        if (
            node instanceof window.Element &&
            targetNode instanceof window.Element
        ) {
            let styles = '';
            let computedStyle = window.getComputedStyle(node, null);

            for (let j = 0; j < computedStyle.length; j++) {
                const property = computedStyle[j];
                const value = computedStyle.getPropertyValue(property);
                if (property !== 'width' && property !== 'height') {
                    styles += `${property}:${value};`;
                }
            }
            if (width && height) {
                styles += `width:${width}px;height:${height}px;`;
            }

            targetNode.setAttribute('style', styles);
            addStyle(node.childNodes, targetNode.childNodes);
        }
    }
}

function updateCssVariables(customColors: any, document: any) {
    if (!customColors || !document) {
        return;
    }

    customColors.forEach(({ colors, name }: any) => {
        const light = hexToHSL(colors.light);
        const dark = hexToHSL(colors.dark);
        document.documentElement.style.setProperty(`--cl-${name}-h`, light.h);
        document.documentElement.style.setProperty(
            `--cl-${name}-s`,
            light.s + '%'
        );
        document.documentElement.style.setProperty(
            `--cl-${name}-l`,
            light.l + '%'
        );
        document.documentElement.style.setProperty(`--cd-${name}-h`, dark.h);
        document.documentElement.style.setProperty(
            `--cd-${name}-s`,
            dark.s + '%'
        );
        document.documentElement.style.setProperty(
            `--cd-${name}-l`,
            dark.l + '%'
        );
    });

    return;
}

export default updateCssVariables;

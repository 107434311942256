const updateContext = (state: any, event: any) => {
    return {
        ...state,
        context: {
            ...state.context,
            ...event.payload
        }
    };
};

const closedState = (state: any, event: any) => {
    switch (event.type) {
        case 'SET_CONTEXT': {
            return updateContext(state, event);
        }
        case 'OPEN': {
            return {
                ...state,
                status: 'opened',
                context: {
                    ...state.context,
                    ...event.payload
                }
            };
        }
        default: {
            return {
                ...state
            };
        }
    }
};

const openedState = (state: any, event: any) => {
    switch (event.type) {
        case 'CLOSE': {
            return {
                ...state,
                status: 'closed',
                context: {
                    ...state.context,
                    ...event.payload
                }
            };
        }
        case 'CLEAR': {
            return {
                ...state,
                status: 'closed',
                context: {
                    ...state.context,
                    ...event.payload
                }
            };
        }
        case 'SET_CONTEXT': {
            return updateContext(state, event);
        }
        default: {
            return {
                ...state
            };
        }
    }
};

const DropdownMachine = (state: any, event: any) => {
    switch (state.status) {
        case 'closed': {
            return closedState(state, event);
        }
        case 'opened': {
            return openedState(state, event);
        }
        default: {
            return {
                ...state
            };
        }
    }
};

export default DropdownMachine;

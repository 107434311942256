import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
        <path
            fill="currentColor"
            d="M12.5512 14.6667H4.18583V5.50005L9.41021 0.339844L9.77875 0.708385C9.85472 0.784219 9.91861 0.881441 9.97042 1.00005C10.0222 1.11866 10.0481 1.22762 10.0481 1.32693V1.45839L9.19542 5.50005H14.9871C15.3354 5.50005 15.6469 5.63734 15.9215 5.91193C16.196 6.18651 16.3333 6.49797 16.3333 6.8463V7.87193C16.3333 7.94776 16.3248 8.03054 16.3077 8.12026C16.2906 8.20998 16.2682 8.29276 16.2404 8.36859L13.9648 13.763C13.8505 14.0194 13.6582 14.2341 13.3879 14.4071C13.1175 14.5802 12.8386 14.6667 12.5512 14.6667ZM5.01917 13.8334H12.5512C12.6687 13.8334 12.789 13.8013 12.9119 13.7371C13.0348 13.6731 13.1283 13.5663 13.1923 13.4167L15.5 8.00005V6.8463C15.5 6.69672 15.4519 6.5738 15.3558 6.47755C15.2596 6.38144 15.1367 6.33339 14.9871 6.33339H8.16021L9.125 1.78214L5.01917 5.85589V13.8334ZM4.18583 5.50005V6.33339H1.33333V13.8334H4.18583V14.6667H0.5V5.50005H4.18583Z"
        />
    </svg>
);
export default SvgComponent;

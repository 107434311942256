import React, { useState, useEffect } from 'react';
import * as uuid from 'uuid';
import { RouteComponentProps } from 'react-router-dom';
import { Configuration } from '../configuration/model';
import { getConfigurations, saveConfiguration } from './actions';
import TextField from '../../common/TextField';
import TextArea from '../../common/TextArea';
import Dropdown from '../../common/Dropdown';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { datasourceOptions, engineNameOptions } from '../constants';
import FieldMap from './FieldMap';
import ConfigurationVariables from './ConfigurationVariables';
import ExcludeWhen from './ExcludeWhen';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import qs from 'qs';
import SupportedGraphs from './SupportedGraphs';

function getEmptyConfiguration() {
    const config: Configuration = {
        analysisName: '',
        dataSource: '',
        engineName: '',
        excludeWhen: [],
        templateName: '',
        templateConfigurationId: '',
        configurationVariables: [],
        uiFieldMapping: {
            analysisMetaFields: {},
            displayFields: {},
            subAnalysisFields: {}
        },
        subAnalysisMapping: {},
        supportedGraphs: []
    };
    return config;
}

function ConfigurationEdit({
    history,
    match,
    location
}: RouteComponentProps<{ id: string }>) {
    const dispatch = useAppDispatch();

    const query = qs.parse(location.search, { ignoreQueryPrefix: true });

    const id = match.params.id || query.copyFrom;
    const { loaded, data } = useAppSelector(
        state => state.datastudio.configurations
    );
    const shouldFetch = !loaded;
    const configuration = id
        ? data?.find((c: any) => c.templateConfigurationId === id)
        : getEmptyConfiguration();

    useEffect(() => {
        if (shouldFetch) {
            dispatch(getConfigurations());
        }
    }, [shouldFetch]);

    const [error, setError] = useState('');
    const [templateName, setTemplateName] = useState(
        configuration?.templateName
    );
    const [analysisName, setAnalysisName] = useState(
        configuration?.analysisName
    );
    const [dataSource, setDataSource] = useState(configuration?.dataSource);
    const [engineName, setEngineName] = useState(configuration?.engineName);
    const [configurationVariables, setConfigurationVariables] = useState(
        configuration?.configurationVariables
    );
    const [uiFieldMapping, setUiFieldMapping] = useState(
        configuration?.uiFieldMapping
    );
    const [excludeWhen, setExcludeWhen] = useState(configuration?.excludeWhen);
    const [supportedGraphs, setSupportedGraphs] = useState<string[]>(
        configuration?.supportedGraphs ?? []
    );
    const [subAnalysisMappingJSON, setSubAnalysisMappingJSON] =
        useState<string>('');

    useEffect(() => {
        if (configuration?.templateConfigurationId) {
            let templateName = configuration.templateName;
            if (query.copyFrom) {
                templateName = 'Copy of ' + templateName;
            }
            setTemplateName(templateName);
            setAnalysisName(configuration.analysisName);
            setDataSource(configuration.dataSource);
            setEngineName(configuration.engineName);
            setConfigurationVariables(configuration.configurationVariables);
            setUiFieldMapping(configuration.uiFieldMapping);
            setExcludeWhen(configuration.excludeWhen);
            setSupportedGraphs(configuration.supportedGraphs ?? []);
            setSubAnalysisMappingJSON(
                JSON.stringify(
                    configuration.subAnalysisMapping ?? [],
                    null,
                    '\t'
                )
            );
        }
    }, [configuration]);

    function handleSave() {
        if (!templateName || !analysisName || !dataSource || !engineName) {
            setError('Please enter all the fields');
            return;
        }

        let templateConfigurationId = uuid.v4();
        if (match.params.id) {
            templateConfigurationId = configuration!.templateConfigurationId;
        }

        const configurationObj: any = {
            templateConfigurationId,
            templateName,
            analysisName,
            dataSource,
            engineName,
            configurationVariables,
            excludeWhen,
            uiFieldMapping,
            supportedGraphs,
            subAnalysisMapping: subAnalysisMappingJSON
                ? JSON.parse(subAnalysisMappingJSON)
                : {}
        };
        dispatch(saveConfiguration(configurationObj));
        history.push(
            `/datastudio/config/${configurationObj.templateConfigurationId}`
        );
    }

    function handleCancel() {
        history.goBack();
    }

    return (
        <div className="ds-container">
            <div className="ds-flex">
                <Button
                    componentType={ButtonTypes.type.PRIMARY}
                    onClick={handleSave}
                >
                    Save
                </Button>
                <Button
                    componentType={ButtonTypes.type.SECONDARY}
                    style={{ marginLeft: 8 }}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
            </div>
            {error && <div className="ds-error">{error}</div>}
            <div className="ds-label">Template configuration id</div>
            <div className="ds-value">
                {match.params.id
                    ? configuration?.templateConfigurationId
                    : 'New'}
            </div>
            <div className="ds-label">Template name</div>
            <div className="ds-input">
                <TextField
                    value={templateName || ''}
                    onChange={e => setTemplateName(e.target.value)}
                />
            </div>
            <div className="ds-label">Analysis name</div>
            <div className="ds-input">
                <TextField
                    value={analysisName || ''}
                    onChange={e => setAnalysisName(e.target.value)}
                />
            </div>
            <div className="ds-label">Data Source</div>
            <div className="ds-input">
                <Dropdown
                    placeholder="Select"
                    options={datasourceOptions}
                    selectedOption={dataSource}
                    onClick={(value: any) => setDataSource(value)}
                />
            </div>
            <div className="ds-label">Engine</div>
            <div className="ds-input">
                <Dropdown
                    placeholder="Select"
                    options={engineNameOptions}
                    selectedOption={engineName}
                    onClick={(value: any) => setEngineName(value)}
                />
            </div>
            <ConfigurationVariables
                configurationVariables={configurationVariables}
                setConfigurationVariables={setConfigurationVariables}
            />
            <ExcludeWhen
                excludeWhen={excludeWhen}
                setExcludeWhen={setExcludeWhen}
            />
            <FieldMap
                label="Analysis fields"
                fieldMapType="analysisMetaFields"
                uiFieldMapping={uiFieldMapping}
                setUiFieldMapping={setUiFieldMapping}
            />
            <FieldMap
                label="Display fields"
                fieldMapType="displayFields"
                uiFieldMapping={uiFieldMapping}
                setUiFieldMapping={setUiFieldMapping}
            />
            <FieldMap
                label="Subanalysis fields"
                fieldMapType="subAnalysisFields"
                uiFieldMapping={uiFieldMapping}
                setUiFieldMapping={setUiFieldMapping}
            />
            <SupportedGraphs
                supportedGraphs={supportedGraphs}
                setSupportedGraphs={setSupportedGraphs}
            />
            <div className="ds-label">Subanalysis Mapping</div>
            <div className="ds-input">
                <TextArea
                    rows={20}
                    value={subAnalysisMappingJSON}
                    onChange={e => setSubAnalysisMappingJSON(e.target.value)}
                />
            </div>
        </div>
    );
}

export default ConfigurationEdit;

import Modal from 'antd/lib/modal';
import { useState } from 'react';
import Grid from '../../../common/Grid';
import InfoCard from '../../../common/InfoCard';
import Stack from '../../../common/Stack';
import Cycle from '../../../icons/Cycle';
import List from '../../../icons/List';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { nextStep, reset, setAudienceType } from '../edit/slice';
import { AudienceType } from '../types';

export default function AudienceTypeModal() {
    const dispatch = useAppDispatch();
    const [hoverIndex, setHoverIndex] = useState(-1);
    const { modalOpen } = useAppSelector(state => state.audiences.edit);

    return (
        <Modal
            visible={modalOpen}
            width={800}
            footer={null}
            closable={true}
            maskClosable={true}
            onCancel={() => dispatch(reset())}
            zIndex={3000}
            bodyStyle={{
                backgroundColor: 'var(--color-shade-h3)',
                borderRadius: '.8rem'
            }}
        >
            <Stack gap="3.2rem">
                <div>
                    <h3 className="font-subtitle">Create new audience</h3>
                </div>
                <Grid base={2} gap="1.6rem">
                    <Grid.Item>
                        <Card
                            title={'Dynamic Audience'}
                            description="Dynamic audiences use the latest employee data from your HRIS to automatically refresh."
                            color="#F9D2FF"
                            iconColor="#C050F5"
                            icon={Cycle}
                            onClick={() => {
                                dispatch(setAudienceType(AudienceType.DYNAMIC));
                                dispatch(nextStep());
                            }}
                            index={0}
                            hoverIndex={hoverIndex}
                            setHoverIndex={setHoverIndex}
                        />
                    </Grid.Item>
                    <Grid.Item>
                        <Card
                            title="Static Audience"
                            description="Static audiences are created via CSV upload."
                            color="#BCFF9C"
                            iconColor="#20B049"
                            icon={List}
                            onClick={() => {
                                dispatch(setAudienceType(AudienceType.STATIC));
                                dispatch(nextStep());
                            }}
                            index={1}
                            hoverIndex={hoverIndex}
                            setHoverIndex={setHoverIndex}
                        />
                    </Grid.Item>
                </Grid>
            </Stack>
        </Modal>
    );
}

const Card = ({
    title,
    description,
    color,
    iconColor,
    icon: Icon,
    onClick,
    index,
    hoverIndex,
    setHoverIndex,
    selectionExists
}: {
    title: string;
    description: string;
    color: string;
    iconColor: string;
    icon: React.FC;
    onClick?: any;
    index: number;
    hoverIndex: number;
    setHoverIndex: any;
    selectionExists?: boolean;
}) => {
    return (
        <InfoCard
            onClick={onClick}
            state={
                selectionExists
                    ? 'selectionExists'
                    : hoverIndex !== -1
                    ? 'hoverExists'
                    : 'default'
            }
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(-1)}
            isHovering={hoverIndex === index}
            style={{
                background: 'var(--color-shade-4)'
            }}
        >
            <div
                style={{
                    width: '100%',
                    aspectRatio: '3/1',
                    height: 'auto',
                    position: 'relative',
                    background: color
                }}
            >
                <div
                    style={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        color: iconColor
                    }}
                >
                    <Icon />
                </div>
            </div>
            <Stack
                gap=".8rem"
                style={{
                    padding: '1.6rem'
                }}
            >
                <h3 className="font-subtitle">{title}</h3>
                <span
                    style={{
                        color: 'var(--color-ui-50'
                    }}
                >
                    {description}
                </span>
            </Stack>
        </InfoCard>
    );
};

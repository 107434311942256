import { useEffect, useRef } from 'react';

import Button from '../../common/Button';
import AnalysisTypes from '../../constants/AnalysisTypes';
import ButtonTypes from '../../constants/ButtonTypes';
import MixPanel from '../../constants/MixPanel';
import AnalysisDropdown from '../../explore/filter/AnalysisDropdown';
import { getInitialAnalysisNames } from '../../explore/filter/Filters';
import SegmentDropdown from '../../explore/filter/SegmentDropdown';
import getMixPanelObjectForFilters from '../../lib/getMixPanelObjectForFilters';
import { track } from '../../lib/segment';
import { getAllSegments } from '../../store/actionUtils';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import {
    clearFilters,
    generateInEditor,
    setAnalysisName,
    setFiltersFromReport,
    updateBreakdownSegment,
    updateFilterSegment,
    updateMainSegments,
} from './actions';

function Filters({ report, showBreakdown = true }: any) {
    const dispatch = useAppDispatch();
    const { analysisNamesTree = [] } = useAppSelector((state) => state.account);
    const { dynamicTemplates } = useAppSelector((state) => state.templates);
    const initialAnalysisNames = getInitialAnalysisNames(analysisNamesTree!, dynamicTemplates);

    const generatePending = useAppSelector((state) => state.editor.chart.generatePending);
    const completed = useAppSelector((state) => state.editor.save.completed);

    const {
        analysisName,
        analysisDisplayName,
        analysisType,
        mainSegments,
        filterSegment,
        breakdownSegment,
        mainNames,
        filterNames,
        breakdownNames,
        attributes,
        generateDisabled,
    } = useAppSelector((state) => state.editor.filter);

    const containerRef = useRef<HTMLDivElement>(null);
    const currentTemplate = dynamicTemplates.find((t: any) => t.analysisName === analysisName);

    useEffect(() => {
        // prevents this effect from firing when we set report data from editor
        if (completed) return;
        if (report) {
            dispatch(setFiltersFromReport(report));
        } else {
            dispatch(clearFilters());
        }
    }, [report, completed]);

    function handleGenerate() {
        const segments = getAllSegments({
            mainSegments,
            filterSegment,
            breakdownSegment,
        });
        const mixpanelObj = getMixPanelObjectForFilters(analysisName, segments, attributes);
        track(MixPanel.Events.ReportEditorGenerate, mixpanelObj);
        dispatch(generateInEditor());
    }

    function handleClear() {
        track(MixPanel.Events.ReportEditorClear);
        dispatch(clearFilters());
    }

    return (
        <div className="explore__filters__section" ref={containerRef}>
            <div className="filters">
                <div className="filters__dropdowns">
                    <AnalysisDropdown
                        initialAnalysisNames={initialAnalysisNames}
                        analysisDisplayName={analysisDisplayName!}
                        analysisName={analysisName}
                        analysisType={analysisType!}
                        setAnalysisName={(props: any) => dispatch(setAnalysisName(props))}
                    />
                    <SegmentDropdown
                        placeholder="Segment"
                        attributes={attributes}
                        dynamicPermissionsFilter={(currentTemplate?.mainNames as any) || []}
                        availableSegmentNames={mainNames}
                        selection={mainSegments}
                        update={(segments) => dispatch(updateMainSegments(segments))}
                        disabled={!analysisName}
                    />
                    <SegmentDropdown
                        placeholder="Filter"
                        attributes={attributes}
                        dynamicPermissionsFilter={(currentTemplate?.filterNames as any) || []}
                        availableSegmentNames={filterNames}
                        selection={filterSegment ? [filterSegment] : []}
                        update={(segments) => dispatch(updateFilterSegment(segments))}
                        disabled={
                            (!filterSegment && currentTemplate?.filterNames?.length === 0) ||
                            !analysisName ||
                            mainSegments.length === 0
                        }
                        useRadioOnSegment
                        useRadioOnValue
                        isSecondary
                    />
                    {showBreakdown && (
                        <SegmentDropdown
                            placeholder="Breakdown"
                            attributes={attributes}
                            dynamicPermissionsFilter={(currentTemplate?.breakdownNames as any) || []}
                            availableSegmentNames={breakdownNames}
                            selection={breakdownSegment ? [breakdownSegment] : []}
                            update={(segments) => dispatch(updateBreakdownSegment(segments))}
                            disabled={
                                (!breakdownSegment && currentTemplate?.breakdownNames?.length === 0) ||
                                !analysisName ||
                                mainSegments.length === 0 ||
                                analysisType === AnalysisTypes.Arithmetic
                            }
                            useRadioOnSegment
                            isSecondary
                        />
                    )}
                </div>
                <div className="filters__buttons">
                    <Button componentType={ButtonTypes.type.TERTIARY} onClick={handleClear} tabIndex={-1}>
                        Clear
                    </Button>
                    <Button
                        disabled={!analysisName || !mainSegments || !mainSegments.length || generateDisabled}
                        onClick={handleGenerate}
                        loading={generatePending}
                        testId="generate"
                    >
                        <span style={{ opacity: generatePending ? 0 : 1 }}>Generate</span>
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default Filters;

import classNames from 'classnames';
import { max, min } from 'd3-array';
import { scaleBand, scaleLinear } from 'd3-scale';
import produce from 'immer';
import { Fragment, useEffect, useRef, useState } from 'react';
import { Animate, NodeGroup } from 'react-move';
import AnalysisTypes from '../../constants/AnalysisTypes';
import CanvasFonts from '../../constants/CanvasFonts';
import Constants from '../../constants/Constants';
import FontFamilies from '../../constants/FontFamilies';
import inlineFont from '../../constants/InlineFont';
import MixPanel from '../../constants/MixPanel';
import getFormattedValue from '../../lib/getFormattedValue';
import getInterpolator from '../../lib/getInterpolator';
import getPeriod from '../../lib/getPeriod';
import measureText from '../../lib/measureText';
import precisionRound from '../../lib/precisionRound';
import * as Tooltip from '@radix-ui/react-tooltip';
import { track } from '../../lib/segment';
import ChartTooltip from '../../common/ChartTooltip';

const AXIS_PADDING = 24;
const PADDING = 16;
const Y_SMALL_PADDING = 40;
const AXIS_FONT_SIZE = 11;
const TEXT_FONT_SIZE = 11;
const LARGE_TEXT_FONT_SIZE = 13;
const TOP_BAR_HEIGHT = 0; // was 3 before
const MIN_GROUP_SPACING = 80;
const ARROW_WIDTH = 6;
const ARROW_HEIGHT = 8;
const ARROW_CIRCLE_RADIUS = 12;
const OTHER_LABEL = 'OtherXYZ';

const barTypes = [
    {
        type: 'large',
        min: 48,
        max: 80
    },
    {
        type: 'medium',
        min: 34,
        max: 47
    },
    {
        type: 'small',
        min: 11,
        max: 33
    }
];

const pageSizes = [0, 6, 4, 2, 1];

function getFormattedDate(date, interval = 'Month') {
    return getPeriod(date, interval.toLowerCase());
}

export default function StackedBarChart(props) {
    const {
        width,
        height: propHeight,
        analysisType,
        dates,
        interval,
        currentIndex,
        setActiveLabel,
        clearActiveLabel,
        toggleLabel,
        selectCurrentTime,
        dateSelection,
        reportId,
        dashboardName,
        title,
        dataProp,
        yAxisType,
        employeeCountHidden,
        columnProps
    } = props;
    let { data } = props;
    const height = propHeight;
    const isActive = data.some(d => d.active || d.selected);
    const showIndexLine =
        isActive &&
        (analysisType === AnalysisTypes.LinearRegression ||
            analysisType === AnalysisTypes.Index);

    let data2 = data.filter(d => !d.isEqualityIndex && !d.isAttritionIndex);

    const [barType, setBarType] = useState();
    const [barWidth, setBarWidth] = useState(0);
    const [pageSize, setPageSize] = useState(0);
    const [startIndex, setStartIndex] = useState(0);
    const [barHovered, setBarHovered] = useState(false);
    const firstLoadRef = useRef({ firstLoad: true });

    useEffect(() => {
        const barCount = data2.length;
        const availableWidth = width - 2 * PADDING;
        let configFound = false;
        pageSizes.forEach(ps => {
            if (configFound) return;
            if (ps > dates.length) return;
            barTypes.forEach((bt, j) => {
                if (configFound) return;
                const groupCount = ps === 0 ? dates.length : ps;
                const maxGraphWidth =
                    bt.max * barCount * groupCount +
                    (groupCount - 1) * MIN_GROUP_SPACING;
                const minGraphWidth =
                    bt.min * barCount * groupCount +
                    (groupCount - 1) * MIN_GROUP_SPACING;
                let barWidth;
                if (j === 0 && maxGraphWidth < availableWidth) {
                    barWidth = bt.max;
                    configFound = true;
                } else if (
                    minGraphWidth <= width &&
                    maxGraphWidth > availableWidth
                ) {
                    barWidth =
                        (availableWidth -
                            (groupCount - 1) * MIN_GROUP_SPACING) /
                        (barCount * groupCount);
                    configFound = true;
                }
                if (configFound) {
                    setBarWidth(barWidth);
                    setBarType(bt.type);
                    setPageSize(ps);
                }
            });
        });
    }, [width, dates.length, data2.length]);

    useEffect(() => {
        if (barWidth > 0) {
            firstLoadRef.current.firstLoad = false;
        }
    }, [barWidth]);

    function handleToggle(label, e) {
        track(
            MixPanel.Events.DashboardReportStackedBarGraphColumnSegmentClick,
            {
                'Dashboard Name': dashboardName,
                'Report Name': title
            }
        );
        toggleLabel(label, e);
    }

    function handleTimeClick(index, e) {
        e.stopPropagation();
        selectCurrentTime(index);
        track(MixPanel.Events.DashboardReportStackedBarGraphXAxisClick, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
    }

    function getBarOpacity(item) {
        const isActive = data2.some(pd => pd.active || pd.selected);
        if (isActive && !item.active && !item.selected) {
            return 0.2;
        }
        return 1;
    }

    function getTopBarOpacity(item) {
        const isActive = data2.some(pd => pd.active || pd.selected);
        if (isActive && !item.active && !item.selected) {
            return 0.15;
        }
        return 1;
    }

    function getTextOpacity(item, currentIndex) {
        const isActive = data2.some(pd => pd.active || pd.selected);

        const index = currentIndex || 0;
        const barValue = precisionRound(item.series[index][dataProp], 1);
        let barHeight;
        let threshold = 12;
        if (yAxisType === 'count') {
            barHeight =
                barValue === 0
                    ? 1
                    : height - yScale(barValue) - PADDING - AXIS_PADDING;
        } else {
            barHeight = yScale(100 - barValue) - PADDING;
            if (barType === 'small') {
                barHeight = barHeight - AXIS_PADDING;
            }
        }

        if (
            (isActive && !item.active && !item.selected) ||
            (barHeight < threshold && !item.active && !item.selected)
        ) {
            return 0;
        }
        return 1;
    }

    function getTopBarHeight(item, height) {
        if (item.active || item.selected) {
            return height;
        }
        return TOP_BAR_HEIGHT;
    }

    function getLeftArrowPoints() {
        const x = pageSize !== 1 ? PADDING : width / 2 - 60;
        const y = height - PADDING - 10;
        return getPoints(x, y);
    }

    function getRightArrowPoints() {
        const x = pageSize !== 1 ? width - PADDING : width / 2 + 60;
        const y = height - PADDING - 10;
        return getPoints(x, y, true);
    }

    function getPoints(x, y, isRight) {
        const x2 = isRight ? x - ARROW_WIDTH : x + ARROW_WIDTH;
        return `${x},${y} ${x2},${y - ARROW_HEIGHT / 2} ${x2},${
            y + ARROW_HEIGHT / 2
        }`;
    }

    function getLeftCenterX() {
        const x = pageSize !== 1 ? PADDING : width / 2 - 60;
        return x + ARROW_WIDTH / 2;
    }

    function getRightCenterX() {
        const x = pageSize !== 1 ? width - PADDING : width / 2 + 60;
        return x - ARROW_WIDTH / 2;
    }

    function getCenterY() {
        return height - PADDING - 10;
    }

    function handlePrevious(e) {
        e.stopPropagation();
        if (startIndex === 0) return;
        setStartIndex(startIndex - 1);
        track(MixPanel.Events.DashboardReportStackedBarGraphTimeScrollClick, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
    }

    function handleNext(e) {
        e.stopPropagation();
        if (startIndex + pageSize >= dates.length) return;
        setStartIndex(startIndex + 1);
        track(MixPanel.Events.DashboardReportStackedBarGraphTimeScrollClick, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
    }

    function getYAxisValues(minValue, maxValue) {
        if (yAxisType === 'count') {
            return [0, maxValue];
        }
        return [0, 100];
    }

    if (height === 0) {
        return null;
    }

    let paginatedData = data2;
    let paginatedDates = dates.slice();
    if (pageSize !== 0) {
        paginatedData = produce(paginatedData, draftState => {
            draftState.forEach(item => {
                item.series = item.series.slice(
                    startIndex,
                    startIndex + pageSize
                );
            });
        });
        paginatedDates = dates.slice(startIndex, startIndex + pageSize);
    }

    if (yAxisType === 'count') {
        paginatedData = produce(paginatedData, draftState => {
            draftState.forEach((item, i) => {
                item.series.forEach((s, j) => {
                    const total = paginatedData.reduce((sum, curr) => {
                        sum += curr.series[j][dataProp];
                        return sum;
                    }, 0);

                    if (i > 0) {
                        s.previous =
                            draftState[i - 1].series[j].previous +
                            draftState[i - 1].series[j][dataProp];
                        s.total = total;
                    } else {
                        s.previous = 0;
                        s.total = total;
                    }
                    s.color = item.color;
                });
            });
        });
    } else {
        paginatedData = produce(paginatedData, draftState => {
            draftState.forEach((item, i) => {
                item.series.forEach((s, j) => {
                    if (i > 0) {
                        s.previous =
                            draftState[i - 1].series[j].previous +
                            draftState[i - 1].series[j].percentage;
                        s.total = s.previous + s.percentage;
                    } else {
                        s.previous = 0;
                        s.total = s.percentage;
                    }
                    s.color = item.color;
                });
            });
        });
    }

    function transformData(data, dataProp, currentIndex) {
        let data2 = _.cloneDeep(data);
        let totalArr = Array(data2[0]?.series?.length).fill(0);
        let dateArr = Array(data2[0]?.series?.length).fill(0);
        data2 = data2.map((d, i) => {
            d.series.forEach((s, j) => {
                totalArr[j] += s.percentage;
                dateArr[j] = s.date;
            });
            return d;
        });
        let thereIsNon100 = false;

        totalArr.forEach((t, i) => {
            if (t < 100) {
                thereIsNon100 = true;
            }
        });
        if (thereIsNon100) {
            let newSeries = [];

            totalArr.forEach((t, i) => {
                let newSeriesItem = {
                    date: dateArr[i],
                    percentage: 100 - t,
                    total: 100,
                    previous: t,
                    color: '#3a3a3d'
                };
                newSeries.push(newSeriesItem);
            });
            data2.push({
                label: OTHER_LABEL,
                series: newSeries
            });
        }

        return data2;
    }

    paginatedData = transformData(paginatedData, dataProp, currentIndex);

    const xScale = scaleBand()
        .domain(paginatedDates)
        .range([PADDING, width - PADDING]);

    let minArray = [];
    let maxArray = [];

    if (yAxisType === 'count') {
        minArray = [0];
        maxArray = Array.from({ length: data[0].series.length }).map((_, i) => {
            let countByDate = 0;

            data.forEach(d => {
                countByDate += d.series[i][dataProp];
            });

            return countByDate;
        });
    } else {
        data.forEach(d => {
            minArray.push(min(d.series, s => s[dataProp]));
            maxArray.push(max(d.series, s => s[dataProp]));
        });
    }
    minArray = minArray.filter(d => d !== Constants.InvalidValue);
    maxArray = maxArray.filter(d => d !== Constants.InvalidValue);
    const minValue = min(minArray);
    const maxValue = max(maxArray);
    const yAxisValues = getYAxisValues(minValue, maxValue);

    const yScale = scaleLinear()
        .domain(yAxisValues)
        .range([
            height - PADDING - AXIS_PADDING - TOP_BAR_HEIGHT,
            barType === 'small' ? Y_SMALL_PADDING : PADDING
        ]);

    const selectedDate = getFormattedDate(dates[currentIndex], interval);

    if (barWidth === 0) {
        return null;
    }

    return (
        <Tooltip.Provider>
            <svg
                className="barchart"
                width={width}
                height={height}
                id={reportId}
                data-export-type="stackedbar"
            >
                <defs>
                    <style type="text/css">{inlineFont}</style>
                </defs>
                {analysisType === AnalysisTypes.LinearRegression && (
                    <rect
                        className="barchart__linear-regression-background"
                        x={0}
                        y={yScale(0)}
                        width={width}
                        height={height - yScale(0)}
                    />
                )}
                {analysisType === AnalysisTypes.Index && (
                    <rect
                        className="barchart__index-background"
                        x={0}
                        y={yScale(1)}
                        width={width}
                        height={height - yScale(1)}
                        fill="#1f1f21"
                    />
                )}
                {paginatedData.map((d, i) => (
                    <g key={d.label} className="barchart__bar">
                        <NodeGroup
                            keyAccessor={d2 => `${d.label}_${d2.date}`}
                            data={d.series.slice()}
                            start={d2 => {
                                let x =
                                    xScale(d2.date) +
                                    xScale.bandwidth() / 2 -
                                    (data2.length * barWidth) / 2;
                                if (isNaN(x)) {
                                    x = -100;
                                }
                                return {
                                    x,
                                    y: height - PADDING - AXIS_PADDING,
                                    height: 0,
                                    topBarHeight: 0,
                                    barOpacity: 1,
                                    topBarOpacity: 0,
                                    textOpacity: 0
                                };
                            }}
                            enter={(d2, i) => {
                                const delay = firstLoadRef.current.firstLoad
                                    ? 0
                                    : Constants.AnimationDuration;
                                let x =
                                    xScale(d2.date) +
                                    xScale.bandwidth() / 2 -
                                    (data2.length * barWidth) / 2;
                                if (isNaN(x)) {
                                    x = -100;
                                }

                                const barValue = precisionRound(
                                    d2[dataProp],
                                    1
                                );
                                let barHeight;
                                let startY;
                                const isValueZero = barValue === 0;

                                if (yAxisType === 'count') {
                                    startY = yScale(
                                        maxValue -
                                            d2.previous -
                                            (maxValue - d2.total)
                                    );
                                    barHeight = isValueZero
                                        ? 1
                                        : height -
                                          yScale(barValue) -
                                          PADDING -
                                          AXIS_PADDING;
                                } else {
                                    startY = yScale(100 - d2.previous);
                                    barHeight =
                                        yScale(100 - barValue) - PADDING;
                                    if (barType === 'small') {
                                        barHeight = barHeight - AXIS_PADDING;
                                    }
                                }

                                return [
                                    {
                                        x: [x],
                                        y: [startY],
                                        height: [barHeight],
                                        topBarHeight: [
                                            getTopBarHeight(d, barHeight)
                                        ],
                                        topBarOpacity: [getTopBarOpacity(d)],
                                        timing: {
                                            delay: delay,
                                            duration:
                                                Constants.AnimationDuration,
                                            ease: Constants.EasingFn
                                        }
                                    },
                                    {
                                        textOpacity: [getTextOpacity(d, i)],
                                        timing: {
                                            delay: Constants.AnimationDuration,
                                            duration:
                                                Constants.AnimationDuration,
                                            ease: Constants.EasingFn
                                        }
                                    }
                                ];
                            }}
                            update={(d2, i) => {
                                let x =
                                    xScale(d2.date) +
                                    xScale.bandwidth() / 2 -
                                    (data2.length * barWidth) / 2;
                                if (isNaN(x)) {
                                    x = -100;
                                }

                                const barValue = precisionRound(
                                    d2[dataProp],
                                    1
                                );
                                let barHeight;
                                let startY;
                                const isValueZero = barValue === 0;

                                if (yAxisType === 'count') {
                                    startY = yScale(
                                        maxValue -
                                            d2.previous -
                                            (maxValue - d2.total)
                                    );
                                    barHeight = isValueZero
                                        ? 1
                                        : height -
                                          yScale(barValue) -
                                          PADDING -
                                          AXIS_PADDING;
                                } else {
                                    startY = yScale(100 - d2.previous);
                                    barHeight =
                                        yScale(100 - barValue) - PADDING;
                                    if (barType === 'small') {
                                        barHeight = barHeight - AXIS_PADDING;
                                    }
                                }

                                return [
                                    {
                                        x: [x],
                                        y: [startY],
                                        height: [barHeight],
                                        topBarHeight: [
                                            getTopBarHeight(d, barHeight)
                                        ],
                                        barOpacity: [getBarOpacity(d)],
                                        topBarOpacity: [getTopBarOpacity(d)],
                                        textOpacity: [getTextOpacity(d, i)],
                                        timing: {
                                            duration:
                                                Constants.AnimationDuration,
                                            ease: Constants.EasingFn
                                        }
                                    }
                                ];
                            }}
                            interpolation={getInterpolator}
                        >
                            {nodes => (
                                <Fragment>
                                    {nodes
                                        .slice(0)
                                        .reverse()
                                        .map(({ state, data, key }) => {
                                            if (
                                                d.isEqualityIndex ||
                                                d.isAttritionIndex ||
                                                data[dataProp] ===
                                                    Constants.InvalidValue
                                            ) {
                                                return null;
                                            }

                                            let formattedText;
                                            let xText;
                                            let yText;
                                            let translateX;
                                            let showText = true;
                                            const isLinearRegression =
                                                analysisType ===
                                                AnalysisTypes.LinearRegression;
                                            const isValueNegative =
                                                data[dataProp] < 0;

                                            formattedText =
                                                yAxisType === 'count'
                                                    ? data.totalCount ||
                                                      data.includedCount
                                                    : getFormattedValue(
                                                          analysisType,
                                                          data[dataProp]
                                                      );

                                            xText =
                                                isLinearRegression &&
                                                isValueNegative
                                                    ? state.x + barWidth - 2
                                                    : state.x + 2;

                                            let startY;
                                            let barHeight;
                                            const barValue = precisionRound(
                                                data[dataProp],
                                                1
                                            );

                                            if (yAxisType === 'count') {
                                                startY = yScale(
                                                    maxValue -
                                                        data.previous -
                                                        (maxValue - data.total)
                                                );

                                                barHeight =
                                                    height - yScale(barValue);

                                                yText =
                                                    startY +
                                                    barHeight / 2 -
                                                    PADDING;
                                            } else {
                                                startY = yScale(
                                                    100 - data.previous
                                                );

                                                barHeight = yScale(
                                                    100 - barValue
                                                );

                                                yText =
                                                    barType === 'small'
                                                        ? startY +
                                                          barHeight / 2 -
                                                          PADDING
                                                        : startY +
                                                          barHeight / 2 -
                                                          4;
                                            }

                                            if (
                                                yText >
                                                height -
                                                    12 -
                                                    PADDING -
                                                    AXIS_PADDING
                                            ) {
                                                yText =
                                                    height -
                                                    12 -
                                                    PADDING -
                                                    AXIS_PADDING;
                                            }
                                            translateX =
                                                (barWidth + TEXT_FONT_SIZE) /
                                                    2 -
                                                4;

                                            const paleBarHeight =
                                                state.height - TOP_BAR_HEIGHT;
                                            const barCount = data2.length;
                                            let groupWidth =
                                                (barWidth - PADDING + 8) *
                                                barCount;

                                            return (
                                                <Fragment key={key}>
                                                    {paleBarHeight > 0 && (
                                                        <rect
                                                            x={state.x}
                                                            y={
                                                                state.y +
                                                                TOP_BAR_HEIGHT
                                                            }
                                                            width={groupWidth}
                                                            height={
                                                                paleBarHeight
                                                            }
                                                            fill={data.color}
                                                            opacity={
                                                                state.barOpacity
                                                            }
                                                        />
                                                    )}

                                                    <Tooltip.Root
                                                        delayDuration={0}
                                                        open={
                                                            barHovered &&
                                                            d.active &&
                                                            state.active
                                                        }
                                                    >
                                                        <Tooltip.Trigger
                                                            asChild
                                                        >
                                                            <g>
                                                                <rect
                                                                    width={
                                                                        groupWidth
                                                                    }
                                                                    x={state.x}
                                                                    y={state.y}
                                                                    height={
                                                                        state.topBarHeight
                                                                    }
                                                                    fill={
                                                                        data.color
                                                                    }
                                                                    opacity={
                                                                        state.topBarOpacity
                                                                    }
                                                                />
                                                                {showText &&
                                                                    !employeeCountHidden && (
                                                                        <circle
                                                                            cx={
                                                                                xText +
                                                                                groupWidth +
                                                                                10
                                                                            }
                                                                            r={
                                                                                3
                                                                            }
                                                                            cy={
                                                                                yText -
                                                                                4
                                                                            }
                                                                            height={
                                                                                10
                                                                            }
                                                                            fill={
                                                                                data.color
                                                                            }
                                                                            opacity={
                                                                                state.textOpacity
                                                                            }
                                                                            cursor="pointer"
                                                                            onMouseOver={() => {
                                                                                setActiveLabel(
                                                                                    d.label
                                                                                );
                                                                                setBarHovered(
                                                                                    true
                                                                                );
                                                                            }}
                                                                            onMouseOut={() => {
                                                                                clearActiveLabel(
                                                                                    null,
                                                                                    d.label
                                                                                );
                                                                                setBarHovered(
                                                                                    false
                                                                                );
                                                                            }}
                                                                            onClick={handleToggle.bind(
                                                                                null,
                                                                                d.label
                                                                            )}
                                                                        />
                                                                    )}

                                                                <rect
                                                                    width={
                                                                        groupWidth
                                                                    }
                                                                    x={state.x}
                                                                    y={state.y}
                                                                    height={
                                                                        state.height
                                                                    }
                                                                    fill="transparent"
                                                                    cursor="pointer"
                                                                    onMouseOver={() => {
                                                                        setActiveLabel(
                                                                            d.label
                                                                        );
                                                                        setBarHovered(
                                                                            true
                                                                        );
                                                                    }}
                                                                    onMouseOut={() => {
                                                                        clearActiveLabel(
                                                                            d.label
                                                                        );
                                                                        setBarHovered(
                                                                            false
                                                                        );
                                                                    }}
                                                                    onClick={handleToggle.bind(
                                                                        null,
                                                                        d.label
                                                                    )}
                                                                />
                                                            </g>
                                                        </Tooltip.Trigger>
                                                        <Tooltip.Portal>
                                                            <Tooltip.Content
                                                                side="bottom"
                                                                sideOffset={16}
                                                            >
                                                                <ChartTooltip
                                                                    dataPoint={
                                                                        d.label ===
                                                                        'OtherXYZ'
                                                                            ? undefined
                                                                            : d
                                                                    }
                                                                    seriesItem={
                                                                        data
                                                                    }
                                                                    columnProps={
                                                                        columnProps
                                                                    }
                                                                    interval={
                                                                        interval
                                                                    }
                                                                />
                                                            </Tooltip.Content>
                                                        </Tooltip.Portal>
                                                    </Tooltip.Root>

                                                    {showText &&
                                                        !employeeCountHidden && (
                                                            <text
                                                                width={barWidth}
                                                                x={
                                                                    xText +
                                                                    groupWidth +
                                                                    20
                                                                }
                                                                y={yText}
                                                                textAnchor={
                                                                    'start'
                                                                }
                                                                height={
                                                                    state.height
                                                                }
                                                                // transform={
                                                                //     barType === 'small'
                                                                //         ? `rotate(-90, ${xText}, ${yText}) translate(0 ${translateX})`
                                                                //         : ''
                                                                // }
                                                                style={{
                                                                    fontFamily:
                                                                        FontFamilies.Regular,
                                                                    fontSize:
                                                                        barType ===
                                                                        'large'
                                                                            ? LARGE_TEXT_FONT_SIZE
                                                                            : TEXT_FONT_SIZE
                                                                }}
                                                                fill="var(--color-ui-100)"
                                                                opacity={
                                                                    state.textOpacity
                                                                }
                                                                cursor="pointer"
                                                                onMouseOver={setActiveLabel.bind(
                                                                    null,
                                                                    d.label
                                                                )}
                                                                onMouseOut={clearActiveLabel.bind(
                                                                    null,
                                                                    d.label
                                                                )}
                                                                onClick={handleToggle.bind(
                                                                    null,
                                                                    d.label
                                                                )}
                                                            >
                                                                {formattedText}
                                                            </text>
                                                        )}
                                                </Fragment>
                                            );
                                        })}
                                </Fragment>
                            )}
                        </NodeGroup>
                    </g>
                ))}
                <Animate
                    show={showIndexLine}
                    start={() => ({
                        opacity: 0
                    })}
                    enter={() => ({
                        opacity: [1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    update={() => ({
                        opacity: [1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    leave={() => ({
                        opacity: [0],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                >
                    {state => {
                        const indexText =
                            analysisType === AnalysisTypes.LinearRegression
                                ? 'Index (0%)'
                                : 'Index (1x)';
                        const yIndex =
                            analysisType === AnalysisTypes.LinearRegression
                                ? yScale(0)
                                : yScale(1);
                        const xIndex =
                            PADDING +
                            measureText(indexText, CanvasFonts.Medium13).width +
                            8;

                        return (
                            <Fragment>
                                <text
                                    className="barchart__index-text"
                                    x={PADDING}
                                    y={yIndex + 3}
                                    opacity={state.opacity}
                                >
                                    {indexText}
                                </text>
                                <line
                                    className="barchart__index-line"
                                    x1={xIndex}
                                    y1={yIndex}
                                    x2={width - PADDING}
                                    y2={yIndex}
                                    opacity={state.opacity}
                                />
                            </Fragment>
                        );
                    }}
                </Animate>
                <line
                    className="barchart__axis-line"
                    x1={PADDING}
                    y1={height - AXIS_PADDING - PADDING}
                    x2={width - PADDING}
                    y2={height - AXIS_PADDING - PADDING}
                />
                <NodeGroup
                    keyAccessor={d => d}
                    data={paginatedDates}
                    start={data => ({
                        opacity: 0,
                        x: xScale(data) + xScale.bandwidth() / 2
                    })}
                    enter={data => {
                        const formattedDate = getFormattedDate(data, interval);
                        const selected =
                            dateSelection && selectedDate === formattedDate;

                        return {
                            opacity: [selected ? 1 : 0.7],
                            timing: {
                                delay: Constants.AnimationDuration,
                                duration: Constants.AnimationDuration,
                                ease: Constants.EasingFn
                            }
                        };
                    }}
                    update={data => {
                        const formattedDate = getFormattedDate(data, interval);
                        const selected =
                            dateSelection && selectedDate === formattedDate;

                        return {
                            opacity: [selected ? 1 : 0.7],
                            x: [xScale(data) + xScale.bandwidth() / 2],
                            timing: {
                                duration: Constants.AnimationDuration,
                                ease: Constants.EasingFn
                            }
                        };
                    }}
                    interpolation={getInterpolator}
                >
                    {nodes => (
                        <g className="barchart__x-labels">
                            {nodes.map(({ state, data, key }, index) => {
                                const formattedDate = getFormattedDate(
                                    data,
                                    interval
                                );
                                const selected =
                                    dateSelection &&
                                    selectedDate === formattedDate;
                                const clickable =
                                    dateSelection &&
                                    selectedDate !== formattedDate;

                                return (
                                    <text
                                        className={classNames(
                                            'barchart__x-labels__text',
                                            {
                                                'barchart__x-labels__text--selected':
                                                    selected
                                            }
                                        )}
                                        key={key}
                                        x={state.x}
                                        y={
                                            height -
                                            PADDING -
                                            0.5 * AXIS_FONT_SIZE
                                        }
                                        textAnchor="middle"
                                        opacity={state.opacity}
                                        onClick={
                                            clickable
                                                ? e => handleTimeClick(index, e)
                                                : e => e.stopPropagation()
                                        }
                                    >
                                        {formattedDate}
                                    </text>
                                );
                            })}
                        </g>
                    )}
                </NodeGroup>
                {pageSize > 0 && (
                    <Fragment>
                        <polygon
                            points={getLeftArrowPoints()}
                            fill={
                                startIndex === 0
                                    ? 'rgba(242,242,242,0.24)'
                                    : '#f2f2f2'
                            }
                        />
                        <circle
                            cx={getLeftCenterX()}
                            cy={getCenterY()}
                            r={ARROW_CIRCLE_RADIUS}
                            cursor={startIndex === 0 ? 'default' : 'pointer'}
                            fill="transparent"
                            onClick={handlePrevious}
                        />
                        <polygon
                            points={getRightArrowPoints()}
                            fill={
                                startIndex + pageSize === dates.length
                                    ? 'rgba(242,242,242,0.24)'
                                    : '#f2f2f2'
                            }
                        />
                        <circle
                            cx={getRightCenterX()}
                            cy={getCenterY()}
                            r={ARROW_CIRCLE_RADIUS}
                            cursor={
                                startIndex + pageSize === dates.length
                                    ? 'default'
                                    : 'pointer'
                            }
                            fill="transparent"
                            onClick={handleNext}
                        />
                    </Fragment>
                )}
            </svg>
        </Tooltip.Provider>
    );
}

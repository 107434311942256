import React, { useState, useEffect } from 'react';
import produce from 'immer';
import Table from '../detail/Table';
import { toggleExpandedInSubtable, sortInSubtable } from './actions';
import { navigateToDetail } from '../detail/actions';
import ChartTypes from '../../constants/ChartTypes';
import HorizontalBarChart from '../../common/HorizontalBarChart';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

function Subtable({
    onHover,
    onHoverEnd,
    onToggle,
    hoveredItem,
    selection,
    status,
    ...props
}: any) {
    const dispatch = useAppDispatch();
    const { breakdownSegment } = useAppSelector(state => state.explore.filter);
    const {
        lifecycle,
        analysisType,
        employeeAttributes,
        employeeAttribute,
        attributeName,
        sortCriteria,
        columnProps,
        summary,
        groupSummary,
        showGroupSummary,
        expanded,
        subtableChartType,
        data: initialData,
        diversityAttributes
    } = useAppSelector(state => state.explore.subtable);
    const [data, setData] = useState(initialData);
    const [selection_, setSelection] = useState([]);

    useEffect(() => {
        const selectedData = produce(initialData, draftState => {
            selection_.forEach(s => {
                const item = draftState?.find(ds => ds.label === s);
                if (item) {
                    item.selected = true;
                }
            });
        });
        setData(selectedData);
    }, [props.data, breakdownSegment, sortCriteria, selection_]);

    function handleLabelActive(label: any) {
        setData(prevState => {
            return produce(prevState, draftState => {
                const item = draftState?.find(d => d.label === label);
                if (item) {
                    item.active = true;
                }
            });
        });
    }

    function handleLabelClear(label: any) {
        setData(prevState => {
            return produce(prevState, draftState => {
                const item = draftState?.find(d => d.label === label);
                if (item) {
                    item.active = false;
                }
            });
        });
    }

    function handleLabelToggle(label: any) {
        setSelection(prevState =>
            produce(prevState, draftState => {
                const index = draftState.findIndex((d: any) => d === label);
                if (index !== -1) {
                    draftState.splice(index, 1);
                } else {
                    // @ts-ignore
                    draftState.push(label);
                }
            })
        );
    }

    function handleNavigateToDetail(...args: any) {
        // @ts-ignore
        dispatch(navigateToDetail(...args));
    }

    if (subtableChartType === ChartTypes.Matrix) {
        return (
            <Table
                lifecycle={lifecycle}
                analysisType={analysisType}
                employeeAttribute={employeeAttribute}
                attributeName={attributeName}
                employeeAttributes={employeeAttributes}
                diversityAttributes={diversityAttributes}
                data={data}
                summary={summary}
                groupSummary={groupSummary}
                showGroupSummary={showGroupSummary}
                expanded={expanded}
                toggleExpansion={() => dispatch(toggleExpandedInSubtable())}
                onSort={props => dispatch(sortInSubtable(props))}
                sortCriteria={sortCriteria}
                setActiveLabel={handleLabelActive}
                clearActiveLabel={handleLabelClear}
                toggleLabel={handleLabelToggle}
                columnProps={columnProps}
                navigateToDetail={handleNavigateToDetail}
            />
        );
    } else if (subtableChartType === ChartTypes.HorizontalBar) {
        return (
            <HorizontalBarChart
                data={data}
                diversityAttributes={diversityAttributes}
                columnProps={columnProps}
                onHover={onHover}
                onHoverEnd={onHoverEnd}
                onToggle={onToggle}
                selection={selection}
                status={status}
                hoveredItem={hoveredItem}
            />
        );
    }

    return null;
}

Subtable.defaultProps = {
    data: [],
    diversityAttributes: [],
    employeeAttributes: []
};

export default Subtable;

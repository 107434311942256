import React, { useState } from 'react';
import Popover from '../../../common/Popover';
import HMore from '../../../icons/HMoreVertical';
import Menu from 'antd/lib/menu';
import { Survey } from './types';
import { push } from 'connected-react-router';
import { useAppDispatch } from '../../../store/hooks';
import { fetchSurveys } from './reducer';
import {
    deleteSurveyTemplate,
    duplicateSurveyTemplate,
    publishSurveyTemplate,
    archiveSurveyTemplate,
    setInitialised
} from './editReducer';
import DeleteModal from '../../../common/DeleteModal';
import ConfirmationModal from '../../../common/ConfirmationModal';

interface TemplateProps {
    survey: Survey;
    archived: boolean;
}

export default function Template({ survey, archived }: TemplateProps) {
    const dispatch = useAppDispatch();
    const [moreOpen, setMoreOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    const [archiveModalOpen, setArchiveModalOpen] = useState(false);
    const [archivePending, setArchivePending] = useState(false);

    const imageUrl =
        survey.meta.uiElements.find(e => e.key === 'imageUrl')?.value ?? '';

    function handleEdit() {
        dispatch(setInitialised(false));
        dispatch(
            push(
                `/datastudio/survey/template/edit?globalSurveyId=${survey.globalSurveyId}`
            )
        );
    }

    async function handleDelete() {
        await dispatch(deleteSurveyTemplate(survey.globalSurveyId));
        await dispatch(fetchSurveys());
        setDeleteModalOpen(false);
    }

    async function handlePublish() {
        await dispatch(
            publishSurveyTemplate({
                globalSurveyId: survey.globalSurveyId,
                isPublished: !survey.isPublished
            })
        );
        await dispatch(fetchSurveys());
    }

    async function handleArchive() {
        try {
            setArchivePending(true);
            await dispatch(
                archiveSurveyTemplate({
                    survey,
                    shouldArchive: !archived
                })
            );
            dispatch(fetchSurveys());
        } finally {
            setArchiveModalOpen(false);
            setArchivePending(false);
        }
    }

    function handleMoreClick() {
        setMoreOpen(!moreOpen);
    }

    function handleMenuClick({ domEvent }: any) {
        domEvent.stopPropagation();
    }

    async function handleDuplicate() {
        const categories = survey.categories.map(category => {
            const globalQuestionIds = category.questions.map(
                q => q.globalQuestionId
            );
            return {
                globalCategoryId: category.globalCategoryId,
                globalQuestionIds
            };
        });

        await dispatch(
            duplicateSurveyTemplate({
                title: survey.title,
                description: survey.description,
                isPublished: false,
                isPrivate: !!survey.enterpriseId,
                enterpriseId: survey.enterpriseId,
                categories,
                uiElements: survey.meta.uiElements
            })
        );
        await dispatch(fetchSurveys());
        window.scrollTo({
            top: document.body.scrollHeight,
            left: 0,
            behavior: 'smooth'
        });
    }

    return (
        <div>
            <div
                className="survey"
                key={survey.title}
                onClick={() => handleEdit()}
            >
                <div className="survey__img">
                    <img src={imageUrl} />
                </div>
                <div className="survey__title">
                    <div>{survey.title}</div>
                    <div
                        className="survey__more"
                        onClick={e => e.stopPropagation()}
                    >
                        <Popover
                            placement="bottomRight"
                            visible={moreOpen}
                            onVisibleChange={handleMoreClick}
                            content={
                                <Menu
                                    className="widget-menu"
                                    selectedKeys={[]}
                                    onClick={handleMenuClick}
                                >
                                    {!archived && (
                                        <Menu.Item
                                            key="publish"
                                            onClick={handlePublish}
                                        >
                                            {survey.isPublished
                                                ? 'Unpublish'
                                                : 'Publish'}
                                        </Menu.Item>
                                    )}
                                    {!archived && (
                                        <Menu.Item
                                            key="edit"
                                            onClick={handleEdit}
                                        >
                                            Edit
                                        </Menu.Item>
                                    )}
                                    <Menu.Item
                                        key="duplicate"
                                        onClick={handleDuplicate}
                                    >
                                        Duplicate
                                    </Menu.Item>
                                    <Menu.Item
                                        key="archive"
                                        onClick={() => {
                                            setArchiveModalOpen(true);
                                        }}
                                        disabled={survey.isPublished}
                                    >
                                        {archived ? 'Unarchive' : 'Archive'}
                                    </Menu.Item>
                                    <Menu.Item
                                        key="delete"
                                        onClick={() => {
                                            setDeleteModalOpen(true);
                                        }}
                                    >
                                        Delete
                                    </Menu.Item>
                                </Menu>
                            }
                            overlayClassName="popover--no-padding"
                        >
                            <HMore className="icon" width={24} height={24} />
                        </Popover>
                    </div>
                </div>
                <div className="survey__desc">{survey.description}</div>
                <div className="survey__published">
                    {survey.isPublished ? 'Published' : 'Draft'}
                </div>
            </div>
            <DeleteModal
                isOpen={deleteModalOpen}
                onCancel={() => setDeleteModalOpen(false)}
                onAction={handleDelete}
                title="Delete campaign template"
            >
                <p>
                    Click Delete to permanently delete this campaign template.
                </p>
            </DeleteModal>
            <ConfirmationModal
                title={archived ? 'Unarchive Campaign' : 'Archive Campaign'}
                isOpen={archiveModalOpen}
                pending={archivePending}
                confirmationText={archived ? 'Unarchive' : 'Archive'}
                onAction={(e: any) => {
                    e.stopPropagation();
                    handleArchive();
                }}
                onCancel={(e: any) => {
                    e.stopPropagation();
                    setArchivePending(false);
                    setArchiveModalOpen(false);
                }}
            >
                {!archived ? (
                    <p>
                        Archiving removes the campaign from the active view. Do
                        you want to archive?
                    </p>
                ) : (
                    <p>
                        Unarchiving restores the campaign to the active view. Do
                        you want to unarchive?
                    </p>
                )}
            </ConfirmationModal>
        </div>
    );
}

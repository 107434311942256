import React from 'react';
import Search from './Search/Search';
import { navigateToReport } from '../../actions';
import { track } from '../../../lib/segment';
import MixPanel from '../../../constants/MixPanel';
import WithPermissions from '../../../common/WithPermissions';
import RbacActions from '../../../constants/RbacActions';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';

interface HighlightedIndex {
    section?: number;
    thumbnail?: number;
}

/**
 * Library of the thumbnails for the blank explore screen
 *
 */
const SearchLibrary = () => {
    const dispatch = useAppDispatch();
    const role = useAppSelector(state => state.auth.role);
    const isEmptyState = !useAppSelector(
        state => state.explore.filter?.analysisName
    );
    const { searches, sortCriteria } = useAppSelector(
        state => state.explore.searches
    );

    const [highlightedIndex, setHighlightedIndex] =
        React.useState<HighlightedIndex | null>(null);
    const [allHighlighted, setAllHighlighted] = React.useState(true);
    const [mouseOutside, setMouseOutside] = React.useState(true);

    const librarySections = [{ title: 'Saved Searches', charts: searches }];

    React.useEffect(() => {
        if (!highlightedIndex || mouseOutside) setAllHighlighted(true);
        else setAllHighlighted(false);
    }, [highlightedIndex, mouseOutside]);

    const handleNavigate = (chart: any) => {
        setAllHighlighted(false);
        setMouseOutside(true);
        setHighlightedIndex(null);
        dispatch(navigateToReport(chart));

        track(MixPanel.Events.ExploreSavedSearchReportClick, {
            'Report Name': chart.name
        });
    };

    if (!isEmptyState) {
        return null;
    }

    return (
        <WithPermissions actions={[RbacActions['Dashboards/ViewAll']]}>
            <div className="thumbnail-library__container">
                {librarySections.map((section, sectionIndex) => (
                    <>
                        {section.charts.length > 0 && (
                            <h3 className="thumbnail-library__section__title">
                                {section.title}
                            </h3>
                        )}
                        <LibrarySection setMouseOutside={setMouseOutside}>
                            {section.charts
                                .sort((a: any, b: any) => {
                                    const res = a[
                                        sortCriteria.columnName
                                    ]?.localeCompare(
                                        b[sortCriteria.columnName]
                                    );
                                    return sortCriteria.ascending ? res : -res;
                                })
                                .map((chart: any, index: number) => {
                                    return (
                                        <Search
                                            type={chart.configuration[0]?.value?.toLowerCase()}
                                            reportId={chart.reportId}
                                            key={chart.reportId}
                                            title={chart.name}
                                            count={
                                                chart.attributes[0].value.length
                                            }
                                            onClick={() => {
                                                handleNavigate(chart);
                                            }}
                                            highlighted={
                                                allHighlighted ||
                                                mouseOutside ||
                                                (highlightedIndex &&
                                                    highlightedIndex.section ===
                                                        sectionIndex &&
                                                    highlightedIndex.thumbnail ===
                                                        index) ||
                                                false
                                            }
                                            hovered={
                                                (highlightedIndex &&
                                                    highlightedIndex.section ===
                                                        sectionIndex &&
                                                    highlightedIndex.thumbnail ===
                                                        index) ||
                                                false
                                            }
                                            onMouseEnter={() =>
                                                setHighlightedIndex({
                                                    section: sectionIndex,
                                                    thumbnail: index
                                                })
                                            }
                                            onMouseLeave={() =>
                                                setHighlightedIndex(null)
                                            }
                                        ></Search>
                                    );
                                })}
                        </LibrarySection>
                    </>
                ))}
            </div>
        </WithPermissions>
    );
};

interface LibrarySectionProps extends React.HTMLProps<HTMLDivElement> {
    setMouseOutside: (mouseOutside: boolean) => void;
}

const LibrarySection = ({ setMouseOutside, ...props }: LibrarySectionProps) => {
    return (
        <div
            className="thumbnail-library__section"
            onMouseLeave={() => setMouseOutside(true)}
            onMouseOver={() => setMouseOutside(false)}
            {...props}
        >
            {props.children}
        </div>
    );
};

export default SearchLibrary;

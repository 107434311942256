import React, { useState, useRef } from 'react';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { updateAccount, editLogo } from './actions';
import sanitize from '../../lib/sanitize';
import Divider from '../../common/Divider';
import ThemeColorCard from '../../common/ThemeColorCard';
import TextField from '../../common/TextField';
import Trash from '../../icons/Trash';
import Constants from '../../constants/Constants';
import WithPermissions from '../../common/WithPermissions';
import RbacActions from '../../constants/RbacActions';
import usePermissions from '../../hooks/usePermissions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';

function Settings() {
    const dispatch = useAppDispatch();
    const { enterpriseName, logoURL, logoLightURL } = useAppSelector(
        state => state.account
    );

    const [editCompany, setEditCompany] = useState(false);
    const [newEnterpriseName, setEnterpriseName] = useState(enterpriseName);
    const editAllowed = usePermissions({
        actions: [RbacActions['Settings/Edit']]
    });
    const darkLogoRef = useRef<HTMLInputElement>(null);
    const lightLogoRef = useRef<HTMLInputElement>(null);

    const disabled = enterpriseName === newEnterpriseName;
    const disabledReset = disabled && !editCompany;

    function handlePhotoChange(ref: any, lightMode: boolean) {
        if (!ref.current.files.length) {
            return;
        }

        const file = ref.current.files[0];
        const regex = /(.*?)\.(jpg|bmp|jpeg|png|tif|tiff|gif)$/;
        if (!file.name.match(regex)) {
            console.log('No file');
            return;
        }
        if (file.size > 10000000) {
            console.log('File size is too big');
            return;
        }

        const reader = new FileReader();
        reader.onload = function (e) {
            const imageData = e.target?.result;
            if (imageData) {
                dispatch(
                    editLogo({
                        logoImage: imageData,
                        lightMode
                    })
                );
            }
        };
        reader.readAsDataURL(file);
    }

    function saveCompanySettings() {
        let promise;
        promise = dispatch(
            updateAccount({
                newEnterpriseName: sanitize(newEnterpriseName).trim()
            })
        );
        promise.then(() => {
            setEditCompany(false);
        });
    }

    function reset() {
        setEnterpriseName(enterpriseName);
        setEditCompany(false);
    }

    return (
        <WithPermissions
            actions={[RbacActions['Settings/View']]}
            showMessage={true}
        >
            <div className="settings">
                <div className="settings__top">
                    <div className="settings__title">Company name</div>
                    <div className="settings__fields">
                        <TextField
                            value={newEnterpriseName}
                            onChange={e => setEnterpriseName(e.target.value)}
                            readOnly={!editAllowed}
                        />
                        <WithPermissions
                            actions={[RbacActions['Settings/Edit']]}
                        >
                            <div className="settings__actions">
                                <Button
                                    componentType={ButtonTypes.type.TERTIARY}
                                    disabled={disabledReset}
                                    onClick={reset}
                                >
                                    Reset
                                </Button>
                                <Button
                                    disabled={disabled}
                                    onClick={saveCompanySettings}
                                >
                                    Save
                                </Button>
                            </div>
                        </WithPermissions>
                    </div>
                    <Divider></Divider>
                </div>
                <div className="settings__custom-logo">
                    <div className="font-subtitle">Logo</div>
                    <div className="settings__logo-cards">
                        <div className="settings__logo-card">
                            <div className="font-subtitle">Dark mode</div>
                            <div className="settings__logo-card-box">
                                {logoURL !== Constants.DefaultLogoUrls.dark ? (
                                    <div className="settings__logo-preview">
                                        <img
                                            className="settings__logo-image"
                                            src={logoURL}
                                            alt="logo"
                                        />
                                        <WithPermissions
                                            actions={[
                                                RbacActions['Settings/Edit']
                                            ]}
                                        >
                                            <Button
                                                componentType={
                                                    ButtonTypes.type.TERTIARY
                                                }
                                                classes={[
                                                    'settings__logo-delete'
                                                ]}
                                                circle={true}
                                                onClick={() => {
                                                    dispatch(
                                                        editLogo({
                                                            logoImage:
                                                                undefined,
                                                            lightMode: false
                                                        })
                                                    );
                                                }}
                                            >
                                                <Trash width={24} height={24} />
                                            </Button>
                                        </WithPermissions>
                                    </div>
                                ) : (
                                    <div className="settings__logo-card-inner">
                                        {editAllowed ? (
                                            <React.Fragment>
                                                Upload logo
                                                <input
                                                    className="settings__logo-file-upload"
                                                    ref={darkLogoRef}
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={() =>
                                                        handlePhotoChange(
                                                            darkLogoRef,
                                                            false
                                                        )
                                                    }
                                                />
                                            </React.Fragment>
                                        ) : (
                                            <span>No logo uploaded</span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="settings__logo-card settings__logo-card">
                            <div className="font-subtitle">Light mode</div>
                            <div className="settings__logo-card-box settings__logo-card-box--light">
                                {logoLightURL !==
                                Constants.DefaultLogoUrls.light ? (
                                    <div className="settings__logo-preview">
                                        <img
                                            className="settings__logo-image"
                                            src={logoLightURL}
                                            alt="logo"
                                        />
                                        <WithPermissions
                                            actions={[
                                                RbacActions['Settings/Edit']
                                            ]}
                                        >
                                            <Button
                                                componentType={
                                                    ButtonTypes.type.TERTIARY
                                                }
                                                classes={[
                                                    'settings__logo-delete'
                                                ]}
                                                circle={true}
                                                onClick={() => {
                                                    dispatch(
                                                        editLogo({
                                                            logoImage:
                                                                undefined,
                                                            lightMode: true
                                                        })
                                                    );
                                                }}
                                            >
                                                <Trash width={24} height={24} />
                                            </Button>
                                        </WithPermissions>
                                    </div>
                                ) : (
                                    <div className="settings__logo-card-inner">
                                        {editAllowed ? (
                                            <React.Fragment>
                                                Upload logo
                                                <input
                                                    className="settings__logo-file-upload"
                                                    ref={lightLogoRef}
                                                    type="file"
                                                    accept="image/*"
                                                    onChange={() =>
                                                        handlePhotoChange(
                                                            lightLogoRef,
                                                            true
                                                        )
                                                    }
                                                />
                                            </React.Fragment>
                                        ) : (
                                            <span>No logo uploaded</span>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <Divider></Divider>
                </div>

                <div className="settings__colors">
                    <ThemeColorCard cardType="dark" />
                    <ThemeColorCard cardType="light" />
                </div>
            </div>
        </WithPermissions>
    );
}

export default Settings;

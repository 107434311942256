import ActionTypes from '../../constants/ActionTypes';
import cloneDeep from 'lodash/cloneDeep';
import AnalysisTypes from '../../constants/AnalysisTypes';
import getBenchmarkData from '../../benchmark/getBenchmarkData';
import {
    getStateForGenerateReport,
    getStateForPrimaryData,
    getStateForSubtable
} from '../../store/reducerUtils';

const defaultSortCriteria = {
    columnName: 'text',
    ascending: true
};

const defaultSortCriteriaForSubtable = {
    columnName: 'label',
    ascending: true
};

interface ReducerState {
    [key: string]: any;
}

export default function reportsReducer(
    state: ReducerState = {},
    action: any
): ReducerState {
    switch (action.type) {
        case ActionTypes.GetReportPending: {
            return {
                ...state,
                [action.reportId]: {
                    lifecycle: action.analysisName,
                    data: [],
                    generatePending: true,
                    generateError: false,
                    generateNoData: false,
                    tableRowHeight: 44,
                    sortCriteria: defaultSortCriteria,
                    expanded: false
                }
            };
        }

        case ActionTypes.GetMatrixPending: {
            return {
                ...state,
                [action.reportId]: {
                    ...state[action.reportId],
                    lifecycle: action.analysisName,
                    data: [],
                    generatePending: true,
                    generateError: false,
                    generateNoData: false,
                    sortCriteria: defaultSortCriteriaForSubtable,
                    expanded: false
                }
            };
        }
        case ActionTypes.GetMatrixRejected:
        case ActionTypes.GetReportRejected: {
            return {
                ...state,
                [action.reportId]: {
                    ...state[action.reportId],
                    generatePending: false,
                    generateError: true,
                    generateNotAllowed: action.status === 400
                }
            };
        }
        case ActionTypes.GetReportInvalidVariation: {
            return {
                ...state,
                [action.reportId]: {
                    ...state[action.reportId],
                    generatePending: false,
                    generateInvalidVariation: true
                }
            };
        }
        case ActionTypes.GetReportNoData: {
            return {
                ...state,
                [action.reportId]: {
                    ...state[action.reportId],
                    generatePending: false,
                    generateNoData: true
                }
            };
        }
        case ActionTypes.GetReportFulfilled: {
            const { reportId } = action;
            const newState = getStateForGenerateReport(state[reportId], action);

            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    ...newState
                }
            };
        }
        case ActionTypes.GetMatrixFulfilled: {
            const { reportId } = action;
            const newState = getStateForSubtable(state[reportId], action);
            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    ...newState
                }
            };
        }
        case ActionTypes.GeneratePrimaryDataFulfilledInDashboard: {
            const { reportId } = action;
            const newState = getStateForPrimaryData(state[reportId], action);
            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    ...newState
                }
            };
        }
        case ActionTypes.ShowDeleteReportModal:
            return {
                ...state,
                deleteReportId: action.reportId,
                showDelete: true
            };
        case ActionTypes.CloseDeleteReportModal:
            return {
                ...state,
                showDelete: false,
                deleteReportId: undefined
            };
        case ActionTypes.ShowSelectDashboardModal:
            return {
                ...state,
                showSelect: true,
                selectDashboardId: action.dashboardId,
                selectReportId: action.reportId
            };
        case ActionTypes.CloseSelectDashboardModal:
            return {
                ...state,
                showSelect: false,
                selectDashboardId: undefined,
                selectReportId: undefined
            };
        case ActionTypes.ShowCreateDashboardModalForCopy:
            return {
                ...state,
                showSelect: false,
                showCreate: true
            };
        case ActionTypes.CloseCreateDashboardModalForCopy:
            return {
                ...state,
                showCreate: false,
                selectDashboardId: undefined,
                selectReportId: undefined
            };
        case ActionTypes.DeleteReportPending:
            return {
                ...state,
                deletePending: true
            };
        case ActionTypes.DeleteReportFulfilled:
        case ActionTypes.DeleteReportRejected:
            return {
                ...state,
                deletePending: false
            };
        case ActionTypes.SetReportData:
            return {
                ...state,
                [action.reportId]: action.reportData
            };
        case ActionTypes.SetSearchTextInDashboard:
            return {
                ...state,
                searchText: action.searchText
            };
        case ActionTypes.SelectCurrentTimeInReport: {
            const { reportId, currentIndex } = action;
            if (!state[reportId]) return state;
            const { analysisType, data } = state[reportId];
            let { confidence } = state[reportId];
            if (
                analysisType === AnalysisTypes.LinearRegression &&
                data.length > 1 &&
                currentIndex >= 0
            ) {
                confidence = data[1].series[currentIndex].confidenceScore;
            }
            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    currentIndex,
                    confidence
                }
            };
        }
        case ActionTypes.SetRowHeightInReport: {
            const { reportId, tableRowHeight } = action;
            if (!state[reportId]) return state;
            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    tableRowHeight
                }
            };
        }
        case ActionTypes.SetSortCriteriaInReport: {
            const { reportId, sortCriteria } = action;
            if (!state[reportId]) return state;
            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    sortCriteria
                }
            };
        }
        case ActionTypes.SetExpandedInReport: {
            const { reportId, expanded } = action;
            if (!state[reportId]) return state;
            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    expanded
                }
            };
        }
        case ActionTypes.CloneReportData: {
            const { reportIds } = action;
            let newState = state;
            reportIds.forEach((r: any) => {
                const reportData = state[r.from];
                if (reportData) {
                    const newReportData = cloneDeep(reportData);
                    newState = {
                        ...newState,
                        [r.to]: newReportData
                    };
                }
            });
            return newState;
        }
        case ActionTypes.SetBenchmarkInReport: {
            const { reportId, benchmark } = action;
            if (!state[reportId]) return state;
            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    benchmark
                }
            };
        }
        case ActionTypes.FetchBenchmarkDataForReportPending: {
            const { reportId } = action;
            if (!state[reportId]) return state;
            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    benchmark: {
                        ...state[reportId].benchmark,
                        pending: true,
                        error: false,
                        noData: false
                    }
                }
            };
        }
        case ActionTypes.FetchBenchmarkDataForReportFulfilled: {
            const { reportId, data } = action;
            if (!state[reportId]) return state;
            let { benchmark } = state[reportId];
            let attribute = 'Gender';
            if (benchmark.attributes && benchmark.attributes.length > 0) {
                attribute = benchmark.attributes[0];
            }
            benchmark = {
                ...benchmark,
                pending: false,
                data: getBenchmarkData(attribute, data)
            };

            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    benchmark
                }
            };
        }
        case ActionTypes.FetchBenchmarkDataForReportRejected: {
            const { reportId } = action;
            if (!state[reportId]) return state;
            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    benchmark: {
                        ...state[reportId].benchmark,
                        pending: false,
                        error: true
                    }
                }
            };
        }
        case ActionTypes.FetchBenchmarkDataForReportNoData: {
            const { reportId } = action;
            if (!state[reportId]) return state;
            return {
                ...state,
                [reportId]: {
                    ...state[reportId],
                    benchmark: {
                        ...state[reportId].benchmark,
                        pending: false,
                        noData: true
                    }
                }
            };
        }

        default:
            return state;
    }
}

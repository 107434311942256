import { createContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import WithPermissions from '../../common/WithPermissions';
import AdminRoutes from '../AdminRoutes';
import { addUsers } from '../users/actions';
import { identitySync } from '../identity/reducer';
import { SSOItem } from '../integrations/models';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import MixPanel from '../../constants/MixPanel';
import { track } from '../../lib/segment';
import RbacActions from '../../constants/RbacActions';
import Stack from '../../common/Stack';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { adminTabs } from '../AdminTabs';
import hasPermission from '../../lib/hasPermission';
import { IAdminTab } from '../../roles/types';
import HeaderWrapper from '../../common/HeaderWrapper';

interface AdminHeaderProps {}

interface AdminHeaderContextProps {
    handleShowCreate: () => void;
    handleUserAction: (
        emails: string[],
        roleIds: string[],
        note: string,
        sendNotifications: boolean
    ) => void;
    handleClose: () => void;
    sso: SSOItem[];
    sync: any;
    addUsersPending: boolean;
    createRoleRoute: () => void;
    showCreateUser: boolean;
}

export const AdminHeaderContext = createContext({} as AdminHeaderContextProps);

function AdminHeader(props: AdminHeaderProps) {
    const history = useHistory();
    const dispatch = useAppDispatch();

    const { data: sso } = useAppSelector(state => state.admin.integrations.sso);
    const { addUsersPending } = useAppSelector(state => state.admin.users);
    const { allGroups } = useAppSelector(state => state.admin.groups);
    const { staticPermissions } = useAppSelector(state => state.auth);
    const [showCreateUser, setShowCreateUser] = useState(false);

    const filteredTabs = adminTabs.filter(tab => {
        if (tab.key === 'groups') {
            if (!allGroups || allGroups.length === 0) {
                return false;
            }
        }

        return hasPermission(staticPermissions, tab.actions);
    });

    function handleShowCreate() {
        setShowCreateUser(true);
        track(MixPanel.Events.AdminClickCreateUser);
    }
    function handleClose() {
        setShowCreateUser(false);
    }

    function handleUserAction(
        emails: string[],
        roleIds: string[],
        note: string,
        sendNotifications: boolean
    ) {
        dispatch(addUsers(emails, roleIds, note, sendNotifications))?.then(
            () => {
                track(MixPanel.Events.CreateUser);
                setShowCreateUser(false);
            }
        );
    }

    function sync() {
        dispatch(identitySync());
    }

    const navigate = (activeKey: string) =>
        history.replace(`/admin/${activeKey}`);

    const createRoleRoute = () => {
        history.push('/admin/create-role');
    };
    const isAdminTabSelected = (key: string) =>
        history.location.pathname === `/admin/${key}`;

    return (
        <WithPermissions
            actions={[
                RbacActions['Roles/View'],
                RbacActions['Users/View'],
                RbacActions['Integrations/View'],
                RbacActions['Users/Edit']
            ]}
        >
            <AdminHeaderContext.Provider
                value={{
                    handleShowCreate,
                    handleClose,
                    handleUserAction,
                    addUsersPending,
                    sso,
                    createRoleRoute,
                    showCreateUser,
                    sync
                }}
            >
                <HeaderWrapper>
                    <Stack flexDirection="row" gap=".8rem">
                        {filteredTabs.map((tab: IAdminTab) => (
                            <Button
                                selected={isAdminTabSelected(tab.key)}
                                onClick={() => navigate(tab.key)}
                                componentType={ButtonTypes.type.LIST_ITEM}
                                selectedStyle={
                                    ButtonTypes.selectedStyle.UNDERLINE
                                }
                                navigation
                                large={false}
                            >
                                {tab.name}
                            </Button>
                        ))}
                    </Stack>
                </HeaderWrapper>
                <AdminRoutes area="header" />
            </AdminHeaderContext.Provider>
        </WithPermissions>
    );
}

export default AdminHeader;

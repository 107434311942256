import { createSlice } from '@reduxjs/toolkit';
import {
    createStaticAudience,
    createDynamicAudience,
    updateDynamicAudience,
    updateStaticAudience,
    overwriteStaticAudience
} from './actions';
import { IAudienceEditState } from './types';
import { getSegmentsFromCriteria } from './utils';

const initialState: IAudienceEditState = {
    mode: 'create',
    selectedIndex: 0,
    isBeginning: true,
    isEnd: false,
    validated: false,
    modalOpen: false,
    segments: [],
    steps: [
        {
            label: 'Audience Type',
            id: 'audience-type',
            validated: false
        },
        {
            label: 'Audience Details',
            id: 'audience-details',
            validated: false
        }
    ]
};

function validate(state: IAudienceEditState) {
    if (!state.title) return false;
    if (state.mode === 'edit') {
        if (!state.audienceId) return false;
    }
    if (state.segments.length === 0) return true;

    return state.segments.every(
        (segment: IAudienceEditState['segments'][0]) =>
            !!segment.name && segment.values?.length > 0
    );
}

const slice = createSlice({
    name: 'audienceEdit',
    initialState,
    reducers: {
        setMode: (state, action) => {
            state.mode = action.payload;
        },
        setModalOpen: (state, action) => {
            state.modalOpen = action.payload;
        },
        setAudienceType: (state, action) => {
            state.type = action.payload;
        },
        setTitle: (state, action) => {
            state.title = action.payload;
            state.validated = validate(state);
        },
        setDescription: (state, action) => {
            state.description = action.payload;
        },
        setFilepath: (state, action) => {
            state.filePath = action.payload;
        },
        nextStep: state => {
            const nextIndex = Math.min(
                state.selectedIndex + 1,
                state.steps.length - 1
            );
            state.selectedIndex = nextIndex;
            state.isBeginning = state.selectedIndex === 0;
            state.isEnd = state.selectedIndex === state.steps.length - 1;
        },
        previousStep: state => {
            state.selectedIndex = Math.max(state.selectedIndex - 1, 0);
            state.isBeginning = state.selectedIndex === 0;
            state.isEnd = state.selectedIndex === state.steps.length - 1;
        },
        setSegments: (state, action) => {
            state.segments = action.payload;
            state.validated = validate(state);
        },
        setEditModeInitialState: (state, action) => {
            if (action.payload.type === 'DYNAMIC') {
                const newState: IAudienceEditState = {
                    ...initialState,
                    audienceId: action.payload.audienceId,
                    title: action.payload.title,
                    description: action.payload.description,
                    type: action.payload.type,
                    segments: getSegmentsFromCriteria(action.payload.criteria),
                    mode: 'edit',
                    modalOpen: true,
                    selectedIndex: 1,
                    isBeginning: false,
                    isEnd: true
                };

                return newState;
            } else {
                state.audienceId = action.payload.audienceId;
                state.title = action.payload.title;
                state.description = action.payload.description;
                state.type = action.payload.type;
                state.mode = 'edit';
                state.modalOpen = true;
                state.selectedIndex = 1;
                state.filePath = action.payload.filePath;
            }
        },
        reset: () => initialState
    },
    extraReducers: builder => {
        builder.addCase(createDynamicAudience.pending, state => {
            state.pending = true;
        });
        builder.addCase(createDynamicAudience.fulfilled, state => {
            return initialState;
        });
        builder.addCase(createDynamicAudience.rejected, (state, action) => {
            state.pending = false;
            state.error = action.error.message;
        });
        builder.addCase(updateDynamicAudience.pending, state => {
            state.pending = true;
        });
        builder.addCase(updateDynamicAudience.fulfilled, state => {
            return initialState;
        });
        builder.addCase(updateDynamicAudience.rejected, (state, action) => {
            state.pending = false;
            state.error = action.error.message;
        });
        builder.addCase(createStaticAudience.pending, state => {
            state.pending = true;
        });
        builder.addCase(createStaticAudience.fulfilled, state => {
            return initialState;
        });
        builder.addCase(createStaticAudience.rejected, (state, action) => {
            state.pending = false;
            state.error = action.error.message;
        });
        builder.addCase(updateStaticAudience.pending, state => {
            state.pending = true;
        });
        builder.addCase(updateStaticAudience.fulfilled, state => {
            return initialState;
        });
        builder.addCase(updateStaticAudience.rejected, (state, action) => {
            state.pending = false;
            state.error = action.error.message;
        });
        builder.addCase(overwriteStaticAudience.pending, state => {
            state.pending = true;
        });
        builder.addCase(overwriteStaticAudience.fulfilled, state => {
            return initialState;
        });
        builder.addCase(overwriteStaticAudience.rejected, (state, action) => {
            state.pending = false;
            state.error = action.error.message;
        });
    }
});

export const {
    setMode,
    setModalOpen,
    setAudienceType,
    setTitle,
    setDescription,
    nextStep,
    previousStep,
    setSegments,
    setEditModeInitialState,
    reset,
    setFilepath
} = slice.actions;
export default slice.reducer;

import cn from '../../lib/cn';
import AiAvatar from '../../common/AiAvatar';
import UserAvatar from '../../icons/UserAvatar';
import { MessageTypes } from './types';
import { useEffect, useRef } from 'react';
import gsap from 'gsap';

export default function MessageBubble({
    children,
    showAvatar,
    type,
    pending,
    animate = true,
    delay
}: {
    children: any;
    showAvatar?: boolean;
    animate?: boolean;
    type: MessageTypes;
    pending?: boolean;
    delay?: number;
}) {
    const scope = useRef<any>();
    useEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap.timeline({});

            const bubble = scope.current.querySelector('.animate-bubble');
            const text = scope.current.querySelector('.animate-text');

            if (animate) {
                tl.fromTo(
                    bubble,
                    {
                        autoAlpha: 0,
                        scaleX: 0,
                        transformOrigin: 'top left'
                    },
                    {
                        autoAlpha: 1,
                        scaleX: 1,
                        duration: 0.4,
                        ease: 'power3.out'
                    },
                    delay || 0
                );

                tl.to(
                    text,
                    {
                        autoAlpha: 1,
                        duration: 1,
                        ease: 'power1.out'
                    },
                    (delay || 0) + 0.2
                );
            }
        });

        return () => {
            ctx.revert();
        };
    }, [animate, delay]);

    return (
        <div
            ref={scope}
            className={cn(
                'relative',
                'text-ui-75 text-[1.6rem]',
                type === 'actions'
                    ? 'mt-[3.2rem] w-avail'
                    : type === 'user'
                    ? 'rounded-[1rem] p-[1.6rem] ml-[2.4rem] mr-[1.6rem] w-fit self-end my-[3.2rem] '
                    : 'rounded-[1rem] p-[1.6rem] ml-[2.4rem] w-fit self-start',
                pending && 'w-avail'
            )}
        >
            <div
                className={cn(
                    'animate-bubble',
                    animate && 'invisible',
                    'absolute inset-0 h-full',
                    pending ? 'bg-shade-h3' : 'bg-shade-h4',
                    type === 'actions'
                        ? ' bg-shade-h3 mt-[3.2rem] w-avail'
                        : type === 'user'
                        ? 'rounded-[1rem] bg-shade-h1 w-fit self-end'
                        : 'rounded-[1rem] w-avail '
                )}
            />

            {showAvatar && (
                <div
                    className={cn(
                        'absolute  top-[-1.2rem]',
                        type === 'assistant'
                            ? 'left-[-1.2rem]'
                            : 'right-[-1.2rem]'
                    )}
                >
                    {type === 'assistant' ? (
                        <AiAvatar size={24} />
                    ) : (
                        <UserAvatar className={cn('text-graph-10')} />
                    )}
                </div>
            )}
            {pending ? (
                <div className={cn('z-10 relative')}>
                    <LoadingSkeleton />
                </div>
            ) : (
                <div
                    className={cn(
                        'z-10 relative animate-text',
                        animate && 'invisible'
                    )}
                >
                    {children}
                </div>
            )}
        </div>
    );
}

const LoadingSkeleton = () => {
    return (
        <div className={cn('flex flex-col gap-[.8rem] items-start w-full')}>
            <SkeletonLine className="w-full" />
            <SkeletonLine className="w-[60%]" delay={150} />
            <SkeletonLine className="w-[80%]" delay={300} />
        </div>
    );
};

const SkeletonLine = ({
    className,
    delay
}: {
    className?: string;
    delay?: number;
}) => {
    return (
        <div
            className={cn(
                'h-[10px] rounded-[.4rem] origin-left animate-skeleton scale-x-0',
                className
            )}
            style={{
                backgroundImage:
                    'linear-gradient(270deg,  var(--color-ui-10) 0%, var(--color-ui-10) 30%, var(--color-ui-30) 50%,  var(--color-ui-10) 70%,  var(--color-ui-10) 100%)',
                backgroundSize: '200% 100%',
                animationDelay: `${delay || 0}ms`
            }}
        />
    );
};

import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 49 49"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                fill="var(--color-shade-h3)"
                d="M.568 4.074a4 4 0 014-4h40a4 4 0 014 4v21.044h-48V4.074z"
            />
            <path
                fill="var(--color-shade-h1)"
                d="M.568 25.117h48v18.957a4 4 0 01-4 4h-40a4 4 0 01-4-4V25.117z"
            />
            <circle fill="var(--color-ui-50)" cx={42.927} cy={16.197} r={3} />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M.568 39.257l15.548-15.549 10.358 10.358 17.16-17.162-1.414-1.414-15.746 15.747L16.117 20.88.567 36.428v2.829z"
            />
        </svg>
    );
}

export default SvgComponent;

import { useState } from 'react';

import Info from '../icons/Info';
import WithPermissions from '../common/WithPermissions';
import RbacActions from '../constants/RbacActions';
import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';
import HeaderWrapper from '../common/HeaderWrapper';
import InfoModal from './InfoModal';

export default function CollectHeader() {
    const [isOpen, setOpen] = useState(false);

    function handleOpen() {
        setOpen(!isOpen);
    }

    function handleClose() {
        setOpen(false);
    }

    return (
        <WithPermissions actions={[RbacActions['Collect/View']]}>
            <HeaderWrapper>
                <span className="font-title">
                    Collect
                    <InfoModal isOpen={isOpen} onClose={handleClose} />
                </span>
                <Button
                    componentType={ButtonTypes.type.SECONDARY}
                    onClick={handleOpen}
                >
                    <Info
                        className="btn-icon collect__info-icon"
                        width={24}
                        height={24}
                    />
                    Learn more about Collect
                </Button>
            </HeaderWrapper>
        </WithPermissions>
    );
}

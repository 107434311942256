function SvgComponent(props: any) {
    return (
        <svg
            width="49"
            height="48"
            viewBox="0 0 49 48"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x="0.0683594"
                width="48"
                height="48"
                rx="4"
                fill="var(--color-shade-h3)"
            />
            <rect
                x="16"
                y="13"
                width="18"
                height="12"
                fill="var(--color-ui-75)"
            />
            <rect
                x="16.5"
                y="35.5"
                width="17"
                height="1"
                stroke="var(--color-ui-75)"
            />
            <rect
                x="16"
                y="27"
                width="18"
                height="6"
                fill="var(--color-ui-75)"
            />
        </svg>
    );
}

export default SvgComponent;

import React, { useRef, useEffect, useReducer } from 'react';
import { connect } from 'react-redux';
import { IFunnelChartProps } from './types';
import useFunnelData from './useFunnelData';
import Graph from './Graph';

export const FunnelChartContext = React.createContext<any>({});

interface IState {
    chartWidth: number;
    chartHeight: number;
    gridRowHeight: number;
    labelColumnWidth: number;
    multiSegment: boolean;
}

interface IAction {
    chartWidth?: number;
    chartHeight?: number;
    gridRowHeight?: number;
    labelColumnWidth?: number;
    multiSegment?: boolean;
}

const initialState: IState = {
    chartWidth: 0,
    chartHeight: 0,
    gridRowHeight: 0,
    labelColumnWidth: 60,
    multiSegment: false
};

const FunnelChart = (props: IFunnelChartProps) => {
    const ref = useRef<HTMLDivElement>(null);
    const [
        {
            chartWidth,
            chartHeight,
            gridRowHeight,
            labelColumnWidth,
            multiSegment
        },
        setState
    ] = useReducer((state: IState, action: IAction): IState => {
        return {
            ...state,
            ...action
        };
    }, initialState);
    const { height, status } = props;

    const funnelData = useFunnelData({
        ...props,
        chartWidth,
        chartHeight
    });

    useEffect(() => {
        if (!ref.current) return;

        const resizeObserver = new ResizeObserver(entries => {
            const { width, height } = entries[0].contentRect;
            setState({
                chartWidth: width,
                chartHeight: height,
                gridRowHeight: Math.max((height - 16) / funnelData.length, 68)
            });
        });

        resizeObserver.observe(ref.current);

        return () => resizeObserver.disconnect();
    }, [ref.current]);

    useEffect(() => {
        setState({
            multiSegment: funnelData.some(
                (item: any) => item.segments.length > 1
            )
        });
    }, [funnelData]);

    return (
        <FunnelChartContext.Provider
            value={{
                ...props,
                funnelData,
                gridRowHeight,
                chartWidth,
                chartHeight,
                labelColumnWidth,
                multiSegment
            }}
        >
            <div
                className="funnel"
                ref={ref}
                style={
                    {
                        '--height': height
                    } as React.CSSProperties
                }
                data-activated={status !== 'idle'}
            >
                <Graph>
                    <Graph.Rows>
                        {funnelData.map((stage, stageIndex) => (
                            <Graph.Row key={stageIndex}>
                                <Graph.StageTitle>
                                    {stage.name}
                                </Graph.StageTitle>
                                <Graph.Bar
                                    key={stageIndex}
                                    width={stage.barWidth}
                                    showEmptyState={stage.totalCount === 0}
                                >
                                    {stage.segments.map(
                                        (segment, segmentIndex) => (
                                            <Graph.Segment
                                                key={segmentIndex}
                                                segment={segment}
                                                labelLeft={
                                                    segmentIndex ===
                                                    stage.segments.length - 1
                                                }
                                                totalCount={stage.totalCount}
                                            />
                                        )
                                    )}
                                    <Graph.BarLine />
                                </Graph.Bar>
                            </Graph.Row>
                        ))}
                    </Graph.Rows>
                    <Graph.RightColumn>
                        <Graph.Rows>
                            {funnelData.map((stage, stageIndex) => (
                                <Graph.Row key={stageIndex}>
                                    <Graph.RightLabel
                                        count={stage.segments[0].count}
                                        rate={stage.segments[0].rate}
                                    />
                                </Graph.Row>
                            ))}
                        </Graph.Rows>
                    </Graph.RightColumn>
                </Graph>
            </div>
        </FunnelChartContext.Provider>
    );
};

function mapState(state: any, ownProps: any) {
    switch (ownProps.mode) {
        case 'explore': {
            return {
                ...state.explore.chart,
                status: ownProps.status,
                selection: ownProps.selection,
                hoveredItem: ownProps.hoveredItem
            };
        }
        case 'dashboard-view': {
            return {
                ...state.reports[ownProps.reportId],
                status: ownProps.status,
                selection: ownProps.selection,
                hoveredItem: ownProps.hoveredItem
            };
        }
        case 'dashboard-edit': {
            return {
                ...state.reports[ownProps.reportId],
                status: ownProps.status,
                selection: ownProps.selection,
                hoveredItem: ownProps.hoveredItem
            };
        }
        case 'editor': {
            return {
                ...state.editor.chart,
                status: ownProps.status,
                selection: ownProps.selection,
                hoveredItem: ownProps.hoveredItem
            };
        }
    }
}

const dispatchProps = {};

const connector = connect(mapState, dispatchProps);

export default connector(FunnelChart);

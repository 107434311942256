import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import { saveReport } from './actions';

function SaveButton({
    disabled,
    title,
    chartType,
    yAxisType,
    indexType,
    length,
    saveReport,
    pending,
    options
}) {
    return (
        <Button
            type={ButtonTypes.type.PRIMARY}
            disabled={disabled}
            loading={pending}
            onClick={saveReport.bind(
                null,
                title,
                chartType,
                yAxisType,
                indexType,
                length,
                options
            )}
        >
            <span style={{ opacity: pending ? 0 : 1 }}>Save to dashboard</span>
        </Button>
    );
}

SaveButton.propTypes = {
    disabled: PropTypes.bool,
    title: PropTypes.string,
    chartType: PropTypes.string,
    yAxisType: PropTypes.string,
    indexType: PropTypes.string,
    saveReport: PropTypes.func,
    pending: PropTypes.bool,
    length: PropTypes.number,
    options: PropTypes.object
};

function mapState(state, ownProps) {
    const { generated } = state.editor.chart;
    return {
        pending: state.editor.save.pending,
        disabled: !generated || !ownProps.title
    };
}

const dispatchProps = {
    saveReport
};

export default connect(mapState, dispatchProps)(SaveButton);

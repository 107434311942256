import { interpolate, interpolateTransformSvg } from 'd3-interpolate';

export default function getInterpolator(
    begValue: any,
    endValue: any,
    attr: any
) {
    if (attr === 'transform') {
        return interpolateTransformSvg(begValue, endValue);
    }
    return interpolate(begValue, endValue);
}

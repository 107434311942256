import { Fragment, useEffect, useRef, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import Button from '../../../../common/Button';
import Grid from '../../../../common/Grid';
import RadioButton from '../../../../common/RadioButton';
import Stack from '../../../../common/Stack';
import WithLoadingStates from '../../../../common/WithLoadingStates';
import ButtonTypes from '../../../../constants/ButtonTypes';
import Close from '../../../../icons/Close';
import DropdownArrow from '../../../../icons/DropdownArrow';
import Info from '../../../../icons/Info';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { getAudiences } from '../../../audiences/list/actions';
import { Audience as AudienceType } from '../../../audiences/types';
import { cadenceOptions, distributionOptions } from '../../constants';
import {
    setAudience,
    setCadence,
    setCadenceNDays,
    setDistributionType,
    setEndDate
} from '../slice';
import { CadenceRunType } from '../types';
import ChoiceItem from './ChoiceItem';
import DatePicker from './DatePicker';
import Handbook from './Handbook';
import IllustrationCard from './IllustrationCard';
import InfoModal from './InfoModal';
import InputField from './InputField';

const formatter = new Intl.DateTimeFormat('en-US');

const getValidAudiences = (state: any) => {
    const audiences = state.audiences.list;
    return {
        pending: audiences.audiencesPending,
        loaded: audiences.audiencesLoaded,
        error: audiences.audiencesError,
        data: audiences.data?.filter(
            (audience: AudienceType) => audience.syncStatus.status !== 'FAILED'
        )
    };
};

const Audience = () => {
    const daysRef = useRef<HTMLInputElement>(null);
    const [cadenceDropdownOpen, setCadenceDropdownOpen] = useState(false);
    const [endDateDropdownOpen, setEndDateDropdownOpen] = useState(false);
    const [handbookOpen, setHandbookOpen] = useState(false);
    const [cadenceInfoOpen, setCadenceInfoOpen] = useState(false);
    const [daysInfoOpen, setDaysInfoOpen] = useState(false);
    const [endDateInfoOpen, setEndDateInfoOpen] = useState(false);
    const [hoverIndex, setHoverIndex] = useState(-1);
    const [audienceHoverIndex, setAudienceHoverIndex] = useState(-1);
    const {
        distributionType,
        privacy,
        audienceId,
        cadenceRunType,
        cadenceRunEachNDays,
        endDate,
        isPublished
    } = useAppSelector(state => state.snapshotSurveys.edit);
    const {
        data: audiences,
        pending: audiencesPending,
        loaded: audiencesLoaded,
        error: audiencesError
    } = useAppSelector(getValidAudiences);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (!audiencesLoaded && !audiencesPending && !audiencesError) {
            dispatch(getAudiences());
        }
    }, [distributionType]);

    useEffect(() => {
        if (cadenceRunType === CadenceRunType.n_days) {
            daysRef.current?.focus();
        }
    }, [cadenceRunType]);

    function onCadenceToggle() {
        const isOpened = !cadenceDropdownOpen;
        setCadenceDropdownOpen(isOpened);
        if (isOpened) {
            setEndDateDropdownOpen(false);
        }
    }

    function onCadenceSelect(option: any) {
        dispatch(setCadence(option.id));
        setCadenceDropdownOpen(false);
    }

    function onEndDateToggle() {
        const isOpened = !endDateDropdownOpen;
        setEndDateDropdownOpen(isOpened);
        if (isOpened) {
            setCadenceDropdownOpen(false);
        }
    }

    function onEndDateSelect(date: Date) {
        dispatch(setEndDate(date.toISOString()));
    }

    return (
        <Stack
            gap="3.2rem"
            style={{
                minHeight: '50rem'
            }}
        >
            <span style={{ color: 'var(--color-ui-75)' }}>
                Choose a method to distribute the campaign.
            </span>
            <Grid base={2} gap="1.6rem">
                {distributionOptions
                    .filter((o: any) => o.dependencies.includes(privacy))
                    .map((option, index) => (
                        <Grid.Item key={option.id}>
                            <ChoiceItem
                                title={option.title}
                                description={option.description}
                                selected={option.id === distributionType}
                                iconConfig={{
                                    ...option.iconConfig
                                }}
                                selectionExists={!!distributionType}
                                defaultState={!distributionType}
                                onClick={() =>
                                    dispatch(setDistributionType(option.id))
                                }
                                index={index}
                                hoverIndex={hoverIndex}
                                setHoverIndex={setHoverIndex}
                                largeTitle={true}
                                readOnly={isPublished}
                            />
                        </Grid.Item>
                    ))}
            </Grid>
            <Stack gap="4.8rem">
                <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap="1.6rem"
                    style={{
                        padding: '1px'
                    }}
                >
                    {distributionType === 'email' && (
                        <Stack gap=".8rem">
                            <div
                                className="font-micro-text"
                                style={{
                                    paddingLeft: '1.6rem',
                                    color: 'var(--color-ui-50)'
                                }}
                            >
                                Cadence
                                <sup>
                                    <Info
                                        className="btn-icon"
                                        width={20}
                                        height={20}
                                        onClick={() => setCadenceInfoOpen(true)}
                                    />
                                </sup>
                                <InfoModal
                                    isOpen={cadenceInfoOpen}
                                    onClose={() => setCadenceInfoOpen(false)}
                                >
                                    <div>
                                        Select "One Time" for a single campaign
                                        send or choose a frequency interval to
                                        automate recurring campaign delivery.
                                    </div>
                                </InfoModal>
                            </div>
                            <Dropdown
                                isOpen={cadenceDropdownOpen}
                                onClick={onCadenceToggle}
                                placeholder="Select cadence"
                                onMouseLeaveDropdown={() =>
                                    setCadenceDropdownOpen(false)
                                }
                                label={
                                    cadenceOptions.find(
                                        (o: any) => o.id === cadenceRunType
                                    )?.label || ''
                                }
                            >
                                <div className="filter-dropdown__list">
                                    <ul>
                                        {cadenceOptions?.map(
                                            (option: any, index: number) => (
                                                <li key={index}>
                                                    <RadioButton
                                                        onClick={() =>
                                                            onCadenceSelect(
                                                                option
                                                            )
                                                        }
                                                        checked={
                                                            option.id ===
                                                            cadenceRunType
                                                        }
                                                    >
                                                        {option.label}
                                                    </RadioButton>
                                                </li>
                                            )
                                        )}
                                    </ul>
                                </div>
                            </Dropdown>
                        </Stack>
                    )}
                    {!distributionType ? (
                        <IllustrationCard
                            onClick={() => setHandbookOpen(true)}
                        />
                    ) : (
                        <Fragment>
                            {distributionType === 'email' &&
                                cadenceRunType === CadenceRunType.n_days && (
                                    <Stack gap=".8rem">
                                        <div
                                            className="font-micro-text"
                                            style={{
                                                paddingLeft: '1.6rem',
                                                color: 'var(--color-ui-50)'
                                            }}
                                        >
                                            Days
                                            <sup>
                                                <Info
                                                    className="btn-icon"
                                                    width={20}
                                                    height={20}
                                                    onClick={() =>
                                                        setDaysInfoOpen(true)
                                                    }
                                                />
                                            </sup>
                                            <InfoModal
                                                isOpen={daysInfoOpen}
                                                onClose={() =>
                                                    setDaysInfoOpen(false)
                                                }
                                            >
                                                <div>
                                                    Enter the number of days
                                                    upon which the campaign
                                                    should be sent on a
                                                    recurring basis.
                                                </div>
                                            </InfoModal>
                                        </div>

                                        <InputField
                                            ref={daysRef}
                                            type="number"
                                            value={cadenceRunEachNDays || ''}
                                            onChange={(e: any) =>
                                                dispatch(
                                                    setCadenceNDays(
                                                        e.target.value
                                                    )
                                                )
                                            }
                                            style={{
                                                width: '4rem'
                                            }}
                                        />
                                    </Stack>
                                )}
                            <Stack gap=".8rem">
                                <div
                                    className="font-micro-text"
                                    style={{
                                        paddingLeft: '1.6rem',
                                        color: 'var(--color-ui-50)'
                                    }}
                                >
                                    End date
                                    <sup>
                                        <Info
                                            className="btn-icon"
                                            width={20}
                                            height={20}
                                            onClick={() =>
                                                setEndDateInfoOpen(true)
                                            }
                                        />
                                    </sup>
                                    <InfoModal
                                        isOpen={endDateInfoOpen}
                                        onClose={() =>
                                            setEndDateInfoOpen(false)
                                        }
                                    >
                                        <div>
                                            This option determines the
                                            campaign's end date. No responses
                                            will be captured after this date. If
                                            you want an "evergreen" campaign
                                            where employees can revisit and
                                            update their identification, simply
                                            set the end date to a distant point
                                            in the future.
                                        </div>
                                    </InfoModal>
                                </div>
                                <DatePicker
                                    onClick={onEndDateToggle}
                                    label={
                                        endDate
                                            ? formatter.format(
                                                  new Date(endDate)
                                              )
                                            : ''
                                    }
                                    placeholder="Select end date"
                                    isOpen={endDateDropdownOpen}
                                    date={
                                        endDate ? new Date(endDate) : new Date()
                                    }
                                    onSelect={onEndDateSelect}
                                    onMouseLeaveDropdown={() =>
                                        setEndDateDropdownOpen(false)
                                    }
                                />
                            </Stack>
                        </Fragment>
                    )}
                </Stack>

                {distributionType === 'email' && (
                    <WithLoadingStates
                        isEmpty={audiencesLoaded && audiences.length === 0}
                        isLoading={audiencesPending}
                        isError={!!audiencesError}
                    >
                        <Grid base={3} gap="1.6rem">
                            {audiences?.map((audience: any, index: number) => (
                                <Grid.Item key={audience.audienceId}>
                                    <ChoiceItem
                                        title={audience.title}
                                        description={audience.description}
                                        selected={
                                            audience.audienceId === audienceId
                                        }
                                        selectionExists={!!audienceId}
                                        onClick={() =>
                                            dispatch(
                                                setAudience(audience.audienceId)
                                            )
                                        }
                                        index={index}
                                        hoverIndex={audienceHoverIndex}
                                        setHoverIndex={setAudienceHoverIndex}
                                        readOnly={isPublished}
                                    />
                                </Grid.Item>
                            ))}
                        </Grid>
                    </WithLoadingStates>
                )}
            </Stack>
            <InfoModal
                onClose={() => setHandbookOpen(false)}
                isOpen={handbookOpen}
                width={744}
            >
                <Handbook
                    stage="audience"
                    onClose={() => setHandbookOpen(false)}
                />
            </InfoModal>
        </Stack>
    );
};

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
    onClick: (option: any) => void;
    placeholder?: string;
    buttonLarge?: boolean;
    allowCancel?: boolean;
    disabled?: boolean;
    isOpen?: boolean;
    label?: string;
    children?: React.ReactNode;
    onClearSelection?: () => void;
    onMouseLeaveDropdown?: () => void;
    limitHeight?: number;
    alignDropdownRight?: boolean;
}

const Dropdown = ({
    placeholder,
    allowCancel,
    disabled,
    isOpen,
    label,
    buttonLarge,
    children,
    limitHeight,
    alignDropdownRight,
    onClick,
    onClearSelection,
    onMouseLeaveDropdown,
    ...rest
}: DropdownProps) => {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        function handleClickOutside(event: any) {
            event.stopPropagation();
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(event.target)
            ) {
                onMouseLeaveDropdown?.();
            }
        }

        document.addEventListener('mousedown', handleClickOutside);
        document.addEventListener('keydown', handleClickOutside, false);
        return () => {
            document.removeEventListener('keydown', handleClickOutside, false);
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [dropdownRef]);

    return (
        <div className="filter-dropdown" {...rest}>
            <Button
                type="button"
                componentType={ButtonTypes.type.SECONDARY}
                large={buttonLarge}
                onClick={onClick}
                activated={isOpen}
                disabled={disabled}
                selected={!!label}
            >
                <span className="long-text">{label || placeholder}</span>
                <div style={{ width: 4 }} />
                {allowCancel && label ? (
                    <Close
                        width={12}
                        height={12}
                        onClick={(e: any) => {
                            e.stopPropagation();
                            if (!disabled) {
                                onClearSelection?.();
                            }
                        }}
                    />
                ) : (
                    <DropdownArrow width={18} height={18} />
                )}
            </Button>
            <CSSTransition
                in={isOpen}
                timeout={300}
                mountOnEnter
                unmountOnExit
                classNames="fade-scale"
            >
                <div
                    className="filter-dropdown__menu"
                    data-test={`dropdown-menu-${placeholder}`}
                    onMouseLeave={onMouseLeaveDropdown}
                    ref={dropdownRef}
                    style={{
                        maxHeight: limitHeight ?? '50vh',
                        overflowY: 'auto',
                        ...(alignDropdownRight && {
                            right: '-3.2rem'
                        })
                    }}
                >
                    {children}
                </div>
            </CSSTransition>
        </div>
    );
};

export default Audience;

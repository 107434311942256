import Button from '../../../../common/Button';
import Stack from '../../../../common/Stack';
import ButtonTypes from '../../../../constants/ButtonTypes';
import AnonymousPerson from '../../../../icons/AnonymousPerson';
import Chain from '../../../../icons/Chain';
import Database from '../../../../icons/Database';
import Document from '../../../../icons/Document';
import Mail from '../../../../icons/Mail';
import Shield from '../../../../icons/Shield';
import { privacyOptions } from '../../constants';
import { IconConfig } from '../types';

type HandbookStage = 'privacy' | 'audience' | 'storage';

interface HandbookProps {
    stage: HandbookStage;
    onClose?: () => void;
}

const Handbook = ({ stage, onClose }: HandbookProps) => {
    const stageTitle =
        stage === 'privacy'
            ? 'Privacy options'
            : stage === 'audience'
            ? 'Distribution & audience options'
            : 'Data storage options';

    const handleOnClose = () => {
        if (onClose) {
            onClose();
        }
    };
    const handleSupportArticleClick = () => {
        let url = '';
        switch (stage) {
            case 'storage':
                url =
                    'https://support.itsdandi.com/hc/en-us/articles/18356057374999-Data-Privacy';
                break;
            case 'audience':
                url =
                    'https://support.itsdandi.com/hc/en-us/articles/18356000076183-Distribution-Methods';
                break;
            default:
                url =
                    'https://support.itsdandi.com/hc/en-us/articles/18356057374999-Data-Privacy';
        }

        window.open(url, '_blank');
    };
    return (
        <Stack flexDirection="column">
            <span
                className="info-modal__header"
                style={{ marginBottom: '2.8rem' }}
            >
                Self-ID handbook - {stageTitle}
            </span>
            <Stack flexDirection="column">
                {stage === 'privacy' && <PrivacyHandbook />}
                {stage === 'audience' && <AudienceHandbook />}
                {stage === 'storage' && <StorageHandbook />}
            </Stack>
            <HandbookFooter
                onClose={handleOnClose}
                onSupportArticleClick={handleSupportArticleClick}
            />
        </Stack>
    );
};

const PrivacyHandbook = () => {
    return (
        <>
            <HandbookItem
                iconConfig={{
                    icon: AnonymousPerson,
                    color: '#FF9133',
                    backgroundColor: '#FFE99A',
                    secondaryColor: '#FFE99A',
                    width: 56,
                    height: 56
                }}
            >
                <h2 className="info-modal__title">{privacyOptions[0].label}</h2>
                <div className="info-modal__content-col">
                    <p className="info-modal__content__large">
                        Dandi will not collect any personally identifiable
                        information, meaning that responses cannot be linked or
                        associated with your existing HRIS data. Select this
                        option if you are gathering data from in a region where
                        collecting PII is not allowed or if you intend to run a
                        campaign where complete anonymity is desired.
                    </p>
                </div>
            </HandbookItem>
            <HandbookItem
                iconConfig={{
                    icon: Shield,
                    color: '#C050F5',
                    backgroundColor: '#F9D2FF',
                    width: 56,
                    height: 56
                }}
            >
                <h2 className="info-modal__title">
                    {privacyOptions[1].label}{' '}
                    <span
                        className="info-modal__title"
                        style={{ opacity: 0.5 }}
                    >
                        (Recommended)
                    </span>
                </h2>
                <div className="info-modal__content-col">
                    <p className="info-modal__content__large">
                        Dandi stores individual responses using the employee ID,
                        allowing responses to be matched with employees in your
                        HRIS data without any PII being disclosed to the
                        employer. Select this option if you want to merge
                        responses from your Self-ID campaign with your HRIS data
                        for deeper insights.
                    </p>
                </div>
            </HandbookItem>
            <HandbookItem
                iconConfig={{
                    icon: Document,
                    color: '#20B049',
                    backgroundColor: '#BCFF9C',
                    width: 56,
                    height: 56
                }}
                removeDivider
            >
                <h2 className="info-modal__title">{privacyOptions[2].label}</h2>
                <div className="info-modal__content-col">
                    <p className="info-modal__content__large">
                        Dandi collects PII and additionally permits you to
                        export individual employee responses along with their
                        respective employee IDs. Use this option when there are
                        no privacy constraints concerning accessing campaign
                        responses by employee ID. It's a suitable method for
                        transferring data to your HR platform.
                    </p>
                </div>
            </HandbookItem>
        </>
    );
};
const AudienceHandbook = () => {
    return (
        <>
            <HandbookItem
                iconConfig={{
                    icon: Mail,
                    color: '#335AFF',
                    backgroundColor: '#A4F0F4',
                    width: 56,
                    height: 56
                }}
            >
                <h2 className="info-modal__title">Email</h2>
                <div className="info-modal__content-col">
                    <p className="info-modal__content__large">
                        Dandi will deliver the campaign to your chosen audience
                        via email. When choosing the email option, you must also
                        choose the following:
                    </p>
                    <span className="info-modal__content__subheading">
                        Cadence
                    </span>
                    <p
                        className="info-modal__content__large"
                        style={{ marginTop: 0 }}
                    >
                        Choose whether your campaign is one-time or recurring.
                        If choosing a recurring campaign, you’ll also set the
                        frequency (i.e., Monthly, etc.)
                    </p>
                    <span className="info-modal__content__subheading">
                        Audience
                    </span>
                    <p
                        className="info-modal__content__large"
                        style={{ marginTop: 0 }}
                    >
                        Set the target audience for your campaign. You can
                        create dynamic audiences via HRIS integration, or static
                        audience via CSV upload.
                    </p>
                </div>
            </HandbookItem>
            <HandbookItem
                iconConfig={{
                    icon: Chain,
                    color: '#B0197D',
                    backgroundColor: '#FD8ED7',
                    width: 58,
                    height: 58
                }}
                removeDivider
            >
                <h2 className="info-modal__title">Open link</h2>
                <div className="info-modal__content-col">
                    <p className="info-modal__content__large">
                        Dandi creates a secure link to your campaign, but does
                        not handle distribution. Note that the cadence, end
                        date, and audience options available for email are not
                        available with open link.
                    </p>
                </div>
            </HandbookItem>
        </>
    );
};
const StorageHandbook = () => {
    return (
        <>
            <HandbookItem
                iconConfig={{
                    icon: Database,
                    width: 56,
                    height: 56,
                    color: '#0D7474',
                    backgroundColor: '#86EAC6'
                }}
                removeDivider
            >
                <h2 className="info-modal__title">Data storage</h2>
                <div className="info-modal__content-col">
                    <p className="info-modal__content__large">
                        Dandi is inherently GDPR compliant. To accommodate data
                        governance preferences, we provide you with the
                        flexibility to select the location for storing and
                        processing the collected data.
                    </p>
                    <p
                        className="info-modal__content__large"
                        style={{ marginTop: 0 }}
                    >
                        If you prefer to store and process the data in the same
                        location where it was collected, you can choose the data
                        storage region matching your audience. Importantly, your
                        choice of storage region is independent of the
                        collection region, allowing you to opt for any storage
                        region that suits your needs.
                    </p>
                    <span>US (Iowa)</span>
                    <p
                        className="info-modal__content__large"
                        style={{ marginTop: 0 }}
                    >
                        All data collected will be stored and processed in Iowa
                        in the US.
                    </p>
                    <span>UK (London)</span>
                    <p
                        className="info-modal__content__large"
                        style={{ marginTop: 0 }}
                    >
                        All data collected will be stored and processed in
                        London in the UK.
                    </p>
                    <span>EU (Frankfurt)</span>
                    <p
                        className="info-modal__content__large"
                        style={{ marginTop: 0 }}
                    >
                        All data collected will be stored and processed in
                        Frankfurt in the EU.
                    </p>
                    <p
                        className="info-modal__content__large"
                        style={{ marginTop: 0 }}
                    >
                        Dandi does not currently offer a data storage/processing
                        option located in APAC. However, there are no
                        regulations against collecting data in APAC and storing
                        it somewhere else.
                    </p>
                </div>
            </HandbookItem>
        </>
    );
};

interface HandbookItemProps extends React.HTMLAttributes<HTMLDivElement> {
    iconConfig: IconConfig;
    children: React.ReactNode;
    removeDivider?: boolean;
}

const HandbookItem = ({
    iconConfig,
    children,
    removeDivider,
    ...rest
}: HandbookItemProps) => {
    const Icon = iconConfig?.icon;
    return (
        <>
            <Stack
                flexDirection="row"
                gap="2rem"
                alignItems="flex-start"
                style={{ marginBottom: '2rem' }}
                {...rest}
            >
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor:
                            iconConfig.backgroundColor || 'transparent',
                        borderRadius: '.8rem',
                        aspectRatio: '1/1',
                        minWidth: 86
                    }}
                >
                    <Icon
                        fill={iconConfig.color}
                        secondaryFill={iconConfig.secondaryColor}
                        width={iconConfig.width}
                        height={iconConfig.height}
                    />
                </div>
                <Stack flexDirection="column">{children}</Stack>
            </Stack>
            {!removeDivider && (
                <div
                    style={{
                        height: 1,
                        width: '100%',
                        backgroundColor: 'var(--color-ui-10)',
                        marginBottom: '2rem'
                    }}
                ></div>
            )}
        </>
    );
};

interface HandbookFooterProps extends React.HTMLAttributes<HTMLDivElement> {
    onClose: () => void;
    onSupportArticleClick?: () => void;
}

const HandbookFooter = ({
    onClose,
    onSupportArticleClick
}: HandbookFooterProps) => {
    return (
        <Stack flexDirection="row" justifyContent="space-between">
            <Button
                componentType={ButtonTypes.type.SECONDARY}
                onClick={onSupportArticleClick}
            >
                Go to support article
            </Button>
            <Button componentType={ButtonTypes.type.PRIMARY} onClick={onClose}>
                Ok got it
            </Button>
        </Stack>
    );
};

export default Handbook;

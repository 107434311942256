import axios from 'axios';
import ActionTypes from '../constants/ActionTypes';
import Urls from '../constants/Urls';
import errorHandler from '../lib/errorHandler';
import MixPanel from '../constants/MixPanel';
import moment from 'moment';
import { track } from '../lib/segment';
import qs from 'qs';
import { replace } from 'connected-react-router';
import sortBy from 'lodash/sortBy';

function getTaggedActors(text, actors) {
    const taggedActors = [];
    actors.forEach(actor => {
        const regex = new RegExp(`@${actor.shortName}\\b`);
        if (regex.test(text)) {
            taggedActors.push(actor.actorId);
        }
    });
    return taggedActors;
}

function sortAttributes(attributes) {
    const sortedAttributes = sortBy(attributes, a => a.name);
    sortedAttributes.forEach(sa => {
        sa.value = sortBy(sa.value);
    });
    return sortedAttributes;
}

export function toggleComments() {
    return (dispatch, getState) => {
        const { show } = getState().comments;
        if (show) {
            dispatch(closeComments());
        } else {
            dispatch(openComments());
        }
    };
}

export function openComments() {
    return dispatch => {
        const launcher = document.getElementById('launcher');
        if (launcher) {
            launcher.style.marginRight = '340px';
        }
        track(MixPanel.Events.OpenComments);
        dispatch({
            type: ActionTypes.OpenComments
        });
        dispatch(getComments());
    };
}

export function closeComments() {
    return (dispatch, getState) => {
        const launcher = document.getElementById('launcher');
        if (launcher) {
            launcher.style.marginRight = '20px';
        }
        dispatch({
            type: ActionTypes.CloseComments
        });

        const search = getState().router.location.search;
        const queryObject = qs.parse(search, {
            ignoreQueryPrefix: true,
            arrayFormat: 'repeat',
            comma: false
        });
        if (queryObject.showComments) {
            delete queryObject.showComments;
            let url = '/explore?';
            const queryString = qs.stringify(queryObject, {
                encodeValuesOnly: true,
                arrayFormat: 'repeat'
            });
            url += queryString;
            dispatch(replace(url));
        }
    };
}

export function getComments() {
    return (dispatch, getState) => {
        const { enterpriseId } = getState().account;
        const { category } = getState().comments;
        let { attributes } = getState().comments;
        attributes = sortAttributes(attributes);

        dispatch({
            type: ActionTypes.GetCommentsPending
        });

        return axios
            .post(`${Urls.CommentApi}comment`, {
                enterpriseId,
                category,
                attributes
            })
            .then(response => {
                const { comments } = response.data;
                dispatch({
                    type: ActionTypes.GetCommentsFulfilled,
                    comments
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.GetCommentsRejected
                });
            });
    };
}

export function addComment(text) {
    return (dispatch, getState) => {
        const { enterpriseId } = getState().account;
        const { actors, category } = getState().comments;
        let { attributes } = getState().comments;
        attributes = sortAttributes(attributes);
        const { actorId } = getState().auth.userInfo;
        const taggedActors = getTaggedActors(text, actors);

        dispatch({
            type: ActionTypes.AddCommentPending
        });

        return axios
            .put(`${Urls.CommentApi}comment`, {
                enterpriseId,
                actorId,
                category,
                attributes,
                text,
                taggedActors
            })
            .then(response => {
                const { commentId } = response.data;
                track(MixPanel.Events.SendComment);

                const {
                    actorId,
                    firstName,
                    lastName,
                    email,
                    profilePictureURL
                } = getState().auth.userInfo;
                dispatch({
                    type: ActionTypes.AddCommentFulfilled,
                    commentId,
                    text,
                    taggedActors,
                    commentDate: moment(),
                    actorId,
                    actorInfo: {
                        actorId,
                        firstName,
                        lastName,
                        email,
                        profilePicture: profilePictureURL
                    }
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.AddCommentRejected
                });
            });
    };
}

export function resolveComment(commentId, undo = false) {
    return (dispatch, getState) => {
        const { enterpriseId } = getState().account;
        const { category } = getState().comments;
        let { attributes } = getState().comments;
        attributes = sortAttributes(attributes);

        dispatch({
            type: ActionTypes.ResolveCommentPending
        });

        return axios
            .put(`${Urls.CommentApi}resolve`, {
                enterpriseId,
                category,
                attributes,
                commentId,
                undo
            })
            .then(() => {
                dispatch({
                    type: ActionTypes.ResolveCommentFulfilled,
                    commentId,
                    undo
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.ResolveCommentRejected
                });
            });
    };
}

export function deleteComment(commentId) {
    return {
        type: ActionTypes.DeleteComment,
        commentId
    };
}

export function addReply(commentId, text) {
    return (dispatch, getState) => {
        const { actorId } = getState().auth.userInfo;
        const { enterpriseId } = getState().account;
        const { actors, category } = getState().comments;
        let { attributes } = getState().comments;
        attributes = sortAttributes(attributes);
        const taggedActors = getTaggedActors(text, actors);

        dispatch({
            type: ActionTypes.AddReplyPending
        });

        return axios
            .put(`${Urls.CommentApi}reply`, {
                enterpriseId,
                commentId,
                text,
                taggedActors,
                actorId,
                category,
                attributes
            })
            .then(response => {
                const { replyId } = response.data;
                track(MixPanel.Events.SendReply);
                const {
                    actorId,
                    firstName,
                    lastName,
                    email,
                    profilePictureURL
                } = getState().auth.userInfo;
                dispatch({
                    type: ActionTypes.AddReplyFulfilled,
                    commentId,
                    replyId,
                    actorId,
                    actorInfo: {
                        actorId,
                        firstName,
                        lastName,
                        email,
                        profilePicture: profilePictureURL
                    },
                    text,
                    taggedActors,
                    replyDate: moment()
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.AddReplyRejected
                });
            });
    };
}

export function updateComment(commentId, text) {
    return (dispatch, getState) => {
        const { enterpriseId } = getState().account;
        const { actors, category } = getState().comments;
        let { attributes } = getState().comments;
        attributes = sortAttributes(attributes);
        const { actorId } = getState().auth.userInfo;
        const taggedActors = getTaggedActors(text, actors);

        dispatch({
            type: ActionTypes.UpdateCommentPending
        });

        return axios
            .put(`${Urls.CommentApi}comment`, {
                enterpriseId,
                commentId,
                text,
                taggedActors,
                actorId,
                category,
                attributes
            })
            .then(() => {
                dispatch({
                    type: ActionTypes.UpdateCommentFulfilled,
                    commentId,
                    text,
                    taggedActors,
                    commentDate: moment()
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.UpdateCommentRejected
                });
            });
    };
}

export function updateReply(commentId, replyId, text) {
    return (dispatch, getState) => {
        const { enterpriseId } = getState().account;
        const { actors, category } = getState().comments;
        let { attributes } = getState().comments;
        attributes = sortAttributes(attributes);
        const { actorId } = getState().auth.userInfo;

        dispatch({
            type: ActionTypes.UpdateReplyPending
        });
        const taggedActors = getTaggedActors(text, actors);

        return axios
            .put(`${Urls.CommentApi}reply`, {
                enterpriseId,
                actorId,
                commentId,
                replyId,
                text,
                taggedActors,
                category,
                attributes
            })
            .then(() => {
                dispatch({
                    type: ActionTypes.UpdateReplyFulfilled,
                    commentId,
                    replyId,
                    text,
                    taggedActors,
                    replyDate: moment()
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.UpdateReplyRejected
                });
            });
    };
}

export function editComment(editId) {
    return {
        type: ActionTypes.EditComment,
        editId
    };
}

export function cancelEdit() {
    return {
        type: ActionTypes.CancelEditComment
    };
}

export function getCommentCount() {
    return (dispatch, getState) => {
        const { enterpriseId } = getState().account;
        const { analysisName, mainSegments, filterSegment, breakdownSegment } =
            getState().explore.filter;

        let attributes = mainSegments.map(ms => ({
            name: ms.name,
            value: ms.values
        }));
        if (filterSegment) {
            attributes.push({
                name: filterSegment.name,
                value: filterSegment.values
            });
        }
        if (breakdownSegment) {
            attributes.push({
                name: breakdownSegment.name,
                value: breakdownSegment.values
            });
        }
        attributes = sortAttributes(attributes);

        dispatch({
            type: ActionTypes.GetCommentCountPending
        });

        return axios
            .post(`${Urls.CommentApi}comment/count`, {
                enterpriseId,
                category: analysisName,
                attributes
            })
            .then(response => {
                const { count } = response.data;
                dispatch({
                    type: ActionTypes.GetCommentCountFulfilled,
                    category: analysisName,
                    attributes,
                    count
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.GetCommentCountRejected
                });
            });
    };
}

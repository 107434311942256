import React, { useRef, useState, useEffect, useContext } from 'react';
import {
    Editor,
    EditorState,
    ContentState,
    convertToRaw,
    convertFromRaw,
    convertFromHTML,
    RichUtils
} from 'draft-js';
import produce from 'immer';
import isEmpty from 'lodash/isEmpty';
import EditorOptions from './EditorOptions';
import sanitize from '../../lib/sanitize';
import cn from '../../lib/cn';
import usePermissions from '../../hooks/usePermissions';
import RbacActions from '../../constants/RbacActions';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { autosaveDashboard } from '../../dashboards/actions';
import { DashboardLayoutContext } from '../../dashboard/DashboardLayout';
import { getDashboardForAutosave } from '../../dashboard/helpers';
import { setHoverMode } from '../../dashboard/aiInsights/reducer';

export default function TextChart({
    reportId,
    options,
    textChartState,
    textChartDispatch,
    chartContainerBoundingBox,
    isInsights = false
}) {
    const { globalLayoutState, dashboard } = useContext(DashboardLayoutContext);
    const dispatch = useAppDispatch();
    const readOnly = !usePermissions({
        actions: [RbacActions['Dashboards/Edit']]
    });
    const currentReport = useAppSelector(state =>
        state.dashboard.view.reports.find(r => r.reportId === reportId)
    );
    const contentState = currentReport.configuration?.find(
        c => c.name === 'contentState'
    )?.value;
    const textAlignment = currentReport.configuration?.find(
        c => c.name === 'textAlignment'
    )?.value;

    const ref = useRef(null);
    const [shouldSave, setShouldSave] = useState(false);
    const [currentTextAlignment, setCurrentTextAligment] =
        useState(textAlignment);
    const defaultState = ContentState.createFromBlockArray(
        convertFromHTML(sanitize('<h2></h2>'))
    );
    const [editorState, setEditorState] = useState(() =>
        !isEmpty(contentState)
            ? EditorState.createWithContent(
                  convertFromRaw(JSON.parse(contentState))
              )
            : EditorState.createWithContent(defaultState)
    );
    const editor = useRef(null);
    let reportIds = [];

    if (isInsights) {
        const ids = dashboard.reports
            .find(r => r.reportId === reportId)
            ?.configuration.find(c => c.name === 'reportIds')?.value;

        if (ids) {
            reportIds = JSON.parse(ids).concat([reportId]);
        }
    }

    // eslint-disable-next-line
    const blockRenderMap = produce(new Map(), draft => {
        draft.set('unstyled', { element: isInsights ? 'h3' : 'h2' });
        draft.set('header-two', { element: 'h2' });
        draft.set('header-three', { element: 'h3' });
        draft.set('header-four', { element: 'h4' });
    });

    function saveEdits(editorState) {
        const contentState = editorState.getCurrentContent();
        const contentStringified = JSON.stringify(convertToRaw(contentState));

        const updatedDashboard = getDashboardForAutosave(globalLayoutState, {
            ...dashboard,
            reports: dashboard.reports.map(report => {
                if (report.reportId === reportId) {
                    return {
                        ...report,
                        configuration: report.configuration.map(c => {
                            if (c.name === 'contentState') {
                                return {
                                    ...c,
                                    value: contentStringified
                                };
                            }
                            if (c.name === 'textAlignment') {
                                return {
                                    ...c,
                                    value: currentTextAlignment
                                };
                            }
                            return c;
                        })
                    };
                }
                return report;
            })
        });
        dispatch(autosaveDashboard(updatedDashboard));
    }

    function handleKeyCommand(command, editorState) {
        const newState = RichUtils.handleKeyCommand(editorState, command);

        if (newState) {
            setEditorState(newState);
            return 'handled';
        }
        return 'not-handled';
    }

    function focus() {
        editor.current.focus();
    }

    function blur() {
        editor.current.blur();
    }

    function blockStyle(contentBlock) {
        const type = contentBlock.getType();

        switch (type) {
            case 'header-four': {
                return 'textchart__small ';
            }
            case 'header-three': {
                return 'textchart__medium ';
            }
            case 'header-two': {
                return 'textchart__large';
            }
            case 'unstyled': {
                return isInsights ? 'textchart__medium' : 'textchart__large';
            }
            default: {
                return;
            }
        }
    }

    function updateFontStyle(event, blockType) {
        event.preventDefault();
        textChartDispatch({
            type: 'UPDATE_OPTION'
        });

        const currentBlockType = RichUtils.getCurrentBlockType(editorState);

        if (currentBlockType !== blockType) {
            blur();
            setEditorState(RichUtils.toggleBlockType(editorState, blockType));
            setTimeout(() => {
                focus();
                textChartDispatch({
                    type: 'EXIT'
                });
            }, 0);
        }

        return;
    }

    function updateTextAlignment(event, alignment) {
        event.preventDefault();
        textChartDispatch({ type: 'UPDATE_OPTION' });
        blur();
        setCurrentTextAligment(alignment);

        setTimeout(() => {
            focus();
        }, 0);

        return;
    }

    function handleClickOutside(event) {
        if (!ref.current) return;

        if (!ref.current.contains(event.target)) {
            textChartDispatch({
                type: 'EXIT'
            });
            setShouldSave(true);
        }
    }

    useEffect(() => {
        if (textChartState.status === 'activated') {
            window.addEventListener('click', handleClickOutside);

            return () => {
                window.removeEventListener('click', handleClickOutside);
            };
        }
    }, [textChartState]);

    useEffect(() => {
        if (shouldSave) {
            saveEdits(editorState);
            setShouldSave(false);
        }
    }, [shouldSave]);

    useEffect(() => {
        if (textChartState.status === 'activated') {
            focus();
        } else if (textChartState.status === 'idle') {
            blur();
        }
    }, [textChartState]);

    useEffect(() => {
        if (isEmpty(contentState)) {
            textChartDispatch({
                type: 'ACTIVATE',
                readOnly
            });
        }
    }, []);

    return (
        <div className={cn('h-full w-full relative')}>
            <div
                className={cn(
                    'h-full w-full overflow-scroll relative hide-scrollbar'
                )}
            >
                <div
                    className={cn(
                        'textchart',
                        textChartState.status === 'activated' ? 'z-40' : 'z-10',
                        'h-auto min-h-full'
                    )}
                    style={{
                        justifyContent: currentTextAlignment || 'flex-end'
                    }}
                    onClick={() => {
                        textChartDispatch({
                            type: 'ACTIVATE',
                            readOnly
                        });
                    }}
                    ref={ref}
                    onMouseEnter={() => {
                        if (isInsights) {
                            dispatch(
                                setHoverMode({
                                    hoverMode: true,
                                    reportIds
                                })
                            );
                        }
                    }}
                    onMouseLeave={() => {
                        if (isInsights) {
                            dispatch(
                                setHoverMode({
                                    hoverMode: false,
                                    reportIds: []
                                })
                            );
                        }
                    }}
                >
                    <Editor
                        ref={editor}
                        readOnly={readOnly}
                        editorState={editorState}
                        onChange={setEditorState}
                        blockStyleFn={blockStyle}
                        blockRenderMap={blockRenderMap}
                        handleKeyCommand={handleKeyCommand}
                        placeholder="Enter text..."
                        stripPastedStyles={true}
                    />
                </div>
            </div>
            <EditorOptions
                editorState={editorState}
                updateFontStyle={updateFontStyle}
                saveEdits={saveEdits}
                updateTextAlignment={updateTextAlignment}
                textAlignment={currentTextAlignment}
                textChartDispatch={textChartDispatch}
                readOnly={readOnly}
                chartContainerBoundingBox={chartContainerBoundingBox}
                setShouldSave={setShouldSave}
            />
        </div>
    );
}

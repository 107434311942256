import { useReducer } from 'react';

interface IUseInteractiveChartProps {
    mode:
        | 'explore'
        | 'dashboard-view'
        | 'dashboard-edit'
        | 'editor'
        | 'measure';
    status: 'idle' | 'activated' | 'activatedLocked';
    setActiveLabel: (label: string) => void;
    clearActiveLabel: any;
    toggleLabel: (label: string) => void;
    toggleLockedSelection: (label: string) => void;
    clearSelection: any;
    lockedSelection: boolean;
    hoveredItem?: string;
    selection?: string[];
}

const internalStateMachine = (state: any, action: any) => {
    switch (state.status) {
        case 'idle': {
            switch (action.type) {
                case 'setActiveLabel': {
                    return {
                        ...state,
                        status: 'activated',
                        hoveredItem: action.payload
                    };
                }
                default:
                    return state;
            }
        }
        case 'activated': {
            switch (action.type) {
                case 'clearActiveLabel': {
                    const status =
                        state.selection.length === 0 ? 'idle' : 'activated';
                    return {
                        ...state,
                        hoveredItem: undefined,
                        status
                    };
                }
                case 'setActiveLabel': {
                    return {
                        ...state,
                        status: 'activated',
                        hoveredItem: action.payload
                    };
                }
                case 'toggleLabel': {
                    let newSelection = [...state.selection];
                    if (newSelection.includes(action.payload)) {
                        newSelection = newSelection.filter(
                            item => item !== action.payload
                        );
                    } else {
                        newSelection.push(action.payload);
                    }
                    return {
                        ...state,
                        selection: newSelection
                    };
                }
                case 'toggleLockedSelection': {
                    return {
                        ...state,
                        status: 'activatedLocked',
                        selection: [action.payload],
                        hoveredItem: action.payload
                    };
                }
                case 'clearSelection': {
                    return {
                        ...state,
                        status: 'idle',
                        selection: [],
                        hoveredItem: undefined
                    };
                }
                default:
                    return state;
            }
        }
        case 'activatedLocked': {
            switch (action.type) {
                case 'clearSelection': {
                    return {
                        ...state,
                        status: 'idle',
                        selection: [],
                        hoveredItem: undefined
                    };
                }
                case 'toggleLockedSelection': {
                    let newSelection = [...state.selection];
                    let hoveredItem = state.hoveredItem;
                    let status = 'activatedLocked';
                    if (newSelection.includes(action.payload)) {
                        newSelection = [];
                        hoveredItem = undefined;
                        status = 'idle';
                    } else {
                        newSelection = [action.payload];
                        hoveredItem = action.payload;
                    }
                    return {
                        ...state,
                        status,
                        selection: newSelection,
                        hoveredItem
                    };
                }
                default:
                    return state;
            }
        }

        default:
            return state;
    }
};

const useInteractiveChart = (props: IUseInteractiveChartProps) => {
    const {
        mode,
        status,
        setActiveLabel,
        clearActiveLabel,
        toggleLabel,
        toggleLockedSelection,
        clearSelection,
        lockedSelection,
        hoveredItem,
        selection
    } = props;
    const [internalState, dispatch] = useReducer(internalStateMachine, {
        status: 'idle',
        selection: [],
        hoveredItem: undefined
    });

    function onHover(label: string) {
        switch (mode) {
            case 'explore': {
                setActiveLabel(label);
                break;
            }
            default: {
                return dispatch({ type: 'setActiveLabel', payload: label });
            }
        }
    }

    function onHoverEnd() {
        switch (mode) {
            case 'explore': {
                return clearActiveLabel();
            }
            default: {
                return dispatch({ type: 'clearActiveLabel' });
            }
        }
    }

    function onToggle(label: string) {
        switch (mode) {
            case 'explore': {
                if (lockedSelection) {
                    return toggleLockedSelection(label);
                }
                return toggleLabel(label);
            }
            default: {
                if (lockedSelection) {
                    return dispatch({
                        type: 'toggleLockedSelection',
                        payload: label
                    });
                }
                return dispatch({ type: 'toggleLabel', payload: label });
            }
        }
    }

    function onClearSelection() {
        switch (mode) {
            case 'explore': {
                return clearSelection();
            }
            default: {
                return dispatch({
                    type: 'clearSelection'
                });
            }
        }
    }

    return {
        onHover,
        onHoverEnd,
        onToggle,
        onClearSelection,
        status: mode === 'explore' ? status : internalState.status,
        hoveredItem:
            mode === 'explore' ? hoveredItem : internalState.hoveredItem,
        selection: mode === 'explore' ? selection : internalState.selection
    };
};

export default useInteractiveChart;

import moment from 'moment';
import * as uuid from 'uuid';
import exportChart from '../editor/chart/exportChart';
import exportTable from '../editor/chart/exportTable';
import AnalysisTypes from '../constants/AnalysisTypes';
import ChartTypes from '../constants/ChartTypes';
import getOptions from '../dashboard/view/getOptions';
import exportMatrixAsImage from '../explore/detail/exportDetailData';
import getPeriod from './getPeriod';
import { EditorState, convertFromRaw } from 'draft-js';
import firebase from './firebase';
import { getComputedCssValue, hexToRgb, hslToHex } from './customColors';
import { LayoutRow } from '../dashboard/DashboardLayout';

export function fromPPI(value: number, PPI = 96) {
    return value / PPI;
}

export function toPPI(value: number, PPI = 96) {
    return value * PPI;
}

async function uploadBlobToFirebase(blob: any, reportId: string) {
    const metadata = {
        contentType: 'image/png'
    };

    const exportRef = firebase
        .storage()
        .ref()
        .child(`exported-images/${reportId}`);
    await exportRef.put(blob, metadata);
    const url = await exportRef.getDownloadURL();
    return url;
}

export function deleteTempImagesFromFirebase(reportIds: string[]) {
    reportIds.forEach(reportId => {
        const ref = firebase
            .storage()
            .ref()
            .child(`exported-images/${reportId}`);

        ref.delete()
            .then(() => null)
            .catch(err => console.log(err));
    });
}

function getXStart(columnIndexStart: number, PARAMS: any) {
    const { COLUMN_WIDTH, COLUMN_GAP, MARGIN_LEFT } = PARAMS;
    const xOffset = MARGIN_LEFT;

    return xOffset + columnIndexStart * (COLUMN_WIDTH + COLUMN_GAP);
}

function getYStart(clientType: string, PARAMS: any) {
    const { HEIGHT } = PARAMS;

    return clientType === 'pptxgen' ? '30%' : HEIGHT * 0.3;
}
function getReportWidth(
    columnIndexStart: number,
    columnIndexEnd: number,
    PARAMS: any
) {
    const { COLUMN_WIDTH, COLUMN_GAP } = PARAMS;

    const columns = columnIndexEnd - columnIndexStart + 1;

    const length = columns * COLUMN_WIDTH + (columns - 1) * COLUMN_GAP;

    return length;
}

function scaleImage(clientType: string, reportObj: any, PARAMS: any) {
    const { BODY_HEIGHT } = PARAMS;
    const { width, height, configuration, columnIndexStart, columnIndexEnd } =
        reportObj;

    const columnWidthPerc = parseInt(
        configuration.find((c: any) => c.name === 'columnWidthPerc').value
    );
    const reportWidth = getReportWidth(
        columnIndexStart,
        columnIndexEnd,
        PARAMS
    );

    let w, h;

    if (clientType === 'pptxgen') {
        w = fromPPI(width);
        h = fromPPI(height);

        if (
            columnWidthPerc === 100 ||
            width > toPPI(reportWidth) ||
            height > toPPI(BODY_HEIGHT)
        ) {
            const xScale = toPPI(reportWidth) / width;
            const yScale = toPPI(BODY_HEIGHT) / height;
            const scale = Math.min(xScale, yScale);
            w = w * scale;
            h = h * scale;
        }
    } else {
        w = width;
        h = height;
        if (
            columnWidthPerc === 100 ||
            width > reportWidth ||
            height > BODY_HEIGHT
        ) {
            const xScale = reportWidth / width;
            const yScale = BODY_HEIGHT / height;
            const scale = Math.min(xScale, yScale);
            w = w * scale;
            h = h * scale;
        } else if (length === 6 || width < reportWidth) {
            const xScale = reportWidth / width;
            w = w * xScale;
            h = h * xScale;
        }
    }

    return { w, h };
}

async function addReportToSlide({
    clientType,
    slide,
    slideId,
    reportId,
    client,
    reportObj,
    presentationId,
    PARAMS
}: any) {
    const { dataURL, blob, columnIndexStart } = reportObj;

    const { w, h } = scaleImage(clientType, reportObj, PARAMS);
    const x = getXStart(columnIndexStart, PARAMS);
    const y = getYStart(clientType, PARAMS);

    const googleUrl =
        clientType === 'gapi'
            ? await uploadBlobToFirebase(blob, reportId)
            : undefined;

    const requests = [
        addImage({
            slide,
            slideId,
            client,
            presentationId,
            clientType,
            path: {
                local: null,
                google: googleUrl
            },
            data: dataURL,
            x,
            y,
            h,
            w,
            scaleX: 1,
            scaleY: 1
        })
    ].flat();

    return requests;
}

function addTextReportToSlide({
    clientType,
    slide,
    slideId,
    reportObj,
    PARAMS
}: any) {
    const textId = clientType === 'gapi' ? uuid.v4() : undefined;
    const { FONT_FAMILY } = PARAMS;
    const { configuration, columnIndexStart, columnIndexEnd } = reportObj;
    const contentState = configuration.find(
        (c: any) => c.name === 'contentState'
    ).value;

    const reportWidth = getReportWidth(
        columnIndexStart,
        columnIndexEnd,
        PARAMS
    );

    const editorState = EditorState.createWithContent(
        convertFromRaw(JSON.parse(contentState))
    );

    const textBlocks = editorState
        .getCurrentContent()
        .getBlocksAsArray()
        .map((block: any) => {
            switch (block.type) {
                case 'header-two': {
                    return {
                        text: block.text,
                        options: {
                            fontSize: 24,
                            breakLine: true
                        }
                    };
                }
                case 'header-three': {
                    return {
                        text: block.text,
                        options: {
                            fontSize: 18,
                            breakLine: true
                        }
                    };
                }
                case 'header-four': {
                    return {
                        text: block.text,
                        options: {
                            fontSize: 13,
                            breakLine: true
                        }
                    };
                }
                case 'unstyled': {
                    return {
                        text: block.text,
                        options: {
                            fontSize: 13,
                            breakLine: true
                        }
                    };
                }
                default: {
                    return {
                        text: block.text,
                        options: {
                            fontSize: 13,
                            breakLine: true
                        }
                    };
                }
            }
        });

    const requests = addText({
        x: getXStart(columnIndexStart, PARAMS),
        y: getYStart(clientType, PARAMS),
        w: reportWidth,
        h: 50,
        scaleX: 1,
        scaleY: 1,
        objectId: textId,
        slide,
        slideId,
        verticalAlign: 'top',
        clientType,
        text: textBlocks,
        fontFace: FONT_FAMILY,
        fontSize: 24,
        color: {
            hex: hslToHex(getComputedCssValue('--color-ui-100')),
            rgbColor: hexToRgb(hslToHex(getComputedCssValue('--color-ui-100')))
        },
        ...PARAMS
    }).flat();

    return requests;
}

export async function addRowSlide({
    client,
    clientType,
    row,
    presentationId,
    enterpriseName,
    reports,
    pageNumber,
    dashboard,
    PARAMS
}: any) {
    const slide = clientType === 'pptxgen' ? client.addSlide() : undefined;
    const slideId = clientType === 'gapi' ? uuid.v4() : undefined;
    const confidentialId = clientType === 'gapi' ? uuid.v4() : undefined;
    const numberId = clientType === 'gapi' ? uuid.v4() : undefined;
    let allSlideRequests: any[] = [];

    const defaultSlideTemplateRequests = generateDefaultSlideTemplate({
        client,
        clientType,
        slide,
        pageNumber,
        slideId,
        numberId,
        insertionIndex: 0,
        confidentialId,
        ...PARAMS
    });

    allSlideRequests = [...allSlideRequests, ...defaultSlideTemplateRequests];

    let lastColumnIndex = 0;

    const reportsWithColumnSpans = row.columns
        .filter((r: any) => !r.isHidden)
        .map((report: LayoutRow['columns'][0]) => {
            const { columnWidthPerc } = report;

            const columns = Math.ceil(
                columnWidthPerc / PARAMS.COLUMN_WIDTH_PERC
            );

            const columnIndexStart = lastColumnIndex;
            const columnIndexEnd = lastColumnIndex + columns - 1;

            lastColumnIndex += columns;

            return {
                ...report,
                columnIndexStart,
                columnIndexEnd
            };
        });

    try {
        await Promise.all(
            reportsWithColumnSpans.map(async (report: any) => {
                const {
                    id: reportId,
                    columnIndexStart,
                    columnIndexEnd
                } = report;
                const reportData = reports[reportId] || {};
                const { configuration, name } = dashboard.reports.find(
                    (r: any) => r.reportId === reportId
                );
                const {
                    generated,
                    analysisType,
                    columnProps,
                    data,
                    currentIndex,
                    lifecycle,
                    dates,
                    interval,
                    tableRowHeight,
                    sortCriteria,
                    expanded,
                    date,
                    summary,
                    groupSummary,
                    attributeName,
                    diversityAttributes
                } = reportData;
                const chartType = configuration.find(
                    (c: any) => c.name === 'chartType'
                ).value;

                const length = parseInt(
                    configuration.find((c: any) => c.name === 'length').value
                );

                if (!generated && chartType !== ChartTypes.Text) {
                    return;
                }

                let confidence;
                if (
                    analysisType === AnalysisTypes.LinearRegression &&
                    data.length > 1 &&
                    typeof currentIndex !== 'undefined'
                ) {
                    confidence = data[1].series[currentIndex].confidenceScore;
                }

                if (chartType === ChartTypes.Matrix) {
                    const period = getPeriod(date, interval);

                    const exportObj = await exportMatrixAsImage(
                        enterpriseName,
                        period,
                        data,
                        summary,
                        groupSummary,
                        name,
                        lifecycle,
                        analysisType,
                        columnProps,
                        attributeName,
                        diversityAttributes,
                        sortCriteria,
                        expanded,
                        clientType === 'gapi',
                        clientType === 'pptxgen'
                    );
                    const requests = await addReportToSlide({
                        clientType,
                        client,
                        slide,
                        slideId,
                        presentationId,
                        reportId,
                        reportObj: {
                            ...exportObj,
                            configuration,
                            columnIndexStart,
                            columnIndexEnd
                        },
                        PARAMS
                    });

                    allSlideRequests = [...allSlideRequests, ...requests];
                } else if (chartType === ChartTypes.Table) {
                    const options = getOptions(configuration);

                    const exportObj = await exportTable(
                        name,
                        confidence,
                        data,
                        lifecycle,
                        analysisType,
                        columnProps,
                        dates,
                        interval,
                        tableRowHeight,
                        sortCriteria,
                        expanded,
                        options,
                        clientType === 'gapi',
                        clientType === 'pptxgen'
                    );

                    const requests = await addReportToSlide({
                        clientType,
                        client,
                        slide,
                        slideId,
                        presentationId,
                        reportId,
                        reportObj: {
                            ...exportObj,
                            configuration,
                            columnIndexStart,
                            columnIndexEnd
                        },
                        PARAMS
                    });

                    allSlideRequests = [...allSlideRequests, ...requests];
                } else if (chartType === ChartTypes.Text) {
                    const requests = addTextReportToSlide({
                        clientType,
                        slide,
                        slideId,
                        reportObj: {
                            configuration,
                            columnIndexStart,
                            columnIndexEnd
                        },
                        PARAMS
                    });
                    allSlideRequests = [...allSlideRequests, ...requests];
                } else {
                    const exportObj = await exportChart({
                        reportId,
                        title: name,
                        analysisType,
                        chartType,
                        confidence,
                        length,
                        data,
                        shouldReturnBlob: clientType === 'gapi',
                        shouldReturnData: clientType === 'pptxgen'
                    });

                    const requests = await addReportToSlide({
                        clientType,
                        client,
                        slide,
                        slideId,
                        presentationId,
                        reportId,
                        reportObj: {
                            ...exportObj,
                            configuration,
                            columnIndexStart,
                            columnIndexEnd
                        },
                        PARAMS
                    });

                    allSlideRequests = [...allSlideRequests, ...requests];
                }
            })
        );

        return allSlideRequests;
    } catch (e) {
        console.error(e);
    }
}

function addLine(config: any) {
    const { color, lineWidth, x, y, w, h, client, clientType } = config;

    if (clientType === 'pptxgen') {
        const { slide } = config;
        slide.addShape(client.ShapeType.line, {
            line: { color: color.hex, width: lineWidth },
            x,
            y,
            w,
            h
        });

        return [];
    } else if (clientType === 'gapi') {
        const { objectId, slideId } = config;
        return [
            {
                createLine: {
                    objectId,
                    category: 'STRAIGHT',
                    elementProperties: {
                        pageObjectId: slideId,
                        size: {
                            width: {
                                magnitude: w,
                                unit: 'PT'
                            },
                            height: {
                                magnitude: h === 0 ? 1 : h,
                                unit: 'PT'
                            }
                        },
                        transform: {
                            scaleX: 1,
                            scaleY: 1,
                            translateX: x,
                            translateY: y,
                            unit: 'PT'
                        }
                    }
                }
            },
            {
                updateLineProperties: {
                    objectId,
                    fields: 'lineFill.solidFill.color',
                    lineProperties: {
                        lineFill: {
                            solidFill: {
                                color: {
                                    rgbColor: color.rgbColor
                                }
                            }
                        }
                    }
                }
            }
        ];
    }
    return [];
}

function addBackground(config: any) {
    const { color, clientType } = config;

    if (clientType === 'pptxgen') {
        const { slide } = config;
        slide.background = { color: color.hex };
        return [];
    } else if (clientType === 'gapi') {
        const { slideId } = config;
        return [
            {
                updatePageProperties: {
                    objectId: slideId,
                    fields: 'pageBackgroundFill.solidFill.color',
                    pageProperties: {
                        pageBackgroundFill: {
                            solidFill: {
                                color: {
                                    rgbColor: color.rgbColor
                                }
                            }
                        }
                    }
                }
            }
        ];
    }

    return [];
}

function addImage(config: any) {
    const { x, y, w, h, path, clientType, data } = config;

    if (clientType === 'pptxgen') {
        const { slide } = config;

        slide.addImage({
            path: path.local,
            data,
            x,
            y,
            w,
            h
        });
        return [];
    } else if (clientType === 'gapi') {
        const { slideId, scaleX, scaleY } = config;
        return [
            {
                createImage: {
                    url: path.google,
                    elementProperties: {
                        pageObjectId: slideId,
                        size: {
                            width: {
                                magnitude: w,
                                unit: 'PT'
                            },
                            height: {
                                magnitude: h,
                                unit: 'PT'
                            }
                        },
                        transform: {
                            scaleX,
                            scaleY,
                            translateX: x,
                            translateY: y,
                            unit: 'PT'
                        }
                    }
                }
            }
        ];
    }

    return [];
}

function addText(config: any) {
    const { x, y, w, h, fontSize, text, fontFace, color, clientType } = config;

    if (!text) {
        return [];
    }

    if (clientType === 'pptxgen') {
        const { slide, verticalAlign } = config;
        const textBlocks = typeof text === 'string' ? text : [...text];

        slide.addText(textBlocks, {
            x,
            y,
            w,
            h,
            fontSize,
            fontFace,
            color: color.hex,
            valign: verticalAlign
        });
    } else if (clientType === 'gapi') {
        const { objectId, slideId, scaleX, scaleY } = config;
        const textElements =
            typeof text === 'string'
                ? text
                : text.reduce(
                      (prev: any, curr: any) => (prev += `\n${curr.text}`),
                      ''
                  );

        const formatting =
            typeof text === 'string'
                ? [
                      {
                          updateTextStyle: {
                              objectId,
                              fields: 'fontSize,foregroundColor,fontFamily',
                              style: {
                                  fontFamily: fontFace,
                                  fontSize: {
                                      magnitude: fontSize,
                                      unit: 'PT'
                                  },
                                  foregroundColor: {
                                      opaqueColor: {
                                          rgbColor: color.rgbColor
                                      }
                                  }
                              }
                          }
                      }
                  ]
                : text.map((t: any, i: number) => {
                      const startIndex =
                          i === 0
                              ? 0
                              : text
                                    .slice(0, i)
                                    .reduce(
                                        (acc: any, curr: any) =>
                                            (acc += curr.text.length + 1),
                                        0
                                    );
                      const endIndex = startIndex + t.text.length + 1;

                      return {
                          updateTextStyle: {
                              objectId,
                              textRange: {
                                  type: 'FIXED_RANGE',
                                  startIndex,
                                  endIndex
                              },
                              style: {
                                  fontFamily: fontFace,
                                  fontSize: {
                                      magnitude: t.options.fontSize,
                                      unit: 'PT'
                                  },
                                  foregroundColor: {
                                      opaqueColor: {
                                          rgbColor: color.rgbColor
                                      }
                                  }
                              },
                              fields: 'foregroundColor,fontFamily,fontSize'
                          }
                      };
                  });

        return [
            {
                createShape: {
                    objectId,
                    shapeType: 'TEXT_BOX',
                    elementProperties: {
                        pageObjectId: slideId,
                        size: {
                            height: {
                                magnitude: h,
                                unit: 'PT'
                            },
                            width: {
                                magnitude: w,
                                unit: 'PT'
                            }
                        },
                        transform: {
                            scaleX,
                            scaleY,
                            translateX: x,
                            translateY: y,
                            unit: 'PT'
                        }
                    }
                }
            },
            {
                insertText: {
                    objectId,
                    insertionIndex: 0,
                    text: textElements
                }
            },

            ...formatting
        ];
    }
    return [];
}

function createSlide(config: any) {
    const { clientType, insertionIndex } = config;

    if (clientType === 'pptxgen') {
        return [];
    } else if (clientType === 'gapi') {
        const { slideId } = config;
        return [
            {
                createSlide: {
                    objectId: slideId,
                    insertionIndex
                }
            }
        ];
    }
}

function deleteSlideObject(config: any) {
    const { clientType } = config;

    if (clientType === 'pptxgen') {
        return [];
    } else if (clientType === 'gapi') {
        const { slideIdToDelete } = config;
        return [
            {
                deleteObject: {
                    objectId: slideIdToDelete
                }
            }
        ];
    }

    return [];
}

export function generateCoverSlideTemplate(
    dashboardName: string,
    enterpriseName: string,
    config: any
) {
    const {
        WIDTH,
        HEIGHT,
        MARGIN_LEFT,
        MARGIN_TOP,
        FONT_FAMILY,
        HALF_BODY_WIDTH,
        RESIZED_IMAGE_HEIGHT,
        PADDING,
        //IMAGE_SCALE,
        clientType,
        yearId,
        dateId,
        lineId,
        nameId,
        enterpriseId,
        confidentialId,
        slideIdToDelete,
        FONT_XSMALL,
        FONT_XLARGE,
        FONT_SMALL
    } = config;

    const requests = [
        createSlide({
            ...config
        }),
        addBackground({
            color: {
                hex: hslToHex(getComputedCssValue('--color-shade-h2')),
                rgbColor: hexToRgb(
                    hslToHex(getComputedCssValue('--color-shade-h2'))
                )
            },
            ...config
        }),
        addLine({
            objectId: lineId,
            color: {
                hex: hslToHex(getComputedCssValue('--color-ui-100')),
                rgbColor: hexToRgb(
                    hslToHex(getComputedCssValue('--color-ui-100'))
                )
            },
            lineWidth: 0.5,
            x: MARGIN_LEFT,
            y: MARGIN_TOP,
            w: WIDTH - 2 * MARGIN_LEFT,
            h: 0,
            ...config
        }),
        addImage({
            path: {
                local: '/images/logo-smiley.png',
                google: 'https://storage.googleapis.com/dandi-prod-assets/logo-smiley.png'
            },
            x: MARGIN_LEFT,
            y:
                clientType === 'pptxgen'
                    ? MARGIN_TOP - fromPPI(30)
                    : MARGIN_TOP - 20,
            w: clientType === 'pptxgen' ? fromPPI(21) : 18,
            h: clientType === 'pptxgen' ? fromPPI(18) : 15,
            scaleX: 1,
            scaleY: 1,
            ...config
        }),
        addText({
            text: new Date().getFullYear().toString(),
            objectId: yearId,
            x:
                clientType === 'pptxgen'
                    ? fromPPI(550)
                    : MARGIN_LEFT + (WIDTH - 2 * MARGIN_LEFT) * 0.55,
            y:
                clientType === 'pptxgen'
                    ? MARGIN_TOP - fromPPI(25)
                    : MARGIN_TOP - 24,
            h: clientType === 'pptxgen' ? fromPPI(18) : 18,
            w: clientType === 'pptxgen' ? fromPPI(70) : 60,
            scaleX: 1,
            scaleY: 1,
            fontSize: FONT_XSMALL,
            fontFace: FONT_FAMILY,
            color: {
                hex: hslToHex(getComputedCssValue('--color-ui-100')),
                rgbColor: hexToRgb(
                    hslToHex(getComputedCssValue('--color-ui-100'))
                ),
                ...config
            }
        }),
        addText({
            text: 'Proprietary & Confidential - Do Not Forward',
            objectId: confidentialId,
            x: clientType === 'pptxgen' ? fromPPI(650) : 470,
            y:
                clientType === 'pptxgen'
                    ? MARGIN_TOP - fromPPI(25)
                    : MARGIN_TOP - 24,
            fontSize: FONT_XSMALL,
            fontFace: FONT_FAMILY,
            color: {
                hex: hslToHex(getComputedCssValue('--color-ui-100')),
                rgbColor: hexToRgb(
                    hslToHex(getComputedCssValue('--color-ui-100'))
                )
            },
            scaleX: 1,
            scaleY: 1,
            w: clientType === 'pptxgen' ? fromPPI(300) : 250,
            h: clientType === 'pptxgen' ? fromPPI(18) : 18,
            ...config
        }),
        addText({
            text: dashboardName,
            objectId: nameId,
            x: clientType === 'pptxgen' ? MARGIN_LEFT : MARGIN_LEFT - 8,
            y: clientType === 'pptxgen' ? HEIGHT - fromPPI(140) : HEIGHT - 140,
            fontSize: FONT_XLARGE,
            fontFace: FONT_FAMILY,
            color: {
                hex: hslToHex(getComputedCssValue('--color-ui-100')),
                rgbColor: hexToRgb(
                    hslToHex(getComputedCssValue('--color-ui-100'))
                )
            },
            scaleX: 1,
            scaleY: 1,
            w: clientType === 'pptxgen' ? fromPPI(500) : 250,
            h: clientType === 'pptxgen' ? fromPPI(18) : 18,
            ...config
        }),
        addText({
            text: enterpriseName,
            objectId: enterpriseId,
            x: clientType === 'pptxgen' ? MARGIN_LEFT : MARGIN_LEFT - 8,
            y:
                clientType === 'pptxgen'
                    ? HEIGHT - (MARGIN_TOP + fromPPI(18))
                    : HEIGHT - (MARGIN_TOP + 36),
            w: clientType === 'pptxgen' ? fromPPI(300) : 250,
            h: clientType === 'pptxgen' ? fromPPI(18) : 18,
            scaleX: 1,
            scaleY: 1,
            fontSize: FONT_SMALL,
            fontFace: FONT_FAMILY,
            color: {
                hex: hslToHex(getComputedCssValue('--color-ui-100')),
                rgbColor: hexToRgb(
                    hslToHex(getComputedCssValue('--color-ui-100'))
                )
            },
            ...config
        }),
        addText({
            text: moment().format('MMM D, YYYY'),
            objectId: dateId,
            x: clientType === 'pptxgen' ? MARGIN_LEFT : MARGIN_LEFT - 8,
            y:
                clientType === 'pptxgen'
                    ? HEIGHT - MARGIN_TOP
                    : HEIGHT - MARGIN_TOP - 18,
            w: clientType === 'pptxgen' ? fromPPI(300) : 250,
            h: clientType === 'pptxgen' ? fromPPI(18) : 18,
            scaleX: 1,
            scaleY: 1,
            fontSize: FONT_SMALL,
            fontFace: FONT_FAMILY,
            color: {
                hex: hslToHex(getComputedCssValue('--color-ui-100')),
                rgbColor: hexToRgb(
                    hslToHex(getComputedCssValue('--color-ui-100'))
                )
            },
            ...config
        }),
        addImage({
            path: {
                local: '/images/cover-slide-graph.png',
                google: 'https://storage.googleapis.com/dandi-prod-assets/cover-slide-graph.png'
            },
            x:
                clientType === 'pptxgen'
                    ? MARGIN_LEFT + HALF_BODY_WIDTH + fromPPI(20)
                    : MARGIN_LEFT + HALF_BODY_WIDTH + PADDING,
            y:
                clientType === 'pptxgen'
                    ? HEIGHT - (MARGIN_TOP + RESIZED_IMAGE_HEIGHT)
                    : HEIGHT - MARGIN_TOP - RESIZED_IMAGE_HEIGHT,
            scaleX: 1,
            scaleY: 1,
            w: HALF_BODY_WIDTH,
            h: RESIZED_IMAGE_HEIGHT,
            ...config
        }),
        deleteSlideObject({
            objectId: slideIdToDelete,
            ...config
        })
    ];

    return requests.flat();
}

export function generateDefaultSlideTemplate(config: any) {
    const {
        WIDTH,
        HEIGHT,
        MARGIN_LEFT,
        MARGIN_TOP,
        clientType,
        FONT_FAMILY,
        pageNumber,
        confidentialId,
        numberId,
        FONT_XSMALL
    } = config;

    const requests = [
        createSlide({
            ...config
        }),
        addBackground({
            color: {
                hex: hslToHex(getComputedCssValue('--color-shade-h2')),
                rgbColor: hexToRgb(
                    hslToHex(getComputedCssValue('--color-shade-h2'))
                )
            },
            ...config
        }),
        addText({
            objectId: confidentialId,
            text: 'Proprietary & Confidential - Do Not Forward',
            h: clientType === 'pptxgen' ? fromPPI(18) : 18,
            w: clientType === 'pptxgen' ? fromPPI(300) : 250,
            scaleX: 1,
            scaleY: 1,
            x: clientType === 'pptxgen' ? MARGIN_LEFT : MARGIN_LEFT,
            y:
                clientType === 'pptxgen'
                    ? MARGIN_TOP - fromPPI(15)
                    : MARGIN_TOP - 24,
            fontSize: FONT_XSMALL,
            fontFace: FONT_FAMILY,
            color: {
                hex: hslToHex(getComputedCssValue('--color-ui-100')),
                rgbColor: hexToRgb(
                    hslToHex(getComputedCssValue('--color-ui-100'))
                )
            },
            ...config
        }),

        addImage({
            path: {
                local: '/images/dandi-black--vertical.png',
                google: 'https://storage.googleapis.com/dandi-prod-assets/dandi-black-vertical.png'
            },
            x:
                clientType === 'pptxgen'
                    ? WIDTH - MARGIN_LEFT - fromPPI(30)
                    : WIDTH - MARGIN_LEFT - 21,
            y: clientType === 'pptxgen' ? fromPPI(30) : MARGIN_TOP - 20,
            scaleX: 1,
            scaleY: 1,
            w: clientType === 'pptxgen' ? fromPPI(35) : 21,
            h: clientType === 'pptxgen' ? fromPPI(100) : 60,
            ...config
        }),
        addText({
            objectId: numberId,
            text: pageNumber.toString(),
            h: clientType === 'pptxgen' ? fromPPI(14) : 14,
            w: clientType === 'pptxgen' ? fromPPI(30) : 30,
            scaleX: 1,
            scaleY: 1,
            x: clientType === 'pptxgen' ? WIDTH - fromPPI(30) : WIDTH - 24,
            y: clientType === 'pptxgen' ? HEIGHT - fromPPI(30) : HEIGHT - 24,
            fontSize: FONT_XSMALL,
            fontFace: FONT_FAMILY,
            color: {
                hex: hslToHex(getComputedCssValue('--color-ui-50')),
                rgbColor: hexToRgb(
                    hslToHex(getComputedCssValue('--color-ui-50'))
                )
            },
            ...config
        })
    ];

    return requests.flat();
}

import React from 'react';
import Info from '../../icons/Info';

export default function Textbar() {
    return (
        <div className="textbar">
            <div className="textbar__title">
                <Info width={16} style={{ marginRight: 8 }} />
                <span>Integrations</span>
            </div>
            <div>
                Integrations display the platforms at your company that are
                currently integrated with your Dandi account. These include HR
                Sources (HRIS, ATS) and any SSO connections.
            </div>
            <div>
                The HR Sources list displays when your platform was initially
                connected and the date of the most recent data pull. This
                refresh occurs on a monthly basis by default. If you’d like to
                refresh your account more frequently, simply contact your
                customer support representative and we’ll take care of it.
            </div>
        </div>
    );
}

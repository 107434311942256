import React, { PropsWithChildren, useReducer } from 'react';
import useInteractiveChart from '../common/useInteractiveChart';

interface IChartState {
    mode:
        | 'explore'
        | 'editor'
        | 'measure'
        | 'dashboard-view'
        | 'dashboard-edit'
        | null;
    lineWidth: number;
    multiLine: boolean;
    chartWidth: number;
    chartHeight: number;
    innerChartOffsets: {
        left: number;
        right: number;
    };
    initialized: boolean;
}

const initialState: IChartState = {
    mode: null,
    initialized: false,
    lineWidth: 1,
    multiLine: false,
    chartWidth: 0,
    chartHeight: 0,
    innerChartOffsets: {
        left: 0,
        right: 0
    }
};

interface IContext {
    chartState: IChartState;
    chartDispatch: React.Dispatch<any>;
    onHover: (event: any) => void;
    onClearSelection: () => void;
    onHoverEnd: () => void;
    onToggle: (event: any) => void;
    hoveredItem?: string;
    selection: string[];
    status: string;
}

export const ChartContext = React.createContext<IContext>({
    chartState: initialState,
    chartDispatch: () => null,
    onHover: () => null,
    onClearSelection: () => null,
    onHoverEnd: () => null,
    onToggle: () => null,
    status: 'idle',
    selection: []
});

const reducer = (state: any, action: any) => {
    switch (action.type) {
        case 'setInitialized':
            return {
                ...state,
                initialized: true
            };
        case 'setChartWidth': {
            return {
                ...state,
                chartWidth: action.payload
            };
        }
        case 'setChartHeight': {
            return {
                ...state,
                chartHeight: action.payload
            };
        }
        case 'setMode': {
            return {
                ...state,
                mode: action.payload
            };
        }
        case 'setInnerChartOffsets': {
            return {
                ...state,
                innerChartOffsets: action.payload
            };
        }
        case 'setWidth': {
            return {
                ...state,
                width: action.payload
            };
        }
        case 'setHeight': {
            return {
                ...state,
                height: action.payload
            };
        }
        case 'setContainerHeight': {
            return {
                ...state,
                containerHeight: action.payload
            };
        }
        case 'setMultiLine': {
            return {
                ...state,
                multiLine: action.payload
            };
        }
        case 'setLineWidth': {
            return {
                ...state,
                lineWidth: action.payload
            };
        }
        default:
            return state;
    }
};

export const ChartContextProvider = ({ children }: PropsWithChildren) => {
    const [chartState, chartDispatch] = useReducer(reducer, initialState);

    const {
        onHover,
        onHoverEnd,
        onToggle,
        onClearSelection,
        hoveredItem,
        selection,
        status
    } = useInteractiveChart({
        mode: chartState.mode,
        status: 'idle',
        hoveredItem: undefined,
        selection: [],
        setActiveLabel: (_: string) => {},
        toggleLabel: (_: string) => {},
        toggleLockedSelection: (_: string) => {},
        lockedSelection: false,
        clearActiveLabel: () => {},
        clearSelection: () => {}
    });

    return (
        <ChartContext.Provider
            value={{
                onHover,
                onHoverEnd,
                onToggle,
                onClearSelection,
                hoveredItem,
                selection,
                status,
                chartState,
                chartDispatch
            }}
        >
            {children}
        </ChartContext.Provider>
    );
};

import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M1.943 6.969a4 4 0 014-4h40a4 4 0 014 4v21.043h-48V6.97z"
                fill="var(--color-shade-h3)"
            />
            <path
                d="M1.943 28.012h48v18.956a4 4 0 01-4 4h-40a4 4 0 01-4-4V28.012z"
                fill="var(--color-shade-h1)"
            />
            <rect
                x={1.037}
                y={1.969}
                width={50}
                height={50}
                rx={5}
                stroke="var(--color-graph-6)"
                strokeWidth={2}
            />
            <path fill="var(--color-graph-6)" d="M11.412 28.012h2v22.956h-2z" />
            <path fill="var(--color-graph-5)" d="M24.943 14.248h2v36.721h-2z" />
            <path
                fill="var(--color-graph-14)"
                d="M38.474 36.002h2v14.967h-2z"
            />
        </svg>
    );
}

export default SvgComponent;

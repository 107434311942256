import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import FilterButton from './FilterButton';

export default function FilterDropdown({
    children,
    isOpen,
    setIsOpen,
    label,
    placeholder,
    disabled,
    isSecondary,
    allowCancel,
    onClearSelection
}) {
    useEffect(() => {
        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('mousedown', handleClickOutside);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
                window.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [isOpen]);

    const containerRef = useRef();

    function handleClick() {
        setIsOpen(!isOpen);
    }

    function handleKeyDown(e) {
        if (e.keyCode === 27) {
            setIsOpen(false);
        }
    }

    function handleClickOutside(e) {
        if (isOpen) {
            if (
                containerRef.current &&
                !containerRef.current.contains(e.target)
            ) {
                e.stopPropagation();
                setIsOpen(false);
            }
        }
    }

    return (
        <div
            className="filter-dropdown"
            ref={containerRef}
            data-test={`dropdown-${placeholder}`}
        >
            <FilterButton
                label={label}
                placeholder={placeholder}
                onClick={handleClick}
                disabled={disabled}
                isOpen={isOpen}
                isSecondary={isSecondary}
                allowCancel={allowCancel}
                onClearSelection={onClearSelection}
            />
            <CSSTransition
                in={isOpen}
                timeout={300}
                mountOnEnter
                unmountOnExit
                classNames="fade-scale"
            >
                <div
                    className="filter-dropdown__menu"
                    data-test={`dropdown-menu-${placeholder}`}
                >
                    {children}
                </div>
            </CSSTransition>
        </div>
    );
}

FilterDropdown.propTypes = {
    label: PropTypes.string,
    placeholder: PropTypes.string,
    isOpen: PropTypes.bool,
    setIsOpen: PropTypes.func,
    children: PropTypes.any,
    disabled: PropTypes.bool,
    isSecondary: PropTypes.bool,
    allowCancel: PropTypes.bool,
    onClearSelection: PropTypes.func
};

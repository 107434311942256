const events = {
    LogIn: 'Log In',
    ViewDetailedReports: 'View Detailed Reports',
    ViewDepartmentReports: 'View Reports By Department',
    OpenComments: 'Open Comments',
    SendComment: 'Send Comment',
    SendReply: 'Send Reply',
    ViewAnalysisInformation: 'View Analysis Information',
    AddReportToDashboard: 'Add Report to Dashboard',
    ViewDashboard: 'View Dashboard',
    LogOut: 'Log Out',
    LeftNavClick: 'Left Nav click',
    ExportCSV: 'Export widget CSV',
    ExportImage: 'Export widget image',
    ViewNotifications: 'View notification menu',
    ViewProfile: 'View user profile menu',
    EditProfile: 'Edit user profile',
    CancelProfile: 'Cancel user profile change',
    SaveProfile: 'Save user profile change',
    ChangePasswordClick: 'Change password click',
    CancelChangePassword: 'Cancel change password',
    SaveChangePassword: 'Save change password',
    ViewUsers: 'View Users Admin',
    ViewRoles: 'View Roles Admin',
    CreateUserClick: 'Create user modal',
    CreateUser: 'Created a new user',
    CreateRoleClick: 'Create role modal',
    CreateRole: 'Created a new role',
    SelectCategoryClick: 'View employee lifecycle filter (Reports)',
    SelectCategory: 'Select employee lifecycle filter (Reports)',
    SelectPeriodClick: 'View time interval filter (Reports)',
    SelectPeriod: 'Select time interval filter (Reports)',

    ClickSendTestEmail: 'Click Send Test Email',
    SendTestEmail: 'Sended Test Email',

    NavBarClick: 'Nav Bar Click',
    PinsClickReport: 'Pins Click Report',
    CollectExport: 'Collect Export',
    AdminClickCreateUser: 'Admin Click Create User',
    AdminCreateUser: 'Admin Create User',
    AdminClickRole: 'Admin Click Role',
    AdminSelectRole: 'Admin Select Role',
    AdminClickDelete: 'Admin Click Delete',
    AdminDeleteUser: 'Admin Delete User',
    AdminDeleteUserCancel: 'Admin Delete User Cancel',
    AdminExploreAccessChange: 'Admin Explore Access Change',

    AdminUsersClickAddRole: 'Admin Users Click Add Role',
    AdminUsersClickRole: 'Admin Users Click Role',
    AdminUsersClickRemoveRole: 'Admin Users Click Remove Role',
    AdminRolesPermissionsClickEditRole:
        'Admin Roles Permissions Click Edit Role',
    AdminRolesPermissionsClickDeleteRole:
        'Admin Roles Permissions Click Delete Role',
    AdminRolesPermissionsClickCreateRole:
        'Admin Roles Permissions Click Create Role',
    AdminRolesPermissionsClickDuplicateRole:
        'Admin Roles Permissions Click Duplicate Role',
    AdminCreateRoleClickPreviewRole: 'Admin Create Role Click Preview Role',
    AdminCreateRoleClickSaveRole: 'Admin Create Role Click Save Role',
    AdminCreateRoleClickCancel: 'Admin Create Role Click Cancel',
    AdminGroupsClickAddRole: 'Admin Groups Click Add Role',
    AdminGroupsClickRole: 'Admin Groups Click Role',
    AdminGroupsClickViewUsers: 'Admin Groups Click View Users',
    AdminGroupsClickSyncUsersGroups: 'Admin Roles Click Sync Users Groups',

    ExploreCategory: 'Explore Generator Select Category',
    ExploreSegment: 'Explore Generator Select Segment',
    ExploreGenerate: 'Explore Generator Generate',
    ExploreClear: 'Explore Generator Clear',
    ExploreMonthly: 'Explore Primary Graph View Monthly',
    ExploreQuarterly: 'Explore Primary Graph View Quarterly',
    ExploreYearly: 'Explore Primary Graph View Yearly',
    ExplorePin: 'Explore Primary Graph Pin',
    ExploreExport: 'Explore Primary Graph Export',
    ExploreExportAsCSV: 'Explore Primary Graph CSV Export',
    ExploreInfo: 'Explore Primary Graph View Info',
    ExploreSegmentNav: 'Explore Primary Table Click Segment Filter',
    ExploreEmployeesNav: 'Employee Level Click',
    ExploreAttributeTableExpand: 'Explore Attribute Table Expand',
    ExploreAttributeTableImageExport: 'Explore Attribute Table Image Export',
    ExploreAttributeTableCSVExport: 'Explore Attribute Table CSV Export',
    ExploreAttributeTableFilter: 'Explore Attribute Table Filter',
    ExploreSelectChartType: 'Explore Select Chart Type',
    ExplorePrimaryGraphAddToDashboardClick:
        'Explore Primary Graph Add To Dashboard Click',
    ExploreAttributeTableAddToDashboardClick:
        'Explore Attribute Table Add To Dashboard Click',
    ExploreInvalidVariation: 'Explore Invalid Variation',
    ExploreNoData: 'Explore No Data',
    ExploreSelectInterval: 'Explore Select Interval',
    ExploreSelectIntervalCount: 'Explore Select Interval Count',
    ExploreSelectIntervalRange: 'Explore Select Interval Range',
    ExploreSelectDate: 'Explore Select Date',
    ExploreSelectBenchmark: 'Explore Select Benchmark',
    ExploreRemoveBenchmark: 'Explore Remove Benchmark',
    ExploreCreateBenchmark: 'Explore Create Benchmark',
    ExploreEditBenchmark: 'Explore Edit Benchmark',
    ExploreDeleteBenchmark: 'Explore Delete Benchmark',
    ExploreDeleteBenchmarkConfirm: 'Explore Delete Benchmark Confirm',
    ExploreDeleteBenchmarkCancel: 'Explore Delete Benchmark Cancel',
    ExploreSelectSubtableCell: 'Explore Select Subtable Cell',
    ExploreSavedSearchSave: 'Explore Saved Search Save ',
    ExploreSavedSearchReportClick: 'Explore Saved Search Report Click',
    ExploreSavedSearchReportDelete: 'Explore Saved Search Report Delete',
    ExploreSavedSearchReportRename: 'Explore Saved Search Report Rename',

    MyDashboardsRecentDashboardClick: 'My Dashboards Recent Dashboard Click',
    MyDashboardsRecentDashboardShare: 'My Dashboards Recent Dashboard Share',
    MyDashboardsRecentDashboardEdit: 'My Dashboards Recent Dashboard Edit',
    MyDashboardsRecentDashboardDelete: 'My Dashboards Recent Dashboard Delete',
    MyDashboardsAllDashboardClick: 'My Dashboards All Dashboard Click',
    MyDashboardsAllDashboardShare: 'My Dashboards All Dashboard Share',
    MyDashboardsAllDashboardEdit: 'My Dashboards All Dashboard Edit',
    MyDashboardsAllDashboardDelete: 'My Dashboards All Dashboard Delete',
    MyDashboardsDeleteDashboard: 'My Dashboards Delete Dashboard',
    MyDashboardsCreateDashboardClick: 'My Dashboards Create Dashboard Click',
    MyDashboardsCreateDashboard: 'My Dashboards Create Dashboard',
    MyDashboardsSearch: 'My Dashboards Search',
    MyDashboardsSearchClear: 'My Dashboards Search Clear',

    DashboardAddReport: 'Dashboard Add Report',
    DashboardShareDashboard: 'Dashboard Share Dashboard',
    DashboardEditDashboard: 'Dashboard Edit Dashboard',
    DashboardCSVExport: 'Dashboard CSV Export',
    DashboardSlidesExport: 'Dashboard Slides Export',
    DashboardPPTExport: 'Dashboard PPT Export',

    DashboardReportExplore: 'Dashboard Report Explore',
    DashboardReportImageExport: 'Dashboard Report Image Export',
    DashboardReportCSVExport: 'Dashboard Report CSV Export',
    DashboardReportEdit: 'Dashboard Report Edit',
    DashboardReportAddTo: 'Dashboard Report Add To',
    DashboardReportDelete: 'Dashboard Report Delete',
    DashboardReportLineGraphXAxisClick:
        'Dashboard Report Line Graph X-axis Click',
    DashboardReportLineGraphLineSegmentClick:
        'Dashboard Report Line Graph Line Segment Click',
    DashboardReportLineGraphLegendSegmentClick:
        'Dashboard Report Line Graph Legend Segment Click',
    DashboardReportTableRowHighlight: 'Dashboard Report Table Row Highlight',
    DashboardReportTableValueClick: 'Dashboard Report Table Value Click',
    DashboardReportStackedBarChartSegmentClick:
        'Dashboard Report Stacked Bar Chart Segment Click',
    DashboardReportStackedBarChartLegentClick:
        'Dashboard Report Stacked Bar Chart Legend Click',
    DashboardReportStackedBarChartTimePeriodClick:
        'Dashboard Report Stacked Bar Chart Time Period Click',
    DashboardReportStackedBarChartTimeScrollClick:
        'Dashboard Report Stacked Bar Chart Time Scroll Click',
    DashboardReportStackedBarChartXAxisClick:
        'Dashboard Report Stacked Bar Chart X-axis Click',
    DashboardReportPieChartSegmentClick:
        'Dashboard Report Pie Chart Segment Click',
    DashboardReportPieChartLegendClick:
        'Dashboard Report Pie Chart Legend Click',
    DashboardReportPieChartTimePeriodClick:
        'Dashboard Report Pie Chart Time Period Click',
    DashboardReportPieChartTimeScrollClick:
        'Dashboard Report Pie Chart Time Scroll Click',
    DashboardReportColumnGraphXAxisClick:
        'Dashboard Report Column Graph X-axis Click',
    DashboardReportColumnGraphTimeScrollClick:
        'Dashboard Report Column Graph Time Scroll Click',
    DashboardReportColumnGraphColumnSegmentClick:
        'Dashboard Report Column Graph Column Segment Click',
    DashboardReportColumnGraphLegendSegmentClick:
        'Dashboard Report Column Graph Legend Segment Click',
    DashboardReportTableColumnClick: 'Dashboard Report Table Column Click',
    DashboardReportSubtableColumnClick:
        'Dashboard Report Subtable Column Click',

    ReportEditorSelectInterval: 'Report Editor Select Interval',
    ReportEditorSelectIntervalCount: 'Report Editor Select Interval Count',
    ReportEditorSelectIntervalRange: 'Report Editor Select Interval Range',
    ReportEditorSelectDate: 'Report Editor Select Date',
    ReportEditorSelectChartType: 'Report Editor Select Chart Type',
    ReportEditorSaveNewReport: 'Report Editor Save New Report',
    ReportEditorSaveExistingReport: 'Report Editor Save Existing Report',
    ReportEditorResize: 'Report Editor Resize',
    ReportEditorSelectShowLastPeriod: 'Report Editor Select Show Last Period',
    ReportEditorSelectPeriodOverPeriodChange:
        'Report Editor Select Period Over Period Change',
    ReportEditorSelectRowAttribute: 'Report Editor Select Row Attribute',
    ReportEditorGenerate: 'Report Editor Generate',
    ReportEditorClear: 'Report Editor Clear',
    ReportEditorInvalidVariation: 'Report Editor Invalid Variation',
    ReportEditorNoData: 'Report Editor No Data',
    ReportEditorSelectBenchmark: 'Report Editor Select Benchmark',
    ReportEditorRemoveBenchmark: 'Report Editor Remove Benchmark',
    ReportEditorDeleteBenchmark: 'Report Editor Delete Benchmark',
    ReportEditorDeleteBenchmarkConfirm:
        'Report Editor Delete Benchmark Confirm',
    ReportEditorDeleteBenchmarkCancel: 'Report Editor Delete Benchmark Cancel',
    ReportEditorCreateBenchmark: 'Report Editor Create Benchmark',
    ReportEditorEditBenchmark: 'Report Editor Edit Benchmark',

    SSOShowProviderPopup: 'SSO Show Provider Popup',

    DashboardShareModalCancelClick: 'Dashboard Share Modal Cancel Click',
    DashboardShareModalDoneClick: 'Dashboard Share Modal Done Click',
    DashboardShareModalCopyLinkClick: 'Dashboard Share Modal Copy Link Click',
    DashboardShareModalSelectUser: 'Dashboard Share Modal Select User',
    DashboardShareModalDeselectUser: 'Dashboard Share Modal Deselect User',
    DashboardShareModalRemoveViewer: 'Dashboard Share Modal Remove Viewer',

    DashboardDuplicateDashboard: 'Dashboard Click Duplicate Dashboard',
    DashboardDuplicateModalCancelClick:
        'Dashboard Click Duplicate Dashboard Cancel',
    DashboardDuplicateModalSaveClick:
        'Dashboard Click Duplicate Dashboard Duplicate',
    DashboardApplyFilterModalCancelClick:
        'Dashboard Click Apply Filter Permanently Cancel',
    DashboardApplyFilterModalSaveClick:
        'Dashboard Click Apply Filter Permanently Update',
    DashboardApplyFilter: 'Dashboard Click Apply Filter Permanently',
    DashboardShowFilter: 'Dashboard Click Add Filter',
    DashboardHideFilter: 'Dashboard Click Hide Filter',
    DashboardOpenSegmentFilter: 'Dashboard Click Filter',
    DashboardOpenIntervalFilter: 'Dashboard Click Filter',
    DashboardOpenIntervalRangeFilter: 'Dashboard Click Filter',
    DashboardChangeSegmentFilter: 'Dashboard Select Filter Value',
    DashboardChangeIntervalFilter: 'Dashboard Select Filter Value',
    DashboardChangeIntervalRangeFilter: 'Dashboard Select Filter Value',
    DashboardFilterClear: 'Dashboard Click Filter Clear',
    DashboardFilterApply: 'Dashboard Click Filter Apply Filter',

    DashboardAddToModalCancelClick: 'Dashboard Add To Modal Cancel Click',
    DashboardAddToModalAddClick: 'Dashboard Add To Modal Add Click',
    DashboardAddToModalSelectDashboard:
        'Dashboard Add To Modal Select Dashboard',
    DashboardAddToModalDeselectDashboard:
        'Dashboard Add To Modal Deselect Dashboard',
    DashboardAddToModalCreateNewDashboardClick:
        'Dashboard Add To Modal Create New Dashboard Click',

    EditDashboardBackClick: 'Edit Dashboard Back Click',
    EditDashboardCancelClick: 'Edit Dashboard Cancel Click',
    EditDashboardSaveClick: 'Edit Dashboard Save Click',
    EditDashboardAddReportClick: 'Edit Dashboard Add Report Click',
    EditDashboardDeleteReportClick: 'Edit Dashboard Delete Report Click',
    EditDashboardMoveReport: 'Edit Dashboard Move Report',
    EditDashboardResizeReport: 'Edit Dashboard Resize Report',

    BenchmarkModalSaveBenchmark: 'Benchmark Modal Save Benchmark',
    BenchmarkModalGenerateBenchmark: 'Benchmark Modal Generate Benchmark',
    BenchmarkModalGoBack: 'Benchmark Modal Go Back',
    BenchmarkModalGenerateCancel: 'Benchmark Modal Generate Cancel',
    BenchmarkModalPreviewCancel: 'Benchmark Modal Preview Cancel',

    TimelineEventCreate: 'Timeline Event Create',
    TimelineEventEdit: 'Timeline Event Edit',
    TimelineEventDelete: 'Timeline Event Delete',
    TimelineEventCancelClick: 'Timeline Event Cancel Click',
    TimelineAddEventToGraph: 'Timeline Add Event To Graph',

    GoalSave: 'Goal Save Click',
    GoalCancelClick: 'Goal Cancel Click',
    GoalEdit: 'Goal Edit Click',
    GoalDelete: 'Goal Delete Click',
    GoalSelect: 'Goal Select Click',
    MeasureGoalsOverviewPageView: 'Measure Goals Overview Page View',
    MeasureGoalsDetailsPageView: 'Measure Goals Details Page View',

    WelcomeNext: 'Welcome Next Click',
    WelcomeDone: 'Welcome Done Click',
    LoginNewUser: 'Login New User'
};

const pages = {
    LogIn: 'Log In',
    Reports: 'Reports',
    Dashboards: 'Dashboards',
    Admin: 'Admin',
    UserProfile: 'User Profile',
    Onboarding: 'Onboarding',
    Dashboard: 'Dashboard',
    ReportEditor: 'Report Editor',
    Explore: 'Explore',
    Collect: 'Collect',
    EditDashboard: 'Edit Dashboard',
    PasswordLogin: 'Password Login',
    Welcome: 'Welcome'
};

const mixPanel = {
    Events: events,
    Pages: pages
};

export default mixPanel;

import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import Urls from '../../../constants/Urls';
import { AsyncThunkConfig } from '../../../store/store';
import {
    ISnapshotSurveysRequestProps,
    ISnapshotSurveyMetricsProps,
    Survey
} from './types';
import orderBy from 'lodash/orderBy';

export const getEnterpriseSurveys = createAsyncThunk<
    any,
    void,
    AsyncThunkConfig
>('/enterprise/surveys', async (_, { getState, dispatch }) => {
    const { enterpriseId } = getState().account;
    const payload: ISnapshotSurveysRequestProps = {
        enterpriseId
    };
    const response = await axios.post(
        `${Urls.SurveyStudioApi}surveys/enterprise`,
        payload
    );

    const sortedSurveys = orderBy(
        response.data?.surveys,
        survey => new Date(survey.meta.updatedAt),
        'desc'
    );

    const data: Survey[] = [];
    const archived: Survey[] = [];
    sortedSurveys.forEach(survey => {
        const uiElement = survey.meta.uiElements.find(
            (u: any) => u.key === 'archive'
        );
        if (uiElement?.value === 'true') {
            archived.push(survey);
        } else {
            data.push(survey);
        }
    });

    return { data, archived };
});

export const getSurveyMetrics = createAsyncThunk<any, string, AsyncThunkConfig>(
    '/survey/metric/total',
    async (surveyId, { getState, dispatch }) => {
        const { enterpriseId } = getState().account;

        const fetchMetric = async (metricName: 'TSCNT' | 'TFCNT' | 'TCR') => {
            const payload: ISnapshotSurveyMetricsProps = {
                enterpriseId,
                surveyId,
                metricName
            };

            const response = await axios.post(
                `${Urls.SurveyMetricsApi}metric/total`,
                payload
            );

            if (response.status === 400) {
                return {
                    value: -1
                };
            }

            return response.data;
        };

        const [tfcnt, tscnt, tcr] = await Promise.all([
            fetchMetric('TFCNT'),
            fetchMetric('TSCNT'),
            fetchMetric('TCR')
        ]);

        return {
            surveyId,
            tcr,
            tfcnt,
            tscnt
        };
    }
);

export const sendReminder = createAsyncThunk<any, string, AsyncThunkConfig>(
    'surveys/enterprise/reminders/create/id',
    async (snapshotId, { getState, dispatch }) => {
        const { enterpriseId } = getState().account;

        const response = await axios.put(
            `${Urls.SurveyStudioApi}surveys/enterprise/reminders/create/id`,
            {
                snapshotId,
                enterpriseId
            }
        );

        return (
            getState().snapshotSurveys.list.data.find(
                d => d.surveyId === snapshotId
            )?.title ?? ''
        );
    }
);

import { useContext, forwardRef, PropsWithChildren } from 'react';
import { GenericTableContext } from './index';
import classNames from 'classnames';

const Graph = ({ children }: PropsWithChildren) => {
    const { tableData, theme, status } = useContext(GenericTableContext);

    return (
        <div
            className={classNames('generic-table', `generic-table--${theme}`, {
                [`generic-table--${status}`]: status
            })}
            style={
                {
                    '--segments': tableData.configuration.segments,
                    '--columns': tableData.configuration.columns
                } as React.CSSProperties
            }
        >
            {children}
        </div>
    );
};

const TableHead = ({ children }: PropsWithChildren) => {
    return <thead>{children}</thead>;
};

const TableBody = ({ children }: PropsWithChildren) => {
    return <tbody>{children}</tbody>;
};

interface ITableRowProps {
    segmentRow?: boolean;
    children?: React.ReactNode;
}

const TableRow = (props: ITableRowProps) => {
    return (
        <tr
            className={classNames('generic-table__row', {
                'generic-table__row--segments': props.segmentRow
            })}
        >
            {props.children}
        </tr>
    );
};

const TableHeadCell = ({
    isActive,
    isHovered,
    onMouseEnter,
    onMouseLeave,
    onClick,
    color,
    children
}: any) => {
    return (
        <th
            className="generic-table__head-cell"
            style={
                {
                    '--color': color
                } as React.CSSProperties
            }
            data-isactive={isActive}
            data-ishovered={isHovered}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
        >
            {children}
        </th>
    );
};

const TableHeadSubCell = ({ isActive, isHovered, children }: any) => {
    return (
        <th
            data-isactive={isActive}
            data-ishovered={isHovered}
            className="generic-table__head-sub-cell"
        >
            {children}
        </th>
    );
};

interface ITableBodyCellProps {
    onMouseEnter?: (
        event: React.MouseEvent<HTMLTableCellElement, MouseEvent>
    ) => void;
    onMouseLeave?: (
        event: React.MouseEvent<HTMLTableCellElement, MouseEvent>
    ) => void;
    onClick?: (
        event: React.MouseEvent<HTMLTableCellElement, MouseEvent>
    ) => void;
    color?: string;
    children?: React.ReactNode;
    isHovered?: boolean;
    isActive?: boolean;
}

const TableBodyCell = (props: ITableBodyCellProps): any => {
    const {
        color,
        onMouseEnter,
        onMouseLeave,
        onClick,
        isHovered,
        isActive,
        children
    } = props;
    return (
        <td
            className="generic-table__body-cell"
            style={
                {
                    '--color': color
                } as React.CSSProperties
            }
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            onClick={onClick}
            data-ishovered={isHovered}
            data-isactive={isActive}
        >
            {children}
        </td>
    );
};

const LeftTable = ({ children }: PropsWithChildren): any => {
    return <table className="generic-table__left-table">{children}</table>;
};

interface Props {
    children?: React.ReactNode;
}

type Ref = HTMLDivElement;

const RightTable = forwardRef<Ref, Props>((props, ref) => {
    return (
        <div ref={ref} className="generic-table__right-table">
            <table>{props.children}</table>
        </div>
    );
});

Graph.LeftTable = LeftTable;
Graph.TableHead = TableHead;
Graph.TableBody = TableBody;
Graph.TableRow = TableRow;
Graph.TableHeadCell = TableHeadCell;
Graph.TableBodyCell = TableBodyCell;
Graph.RightTable = RightTable;
Graph.TableHeadSubCell = TableHeadSubCell;

export default Graph;

import Stack from '../../../../../common/Stack';
import ValidCheck from '../../../../../icons/ValidCheck';

const StepIndicator = ({
    validated,
    number,
    seeminglyDisabled
}: {
    validated: boolean;
    number: number;
    seeminglyDisabled: boolean;
}) => {
    if (validated) {
        return (
            <Stack
                justifyContent="center"
                style={{
                    color: 'var(--color-graph-1)',
                    opacity: seeminglyDisabled ? 0.5 : 1
                }}
            >
                <ValidCheck width={24} height={24} />
            </Stack>
        );
    }

    return (
        <Stack
            alignItems="center"
            justifyContent="center"
            style={{
                '--size': '2.4rem',
                width: 'var(--size)',
                height: 'var(--size)',
                borderRadius: '9999px',
                border: '1px solid white',
                opacity: seeminglyDisabled ? 0.5 : 1
            }}
        >
            <span
                className="font-micro-text"
                style={{
                    lineHeight: '1.0'
                }}
            >
                {number}
            </span>
        </Stack>
    );
};

export default StepIndicator;

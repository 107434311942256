import LottieAnimation from './LottieAnimation';
import Loader from '../lottie/graph-loader.json';

interface IEmptyProps {
    pending: boolean;
    error: string;
    noData: boolean;
    generated: boolean;
    invalidVariation: boolean;
    initialized: boolean;
    errorContent?: any;
}

const Empty = (props: IEmptyProps) => {
    const {
        pending,
        error,
        noData,
        generated,
        invalidVariation,
        initialized,
        errorContent
    } = props;

    const styles = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '20rem'
    };

    if (pending || !initialized) {
        return (
            <div style={styles}>
                <LottieAnimation
                    autoplay={true}
                    loop={true}
                    width={60}
                    height={60}
                    animation={Loader}
                />
            </div>
        );
    }

    if (error) {
        return (
            <div style={styles}>
                {errorContent ? (
                    errorContent
                ) : (
                    <span>
                        Sorry! We’ve encountered an issue displaying this graph.
                    </span>
                )}
            </div>
        );
    }

    if (noData) {
        return (
            <div style={styles}>Sorry! There is no data for this graph.</div>
        );
    }

    if (!generated) {
        return (
            <div style={styles}>
                Select a metric and one or more attributes. Then click Generate
                to create your report.
            </div>
        );
    }

    if (invalidVariation) {
        return (
            <div style={styles}>
                This report has not yet been activated by your company.
                <br />
                <a href="mailto: support@itsdandi.com" target="__blank">
                    Contact
                </a>
                your Dandi Customer Success Representative to turn it on.
            </div>
        );
    }

    return null;
};

export default Empty;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { sendResetEmail } from './actions';
import validator from 'email-validator';
import Button from '../common/Button';
import TextField from '../common/TextField';

function ForgotPassword({ onReset }) {
    const [email, setEmail] = useState('');

    function handleReset(e) {
        e.preventDefault();
        onReset(email);
    }

    return (
        <div className="forgot-password">
            <div className="forgot-password__title">Reset password</div>
            <div className="forgot-password__form">
                <form onSubmit={e => e.preventDefault()}>
                    <TextField
                        placeholder="Enter email"
                        onChange={e => setEmail(e.target.value)}
                        value={email}
                        classes={['mb16']}
                    />
                    <div className="forgot-password__button">
                        <Button
                            large
                            disabled={!validator.validate(email)}
                            onClick={handleReset}
                        >
                            Reset password{' '}
                        </Button>
                    </div>
                </form>
            </div>
        </div>
    );
}

ForgotPassword.propTypes = {
    onReset: PropTypes.func
};

function mapStateToProps() {
    return {};
}

const dispatchProps = {
    onReset: sendResetEmail
};

export default connect(mapStateToProps, dispatchProps)(ForgotPassword);

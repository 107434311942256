import axios from 'axios';
import ActionTypes from '../constants/ActionTypes';
import Urls from '../constants/Urls';
import { GoalCategory, IGoal, IMetric, MeasureType } from './types';

export function getGoals() {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;

        dispatch({ type: ActionTypes.GetGoalsPending });

        return axios
            .post(`${Urls.GoalsApi}get`, {
                enterpriseId
            })
            .then(response => {
                dispatch({
                    type: ActionTypes.GetGoalsFulfilled,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionTypes.GetGoalsRejected,
                    payload: error
                });
            });
    };
}

export function getGoal(id: string) {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;

        dispatch({ type: ActionTypes.GetGoalPending });

        return axios
            .post(`${Urls.GoalsApi}get/id`, {
                enterpriseId,
                goalId: id
            })
            .then(response => {
                dispatch({
                    type: ActionTypes.GetGoalFulfilled,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionTypes.GetGoalRejected,
                    payload: error
                });
            });
    };
}

export function getTrackers() {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;

        dispatch({ type: ActionTypes.GetTrackersPending });

        return axios
            .post(`${Urls.GoalsApi}get`, {
                enterpriseId
            })
            .then(response => {
                dispatch({
                    type: ActionTypes.GetTrackersFulfilled,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionTypes.GetTrackersRejected,
                    payload: error
                });
            });
    };
}

export type InputGoal = Omit<
    IGoal,
    | 'goalId'
    | 'metricRegistrationRequest'
    | 'enterpriseId'
    | 'metricTrajectoryValues'
    | 'createdAt'
>;

export type InputMetric = Omit<
    IMetric,
    | 'trajectoryStatus'
    | 'enterpriseId'
    | 'metricRegistrationId'
    | 'metricStatus'
>;

export function addGoal(goal: InputGoal, metric: InputMetric, id?: string) {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;
        const { email } = getState().auth.userInfo;
        dispatch({ type: ActionTypes.AddGoalPending });

        const createdAt = new Date().toISOString();

        const reqGoal = {
            enterpriseId,
            ...goal,
            goalId: id ?? undefined,
            createdAt,
            recipientEmails: [email],
            metricRegistrationRequest: {
                enterpriseId,
                metrics: [{ enterpriseId, ...metric }]
            },
            metricTrajectoryValues: []
        };

        return axios
            .put(`${Urls.GoalsApi}save`, reqGoal)
            .then(response => {
                axios
                    .post(`${Urls.GoalsApi}get/id`, {
                        enterpriseId,
                        goalId: response.data
                    })
                    .then(response => {
                        dispatch({
                            type: ActionTypes.AddGoalFulfilled,
                            payload: response.data
                        });
                    })
                    .catch(error => {
                        dispatch({
                            type: ActionTypes.AddGoalRejected,
                            payload: error
                        });
                    });
            })
            .catch(error => {
                dispatch({
                    type: ActionTypes.AddGoalRejected,
                    payload: error
                });
            });
    };
}

export function editGoal(id: string, goal: any) {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;
        dispatch({ type: ActionTypes.EditGoalPending });

        const reqGoal = {
            enterpriseId,
            goalId: id,
            ...goal
        };

        return axios
            .put(`${Urls.GoalsApi}save`, reqGoal)
            .then(response => {
                setToEdit();
                dispatch({
                    type: ActionTypes.EditGoalFulfilled,
                    payload: { ...reqGoal, goalId: id }
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionTypes.EditGoalRejected,
                    payload: error
                });
            });
    };
}

export function deleteGoal(id: string, metricRegistrationIds?: string[]) {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;
        dispatch({ type: ActionTypes.DeleteGoalPending });

        return axios
            .put(`${Urls.GoalsApi}delete`, {
                enterpriseId,
                goalId: id,
                metricRegistrationIds: metricRegistrationIds
            })
            .then(response => {
                setToDelete();
                dispatch({
                    type: ActionTypes.DeleteGoalFulfilled,
                    payload: id
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionTypes.DeleteGoalRejected,
                    payload: error
                });
            });
    };
}

export function setToEdit(id?: string) {
    if (!id) {
        return (dispatch: any) => {
            dispatch({
                type: ActionTypes.SetGoalToEdit,
                payload: undefined
            });
        };
    }
    return (dispatch: any, getState: any) => {
        const { data: allGoals } = getState().measure.goals;
        const goal = allGoals.find((goal: any) => goal.goalId === id);

        dispatch({
            type: ActionTypes.SetGoalToEdit,
            payload: goal
        });
    };
}

export function setToDelete(id?: string) {
    if (!id) {
        return (dispatch: any) => {
            dispatch({
                type: ActionTypes.SetGoalToDelete,
                payload: undefined
            });
        };
    }

    return (dispatch: any, getState: any) => {
        const { data: allGoals } = getState().measure.goals;
        const goal = allGoals.find((goal: any) => goal.goalId === id);

        dispatch({
            type: ActionTypes.SetGoalToDelete,
            payload: goal
        });
    };
}

export function setActiveTab(tab: MeasureType) {
    return {
        type: ActionTypes.SetActiveMeasureTab,
        payload: tab
    };
}

export function openModal(tab: MeasureType) {
    switch (tab) {
        case 'goal':
            return {
                type: ActionTypes.ShowAddGoalModal,
                payload: true
            };
        case 'tracker':
            return {
                type: ActionTypes.ShowAddTrackerModal,
                payload: true
            };
    }
}

export function closeModal(tab: MeasureType) {
    setToEdit();
    switch (tab) {
        case 'goal':
            return {
                type: ActionTypes.CloseAddGoalModal,
                payload: false
            };
        case 'tracker':
            return {
                type: ActionTypes.CloseAddTrackerModal,
                payload: false
            };
    }
}

import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
    const fill = props.fill || 'currentColor';
    const width = props.width || '20';
    const height = props.height || '20';

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox="0 0 20 20"
            fill="none"
            {...props}
        >
            <path
                fill={fill}
                d="M2.917 16.667a1.2 1.2 0 0 1-.875-.375 1.2 1.2 0 0 1-.375-.875V4.583a1.2 1.2 0 0 1 .375-.875 1.2 1.2 0 0 1 .875-.375h14.166a1.2 1.2 0 0 1 .875.375c.25.25.375.542.375.875v10.834a1.2 1.2 0 0 1-.375.875 1.2 1.2 0 0 1-.875.375H2.917ZM10 10.375 2.917 5.729v9.688h14.166V5.729L10 10.375Zm0-1.25 7-4.542H3.02L10 9.125ZM2.917 5.729V4.583v10.834-9.688Z"
            />
        </svg>
    );
};
export default SvgComponent;

import React from 'react';

interface CaretDownProps {
    size?: number;
}

const CaretDown = ({ size = 6 }: CaretDownProps) => {
    const ratio = 4 / 6;
    const width = size;
    const height = size * ratio;
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 7 4"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M3.5 4L0.468911 0.25L6.53109 0.25L3.5 4Z"
                fill="currentColor"
            />
        </svg>
    );
};

export default CaretDown;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Edit from '../icons/Edit';
import Avatar from '../common/Avatar';
import AvatarTypes from '../constants/AvatarTypes';
import CommentMention from './CommentMention';
import Constants from '../constants/Constants';
import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';
import getAvatarFill from '../lib/getAvatarFill';

export default class CommentDetail extends Component {
    constructor() {
        super();
        this.state = {
            suggestions: []
        };
    }

    handleEdit() {
        this.setState({
            value: this.props.text
        });
        this.props.onEdit();
    }

    handleTextChange(value) {
        this.setState({ value });
    }

    handleCancel() {
        this.props.onCancel();
    }

    handleSend() {
        this.props.onUpdate(this.state.value);
    }

    render() {
        const { value } = this.state;
        const {
            actors,
            profilePicture,
            editable,
            firstName,
            lastName,
            email,
            date,
            text,
            updatePending,
            showEdit
        } = this.props;
        return (
            <div className="comment-detail">
                <div className="comment-detail__photo">
                    {profilePicture ? (
                        <img src={profilePicture} alt="User Photo" />
                    ) : (
                        <Avatar
                            type={AvatarTypes.type.DEFAULT}
                            colorCode={getAvatarFill(email)}
                        />
                    )}
                </div>
                {showEdit ? (
                    <div className="comment-detail__edit-area">
                        <CommentMention
                            type="edit"
                            value={value}
                            onChange={this.handleTextChange.bind(this)}
                            actors={actors}
                        />
                        <div className="comment-detail__edit-actions">
                            <Button
                                componentType={ButtonTypes.type.TERTIARY}
                                onClick={this.handleCancel.bind(this)}
                            >
                                Cancel
                            </Button>
                            <Button
                                loading={updatePending}
                                onClick={this.handleSend.bind(this)}
                            >
                                Send
                            </Button>
                        </div>
                    </div>
                ) : (
                    <div className="comment-detail__info">
                        <div className="comment-detail__name">
                            {firstName} {lastName}
                        </div>
                        <div className="comment-detail__date">
                            {date.format(Constants.CommentDateFormat)}
                        </div>
                        <div className="comment-detail__text">{text}</div>
                    </div>
                )}
                {editable && !showEdit && (
                    <div className="comment-detail__edit">
                        <Edit
                            className="btn-icon"
                            width={16}
                            onClick={this.handleEdit.bind(this)}
                        />
                    </div>
                )}
            </div>
        );
    }
}

CommentDetail.defaultProps = {
    actors: []
};

CommentDetail.propTypes = {
    actors: PropTypes.array,
    profilePicture: PropTypes.string,
    editable: PropTypes.bool,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    date: PropTypes.object,
    text: PropTypes.string,
    onEdit: PropTypes.func,
    onCancel: PropTypes.func,
    onUpdate: PropTypes.func,
    updatePending: PropTypes.bool,
    showEdit: PropTypes.bool
};

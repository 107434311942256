import React from 'react';

interface OverallProps {
    completion: number;
};

export default function Overall({ completion }: OverallProps) {
    return (
        <div className="collect-card__overall">
            <div className="collect-card__overall__title">
                Overall data completion
            </div>
            <div className="collect-card__overall__value">{completion}%</div>
        </div>
    );
}



import moment from 'moment';
import PeriodTypes from '../constants/PeriodTypes';

export default function getDate(dateStr: string, periodType: string) {
    periodType = periodType.toLowerCase();
    switch (periodType) {
        case PeriodTypes.Yearly:
            return moment.utc(dateStr).toDate();
        case PeriodTypes.Quarterly:
            return moment.utc(dateStr.substring(0, 7), 'YYYY-[0]Q').toDate();
        default:
            return moment.utc(dateStr).toDate();
    }
}

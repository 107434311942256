import { createSlice } from '@reduxjs/toolkit';
import { AdminTab, IAdminTab } from './types';
import ActionTypes from '../../constants/ActionTypes';
import { adminTabs } from '../AdminTabs';

interface IConfigState {
    activeTab: IAdminTab;
    searchText: string;
}

const initialState: IConfigState = {
    activeTab: adminTabs[0] ?? {
        key: 'users',
        name: 'Users'
    },
    searchText: ''
};

const configSlice = createSlice({
    name: 'config',
    initialState: initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = {
                key: action.payload.key,
                name: action.payload.name
            };
        },
        setSearchText: (state, action) => {
            state.searchText = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(ActionTypes.ChangeLocation, (state, _) => {
            state.searchText = '';
        });
    }
});

export const { setActiveTab, setSearchText } = configSlice.actions;

export default configSlice.reducer;

export default function resizePhoto(image: any, width: number, height: number) {
    const img = document.createElement('img');
    img.src = image;

    return new Promise((resolve, reject) => {
        img.addEventListener('load', () => {
            const scaleX = (width * 1.0) / img.width;
            const scaleY = (height * 1.0) / img.height;
            const scale = Math.max(scaleX, scaleY);
            const canvasWidth = img.width * scale;
            const canvasHeight = img.height * scale;
            const canvas = document.createElement('canvas');
            canvas.width = canvasWidth;
            canvas.height = canvasHeight;
            const ctx = canvas.getContext('2d');
            ctx!.drawImage(img, 0, 0, canvasWidth, canvasHeight);
            resolve(canvas.toDataURL('image/png'));
        });
        img.addEventListener('error', err => {
            reject(err);
        });
    });
}

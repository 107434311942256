import produce from 'immer';
import moment from 'moment';
import qs from 'qs';
import getBenchmarkData from '../../benchmark/getBenchmarkData';
import ActionTypes from '../../constants/ActionTypes';
import AnalysisTypes from '../../constants/AnalysisTypes';
import ChartTypes from '../../constants/ChartTypes';
import { getStateForGenerateReport } from '../../store/reducerUtils';
import { IExploreChartState } from './types';

const DATE_FORMAT = 'YYYY/MM/DD';

function getInitialState(): IExploreChartState {
    return {
        status: 'idle',
        lifecycle: '',
        analysisDisplayName: '',
        data: [],
        dates: [],
        chartType: ChartTypes.Bar,
        selection: [],
        subAnalysisFields: [],
        eventIds: [],
        eventsModalOpen: false,
        yAxisType: 'percentage',
        indexType: 'indexed',
        allowYAxisToggle: false,
        allowIndexToggle: false
    };
}

// create shared action types for all states
const sharedActions = (state: IExploreChartState, action: any) => {
    switch (action.type) {
        case ActionTypes.SetAnalysisName: {
            const { analysisDisplayName } = action;
            if (!state.analysisDisplayName) {
                return state;
            }
            if (analysisDisplayName !== state.analysisDisplayName) {
                return getInitialState();
            } else {
                return state;
            }
        }
        case ActionTypes.GeneratePending: {
            return {
                ...state,
                lifecycle: action.analysisName,
                data: [],
                generatePending: true,
                generateError: false,
                generateNoData: false,
                generateInvalidVariation: false
            };
        }
        case ActionTypes.GenerateInvalidVariation:
            if (!action.isSubtable) {
                return {
                    ...state,
                    generatePending: false,
                    generateInvalidVariation: true
                };
            }
            return state;
        case ActionTypes.GenerateNoData:
            const { analysisType } = action;
            if (!action.isSubtable) {
                return {
                    ...state,
                    generatePending: false,
                    generateNoData: true,
                    chartType: ChartTypes.Bar,
                    analysisType
                };
            }
            return state;
        case ActionTypes.GenerateRejected: {
            return {
                ...state,
                generatePending: false,
                generateError: true
            };
        }
        case ActionTypes.GenerateFulfilled: {
            const { eventIds, chartType } = state;
            const yAxisType = action?.yAxisType || state?.yAxisType;
            const indexType = action?.indexType || state?.indexType;

            return getStateForGenerateReport(state, {
                ...action,
                eventIds,
                chartType,
                yAxisType,
                indexType
            });
        }
        case ActionTypes.SelectCurrentTime: {
            const { currentIndex } = action;
            const { dates, analysisType, data } = state;
            let { confidence } = state;
            if (
                analysisType === AnalysisTypes.LinearRegression &&
                data.length > 1 &&
                currentIndex >= 0
            ) {
                confidence = data[1].series[currentIndex].confidenceScore;
            }

            let currentDate;

            if (currentIndex !== dates.length - 1) {
                currentDate = moment
                    .utc(dates[currentIndex])
                    .format(DATE_FORMAT);
            }
            return {
                ...state,
                currentIndex,
                currentDate,
                confidence
            };
        }
        case ActionTypes.ResetCurrentTime:
            return {
                ...state,
                currentIndex: undefined,
                currentDate: undefined,
                generatePending: true
            };
        case ActionTypes.SelectIntervalInExplore:
        case ActionTypes.SelectIntervalCountInExplore:
        case ActionTypes.SelectIntervalRangeInExplore:
            return {
                ...state,
                // currentIndex: undefined,
                currentDate: undefined
            };
        case ActionTypes.SetChartType:
            return {
                ...state,
                chartType: action.chartType
            };
        case ActionTypes.SetBenchmark:
            return {
                ...state,
                benchmark: action.benchmark,
                yAxisType: 'percentage',
                chartType: ChartTypes.Pie
            };
        case ActionTypes.RemoveBenchmark:
            return {
                ...state,
                benchmark: undefined
            };
        case ActionTypes.SetHidePie: {
            return {
                ...state,
                benchmark: {
                    ...state.benchmark,
                    hidePie: action.hidePie
                }
            };
        }
        case ActionTypes.OpenEventsModal: {
            return {
                ...state,
                eventsModalOpen: true
            };
        }
        case ActionTypes.CloseEventsModal: {
            return {
                ...state,
                eventsModalOpen: false
            };
        }
        case ActionTypes.ToggleChartEvent: {
            const { eventIds } = state;
            const { eventId } = action;

            return {
                ...state,
                eventIds: eventIds.includes(eventId)
                    ? eventIds.filter(id => id !== eventId)
                    : [...eventIds, eventId]
            };
        }
        case ActionTypes.AddAllChartEvents: {
            const { allEvents } = action;

            return {
                ...state,
                eventIds: [...allEvents.map((event: any) => event.eventId)]
            };
        }
        case ActionTypes.RemoveAllChartEvents: {
            return {
                ...state,
                eventIds: []
            };
        }
        case ActionTypes.SetChartEvents: {
            const { eventIds } = action;
            return {
                ...state,
                eventIds
            };
        }
        case ActionTypes.ChangeLocation: {
            const { location } = action.payload;
            if (location.pathname !== '/explore') {
                // clear the state when we leave explore
                return {
                    ...state,
                    status: 'idle',
                    selection: [],
                    hoveredItem: undefined,
                    data: [],
                    dates: [],
                    eventIds: [],
                    currentIndex: undefined,
                    currentDate: undefined,
                    generated: false,
                    generateError: false,
                    generateNoData: false,
                    benchmark: undefined,
                    chartType: 'Bar',
                    yAxisType: 'percentage',
                    indexType: 'indexed'
                };
            } else if (location.state) {
                const query = qs.parse(location.search, {
                    ignoreQueryPrefix: true
                });

                const { date, chartType, yAxisType, indexType, eventIds } =
                    query;

                const { benchmark } = location.state;
                const newState = {
                    ...state,
                    eventIds,
                    chartType: chartType || ChartTypes.Bar,
                    yAxisType: yAxisType || 'percentage',
                    indexType: indexType || 'indexed'
                };

                if (benchmark) {
                    newState.benchmark = benchmark;
                }
                if (date) {
                    newState.date = date as string;
                }
                return newState;
            }
            return state;
        }
        case ActionTypes.ClearFilters:
            return {
                ...state,
                status: 'idle',
                selection: [],
                hoveredItem: undefined,
                data: [],
                dates: [],
                eventIds: [],
                currentIndex: undefined,
                currentDate: undefined,
                analysisType: undefined,
                generated: false,
                generateError: false,
                generateNoData: false,
                lifecycle: '',
                chartType: 'Bar',
                yAxisType: 'percentage',
                indexType: 'indexed'
            };
        case ActionTypes.FetchBenchmarkDataPending:
            return {
                ...state,
                benchmark: {
                    ...state.benchmark,
                    pending: true,
                    error: false,
                    noData: false
                }
            };
        case ActionTypes.FetchBenchmarkDataFulfilled: {
            let attribute = 'Gender';
            if (
                state.benchmark.attributes &&
                state.benchmark.attributes.length > 0
            ) {
                attribute = state.benchmark.attributes[0];
            }
            const data = getBenchmarkData(attribute, action.data);

            return {
                ...state,
                benchmark: {
                    ...state.benchmark,
                    pending: false,
                    data
                }
            };
        }
        case ActionTypes.FetchBenchmarkDataRejected:
            return {
                ...state,
                benchmark: {
                    ...state.benchmark,
                    pending: false,
                    error: true
                }
            };
        case ActionTypes.FetchBenchmarkDataNoData:
            return {
                ...state,
                benchmark: {
                    ...state.benchmark,
                    pending: false,
                    noData: true
                }
            };

        case ActionTypes.SetYAxisType: {
            return {
                ...state,
                yAxisType: action.payload
            };
        }

        case ActionTypes.SetIndexType: {
            return {
                ...state,
                indexType: action.payload
            };
        }

        default:
            return state;
    }
};

function idleState(state: IExploreChartState, action: any) {
    switch (action.type) {
        case ActionTypes.SetActiveLabel: {
            const { label } = action;
            return produce(state, draftState => {
                draftState.status = 'activated';
                draftState.hoveredItem = label;

                const dataItem = draftState.data.find(d => d.label === label);
                if (dataItem) {
                    dataItem.active = true;
                }
            });
        }
        default:
            return sharedActions(state, action);
    }
}

function activatedState(state: IExploreChartState, action: any) {
    switch (action.type) {
        case ActionTypes.ClearFilters:
            return {
                ...state,
                status: 'idle',
                selection: [],
                hoveredItem: undefined,
                data: [],
                dates: [],
                currentIndex: undefined,
                currentDate: undefined,
                analysisType: undefined,
                generated: false,
                generateError: false,
                generateNoData: false,
                lifecycle: '',
                chartType: 'Bar',
                yAxisType: 'percentage',
                indexType: 'indexed'
            };
        case ActionTypes.SetActiveLabel: {
            const { label } = action;
            return produce(state, draftState => {
                draftState.hoveredItem = label;

                const dataItem = draftState.data.find(d => d.label === label);
                if (dataItem) {
                    dataItem.active = true;
                }
            });
        }

        case ActionTypes.ClearActiveLabel: {
            return produce(state, draftState => {
                draftState.hoveredItem = undefined;
                if (draftState.selection.length === 0) {
                    draftState.status = 'idle';
                }

                const dataItem = draftState.data.find(d => d.active);
                if (dataItem) {
                    dataItem.active = false;
                }
            });
        }
        case ActionTypes.ToggleLabel: {
            const { label } = action;
            return produce(state, draftState => {
                if (draftState.selection.includes(label)) {
                    draftState.selection = draftState.selection.filter(
                        l => l !== label
                    );
                } else {
                    draftState.selection.push(label);
                }

                const item = draftState.data.find(d => d.label === label);
                if (item) {
                    item.selected = !item.selected;
                }
            });
        }
        case ActionTypes.ToggleLockedSelection: {
            const { label } = action;
            return produce(state, draftState => {
                draftState.status = 'activatedLocked';
                draftState.selection = [label];
                draftState.hoveredItem = label;
            });
        }
        case ActionTypes.ClearSelection:
            return produce(state, draftState => {
                draftState.selection = [];
                if (draftState.hoveredItem === undefined) {
                    draftState.status = 'idle';
                }

                draftState.data.forEach(d => {
                    d.selected = false;
                });
            });

        default:
            return sharedActions(state, action);
    }
}

function activatedLockedState(state: IExploreChartState, action: any) {
    switch (action.type) {
        case ActionTypes.ClearFilters:
            return {
                ...state,
                status: 'idle',
                selection: [],
                hoveredItem: undefined,
                data: [],
                dates: [],
                currentIndex: undefined,
                currentDate: undefined,
                analysisType: undefined,
                generated: false,
                generateError: false,
                generateNoData: false,
                lifecycle: '',
                chartType: 'Bar',
                yAxisType: 'percentage',
                indexType: 'indexed'
            };
        case ActionTypes.ClearSelection:
            return produce(state, draftState => {
                draftState.selection = [];
                draftState.hoveredItem = undefined;
                draftState.status = 'idle';

                draftState.data.forEach(d => {
                    d.selected = false;
                });
            });

        case ActionTypes.ToggleLockedSelection: {
            const { label } = action;
            return produce(state, draftState => {
                if (draftState.selection.includes(label)) {
                    draftState.selection = [];
                    draftState.hoveredItem = undefined;
                    draftState.status = 'idle';
                } else {
                    draftState.selection = [label];
                    draftState.hoveredItem = label;
                }
            });
        }

        default:
            return sharedActions(state, action);
    }
}

export default function chartReducer(
    state: IExploreChartState = getInitialState(),
    action: any
): IExploreChartState {
    switch (state.status) {
        case 'idle': {
            return idleState(state, action);
        }
        case 'activated': {
            return activatedState(state, action);
        }
        case 'activatedLocked': {
            return activatedLockedState(state, action);
        }
        default: {
            return state;
        }
    }
}

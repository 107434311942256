import React, { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { scaleLinear } from 'd3-scale';
import { line, curveMonotoneX } from 'd3-shape';
import classNames from 'classnames';
import { min, max } from 'd3-array';
import { NodeGroup, Animate } from 'react-move';
import sortBy from 'lodash/sortBy';
import getInterpolator from '../../lib/getInterpolator';
import Constants from '../../constants/Constants';
import getPeriod from '../../lib/getPeriod';
import getFormattedValue from '../../lib/getFormattedValue';
import AnalysisTypes from '../../constants/AnalysisTypes';
import FontFamilies from '../../constants/FontFamilies';
import measureText from '../../lib/measureText';
import CanvasFonts from '../../constants/CanvasFonts';
import produce from 'immer';
import inlineFont from '../../constants/InlineFont';
import { track } from '../../lib/segment';
import MixPanel from '../../constants/MixPanel';
import precisionRound from '../../lib/precisionRound';
import * as Tooltip from '@radix-ui/react-tooltip';
import ChartTooltip from '../../common/ChartTooltip';
import cn from '../../lib/cn';

const AXIS_PADDING = 18;
const PADDING = 16;
const RADIUS = 4;
const ARROW_WIDTH = 8;
const ARROW_HEIGHT = 6;
const XAXIS_FONT_SIZE = 11;
const MIN_VALUE_SPACE = 17;

function getFormattedDate(date, interval, showCondensed) {
    return getPeriod(date, interval.toLowerCase(), showCondensed);
}

function LineChart(props) {
    const {
        width,
        height,
        dates,
        lifecycle,
        interval,
        currentIndex,
        analysisType,
        setActiveLabel,
        clearActiveLabel,
        toggleLabel,
        reportId,
        hasInvalidValue,
        dashboardName,
        title,
        dataProp,
        status,
        selectCurrentTime,
        setInnerChartOffsets,
        yAxisType,
        indexType,
        employeeCountHidden,
        columnProps
    } = props;
    let { data } = props;
    const bodyRef = useRef(null);
    const [hoverIndex, setHoverIndex] = useState();
    const [hoverLabel, setHoverLabel] = useState();

    const data2 = data.filter(d => !d.isEqualityIndex && !d.isAttritionIndex);
    const singleSegment = data2.length === 1;
    const isActive = status !== 'idle';
    const showIndexLine =
        isActive &&
        (analysisType === AnalysisTypes.LinearRegression ||
            (analysisType === AnalysisTypes.Index && indexType === 'indexed'));

    // Compute maxValue and valueWidth
    const { valueWidth } = useMemo(() => {
        let max = '';
        data.forEach(d => {
            const seriesItem = d.series[dates.length - 1];
            if (seriesItem) {
                const value = getFormattedValue(analysisType, [
                    seriesItem[dataProp]
                ]);
                if (value.length > max.length) {
                    max = value;
                }
            }
        });
        const maxTextSize = measureText(
            max,
            singleSegment ? CanvasFonts.Medium24 : CanvasFonts.Medium13
        );
        return { valueWidth: maxTextSize.width + 8 };
    }, [data, dates, analysisType, dataProp, singleSegment]);

    // Compute xScale and yScale
    const { xScale, yScale } = useMemo(() => {
        const minArray = data.map(d =>
            min(d.series, s => precisionRound(s[dataProp], 1))
        );
        const maxArray = data.map(d =>
            max(d.series, s => precisionRound(s[dataProp], 1))
        );
        const minValue = min(minArray);
        const maxValue = max(maxArray);

        const yAxisValues = getYAxisValues(minValue, maxValue);

        const xScale = scaleLinear()
            .domain([dates[0], dates[dates.length - 1]])
            .range([PADDING, width - PADDING - valueWidth]);

        const yScale = scaleLinear()
            .domain(yAxisValues)
            .range([height - 2 * PADDING, 2 * PADDING]);

        return { xScale, yScale };
    }, [data, dates, width, height, valueWidth]);

    // Compute valueArray
    const valueArray = useMemo(() => {
        let valueArray = [];
        data2.forEach(d => {
            const { color, active, selected, series } = d;
            const seriesItem = series[currentIndex];
            if (seriesItem) {
                const value = seriesItem[dataProp];

                const labelValue =
                    yAxisType === 'count'
                        ? seriesItem.totalCount || seriesItem.includedCount
                        : seriesItem[dataProp];

                const { date } = seriesItem;
                const valueText =
                    yAxisType === 'count'
                        ? employeeCountHidden
                            ? ''
                            : labelValue
                        : getFormattedValue(
                              indexType === 'percentage'
                                  ? AnalysisTypes.Percentage
                                  : analysisType,
                              labelValue
                          );
                const valueY = yScale(precisionRound(value, 1)) + 5;
                valueArray.push({
                    label: d.label,
                    valueText,
                    valueY,
                    date,
                    color,
                    active,
                    selected
                });
            }
        });

        valueArray = sortBy(valueArray, va => va.valueY);

        let setBackward = false;
        for (let i = 1; i < valueArray.length; i++) {
            const { valueY } = valueArray[i];
            const { valueY: prevValueY } = valueArray[i - 1];
            if (valueY - prevValueY < MIN_VALUE_SPACE) {
                valueArray[i].valueY = prevValueY + MIN_VALUE_SPACE;
                if (
                    valueArray[i].valueY >
                    height - 2 * PADDING - AXIS_PADDING
                ) {
                    setBackward = true;
                    break;
                }
            }
        }
        if (setBackward) {
            for (let i = valueArray.length - 2; i >= 0; i--) {
                const { valueY } = valueArray[i];
                const { valueY: prevValueY } = valueArray[i + 1];
                if (prevValueY - valueY < MIN_VALUE_SPACE) {
                    valueArray[i].valueY = prevValueY - MIN_VALUE_SPACE;
                }
            }
        }

        return valueArray;
    }, [
        data2,
        currentIndex,
        yAxisType,
        indexType,
        analysisType,
        employeeCountHidden
    ]);

    useEffect(() => {
        function onResize() {
            if (!bodyRef?.current) return;
            setInnerChartOffsets({
                left: 0,
                right:
                    width -
                    bodyRef.current.getBoundingClientRect().width -
                    PADDING
            });
        }

        const observer = new ResizeObserver(onResize);

        observer.observe(bodyRef.current);

        return () => {
            observer.disconnect();
        };
    }, [width]);

    function isFaded(item) {
        const isActive = status !== 'idle';
        if (isActive) {
            return !(
                item.active ||
                item.selected ||
                item.isEqualityIndex ||
                item.isAttritionIndex
            );
        }
        return false;
    }

    function getTextAnchor(index) {
        if (index === 0) {
            return 'start';
        } else if (index === dates.length - 1) {
            return 'end';
        } else {
            return 'middle';
        }
    }

    function getCursor(index) {
        return index === currentIndex ? 'undefined' : 'pointer';
    }

    function getFill(index) {
        return index === currentIndex ? '#f2f2f2' : 'rgba(242,242,242,.70)';
    }

    function getStyle(index) {
        return {
            fontSize: XAXIS_FONT_SIZE,
            fontFamily:
                index === currentIndex
                    ? FontFamilies.Medium
                    : FontFamilies.Regular
        };
    }

    function getPoints(data) {
        const x = xScale(data);
        const y = height - AXIS_PADDING - PADDING + 1;
        return `${x} ${y + ARROW_HEIGHT}, ${x - ARROW_WIDTH / 2} ${y}, ${
            x + ARROW_WIDTH / 2
        } ${y}`;
    }

    function handleTimeSelect(index, e) {
        e.stopPropagation();
        if (index !== currentIndex) {
            track(MixPanel.Events.DashboardReportLineGraphXAxisClick, {
                'Dashboard Name': dashboardName,
                'Report Name': title
            });
            setHoverIndex(undefined);
            selectCurrentTime(index);
        }
    }

    function handleToggle(label, e) {
        track(MixPanel.Events.DashboardReportLineGraphLineSegmentClick, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
        toggleLabel(label, e);
    }

    function handleMouseOverOnPoint(label, index) {
        setHoverIndex(index);
        setHoverLabel(label);
    }

    function handleMouseOutOnPoint() {
        setHoverIndex(undefined);
        setHoverLabel(undefined);
    }

    function handleClickOnPoint(_, index, e) {
        e.stopPropagation();
        if (index !== currentIndex) {
            track(MixPanel.Events.DashboardReportLineGraphXAxisClick, {
                'Dashboard Name': dashboardName,
                'Report Name': title
            });

            setHoverIndex(undefined);
            setHoverLabel(undefined);
            selectCurrentTime(index);
        }
    }

    function getYAxisValues(minValue, maxValue) {
        let dev = maxValue - minValue;
        if (dev === 0) {
            if (minValue === 0) {
                dev = 0.1;
            } else {
                dev = minValue * 0.1;
            }
        } else {
            dev = dev * 0.3;
        }
        let adjMinValue = minValue - dev;
        if (
            lifecycle !== 'Compensation' &&
            analysisType !== AnalysisTypes.LinearRegression
        ) {
            if (adjMinValue < 0) {
                adjMinValue = 0;
            }
        }
        let adjMaxValue = maxValue + dev;
        if (yAxisType === 'percentage' || indexType === 'percentage') {
            if (adjMaxValue > 100) {
                adjMaxValue = 100;
            }
        }

        return [adjMinValue, adjMaxValue];
    }

    if (hasInvalidValue) {
        data = produce(data, draftState => {
            draftState.forEach(ds => {
                for (let i = ds.series.length - 1; i >= 0; i--) {
                    if (ds.series[i][dataProp] === Constants.InvalidValue) {
                        ds.series.splice(i, 1);
                    }
                }
            });
        });
    }

    const minArray = [];
    const maxArray = [];
    data.forEach(d => {
        minArray.push(min(d.series, s => precisionRound(s[dataProp], 1)));
        maxArray.push(max(d.series, s => precisionRound(s[dataProp], 1)));
    });
    const minValue = min(minArray);
    const maxValue = max(maxArray);

    const { showCondensed, indexes } = useMemo(() => {
        const bandwidthCalc =
            (width - 2 * PADDING - valueWidth) / (dates.length - 1);
        let intervalLength =
            measureText(
                getFormattedDate(dates[0], interval),
                CanvasFonts.Medium11
            ).width *
                1.5 +
            PADDING;
        const skipCount = Math.ceil(intervalLength / bandwidthCalc) - 1;

        const indexes = dates
            .map((_, i) => i)
            .filter((_, i) => i % (skipCount + 1) === 0);

        const showCondensed = skipCount > 0;

        return { showCondensed, indexes };
    }, [width, valueWidth, dates, interval, currentIndex]);

    const avgValue = (minValue + maxValue) / 2;

    const lineFn = line()
        .x(d => xScale(d.date))
        .y(d => yScale(precisionRound(d[dataProp], 1)))
        .curve(curveMonotoneX);

    return (
        <Tooltip.Provider>
            <svg
                className={cn('linechart')}
                data-export-type="linechart"
                width={width}
                height={height}
                id={reportId}
            >
                <defs>
                    <style type="text/css">{inlineFont}</style>
                </defs>
                {analysisType === AnalysisTypes.LinearRegression && (
                    <rect
                        className="linechart__linear-regression-background"
                        x={0}
                        y={yScale(0)}
                        width={width}
                        height={height - yScale(0)}
                    />
                )}
                {analysisType === AnalysisTypes.Index &&
                    indexType === 'indexed' && (
                        <rect
                            className="linechart__index-background"
                            x={0}
                            y={yScale(1)}
                            width={width}
                            height={height - yScale(1)}
                        />
                    )}
                <clipPath id={`${reportId}_main`}>
                    <rect
                        x={0}
                        y={0}
                        width={xScale(dates[currentIndex])}
                        height={height}
                    />
                </clipPath>
                <clipPath id={`${reportId}_fade`}>
                    <rect
                        x={xScale(dates[currentIndex])}
                        y={0}
                        width={width - xScale(dates[currentIndex])}
                        height={height}
                    />
                </clipPath>

                <NodeGroup
                    keyAccessor={d => d}
                    data={dates}
                    start={() => ({
                        opacity: 0
                    })}
                    enter={() => ({
                        opacity: [1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    update={() => ({
                        opacity: [0, 1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    interpolation={getInterpolator}
                >
                    {nodes => (
                        <g className="linechart__body" ref={bodyRef}>
                            {nodes.map(({ state, data, key }, index) => (
                                <Fragment key={key}>
                                    <line
                                        className={classNames(
                                            'linechart__body__outline',
                                            {
                                                'linechart__body__outline--selected':
                                                    currentIndex === index ||
                                                    hoverIndex === index
                                            }
                                        )}
                                        x1={xScale(data)}
                                        y1={PADDING}
                                        x2={xScale(data)}
                                        y2={height - AXIS_PADDING}
                                        opacity={state.opacity}
                                    />
                                    <line
                                        x1={xScale(data) + 2}
                                        y1={PADDING}
                                        x2={xScale(data)}
                                        y2={height - AXIS_PADDING}
                                        stroke="transparent"
                                        strokeWidth={5}
                                        cursor="pointer"
                                        onMouseOver={setHoverIndex.bind(
                                            null,
                                            index
                                        )}
                                        onMouseOut={setHoverIndex.bind(
                                            null,
                                            undefined
                                        )}
                                        onClick={handleTimeSelect.bind(
                                            null,
                                            index
                                        )}
                                    />
                                </Fragment>
                            ))}
                        </g>
                    )}
                </NodeGroup>
                <NodeGroup
                    keyAccessor={d => d}
                    data={dates}
                    start={() => ({
                        opacity: 0
                    })}
                    enter={() => ({
                        opacity: [1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    update={() => ({
                        opacity: [0, 1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    interpolation={getInterpolator}
                >
                    {nodes => (
                        <g
                            className="linechart__x-labels"
                            style={{
                                display: 'none'
                            }}
                        >
                            {nodes.map(({ state, data, key }, index) => {
                                if (
                                    index === currentIndex ||
                                    index === hoverIndex ||
                                    !indexes.includes(index)
                                ) {
                                    return null;
                                }
                                const text = getFormattedDate(
                                    data,
                                    interval,
                                    showCondensed
                                );
                                const x = xScale(data);

                                return (
                                    <text
                                        className={classNames(
                                            'linechart__x-labels__text',
                                            {
                                                'linechart__x-labels__text--selected':
                                                    currentIndex === index
                                            }
                                        )}
                                        key={key}
                                        x={x}
                                        y={
                                            height -
                                            PADDING -
                                            AXIS_PADDING +
                                            ARROW_HEIGHT +
                                            14
                                        }
                                        textAnchor={getTextAnchor(index)}
                                        opacity={state.opacity}
                                    >
                                        {text}
                                    </text>
                                );
                            })}
                        </g>
                    )}
                </NodeGroup>
                <NodeGroup
                    keyAccessor={d => d}
                    data={dates}
                    start={() => ({
                        opacity: 0
                    })}
                    enter={() => ({
                        opacity: [1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    update={() => ({
                        opacity: [0, 1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    interpolation={getInterpolator}
                >
                    {nodes => (
                        <g
                            className="linechart__x-labels"
                            style={{
                                display: 'none'
                            }}
                        >
                            {nodes.map(({ state, data, key }, index) => {
                                const text = getFormattedDate(
                                    data,
                                    interval,
                                    showCondensed
                                );
                                const x = xScale(data);
                                let width = 12;
                                let xRect = x - width / 2;
                                if (
                                    index === currentIndex ||
                                    index === hoverIndex ||
                                    indexes.includes(index)
                                ) {
                                    width =
                                        measureText(text, CanvasFonts.Regular12)
                                            .width + 16;
                                    xRect = x - width / 2;
                                    if (index === 0) {
                                        xRect = x - 8;
                                    }
                                    if (index === dates.length - 1) {
                                        xRect = x - width + 8;
                                    }
                                }
                                return (
                                    <Fragment key={key}>
                                        {index === currentIndex && (
                                            <rect
                                                className="linechart__x-labels__rect"
                                                x={xRect}
                                                y={
                                                    height -
                                                    PADDING -
                                                    AXIS_PADDING +
                                                    ARROW_HEIGHT +
                                                    1
                                                }
                                                width={width}
                                                height={19}
                                                rx={8}
                                                opacity={state.opacity}
                                            />
                                        )}
                                        {index === hoverIndex && (
                                            <rect
                                                className="linechart__x-labels__rect--selected"
                                                x={xRect}
                                                y={
                                                    height -
                                                    PADDING -
                                                    AXIS_PADDING +
                                                    ARROW_HEIGHT +
                                                    1
                                                }
                                                width={width}
                                                height={19}
                                                rx={8}
                                                opacity={state.opacity}
                                            />
                                        )}
                                        {(index === currentIndex ||
                                            index === hoverIndex) && (
                                            <text
                                                className={classNames(
                                                    'linechart__x-labels__text',
                                                    {
                                                        'linechart__x-labels__text--selected':
                                                            currentIndex ===
                                                            index
                                                    }
                                                )}
                                                x={x}
                                                y={
                                                    height -
                                                    PADDING -
                                                    AXIS_PADDING +
                                                    ARROW_HEIGHT +
                                                    14
                                                }
                                                textAnchor={getTextAnchor(
                                                    index
                                                )}
                                                opacity={state.opacity}
                                            >
                                                {text}
                                            </text>
                                        )}
                                        {index === currentIndex && (
                                            <polygon
                                                className="linechart__x-labels__arrow"
                                                points={getPoints(data)}
                                                opacity={state.opacity}
                                            />
                                        )}
                                    </Fragment>
                                );
                            })}
                        </g>
                    )}
                </NodeGroup>
                <NodeGroup
                    keyAccessor={d => d}
                    data={dates}
                    start={() => ({
                        opacity: 0
                    })}
                    enter={() => ({
                        opacity: [1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    update={() => ({
                        opacity: [0, 1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    interpolation={getInterpolator}
                >
                    {nodes => (
                        <g
                            className="linechart__x-labels"
                            style={{
                                display: 'none'
                            }}
                        >
                            {nodes.map(({ state, data, key }, index) => {
                                if (index !== hoverIndex) {
                                    return null;
                                }
                                const text = getFormattedDate(
                                    data,
                                    interval,
                                    showCondensed
                                );
                                const x = xScale(data);
                                const width =
                                    measureText(text, CanvasFonts.Regular11)
                                        .width + 16;
                                let xRect = x - width / 2;
                                if (index === 0) {
                                    xRect = x - 8;
                                }
                                if (index === dates.length - 1) {
                                    xRect = x - width + 8;
                                }

                                return (
                                    <Fragment key={key}>
                                        <rect
                                            className="linechart__x-labels__hover-rect"
                                            x={xRect}
                                            y={
                                                height -
                                                PADDING -
                                                AXIS_PADDING +
                                                ARROW_HEIGHT +
                                                1
                                            }
                                            width={width}
                                            height={19}
                                            rx={8}
                                            opacity={state.opacity}
                                        />
                                        <text
                                            className="linechart__x-labels__hover-text"
                                            x={x}
                                            y={
                                                height -
                                                PADDING -
                                                AXIS_PADDING +
                                                ARROW_HEIGHT +
                                                14
                                            }
                                            textAnchor={getTextAnchor(index)}
                                            opacity={state.opacity}
                                            cursor={getCursor(index)}
                                            fill={getFill(index)}
                                            style={getStyle(index)}
                                        >
                                            {text}
                                        </text>
                                    </Fragment>
                                );
                            })}
                        </g>
                    )}
                </NodeGroup>
                <NodeGroup
                    keyAccessor={d => d}
                    data={dates}
                    start={() => ({
                        opacity: 0
                    })}
                    enter={() => ({
                        opacity: [1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    update={() => ({
                        opacity: [0, 1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    interpolation={getInterpolator}
                >
                    {nodes => (
                        <g className="linechart__x-labels">
                            {nodes.map(({ state, data, key }, index) => {
                                const text = getFormattedDate(
                                    data,
                                    interval,
                                    showCondensed
                                );
                                const x = xScale(data);
                                let width = 12;
                                let xRect = x - width / 2;
                                if (
                                    index === currentIndex ||
                                    index === hoverIndex ||
                                    indexes.includes(index)
                                ) {
                                    width =
                                        measureText(text, CanvasFonts.Regular11)
                                            .width + 16;
                                    xRect = x - width / 2;
                                    if (index === 0) {
                                        xRect = x - 8;
                                    }
                                    if (index === dates.length - 1) {
                                        xRect = x - width + 8;
                                    }
                                }
                                return (
                                    <Fragment key={key}>
                                        {index !== currentIndex && (
                                            <rect
                                                className="linechart__x-labels__rect--transparent"
                                                x={xRect}
                                                y={
                                                    height -
                                                    PADDING -
                                                    AXIS_PADDING +
                                                    ARROW_HEIGHT +
                                                    1
                                                }
                                                width={width}
                                                height={19}
                                                rx={8}
                                                opacity={state.opacity}
                                                cursor="pointer"
                                                onMouseOver={setHoverIndex.bind(
                                                    null,
                                                    index
                                                )}
                                                onMouseOut={setHoverIndex.bind(
                                                    null,
                                                    undefined
                                                )}
                                                onClick={handleTimeSelect.bind(
                                                    null,
                                                    index
                                                )}
                                            />
                                        )}
                                    </Fragment>
                                );
                            })}
                        </g>
                    )}
                </NodeGroup>
                <Animate
                    show={showIndexLine}
                    start={() => ({
                        opacity: 0
                    })}
                    enter={() => ({
                        opacity: [1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    update={() => ({
                        opacity: [1],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                    leave={() => ({
                        opacity: [0],
                        timing: {
                            duration: Constants.AnimationDuration,
                            ease: Constants.EasingFn
                        }
                    })}
                >
                    {state => {
                        const indexText =
                            analysisType === AnalysisTypes.LinearRegression
                                ? 'Index (0%)'
                                : 'Index (1x)';
                        const yIndex =
                            analysisType === AnalysisTypes.LinearRegression
                                ? yScale(0)
                                : yScale(1);
                        const xIndex =
                            PADDING +
                            measureText(indexText, CanvasFonts.Medium13).width +
                            8;
                        return (
                            <Fragment>
                                <text
                                    className="linechart__index-text"
                                    x={PADDING}
                                    y={yIndex + 3}
                                    opacity={state.opacity}
                                >
                                    {indexText}
                                </text>
                                <line
                                    className="linechart__index-line"
                                    x1={xIndex}
                                    y1={yIndex}
                                    x2={width - PADDING - valueWidth}
                                    y2={yIndex}
                                    opacity={state.opacity}
                                />
                            </Fragment>
                        );
                    }}
                </Animate>
                <NodeGroup
                    keyAccessor={d => d.label}
                    data={data}
                    start={d => {
                        const initial = d.series.map(({ date }) => ({
                            [dataProp]: avgValue,
                            date
                        }));
                        return {
                            opacity: 1,
                            series: initial
                        };
                    }}
                    enter={d => {
                        return {
                            series: [d.series],
                            timing: {
                                duration: Constants.AnimationDuration,
                                ease: Constants.EasingFn
                            }
                        };
                    }}
                    update={d => {
                        return {
                            series: [d.series],
                            opacity: [isFaded(d) ? 0.05 : 1],
                            timing: {
                                duration: Constants.AnimationDuration,
                                ease: Constants.EasingFn
                            }
                        };
                    }}
                    interpolation={getInterpolator}
                >
                    {nodes => (
                        <g className="linechart__lines">
                            {nodes.map(
                                ({ state, data, key }) =>
                                    !data.isEqualityIndex &&
                                    !data.isAttritionIndex && (
                                        <g data-label="line" key={key}>
                                            <path
                                                className="linechart__lines__line"
                                                d={lineFn(state.series)}
                                                strokeOpacity={state.opacity}
                                                stroke={data.color}
                                                clipPath={`url(#${reportId}_main)`}
                                                onMouseOver={setActiveLabel.bind(
                                                    null,
                                                    data.label
                                                )}
                                                onMouseOut={clearActiveLabel.bind(
                                                    null,
                                                    data.label
                                                )}
                                                onClick={handleToggle.bind(
                                                    null,
                                                    data.label
                                                )}
                                                data-label="line-primary"
                                            />
                                            <path
                                                className="linechart__lines__line"
                                                d={lineFn(state.series)}
                                                strokeOpacity={0.05}
                                                stroke={data.color}
                                                clipPath={`url(#${reportId}_fade)`}
                                                data-label="line-secondary"
                                            />
                                        </g>
                                    )
                            )}
                        </g>
                    )}
                </NodeGroup>
                <NodeGroup
                    keyAccessor={d => d.label}
                    data={data}
                    start={d => {
                        const initial = d.series.map(({ date }) => ({
                            [dataProp]: avgValue,
                            date
                        }));
                        return {
                            opacityCircle: 0,
                            opacityText: 0,
                            series: initial
                        };
                    }}
                    enter={d => {
                        return {
                            opacityCircle: [1],
                            opacityText: [1],
                            series: [d.series],
                            timing: {
                                duration: Constants.AnimationDuration,
                                ease: Constants.EasingFn
                            }
                        };
                    }}
                    update={d => {
                        return {
                            opacityCircle: isFaded(d) ? [0.05] : [1],
                            opacityText: isFaded(d) ? [0.24] : [1],
                            timing: {
                                duration: Constants.AnimationDuration,
                                ease: Constants.EasingFn
                            }
                        };
                    }}
                    interpolation={getInterpolator}
                >
                    {nodes => (
                        <g className="linechart__circles">
                            {nodes.map(({ state, data, key }) => {
                                if (
                                    data.isEqualityIndex ||
                                    data.isAttritionIndex
                                ) {
                                    return null;
                                }
                                const currentDate = getFormattedDate(
                                    dates[currentIndex],
                                    interval
                                );
                                const item = state.series.find(
                                    s =>
                                        getFormattedDate(s.date, interval) ===
                                        currentDate
                                );
                                let anotherItem;
                                if (currentIndex !== dates.length - 1) {
                                    const lastDate = getFormattedDate(
                                        dates[dates.length - 1],
                                        interval
                                    );
                                    anotherItem = state.series.find(
                                        s =>
                                            getFormattedDate(
                                                s.date,
                                                interval
                                            ) === lastDate
                                    );
                                }

                                return (
                                    <Fragment key={key}>
                                        {item && (
                                            <g data-label="data-point-current-index">
                                                <circle
                                                    className="linechart__circles__circle"
                                                    stroke="var(--color-shade-h4)"
                                                    fill="var(--color-shade-h4)"
                                                    style={{
                                                        pointerEvents: 'none'
                                                    }}
                                                    cx={xScale(item.date)}
                                                    cy={yScale(
                                                        precisionRound(
                                                            item[dataProp],
                                                            1
                                                        )
                                                    )}
                                                    r={RADIUS}
                                                    data-label="data-point-mask"
                                                />
                                                <circle
                                                    className="linechart__circles__circle"
                                                    fill={data.color}
                                                    cx={xScale(item.date)}
                                                    cy={yScale(
                                                        precisionRound(
                                                            item[dataProp],
                                                            1
                                                        )
                                                    )}
                                                    r={RADIUS}
                                                    // cursor="pointer"
                                                    opacity={
                                                        state.opacityCircle
                                                    }
                                                    style={{
                                                        pointerEvents: 'none'
                                                    }}
                                                    data-label="data-point-primary"
                                                />
                                                <Tooltip.Root
                                                    delayDuration={0}
                                                    open={
                                                        data.label ===
                                                            hoverLabel &&
                                                        hoverIndex ===
                                                            currentIndex
                                                    }
                                                >
                                                    <Tooltip.Trigger asChild>
                                                        <circle
                                                            className="linechart__circles__circle"
                                                            fill={data.color}
                                                            cx={xScale(
                                                                item.date
                                                            )}
                                                            cy={yScale(
                                                                precisionRound(
                                                                    item[
                                                                        dataProp
                                                                    ],
                                                                    1
                                                                )
                                                            )}
                                                            r={RADIUS * 2}
                                                            cursor="pointer"
                                                            opacity={0}
                                                            onMouseOver={() => {
                                                                handleMouseOverOnPoint(
                                                                    data.label,
                                                                    currentIndex
                                                                );
                                                                setActiveLabel(
                                                                    data.label
                                                                );
                                                            }}
                                                            onMouseOut={() => {
                                                                handleMouseOutOnPoint();
                                                                clearActiveLabel();
                                                            }}
                                                            onClick={() => {
                                                                handleToggle(
                                                                    data.label
                                                                );
                                                            }}
                                                            data-label="data-point-hover-trigger"
                                                        />
                                                    </Tooltip.Trigger>
                                                    <Tooltip.Portal>
                                                        <Tooltip.Content
                                                            side="bottom"
                                                            sideOffset={16}
                                                        >
                                                            <ChartTooltip
                                                                dataPoint={data}
                                                                seriesItem={
                                                                    item
                                                                }
                                                                columnProps={
                                                                    columnProps
                                                                }
                                                                interval={
                                                                    interval
                                                                }
                                                            />
                                                        </Tooltip.Content>
                                                    </Tooltip.Portal>
                                                </Tooltip.Root>
                                            </g>
                                        )}
                                        {anotherItem && (
                                            <g data-label="data-point-another-item">
                                                <circle
                                                    className="linechart__circles__circle"
                                                    fill="var(--color-shade-h4)"
                                                    cx={xScale(
                                                        anotherItem.date
                                                    )}
                                                    cy={yScale(
                                                        precisionRound(
                                                            anotherItem[
                                                                dataProp
                                                            ],
                                                            1
                                                        )
                                                    )}
                                                    r={RADIUS}
                                                />
                                                <circle
                                                    className="linechart__circles__circle"
                                                    stroke={data.color}
                                                    strokeWidth={1}
                                                    fill="var(--color-shade-h4)"
                                                    cx={xScale(
                                                        anotherItem.date
                                                    )}
                                                    cy={yScale(
                                                        precisionRound(
                                                            anotherItem[
                                                                dataProp
                                                            ],
                                                            1
                                                        )
                                                    )}
                                                    strokeOpacity={0.05}
                                                    r={RADIUS}
                                                    cursor="pointer"
                                                    onClick={handleToggle.bind(
                                                        null,
                                                        data.label
                                                    )}
                                                />
                                            </g>
                                        )}
                                        {state.series.map((s, i) =>
                                            i !== currentIndex ? (
                                                <Tooltip.Root
                                                    open={
                                                        data.label ===
                                                            hoverLabel &&
                                                        hoverIndex === i
                                                    }
                                                    key={i}
                                                >
                                                    <Tooltip.Trigger asChild>
                                                        <g data-label="data-point-tooltip-trigger">
                                                            <circle
                                                                className="linechart__circles__circle"
                                                                data-label="tooltip-item-big"
                                                                stroke={
                                                                    data.color
                                                                }
                                                                strokeWidth={1}
                                                                fill="var(--color-shade-h4)"
                                                                cx={xScale(
                                                                    s.date
                                                                )}
                                                                cy={yScale(
                                                                    precisionRound(
                                                                        s[
                                                                            dataProp
                                                                        ],
                                                                        1
                                                                    )
                                                                )}
                                                                r={RADIUS * 2}
                                                                cursor="pointer"
                                                                opacity={0}
                                                                onMouseOver={() => {
                                                                    handleMouseOverOnPoint(
                                                                        data.label,
                                                                        i
                                                                    );
                                                                    setActiveLabel(
                                                                        data.label
                                                                    );
                                                                }}
                                                                onMouseOut={() => {
                                                                    handleMouseOutOnPoint(
                                                                        data.label,
                                                                        i
                                                                    );
                                                                    clearActiveLabel(
                                                                        data.label
                                                                    );
                                                                }}
                                                                onClick={e =>
                                                                    handleClickOnPoint(
                                                                        data.label,
                                                                        i,
                                                                        e
                                                                    )
                                                                }
                                                            />
                                                            <circle
                                                                className="linechart__circles__circle"
                                                                data-label="tooltip-item"
                                                                stroke={
                                                                    data.color
                                                                }
                                                                strokeWidth={1}
                                                                fill="var(--color-shade-h4)"
                                                                cx={xScale(
                                                                    s.date
                                                                )}
                                                                cy={yScale(
                                                                    precisionRound(
                                                                        s[
                                                                            dataProp
                                                                        ],
                                                                        1
                                                                    )
                                                                )}
                                                                r={RADIUS}
                                                                cursor="pointer"
                                                                opacity={
                                                                    data.label ===
                                                                        hoverLabel &&
                                                                    hoverIndex ===
                                                                        i
                                                                        ? 1
                                                                        : i >
                                                                          currentIndex
                                                                        ? 0.05
                                                                        : state.opacityCircle
                                                                }
                                                                style={{
                                                                    pointerEvents:
                                                                        'none'
                                                                }}
                                                            />
                                                        </g>
                                                    </Tooltip.Trigger>
                                                    <Tooltip.Portal>
                                                        <Tooltip.Content
                                                            side="bottom"
                                                            sideOffset={16}
                                                        >
                                                            <ChartTooltip
                                                                dataPoint={data}
                                                                seriesItem={s}
                                                                columnProps={
                                                                    columnProps
                                                                }
                                                                interval={
                                                                    interval
                                                                }
                                                            />
                                                        </Tooltip.Content>
                                                    </Tooltip.Portal>
                                                </Tooltip.Root>
                                            ) : null
                                        )}
                                    </Fragment>
                                );
                            })}
                        </g>
                    )}
                </NodeGroup>
                <NodeGroup
                    keyAccessor={d => d.label}
                    data={valueArray}
                    start={() => {
                        return {
                            opacity: 0
                        };
                    }}
                    enter={() => {
                        return {
                            opacity: [1],
                            timing: {
                                duration: Constants.AnimationDuration,
                                ease: Constants.EasingFn
                            }
                        };
                    }}
                    update={d => {
                        return {
                            opacity: isFaded(d) ? [0.24] : [1],
                            timing: {
                                duration: Constants.AnimationDuration,
                                ease: Constants.EasingFn
                            }
                        };
                    }}
                    interpolation={getInterpolator}
                >
                    {nodes => (
                        <g className="linechart__values">
                            {nodes.map(({ state, data, key }) => {
                                return (
                                    <text
                                        className="linechart__values__text"
                                        key={key}
                                        x={xScale(data.date) + 8}
                                        y={data.valueY}
                                        textAnchor="start"
                                        opacity={state.opacity}
                                        fill={data.color}
                                        cursor="pointer"
                                        style={{
                                            fontFamily: FontFamilies.Medium,
                                            fontSize: singleSegment ? 24 : 13
                                        }}
                                        onMouseOver={setActiveLabel.bind(
                                            null,
                                            data.label
                                        )}
                                        onMouseOut={clearActiveLabel.bind(
                                            null,
                                            data.label
                                        )}
                                        onClick={handleToggle.bind(
                                            null,
                                            data.label
                                        )}
                                    >
                                        {data.valueText}
                                    </text>
                                );
                            })}
                        </g>
                    )}
                </NodeGroup>
                <line
                    className={classNames('linechart__outline')}
                    x1={PADDING}
                    y1={height - AXIS_PADDING - PADDING}
                    x2={width - PADDING - valueWidth}
                    y2={height - AXIS_PADDING - PADDING}
                />
            </svg>
        </Tooltip.Provider>
    );
}

LineChart.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number || PropTypes.string,
    data: PropTypes.array,
    dates: PropTypes.array,
    loading: PropTypes.bool,
    interval: PropTypes.string,
    lifecycle: PropTypes.string,
    analysisType: PropTypes.string,
    currentIndex: PropTypes.number,
    setActiveLabel: PropTypes.func,
    clearActiveLabel: PropTypes.func,
    selectCurrentTime: PropTypes.func,
    toggleLabel: PropTypes.func,
    editMode: PropTypes.bool,
    reportId: PropTypes.string,
    hasInvalidValue: PropTypes.bool,
    dashboardName: PropTypes.string,
    title: PropTypes.string,
    dataProp: PropTypes.string,
    status: PropTypes.string,
    setInnerChartOffsets: PropTypes.func,
    hoveredItem: PropTypes.any,
    selection: PropTypes.any,
    yAxisType: PropTypes.string,
    indexType: PropTypes.string,
    employeeCountHidden: PropTypes.bool,
    columnProps: PropTypes.array,
    isResizing: PropTypes.bool
};

LineChart.defaultProps = {
    selectCurrentTime: () => {},
    isResizing: false
};

export default LineChart;

export enum MessageTypes {
    user = 'user',
    assistant = 'assistant',
    actions = 'actions'
}

export enum MessageComponentTypes {
    div = 'div',
    component = 'component'
}

export interface Message {
    id: string;
    componentType: MessageComponentTypes;
    children: any;
    component?: any;
    props: {
        type: MessageTypes;
        showAvatar?: boolean;
        [key: string]: any;
    };
}

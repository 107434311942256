import { useRef, useEffect, useReducer } from 'react';
import { IChartEventsProps } from '../../lib/getChartDeclaration';
import classNames from 'classnames';
import { scaleLinear } from 'd3-scale';
import stateMachine from './stateMachine';

const Events = (props: IChartEventsProps) => {
    const titleRef = useRef(null);
    const [{ status, hoverId, selection }, dispatch] = useReducer(
        stateMachine,
        {
            hoverId: null,
            selection: [],
            status: 'idle'
        }
    );

    const { events, dates, innerChartOffsets, interval, analysisType } = props;
    const xScale = scaleLinear()
        .domain([dates[0], dates[dates.length - 1]])
        .range([0, 100]);

    function getFirstOf(date: string, interval: string) {
        const dateConverted = new Date(date);
        switch (interval) {
            case 'Month':
                return new Date(
                    dateConverted.getFullYear(),
                    dateConverted.getMonth(),
                    1
                );
            case 'Quarter':
                const month = dateConverted.getMonth();
                return new Date(
                    dateConverted.getFullYear(),
                    Math.floor(month / 3) * 3,
                    1
                );
            case 'Year':
                return new Date(dateConverted.getFullYear(), 0, 1);
            default:
                return dateConverted;
        }
    }

    function getCorrectDate(
        event: IChartEventsProps['events'][0],
        interval: string = 'Month',
        start?: boolean
    ) {
        if (start) {
            return getFirstOf(event.dateStart, interval);
        } else {
            return getFirstOf(event.dateEnd, interval);
        }
    }

    function isEventHidden(event: IChartEventsProps['events'][0]) {
        const date = getCorrectDate(event, interval);

        return xScale(date) < 0;
    }

    function getLabelPosition(
        event: IChartEventsProps['events'][0]
    ): 'left' | 'right' {
        if (
            xScale(getCorrectDate(event, interval)) > 100 ||
            (xScale(getCorrectDate(event, interval)) >= 70 &&
                xScale(getCorrectDate(event, interval, true)) > 25)
        ) {
            return 'left';
        }

        return 'right';
    }
    function isSinglePoint(event: any) {
        return event.dateStart === event.dateEnd;
    }

    return (
        <div
            className={classNames(
                'chart-events',
                `chart-events--${analysisType
                    .toLowerCase()
                    .split(' ')
                    .join('-')}`
            )}
            style={
                {
                    '--right-offset': innerChartOffsets.right + 'px'
                } as React.CSSProperties
            }
            data-status={status}
            onClick={() => {
                dispatch({
                    type: 'onClear'
                });
            }}
        >
            <div className="chart-events__inner">
                {events.map((event, i) => (
                    <div
                        key={i}
                        className="chart-events__row"
                        data-hidden={isEventHidden(event)}
                    >
                        <div
                            className="chart-events__event"
                            data-label-position={getLabelPosition(event)}
                            data-single-point={isSinglePoint(event)}
                            onMouseEnter={() => {
                                dispatch({
                                    type: 'onHover',
                                    payload: event.eventId
                                });
                            }}
                            onMouseLeave={() => {
                                dispatch({
                                    type: 'onLeave'
                                });
                            }}
                            onClick={e => {
                                e.stopPropagation();
                                dispatch({
                                    type: 'onToggle',
                                    payload: event.eventId
                                });
                            }}
                            data-active={
                                hoverId === event.eventId ||
                                selection.includes(event.eventId)
                            }
                            style={
                                {
                                    '--xStart':
                                        xScale(
                                            getCorrectDate(
                                                event,
                                                interval,
                                                true
                                            )
                                        ) + '%',
                                    '--xEnd':
                                        xScale(
                                            getCorrectDate(
                                                event,
                                                interval,
                                                false
                                            )
                                        ) + '%'
                                } as React.CSSProperties
                            }
                        >
                            <span
                                className="chart-events__title"
                                ref={titleRef}
                            >
                                {event.name}
                            </span>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Events;

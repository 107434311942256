import React, { useEffect } from 'react';
import TextField from '../../../common/TextField';
import { setTitle, setDescription } from './editReducer';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import qs from 'qs';
import { RouteComponentProps } from 'react-router';
import { fetchCategories } from './reducer';

export default function CategoryEdit({ location }: RouteComponentProps) {
    const dispatch = useAppDispatch();
    const { data, loaded } = useAppSelector(
        state => state.datastudio.survey.categories
    );
    const { title, description } = useAppSelector(
        state => state.datastudio.survey.categoryEdit.categoryToEdit
    );
    const { globalCategoryId } = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    useEffect(() => {
        if (!loaded) {
            dispatch(fetchCategories());
        }
        if (globalCategoryId) {
            const category = data.find(
                d => d.globalCategoryId === globalCategoryId
            );
            if (category) {
                dispatch(setTitle(category.title));
                dispatch(setDescription(category.description));
            }
        }
    }, [globalCategoryId, data]);

    return (
        <div className="survey-content">
            <div className="survey-content__edit">
                <div className="ds-form-group">
                    <div className="ds-label">Category name</div>
                    <div className="ds-input">
                        <TextField
                            value={title}
                            onChange={e => dispatch(setTitle(e.target.value))}
                            showClose
                            onClose={() => dispatch(setTitle(''))}
                        />
                    </div>
                </div>
                <div className="ds-form-group">
                    <div className="ds-label">Description (optional)</div>
                    <div className="ds-input">
                        <TextField
                            value={description ?? ''}
                            onChange={e =>
                                dispatch(setDescription(e.target.value))
                            }
                            showClose
                            onClose={() => dispatch(setDescription(''))}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

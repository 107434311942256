import ChevronLeft from '../icons/ChevronLeft';
import Logo from '../icons/Logo';
import { useAppSelector } from '../store/hooks';
import { useOnClickHandler } from './hooks';

const TopLeftAction = ({ scrollPos }: { scrollPos?: number }) => {
    const { topLeftAction } = useAppSelector(state => state.appState);
    const onClickHandler = useOnClickHandler();

    const handleClick = () => {
        onClickHandler(topLeftAction.type, topLeftAction.navigateTo, scrollPos);
    };

    return (
        <div onClick={handleClick} style={{ cursor: 'pointer' }}>
            <ActionUI type={topLeftAction.type} />
        </div>
    );
};

const ActionUI = ({ type }: { type: 'default' | 'back' | 'exit' }) => {
    switch (type) {
        case 'back':
            return (
                <div className="nav__header__heading">
                    <ChevronLeft size={16} />
                    <span className="explore-header__title">Back</span>
                </div>
            );
        case 'exit':
            return (
                <div className="nav__header__heading">
                    <ChevronLeft size={16} />
                    <span className="explore-header__title">Exit</span>
                </div>
            );
        default:
            return <Logo size={50} fill={'var(--color-ui-100)'} />;
    }
};

export default TopLeftAction;

import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { AsyncThunkConfig } from '../../store/store';
import axios from 'axios';
import Urls from '../../constants/Urls';
import { Base64 } from 'js-base64';

interface InputParam {
    enterpriseName: string;
    hris: string;
}

const customColors = [
    {
        name: 'graph-1',
        colors: {
            light: '#01B9BB',
            dark: '#FF9133',
            lightDefault: '#01B9BB',
            darkDefault: '#FF9133'
        }
    },
    {
        name: 'graph-2',
        colors: {
            light: '#F1AE00',
            dark: '#FFE99A',
            lightDefault: '#F1AE00',
            darkDefault: '#FFE99A'
        }
    },
    {
        name: 'graph-3',
        colors: {
            light: '#A140CE',
            dark: '#C050F5',
            lightDefault: '#A140CE',
            darkDefault: '#C050F5'
        }
    },
    {
        name: 'graph-4',
        colors: {
            light: '#CF47CF',
            dark: '#FFAFFF',
            lightDefault: '#CF47CF',
            darkDefault: '#FFAFFF'
        }
    },
    {
        name: 'graph-5',
        colors: {
            light: '#3258F8',
            dark: '#335AFF',
            lightDefault: '#3258F8',
            darkDefault: '#335AFF'
        }
    },
    {
        name: 'graph-6',
        colors: {
            light: '#01B9BB',
            dark: '#50E5E7',
            lightDefault: '#01B9BB',
            darkDefault: '#50E5E7'
        }
    },
    {
        name: 'graph-7',
        colors: {
            light: '#0B812C',
            dark: '#2CC056',
            lightDefault: '#0B812C',
            darkDefault: '#2CC056'
        }
    },
    {
        name: 'graph-8',
        colors: {
            light: '#15B742',
            dark: '#BCFF9C',
            lightDefault: '#15B742',
            darkDefault: '#BCFF9C'
        }
    },
    {
        name: 'graph-9',
        colors: {
            light: '#E22A40',
            dark: '#FF5F72',
            lightDefault: '#E22A40',
            darkDefault: '#FF5F72'
        }
    },
    {
        name: 'graph-10',
        colors: {
            light: '#D95565',
            dark: '#FFA9B3',
            lightDefault: '#D95565',
            darkDefault: '#FFA9B3'
        }
    },
    {
        name: 'graph-11',
        colors: {
            light: '#DA3400',
            dark: '#F35410',
            lightDefault: '#DA3400',
            darkDefault: '#F35410'
        }
    },
    {
        name: 'graph-12',
        colors: {
            light: '#EC5F4C',
            dark: '#FF9979',
            lightDefault: '#EC5F4C',
            darkDefault: '#FF9979'
        }
    },
    {
        name: 'graph-13',
        colors: {
            light: '#C53B96',
            dark: '#DA3BA4',
            lightDefault: '#C53B96',
            darkDefault: '#DA3BA4'
        }
    },
    {
        name: 'graph-14',
        colors: {
            light: '#E165B7',
            dark: '#FD8ED7',
            lightDefault: '#E165B7',
            darkDefault: '#FD8ED7'
        }
    },
    {
        name: 'graph-15',
        colors: {
            light: '#298C8C',
            dark: '#189797',
            lightDefault: '#298C8C',
            darkDefault: '#189797'
        }
    },
    {
        name: 'graph-16',
        colors: {
            light: '#38BA8B',
            dark: '#86EAC6',
            lightDefault: '#38BA8B',
            darkDefault: '#86EAC6'
        }
    }
];

export const createAccount = createAsyncThunk<
    void,
    InputParam,
    AsyncThunkConfig
>('createAccount', async ({ enterpriseName, hris }, { getState }) => {
    const { firstName, lastName, email } = getState().auth.userInfo;
    const logoURL =
        'https://storage.googleapis.com/dandi-prod-assets/dandi-logo.png';
    const response = await axios.put(`${Urls.AccountApi}actor/account`, {
        firstName,
        lastName,
        email,
        enterpriseName,
        logoURL
    });
    const { enterpriseId } = response.data;
    const secret = Base64.encode(JSON.stringify({}));
    await axios.put(`${Urls.AccountApi}hris/save`, {
        enterpriseId,
        hrisName: hris,
        secret
    });
    await axios.put(`${Urls.AccountApi}account`, {
        enterpriseId,
        enterpriseName,
        logoURL,
        logoLightURL: logoURL,
        customColors
    });
});

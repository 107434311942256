import { useEffect, useState } from 'react';

const useTableFeatures = (tableRef: any, configuration: any) => {
    const [showLeft, setShowLeft] = useState<boolean>(false);
    const [showRight, setShowRight] = useState<boolean>(false);

    useEffect(() => {
        const tableNode: HTMLDivElement | null = tableRef.current;
        if (!tableNode) return;

        setTimeout(() => {
            if (tableNode.scrollWidth > tableNode.offsetWidth) {
                setShowRight(true);
            }
        }, 200);

        tableNode.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);

        return () => {
            setShowLeft(false);
            setShowRight(false);
            tableNode.removeEventListener('scroll', handleScroll);
            window.removeEventListener('resize', handleResize);
        };
    }, [tableRef]);

    function handleScroll() {
        const tableNode = tableRef.current;
        if (!tableNode) return;
        setShowLeft(tableNode.scrollLeft > 5);
        setShowRight(
            Math.abs(
                tableNode.scrollWidth -
                    tableNode.scrollLeft -
                    tableNode.offsetWidth
            ) > 5
        );
    }

    function handleResize() {
        const tableNode = tableRef.current;
        const scrollable = tableNode.scrollWidth > tableNode.offsetWidth;
        if (scrollable) {
            setShowLeft(tableNode.scrollLeft > 5);
            setShowRight(
                Math.abs(
                    tableNode.scrollWidth -
                        tableNode.scrollLeft -
                        tableNode.offsetWidth
                ) > 5
            );
        } else {
            setShowLeft(false);
            setShowRight(false);
        }
    }

    return {
        showLeft,
        showRight
    };
};

export default useTableFeatures;

import getValueWithUnit from '../../lib/getValueWithUnit';
import isMasked from '../../lib/isMasked';
import { Intervals, TimeInterval } from '../../types';
import Grid from '../Grid';
import Stack from '../Stack';

interface ChartTooltipProps {
    dataPoint: any;
    seriesItem: any;
    columnProps: any[];
    interval: TimeInterval;
    isBenchmark?: boolean;
}

function dateFormatter(date: any, interval: TimeInterval) {
    const monthFormat = new Intl.DateTimeFormat('en-US', {
        month: 'short',
        year: 'numeric'
    });

    const yearFormat = new Intl.DateTimeFormat('en-US', {
        year: 'numeric'
    });

    if (!date) return;
    const adjustedDate = new Date(date);
    adjustedDate.setDate(date.getDate() + 1);

    switch (interval) {
        case Intervals.Month:
            return monthFormat.format(adjustedDate);
        case Intervals.Year:
            return yearFormat.format(adjustedDate);
        case Intervals.Quarter:
            return `Q${
                Math.floor(adjustedDate.getMonth() / 3) + 1
            } ${yearFormat.format(adjustedDate)}`;
        default:
            return '';
    }
}

export default function ChartTooltip({
    dataPoint,
    seriesItem,
    columnProps,
    interval,
    isBenchmark
}: ChartTooltipProps) {
    if (!dataPoint || !seriesItem || (!columnProps && !isBenchmark))
        return null;

    return (
        <Stack
            style={{
                padding: '.8rem',
                borderRadius: '.8rem',
                backgroundColor: 'var(--color-shade-h2)',
                boxShadow: '0px 20px 30px 0px rgba(0, 0, 0, 0.2)'
            }}
            gap={'.8rem'}
        >
            <Grid base={2} gap={'.8rem'}>
                <Stack flexDirection="row" alignItems="center" gap=".4rem">
                    <div
                        style={{
                            backgroundColor: dataPoint.color,
                            '--size': '.4rem',
                            height: 'var(--size)',
                            width: 'var(--size)',
                            borderRadius: '50%'
                        }}
                    />
                    <div
                        className="font-micro-text"
                        style={{
                            maxWidth: '96px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis'
                        }}
                    >
                        {dataPoint.segment.text}
                    </div>
                </Stack>
                {!isBenchmark && (
                    <div
                        className="font-micro-text"
                        style={{
                            color: 'var(--color-ui-50)',
                            textAlign: 'right'
                        }}
                    >
                        {dateFormatter(seriesItem.date, interval)}
                    </div>
                )}
            </Grid>
            <div
                style={{
                    background: 'var(--color-ui-10)',
                    height: '1px',
                    width: '100%'
                }}
            />
            {columnProps
                .filter(cp => !isMasked(seriesItem[cp.source], cp.unit))
                .map((cp: any, i: number) => (
                    <DataRow key={i} label={cp.header}>
                        {getValueWithUnit(
                            seriesItem[cp.source],
                            cp.unit,
                            cp.source === 'gap'
                        )}
                    </DataRow>
                ))}
        </Stack>
    );
}

const DataRow = ({ label, children }: any) => {
    return (
        <Grid base={2} gap={'.8rem'}>
            <div
                style={{
                    color: 'var(--color-ui-50)'
                }}
                className="font-micro-text"
            >
                {label}
            </div>
            <div
                style={{
                    textAlign: 'right'
                }}
                className="font-micro-text"
            >
                {children}
            </div>
        </Grid>
    );
};

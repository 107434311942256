import React, { useState } from 'react';
import VariableEdit from './VariableEdit';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import produce from 'immer';
import { generateTypeOptions } from '../constants';
import ImportModal from './ImportModal';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import { cloneDeep } from 'lodash';

export default function ConfigurationVariables({
    configurationVariables,
    setConfigurationVariables
}: any) {
    const [expandNow, setExpandNow] = useState(false);
    const [modalOpen, setModalOpen] = useState(false);
    const { data = [] } = useAppSelector(
        state => state.datastudio.configurations
    );

    function handleNew() {
        setExpandNow(true);
        setConfigurationVariables((configurationVariables: any) =>
            produce(configurationVariables, (draftState: any) => {
                draftState.push({
                    mainVariables: [],
                    controlVariables: [],
                    additionalVariables: [],
                    formulas: [],
                    exclusionRules: [],
                    generateType: generateTypeOptions[0]
                });
            })
        );
    }

    function handleImport(templateConfigurationId: string) {
        const templateConfig = data.find(
            d => d.templateConfigurationId === templateConfigurationId
        );
        if (templateConfig) {
            setConfigurationVariables(
                cloneDeep(templateConfig.configurationVariables)
            );
        }
        setModalOpen(false);
    }

    return (
        <>
            <div className="ds-label" style={{ marginBottom: 8 }}>
                <span style={{ marginRight: 16 }}>Configuration variables</span>
                <Button
                    componentType={ButtonTypes.type.SECONDARY}
                    onClick={handleNew}
                    large={false}
                >
                    Add new
                </Button>
                <Button
                    componentType={ButtonTypes.type.SECONDARY}
                    onClick={() => setModalOpen(true)}
                    large={false}
                    style={{ marginLeft: '0.8rem' }}
                >
                    Import from
                </Button>
            </div>
            {configurationVariables?.map((cv: any, i: number) => (
                <VariableEdit
                    key={i}
                    label={`Configuration variable ${i + 1}`}
                    index={i}
                    variable={cv}
                    setConfigurationVariables={setConfigurationVariables}
                    expandNow={
                        expandNow && configurationVariables.length - 1 === i
                    }
                    setExpandNow={setExpandNow}
                />
            )) || null}
            <ImportModal
                isOpen={modalOpen}
                onAction={handleImport}
                onCancel={() => setModalOpen(false)}
            />
        </>
    );
}

import React from 'react';
import './styles.scss';
import TextFieldStateWrapper from './TextFieldStateWrapper';
import Avatar from '../common/Avatar';
import Calendar from '../icons/Calendar';
import Pie from '../icons/Pie';
import BarChartVertical from '../icons/BarChartVertical';
import BarChartHorizontal from '../icons/BarChartHorizontal';
import Line from '../icons/Line';
import Menu from '../icons/Menu';
import Button from '../common/Button';
import TextFieldTypes from '../constants/TextFieldTypes';
import ListItemTypes from '../constants/ListItemTypes';
import CheckboxTypes from '../constants/CheckboxTypes';
import ButtonTypes from '../constants/ButtonTypes';
import AvatarTypes from '../constants/AvatarTypes';
import Divider from '../common/Divider';
import ListItemStateWrapper from './ListItemStateWrapper';
import CheckboxStateWrapper from './CheckboxStateWrapper';
import ModalStateWrapper from './ModalStateWrapper';
import LottieAnimation from '../common/LottieAnimation';
import Loader from '../lottie/graph-loader.json';
import Wink from '../lottie/wink.json';
import LegendStateWrapper from './LegendStateWrapper';
import LegendTypes from '../constants/LegendTypes';
import DropdownStateWrapper from './DropdownStateWrapper';
import TextAreaStateWrapper from './TextAreaStateWrapper';
import RadioButtonStateWrapper from './RadioButtonStateWrapper';
import RadioButtonTypes from '../constants/RadioButtonTypes';
import ChartPicker from '../common/ChartPicker';
import WithPermissions from '../common/WithPermissions';
import RbacActions from '../constants/RbacActions';

const sections = [
    {
        title: 'Avatars',
        flexFlow: 'row',
        components: [
            [
                {
                    type: Avatar,
                    props: {
                        type: AvatarTypes.type.DEFAULT,
                        colorCode: 16
                    }
                },
                {
                    type: Avatar,
                    props: {
                        type: AvatarTypes.type.DEFAULT,
                        colorCode: 1
                    }
                }
            ]
        ]
    },
    {
        title: 'Buttons',
        flexFlow: 'row',
        components: [
            [
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        children: <span>Primary</span>,
                        onClick: () => console.log('btn clicked'),
                        large: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.SECONDARY,
                        children: <span>Secondary</span>,
                        onClick: null,
                        large: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        children: <span>Tertiary</span>,
                        onClick: () => console.log('btn clicked'),
                        large: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        children: <span>Disabled</span>,
                        disabled: true,
                        onClick: () => console.log('btn clicked'),
                        large: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        loading: true,
                        children: <span style={{ opacity: 0 }}>Loading</span>,
                        onClick: () => console.log('btn clicked'),
                        large: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        selected: true,
                        children: <span>Selected</span>,
                        onClick: () => console.log('btn clicked')
                    }
                }
            ],

            [
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        onClick: null,
                        children: <span>Primary</span>,
                        large: false
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.SECONDARY,
                        onClick: null,
                        children: <span>Secondary</span>,
                        large: false
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        children: <span>Tertiary</span>,
                        large: false
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        onClick: null,
                        disabled: true,
                        children: <span>Disabled</span>,
                        large: false
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        onClick: null,
                        children: (
                            <div className="centered-page">
                                <Calendar
                                    width={14}
                                    height={17}
                                    style={{ marginRight: '8px' }}
                                />
                                <span>Primary</span>
                            </div>
                        ),
                        large: false
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.SECONDARY,
                        disabled: false,
                        onClick: null,
                        children: (
                            <div className="centered-page">
                                <Calendar
                                    width={14}
                                    height={17}
                                    style={{ marginRight: '8px' }}
                                />
                                <span>Secondary</span>
                            </div>
                        ),
                        large: false
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        children: (
                            <div className="centered-page">
                                <Calendar
                                    width={14}
                                    height={17}
                                    style={{ marginRight: '8px' }}
                                />
                                <span>Tertiary</span>
                            </div>
                        ),
                        large: false
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        activated: true,
                        selectedStyle: ButtonTypes.selectedStyle.DEFAULT,
                        children: (
                            <div className="centered-page">
                                <Calendar
                                    width={14}
                                    height={17}
                                    style={{ marginRight: '8px' }}
                                />
                                <span>Tertiary</span>
                            </div>
                        ),
                        large: false
                    }
                }
            ],
            [
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        onClick: null,
                        children: (
                            <Pie width={24} height={24} strokeWidth={2} />
                        ),
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        children: (
                            <Pie width={24} height={24} strokeWidth={1} />
                        ),
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        disabled: true,
                        children: (
                            <Pie width={24} height={24} strokeWidth={1} />
                        ),
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        activated: true,
                        selectedStyle: ButtonTypes.selectedStyle.DEFAULT,
                        children: (
                            <Pie width={24} height={24} strokeWidth={1} />
                        ),
                        circle: true
                    }
                }
            ],
            [
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        onClick: null,
                        children: (
                            <BarChartHorizontal
                                width={24}
                                height={24}
                                strokeWidth={2}
                            />
                        ),
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        children: (
                            <BarChartHorizontal
                                width={24}
                                height={24}
                                strokeWidth={1}
                            />
                        ),
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        disabled: true,
                        onClick: null,
                        children: (
                            <BarChartHorizontal
                                width={24}
                                height={24}
                                strokeWidth={1}
                            />
                        ),
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        activated: true,
                        selectedStyle: ButtonTypes.selectedStyle.DEFAULT,
                        onClick: null,
                        children: (
                            <BarChartHorizontal
                                width={24}
                                height={24}
                                strokeWidth={1}
                            />
                        ),
                        circle: true
                    }
                }
            ],
            [
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        onClick: null,
                        children: (
                            <BarChartVertical
                                width={24}
                                height={24}
                                strokeWidth={2}
                            />
                        ),
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        children: <BarChartVertical width={24} height={24} />,
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        disabled: true,
                        children: <BarChartVertical width={24} height={24} />,
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        activated: true,
                        selectedStyle: ButtonTypes.selectedStyle.DEFAULT,
                        children: <BarChartVertical width={24} height={24} />,
                        circle: true
                    }
                }
            ],
            [
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        onClick: null,
                        children: (
                            <Line width={24} height={24} strokeWidth={2} />
                        ),
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        children: (
                            <Line width={24} height={24} strokeWidth={1} />
                        ),
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        disabled: true,
                        children: (
                            <Line width={24} height={24} strokeWidth={1} />
                        ),
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        activated: true,
                        selectedStyle: ButtonTypes.selectedStyle.DEFAULT,
                        children: (
                            <Line width={24} height={24} strokeWidth={1} />
                        ),
                        circle: true
                    }
                }
            ],
            [
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.PRIMARY,
                        onClick: null,
                        children: (
                            <Menu width={24} height={24} strokeWidth={2} />
                        ),
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        children: (
                            <Menu width={24} height={24} strokeWidth={1} />
                        ),
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        disabled: true,
                        children: (
                            <Menu width={24} height={24} strokeWidth={1} />
                        ),
                        circle: true
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.TERTIARY,
                        onClick: null,
                        activated: true,
                        selectedStyle: ButtonTypes.selectedStyle.DEFAULT,
                        children: (
                            <Menu width={24} height={24} strokeWidth={1} />
                        ),
                        circle: true
                    }
                }
            ],
            [
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.LIST_ITEM,
                        onClick: null,
                        children: <span>4M</span>
                    }
                },
                {
                    type: Button,
                    props: {
                        componentType: ButtonTypes.type.LIST_ITEM,
                        disabled: true,
                        onClick: null,
                        children: <span>Custom</span>
                    }
                }
            ]
        ]
    },
    {
        title: 'Dropdowns',
        flexFlow: 'row',
        components: [
            [
                {
                    type: DropdownStateWrapper,
                    props: {
                        buttonType: ButtonTypes.type.PRIMARY,
                        allowCancel: true,
                        options: [
                            {
                                label: 'Option1',
                                value: 1
                            },
                            {
                                label: 'Option2',
                                value: 2
                            }
                        ]
                    }
                },
                {
                    type: DropdownStateWrapper,
                    props: {
                        options: [
                            {
                                label: 'Option1',
                                value: 1
                            },
                            {
                                label: 'Option2',
                                value: 2
                            }
                        ]
                    }
                },
                {
                    type: DropdownStateWrapper,
                    props: {
                        buttonType: ButtonTypes.type.TERTIARY,
                        options: [
                            {
                                label: 'Option1',
                                value: 1
                            },
                            {
                                label: 'Option2',
                                value: 2
                            }
                        ]
                    }
                },
                {
                    type: DropdownStateWrapper,
                    props: {
                        buttonType: ButtonTypes.type.LIST_ITEM,
                        selectedStyle: ButtonTypes.selectedStyle.UNDERLINE,
                        options: [
                            {
                                label: 'Option1',
                                value: 1
                            },
                            {
                                label: 'Option2',
                                value: 2
                            }
                        ]
                    }
                }
            ],
            [
                {
                    type: DropdownStateWrapper,
                    props: {
                        buttonType: ButtonTypes.type.PRIMARY,
                        options: [
                            {
                                label: 'Option1',
                                value: 1
                            },
                            {
                                label: 'Option2',
                                value: 2
                            }
                        ]
                    }
                },
                {
                    type: DropdownStateWrapper,
                    props: {
                        options: [
                            {
                                label: 'Option1',
                                value: 1
                            },
                            {
                                label: 'Option2',
                                value: 2
                            }
                        ]
                    }
                },
                {
                    type: DropdownStateWrapper,
                    props: {
                        buttonType: ButtonTypes.type.TERTIARY,
                        options: [
                            {
                                label: 'Option1',
                                value: 1
                            },
                            {
                                label: 'Option2',
                                value: 2
                            }
                        ]
                    }
                },
                {
                    type: DropdownStateWrapper,
                    props: {
                        buttonType: ButtonTypes.type.LIST_ITEM,
                        selectedStyle: ButtonTypes.selectedStyle.UNDERLINE,
                        options: [
                            {
                                label: 'Option1',
                                value: 1
                            },
                            {
                                label: 'Option2',
                                value: 2
                            }
                        ]
                    }
                }
            ],
            [
                {
                    type: DropdownStateWrapper,
                    props: {
                        enableSearch: true,
                        placeholder: 'Search',
                        options: [
                            {
                                label: 'Option1',
                                value: 1
                            },
                            {
                                label: 'Option2',
                                value: 2
                            }
                        ]
                    }
                }
            ]
        ]
    },
    {
        title: 'Divider',
        flexFlow: 'row',
        components: [
            [
                {
                    type: Divider,
                    props: {
                        style: {
                            width: '100%'
                        }
                    }
                }
            ]
        ]
    },
    {
        title: 'Text Fields',
        flexFlow: 'row wrap',
        components: [
            [
                {
                    type: TextFieldStateWrapper,
                    props: {
                        componentType: TextFieldTypes.type.DEFAULT,
                        placeholder: 'Placeholder'
                    }
                },
                {
                    type: TextFieldStateWrapper,
                    props: {
                        componentType: TextFieldTypes.type.DEFAULT,
                        danger: true,
                        inputValue: 'Danger',
                        placeholder: 'Placeholder'
                    }
                }
            ],
            [
                {
                    type: TextFieldStateWrapper,
                    props: {
                        componentType: TextFieldTypes.type.DEFAULT,
                        placeholder: 'Placeholder',
                        showClose: true
                    }
                },
                {
                    type: TextFieldStateWrapper,
                    props: {
                        componentType: TextFieldTypes.type.DEFAULT,
                        danger: true,
                        showClose: true,
                        inputValue: 'Danger',
                        placeholder: 'Placeholder'
                    }
                }
            ],
            [
                {
                    type: TextFieldStateWrapper,
                    props: {
                        componentType: TextFieldTypes.type.ROUND,
                        large: false,
                        placeholder: 'Search',
                        showClose: false,
                        isSearch: false,
                        inputValue: ''
                    }
                }
            ],
            [
                {
                    type: TextFieldStateWrapper,
                    props: {
                        componentType: TextFieldTypes.type.TAGS,
                        large: true,
                        placeholder: 'Add Tags',
                        showClose: false,
                        inputValue: '',
                        maxWidth: '350px'
                    }
                },
                {
                    type: TextAreaStateWrapper,
                    props: {
                        inputValue: '',
                        placeholder: 'Placeholder'
                    }
                }
            ]
        ]
    },
    {
        title: 'List Items',
        components: [
            [
                {
                    type: ListItemStateWrapper,
                    props: {
                        type: ListItemTypes.type.DEFAULT,
                        children: <span>Row</span>,
                        initialState: false
                    }
                },
                {
                    type: CheckboxStateWrapper,
                    props: {
                        type: CheckboxTypes.type.DEFAULT,
                        initialState: false,
                        children: <span>Row</span>
                    }
                },
                {
                    type: CheckboxStateWrapper,
                    props: {
                        type: CheckboxTypes.type.DEFAULT,
                        initialState: true,
                        children: <span>Row</span>
                    }
                }
            ],
            [
                {
                    type: RadioButtonStateWrapper,
                    props: {
                        type: RadioButtonTypes.type.DEFAULT
                    }
                }
            ]
        ]
    },
    {
        title: 'Modals',
        components: [
            [
                {
                    type: ModalStateWrapper,
                    props: {
                        children: (
                            <React.Fragment>
                                <h3 className="font-subtitle mb40">
                                    Name your dashboard
                                </h3>
                                <TextFieldStateWrapper
                                    type={TextFieldTypes.type.DEFAULT}
                                    placeholder="Enter name here"
                                ></TextFieldStateWrapper>
                                <div className="buttons mt24">
                                    <Button
                                        componentType={
                                            ButtonTypes.type.SECONDARY
                                        }
                                    >
                                        Cancel
                                    </Button>
                                </div>
                            </React.Fragment>
                        )
                    }
                }
            ]
        ]
    },
    {
        title: 'Animations',
        components: [
            [
                {
                    type: LottieAnimation,
                    props: {
                        animation: Wink,
                        autoplay: true,
                        loop: true,
                        width: 200,
                        height: 200
                    }
                }
            ],
            [
                {
                    type: LottieAnimation,
                    props: {
                        animation: Loader,
                        autoplay: true,
                        loop: true,
                        width: 100,
                        height: 100
                    }
                }
            ]
        ]
    },

    {
        title: 'Legends',
        components: [
            [
                {
                    type: LegendStateWrapper,
                    props: {
                        type: LegendTypes.type.DEFAULT,
                        length: 2,
                        data: [
                            {
                                label: 'Equity Index',
                                segment: {
                                    text: 'Equity Index',
                                    html: 'Equity Index'
                                },
                                isEqualityIndex: true
                            },
                            {
                                label: '1',
                                navProps: {
                                    Age: '1'
                                },
                                color: '#00fcff',
                                segment: {
                                    tokens: [
                                        {
                                            text: '18 to 29',
                                            color: '#00fcff'
                                        }
                                    ],
                                    text: '18 to 29',
                                    html: '<span style="color: #00fcff">18 to 29</span>'
                                },

                                active: false
                            },
                            {
                                label: '2',
                                navProps: {
                                    Age: '2'
                                },
                                color: '#335aff',
                                segment: {
                                    tokens: [
                                        {
                                            text: '30 to 39',
                                            color: '#335aff'
                                        }
                                    ],
                                    text: '30 to 39',
                                    html: '<span style="color: #335aff">30 to 39</span>'
                                }
                            },
                            {
                                label: '3',
                                navProps: {
                                    Age: '3'
                                },
                                color: '#ffafff',
                                segment: {
                                    tokens: [
                                        {
                                            text: '40 to 49',
                                            color: '#ffafff'
                                        }
                                    ],
                                    text: '40 to 49',
                                    html: '<span style="color: #ffafff">40 to 49</span>'
                                },

                                active: false
                            },
                            {
                                label: '4',
                                navProps: {
                                    Age: '4'
                                },
                                color: '#ff9133',
                                segment: {
                                    tokens: [
                                        {
                                            text: '50 to 59',
                                            color: '#ff9133'
                                        }
                                    ],
                                    text: '50 to 59',
                                    html: '<span style="color: #ff9133">50 to 59</span>'
                                },

                                active: false
                            },
                            {
                                label: '5',
                                navProps: {
                                    Age: '5'
                                },
                                color: '#63ff75',
                                segment: {
                                    tokens: [
                                        {
                                            text: '60+',
                                            color: '#63ff75'
                                        }
                                    ],
                                    text: '60+',
                                    html: '<span style="color: #63ff75">60+</span>'
                                }
                            }
                        ],
                        chartType: 'line',
                        multiLine: false,
                        lineWidth: 1
                    }
                }
            ]
        ]
    },
    {
        title: 'Table',
        components: [
            [
                {
                    type: ChartPicker,
                    props: {
                        width: null,
                        height: 100,
                        showSubtable: false,
                        data: [
                            {
                                label: 'Asian',
                                series: []
                            }
                        ],
                        chartType: 'Stats',
                        columnProps: [],
                        dates: [],
                        interval: 'month',
                        lifecycle: 'lifecycle',
                        setActiveLabel: () => {},
                        clearActiveLabel: () => {},
                        handleToggleLabel: () => {},
                        analysisType: 'Aritmetic'
                    }
                }
            ]
        ]
    }
];

const Components = () => {
    return (
        <WithPermissions
            showMessage={true}
            actions={[RbacActions['Roles/View']]}
        >
            <div className="components">
                <div className="components__top-bar">
                    <h1>Components</h1>
                </div>
                {sections.map((section, i) => (
                    <div className="section" key={i}>
                        <div className="section__left">
                            <h2 className="section__title">{section.title}</h2>
                        </div>
                        <div
                            className="section__right"
                            style={{ flexFlow: section.flexFlow }}
                        >
                            {section.components.map((column, i) => (
                                <div className="section__column" key={i}>
                                    {column.map((Component, i) => (
                                        <div
                                            className="section__component"
                                            key={i}
                                        >
                                            <Component.type
                                                {...Component.props}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </div>
                    </div>
                ))}
            </div>
        </WithPermissions>
    );
};

export default Components;

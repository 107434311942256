import cn from '../../lib/cn';
import DragIndicatorIcon from '../../icons/DragIndicator';
import { createContext, useEffect, useRef, useState } from 'react';
import ModalCloseIcon from '../../icons/ModalClose';
import ModalMinimizeIcon from '../../icons/ModalMinimize';
import ModalExpandIcon from '../../icons/ModalExpand';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { setReportSelectMode } from '../../dashboard/aiInsights/reducer';
import Chat from './Chat';
import { Message, MessageComponentTypes, MessageTypes } from './types';
import ResizeHandle from './ResizeHandle';
import DraggableModal from './DraggableModal';
import Modal from './Modal';
import { createInitialMessages } from './Messages';

// TODO: can handleReportSelectMode, handleReportSelectCancel be moved to more isolated components?
interface InsightsContextProps {
    loaded?: boolean;
    pending?: boolean;
    error?: string;
    regenerateMode?: boolean;
    isSummary?: boolean;
    selectedReports: any[];
    onSave?: () => void;
    onReportUpdate?: (reportId: string) => void;
    aiInsights?: string;
    handleSummarizeDashboard: () => void;
    handleReportSelectMode: () => void;
    handleReportSelectCancel: () => void;
    handleGenerateAiInsights: () => void;
    modalHeight: number;
    modalWidth: number;
    modalMaxHeight: number;
    setModalHeight: (height: number) => void;
    setModalWidth: (width: number) => void;
    modalRef: any;
    isMinimized?: boolean;
    isGivingFeedback?: boolean;
    setIsGivingFeedback?: (isGivingFeedback: boolean) => void;
}
// ----------------------

export const InsightsContext = createContext({
    selectedReports: [],
    handleSummarizeDashboard: () => {},
    handleReportSelectMode: () => {},
    handleReportSelectCancel: () => {},
    handleGenerateAiInsights: () => {},
    modalHeight: 0,
    modalWidth: 0,
    setModalHeight: () => {},
    setModalWidth: () => {},
    modalRef: null,
    modalMaxHeight: 0
} as InsightsContextProps);

export default function AiInsightsModal({
    onClose,
    onSave,
    onReportUpdate,
    aiInsights,
    pending,
    loaded,
    error,
    onGenerateInsights,
    onSummarize
}: {
    onClose: () => void;
    aiInsights: string;
    onSave?: () => void;
    pending?: boolean;
    loaded?: boolean;
    error?: string;
    onGenerateInsights?: () => void;
    onSummarize?: () => void;
    onReportUpdate?: (reportId: string) => void;
}) {
    const dispatch = useAppDispatch();
    const modalRef = useRef<any>();
    const [modalHeight, setModalHeight] = useState(0);
    const [modalWidth, setModalWidth] = useState(700);
    const [modalMaxHeight, setModalMaxHeight] = useState(0);
    const [isGivingFeedback, setIsGivingFeedback] = useState(false);
    const { reportIds, regenerateMode } = useAppSelector(
        state => state.dashboard.aiInsights
    );
    const selectedReports = useAppSelector(state =>
        state.dashboard.view.reports?.filter((r: any) =>
            reportIds?.includes(r.reportId)
        )
    );

    const [isSummary, setIsSummary] = useState(false);
    const { firstName } = useAppSelector(state => state.auth.userInfo);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [isMinimized, setIsMinimized] = useState(false);

    const [messages, setMessages] = useState(
        createInitialMessages({
            onSave,
            pending,
            reportIds,
            firstName,
            regenerateMode
        })
    );

    // Close on Esc
    useEffect(() => {
        const handleEsc = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleEsc);

        return () => {
            document.removeEventListener('keydown', handleEsc);
        };
    }, []);

    useEffect(() => {
        if (modalHeight === 0 || modalHeight > modalMaxHeight) {
            setModalHeight(modalMaxHeight);
        }
    }, [modalMaxHeight, modalHeight]);

    useEffect(() => {
        const handleResize = () => {
            if (!modalRef.current) return;

            const TOP_MARGIN = 72;
            const BOTTOM_MARGIN = 16;
            const dynamicHeight =
                window.innerHeight - TOP_MARGIN - BOTTOM_MARGIN;
            setModalMaxHeight(dynamicHeight);
        };

        handleResize();

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function handleSummarizeDashboard() {
        if (onSummarize) {
            onSummarize();
        }

        setIsSummary(true);

        setMessages((prev: any[]) => [
            ...prev.filter((m: Message) => m.id !== 'suggestions'),
            {
                id: 'final-insights-ui',
                componentType: MessageComponentTypes.component,
                component: 'Insights',
                props: {
                    type: MessageTypes.assistant
                },
                children: null
            }
        ]);
    }

    function handleReportSelectMode() {
        dispatch(setReportSelectMode(true));
        setMessages((prev: any[]) => [
            ...prev.filter((m: Message) => m.id !== 'suggestions'),
            {
                id: 'report-select-mode',
                componentType: MessageComponentTypes.component,
                component: 'MessageBubble',
                props: {
                    type: MessageTypes.user,
                    animate: false,
                    showAvatar: true
                },
                children: <span>Generate insights from reports</span>
            },
            {
                id: 'report-select-mode-description',
                componentType: MessageComponentTypes.component,
                component: 'MessageBubble',
                props: {
                    type: MessageTypes.assistant,
                    showAvatar: true
                },
                children: (
                    <span>
                        Great choice, {firstName}! Please select the reports for
                        which you'd like to generate insights. Once you've made
                        your selection, click "Generate Insights" to proceed.
                    </span>
                )
            },
            {
                id: 'report-select-mode-ui',
                componentType: MessageComponentTypes.component,
                component: 'ReportSelect',
                props: {
                    type: MessageTypes.user,
                    animate: false,
                    showAvatar: true
                },
                children: null
            }
        ]);
    }

    function handleReportSelectCancel() {
        dispatch(setReportSelectMode(false));

        setMessages(
            createInitialMessages({
                onSave,
                reportIds: [],
                firstName,
                pending,
                regenerateMode
            })
        );
    }

    function handleGenerateAiInsights() {
        if (onGenerateInsights) {
            onGenerateInsights();
        }

        setMessages((prev: any[]) => [
            ...prev.filter((m: Message) => m.id !== 'suggestions'),
            {
                id: 'final-insights-ui',
                componentType: MessageComponentTypes.component,
                component: 'Insights',
                props: {
                    type: MessageTypes.assistant
                },
                children: null
            }
        ]);

        dispatch(setReportSelectMode(false));
    }

    return (
        <Modal setPosition={setPosition}>
            <DraggableModal position={position} setPosition={setPosition}>
                {({ attributes, listeners, setActivatorNodeRef }: any) => (
                    <InsightsContext.Provider
                        value={{
                            isSummary,
                            selectedReports,
                            loaded,
                            pending,
                            error,
                            aiInsights,
                            onSave,
                            onReportUpdate,
                            handleSummarizeDashboard,
                            handleReportSelectMode,
                            handleReportSelectCancel,
                            handleGenerateAiInsights,
                            modalHeight,
                            modalWidth,
                            setModalHeight,
                            setModalWidth,
                            modalRef,
                            isMinimized,
                            modalMaxHeight,
                            regenerateMode,
                            isGivingFeedback,
                            setIsGivingFeedback
                        }}
                    >
                        <div
                            ref={modalRef}
                            className={cn(
                                'relative flex flex-col gap-[2rem] p-[1.6rem] bg-shade-h3',
                                'rounded-[2rem] overflow-auto shadow-lg'
                            )}
                            style={{
                                pointerEvents: 'all',
                                height: isMinimized ? '62px' : modalHeight,
                                width: modalWidth
                            }}
                        >
                            {/* Header */}
                            <header
                                className={cn(
                                    'w-full flex justify-between text-ui-50  '
                                )}
                            >
                                <div
                                    className={cn(
                                        'flex items-center gap-[.8rem]'
                                    )}
                                >
                                    <div
                                        className={cn(
                                            'cursor-move flex items-center'
                                        )}
                                        ref={setActivatorNodeRef}
                                        {...attributes}
                                        {...listeners}
                                    >
                                        <DragIndicatorIcon />
                                    </div>
                                    <span className={cn('text-ui-100')}>
                                        Dandi AI (Preview)
                                    </span>
                                </div>
                                <div
                                    className={cn(
                                        'flex items-center gap-[.8rem]'
                                    )}
                                >
                                    {isMinimized ? (
                                        <div
                                            onClick={() =>
                                                setIsMinimized(false)
                                            }
                                            className={cn('cursor-pointer')}
                                        >
                                            <ModalExpandIcon />
                                        </div>
                                    ) : (
                                        <div
                                            onClick={() => {
                                                setIsMinimized(true);
                                            }}
                                            className={cn('cursor-pointer')}
                                        >
                                            <ModalMinimizeIcon />
                                        </div>
                                    )}
                                    <div
                                        onClick={onClose}
                                        className={cn('cursor-pointer')}
                                    >
                                        <ModalCloseIcon />
                                    </div>
                                </div>
                            </header>
                            {!isMinimized && <Chat messages={messages} />}
                            <ResizeHandle />
                        </div>
                    </InsightsContext.Provider>
                )}
            </DraggableModal>
        </Modal>
    );
}

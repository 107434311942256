import React from 'react';
import PropTypes from 'prop-types';

const SpinnerDefault = ({ size, isWhite }: any) => {
    const spinnerStyle = { width: size, height: size };
    const center = size / 2;
    const wrapperStyle = { transformOrigin: `${center}px ${center}px` };
    const top = Math.round(size * 0.05);
    const width = Math.round(size * 0.08);
    const left = Math.round(center - width / 2);
    const height = Math.round((center - top) * 0.5);
    const elemStyle = { top, left, width, height };

    return (
        <div
            className={
                isWhite ? 'lds-spinner lds-spinner--white' : 'lds-spinner'
            }
            style={spinnerStyle}
        >
            <div style={wrapperStyle}>
                <div style={elemStyle} />
            </div>
            <div style={wrapperStyle}>
                <div style={elemStyle} />
            </div>
            <div style={wrapperStyle}>
                <div style={elemStyle} />
            </div>
            <div style={wrapperStyle}>
                <div style={elemStyle} />
            </div>
            <div style={wrapperStyle}>
                <div style={elemStyle} />
            </div>
            <div style={wrapperStyle}>
                <div style={elemStyle} />
            </div>
            <div style={wrapperStyle}>
                <div style={elemStyle} />
            </div>
            <div style={wrapperStyle}>
                <div style={elemStyle} />
            </div>
            <div style={wrapperStyle}>
                <div style={elemStyle} />
            </div>
            <div style={wrapperStyle}>
                <div style={elemStyle} />
            </div>
            <div style={wrapperStyle}>
                <div style={elemStyle} />
            </div>
            <div style={wrapperStyle}>
                <div style={elemStyle} />
            </div>
        </div>
    );
};

SpinnerDefault.propTypes = {
    size: PropTypes.number,
    isWhite: PropTypes.bool
};

SpinnerDefault.defaultProps = {
    size: 64,
    isWhite: false
};

export default SpinnerDefault;

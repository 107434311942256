import { useState, useEffect, useLayoutEffect } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Menu from 'antd/lib/menu';
import MenuItem from 'antd/lib/menu/MenuItem';
import { MdMenu } from 'react-icons/md';

import ChevronLeft from '../icons/ChevronLeft';
import cn from '../lib/cn';

const menu = [
    {
        sectionName: 'Template configurations',
        key: 'root'
    },
    {
        sectionName: 'Templates',
        key: 'templates'
    },
    {
        sectionName: 'Packages',
        key: 'packages'
    },
    {
        sectionName: 'Enterprises',
        key: 'enterprises'
    },
    {
        sectionName: 'Create Account',
        key: 'createAccount'
    },
    {
        sectionName: 'Self ID content creation',
        key: 'survey'
    }
];

function LeftNav({ history, location }: RouteComponentProps) {
    const [selectedKeys, setSelectedKeys] = useState<string[]>(['root']);
    const inSurveyCategoryEdit = /\/datastudio\/survey\/category\/edit/.test(
        location.pathname
    );
    const inSurveyQuestionEdit = /\/datastudio\/survey\/question\/edit/.test(
        location.pathname
    );
    const inSurveyTemplateEdit = /\/datastudio\/survey\/template\/edit/.test(
        location.pathname
    );
    const inSurveyTemplateQuestions =
        /\/datastudio\/survey\/template\/questions/.test(location.pathname);

    const [collapsible, setCollapsible] = useState(window.innerWidth < 960);
    const [collapsed, setCollapsed] = useState(collapsible);

    useEffect(() => {
        if (location?.pathname.includes('datastudio')) {
            let key = [
                'templates',
                'packages',
                'enterprises',
                'createAccount',
                'survey'
            ].find(route => location.pathname.includes('/datastudio/' + route));
            if (!key) {
                key = 'root';
            }
            setSelectedKeys([key]);
        }
    }, [location?.pathname]);

    useLayoutEffect(() => {
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    function handleCollapsedToggle() {
        setCollapsed(!collapsed);
    }

    function handleMenuClick({ key }: any) {
        setSelectedKeys([key]);
        let route = key;
        if (key === 'root') {
            route = '';
        }
        if (key === 'survey') {
            route = 'survey/templates';
        }
        history.push(`/datastudio/${route}`);
        window.scrollTo(0, 0);
    }

    function handleBack() {
        history.goBack();
    }

    function handleResize() {
        const shouldCollapse = window.innerWidth < 1180;
        setCollapsible(shouldCollapse);
        setCollapsed(shouldCollapse);
    }

    const showBackButton =
        inSurveyCategoryEdit ||
        inSurveyQuestionEdit ||
        inSurveyTemplateEdit ||
        inSurveyTemplateQuestions;

    return (
        <div className={cn('fixed top-0 w-screen h-[60px] nav')}>
            <div className="nav__header">
                {showBackButton ? (
                    <div className="nav__header__back" onClick={handleBack}>
                        <ChevronLeft size={24} />
                        <span>Back</span>
                    </div>
                ) : collapsible ? (
                    <MdMenu
                        size={24}
                        style={{ cursor: 'pointer' }}
                        onClick={handleCollapsedToggle}
                    />
                ) : (
                    <>Data studio</>
                )}
            </div>
            {collapsible && !collapsed && (
                <div className="nav__menu nav__menu__collapsible">
                    <Menu selectedKeys={selectedKeys} onClick={handleMenuClick}>
                        {menu.map(m => (
                            <MenuItem key={m.key}>{m.sectionName}</MenuItem>
                        ))}
                        <div
                            style={{
                                backgroundColor: 'var(--color-shade-h2)',
                                height: '100vh'
                            }}
                        ></div>
                    </Menu>
                </div>
            )}
            {!collapsible && (
                <div className="nav__menu">
                    <Menu
                        selectedKeys={selectedKeys}
                        onClick={handleMenuClick}
                        mode="horizontal"
                    >
                        {menu.map(m => (
                            <MenuItem key={m.key}>{m.sectionName}</MenuItem>
                        ))}
                    </Menu>
                </div>
            )}
        </div>
    );
}

export default withRouter(LeftNav);

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import exportReportAsCsv from '../../editor/chart/exportCsv';
import exportMatrixAsCsv from '../../explore/detail/exportCsv';
import ChartTypes from '../../constants/ChartTypes';

export default function exportCsv(dashboardName, params) {
    const zip = new JSZip();
    params.forEach((p, i) => {
        const {
            reportName,
            lifecycle,
            analysisType,
            columnProps,
            data,
            dates,
            interval,
            summary,
            groupSummary,
            attributeName,
            diversityAttributes,
            period,
            chartType,
            benchmark,
            subAnalysisFields,
            subAnalysisMapping
        } = p;
        let filename;
        let csv;
        if (
            chartType === ChartTypes.Matrix ||
            chartType === ChartTypes.HorizontalBar
        ) {
            csv = exportMatrixAsCsv(
                data,
                summary,
                groupSummary,
                lifecycle,
                analysisType,
                columnProps,
                attributeName,
                diversityAttributes,
                period,
                true
            );
            filename = `${dashboardName}_${reportName}.csv`;
            filename = filename.replace(/ /g, '_');
        } else {
            ({ filename, csv } = exportReportAsCsv({
                dashboardName,
                reportName,
                lifecycle,
                analysisType,
                columnProps,
                data,
                dates,
                interval,
                benchmark,
                chartType,
                subAnalysisFields,
                subAnalysisMapping,
                shouldReturnCsv: true
            }));
        }
        filename = `${i + 1}_${filename}`;
        zip.file(filename, csv);
    });
    zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `${dashboardName}.zip`);
    });
}

import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import orderBy from 'lodash/orderBy';
import Modal from 'antd/lib/modal';
import Checkbox from '../common/Checkbox';
import Tooltip from 'antd/lib/tooltip';
import validator from 'email-validator';
import classNames from 'classnames';
import OpenText from '../common/OpenText';
import {
    closeShareModal,
    getVault,
    vaultLinkCopied,
    shareDashboard,
    getSharedUsers
} from './actions';
import Avatar from '../common/Avatar';
import AvatarTypes from '../constants/AvatarTypes';
import Close from '../icons/Close';
import getAvatarFill from '../lib/getAvatarFill';
import { track } from '../lib/segment';
import MixPanel from '../constants/MixPanel';
import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';

interface ActorProps {
    actor: any;
    onClick?: any;
    onRemove?: any;
    focus?: boolean;
}

function Actor({ actor, onClick, onRemove, focus }: ActorProps) {
    const selected = !onClick && !!onRemove;
    return (
        <div
            key={actor.actorId}
            className={classNames('share-modal__actor', {
                'share-modal__actor--focus': focus,
                'share-modal__actor--selected': selected
            })}
            onClick={onClick}
        >
            <div className="share-modal__actor__photo">
                {actor.profilePictureURL ? (
                    <img src={actor.profilePictureURL} />
                ) : (
                    <Avatar
                        type={AvatarTypes.type.DEFAULT}
                        colorCode={getAvatarFill(actor.email)}
                    />
                )}
            </div>
            <div className="share-modal__actor__details">
                <div className="share-modal__actor__email">{actor.email}</div>
                <div className="share-modal__actor__name">
                    {actor.firstName + ' ' + actor.lastName}
                </div>
            </div>
            {selected && (
                <div className="share-modal__actor__remove">
                    <Close
                        className="icon"
                        width={24}
                        height={24}
                        onClick={onRemove}
                    />
                </div>
            )}
        </div>
    );
}

interface PillProps {
    text: string;
    onRemove: any;
}

function Pill({ text, onRemove }: PillProps) {
    return (
        <div className="share-modal__pill">
            <span>{text}</span>

            <Close className="icon" width="12" height="12" onClick={onRemove} />
        </div>
    );
}

interface ShareModalProps {
    isOpen: boolean;
    dashboardId: string;
    dashboardName: string;
    vaultId: string;
    closeShareModal: any;
    getVault: any;
    vaultLinkCopied: any;
    actors: any[];
    sharedUsers: any[];
    shareDashboard: any;
    getSharedUsers: any;
    sso: any;
    sharableActors: any[];
}

function ShareModal(props: ShareModalProps) {
    const {
        isOpen,
        dashboardId,
        dashboardName,
        vaultId,
        closeShareModal,
        getVault,
        vaultLinkCopied,
        actors,
        sharedUsers,
        shareDashboard,
        getSharedUsers,
        sso,
        sharableActors
    } = props;
    const [name, setName] = useState('');
    const [cursor, setCursor] = useState(-1);
    const containerRef = useRef<HTMLDivElement>(null);
    const filteredActorsRef = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);
    const [selectedActorIds, setSelectedActorIds] = useState<string[]>([]);
    const [filteredActors, setFilteredActors] = useState<any[]>([]);
    const [newUserEmails, setNewUserEmails] = useState<string[]>([]);
    const [sendNotification, setSendNotification] = useState(true);
    const [disabled, setDisabled] = useState(true);
    const [loading, setLoading] = useState(false);
    const [deselectedActorIds, setDeselectedActorIds] = useState<string[]>([]);

    let selectedActors = selectedActorIds.length
        ? sharableActors.filter((a: any) =>
              selectedActorIds.includes(a.actorId)
          )
        : [];
    if (newUserEmails.length) {
        const newUsers = newUserEmails.map(e => ({
            actorId: e,
            email: e
        }));
        selectedActors = selectedActors.concat(newUsers);
    }

    let sharedActors = actors.concat(sharedUsers);
    sharedActors = sharedActors.filter(
        s => !deselectedActorIds.includes(s.actorId)
    );
    sharedActors = orderBy(sharedActors, ['email'], ['asc']);

    useEffect(() => {
        if (isOpen) {
            setName('');
            setSelectedActorIds([]);
            setNewUserEmails([]);
            setCursor(-1);
            getVault();
            getSharedUsers();
        }
    }, [isOpen]);

    function handleNameChange(name: string) {
        setName(name);
        const filteredActors = name
            ? sharableActors.filter(
                  a =>
                      a.firstName?.toLowerCase().includes(name.toLowerCase()) ||
                      a.lastName?.toLowerCase().includes(name.toLowerCase()) ||
                      a.email?.toLowerCase().includes(name.toLowerCase())
              )
            : [];
        if (filteredActors.length === 0 && validator.validate(name)) {
            filteredActors.push({
                actorId: name,
                email: name,
                firstName: 'New',
                lastName: 'user'
            });
        }
        setFilteredActors(filteredActors);
    }

    function handleCancel() {
        track(MixPanel.Events.DashboardShareModalCancelClick, {
            'Dashboard Name': dashboardName
        });
        closeShareModal();
    }

    function handleShare() {
        track(MixPanel.Events.DashboardShareModalDoneClick, {
            'Dashboard Name': dashboardName
        });
        setLoading(true);
        shareDashboard(
            dashboardId,
            selectedActorIds,
            deselectedActorIds,
            newUserEmails,
            sendNotification
        ).then(() => {
            setLoading(false);
        });
    }

    function handleCopy() {
        track(MixPanel.Events.DashboardShareModalCopyLinkClick, {
            'Dashboard Name': dashboardName
        });
        const url = window.location.origin + '/vault/' + vaultId;
        window.navigator.clipboard.writeText(url).then(() => {
            vaultLinkCopied();
            closeShareModal();
        });
    }

    function handleActorSelect(actorId: string) {
        const actor = sharableActors.find(a => a.actorId === actorId);
        let newEmail = actorId;
        if (actor) {
            track(MixPanel.Events.DashboardShareModalSelectUser, {
                'Dashboard Name': dashboardName,
                Email: actor.email
            });
            newEmail = actor.email;
            setSelectedActorIds(prevActorIds => {
                const newActorIds = prevActorIds.slice();
                newActorIds.push(actorId);
                return newActorIds;
            });
            setDeselectedActorIds(prev => {
                const index = prev.findIndex(a => a === actorId);
                if (index != -1) {
                    const newState = prev.slice();
                    newState.splice(index, 1);
                    return newState;
                }
                return prev;
            });
        } else {
            track(MixPanel.Events.DashboardShareModalSelectUser, {
                'Dashboard Name': dashboardName,
                Email: actorId
            });
            setNewUserEmails(prevState => {
                const newState = prevState.slice();
                newState.push(actorId);
                return newState;
            });
        }
        const emails = selectedActors.map(sa => sa.email);
        emails.push(newEmail);
        setCheckboxState(emails);
        setName('');
        setCursor(-1);
    }

    function handleSelectionRemove(actorId: string) {
        const actor = sharableActors.find(a => a.actorId === actorId);
        let newEmail = actorId;
        if (actor) {
            track(MixPanel.Events.DashboardShareModalDeselectUser, {
                'Dashboard Name': dashboardName,
                Email: actor.email
            });
            newEmail = actor.email;
            setSelectedActorIds(prevActorIds => {
                return prevActorIds.filter(pa => pa !== actorId);
            });
        } else {
            track(MixPanel.Events.DashboardShareModalDeselectUser, {
                'Dashboard Name': dashboardName,
                Email: actorId
            });
            setNewUserEmails(prevEmails => {
                return prevEmails.filter(pe => pe !== actorId);
            });
        }
        let emails = selectedActors.map(sa => sa.email);
        emails = emails.filter(e => e !== newEmail);
        setCheckboxState(emails);
    }

    function handleActorRemove(actorId: string) {
        setDeselectedActorIds(prev => {
            const newVal = prev.slice();
            newVal.push(actorId);
            return newVal;
        });
        const actor = actors.find(a => a.actorId === actorId);
        if (actor) {
            track(MixPanel.Events.DashboardShareModalRemoveViewer, {
                'Dashboard Name': dashboardName,
                Email: actor.email
            });
        }
    }

    function handleKeyDownForName(e: any) {
        if (e.keyCode === 40 && filteredActors.length > 0) {
            setCursor(0);
            if (filteredActorsRef.current) {
                filteredActorsRef.current.focus();
            }
        }
    }

    function handleKeyDownForActors(e: any) {
        if (e.keyCode === 40 && cursor < filteredActors.length - 1) {
            setCursor(cursor + 1);
        }
        if (e.keyCode === 38) {
            if (cursor > 0) {
                setCursor(cursor - 1);
            } else {
                if (inputRef.current) {
                    inputRef.current.focus();
                }
                setCursor(-1);
            }
        }
        if (e.key === 'Enter') {
            if (cursor >= 0 && cursor < filteredActors.length) {
                handleActorSelect(filteredActors[cursor].actorId);
                if (inputRef.current) {
                    inputRef.current.focus();
                }
            }
        }
    }

    function handleCheckboxChange(newState: any) {
        setSendNotification(newState);
    }

    function setCheckboxState(emails: string[]) {
        const domains = sso.map((s: any) => s.domain);
        const disabled = emails.some(e => {
            const emailDomain = e.split('@')[1];
            return !domains.includes(emailDomain);
        });
        const newSendNotification = disabled ? true : sendNotification;
        setDisabled(disabled);
        setSendNotification(newSendNotification);
    }

    return (
        <Modal
            visible={isOpen}
            width={450}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={handleCancel}
            zIndex={3000}
            className="share-modal-container"
        >
            <div className="share-modal modal" ref={containerRef}>
                <div className="modal__header">
                    <div className="modal__title">Share dashboard</div>
                    <div className="modal__buttons">
                        <Button
                            componentType={ButtonTypes.type.TERTIARY}
                            onClick={handleCancel}
                            classes={['mr8']}
                        >
                            Cancel
                        </Button>
                        <Button onClick={handleShare} loading={loading}>
                            Done
                        </Button>
                    </div>
                </div>
                <div className="modal__content">
                    <div className="share-modal__info">
                        <p>
                            Users with roles that have the “Dashboard: View All”
                            feature permission have access to all dashboards.
                            Users with roles that have the “Dashboard: View
                            Shared” feature permission can only access
                            dashboards that are shared with them.
                        </p>
                        <p>
                            The users you enter below will receive an email
                            notification to access this dashboard. You can also
                            remove access for any added users by clicking the
                            “X” next to their name. All changes will take effect
                            after you click Done above.
                        </p>
                    </div>
                    <div className="modal__label mb8">Add people</div>
                    <OpenText
                        placeholder="Type name or email"
                        value={name}
                        onChange={handleNameChange}
                        onKeyDown={handleKeyDownForName}
                        inputRef={inputRef}
                    />
                    {!!name && (
                        <div
                            className="share-modal__actors"
                            onKeyDown={handleKeyDownForActors}
                            tabIndex={1}
                            ref={filteredActorsRef}
                        >
                            {filteredActors.length === 0 && (
                                <div className="share-modal__actors__empty">
                                    No users matched the search text.
                                    <br />
                                    Enter an email address to share this
                                    dashboard with a new user. They will be
                                    invited to Dandi with a Viewer role.
                                </div>
                            )}
                            {filteredActors.map((fa, i) => (
                                <Actor
                                    key={fa.actorId}
                                    focus={cursor === i}
                                    actor={fa}
                                    onClick={() =>
                                        handleActorSelect(fa.actorId)
                                    }
                                />
                            ))}
                        </div>
                    )}
                    {selectedActors.length ? (
                        <div className="share-modal__pills">
                            {selectedActors.map(sa => (
                                <Pill
                                    key={sa.actorId}
                                    text={sa.email}
                                    onRemove={handleSelectionRemove.bind(
                                        null,
                                        sa.actorId
                                    )}
                                />
                            ))}
                        </div>
                    ) : null}
                    {selectedActors.length > 0 && (
                        <Tooltip
                            title="Email notification can be suppressed only if SSO is enabled for all recipients"
                            placement="top"
                            getPopupContainer={() =>
                                containerRef.current as HTMLElement
                            }
                        >
                            <Checkbox
                                checked={sendNotification}
                                onClick={handleCheckboxChange}
                                disabled={disabled}
                            >
                                Send email notification
                            </Checkbox>
                        </Tooltip>
                    )}
                    {sharedActors.length ? (
                        <div className="share-modal__selection">
                            {sharedActors.map(sa => (
                                <Actor
                                    key={sa.actorId}
                                    actor={sa}
                                    onRemove={
                                        actors.every(
                                            (a: any) => a.actorId !== sa.actorId
                                        )
                                            ? () =>
                                                  handleActorRemove(sa.actorId)
                                            : undefined
                                    }
                                />
                            ))}
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="link-modal modal">
                <div className="modal__header">
                    <div className="modal__title">Get link</div>
                    <div className="modal__buttons">
                        <Button
                            componentType={ButtonTypes.type.SECONDARY}
                            onClick={handleCopy}
                        >
                            Copy link
                        </Button>
                    </div>
                </div>
                <div className="modal__content">
                    Only people within your organization with this link can
                    access this dashboard.
                </div>
            </div>
        </Modal>
    );
}

ShareModal.defaultProps = {
    sharedUsers: []
};

function mapState(state: any) {
    const {
        shareDashboardId: dashboardId,
        vaultMap,
        showShare: isOpen,
        sharedUsers
    } = state.dashboards;
    const dashboard = state.dashboards[dashboardId];
    const dashboardName = dashboard ? dashboard.name : '';

    const allUsers = state.admin.users.allUsers;
    const allRoles = state.admin.roles.allRoles;

    const actors = allUsers.filter((user: any) => {
        return user.role.some((role: any) => {
            const roleObj = allRoles.find((r: any) => r.id === role.id);
            return roleObj?.staticPermissions.some(
                (p: any) => p.action === 'Dashboards/ViewAll'
            );
        });
    });

    let sharableActors = allUsers.filter((user: any) => {
        return user.role.some((role: any) => {
            const roleObj = allRoles.find((r: any) => r.id === role.id);

            return roleObj?.staticPermissions.some(
                (p: any) => p.action === 'Dashboards/ViewShared'
            );
        });
    });

    sharableActors = sharableActors.filter((s: any) =>
        actors.every((a: any) => a.actorId !== s.actorId)
    );

    return {
        dashboardId,
        dashboardName,
        isOpen,
        sharedUsers,
        vaultId: vaultMap && vaultMap[dashboardId],
        actors,
        sso: state.admin.integrations.sso.data,
        sharableActors
    };
}

const dispatchProps = {
    closeShareModal,
    getVault,
    vaultLinkCopied,
    shareDashboard,
    getSharedUsers
};

export default connect(mapState, dispatchProps)(ShareModal);

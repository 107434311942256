import { createSlice } from '@reduxjs/toolkit';
import actionTypes from '../constants/ActionTypes';
import { FieldsState } from './types';

const initialState: FieldsState = {
    mappings: {}
};

const slice = createSlice({
    name: 'enterprise-fields',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(actionTypes.GetFieldsPending, (state, action) => {
            state.pending = true;
        });
        builder.addCase(
            actionTypes.GetFieldsFulfilled,
            (state, action: any) => {
                state.pending = false;
                state.loaded = true;
                state.fields = action.payload;
            }
        );
        builder.addCase(actionTypes.GetFieldsRejected, (state, action: any) => {
            state.pending = false;
            state.loaded = false;
            state.error = action.payload;
        });
        builder.addCase(actionTypes.SetFilterLabels, (state, action: any) => {
            state.mappings = action.labels;
        });
    }
});

export default slice.reducer;

import React from 'react';
import CaretLeft from '../../../icons/CaretLeft';
import CaretRight from '../../../icons/CaretRight';
import { EVENT_HEIGHT, MONTH_WIDTH } from './TimelineContent';
import moment from 'moment';

interface DatesProps {
    dates: string[];
    goToPast: () => void;
    goToFuture: () => void;
}

const Dates = ({ dates, goToPast, goToFuture }: DatesProps) => {
    const dateRef = React.useRef<HTMLDivElement>(null);

    const calculateMonthWidth = (date: string) => {
        const daysInMonth = moment(date, 'MMM YY').daysInMonth();
        return (MONTH_WIDTH / 31) * daysInMonth;
    };

    return (
        <div className="dates" style={{ position: 'relative' }}>
            <div onClick={goToPast} className="caret caret--left">
                <CaretLeft />
            </div>
            {dates.map(date => {
                return (
                    <div
                        key={date}
                        ref={dateRef}
                        className="date"
                        style={{
                            width: calculateMonthWidth(date),
                            minWidth: calculateMonthWidth(date),
                            height: EVENT_HEIGHT
                        }}
                    >
                        {date}
                    </div>
                );
            })}
            <div onClick={goToFuture} className="caret caret--right">
                <CaretRight />
            </div>
        </div>
    );
};

export default Dates;

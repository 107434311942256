import { Role, UserRolesData } from './model';
import uniq from 'lodash/uniq';

export function generateUserRolesData(rolesResponseData: any[]): UserRolesData {
    const rolesData = rolesResponseData.reduce(
        (acc, role: Role) => ({
            ...acc,
            staticPermissions: uniq([
                ...acc.staticPermissions,
                ...role.staticPermissions
            ]),
            dynamicPermissions: uniq([
                ...acc.dynamicPermissions,
                ...role.dynamicPermissions
            ]),
            flags: { ...acc.flags, ...role.flags }
        }),
        {
            staticPermissions: [],
            dynamicPermissions: [],
            flags: {}
        }
    );

    return rolesData;
}

import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 53 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                x={1.724}
                y={1.969}
                width={50}
                height={50}
                rx={5}
                fill="var(--color-shade-h3)"
                stroke="var(--color-graph-6)"
                strokeWidth={2}
            />
            <path fill="var(--color-graph-6)" d="M18.744 17.012h14v2h-14z" />
            <path fill="var(--color-graph-1)" d="M18.744 35.012h10v2h-10z" />
            <path fill="var(--color-graph-6)" d="M36.744 17.012h14v2h-14z" />
            <path fill="var(--color-graph-1)" d="M36.744 35.012h14v2h-14z" />
            <path fill="var(--color-graph-6)" d="M8.724 17.012h4v2h-4z" />
            <path fill="var(--color-graph-1)" d="M8.724 35.012h4v2h-4z" />
            <path
                fill="var(--color-graph-5)"
                d="M18.744 23.012h7v2h-7zM36.744 23.012h13.98v2h-13.98zM8.724 23.012h5v2h-5z"
            />
            <path
                fill="var(--color-graph-14)"
                d="M18.744 29.012h13v2h-13zM36.744 29.012h14v2h-14zM8.724 29.012h3v2h-3z"
            />
        </svg>
    );
}

export default SvgComponent;

import React from 'react';

import Button from '../../../common/Button';
import Modal from '../../../common/Modal';
import { addMessage } from '../../../common/actions';
import ButtonTypes from '../../../constants/ButtonTypes';
import MixPanel from '../../../constants/MixPanel';
import { track } from '../../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { deleteRole } from '../../roles/actions';
import { clearToDelete } from '../../roles/reducer';

interface DeleteRoleModalProps {}

const DeleteRoleModal = (props: DeleteRoleModalProps) => {
    const dispatch = useAppDispatch();
    const { toDelete, allRoles } = useAppSelector(state => state.admin.roles);
    const [deleteDisabled, setDeleteDisabled] = React.useState(false);

    const handleDelete = async (event: any) => {
        event.stopPropagation();
        setDeleteDisabled(true);
        const roleName = allRoles.find(role => role.id === toDelete)?.name;
        //@ts-ignore
        await dispatch(deleteRole(toDelete));
        dispatch(clearToDelete());
        dispatch(addMessage(`"${roleName}" has been deleted`));
        track(MixPanel.Events.AdminRolesPermissionsClickDeleteRole, {
            roleId: toDelete,
            name: roleName
        });
        setDeleteDisabled(false);
    };

    const handleCancel = () => {
        dispatch(clearToDelete());
    };

    return (
        <Modal
            visible={!!toDelete}
            destroyOnClose
            width={450}
            footer={null}
            closable={false}
            maskClosable
            onCancel={handleCancel}
            zIndex={4000}
        >
            <div className="modal">
                <div className="modal__header">
                    <div className="modal__title">Delete Role</div>
                </div>
                <div className="modal__content">
                    <p>
                        Click Delete to remove this role. It will be unassigned
                        from all the users currently assigned to it.
                    </p>
                    <p>IMPORTANT: This action cannot be undone</p>
                </div>
            </div>
            <div className="modal__footer">
                <Button
                    componentType={ButtonTypes.type.TERTIARY}
                    onClick={handleCancel}
                >
                    Cancel
                </Button>
                <Button onClick={handleDelete} disabled={deleteDisabled}>
                    Delete
                </Button>
            </div>
        </Modal>
    );
};

export default DeleteRoleModal;

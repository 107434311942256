import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import SortableHead from '../../common/SortableHead';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import getFormattedValue from '../../lib/getFormattedValue';
import useTableScroll from '../../common/useTableScroll';
import ScrollArrows from '../../common/ScrollArrows';
import precisionRound from '../../lib/precisionRound';
import getPeriod from '../../lib/getPeriod';
import { track } from '../../lib/segment';
import MixPanel from '../../constants/MixPanel';
import Masking from '../../common/Masking';
import Stack from '../../common/Stack';
import Eye from '../../icons/Eye';

function LeftTable({
    data,
    sortCriteria,
    onSort,
    tableRowHeight,
    showSinglePeriod,
    setActiveLabel,
    clearActiveLabel,
    toggleLabel,
    tableActive
}) {
    return (
        <table className="table__left-table">
            <thead onClick={e => e.stopPropagation()}>
                {!showSinglePeriod && (
                    <tr style={{ height: tableRowHeight }}>
                        <th className="table__divider" />
                    </tr>
                )}
                <tr style={{ height: tableRowHeight }}>
                    <SortableHead
                        label="Segment"
                        columnName="text"
                        sortCriteria={sortCriteria}
                        onSort={onSort}
                        className="table__divider"
                    />
                </tr>
            </thead>
            <tbody>
                {data.map(d => (
                    <tr
                        key={d.label}
                        className={
                            d.active || d.selected ? 'table__selected' : ''
                        }
                        style={{
                            height: tableRowHeight,
                            opacity: tableActive
                                ? d.active || d.selected
                                    ? 1
                                    : 0.24
                                : 1
                        }}
                        onMouseOver={setActiveLabel.bind(null, d.label)}
                        onMouseOut={clearActiveLabel.bind(null, d.label)}
                        onClick={toggleLabel.bind(null, d.label)}
                    >
                        <td
                            className="table__divider"
                            style={{ cursor: 'pointer' }}
                        >
                            <span
                                dangerouslySetInnerHTML={{
                                    __html: d.segment.html
                                }}
                            />
                        </td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

LeftTable.propTypes = {
    data: PropTypes.array,
    sortCriteria: PropTypes.object,
    onSort: PropTypes.func,
    tableRowHeight: PropTypes.number,
    showSinglePeriod: PropTypes.bool,
    setActiveLabel: PropTypes.func,
    clearActiveLabel: PropTypes.func,
    toggleLabel: PropTypes.func,
    tableActive: PropTypes.bool
};

function HeaderRow(props) {
    const { date, interval, sortCriteria, onSort, expanded, columnProps } =
        props;

    const label = getPeriod(date, interval.toLowerCase());
    const colPrefix = label.toLowerCase().replace(/ /g, '-');
    const expandable = columnProps.length > 2;
    return !expandable || expanded ? (
        columnProps.map(columnProp => (
            <SortableHead
                key={columnProp.header}
                label={columnProp.header}
                columnName={colPrefix + '-' + columnProp.source}
                sortCriteria={sortCriteria}
                onSort={onSort}
                className="table__divider"
            />
        ))
    ) : (
        <SortableHead
            label={columnProps[0].header}
            columnName={colPrefix + '-' + columnProps[0].source}
            sortCriteria={sortCriteria}
            onSort={onSort}
            className="table__divider"
        />
    );
}

HeaderRow.propTypes = {
    date: PropTypes.any,
    interval: PropTypes.string,
    sortCriteria: PropTypes.object,
    onSort: PropTypes.func,
    expanded: PropTypes.bool,
    columnProps: PropTypes.array
};

function TableRow(props) {
    const {
        reportId,
        dataItem,
        index,
        odd,
        analysisType,
        navigate,
        expanded,
        disableLinks,
        changeOption,
        columnProps
    } = props;

    function handleNavigate(navProps, employees, employeeCountType, e) {
        if (disableLinks) return;
        e.preventDefault();
        if (reportId) {
            navigate(reportId, navProps, employees, employeeCountType, index);
        } else {
            navigate(navProps, employees, employeeCountType, index);
        }
    }

    function getChange(current, previous, propName) {
        if (
            !previous ||
            changeOption === 'None' ||
            dataItem.isEqualityIndex ||
            dataItem.isAttritionIndex
        )
            return '';
        if (
            changeOption === 'Percentage' &&
            precisionRound(previous[propName], 1) === 0
        ) {
            return '(---)';
        }
        let text;
        if (changeOption === 'Value') {
            const change = precisionRound(
                current[propName] - previous[propName],
                1
            );
            text = change > 0 ? ` (+${change})` : ` (${change})`;
        }
        if (changeOption === 'Percentage') {
            const change = precisionRound(
                ((current[propName] - previous[propName]) * 100) /
                    previous[propName],
                1
            );
            text = change > 0 ? ` (+${change}%)` : ` (${change}%)`;
        }
        return (
            <span className="table__text table__text--secondary">{text}</span>
        );
    }

    const seriesItem = dataItem.series[index];
    let prevSeriesItem;
    if (index > 0) {
        prevSeriesItem = dataItem.series[index - 1];
    }

    const className = classNames('table__divider', {
        table__odd: odd,
        clickable: !disableLinks && seriesItem
    });
    const empClassName = classNames('table__divider', {
        table__odd: odd
    });

    const expandable = columnProps.length > 2;

    if (!seriesItem) {
        return (
            <Fragment>
                <td className={className}>
                    <span>---</span>
                </td>
                {(!expandable || expanded) &&
                    columnProps
                        .slice(1)
                        .map(columnProp => (
                            <td
                                key={columnProp.header}
                                className={empClassName}
                            ></td>
                        ))}
            </Fragment>
        );
    }
    const primaryFieldKey = columnProps[0]?.source;
    return (
        <Fragment>
            <td
                className={className}
                onClick={handleNavigate.bind(
                    null,
                    dataItem.navProps,
                    false,
                    undefined
                )}
            >
                <span>
                    {getFormattedValue(
                        analysisType,
                        seriesItem[primaryFieldKey]
                    )}
                    {getChange(seriesItem, prevSeriesItem, primaryFieldKey)}
                </span>
            </td>
            {(!expandable || expanded) &&
                columnProps.slice(1).map(columnProp => (
                    <td className={empClassName}>
                        <Masking
                            value={seriesItem[columnProp.source]}
                            unit={columnProp.unit}
                            hiddenDisplay={
                                <Stack
                                    style={{
                                        color: 'var(--color-ui-20)'
                                    }}
                                >
                                    <Eye />
                                </Stack>
                            }
                        />
                    </td>
                ))}
        </Fragment>
    );
}

TableRow.defaultProps = {
    changeOption: 'None'
};

TableRow.propTypes = {
    reportId: PropTypes.string,
    dataItem: PropTypes.object,
    index: PropTypes.number,
    odd: PropTypes.bool,
    lifecycle: PropTypes.string,
    analysisType: PropTypes.string,
    navigate: PropTypes.func,
    expanded: PropTypes.bool,
    disableLinks: PropTypes.bool,
    changeOption: PropTypes.string
};

function RightTable(props) {
    const {
        reportId,
        lifecycle,
        analysisType,
        attributeName,
        data,
        dates,
        interval,
        onSort,
        sortCriteria,
        navigate,
        expanded,
        toggleExpansion,
        tableRowHeight,
        changeOption,
        disableLinks,
        tableActive,
        columnProps
    } = props;

    const expandable = columnProps.length > 2;

    function getColspan() {
        if (columnProps.length > 2) {
            return expanded ? columnProps.length : 1;
        } else {
            return columnProps.length;
        }
    }

    return (
        <table className="table__right-table">
            <thead onClick={e => e.stopPropagation()}>
                <tr style={{ height: tableRowHeight }}>
                    {dates.map(date => (
                        <th
                            key={date}
                            colSpan={getColspan()}
                            className="table__divider"
                        >
                            <div className="table__flexhead">
                                <span className="table__flexhead__text">
                                    {getPeriod(date, interval.toLowerCase())}
                                </span>
                                {expandable && (
                                    <span className="table__flexhead__expand">
                                        {expanded ? (
                                            <MdChevronLeft
                                                size={18}
                                                onClick={toggleExpansion}
                                            />
                                        ) : (
                                            <MdChevronRight
                                                size={18}
                                                onClick={toggleExpansion}
                                            />
                                        )}
                                    </span>
                                )}
                            </div>
                        </th>
                    ))}
                    <th />
                </tr>
                <tr style={{ height: tableRowHeight }}>
                    {dates.map(date => (
                        <HeaderRow
                            key={date}
                            date={date}
                            interval={interval}
                            sortCriteria={sortCriteria}
                            onSort={onSort}
                            expanded={expanded}
                            columnProps={columnProps}
                        />
                    ))}
                    <th />
                </tr>
            </thead>
            <tbody>
                {data.map(d => (
                    <tr
                        key={d.label}
                        style={{
                            height: tableRowHeight,
                            opacity: tableActive
                                ? d.active || d.selected
                                    ? 1
                                    : 0.24
                                : 1
                        }}
                        className={
                            d.active || d.selected ? 'table__selected' : ''
                        }
                    >
                        {dates.map((date, index) => {
                            const label = getPeriod(
                                date,
                                interval.toLowerCase()
                            );
                            return (
                                <TableRow
                                    reportId={reportId}
                                    key={label}
                                    dataItem={d}
                                    index={index}
                                    odd={dates.length > 1 && index % 2 === 0}
                                    lifecycle={lifecycle}
                                    attributeName={attributeName}
                                    analysisType={analysisType}
                                    navigate={navigate}
                                    expanded={expanded}
                                    changeOption={changeOption}
                                    disableLinks={disableLinks}
                                    columnProps={columnProps}
                                />
                            );
                        })}
                        <td />
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

RightTable.propTypes = {
    reportId: PropTypes.string,
    lifecycle: PropTypes.string,
    analysisType: PropTypes.string,
    employeeAttribute: PropTypes.string,
    employeeAttributes: PropTypes.array,
    diversityAttributes: PropTypes.array,
    data: PropTypes.array,
    dates: PropTypes.array,
    interval: PropTypes.string,
    pending: PropTypes.bool,
    onSort: PropTypes.func,
    sortCriteria: PropTypes.object,
    navigate: PropTypes.func,
    expanded: PropTypes.bool,
    toggleExpansion: PropTypes.func,
    attributeName: PropTypes.string,
    tableRowHeight: PropTypes.number,
    changeOption: PropTypes.string,
    disableLinks: PropTypes.bool,
    tableActive: PropTypes.bool,
    columnProps: PropTypes.array
};

function RightTableSingle(props) {
    const {
        reportId,
        lifecycle,
        analysisType,
        attributeName,
        data,
        date,
        interval,
        onSort,
        sortCriteria,
        navigate,
        tableRowHeight,
        currentIndex,
        disableLinks,
        tableActive,
        columnProps
    } = props;

    return (
        <table className="table__right-table">
            <thead>
                <tr style={{ height: tableRowHeight }}>
                    <HeaderRow
                        date={date}
                        interval={interval}
                        sortCriteria={sortCriteria}
                        onSort={onSort}
                        expanded
                        columnProps={columnProps}
                    />
                    <th />
                </tr>
            </thead>
            <tbody>
                {data.map(d => (
                    <tr
                        key={d.label}
                        style={{
                            height: tableRowHeight,
                            opacity: tableActive
                                ? d.active || d.selected
                                    ? 1
                                    : 0.24
                                : 1
                        }}
                        className={
                            d.active || d.selected ? 'table__selected' : ''
                        }
                    >
                        <TableRow
                            reportId={reportId}
                            dataItem={d}
                            index={currentIndex}
                            lifecycle={lifecycle}
                            attributeName={attributeName}
                            analysisType={analysisType}
                            navigate={navigate}
                            expanded
                            disableLinks={disableLinks}
                            columnProps={columnProps}
                        />
                        <td />
                    </tr>
                ))}
            </tbody>
        </table>
    );
}

RightTableSingle.propTypes = {
    reportId: PropTypes.string,
    lifecycle: PropTypes.string,
    analysisType: PropTypes.string,
    employeeAttribute: PropTypes.string,
    employeeAttributes: PropTypes.array,
    diversityAttributes: PropTypes.array,
    data: PropTypes.array,
    date: PropTypes.any,
    index: PropTypes.number,
    interval: PropTypes.string,
    pending: PropTypes.bool,
    onSort: PropTypes.func,
    sortCriteria: PropTypes.object,
    navigate: PropTypes.func,
    disableLinks: PropTypes.bool,
    attributeName: PropTypes.string,
    tableRowHeight: PropTypes.number,
    currentIndex: PropTypes.number,
    tableActive: PropTypes.bool,
    columnProps: PropTypes.array
};

export default function Table({
    reportId,
    lifecycle,
    analysisType,
    dates,
    currentIndex,
    interval,
    pending,
    width,
    tableRowHeight,
    options,
    height,
    useMaxHeightOnTable,
    navigate,
    disableLinks,
    setActiveLabel,
    clearActiveLabel,
    toggleLabel,
    expanded,
    toggleExpansion,
    data,
    sortCriteria,
    onSort,
    dashboardName,
    title,
    role,
    columnProps
}) {
    const [showLeft, showRight, paddingTop, containerRef, tableRef] =
        useTableScroll(!pending);

    const tableActive = data?.some(d => d.active || d.selected);

    function handleToggle(...args) {
        track(MixPanel.Events.DashboardReportTableRowHighlight, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
        toggleLabel(...args);
    }

    function handleNavigate(...args) {
        track(MixPanel.Events.DashboardReportTableValueClick, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
        navigate(...args);
    }

    const showSinglePeriod =
        typeof options.showSinglePeriod === 'string'
            ? options.showSinglePeriod === 'true'
            : options.showSinglePeriod;

    const { changeOption = 'None' } = options;

    return (
        <div
            className={
                showLeft || showRight ? 'table table--extended' : 'table'
            }
            ref={containerRef}
            style={{
                width,
                maxHeight: useMaxHeightOnTable ? height : undefined
            }}
        >
            <div className="table__left">
                <LeftTable
                    data={data}
                    onSort={onSort}
                    sortCriteria={sortCriteria}
                    tableRowHeight={tableRowHeight}
                    showSinglePeriod={showSinglePeriod}
                    setActiveLabel={setActiveLabel}
                    clearActiveLabel={clearActiveLabel}
                    toggleLabel={handleToggle}
                    tableActive={tableActive}
                />
            </div>
            <div className="table__right" ref={tableRef}>
                {showSinglePeriod ? (
                    <RightTableSingle
                        reportId={reportId}
                        lifecycle={lifecycle}
                        analysisType={analysisType}
                        data={data}
                        currentIndex={currentIndex}
                        date={dates[currentIndex]}
                        index={dates.length - 1}
                        interval={interval}
                        onSort={onSort}
                        sortCriteria={sortCriteria}
                        tableRowHeight={tableRowHeight}
                        navigate={handleNavigate}
                        disableLinks={disableLinks || role === 'Viewer'}
                        tableActive={tableActive}
                        columnProps={columnProps}
                    />
                ) : (
                    <RightTable
                        reportId={reportId}
                        lifecycle={lifecycle}
                        analysisType={analysisType}
                        data={data}
                        dates={dates}
                        interval={interval}
                        onSort={onSort}
                        sortCriteria={sortCriteria}
                        expanded={expanded}
                        toggleExpansion={toggleExpansion}
                        tableRowHeight={tableRowHeight}
                        navigate={handleNavigate}
                        changeOption={changeOption}
                        disableLinks={disableLinks || role === 'Viewer'}
                        tableActive={tableActive}
                        columnProps={columnProps}
                    />
                )}
            </div>
            <ScrollArrows
                showLeft={showLeft}
                showRight={showRight}
                paddingTop={paddingTop}
                scrollRef={tableRef}
                scrollWidth={145}
            />
        </div>
    );
}

Table.propTypes = {
    reportId: PropTypes.string,
    data: PropTypes.array,
    dates: PropTypes.array,
    currentIndex: PropTypes.number,
    lifecycle: PropTypes.string,
    analysisType: PropTypes.string,
    interval: PropTypes.string,
    pending: PropTypes.bool,
    width: PropTypes.number,
    tableRowHeight: PropTypes.number,
    options: PropTypes.object,
    height: PropTypes.number,
    useMaxHeightOnTable: PropTypes.bool,
    navigate: PropTypes.func,
    disableLinks: PropTypes.bool,
    setActiveLabel: PropTypes.func,
    clearActiveLabel: PropTypes.func,
    toggleLabel: PropTypes.func,
    expanded: PropTypes.bool,
    toggleExpansion: PropTypes.func,
    sortCriteria: PropTypes.object,
    onSort: PropTypes.func,
    dashboardName: PropTypes.string,
    title: PropTypes.string,
    role: PropTypes.string,
    columnProps: PropTypes.array
};

import RbacActions from '../constants/RbacActions';
import { IAdminTab } from '../roles/types';

export const adminTabs: IAdminTab[] = [
    { key: 'users', name: 'Users', actions: [RbacActions['Users/View']] },
    { key: 'groups', name: 'Groups', actions: [RbacActions['Users/View']] },
    {
        key: 'roles',
        name: 'Roles & Permissions',
        actions: [RbacActions['Roles/View']]
    },
    {
        key: 'integrations',
        name: 'Integrations',
        actions: [RbacActions['Integrations/View']]
    },
    { key: 'payment', name: 'Payment', actions: [RbacActions['Billing/View']] },
    {
        key: 'settings',
        name: 'Settings',
        actions: [RbacActions['Settings/View']]
    }
];

import React, { useState, useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import TextField from './TextField';
import measureText from '../lib/measureText';
import CanvasFonts from '../constants/CanvasFonts';

const CLOSE_ICON_SIZE = 16;
const PADDING_RIGHT = CLOSE_ICON_SIZE + 8;

export default function OpenText({
    value,
    onChange,
    placeholder,
    autoFocus,
    paddingLeft,
    onKeyDown,
    showCloseAlways,
    onClose,
    inputRef,
    expand,
    isSearch,
    style: styleProp,
    contentStyle: contentStyleProp
}: any) {
    const [showClose, setShowClose] = useState(autoFocus);
    const widthObject = useRef({ width: 0 }).current;
    const compRef = useRef<HTMLDivElement>(null);
    const fallbackRef = useRef<HTMLInputElement>();
    const inputRefWithDefault = inputRef || fallbackRef;
    const style: any = {};
    const inputStyle: any = {};
    if (paddingLeft) {
        inputStyle.paddingLeft = paddingLeft;
    }
    if (showClose || (value && showCloseAlways) || expand) {
        inputStyle.paddingRight = PADDING_RIGHT;
    }
    if (expand) {
        const { width } = computeWidth(value);
        if (width > widthObject.width) {
            widthObject.width = width;
        }
        if (
            compRef.current &&
            widthObject.width > compRef.current.offsetWidth
        ) {
            widthObject.width = compRef.current.offsetWidth;
        }
        style.width = widthObject.width;
    }

    function computeWidth(value: string) {
        const width =
            measureText(value, CanvasFonts.Regular18).width +
            paddingLeft +
            PADDING_RIGHT;
        return { width };
    }

    useEffect(() => {
        window.addEventListener('mousedown', shouldBlur);
        return () => {
            window.removeEventListener('mousedown', shouldBlur);
        };
    }, []);

    function shouldBlur(e: any) {
        if (!compRef.current?.contains(e.target)) {
            setShowClose(false);
        }
    }

    function handleCloseClick() {
        if (onClose) {
            onClose();
        }
        onChange('');
        inputRefWithDefault.current.focus();
    }

    function handleKeyDown(e: any) {
        if (e.key === 'Tab') {
            setShowClose(false);
        }
        if (onKeyDown) {
            onKeyDown(e);
        }
    }

    return (
        <div className="open-text" ref={compRef} style={styleProp}>
            <div
                className="open-text__wrapper"
                style={{ ...style, ...contentStyleProp }}
            >
                <TextField
                    ref={inputRefWithDefault}
                    autoFocus={autoFocus}
                    placeholder={placeholder}
                    value={value}
                    onChange={e => onChange(e.target.value)}
                    onFocus={() => setShowClose(true)}
                    onKeyDown={handleKeyDown}
                    showClose={showClose || (value && showCloseAlways)}
                    onClose={handleCloseClick}
                    isSearch={isSearch}
                ></TextField>
            </div>
        </div>
    );
}

OpenText.defaultProps = {
    autoFocus: true,
    paddingLeft: 0,
    isSearch: false
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import sanitize from '../../lib/sanitize';
import OpenText from '../../common/OpenText';
import Modal from '../../common/Modal';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';

export default function CreateDashboardModal(props) {
    const { isOpen, onSave, onCancel } = props;
    const [name, setName] = useState('');

    useEffect(() => {
        if (isOpen) {
            setName('');
        }
    }, [isOpen]);

    function handleNameChange(name) {
        setName(name);
    }

    function handleSave(e) {
        e.preventDefault();
        let dashboardName = name.trim();
        dashboardName = sanitize(dashboardName);
        onSave(dashboardName);
    }

    return (
        <Modal
            visible={isOpen}
            width={450}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={onCancel}
            zIndex={3000}
            destroyOnClose
        >
            <div className="create-dashboard-modal modal">
                <form onSubmit={handleSave}>
                    <div className="modal__header">
                        <div className="modal__title">Create new dashboard</div>
                        <div className="modal__buttons">
                            <Button
                                componentType={ButtonTypes.type.TERTIARY}
                                onClick={() => onCancel()}
                            >
                                Cancel
                            </Button>
                            <Button onClick={handleSave} disabled={!name}>
                                Save
                            </Button>
                        </div>
                    </div>
                    <div className="modal__content">
                        <div className="create-dashboard-modal__prompt">
                            Name your dashboard
                        </div>
                        <OpenText
                            value={name}
                            onChange={handleNameChange}
                            placeholder="Enter name here"
                        />
                    </div>
                </form>
            </div>
        </Modal>
    );
}

CreateDashboardModal.propTypes = {
    isOpen: PropTypes.bool,
    onSave: PropTypes.func,
    onCancel: PropTypes.func
};

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal';
import Button from './Button';
import ButtonTypes from '../constants/ButtonTypes';

export default function DeleteModal(props: any) {
    const { isOpen, pending, onAction, onCancel, title, children } = props;
    return (
        <Modal
            visible={isOpen}
            width={400}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={onCancel}
            zIndex={3000}
        >
            <div
                className="modal modal--delete"
                onClick={e => e.stopPropagation()}
            >
                <div className="modal__header">
                    <div className="modal__title">{title}</div>
                </div>
                <div className="modal__content">{children}</div>
                <div className="modal__footer">
                    <Button
                        componentType={ButtonTypes.type.TERTIARY}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button loading={pending} onClick={onAction} danger>
                        Delete
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

DeleteModal.propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    onAction: PropTypes.func,
    pending: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.node
};

import { combineReducers } from 'redux';
import goalsReducer from './goals/reducer';
import goalReducer from './goal/reducer';
import settingsReducer from './settings/reducer';

export default combineReducers({
    goals: goalsReducer,
    goal: goalReducer,
    settings: settingsReducer
});

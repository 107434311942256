import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import GridRow from './GridRow';
import GridColumn from './GridColumn';
import ChartWrapper from '../chart/ChartWrapper';
import GridConstants from '../../constants/GridConstants';
import ChartTypes from '../../constants/ChartTypes';

export default function Grid({
    title,
    setTitle,
    chartType,
    length,
    setLength,
    options,
    generatePending,
    colWidth,
    generated,
    hasBenchmark
}) {
    const [rowHeight, setRowHeight] = useState();
    const emptyCols = Array(GridConstants.ColumnCount - length).fill(1);

    useEffect(() => {
        const rowHeight = 2 * colWidth + GridConstants.ColumnGap;
        setRowHeight(rowHeight);
    }, [colWidth]);

    useEffect(() => {
        if (
            chartType === ChartTypes.Table ||
            chartType === ChartTypes.ArithmeticTable ||
            chartType === ChartTypes.Stats ||
            chartType === ChartTypes.Funnel
        ) {
            setLength(GridConstants.ColumnCount);
        }
    }, [chartType]);

    function handleIncrementLength() {
        setLength(length + 1);
    }

    return (
        <div className="grid">
            <GridRow
                isTableRow={
                    chartType === ChartTypes.Table ||
                    chartType === ChartTypes.Matrix ||
                    chartType === ChartTypes.ArithmeticTable ||
                    chartType === ChartTypes.Funnel ||
                    chartType === ChartTypes.HorizontalBar
                }
                generatePending={generatePending}
                rowHeight={rowHeight}
            >
                <GridColumn
                    length={length}
                    gap={GridConstants.ColumnGap}
                    colWidth={colWidth}
                    chartType={chartType}
                    incrementLength={handleIncrementLength}
                    generated={generated}
                    hasBenchmark={hasBenchmark}
                >
                    <ChartWrapper
                        title={title}
                        setTitle={setTitle}
                        chartType={chartType}
                        length={length}
                        colWidth={colWidth}
                        rowHeight={rowHeight}
                        options={options}
                    />
                </GridColumn>
                {emptyCols.map((_, i) => (
                    <GridColumn key={i} colWidth={colWidth} />
                ))}
            </GridRow>
        </div>
    );
}

Grid.propTypes = {
    title: PropTypes.string,
    setTitle: PropTypes.func,
    chartType: PropTypes.string,
    length: PropTypes.number,
    setLength: PropTypes.func,
    options: PropTypes.object,
    generatePending: PropTypes.bool,
    colWidth: PropTypes.number,
    generated: PropTypes.bool,
    hasBenchmark: PropTypes.bool
};

import React from 'react';
import { IconProps } from './types';

const ArrowUpRight = ({ size, color }: IconProps) => {
    return (
        <svg
            width={size}
            height={size}
            viewBox="0 0 28 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27.5 9.39444V7H23.6441V9.39444C23.6441 14.5681 19.4009 18.7841 14.0043 18.7841C8.60775 18.7841 4.36452 14.5681 4.36452 9.39444V7H0.5V9.39444C0.5 16.8685 6.67118 23 14.0043 23C21.3288 23 27.5 16.8685 27.5 9.39444Z"
                fill={color}
            />
            <path d="M21.5 0H17.5V4H21.5V0Z" fill={color} />
            <path d="M10.5 0H6.5V4H10.5V0Z" fill={color} />
        </svg>
    );
};

export default ArrowUpRight;

import ActionTypes from '../../constants/ActionTypes';
import axios from 'axios';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';

export function getConfigurations() {
    return async (dispatch: any) => {
        try {
            dispatch({
                type: ActionTypes.GetConfigurationsPending
            });
            const response = await axios.post(
                `${Urls.DataStudioApi}templates/configuration/all`,
                {}
            );

            dispatch({
                type: ActionTypes.GetConfigurationsFulfilled,
                data: response.data
            });
        } catch (error: any) {
            errorHandler.report(error);
            dispatch({
                type: ActionTypes.GetConfigurationsRejected
            });
        }
    };
}

export function saveConfiguration(configuration: any) {
    return async (dispatch: any, getState: any) => {
        try {
            dispatch({
                type: ActionTypes.SaveConfigurationPending
            });
            await axios.put(
                `${Urls.DataStudioApi}template/configuration`,
                configuration
            );
            dispatch({
                type: ActionTypes.SaveConfigurationFulfilled,
                configuration
            });
        } catch (error: any) {
            errorHandler.report(error);
            dispatch({
                type: ActionTypes.SaveConfigurationRejected
            });
        }
    };
}

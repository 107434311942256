import ActionTypes from '../../constants/ActionTypes';
import { Template } from '../../templates/models';
import { FilterSegment } from '../../types';

export function clearDashboardFilter(dashboardId: string) {
    return (dispatch: any) => {
        dispatch({
            type: ActionTypes.ClearFiltersInDashboard,
            dashboardId
        });
    };
}

export function clearDashboardIntervalRange(dashboardId: string) {
    return (dispatch: any) => {
        dispatch({
            type: ActionTypes.ClearIntervalRangeInDashboard,
            dashboardId
        });
    };
}

export function setFilterInDashboard(
    analysisNames: string[],
    dashboardId: string
) {
    return (dispatch: any, getState: any) => {
        const { templates, dynamicTemplates } = getState().templates;
        const filterNames = [
            ...new Set(
                templates
                    .filter((t: Template) =>
                        analysisNames.includes(t.analysisName)
                    )
                    .flatMap((t: Template) => t.filterNames)
            )
        ].map((n: any) => ({ name: n }));
        const dynamicFilterPermissions = dynamicTemplates
            .filter((t: Template) => analysisNames.includes(t.analysisName))
            .flatMap((t: Template) => t.filterNames);

        return dispatch({
            type: ActionTypes.SetFilterNamesInDashboard,
            dashboardId,
            dynamicFilterPermissions,
            filterNames
        });
    };
}

export function setFilterApplyState(
    dashboardId: string,
    isApplyButtonDisabled: boolean
) {
    return {
        type: ActionTypes.UpdateFilterApplyState,
        dashboardId,
        isApplyButtonDisabled
    };
}

export function setUpdatedWithFiltersReports(
    dashboardId: string,
    reports: any,
    appliedFilter: string[]
) {
    return {
        type: ActionTypes.UpdateReportsWithFilters,
        dashboardId,
        reports,
        appliedFilter
    };
}

export function selectFilterSegmentInDashboard(
    dashboardId: string,
    filterSegment?: FilterSegment
) {
    return {
        type: ActionTypes.UpdateFilterSegmentInDashboard,
        dashboardId,
        filterSegment
    };
}

export function selectIntervalInDashboard(
    dashboardId: string,
    interval: string
) {
    return (dispatch: any) => {
        dispatch({
            type: ActionTypes.SelectIntervalInDashboard,
            dashboardId,
            interval
        });
    };
}

export function selectIntervalRangeInDashboard(
    dashboardId: string,
    startDate: string,
    endDate: string
) {
    return (dispatch: any) => {
        dispatch({
            type: ActionTypes.SelectIntervalRangeInDashboard,
            dashboardId,
            startDate,
            endDate
        });
    };
}

import { useState, useEffect, useRef } from 'react';

export default function useTableScroll(loaded: boolean, ...rest: any[]) {
    const [paddingTop, setPaddingTop] = useState(0);
    const [showLeft, setShowLeft] = useState(false);
    const [showRight, setShowRight] = useState(false);

    const tableRef = useRef<HTMLDivElement>();
    const containerRef = useRef<HTMLDivElement>();

    useEffect(() => {
        if (loaded) {
            const tableNode = tableRef.current;

            setTimeout(() => {
                if (
                    tableNode &&
                    tableNode.scrollWidth > tableNode.offsetWidth
                ) {
                    setShowRight(true);
                }
                setPaddingTopForArrows();
            }, 200);

            tableNode?.addEventListener('scroll', handleScroll);
            window.addEventListener('resize', handleResize);
            window.addEventListener('scroll', handleWindowScroll);

            return () => {
                setShowLeft(false);
                setShowRight(false);
                tableNode?.removeEventListener('scroll', handleScroll);
                window.removeEventListener('resize', handleResize);
                window.removeEventListener('scroll', handleWindowScroll);
            };
        }
    }, [loaded, ...rest]);

    function handleResize() {
        const tableNode = tableRef.current;
        const scrollable =
            tableNode && tableNode.scrollWidth > tableNode.offsetWidth;
        if (scrollable) {
            setShowLeft(tableNode.scrollLeft > 5);
            setShowRight(
                Math.abs(
                    tableNode.scrollWidth -
                        tableNode.scrollLeft -
                        tableNode.offsetWidth
                ) > 5
            );
        } else {
            setShowLeft(false);
            setShowRight(false);
        }
    }

    function handleWindowScroll() {
        setPaddingTopForArrows();
    }

    function handleScroll() {
        const tableNode = tableRef.current;
        if (!tableNode) return;
        setShowLeft(tableNode.scrollLeft > 5);
        setShowRight(
            Math.abs(
                tableNode.scrollWidth -
                    tableNode.scrollLeft -
                    tableNode.offsetWidth
            ) > 5
        );
    }

    function setPaddingTopForArrows() {
        const containerNode = containerRef.current;
        if (containerNode) {
            const rect = containerNode.getBoundingClientRect();
            let { top, bottom } = rect;
            const headerHeight =
                document.getElementById('header')?.clientHeight || 0;
            let paddingTop = 0;
            if (top < headerHeight) {
                paddingTop = headerHeight - top;
                top = headerHeight;
            }
            if (bottom > window.innerHeight) {
                bottom = window.innerHeight;
            }
            paddingTop += (bottom - top) / 2;
            setPaddingTop(paddingTop);
        }
    }

    return [showLeft, showRight, paddingTop, containerRef, tableRef];
}

import { Modal } from 'antd';
import React from 'react';
import Close from '../../../icons/Close';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { openInfoModal, closeInfoModal } from '../../settings/reducer';
import { MeasureType } from '../../types';

interface InfoModalProps {
    activeTab: MeasureType;
}

const InfoModal = ({ activeTab }: InfoModalProps) => {
    const { measureInfoModalOpen: measureInfoModalOpen } = useAppSelector(
        state => state.measure.settings
    );
    const dispatch = useAppDispatch();

    const handleClose = () => {
        dispatch(closeInfoModal());
    };

    return (
        <Modal
            open={measureInfoModalOpen}
            width={600}
            bodyStyle={{ padding: 0 }}
            zIndex={3000}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            onCancel={handleClose}
        >
            <div className="info-modal">
                <div className="info-modal__inner">
                    <div className="info-modal__header">
                        <div className="info-modal__title">Goals</div>
                        <div>
                            <Close
                                width={24}
                                height={24}
                                className="btn-icon"
                                onClick={handleClose}
                            />
                        </div>
                    </div>
                    <div
                        className="info-modal__content"
                        style={{ marginTop: 0 }}
                    >
                        <div className="info-modal__content-col">
                            <div>
                                <p>
                                    Start tracking your progress against your
                                    DEI analytics goals. Each goal you create
                                    includes: the analysis type, the segment of
                                    people, the desired goal value, and the
                                    timeframe. Dandi then keeps track of the
                                    associated metric upon each data refresh in
                                    your account. The goal tracking report will
                                    display your progress over time until the
                                    end date is reached.
                                </p>
                                <p>
                                    Goal creation will be specific to your
                                    internal initiatives. Examples include:
                                </p>
                                <ul>
                                    <li>
                                        Increase hires of women by 10% in your
                                        engineering department
                                    </li>
                                    <li>
                                        Improve representation of BIPOC
                                        employees at the executive level by 20%
                                    </li>
                                    <li>
                                        Reduce the female adjusted wage gap by
                                        2%
                                    </li>
                                </ul>
                                <p>
                                    All goals are account-wide. All admins and
                                    editors have access to create, edit, and
                                    view goals in the account. Viewers do not
                                    have access to see any goals.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="cover-bar" />
                </div>
            </div>
        </Modal>
    );
};

export default InfoModal;

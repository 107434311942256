import * as uuid from 'uuid';
import {
    AnalysisPermission as AnalysisPermissionModel,
    FeaturePermission as FeaturePermissionModel
} from '../../../roles/createRole/models';

export function createBlankAnalysisPermission() {
    const analysisPermission: AnalysisPermissionModel = {
        id: uuid.v4(),
        analysises: [
            {
                analysisName: '',
                analysisDisplayName: '',
                subAnalyses: [],
                analysisType: ''
            }
        ],
        filters: [],
        breakdowns: [],
        segments: []
    };

    return analysisPermission;
}

export function createBlankFeaturePermission() {
    const id = uuid.v4();
    return {
        featureName: '',
        scopes: [],
        actions: [],
        resources: [],
        id
    } as FeaturePermissionModel;
}

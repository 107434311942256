import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { back, setScrollPosition } from '../common/actions';
import qs from 'qs';
import Filters from './filter/Filters';

function Header({ back, location, setScrollPosition, report }) {
    function handleBack() {
        if (location.state && location.state.scrollPos) {
            setScrollPosition(location.state.scrollPos);
        }
        back();
    }

    return (
        <div className="editor-header">
            <div className="editor-header__filters-container">
                <Filters report={report} />
            </div>
        </div>
    );
}

function mapState(state, ownProps) {
    const query = qs.parse(ownProps.location.search, {
        ignoreQueryPrefix: true
    });
    const { dashboardId, reportId } = query;
    const dashboard = state.dashboards[dashboardId];

    const editMode = !!reportId;
    let report;
    if (editMode) {
        if (dashboard) {
            report = dashboard.reports.find(r => r.reportId === reportId);
        }
    }
    return {
        report
    };
}

Header.propTypes = {
    location: PropTypes.object,
    back: PropTypes.func,
    setScrollPosition: PropTypes.func
};

const dispatchProps = {
    back,
    setScrollPosition
};

export default connect(mapState, dispatchProps)(Header);

import classNames from 'classnames';
import React from 'react';
import WithPermissions from '../../common/WithPermissions';
import RbacActions from '../../constants/RbacActions';
import { EventMore } from './More';
import { EVENT_HEIGHT } from './TimelineContent/TimelineContent';

interface EventNameProps extends React.HTMLAttributes<HTMLDivElement> {
    name: string;
    index: number;
    id: string;
    highlighted?: boolean;
}

const EventName = ({
    name,
    index,
    id,
    highlighted,
    ...rest
}: EventNameProps) => {
    const [moreOpen, setMoreOpen] = React.useState<boolean>(false);

    return (
        <div
            data-test={`event-list-${name}`}
            className={classNames(
                'name',
                {
                    'name--odd': index % 2 === 1
                },
                { 'name--even': index % 2 === 0 }
            )}
            style={{ height: EVENT_HEIGHT, opacity: highlighted ? 1 : 0.3 }}
            {...rest}
        >
            <div className="name__wrapper">
                <span
                    style={{
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis'
                    }}
                >
                    {name}
                </span>
            </div>
            <WithPermissions actions={[RbacActions['Events/Edit']]}>
                <EventMore open={moreOpen} setOpen={setMoreOpen} eventId={id} />
            </WithPermissions>
        </div>
    );
};

export default EventName;

import React, { Fragment } from 'react';
import Button from '../../../common/Button';
import { addMessage } from '../../../common/actions';
import analysisTypes from '../../../constants/AnalysisTypes';
import ButtonTypes from '../../../constants/ButtonTypes';
import MixPanel from '../../../constants/MixPanel';
import { track } from '../../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { createSearch } from '../../actions';
import SaveModal from './SaveModal';

interface SaveButtonProps {
    text?: string;
    disabled?: boolean;
}

const SaveButton = ({ text, disabled }: SaveButtonProps) => {
    const dispatch = useAppDispatch();
    const data = useAppSelector(state => state.explore.chart);
    const filter = useAppSelector(state => state.explore.filter);
    const subtable = useAppSelector(state => state.explore.subtable);

    const [saveTemplateModalIsOpen, setSaveTemplateModalIsOpen] =
        React.useState(false);
    const [templateName, setTemplateName] = React.useState('');

    const handleSaveAsThumbnailClick = () => {
        setSaveTemplateModalIsOpen(true);
    };

    const handleTemplateNameSave = (name: string) => {
        setTemplateName(name);
        setSaveTemplateModalIsOpen(false);
    };

    const handleTemplateAdd = () => {
        const cleanedAttributes = filter.mainSegments.map((segment: any) => {
            return {
                name: segment.name,
                value: segment.values
            };
        });

        let chartType =
            (filter?.breakdownSegment?.values?.length ?? 0) > 1
                ? subtable.subtableChartType !== undefined
                    ? subtable.subtableChartType
                    : data.chartType
                : data.chartType;

        if (
            filter.analysisType === analysisTypes.LinearRegression &&
            chartType?.toLowerCase() === 'bar'
        ) {
            chartType = 'index-bar';
        }

        const configuration = [
            { name: 'chartType', value: chartType },
            { name: 'length', value: data.data.length.toString() },
            {
                name: 'interval',
                value: filter.interval
            },
            {
                name: 'intervalCount',
                value: filter.intervalCount?.toString()
            },
            {
                name: 'benchmark',
                value: JSON.stringify(data.benchmark)
            }
        ];

        if (filter.interval === 'custom') {
            configuration.push({ name: 'interval', value: filter.interval });
        }
        if (filter.intervalCount) {
            configuration.push({
                name: 'intervalCount',
                value: filter.intervalCount?.toString()
            });
        }
        if (filter.startDate) {
            configuration.push({
                name: 'startDate',
                value: filter.startDate
            });
        }
        if (filter.endDate) {
            configuration.push({
                name: 'endDate',
                value: filter.endDate
            });
        }

        if (data.indexType) {
            configuration.push({
                name: 'indexType',
                value: data.indexType
            });
        }

        if (data.yAxisType) {
            configuration.push({
                name: 'yAxisType',
                value: data.yAxisType
            });
        }

        dispatch(
            createSearch({
                name: templateName,
                analysisName: filter.analysisName,
                analysis: [],
                attributes: cleanedAttributes,
                version: '2',
                configuration
            })
        );
        dispatch(addMessage(`Search "${templateName}" saved successfully`));
        track(MixPanel.Events.ExploreSavedSearchSave, {
            'Report Name': templateName
        });
    };

    React.useEffect(() => {
        if (templateName !== '') {
            handleTemplateAdd();
        }
    }, [templateName]);

    const defaultReportName = React.useMemo(() => {
        if (!filter) return '';
        const reportName = filter.analysisName
            ? filter.analysisName
            : 'Custom Report';
        return `${reportName} - ${filter.mainSegments
            .map((segment: any) => segment.name)
            .join(', ')}`;
    }, [filter, data]);

    return (
        <Fragment>
            <Button
                disabled={disabled}
                large={false}
                componentType={ButtonTypes.type.SECONDARY}
                onClick={handleSaveAsThumbnailClick}
                style={{ marginRight: '8px' }}
            >
                {text || 'Save'}
            </Button>
            <SaveModal
                defaultName={defaultReportName}
                isOpen={saveTemplateModalIsOpen}
                onSave={handleTemplateNameSave}
                onCancel={() => setSaveTemplateModalIsOpen(false)}
            />
        </Fragment>
    );
};

export default SaveButton;

import { useContext, Fragment } from 'react';
import { LineChartContext } from './LineChart';
import { NodeGroup } from 'react-move';
import { ChartContext } from '../../contexts/ChartContext';
import { useAppSelector, useAppDispatch } from '../../store/hooks';
import Constants from '../../constants/Constants';
import getInterpolator from '../../lib/getInterpolator';
import precisionRound from '../../lib/precisionRound';

const Points = () => {
    const {
        chartState: { mode, chartHeight }
    } = useContext(ChartContext);

    const { chartData, currentIndex, dataProp } = useAppSelector(state => {
        switch (mode) {
            case 'measure': {
                return state.measure.goal;
            }
            default:
                return {} as any;
        }
    });
    const dispatch = useAppDispatch();

    const {
        avgValue,
        isFaded,
        RADIUS,
        xScale,
        yScale,
        handleMouseOverOnPoint,
        handleMouseOutOnPoint,
        setCurrentIndex
    } = useContext(LineChartContext);

    const baseClass = 'linechartv2';

    return (
        <NodeGroup
            keyAccessor={d => d.label}
            data={chartData}
            start={d => {
                const initial = d.series.map(({ date }: any) => ({
                    [dataProp]: avgValue,
                    date
                }));
                return {
                    opacityCircle: 0,
                    opacityText: 0,
                    series: initial
                };
            }}
            enter={d => {
                return {
                    opacityCircle: [1],
                    opacityText: [1],
                    series: [d.series],
                    timing: {
                        duration: Constants.AnimationDuration,
                        ease: Constants.EasingFn
                    }
                };
            }}
            update={d => {
                return {
                    opacityCircle: isFaded(d) ? [0.05] : [1],
                    opacityText: isFaded(d) ? [0.24] : [1],
                    timing: {
                        duration: Constants.AnimationDuration,
                        ease: Constants.EasingFn
                    }
                };
            }}
            interpolation={getInterpolator}
        >
            {nodes => (
                <g className={`${baseClass}__circles`}>
                    {nodes.map(({ state, data, key }) => {
                        if (data.isEqualityIndex || data.isAttritionIndex) {
                            return null;
                        }

                        return (
                            <Fragment key={key}>
                                {state.series.map((s: any, i: number) => (
                                    <Fragment>
                                        <circle
                                            className={`${baseClass}__circle`}
                                            key={i}
                                            stroke={data.color}
                                            strokeWidth={1}
                                            fill={
                                                currentIndex === i
                                                    ? data.color
                                                    : 'var(--color-shade-h4)'
                                            }
                                            cx={xScale(s.date)}
                                            cy={yScale(
                                                precisionRound(s[dataProp], 1)
                                            )}
                                            r={RADIUS}
                                            cursor="none"
                                            opacity={i > currentIndex ? 0.2 : 1}
                                        />
                                        <line
                                            className={`${baseClass}__point-hover`}
                                            key={i}
                                            stroke="transparent"
                                            strokeWidth="15"
                                            x1={xScale(s.date)}
                                            x2={xScale(s.date)}
                                            y1={0}
                                            y2={chartHeight}
                                            cursor="pointer"
                                            onMouseOver={() =>
                                                handleMouseOverOnPoint(
                                                    data.label,
                                                    i
                                                )
                                            }
                                            onMouseOut={() =>
                                                handleMouseOutOnPoint()
                                            }
                                            onClick={() =>
                                                dispatch(setCurrentIndex(i))
                                            }
                                        />
                                    </Fragment>
                                ))}
                            </Fragment>
                        );
                    })}
                </g>
            )}
        </NodeGroup>
    );
};

export default Points;

import { useEffect, useMemo, useRef, useState } from 'react';

import Button from '../../common/Button';
import DatePicker from '../../common/DatePicker';
import Dropdown from '../../common/Dropdown';
import ButtonTypes from '../../constants/ButtonTypes';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import { formatDateByIntervalType } from '../../explore/dashboard/DateRangeSelection';
import usePermissions from '../../hooks/usePermissions';
import { track } from '../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { FilterSegment, Intervals } from '../../types';
import {
    clearDashboardFilter,
    clearDashboardIntervalRange,
    selectFilterSegmentInDashboard,
    selectIntervalInDashboard,
    selectIntervalRangeInDashboard,
    setFilterApplyState
} from './actions';
import DashboardFilterDropdown from './DashboardFilterDropdown';
import { DashboardFilter } from './filterReducer';

type DashboardFiltersProps = {
    dashboardId: string;
    isFilterApplied: boolean;
    onApply: () => void;
    onClear: () => void;
};

export const INTERVAL_OPTIONS = [
    {
        value: Intervals.Month,
        label: 'Monthly'
    },
    {
        value: Intervals.Quarter,
        label: 'Quarterly'
    },
    {
        value: Intervals.Year,
        label: 'Yearly'
    }
];

function DashboardFilters({
    dashboardId,
    isFilterApplied,
    onApply,
    onClear
}: DashboardFiltersProps) {
    const dispatch = useAppDispatch();
    const [showDatePicker, setShowDatePicker] = useState(false);
    const { attributes } = useAppSelector(state => state.explore.filter);
    const dashboardFilters = useAppSelector(
        state => state.dashboard.filter.filters
    );
    const dashboardFiler = useMemo(
        () =>
            dashboardFilters.find(
                filter => filter.dashboardId === dashboardId
            ) || {},
        [dashboardFilters]
    );

    const { name: dashboardName } = useAppSelector(
        state => state.dashboards[dashboardId]
    );
    const {
        filterNames = [],
        filterSegment,
        interval,
        isApplyButtonDisabled,
        startDate,
        endDate,
        dynamicFilterPermissions = []
    } = dashboardFiler as DashboardFilter;

    const prevValues = useRef({ endDate, filterSegment, interval, startDate });

    useEffect(() => {
        if (
            filterSegment !== prevValues.current.filterSegment ||
            interval !== prevValues.current.interval ||
            startDate !== prevValues.current.startDate ||
            endDate !== prevValues.current.endDate
        ) {
            dispatch(setFilterApplyState(dashboardId, false));
            prevValues.current = {
                endDate,
                filterSegment,
                interval,
                startDate
            };
        }
    }, [endDate, filterSegment, interval, startDate]);

    useEffect(() => {
        localStorage.setItem(
            'dashboardFilters',
            JSON.stringify(dashboardFilters)
        );
    }, [dashboardFilters]);

    const disabled = !usePermissions({
        actions: [RbacActions['Explore/Generate']]
    });

    function getIntervalRangeChange(startDate: string, endDate: string) {
        track(MixPanel.Events.ExploreSelectIntervalRange, {
            'Start Date': startDate,
            'End Date': endDate
        });
        dispatch(
            selectIntervalRangeInDashboard(dashboardId, startDate, endDate)
        );
    }

    const handleDateSelect = (dates: string[]) => {
        const startDate = formatDateByIntervalType(interval, dates[0]);

        const endDate = formatDateByIntervalType(interval, dates[1]);
        track(MixPanel.Events.DashboardChangeIntervalRangeFilter, {
            'Dashboard Name': dashboardName,
            filterTimeRange: `${startDate} - ${endDate}`
        });

        getIntervalRangeChange(startDate!, endDate!);
        setShowDatePicker(false);
    };

    function handleApply() {
        track(MixPanel.Events.DashboardFilterApply, {
            'Dashboard Name': dashboardName
        });
        dispatch(setFilterApplyState(dashboardId, true));
        onApply();
    }

    function handleIntervalChange(interval: string) {
        track(MixPanel.Events.ExploreSelectInterval, {
            'Dashboard Name': dashboardName,
            filterTimeInterval:
                INTERVAL_OPTIONS.find(option => option.value === interval)
                    ?.label || ''
        });
        dispatch(selectIntervalInDashboard(dashboardId, interval));
    }

    function handleClear() {
        track(MixPanel.Events.DashboardFilterClear, {
            'Dashboard Name': dashboardName
        });
        dispatch(clearDashboardFilter(dashboardId));
        onClear();
    }

    function handleUpdateSegment(segment?: FilterSegment) {
        dispatch(selectFilterSegmentInDashboard(dashboardId, segment));
    }

    function handleClearSelection() {
        dispatch(clearDashboardIntervalRange(dashboardId));
    }

    function handleIntervalRangeOpen() {
        if (!showDatePicker) {
            track(MixPanel.Events.DashboardOpenIntervalRangeFilter, {
                'Dashboard Name': dashboardName
            });
        }
        setShowDatePicker(!showDatePicker);
    }

    return (
        <div className="filters dashboard-filters">
            <div className="filters__dropdowns">
                <DashboardFilterDropdown
                    attributes={attributes}
                    dashboardName={dashboardName}
                    dynamicPermissionsFilter={dynamicFilterPermissions}
                    placeholder="Filter"
                    segmentNames={filterNames}
                    selection={filterSegment}
                    update={handleUpdateSegment}
                />
                <Dropdown
                    allowCancel
                    buttonLarge={true}
                    options={INTERVAL_OPTIONS}
                    placeholder="Time interval"
                    selected={!!interval}
                    selectedOption={interval}
                    onClick={handleIntervalChange}
                />
                <DatePicker
                    allowCancel
                    buttonLarge={true}
                    disabled={!interval}
                    endDate={endDate}
                    formatType={interval as any}
                    isOpen={showDatePicker}
                    placeholder={'Select Range (optional)'}
                    label={
                        startDate
                            ? `${startDate} - ${endDate}`
                            : 'Select Range (optional)'
                    }
                    selectsRange={true}
                    startDate={startDate}
                    onClearSelection={handleClearSelection}
                    onClick={handleIntervalRangeOpen}
                    onDateSelect={handleDateSelect}
                />
            </div>
            {!disabled && (
                <div className="filters__buttons" data-test="filters-actions">
                    <Button
                        disabled={isFilterApplied && !isApplyButtonDisabled}
                        componentType={ButtonTypes.type.TERTIARY}
                        onClick={handleClear}
                        tabIndex={-1}
                    >
                        Clear
                    </Button>
                    <Button
                        disabled={
                            (!filterSegment && !interval) ||
                            isApplyButtonDisabled
                        }
                        onClick={handleApply}
                        testId="generate"
                    >
                        <span className="filters__buttons">Apply Filter</span>
                    </Button>
                </div>
            )}
        </div>
    );
}

export default DashboardFilters;

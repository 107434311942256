import React, { MouseEvent } from 'react';
import Close from '../../../icons/Close';
import Modal from 'antd/lib/modal';
import Stack from '../../../common/Stack';

interface InfoModalProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
}

function InfoModal({ isOpen, onClose, children }: InfoModalProps) {
    function handleInfoClose(e: MouseEvent) {
        e.preventDefault();
        e.stopPropagation();
        onClose();
    }

    return (
        <Modal
            open={isOpen}
            width={500}
            bodyStyle={{ padding: 0 }}
            zIndex={3000}
            footer={null}
            closable={false}
            maskClosable={true}
            destroyOnClose={true}
            onCancel={handleInfoClose}
            centered={true}
        >
            <div
                className="info-modal"
                onClick={(e: any) => e.stopPropagation()}
            >
                <div
                    style={{
                        position: 'relative'
                    }}
                >
                    <div
                        style={{
                            position: 'absolute',
                            top: 0,
                            right: 0
                        }}
                    ></div>
                    <Stack
                        style={{
                            paddingRight: '1.6rem'
                        }}
                    >
                        {children}
                    </Stack>
                </div>
            </div>
        </Modal>
    );
}

InfoModal.defaultProps = {
    isOpen: false
};

export default InfoModal;

import Menu from 'antd/lib/menu';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import MixPanel from '../../constants/MixPanel';
import { track } from '../../lib/segment';
import {
    navigateToEditor,
    showDeleteReportModal,
    showSelectDashboardModal
} from './actions';

function MoreMenu({
    dashboardId,
    dashboardName,
    reportId,
    title,
    onClose,
    navigateToEditor,
    showDeleteReportModal,
    showSelectDashboardModal,
    role,
    isTextChart,
    hasEvents,
    eventsHidden,
    setEventsHidden,
    textChartDispatch
}) {
    function handleEdit() {
        track(MixPanel.Events.DashboardReportEdit, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });

        if (isTextChart) {
            textChartDispatch({
                type: 'ACTIVATE',
                role
            });
        } else {
            navigateToEditor(dashboardId, reportId);
        }
        onClose();
    }

    function handleDelete() {
        track(MixPanel.Events.DashboardReportDelete, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
        showDeleteReportModal(reportId);
        onClose();
    }

    function handleCopy() {
        track(MixPanel.Events.DashboardReportAddTo, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
        showSelectDashboardModal(dashboardId, reportId);
    }

    function handleClick({ domEvent }) {
        domEvent.stopPropagation();
    }

    function handleEventToggle() {
        setEventsHidden(!eventsHidden);
        onClose();
    }

    return (
        <Menu className="widget-menu" selectedKeys={[]} onClick={handleClick}>
            <Menu.Item onClick={handleEdit}>Edit</Menu.Item>
            <Menu.Item onClick={handleCopy}>Add to ...</Menu.Item>
            {hasEvents && (
                <Menu.Item onClick={handleEventToggle}>
                    {eventsHidden ? 'Show events' : 'Hide events'}
                </Menu.Item>
            )}
            <Menu.Item onClick={handleDelete}>Delete</Menu.Item>
        </Menu>
    );
}

MoreMenu.propTypes = {
    dashboardId: PropTypes.string,
    dashboardName: PropTypes.string,
    reportId: PropTypes.string,
    title: PropTypes.string,
    onClose: PropTypes.func,
    navigateToEditor: PropTypes.func,
    showDeleteReportModal: PropTypes.func,
    showSelectDashboardModal: PropTypes.func,
    role: PropTypes.string,
    isTextChart: PropTypes.bool,
    hasEvents: PropTypes.bool,
    eventsHidden: PropTypes.bool,
    setEventsHidden: PropTypes.func,
    textChartDispatch: PropTypes.func
};

const dispatchProps = {
    showSelectDashboardModal,
    showDeleteReportModal,
    navigateToEditor
};

export default connect(null, dispatchProps)(MoreMenu);

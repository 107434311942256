import Modal from 'antd/lib/modal';
import Radio from 'antd/lib/radio';
import Tree from 'antd/lib/tree';
import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';
import { Fragment, useEffect, useRef, useState } from 'react';
import Button from '../common/Button';
import Checkbox from '../common/Checkbox';
import Dropdown from '../common/Dropdown';
import OpenText from '../common/OpenText';
import ButtonTypes from '../constants/ButtonTypes';
import MixPanel from '../constants/MixPanel';
import BenchmarkChartInner from '../editor/chart/BenchmarkChartInner';
import { setBenchmark as setBenchmarkInEditor } from '../editor/chart/actions';
import { setBenchmark } from '../explore/chart/actions';
import Info2 from '../icons/Info2';
import sanitize from '../lib/sanitize';
import { track } from '../lib/segment';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
    queryBenchmark,
    saveBenchmark,
    showPreviewInBenchmarkModal
} from './actions';
import { Benchmark } from './model';
import naics2Names from './options/2018_naics2_names.json';
import naics2Values from './options/2018_naics2s.json';
import naics3Names from './options/2018_naics3_names.json';
import naics3Values from './options/2018_naics3s.json';
import divisionsConfig from './options/divisions';
import regions from './options/regions';
import statesConfig from './options/states';

const WIDTH = 600;
const PADDING = 16;
const BOTTOM_PADDING = 40;
const TOP_PADDING = 16;
const TABLE_MARGIN = 32;
const ROW_HEIGHTS = [44, 40, 36, 28];
const NATION = 'United States';

const locationData = [
    {
        title: 'Location',
        key: 'location',
        children: []
    }
];

const locationChildrenData = [
    {
        title: 'State',
        key: 'state'
    },
    {
        title: 'Division',
        key: 'division'
    },
    {
        title: 'Region',
        key: 'region'
    },
    {
        title: 'All (US)',
        key: 'all'
    }
];

function getMixPanelProps(query: any) {
    const mixPanelProps: any = {};
    for (let key in query) {
        if (query[key]) {
            mixPanelProps[capitalize(key)] = query[key];
        }
    }
    return mixPanelProps;
}

interface BenchmarkModalProps {
    benchmark?: Benchmark;
    isOpen: boolean;
    onCancel: () => void;
    onBenchmarkDelete: () => void;
    isOther: boolean;
    fromEditor?: boolean;
}

function BenchmarkModal(props: BenchmarkModalProps) {
    const {
        isOpen,
        onCancel,
        benchmark,
        onBenchmarkDelete,
        isOther,
        fromEditor
    } = props;
    const dispatch = useAppDispatch();
    const { pending, noData, showPreview, data, savePending } = useAppSelector(
        state => state.benchmarkModal
    );
    const [attribute, setAttribute] = useState<string>();
    const [region, setRegion] = useState<string>();
    const [division, setDivision] = useState<string>();
    const [stat, setStat] = useState<string>();
    const [naics2, setNaics2] = useState<string>();
    const [naics3, setNaics3] = useState<string>();
    const containerRef = useRef<HTMLDivElement>(null);
    const [name, setName] = useState('');
    const [height, setHeight] = useState(300);
    const [rowHeight, setRowHeight] = useState(ROW_HEIGHTS[0]);
    const [states, setStates] = useState<string[]>([]);
    const [divisions, setDivisions] = useState<any[]>([]);
    const [naics2s, setNaics2s] = useState<any[]>([]);
    const [naics3s, setNaics3s] = useState<any[]>([]);
    const [filteredNaics3s, setFilteredNaics3s] = useState<any[]>([]);
    const [locationSelected, setLocationSelected] = useState(false);
    const [industrySelected, setIndustrySelected] = useState(false);
    const [locationOption, setLocationOption] = useState<string>();
    const [treeData, setTreeData] = useState<any[]>(locationData);
    const [shouldSaveTemplate, setShouldSaveTemplate] = useState(false);

    useEffect(() => {
        let states = statesConfig.map(s => s.name);
        states = sortBy(states);
        setStates(states);
        let divisions = divisionsConfig.map(d => ({
            label: d.label || d.division,
            value: d.division
        }));
        divisions = sortBy(divisions, d => d.label);
        setDivisions(divisions);
        let naics2s = [];
        for (let i = 0; i < naics2Values.length; i++) {
            const naics2Value = naics2Values[i].NAICS2;
            naics2s.push({
                name: naics2Names[i].NAICS2_name,
                value: naics2Value.toString(),
                label: `${naics2Value} - ${naics2Names[i].NAICS2_name}`
            });
        }
        naics2s = sortBy(naics2s, n => n.name);
        setNaics2s(naics2s);
        let naics3s = [];
        for (let i = 0; i < naics3Values.length; i++) {
            const naics3Value = naics3Values[i].NAICS3;
            naics3s.push({
                name: naics3Names[i].NAICS3_Name,
                value: naics3Value.toString(),
                label: `${naics3Value} - ${naics3Names[i].NAICS3_Name}`
            });
        }
        naics3s = sortBy(naics3s, n => n.name);
        setNaics3s(naics3s);
    }, []);

    useEffect(() => {
        if (isOpen) {
            if (benchmark) {
                if (benchmark.attributes && benchmark.attributes.length > 0) {
                    setAttribute(benchmark.attributes[0]);
                }
                const { nation, state, region, division, naics2, naics3 } =
                    benchmark.query;
                setLocationSelected(!!nation);
                let locationOption = 'all';
                if (state) {
                    setStat(state);
                    locationOption = 'state';
                } else if (division) {
                    setDivision(division);
                    locationOption = 'division';
                } else if (region) {
                    setRegion(region);
                    locationOption = 'region';
                }
                setIndustrySelected(!!naics2);
                setNaics2(naics2 && naics2.toString());
                setNaics3(naics3 && naics3.toString());
                setName(benchmark.benchmarkName);
                setLocationOption(locationOption);
                const treeData: any[] = locationData.slice();
                treeData[0] = {
                    ...treeData[0],
                    children: locationSelected ? locationChildrenData : []
                };
                setTreeData(treeData);
                if (naics2) {
                    const filteredNaics3s = naics3s.filter(
                        n => n.value.indexOf(naics2.toString()) === 0
                    );
                    setFilteredNaics3s(filteredNaics3s);
                }
                setShouldSaveTemplate(!!benchmark.benchmarkId);
            } else {
                setAttribute(undefined);
                setStat(undefined);
                setRegion(undefined);
                setDivision(undefined);
                setNaics2(undefined);
                setNaics3(undefined);
                setLocationSelected(false);
                setIndustrySelected(false);
                setLocationOption(undefined);
                setName('');
                setTreeData(locationData);
                setShouldSaveTemplate(false);
            }
        }
    }, [isOpen]);

    useEffect(() => {
        if (showPreview) {
            const dataLength = data?.length || 0;
            const tableHeight = height - TOP_PADDING - BOTTOM_PADDING;
            let rowHeight = 0;
            let extend = true;
            for (let i = 0; i < ROW_HEIGHTS.length; i++) {
                rowHeight = ROW_HEIGHTS[i];
                if (rowHeight * dataLength <= tableHeight) {
                    extend = false;
                    break;
                }
            }
            setRowHeight(rowHeight);
            if (extend) {
                const newHeight =
                    rowHeight * dataLength + TOP_PADDING + BOTTOM_PADDING;
                setHeight(newHeight);
            }
        }
    }, [showPreview, data]);

    function handleCreate(e: any) {
        e.preventDefault();
        let query = {
            nation: locationSelected ? NATION : null,
            state: stat || null,
            naics2: Number(naics2) || null,
            naics3: Number(naics3) || null,
            region: region || null,
            division: division || null
        };
        if (stat) {
            const config = statesConfig.find(s => s.name === stat);
            if (config) {
                query = {
                    ...query,
                    division: config.division,
                    region: config.region
                };
            }
        }
        if (division) {
            const config = divisionsConfig.find(d => d.division === division);
            if (config) {
                query = {
                    ...query,
                    region: config.region
                };
            }
        }
        track(MixPanel.Events.BenchmarkModalGenerateBenchmark, {
            Attribute: attribute,
            ...getMixPanelProps(query)
        });
        dispatch(queryBenchmark(attribute, query));
    }

    function handleSave(e: any) {
        e.preventDefault();
        let benchmarkName = name.trim();
        benchmarkName = sanitize(benchmarkName);
        if (benchmarkName) {
            let query = {
                nation: locationSelected ? NATION : null,
                region: region || null,
                division: division || null,
                state: stat || null,
                naics2: Number(naics2) || null,
                naics3: Number(naics3) || null
            };
            if (stat) {
                const config = statesConfig.find(s => s.name === stat);
                if (config) {
                    query = {
                        ...query,
                        region: config.region,
                        division: config.division
                    };
                }
            }
            if (division) {
                const config = divisionsConfig.find(
                    d => d.division === division
                );
                if (config) {
                    query = {
                        ...query,
                        region: config.region
                    };
                }
            }
            track(MixPanel.Events.BenchmarkModalSaveBenchmark, {
                'Benchmark Name': name,
                Attribute: attribute,
                ...getMixPanelProps(query)
            });
            if (shouldSaveTemplate) {
                dispatch(
                    saveBenchmark(
                        benchmark && benchmark.benchmarkId,
                        benchmarkName,
                        attribute,
                        query,
                        isOther,
                        fromEditor
                    )
                );
            } else {
                if (!isOther) {
                    const benchmarkToSet = {
                        benchmarkId: benchmark && benchmark.benchmarkId,
                        benchmarkName,
                        attributes: [attribute],
                        query,
                        data
                    };
                    if (fromEditor) {
                        dispatch(setBenchmarkInEditor(benchmarkToSet));
                    } else {
                        dispatch(setBenchmark(benchmarkToSet));
                    }
                }
                onCancel();
            }
        }
    }

    function handleNameChange(name: string) {
        setName(name);
    }

    function handleBack() {
        track(MixPanel.Events.BenchmarkModalGoBack);
        dispatch(showPreviewInBenchmarkModal(false));
    }

    function handleNaics2Change(naics2: string) {
        setNaics2(naics2);
        if (!naics2) {
            setNaics3(undefined);
        } else {
            const filteredNaics3s = naics3s.filter(
                n => n.value.indexOf(naics2) === 0
            );
            setFilteredNaics3s(filteredNaics3s);
        }
    }

    function handleLocationChange(checked: boolean) {
        setLocationSelected(checked);
        if (checked) {
            setLocationOption('state');
            setTreeData(treeData => {
                const newTreeData = treeData.slice();
                newTreeData[0] = {
                    ...newTreeData[0],
                    children: locationChildrenData
                };
                return newTreeData;
            });
        } else {
            setStat(undefined);
            setDivision(undefined);
            setRegion(undefined);
            setTreeData(locationData);
        }
    }

    function handleIndustryChange(checked: boolean) {
        setIndustrySelected(checked);
        if (!checked) {
            setNaics3(undefined);
            setNaics2(undefined);
        }
    }

    function handleRadioChange(e: any) {
        if (locationOption === 'region') {
            setRegion(undefined);
        } else if (locationOption === 'division') {
            setDivision(undefined);
        } else if (locationOption === 'state') {
            setStat(undefined);
        }
        setLocationOption(e.target.value);
    }

    function handleGenerateCancel() {
        track(MixPanel.Events.BenchmarkModalGenerateCancel);
        onCancel();
    }

    function handlePreviewCancel() {
        track(MixPanel.Events.BenchmarkModalPreviewCancel);
        onCancel();
    }

    function getTitle() {
        if (showPreview) {
            return 'Preview';
        } else if (benchmark) {
            return 'Edit benchmark';
        } else {
            return 'Create benchmark';
        }
    }

    function getCreateDisabledState() {
        let disabled = !attribute;
        if (!disabled) {
            if (locationSelected) {
                if (locationOption !== 'all') {
                    disabled = !(stat || region || division);
                }
            } else {
                disabled = !naics2;
            }
        }
        return disabled;
    }

    const radius = (height - TOP_PADDING - BOTTOM_PADDING) / 2;
    const paddingLeft = 2 * radius + TABLE_MARGIN;

    const ATTRIBUTE_OPTIONS = [
        {
            label: 'Ethnicity',
            value: 'Ethnicity'
        },
        {
            label: 'Gender',
            value: 'Gender'
        }
    ];

    return (
        <Modal
            visible={isOpen}
            width={WIDTH}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={onCancel}
            zIndex={3000}
            destroyOnClose
            centered
        >
            <div
                className="benchmark-modal modal"
                ref={containerRef}
                onClick={e => e.stopPropagation()}
            >
                <div className="modal__header">
                    <h3 className="modal__title">{getTitle()}</h3>
                </div>
                <div className="modal__content">
                    {!showPreview ? (
                        <form className="benchmark-modal__create">
                            <div className="benchmark-modal__create__desc">
                                <Info2 />
                                <div>
                                    See where you stand compared to other
                                    employers. Dandi benchmarking compares your
                                    company&apos;s representation data (gender
                                    and ethnicity) to data collected by the US
                                    Equal Employment Opportunity Commission
                                    (EEOC).
                                </div>
                            </div>
                            <div className="benchmark-modal__create__attribute">
                                <div className="benchmark-modal__create__label">
                                    Select Attribute
                                </div>
                                <Dropdown
                                    selectedOption={attribute}
                                    onClick={(value: any) =>
                                        setAttribute(value)
                                    }
                                    options={ATTRIBUTE_OPTIONS}
                                    placeholder="Select Attribute"
                                ></Dropdown>
                            </div>
                            <div className="benchmark-modal__create__location">
                                <div className="benchmark-modal__create__label">
                                    Select Filter
                                </div>
                                <div className="benchmark-modal__create__location-tree">
                                    <Tree
                                        showLine={{ showLeafIcon: false }}
                                        selectable={false}
                                        expandedKeys={['location']}
                                        treeData={treeData}
                                        titleRender={nodeData =>
                                            nodeData.key === 'location' ? (
                                                <Checkbox
                                                    checked={locationSelected}
                                                    onClick={
                                                        handleLocationChange
                                                    }
                                                    style={{
                                                        paddingLeft: 2,
                                                        paddingBottom: 0
                                                    }}
                                                >
                                                    Location
                                                </Checkbox>
                                            ) : (
                                                <Radio
                                                    checked={
                                                        locationOption ===
                                                        nodeData.key
                                                    }
                                                    value={nodeData.key}
                                                    onChange={handleRadioChange}
                                                >
                                                    {nodeData.title}
                                                </Radio>
                                            )
                                        }
                                    />
                                </div>
                                {locationSelected && locationOption !== 'all' && (
                                    <div className="benchmark-modal__create__state">
                                        {locationOption === 'state' && (
                                            <Dropdown
                                                selectedOption={stat}
                                                options={states}
                                                onClick={(value: any) =>
                                                    setStat(value)
                                                }
                                                enableSearch={true}
                                                placeholder="Select State"
                                            ></Dropdown>
                                        )}
                                        {locationOption === 'region' && (
                                            <Dropdown
                                                selectedOption={region}
                                                onClick={(value: any) =>
                                                    setRegion(value)
                                                }
                                                placeholder="Select Region"
                                                options={regions}
                                                enableSearch={true}
                                            ></Dropdown>
                                        )}
                                        {locationOption === 'division' && (
                                            <Dropdown
                                                selectedOption={division}
                                                onClick={(value: any) =>
                                                    setDivision(value)
                                                }
                                                placeholder="Selected Division"
                                                options={divisions}
                                                enableSearch={true}
                                            ></Dropdown>
                                        )}
                                    </div>
                                )}
                            </div>

                            <div className="benchmark-modal__create__industry">
                                <div className="benchmark-modal__create__radio">
                                    <Checkbox
                                        checked={industrySelected}
                                        onClick={handleIndustryChange}
                                    >
                                        Industry classification
                                    </Checkbox>
                                </div>
                                {industrySelected && (
                                    <Fragment>
                                        <div className="benchmark-modal__create__naics2">
                                            <Dropdown
                                                selectedOption={naics2}
                                                onClick={handleNaics2Change}
                                                placeholder="Select NAICS2"
                                                options={naics2s}
                                                enableSearch={true}
                                            ></Dropdown>
                                        </div>

                                        <div className="benchmark-modal__create__naics3">
                                            <Dropdown
                                                selectedOption={naics3}
                                                onClick={(value: any) =>
                                                    setNaics3(value)
                                                }
                                                options={filteredNaics3s}
                                                disabled={!naics2}
                                                placeholder="Select NAICS3"
                                                enableSearch={true}
                                            ></Dropdown>
                                        </div>
                                    </Fragment>
                                )}
                            </div>

                            {noData && (
                                <div className="benchmark-modal__create__error">
                                    EEOC benchmarking is not available for the
                                    chosen filters
                                </div>
                            )}
                            <div className="benchmark-modal__create__clear">
                                {benchmark && benchmark.benchmarkId && (
                                    <Button
                                        componentType={
                                            ButtonTypes.type.SECONDARY
                                        }
                                        onClick={onBenchmarkDelete}
                                    >
                                        Delete template
                                    </Button>
                                )}
                            </div>
                            <div className="benchmark-modal__create__save">
                                <div>
                                    <Button
                                        componentType={
                                            ButtonTypes.type.TERTIARY
                                        }
                                        onClick={handleGenerateCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        loading={pending}
                                        disabled={getCreateDisabledState()}
                                        onClick={handleCreate}
                                    >
                                        <span
                                            style={{ opacity: pending ? 0 : 1 }}
                                        >
                                            Generate
                                        </span>
                                    </Button>
                                </div>
                            </div>
                            <div className="benchmark-modal__create__footer">
                                Please note that EEOC data may contain
                                inaccuracies, and is sourced only from US-based
                                companies. We will refresh the EEOC data when
                                new information is available.
                            </div>
                        </form>
                    ) : (
                        <form className="benchmark-modal__preview">
                            <div
                                className="benchmark-modal__preview__name"
                                style={{ paddingLeft }}
                            >
                                <OpenText
                                    value={name}
                                    onChange={handleNameChange}
                                    placeholder="Enter name here"
                                    paddingLeft={16}
                                />
                            </div>
                            <BenchmarkChartInner
                                width={WIDTH - 2 * PADDING}
                                height={height}
                                data={data}
                                rowHeight={rowHeight}
                                showPie
                            />
                            <div className="benchmark-modal__preview__actions">
                                <div className="benchmark-modal__preview__back">
                                    <Button
                                        componentType={
                                            ButtonTypes.type.TERTIARY
                                        }
                                        onClick={handleBack}
                                    >
                                        Go Back
                                    </Button>
                                    {!benchmark && (
                                        <Checkbox
                                            checked={shouldSaveTemplate}
                                            onClick={(checked: boolean) =>
                                                setShouldSaveTemplate(checked)
                                            }
                                        >
                                            Save as template
                                        </Checkbox>
                                    )}
                                </div>
                                <div className="benchmark-modal__preview__save">
                                    <Button
                                        componentType={
                                            ButtonTypes.type.TERTIARY
                                        }
                                        onClick={handlePreviewCancel}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={!name}
                                        loading={savePending}
                                        onClick={handleSave}
                                    >
                                        <span
                                            style={{
                                                opacity: savePending ? 0 : 1
                                            }}
                                        >
                                            {isOther ? 'Save' : 'Use Benchmark'}
                                        </span>
                                    </Button>
                                </div>
                            </div>
                        </form>
                    )}
                </div>
            </div>
        </Modal>
    );
}

export default BenchmarkModal;

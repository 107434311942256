import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Urls from '../../../constants/Urls';
import { AsyncThunkConfig } from '../../../store/store';
import {
    CategoryEditState,
    SavableCategory,
    CategoryArchiveState
} from './types';
import { goBack } from 'connected-react-router';
import { cloneDeep } from 'lodash';

const initialCategory = { title: '', description: '' };

export const saveCategory = createAsyncThunk<
    void,
    SavableCategory,
    AsyncThunkConfig
>('survey/saveCategory', async (category, { getState, dispatch }) => {
    if (category.globalCategoryId) {
        await axios.put(`${Urls.SurveyStudioApi}categories/update`, {
            globalCategoryId: category.globalCategoryId,
            title: category.title,
            description: category.description,
            uiElements: []
        });
    } else {
        await axios.put(`${Urls.SurveyStudioApi}categories/create`, {
            title: category.title,
            description: category.description,
            uiElements: []
        });
    }
    dispatch(goBack());
});

export const deleteCategory = createAsyncThunk<void, string, AsyncThunkConfig>(
    'survey/deleteCategory',
    async (globalCategoryId, { getState, dispatch }) => {
        try {
            const response = await axios.put(
                `${Urls.SurveyStudioApi}categories/delete`,
                {
                    globalCategoryId
                }
            );
        } catch (error: any) {
            throw new Error(error.response.data);
        }
    }
);

export const archiveCategory = createAsyncThunk<
    void,
    CategoryArchiveState,
    AsyncThunkConfig
>(
    'survey/archiveCategory',
    async ({ category, shouldArchive }, { getState, dispatch }) => {
        try {
            const archiveValue = shouldArchive ? 'true' : 'false';
            const uiElements = cloneDeep(category.meta.uiElements);
            const uiElement = uiElements.find(u => u.key === 'archive');
            if (uiElement) {
                uiElement.value = archiveValue;
            } else {
                uiElements.push({
                    key: 'archive',
                    value: archiveValue
                });
            }

            await axios.put(`${Urls.SurveyStudioApi}categories/update`, {
                globalCategoryId: category.globalCategoryId,
                title: category.title,
                description: category.description,
                uiElements
            });
        } catch (error: any) {
            throw new Error(error.response.data);
        }
    }
);

export const categoryEditSlice = createSlice({
    name: 'auth',
    initialState: {
        categoryToEdit: initialCategory
    } as CategoryEditState,
    reducers: {
        setTitle: (state, action) => {
            state.categoryToEdit.title = action.payload;
        },
        setDescription: (state, action) => {
            state.categoryToEdit.description = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(saveCategory.fulfilled, (state, action) => {
            state.pending = false;
        });
        builder.addCase(saveCategory.pending, (state, action) => {
            state.error = false;
            state.pending = true;
        });
        builder.addCase(saveCategory.rejected, (state, action) => {
            state.error = true;
            state.pending = false;
        });
    }
});

export const { setTitle, setDescription } = categoryEditSlice.actions;
export default categoryEditSlice.reducer;

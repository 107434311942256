import axios from 'axios';
import { push } from 'connected-react-router';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import * as uuid from 'uuid';

import { addUsers } from '../admin/users/actions';
import ActionTypes from '../constants/ActionTypes';
import MixPanel from '../constants/MixPanel';
import Urls from '../constants/Urls';
import { getReports } from '../dashboard/view/actions';
import errorHandler from '../lib/errorHandler';
import { track } from '../lib/segment';

export function getDashboards(dashboardId: string) {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;

        dispatch({
            type: ActionTypes.GetDashboardsPending
        });

        return axios
            .post(`${Urls.DashboardsApi}dashboards`, {
                enterpriseId
            })
            .then(response => {
                const dashboards = response.data;
                dispatch({
                    type: ActionTypes.GetDashboardsFulfilled,
                    dashboards
                });
                if (dashboardId) {
                    const dashboard = dashboards.find(
                        (d: any) => d.dashboardId === dashboardId
                    );
                    dispatch({
                        type: ActionTypes.EditDashboard,
                        dashboard
                    });
                }
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.GetDashboardsRejected
                });
            });
    };
}

export function getSharedDashboards() {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;

        dispatch({
            type: ActionTypes.GetSharedDashboardsPending
        });

        return axios
            .post(`${Urls.DashboardsApi}dashboards/shared`, {
                enterpriseId
            })
            .then(response => {
                dispatch({
                    type: ActionTypes.GetSharedDashboardsFulfilled,
                    dashboards: response.data
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.GetSharedDashboardsRejected
                });
            });
    };
}

export function navigateToDashboard(dashboardId: string) {
    return (dispatch: any) => {
        let url = `/dashboard/${dashboardId}`;
        dispatch(push(url));
    };
}

export function getDashboard(dashboardId: string) {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;

        dispatch({
            type: ActionTypes.GetDashboardPending
        });

        return axios
            .post(`${Urls.DashboardsApi}dashboards/ids`, {
                enterpriseId,
                dashboardIds: [dashboardId]
            })
            .then(response => {
                dispatch({
                    type: ActionTypes.GetDashboardFulfilled,
                    dashboard: response.data.dashboards[0]
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.GetDashboardRejected
                });
            });
    };
}

export function showDashboardTilesView() {
    return (dispatch: any) => {
        dispatch({ type: ActionTypes.ShowTilesView });
    };
}

export function showDashboardListView() {
    return (dispatch: any) => {
        dispatch({ type: ActionTypes.ShowListView });
    };
}

export function showCreateDashboardModal() {
    return (dispatch: any) => {
        track(MixPanel.Events.MyDashboardsCreateDashboardClick);
        dispatch({ type: ActionTypes.ShowCreateDashboardModal });
    };
}

export function closeCreateDashboardModal() {
    return {
        type: ActionTypes.CloseCreateDashboardModal
    };
}

export function createDashboard(name = 'Untitled Dashboard') {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;
        const { uid, firstName, lastName } = getState().auth.userInfo;

        dispatch({
            type: ActionTypes.CreateDashboardPending
        });

        const dashboard = {
            enterpriseId,
            dashboardId: uuid.v4(),
            name,
            reports: [],
            createdById: uid,
            createdByName: `${firstName} ${lastName}`,
            dateAdded: moment.utc().toISOString(),
            lastUpdatedAt: moment.utc().toISOString()
        };

        return axios
            .put(`${Urls.DashboardsApi}dashboard`, { dashboard })
            .then(() => {
                track(MixPanel.Events.MyDashboardsCreateDashboard, {
                    'Dashboard Name': dashboard.name
                });
                dispatch({
                    type: ActionTypes.CreateDashboardFulfilled,
                    dashboard
                });
                dispatch(closeCreateDashboardModal());
                dispatch(navigateToDashboard(dashboard.dashboardId));
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.CreateDashboardRejected
                });
            });
    };
}

export function showDeleteDashboardModal(dashboardId: string) {
    return {
        type: ActionTypes.ShowDeleteDashboardModal,
        dashboardId
    };
}

export function closeDeleteDashboardModal() {
    return {
        type: ActionTypes.CloseDeleteDashboardModal
    };
}

export function deleteDashboard() {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;
        const { deleteDashboardId } = getState().dashboards;

        dispatch({
            type: ActionTypes.DeleteDashboardPending
        });

        return axios
            .put(`${Urls.DashboardsApi}dashboard/remove`, {
                enterpriseId,
                dashboardId: deleteDashboardId
            })
            .then(() => {
                const dashboard = getState().dashboards[deleteDashboardId];
                track(MixPanel.Events.MyDashboardsDeleteDashboard, {
                    'Dashboard Name': dashboard ? dashboard.name : ''
                });
                dispatch({
                    type: ActionTypes.DeleteDashboardFulfilled,
                    dashboardId: deleteDashboardId,
                    dashboardName: dashboard ? dashboard.name : ''
                });
                dispatch(closeDeleteDashboardModal());
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.DeleteDashboardRejected
                });
            });
    };
}

export function showShareModal(dashboardId: string) {
    return {
        type: ActionTypes.ShowShareModal,
        dashboardId
    };
}

export function closeShareModal() {
    return {
        type: ActionTypes.CloseShareModal
    };
}

export function showDuplicateModal(dashboardId: string) {
    return {
        type: ActionTypes.ShowDuplicateModal,
        dashboardId
    };
}

export function closeDuplicateModal() {
    return {
        type: ActionTypes.CloseDuplicateModal
    };
}

export function showApplyFilterModal(dashboardId: string) {
    return {
        type: ActionTypes.ShowApplyFilterModal,
        dashboardId
    };
}

export function closeApplyFilterModal() {
    return {
        type: ActionTypes.CloseApplyFilterModal
    };
}

export function getVault() {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;
        const { shareDashboardId, vaultMap } = getState().dashboards;
        const { actorId: generatorActorId } = getState().auth.userInfo;

        if (vaultMap && vaultMap[shareDashboardId]) {
            return Promise.resolve();
        }

        dispatch({
            type: ActionTypes.GetVaultPending
        });

        return axios
            .put(`${Urls.DashboardsApi}vault`, {
                enterpriseId,
                dashboardId: shareDashboardId,
                generatorActorId,
                scope: ''
            })
            .then(response => {
                const { vaultId } = response.data;
                dispatch({
                    type: ActionTypes.GetVaultFulfilled,
                    dashboardId: shareDashboardId,
                    vaultId
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.GetVaultRejected
                });
            });
    };
}

export function vaultLinkCopied() {
    return {
        type: ActionTypes.VaultLinkCopied
    };
}

export function getSharedUsers() {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;
        const { shareDashboardId: dashboardId } = getState().dashboards;

        dispatch({
            type: ActionTypes.GetSharedUsersPending
        });

        return axios
            .post(`${Urls.DashboardsApi}dashboard/users`, {
                enterpriseId,
                dashboardId
            })
            .then(response => {
                dispatch({
                    type: ActionTypes.GetSharedUsersFulfilled,
                    dashboardId,
                    sharedUsers: response.data.data
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.GetSharedUsersRejected
                });
            });
    };
}

export function shareDashboard(
    dashboardId: string,
    selectedActorIds: any,
    deselectedActorIds: any[],
    newUserEmails: string[],
    sendNotification: boolean
) {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;

        let promise = Promise.resolve([]);
        if (newUserEmails && newUserEmails.length) {
            promise = dispatch(
                addUsers(newUserEmails, [], '', sendNotification)
            );
        }

        return promise.then(newActorIds => {
            let shareActorIds = selectedActorIds;
            shareActorIds = shareActorIds.concat(newActorIds);
            const sharePromises = shareActorIds.map((actorId: any) =>
                axios.put(`${Urls.DashboardsApi}share`, {
                    enterpriseId,
                    dashboardId,
                    actorId,
                    sendNotification
                })
            );

            const unsharePromises = deselectedActorIds.map((actorId: any) =>
                axios.put(`${Urls.DashboardsApi}share/remove`, {
                    enterpriseId,
                    dashboardId,
                    actorId
                })
            );

            const dashboard = getState().dashboards[dashboardId];

            dispatch({
                type: ActionTypes.UnshareDashboardPending
            });

            return Promise.all(unsharePromises)
                .then(() => {
                    dispatch({
                        type: ActionTypes.UnshareDashboardFulfilled,
                        dashboardName: dashboard ? dashboard.name : '',
                        count: unsharePromises.length
                    });
                    dispatch({
                        type: ActionTypes.ShareDashboardPending
                    });
                    return Promise.all(sharePromises);
                })
                .then(() => {
                    dispatch({
                        type: ActionTypes.ShareDashboardFulfilled,
                        dashboardName: dashboard ? dashboard.name : '',
                        count: sharePromises.length
                    });
                    dispatch(closeShareModal());
                })
                .catch(error => {
                    errorHandler.report(error);
                    dispatch({
                        type: ActionTypes.UnshareDashboardRejected
                    });
                    dispatch({
                        type: ActionTypes.ShareDashboardRejected
                    });
                });
        });
    };
}

export function setSearchText(searchText: any) {
    return (dispatch: any) => {
        if (searchText) {
            track(MixPanel.Events.MyDashboardsSearch, {
                'Search query': searchText
            });
        }
        dispatch({
            type: ActionTypes.SetSearchTextInMyDashboards,
            searchText
        });
    };
}

export function duplicateDashboard(
    dashboardId: string,
    name: any,
    updatedReports?: any[]
) {
    return (dispatch: any, getState: any) => {
        let dashboard = getState().dashboards[dashboardId];
        const { uid, firstName, lastName } = getState().auth.userInfo;

        dispatch({
            type: ActionTypes.DuplicateDashboardPending
        });

        if (!dashboard) {
            return dispatch({
                type: ActionTypes.DuplicateDashboardRejected
            });
        }

        if (!!updatedReports?.length) {
            dashboard = {
                ...dashboard,
                reports: updatedReports
            };
        }

        const reportIds: any[] = [];
        const reports: any[] = [];
        dashboard.reports.forEach((report: any) => {
            const newReportId = uuid.v4();
            reportIds.push({
                from: report.reportId,
                to: newReportId
            });
            const newReport = cloneDeep(report);
            newReport.reportId = newReportId;
            reports.push(newReport);
        });

        dashboard = {
            ...dashboard,
            dashboardId: uuid.v4(),
            name,
            reports,
            createdById: uid,
            createdByName: `${firstName} ${lastName}`,
            dateAdded: moment.utc().toISOString(),
            lastUpdatedAt: moment.utc().toISOString()
        };

        return axios
            .put(`${Urls.DashboardsApi}dashboard`, { dashboard })
            .then(() => {
                track(MixPanel.Events.MyDashboardsCreateDashboard, {
                    'Dashboard Name': dashboard.name
                });
                dispatch({
                    type: ActionTypes.DuplicateDashboardFulfilled,
                    dashboard
                });
                dispatch(getReports(dashboard.reports, true));
                dispatch(closeDuplicateModal());
                dispatch(navigateToDashboard(dashboard.dashboardId));
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.DuplicateDashboardRejected
                });
            });
    };
}

export function autosaveDashboard(updatedDashboard: any) {
    return async (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;
        const {
            dashboardId,
            name,
            reports,
            dateAdded,
            createdById,
            createdByName
        } = updatedDashboard;

        dispatch({
            type: ActionTypes.UpdateDashboardPending
        });

        const dashboard = {
            enterpriseId,
            dashboardId,
            name,
            reports,
            dateAdded,
            createdById,
            createdByName,
            lastUpdatedAt: moment.utc().toISOString()
        };

        return axios
            .put(`${Urls.DashboardsApi}dashboard`, { dashboard })
            .then(() => {
                dispatch({
                    type: ActionTypes.UpdateDashboardFulfilled,
                    dashboard
                });
            })
            .catch(error => {
                errorHandler.report(error);
                console.log(error);
                dispatch({
                    type: ActionTypes.UpdateDashboardRejected
                });
            });
    };
}

export function applyFilterPermanently(dashboard: any, updatedReports: any[]) {
    return (dispatch: any) => {
        dashboard = {
            ...dashboard,
            reports: updatedReports
        };
        const { dashboardId } = dashboard;

        return axios
            .put(`${Urls.DashboardsApi}dashboard`, { dashboard })
            .then(() => {
                dispatch({
                    type: ActionTypes.UpdateAllReportsFulfilled,
                    dashboardId,
                    dashboard
                });
            })
            .catch(error => {
                errorHandler.report(error);
            });
    };
}

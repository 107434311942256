import ActionTypes from '../../constants/ActionTypes';

interface ReducerState {
    pending?: boolean;
    deletePending?: boolean;
    paymentMethod?: any;
}

export default function billingReducer(
    state: ReducerState = {},
    action: any
): ReducerState {
    switch (action.type) {
        case ActionTypes.FetchBillingInfoPending:
            return {
                ...state,
                pending: true
            };
        case ActionTypes.FetchBillingInfoFulfilled:
            return {
                ...state,
                pending: false,
                ...action.data
            };
        case ActionTypes.FetchBillingInfoRejected:
            return {
                ...state,
                pending: false
            };
        case ActionTypes.DeletePaymentMethodPending:
            return {
                ...state,
                deletePending: true
            };
        case ActionTypes.DeletePaymentMethodFulfilled:
            return {
                ...state,
                deletePending: false,
                paymentMethod: undefined
            };
        case ActionTypes.DeletePaymentMethodRejected:
            return {
                ...state,
                deletePending: false
            };
        default:
            return state;
    }
}

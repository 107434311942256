import React from 'react';

interface StackProps {
    children?: React.ReactNode;
    flexDirection?: string;
    alignItems?: string;
    justifyContent?: string;
    flexWrap?: string;
    gap?: string;
    height?: string;
    className?: string;
    style?: React.CSSProperties;
    [key: string]: any;
}

const Stack = React.forwardRef<HTMLDivElement, StackProps>(
    (
        {
            children,
            flexDirection = 'column',
            alignItems = 'initial',
            justifyContent = 'initial',
            flexWrap = 'initial',
            gap = 'initial',
            height = 'auto',
            className = 'stack',
            style = {},
            ...rest
        },
        ref
    ) => {
        return (
            <div
                ref={ref}
                className={className}
                style={{
                    display: 'flex',
                    height,
                    flexDirection,
                    alignItems,
                    flexWrap,
                    justifyContent,
                    gap,
                    ...style
                }}
                {...rest}
            >
                {children}
            </div>
        );
    }
);

export default Stack;

import ActionTypes from '../../constants/ActionTypes';

interface ReducerState {
    uploadLogoPending?: boolean;
}

export default function settingsReducer(
    state: ReducerState = {},
    action: any
): ReducerState {
    switch (action.type) {
        case ActionTypes.UploadLogoPending:
            return {
                ...state,
                uploadLogoPending: true
            };
        case ActionTypes.UploadLogoFulfilled:
        case ActionTypes.UploadLogoRejected:
            return {
                ...state,
                uploadLogoPending: false
            };
        default:
            return state;
    }
}

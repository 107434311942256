import React from 'react';
import InnerVariableEdit from './InnerVariableEdit';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import produce from 'immer';

export default function InnerVariables({
    label,
    mainVariables,
    propName,
    setConfigurationVariables,
    index
}: any) {
    function handleNew() {
        setConfigurationVariables((configurationVariables: any) =>
            produce(configurationVariables, (draftState: any) => {
                draftState[index][propName].push({
                    analysisName: '',
                    columnName: '',
                    originalName: '',
                    weight: 0
                });
            })
        );
    }

    return (
        <>
            <div className="ds-label" style={{ marginBottom: 8 }}>
                <span style={{ marginRight: 16 }}>{label}</span>
                <Button
                    componentType={ButtonTypes.type.SECONDARY}
                    onClick={handleNew}
                    large={false}
                >
                    Add new
                </Button>
            </div>
            {mainVariables.map((mv: any, i: number) => (
                <InnerVariableEdit
                    mainVariable={mv}
                    propName={propName}
                    setConfigurationVariables={setConfigurationVariables}
                    index={index}
                    innerIndex={i}
                />
            ))}
            <div style={{ marginBottom: 16 }} />
        </>
    );
}

import React from 'react';
import PropTypes from 'prop-types';
import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';
import WithPermissions from '../common/WithPermissions';
import RbacActions from '../constants/RbacActions';
import usePermissions from '../hooks/usePermissions';

export default function EmptySection({
    width,
    showCreateDashboardModal,
    role
}) {
    const canEdit = usePermissions({
        actions: [RbacActions['Dashboards/Edit']]
    });

    return (
        <div className="dashboards__empty-section">
            <div className="dashboards__cards">
                <div
                    className="card card--empty"
                    style={{ width, height: (2 * width) / 3 }}
                >
                    <div className="card__title">
                        {!canEdit
                            ? 'You haven’t been invited to any dashboards yet'
                            : 'Create your first dashboard'}
                    </div>
                    <div className="card__empty-state-text">
                        {!canEdit
                            ? 'Your dashboards will appear here as soon as an admin or editor in your account shares one with you'
                            : 'All admins and editors will have access to the dashboards you create'}
                    </div>
                    <WithPermissions actions={[RbacActions['Dashboards/Edit']]}>
                        <div className="card__empty-state-button">
                            <Button
                                type={ButtonTypes.type.PRIMARY}
                                onClick={showCreateDashboardModal}
                            >
                                <span>Create Dashboard</span>
                            </Button>
                        </div>
                    </WithPermissions>
                </div>
                <div
                    className="card card--empty"
                    style={{ width, height: (2 * width) / 3 }}
                ></div>
                <div
                    className="card card--empty"
                    style={{ width, height: (2 * width) / 3 }}
                ></div>
            </div>
        </div>
    );
}

EmptySection.propTypes = {
    width: PropTypes.number,
    showCreateDashboardModal: PropTypes.func,
    role: PropTypes.string
};

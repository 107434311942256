import ActionTypes from '../../constants/ActionTypes';
import axios from 'axios';
import Urls from '../../constants/Urls';
import errorHandler from '../../lib/errorHandler';

export function fetchIntegrations() {
    return (dispatch: any, getState: any) => {
        const { enterpriseId, latestDataUpdate } = getState().account;

        dispatch({ type: ActionTypes.FetchIntegrationsPending });
        return axios
            .post(`${Urls.AccountApi}integrations`, {
                enterpriseId
            })
            .then(response => {
                dispatch({
                    type: ActionTypes.FetchIntegrationsFulfilled,
                    data: response.data,
                    latestDataUpdate
                });
            })
            .catch(error => {
                errorHandler.report(error);
                dispatch({
                    type: ActionTypes.FetchIntegrationsRejected
                });
            });
    };
}

import React, { useState } from 'react';
import {
    MdKeyboardArrowDown,
    MdKeyboardArrowUp,
    MdClose
} from 'react-icons/md';
import { TemplateFragment } from '../template/TemplateFragment';
import { Package } from './model';

interface PackageFragmentProps {
    packageObj: Package;
    expandable?: boolean;
    removable?: boolean;
    onRemove?: () => void;
}

export function PackageFragment({
    packageObj,
    expandable,
    removable,
    onRemove
}: PackageFragmentProps) {
    const [expanded, setExpanded] = useState(!expandable);

    function handleExpand() {
        setExpanded(true);
    }

    function handleCollapse() {
        setExpanded(false);
    }

    return expanded ? (
        <div>
            {expandable ? (
                <div className="ds-expander">
                    <div className="ds-expander__text">
                        <div className="ds-label">Package name</div>
                        <div className="ds-value">
                            {packageObj?.packageName}
                        </div>
                    </div>
                    <div className="ds-expander__icon" onClick={handleCollapse}>
                        <MdKeyboardArrowUp className="icon" size={20} />
                    </div>
                    {removable && (
                        <div className="ds-expander__close" onClick={onRemove}>
                            <MdClose className="icon" size={16} />
                        </div>
                    )}
                </div>
            ) : (
                <>
                    <div className="ds-label">Package name</div>
                    <div className="ds-value">{packageObj?.packageName}</div>
                </>
            )}
            <div style={{ marginLeft: expandable ? 24 : 0 }}>
                <div className="ds-label">Package id</div>
                <div className="ds-value">{packageObj?.packageId}</div>
                <div className="ds-label">Data source name</div>
                <div className="ds-value">{packageObj?.dataSource}</div>
                {packageObj?.templates.map((t, i) => (
                    <TemplateFragment
                        key={t.templateId}
                        template={t}
                        expandable
                    />
                ))}
            </div>
        </div>
    ) : (
        <div className="ds-expander">
            <div className="ds-expander__text">
                <div className="ds-label">Package name</div>
                <div className="ds-value">{packageObj?.packageName}</div>
            </div>
            <div className="ds-expander__icon" onClick={handleExpand}>
                <MdKeyboardArrowDown className="icon" size={20} />
            </div>
            {removable && (
                <div className="ds-expander__close" onClick={onRemove}>
                    <MdClose className="icon" size={16} />
                </div>
            )}
        </div>
    );
}

export default PackageFragment;

import gsap from 'gsap';
import { useContext, useEffect } from 'react';
import { InsightsContext } from '.';
import ButtonTypes from '../../constants/ButtonTypes';
import cn from '../../lib/cn';
import Button from '../Button';

export default function DashboardOptions({ delay = 0 }: any) {
    const { handleSummarizeDashboard, handleReportSelectMode } =
        useContext(InsightsContext);

    useEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap.timeline({});
            tl.fromTo(
                '.animate-button',
                {
                    autoAlpha: 0,
                    y: 25
                },
                {
                    autoAlpha: 1,
                    y: 0,
                    duration: 0.6,
                    stagger: 0.175,
                    ease: 'power2.out',
                    delay
                }
            );
        });

        return () => {
            ctx.revert();
        };
    }, [delay]);
    return (
        <div className={cn('w-avail')}>
            <div className={cn('flex flex-col gap-[.8rem] items-end')}>
                <div className={cn('animate-button invisible')}>
                    <Button
                        onClick={handleSummarizeDashboard}
                        componentType={ButtonTypes.type.SECONDARY}
                    >
                        Summarize entire dashboard
                    </Button>
                </div>
                <div className={cn('animate-button invisible')}>
                    <Button
                        onClick={handleReportSelectMode}
                        componentType={ButtonTypes.type.SECONDARY}
                    >
                        Get insights on specific reports
                    </Button>
                </div>
            </div>
        </div>
    );
}

import ActionTypes from '../constants/ActionTypes';
import orderBy from 'lodash/orderBy';
import moment from 'moment';
import { CommentsState } from './models';

export default function commentsReducer(
    state: CommentsState = {},
    action: any
): CommentsState {
    switch (action.type) {
        case ActionTypes.OpenComments:
            return {
                ...state,
                show: true
            };
        case ActionTypes.CloseComments:
            return {
                ...state,
                show: false
            };
        case ActionTypes.GetCommentCountFulfilled: {
            const { count, category, attributes } = action;
            return {
                ...state,
                commentCount: count,
                category,
                attributes
            };
        }
        case ActionTypes.GetCommentsPending:
            return {
                ...state,
                loading: true,
                error: false
            };
        case ActionTypes.GetCommentsFulfilled: {
            let { comments } = action;
            comments.forEach((c: any) => {
                c.commentDate = moment(c.commentDate);
                c.replies.forEach((r: any) => {
                    r.replyDate = moment(r.replyDate);
                });
                c.replies = orderBy(c.replies, r => r.replyDate);
                c.timestamp = c.commentDate;
                if (c.replies.length) {
                    c.timestamp = c.replies[c.replies.length - 1].replyDate;
                }
            });
            comments = orderBy(comments, ['timestamp'], ['desc']);
            return {
                ...state,
                loading: false,
                comments
            };
        }
        case ActionTypes.GetCommentsRejected:
            return {
                ...state,
                loading: false,
                error: true
            };
        case ActionTypes.AddCommentPending:
            return {
                ...state,
                addCommentPending: true
            };
        case ActionTypes.AddCommentFulfilled: {
            const {
                actorId,
                actorInfo,
                commentId,
                enterpriseId,
                text,
                taggedActors,
                commentDate
            } = action;
            const comment = {
                actorId,
                actorInfo,
                commentId,
                enterpriseId,
                text,
                taggedActors,
                commentDate,
                timestamp: commentDate,
                replies: []
            };
            let { comments = [], commentCount = 0 } = state;
            commentCount++;
            comments = comments.slice();
            comments.unshift(comment);
            return {
                ...state,
                comments,
                commentCount,
                addCommentPending: false
            };
        }
        case ActionTypes.AddCommentRejected:
            return {
                ...state,
                addCommentPending: false
            };
        case ActionTypes.AddReplyPending:
            return {
                ...state,
                addReplyPending: true
            };
        case ActionTypes.AddReplyFulfilled: {
            const { commentId } = action;
            let { comments = [] } = state;
            const index = comments.findIndex(c => c.commentId === commentId);
            if (index !== -1) {
                comments = comments.slice();
                const replies = comments[index].replies.slice();
                const {
                    text,
                    taggedActors,
                    replyId,
                    replyDate,
                    actorId,
                    actorInfo
                } = action;
                const reply = {
                    commentId,
                    replyId,
                    actorId,
                    actorInfo,
                    replyDate,
                    text,
                    taggedActors
                };
                replies.push(reply);
                comments[index] = {
                    ...comments[index],
                    replies,
                    timestamp: replyDate
                };
                comments = orderBy(comments, ['timestamp'], ['desc']);
            }
            return {
                ...state,
                comments,
                addReplyPending: false
            };
        }
        case ActionTypes.AddReplyRejected:
            return {
                ...state,
                addReplyPending: false
            };
        case ActionTypes.UpdateCommentPending:
            return {
                ...state,
                updateCommentPending: true
            };
        case ActionTypes.UpdateCommentFulfilled: {
            const index = state.comments?.findIndex(
                c => c.commentId === action.commentId
            );
            let comments = state.comments || [];
            if (index !== undefined && index !== -1) {
                comments = comments.slice();
                comments[index] = {
                    ...comments[index],
                    text: action.text,
                    taggedActors: action.taggedActors,
                    commentDate: moment(action.commentDate),
                    timestamp: action.commentDate
                };
                comments = orderBy(comments, ['timestamp'], ['desc']);
            }
            return {
                ...state,
                comments,
                updateCommentPending: false,
                showEdit: false
            };
        }
        case ActionTypes.UpdateCommentRejected:
            return {
                ...state,
                updateCommentPending: false
            };
        case ActionTypes.UpdateReplyPending:
            return {
                ...state,
                updateReplyPending: true
            };
        case ActionTypes.UpdateReplyFulfilled: {
            let comments = state.comments || [];
            const commentIndex = comments.findIndex(
                c => c.commentId === action.commentId
            );
            const replyIndex = comments[commentIndex].replies.findIndex(
                r => r.replyId === action.replyId
            );
            if (commentIndex !== -1 && replyIndex !== -1) {
                comments = comments.slice();
                const replies = comments[commentIndex].replies.slice();
                replies[replyIndex] = {
                    ...replies[replyIndex],
                    text: action.text,
                    taggedActors: action.taggedActors,
                    replyDate: action.replyDate
                };
                comments[commentIndex] = {
                    ...comments[commentIndex],
                    replies,
                    timestamp: action.replyDate
                };
                comments = orderBy(comments, ['timestamp'], ['desc']);
            }
            return {
                ...state,
                comments,
                updateReplyPending: false,
                showEdit: false
            };
        }
        case ActionTypes.UpdateReplyRejected:
            return {
                ...state,
                updateReplyPending: false
            };
        case ActionTypes.GetCanCommentFulfilled: {
            const { actors } = action;
            actors.forEach((actor: any) => {
                actor.shortName = actor.email.substring(
                    0,
                    actor.email.indexOf('@')
                );
            });
            return {
                ...state,
                actors
            };
        }
        case ActionTypes.ResolveCommentFulfilled: {
            const { undo } = action;
            let { commentCount = 0 } = state;
            commentCount = undo ? commentCount + 1 : commentCount - 1;
            return {
                ...state,
                commentCount
            };
        }
        case ActionTypes.DeleteComment: {
            let { comments = [] } = state;
            const index = comments.findIndex(
                c => c.commentId === action.commentId
            );
            if (index !== -1) {
                comments = comments.slice();
                comments.splice(index, 1);
            }
            return {
                ...state,
                comments
            };
        }
        case ActionTypes.EditComment:
            return {
                ...state,
                showEdit: true,
                editId: action.editId
            };
        case ActionTypes.CancelEditComment:
            return {
                ...state,
                showEdit: false
            };
        default:
            return state;
    }
}

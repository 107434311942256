export interface FeedbackCreateRequestTokenBased {
    token_or_url: string;
    score?: number | boolean;
    value?: number | boolean | string | object;
    comment?: string;
    correction?: object;
    metadata?: object;
}

export interface FeedbackData {
    is_good: boolean;
    value?: number;
    comment?: string;
    updated_at?: Date;
}

export interface InsightsInvokeRequest {
    input: InputInsights;
    config: InsightsRunnableSequenceConfig;
    kwargs?: object;
}

export interface InputInsights {
    question: string;
    context: ContextData[];
    model: InsidesModels;
}

export interface ContextData {
    title: string;
    interval: string;
    data: string;
    user_context: string;
}

export interface InsidesModels {
    gemini_pro: string;
    gemini_pro_15: string;
    gemini_flash_15: string;
    bison: string;
}

export interface InsightsRunnableSequenceConfig {
    configurable: InsightsConfigurable;
}

export interface InsightsConfigurable {
    conversation_id?: string;
    enterprise_id: string;
    user_id?: string;
}

export interface ChatInvokeRequest {
    input: InputChat;
    config: ChatRunnableSequenceConfig;
    kwargs?: object;
}

export interface InputChat {
    input: string;
}

export interface ChatRunnableSequenceConfig {
    configurable: ChatConfigurable;
}

export interface ChatConfigurable {
    conversation_id?: string;
    enterprise_id: string;
    user_id?: string;
}

export interface InsightsResponse {
    insightsData: string;
    error?: string;
}

interface Metadata {
    [key: string]: any;
}

export enum ChatMessageRoles {
    USER = 'user',
    ASSISTANT = 'assistant',
}

export interface ChatMessage {
    role: ChatMessageRoles;
    content: string;
    additional_kwargs?: any;
    metadata?: Metadata;
    feedback?: FeedbackData;
}

import React, { Fragment } from 'react';
import { scaleLinear, scaleBand } from 'd3-scale';
import { NodeGroup } from 'react-move';
import Constants from '../constants/Constants';
import getInterpolator from '../lib/getInterpolator';
import { CollectAttribute } from './model';

const BAR_HEIGHT = 21;
const WIDTH = 320;

interface ChartProps {
    attributes: CollectAttribute[];
}

export default function Chart({ attributes }: ChartProps) {
    const height = attributes.length * BAR_HEIGHT;

    function getOpacity(perc: number) {
        return perc === 100 ? 0.2 : 1;
    }

    const xScale = scaleLinear().domain([0, 100]).range([1, WIDTH]);

    const yScale = scaleBand()
        .domain(attributes.map(a => a.fieldName))
        .range([0, height]);

    return (
        <div className="collect-card__chart">
            <div className="collect-card__chart__title">
                Data completion by field
            </div>
            <svg width={WIDTH} height={height}>
                <NodeGroup
                    keyAccessor={a => a.fieldName}
                    data={attributes}
                    start={() => {
                        return {
                            width: 0
                        };
                    }}
                    enter={d => {
                        return {
                            width: [xScale(d.completionPercentage)],
                            timing: {
                                duration: Constants.AnimationDuration,
                                ease: Constants.EasingFn
                            }
                        };
                    }}
                    update={d => {
                        return {
                            width: [0, xScale(d.completionPercentage)],
                            timing: {
                                duration: Constants.AnimationDuration,
                                ease: Constants.EasingFn
                            }
                        };
                    }}
                    interpolation={getInterpolator}
                >
                    {nodes => (
                        <g>
                            {nodes.map(({ state, data, key }) => (
                                <Fragment key={key}>
                                    <rect
                                        x={xScale(0)}
                                        y={yScale(data.fieldName)}
                                        width={state.width}
                                        height={BAR_HEIGHT}
                                        fill={'var(--color-graph-1)'}
                                        fillOpacity={getOpacity(
                                            data.completionPercentage
                                        )}
                                    />
                                </Fragment>
                            ))}
                        </g>
                    )}
                </NodeGroup>
                <line
                    x1={0}
                    y1={0}
                    x2={0}
                    y2={height}
                    strokeWidth={1}
                    stroke="var(--color-ui-100)"
                />
            </svg>
        </div>
    );
}

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={24}
        height={24}
        viewBox="0 0 24 24"
        fill="none"
        {...props}
    >
        <path
            fill="currentColor"
            d="M9 19.615c-.444 0-.824-.158-1.14-.474A1.555 1.555 0 0 1 7.384 18c0-.444.158-.825.474-1.14A1.556 1.556 0 0 1 9 16.384c.444 0 .825.158 1.141.474.316.316.475.697.475 1.141 0 .444-.159.825-.475 1.14a1.555 1.555 0 0 1-1.14.475Zm6 0c-.444 0-.824-.158-1.14-.474A1.555 1.555 0 0 1 13.384 18c0-.444.158-.825.474-1.14A1.555 1.555 0 0 1 15 16.384c.444 0 .825.158 1.141.474.316.316.475.697.475 1.141 0 .444-.159.825-.475 1.14a1.555 1.555 0 0 1-1.14.475Zm-6-6c-.444 0-.824-.158-1.14-.474A1.555 1.555 0 0 1 7.384 12c0-.444.158-.825.474-1.14A1.556 1.556 0 0 1 9 10.384c.444 0 .825.158 1.141.474.316.316.475.697.475 1.141 0 .444-.159.825-.475 1.14a1.555 1.555 0 0 1-1.14.475Zm6 0c-.444 0-.824-.158-1.14-.474A1.555 1.555 0 0 1 13.384 12c0-.444.158-.825.474-1.14A1.555 1.555 0 0 1 15 10.384c.444 0 .825.158 1.141.474.316.316.475.697.475 1.141 0 .444-.159.825-.475 1.14a1.555 1.555 0 0 1-1.14.475Zm-6-6c-.444 0-.824-.158-1.14-.474A1.556 1.556 0 0 1 7.384 6c0-.444.158-.825.474-1.14A1.556 1.556 0 0 1 9 4.384c.444 0 .825.158 1.141.474.316.316.475.697.475 1.141 0 .444-.159.824-.475 1.14a1.556 1.556 0 0 1-1.14.475Zm6 0c-.444 0-.824-.158-1.14-.474A1.555 1.555 0 0 1 13.384 6c0-.444.158-.825.474-1.14A1.555 1.555 0 0 1 15 4.384c.444 0 .825.158 1.141.474.316.316.475.697.475 1.141 0 .444-.159.824-.475 1.14a1.556 1.556 0 0 1-1.14.475Z"
        />
    </svg>
);
export default SvgComponent;

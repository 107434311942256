import React from 'react';
import { MdMore } from 'react-icons/md';
import Popover from '../../../common/Popover';
import More from '../../../icons/More';
import { IGoal } from '../../types';
import { goalCategoryFromStatus } from '../../utils';
import GoalCardMoreItems from './GoalCardMoreItems';
// import EventMoreItems from './EventMoreItems';

interface GoalCardMoreProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    goalId: string;
    goal: IGoal;
}

const GoalCardMore = ({ setOpen, open, goalId, goal }: GoalCardMoreProps) => {
    return (
        <Popover
            closeOnScroll={true}
            placement="bottom"
            overlayClassName="popover--no-padding"
            destroyTooltipOnHide
            showArrow={false}
            visible={open}
            onVisibleChange={() => {
                setOpen(!open);
            }}
            content={
                <GoalCardMoreItems
                    open={open}
                    setOpen={setOpen}
                    goalId={goalId}
                    showDelete={
                        goalCategoryFromStatus(goal.goalStatus) === 'active'
                    }
                />
            }
        >
            <div onClick={e => e.stopPropagation()}>
                <More width={20} height={20} className="icon" />
            </div>
        </Popover>
    );
};

export default GoalCardMore;

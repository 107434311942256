import Forbidden from '../routes/Forbidden';
import usePermissions from '../hooks/usePermissions';
import { useHistory } from 'react-router-dom';
import RbacActions from '../constants/RbacActions';

interface IWithPermissionsProps {
    actions: RbacActions[];
    children: any;
    showMessage?: boolean;
}

const WithPermissions = (props: IWithPermissionsProps) => {
    const { actions, children, showMessage } = props;
    const hasPermission = usePermissions({ actions });
    const history = useHistory();

    if (!hasPermission) {
        if (showMessage) {
            return <Forbidden history={history} />;
        }
        return null;
    }

    return children;
};

export default WithPermissions;

import React, { useEffect } from 'react';
import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';
import { push } from 'connected-react-router';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { fetchCategories } from '../category/reducer';
import MoveVertical from '../../../common/MoveVertical';
import { moveUp, moveDown, toggleQuestion } from './editReducer';
import Trash from '../../../icons/Trash';

export default function Questionnaire() {
    const dispatch = useAppDispatch();
    const { questions, questionOrder } = useAppSelector(
        state => state.datastudio.survey.surveyEdit.surveyToEdit
    );
    const { title } = useAppSelector(
        state => state.datastudio.survey.surveyEdit.surveyToEdit
    );
    const { data: categories, loaded: categoriesLoded } = useAppSelector(
        state => state.datastudio.survey.categories
    );

    function getCategoryName(globalCategoryId: string) {
        return (
            categories.find(c => c.globalCategoryId === globalCategoryId)
                ?.title ?? ''
        );
    }

    useEffect(() => {
        if (!categoriesLoded) {
            dispatch(fetchCategories());
        }
    }, []);

    let orderedQuestions = questionOrder.length
        ? questionOrder.map(qid =>
              questions.find(q => q.globalQuestionId === qid)
          )
        : questions;

    return (
        <div className="questionnaire">
            <div className="ds-label questionnaire__campaign">
                Campaign name:
                <span className="ds-label__value">{title}</span>
            </div>
            <div className="questionnaire__title">Questions</div>
            <div className="questionnaire__content">
                <div className="questionnaire__left">
                    {orderedQuestions.map((q, i) => (
                        <div key={i} className="selected-question">
                            <div className="selected-question__content">
                                <div className="selected-question__title">
                                    {q?.title}
                                </div>
                                <div className="selected-question__category">
                                    {getCategoryName(q?.globalCategoryId ?? '')}
                                </div>
                                <div className="selected-question__button">
                                    <MoveVertical
                                        hideUp={i === 0}
                                        hideDown={
                                            i === orderedQuestions.length - 1
                                        }
                                        moveUp={() => dispatch(moveUp(i))}
                                        moveDown={() => dispatch(moveDown(i))}
                                    />
                                </div>
                            </div>
                            <div className="selected-question__delete">
                                <Button
                                    componentType={ButtonTypes.type.TERTIARY}
                                    circle={true}
                                    onClick={async () => {
                                        dispatch(
                                            toggleQuestion(
                                                q || questionOrder[i]
                                            )
                                        );
                                    }}
                                >
                                    <Trash width={24} />
                                </Button>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="questionnaire__right">
                    <Button
                        componentType={ButtonTypes.type.PRIMARY}
                        onClick={() =>
                            dispatch(
                                push('/datastudio/survey/template/questions')
                            )
                        }
                    >
                        Add question
                    </Button>
                </div>
            </div>
        </div>
    );
}

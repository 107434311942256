import ActionTypes from '../constants/ActionTypes';
import getBenchmarkData from './getBenchmarkData';
import { BenchmarkModalState } from './model';

export default function benchmarkModalReducer(
    state: BenchmarkModalState = {},
    action: any
): BenchmarkModalState {
    switch (action.type) {
        case ActionTypes.OpenBenchmarkModal:
            return {
                ...state,
                isOpen: true,
                showPreview: false,
                noData: false
            };
        case ActionTypes.CloseBenchmarkModal:
            return {
                ...state,
                isOpen: false
            };
        case ActionTypes.QueryBenchmarkPending:
            return {
                ...state,
                error: false,
                pending: true,
                noData: false,
                data: []
            };
        case ActionTypes.QueryBenchmarkFulfilled:
            return {
                ...state,
                pending: false,
                data: getBenchmarkData(action.attribute, action.data),
                showPreview: true
            };
        case ActionTypes.QueryBenchmarkRejected:
            return {
                ...state,
                error: true,
                pending: false
            };
        case ActionTypes.QueryBenchmarkNoData:
            return {
                ...state,
                noData: true,
                pending: false
            };
        case ActionTypes.ShowPreviewInBenchmarkModal:
            return {
                ...state,
                showPreview: action.showPreview
            };
        case ActionTypes.SaveBenchmarkPending:
            return {
                ...state,
                saveError: false,
                savePending: true
            };
        case ActionTypes.SaveBenchmarkFulfilled:
            return {
                ...state,
                savePending: false,
                isOpen: false
            };
        case ActionTypes.SaveBenchmarkRejected:
            return {
                ...state,
                saveError: true,
                savePending: false
            };
        default:
            return state;
    }
}

enum RbacActions {
    'Dashboards/ViewAll' = 'Dashboards/ViewAll',
    'Dashboards/ViewShared' = 'Dashboards/ViewShared',
    'Dashboards/Share' = 'Dashboards/Share',
    'Dashboards/Edit' = 'Dashboards/Edit',
    'Dashboards/Explore' = 'Dashboards/Explore',
    'Explore/View' = 'Explore/View',
    'Explore/SaveReport' = 'Explore/SaveReport',
    'Explore/Export' = 'Explore/Export',
    'Explore/Generate' = 'Explore/Generate',
    'Roles/View' = 'Roles/View',
    'Roles/Edit' = 'Roles/Edit',
    'Collect/View' = 'Collect/View',
    'Users/View' = 'Users/View',
    'Users/Edit' = 'Users/Edit',
    'Events/View' = 'Events/View',
    'Events/Edit' = 'Events/Edit',
    'Goals/View' = 'Goals/View',
    'Goals/Edit' = 'Goals/Edit',
    'Integrations/View' = 'Integrations/View',
    'Integrations/Edit' = 'Integrations/Edit',
    'Benchmarks/View' = 'Benchmarks/View',
    'Benchmarks/Edit' = 'Benchmarks/Edit',
    'Comments/View' = 'Comments/View',
    'Comments/Edit' = 'Comments/Edit',
    'Policies/View' = 'Policies/View',
    'Policies/Edit' = 'Policies/Edit',
    'Groups/View' = 'Groups/View',
    'Billing/View' = 'Billing/View',
    'Billing/Edit' = 'Billing/Edit',
    'Settings/View' = 'Settings/View',
    'Settings/Edit' = 'Settings/Edit',
    'Campaign/View' = 'Campaign/View',
    'Campaign/Edit' = 'Campaign/Edit',
    'Campaign/ViewMetrics' = 'Campaign/ViewMetrics'
}

export default RbacActions;

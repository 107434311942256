import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

export default function ListItem({ type, onClick, activated, children }: any) {
    return (
        <div
            onClick={onClick}
            className={classNames('list-item', `list-item--${type}`, {
                [`list-item--${type}--activated`]: activated
            })}
        >
            {children}
        </div>
    );
}

ListItem.propTypes = {
    onClick: PropTypes.func,
    type: PropTypes.string,
    activated: PropTypes.bool,
    children: PropTypes.node
};

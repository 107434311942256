import ActionTypes from '../../constants/ActionTypes';
import produce from 'immer';
import { Enterprise } from './model';
import sortBy from 'lodash/sortBy';

interface ReducerState {
    pending?: boolean;
    error?: boolean;
    data?: Enterprise[];
    loaded?: boolean;
}

export default function reducer(
    state: ReducerState = {},
    action: any
): ReducerState {
    switch (action.type) {
        case ActionTypes.GetDSEnterprisesPending: {
            return {
                ...state,
                pending: true,
                error: false
            };
        }
        case ActionTypes.GetDSEnterprisesFulfilled: {
            let { data, configData } = action;
            data.forEach((e: any) => {
                e.packages.forEach((p: any) => {
                    p.templates = p.templates.map((t: any) => {
                        const config = configData.find(
                            (c: any) =>
                                c.templateConfigurationId ===
                                t.templateConfigurationId
                        );
                        const { templateName } = config || {};
                        return {
                            ...t,
                            templateName
                        };
                    });
                    p.templates = sortBy(p.templates, t => t.templateName);
                });
                e.packages = sortBy(e.packages, p => p.packageName);
            });
            data = sortBy(data, d => d.enterpriseName);
            return {
                ...state,
                pending: false,
                data,
                loaded: true
            };
        }
        case ActionTypes.GetDSEnterprisesRejected: {
            return {
                ...state,
                pending: false,
                error: true
            };
        }
        case ActionTypes.SaveEnterpriseFulfilled: {
            const { enterprise, enterpriseName } = action;
            return produce(state, draftState => {
                const index = draftState.data?.findIndex(
                    d => d.enterpriseId === enterprise.enterpriseId
                );
                if (index !== undefined && index >= 0 && draftState.data) {
                    enterprise.enterpriseName = enterpriseName;
                    draftState.data[index] = enterprise;
                }
            });
        }
        default:
            return state;
    }
}

import React, { useEffect, useState } from 'react';
import SaveIcon from '../../icons/Save';
import cn from '../../lib/cn';
import { useAppSelector } from '../../store/hooks';

export default function SaveIndicator() {
    const { pending } = useAppSelector(state => state.dashboard.view);
    const [isSaving, setIsSaving] = useState(false);

    useEffect(() => {
        if (pending) {
            setIsSaving(true);
        }
    }, [pending]);

    useEffect(() => {
        let timeout: NodeJS.Timeout;

        if (isSaving) {
            timeout = setTimeout(() => {
                setIsSaving(false);
            }, 2000);
        }

        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [isSaving]);

    return (
        <div
            className={cn(
                'flex items-center gap-[8px]',
                'text-ui-50',
                !isSaving && 'hidden'
            )}
        >
            <SaveIcon className={cn('animate-spin')} />
            <div className={cn('font-text')}>Saving</div>
        </div>
    );
}

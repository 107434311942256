import { useDraggable } from '@dnd-kit/core';

export default function DraggableModal({ children, position }: any) {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        setActivatorNodeRef
    } = useDraggable({
        id: 'draggable-modal'
    });

    const finalPosition = transform
        ? {
              x: position.x + transform.x,
              y: position.y + transform.y
          }
        : position;

    const style = {
        transform: `translate3d(${finalPosition.x}px, ${finalPosition.y}px, 0)`
    };

    return (
        <div ref={setNodeRef} style={style}>
            {children({ attributes, listeners, setActivatorNodeRef })}
        </div>
    );
}

import * as React from 'react';
import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={23}
        height={23}
        viewBox="0 0 23 23"
        fill="none"
        {...props}
    >
        <circle
            cx={11.5}
            cy={11.5}
            r={11}
            fill="currentColor"
            stroke="currentColor"
        />
        <path
            fill="#000"
            d="M10.033 16 6 12.354l.774-.7 3.259 2.946L16.226 9l.774.7-6.967 6.3Z"
        />
    </svg>
);
export default SvgComponent;

import React from 'react';

function sortItems(items: any, sortConfig: any) {
    return items.sort((a: any, b: any) => {
        if (
            typeof a[sortConfig.key] === 'string' &&
            typeof b[sortConfig.key] === 'string'
        ) {
            return sortConfig.direction === 'asc'
                ? a[sortConfig.key]
                      .toLowerCase()
                      .localeCompare(b[sortConfig.key].toLowerCase())
                : b[sortConfig.key]
                      .toLowerCase()
                      .localeCompare(a[sortConfig.key].toLowerCase());
        } else if (
            typeof a[sortConfig.key] === 'number' &&
            typeof b[sortConfig.key] === 'number'
        ) {
            return sortConfig.direction === 'asc'
                ? a[sortConfig.key] - b[sortConfig.key]
                : b[sortConfig.key] - a[sortConfig.key];
        }

        return 0;
    });
}

export default function useFilteredAndSortedItems({
    items,
    searchText,
    sortConfig,
    keepOnTop
}: {
    items: any[];
    searchText: string;
    sortConfig: {
        key: string;
        direction: string;
    };
    keepOnTop?: string;
}) {
    const getItems = React.useMemo(() => {
        if (searchText) {
            items = items.filter(a => {
                return a.searchValue.indexOf(searchText.toLowerCase()) > -1;
            });
        }

        items = sortItems(items, sortConfig);

        if (keepOnTop) {
            const keepOnTopItem = items.find(a => a.actorId === keepOnTop);
            if (keepOnTopItem) {
                items = items.filter(a => {
                    return a.actorId !== keepOnTop;
                });
                items.unshift(keepOnTopItem);
            }
        }

        return items;
    }, [items, searchText, sortConfig, keepOnTop]);

    return getItems;
}

enum ChartTypes {
    'Line' = 'Line',
    'Bar' = 'Bar',
    'Pie' = 'Pie',
    'Table' = 'Table',
    'Matrix' = 'Matrix',
    'HorizontalBar' = 'HorizontalBar',
    'Text' = 'Text',
    'Stats' = 'Stats',
    'ArithmeticTable' = 'ArithmeticTable',
    'Funnel' = 'Funnel',
    'StackedBar' = 'StackedBar',
    'InsightsText' = 'InsightsText'
}

export default ChartTypes;

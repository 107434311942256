import React, { ReactNode, useState } from 'react';
import Modal from '../common/Modal';
import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';

interface ModalStateWrapperProps {
    children: ReactNode;
}

const ModalStateWrapper = ({ children } : ModalStateWrapperProps) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div>
            <Button
                componentType={ButtonTypes.type.SECONDARY}
                large={true}
                onClick={toggleModal}
            >
                Open Modal
            </Button>
            <Modal
                visible={isOpen}
                onCancel={toggleModal}
                footer={null}
                closable={false}
                width={600}
                zIndex={3000}
                destroyOnClose
                centered
            >
                {children}
            </Modal>
        </div>
    );
};

export default ModalStateWrapper;

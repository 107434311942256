import Loader from '../lottie/graph-loader.json';
import LottieAnimation from './LottieAnimation';
import Stack from './Stack';

const WithLoadingStates = ({
    isLoading,
    isEmpty,
    isError,
    errorMessage,
    emptyContent,
    children
}: {
    isLoading: boolean | undefined;
    isEmpty: boolean | undefined;
    isError: boolean | undefined;
    errorMessage?: string;
    emptyContent?: any;
    children: any;
}) => {
    if (isLoading) {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                style={{
                    padding: '5vh'
                }}
            >
                <LottieAnimation
                    autoplay={true}
                    loop={true}
                    width={60}
                    height={60}
                    animation={Loader}
                ></LottieAnimation>
            </Stack>
        );
    }

    if (isError) {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                style={{ padding: '5vh' }}
            >
                <div>{errorMessage || 'Error'}</div>
            </Stack>
        );
    }

    if (isEmpty) {
        return (
            <Stack
                alignItems="center"
                justifyContent="center"
                style={{ padding: '5vh' }}
            >
                {emptyContent}
            </Stack>
        );
    }

    return <>{children}</>;
};

export default WithLoadingStates;

import { useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';

import { FeedbackData } from '../ai/aiTypes';
import { provideChatFeedbackMessage } from '../ai/chat/actions';
import { provideInsightFeedbackMessage } from '../ai/insights/actions';
import CloseIcon from '../icons/Close';
import ThumbsDownIcon from '../icons/ThumbsDown';
import ThumbsUpIcon from '../icons/ThumbsUp';
import cn from '../lib/cn';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { InsightsContext } from './AiInsightsModal';
import Button from './Button';
import TextField from './TextField';

export default function Feedback({
    isChat = false,
    messageIdx = 0,
    feedback,
}: {
    isChat?: boolean;
    messageIdx?: number;
    feedback?: FeedbackData;
}) {
    const dispatch = useAppDispatch();
    const scrollToRef = useRef<any>();
    const { setIsGivingFeedback } = useContext(InsightsContext);
    const [success, setSuccess] = useState<boolean>(false);
    const [additionalFeedback, setAdditionalFeedback] = useState<boolean>(false);
    const [userFeedback, setUserFeedback] = useState<'happy' | 'unhappy' | undefined>(undefined);
    const { conversationId } = isChat ? useParams<any>() : useAppSelector((state) => state.dashboard.aiInsights);

    const onCancel = () => {
        setAdditionalFeedback(false);
    };

    useEffect(() => {
        if (feedback) {
            if (feedback.is_good) {
                setUserFeedback('happy');
            } else {
                setUserFeedback('unhappy');
            }
        }
    }, [feedback]);

    useEffect(() => {
        if (!isChat) {
            if (additionalFeedback) {
                if (scrollToRef.current) {
                    scrollToRef.current.scrollIntoView({
                        behavior: 'smooth',
                    });
                }
                setIsGivingFeedback?.(true);
            } else {
                setIsGivingFeedback?.(false);
            }
        }
    }, [additionalFeedback]);

    const feedbackMessage = (feedback: FeedbackData) => {
        const message = {
            conversationId,
            feedback,
            messageIdx,
        };
        dispatch(isChat ? provideChatFeedbackMessage(message) : provideInsightFeedbackMessage(message));
    };

    return (
        <div className={cn('flex flex-col gap-[1.6rem] items-start')} ref={scrollToRef}>
            <div className={cn('flex items-center gap-[.5rem]')}>
                <ThumbButton
                    up={true}
                    activated={userFeedback === 'happy'}
                    onClick={() => {
                        setAdditionalFeedback(false);
                        setSuccess(true);
                        setUserFeedback('happy');
                        feedbackMessage({
                            is_good: true,
                        });
                    }}
                />
                <ThumbButton
                    up={false}
                    activated={userFeedback === 'unhappy'}
                    onClick={() => {
                        setAdditionalFeedback(true);
                        setSuccess(false);
                        setUserFeedback('unhappy');
                        feedbackMessage({
                            is_good: false,
                        });
                    }}
                />
            </div>
            {success ? (
                <SuccessMessage />
            ) : (
                <UserInput
                    visible={additionalFeedback}
                    onCancel={onCancel}
                    onSuccess={(value: string) => {
                        setSuccess(true);
                        setAdditionalFeedback(false);
                        feedbackMessage({
                            is_good: false,
                            comment: value,
                        });
                    }}
                />
            )}
        </div>
    );
}

export const SuccessMessage = () => {
    const [visible, setVisible] = useState(true);

    useEffect(() => {
        if (visible) {
            setTimeout(() => setVisible(false), 3000);
        }
    }, [visible]);

    return (
        <div
            className={cn(
                'ml-[.8rem] bg-shade-h4 p-[1.6rem] rounded-[.8rem]',
                'flex items-center gap-[.8rem]',
                !visible && 'hidden'
            )}
        >
            <div>Thanks for your feedback!</div>
            <button
                onClick={() => setVisible(false)}
                className={cn('flex-shrink-0 w-[2rem] h-[2rem] flex items-center justify-center text-ui-50')}
            >
                <CloseIcon width="100%" height="100%" />
            </button>
        </div>
    );
};

export const UserInput = ({
    visible,
    onCancel,
    onSuccess,
}: {
    visible?: boolean;
    onCancel: () => void;
    onSuccess: (value: string) => void;
}) => {
    const [value, setValue] = useState('');

    const handleSubmit = () => {
        setTimeout(() => onSuccess(value), 500);
    };

    if (!visible) {
        return null;
    }

    return (
        <div
            className={cn(
                'ml-[.8rem] rounded-[.8rem] bg-shade-h4 p-[1.6rem] w-full relative',
                'flex flex-col items-stretch gap-[1.6rem]'
            )}
        >
            <header className={cn('flex justify-between gap-[1.6rem]')}>
                <div className={cn('text-[1.6rem] text-ui-75')}>{`Why did you choose this rating? (optional)`}</div>
                <button
                    onClick={onCancel}
                    className={cn('flex-shrink-0 w-[2rem] h-[2rem] flex items-center justify-center text-ui-50')}
                >
                    <CloseIcon width="100%" height="100%" />
                </button>
            </header>
            <TextField
                value={value}
                onChange={(e) => setValue(e.target.value)}
                placeholder="Please provide additional feedback"
            ></TextField>
            <div className={cn('flex items-center justify-between gap-[1.6rem] ')}>
                <div
                    className={cn('font-micro-text text-ui-75')}
                >{`Your feedback is used to improve Dandi AI services`}</div>
                <Button onClick={handleSubmit} disabled={!value}>
                    Submit
                </Button>
            </div>
        </div>
    );
};

export const ThumbButton = ({ up, activated, onClick }: { up: boolean; activated?: boolean; onClick: () => void }) => {
    const IconComp = up ? ThumbsUpIcon : ThumbsDownIcon;

    return (
        <button
            onClick={onClick}
            className={cn(
                'flex items-center justify-center rounded-full w-[3.6rem] h-[3.6rem]',
                'bg-[transparent] text-ui-50',
                'hover:bg-shade-5',
                'active:bg-shade-6 active:text-ui-100',
                activated && '!bg-shade-6 !text-ui-100',
                'transition-all duration-175 ease-in-out'
            )}
        >
            <IconComp />
        </button>
    );
};

import ActionTypes from '../constants/ActionTypes';
import axios from 'axios';
import Urls from '../constants/Urls';
import { TabName } from './AddEvent/types';
import { IEvent } from './types';
import moment from 'moment';

export function getEvents() {
    return (dispatch: any, getState: any) => {
        const { enterpriseId } = getState().account;

        dispatch({
            type: ActionTypes.GetEventsPending
        });

        return axios
            .post(`${Urls.EventsApi}event/all`, {
                enterpriseId
            })
            .then(response => {
                dispatch({
                    type: ActionTypes.GetEventsFulfilled,
                    payload: response.data
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionTypes.GetEventsRejected,
                    payload: error
                });
            });
    };
}

export function setEditEvent(id?: string) {
    return (dispatch: any, getState: any) => {
        const allEvents = getState().timeline.events;
        dispatch({ type: ActionTypes.SetEditEventPending });

        if (id) {
            const event = allEvents.find((event: any) => event.eventId === id);

            dispatch({
                type: ActionTypes.SetEditEventFulfilled,
                payload: event
            });
        } else {
            dispatch({
                type: ActionTypes.SetEditEventFulfilled,
                payload: undefined
            });
        }
    };
}

export function setDeleteEvent(id?: string) {
    return (dispatch: any, getState: any) => {
        const allEvents = getState().timeline.events;

        if (id) {
            const event = allEvents.find((event: any) => event.eventId === id);

            dispatch({
                type: ActionTypes.SetDeleteEvent,
                payload: event
            });
        } else {
            dispatch({
                type: ActionTypes.SetDeleteEvent,
                payload: undefined
            });
        }
    };
}

export function editEvent(event: IEvent) {
    return (dispatch: any, getState: any) => {
        const allEvents = getState().timeline.events;
        dispatch({ type: ActionTypes.EditEventPending });

        const { enterpriseId } = getState().account;
        const { actorId } = getState().auth.userInfo;
        const { dateStart, dateEnd } = formatDates(event);

        const newEvents = allEvents.filter(
            (e: any) => e.eventId !== event.eventId
        );

        const newEvent = {
            name: event.name,
            description: event.description,
            dateStart,
            dateEnd,
            color: event.color,
            createdAt: event.createdAt ?? moment().utc().toISOString(),
            creatorId: actorId,
            enterpriseId,
            eventId: event.eventId
        };

        newEvents.push(newEvent);

        return axios
            .put(`${Urls.EventsApi}event/save`, newEvent)
            .then(response => {
                setEditEvent();
                dispatch({
                    type: ActionTypes.EditEventFulfilled,
                    payload: newEvents
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionTypes.EditEventRejected,
                    payload: error
                });
            });
    };
}

export function deleteEvent(id: string) {
    return (dispatch: any, getState: any) => {
        dispatch({ type: ActionTypes.DeleteEventPending });

        const { enterpriseId } = getState().account;
        const allEvents = getState().timeline.events;

        const newEvents = allEvents.filter(
            (event: any) => event.eventId !== id
        );

        return axios
            .put(`${Urls.EventsApi}event/delete`, {
                eventId: id,
                enterpriseId
            })
            .then(response => {
                setDeleteEvent();
                dispatch({
                    type: ActionTypes.DeleteEventFulfilled,
                    payload: newEvents
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionTypes.DeleteEventRejected,
                    payload: error
                });
            });
    };
}

function formatDates(event: IEvent) {
    const dateStart = moment(event.dateStart).format('MM/DD/YYYY');
    const dateEnd =
        event.dateEnd && event.dateEnd !== ''
            ? moment(event.dateEnd).format('MM/DD/YYYY')
            : moment(event.dateStart).format('MM/DD/YYYY');

    return {
        dateStart,
        dateEnd
    };
}

function datesToIsoString(event: IEvent) {
    const dateStart = moment(event.dateStart, 'MM/DD/YYYY').toISOString();
    const dateEnd =
        event.dateEnd && event.dateEnd !== ''
            ? moment(event.dateEnd, 'MM/DD/YYYY').toISOString()
            : moment(event.dateStart, 'MM/DD/YYYY').toISOString();

    return {
        dateStart,
        dateEnd
    };
}

export function addEvent(event: IEvent) {
    return (dispatch: any, getState: any) => {
        dispatch({
            type: ActionTypes.AddEventPending
        });

        const { enterpriseId } = getState().account;
        const { actorId } = getState().auth.userInfo;
        const { dateStart, dateEnd } = formatDates(event);

        const newEvent = {
            name: event.name,
            description: event.description,
            dateStart,
            dateEnd,
            color: event.color,
            createdAt: moment().utc().toISOString(),
            creatorId: actorId,
            enterpriseId
        };

        return axios
            .put(`${Urls.EventsApi}event/save`, newEvent)
            .then(response => {
                const { dateStart: isoDateStart, dateEnd: isoDateEnd } =
                    datesToIsoString({ ...newEvent, eventId: response.data });
                dispatch({
                    type: ActionTypes.AddEventFulfilled,
                    payload: {
                        ...newEvent,
                        // dateStart: isoDateStart,
                        // dateEnd: isoDateEnd,
                        eventId: response.data
                    }
                });
            })
            .catch(error => {
                dispatch({
                    type: ActionTypes.AddEventRejected,
                    payload: error
                });
            });
    };
}

export function showAddEventModal(event?: IEvent) {
    return {
        type: ActionTypes.ShowAddEventModal
    };
}

export function closeAddEventModal() {
    return {
        type: ActionTypes.CloseAddEventModal
    };
}

export function showAddEventTab(tabName: TabName) {
    return {
        type: ActionTypes.ShowAddEventTab,
        payload: tabName
    };
}

export function setEventToScrollTo(eventId: string) {
    return {
        type: ActionTypes.SetEventToScrollTo,
        payload: eventId
    };
}

export function removeEventToScrollTo() {
    return {
        type: ActionTypes.RemoveEventToScrollTo
    };
}

export function setInfoModalOpen(isOpen: boolean) {
    return {
        type: ActionTypes.SetTimelineInfoModalOpen,
        payload: isOpen
    };
}

import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={21}
            height={21}
            viewBox="0 0 21 21"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <path
                d="M18.1727 13.7417C17.6426 14.9954 16.8134 16.1002 15.7576 16.9594C14.7019 17.8187 13.4517 18.4062 12.1164 18.6707C10.7812 18.9351 9.40142 18.8685 8.09785 18.4765C6.79429 18.0845 5.60659 17.3792 4.63858 16.4222C3.67058 15.4652 2.95174 14.2856 2.54493 12.9866C2.13811 11.6876 2.0557 10.3087 2.3049 8.97053C2.55409 7.63232 3.12731 6.37553 3.97444 5.31003C4.82156 4.24453 5.9168 3.40277 7.1644 2.85834"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
            <path
                d="M18.8333 10.5C18.8333 9.40564 18.6178 8.32201 18.199 7.31096C17.7802 6.29991 17.1664 5.38125 16.3926 4.60743C15.6187 3.83361 14.7001 3.21978 13.689 2.80099C12.678 2.3822 11.5943 2.16666 10.5 2.16666V10.5H18.8333Z"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                fill="none"
            />
        </svg>
    );
}

export default SvgComponent;

import React from 'react';

const Timeline = (props: any) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        width="24"
        viewBox="0 0 48 48"
        fill="currentColor"
        {...props}
    >
        <path d="M12.5 34.05h11v-3h-11Zm6-8.55h11v-3h-11Zm6-8.55h11v-3h-11ZM9 42q-1.2 0-2.1-.9Q6 40.2 6 39V9q0-1.2.9-2.1Q7.8 6 9 6h30q1.2 0 2.1.9.9.9.9 2.1v30q0 1.2-.9 2.1-.9.9-2.1.9Zm0-3h30V9H9v30ZM9 9v30V9Z" />
    </svg>
);

export default Timeline;

import ActionTypes from '../constants/ActionTypes';
import produce from 'immer';
import { BenchmarksState } from './model';

export default function benchmarksReducer(
    state: BenchmarksState = {},
    action: any
): BenchmarksState {
    switch (action.type) {
        case ActionTypes.FetchBenchmarksPending:
            return {
                ...state,
                error: false,
                pending: true
            };
        case ActionTypes.FetchBenchmarksFulfilled:
            return {
                ...state,
                pending: false,
                loaded: true,
                data: action.data
            };
        case ActionTypes.FetchBenchmarksRejected:
            return {
                ...state,
                error: true,
                pending: false
            };
        case ActionTypes.SaveBenchmarkFulfilled: {
            const { benchmark } = action;
            return produce(state, draftState => {
                if (!draftState.data) {
                    draftState.data = [];
                }
                const index = draftState.data.findIndex(
                    d => d.benchmarkId === benchmark.benchmarkId
                );
                if (index !== -1) {
                    draftState.data[index] = benchmark;
                } else {
                    draftState.data.push(benchmark);
                }
            });
        }
        case ActionTypes.DeleteBenchmarkFulfilled: {
            const { benchmarkId } = action;
            return {
                ...state,
                data: state.data?.filter(d => d.benchmarkId !== benchmarkId)
            };
        }
        case ActionTypes.SetEnterpriseId: {
            return {
                data: [],
                loaded: false
            };
        }
        default:
            return state;
    }
}

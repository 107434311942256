import moment from 'moment';
import React from 'react';
import { findEarliestAndLatestDate } from '../../utils';
import Dates from './Dates';
import EventMarker from './EventMarker';
import { scaleLinear } from 'd3-scale';
import { setEventToScrollTo } from '../../actions';
import orderBy from 'lodash/orderBy';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';

interface TimelineContentProps {
    goToPast: () => void;
    goToFuture: () => void;
    hoveredIndex: number | undefined;
    setHoveredIndex: (index: number | undefined) => void;
    showTodayToggle?: boolean;
}

export const MONTH_WIDTH = 100;
export const EVENT_HEIGHT = 40;
export const CARET_WIDTH = 20;

const calculateNumberOfMonths = (earliestDate: Date, latestDate: Date) => {
    const numberOfMonths = moment(latestDate)
        .startOf('month')
        .diff(moment(earliestDate).startOf('month'), 'months');
    return numberOfMonths;
};

const widthFromDates = (dates: string[]) => {
    const width = dates.reduce((acc, date) => {
        const daysInMonth = moment(date, 'MMM YY').daysInMonth();
        const monthWidth = (MONTH_WIDTH / 31) * daysInMonth;
        return acc + monthWidth;
    }, 0);

    return width;
};

const allDates = (startDate: Date, stopDate: Date) => {
    const dateArray: string[] = [];
    let currentDate = moment(startDate);
    while (currentDate <= moment(stopDate)) {
        dateArray.push(moment(currentDate).format('MMM YY'));
        currentDate = moment(currentDate).add(1, 'months');
    }
    return dateArray;
};

const TimelineContent = ({
    goToFuture,
    goToPast,
    hoveredIndex,
    setHoveredIndex,
    showTodayToggle
}: TimelineContentProps) => {
    const dispatch = useAppDispatch();
    const { events, sortCriteria } = useAppSelector(state => state.timeline);
    const { earliestDate: initialEarliestDate, latestDate: initialLatestDate } =
        findEarliestAndLatestDate(events);
    const [earliestDate, setEarliestDate] =
        React.useState<Date>(initialEarliestDate);
    const [latestDate, setLatestDate] = React.useState<Date>(initialLatestDate);

    const [timelineStart, setTimelineStart] = React.useState<Date>(
        moment(earliestDate).add(-12, 'months').toDate()
    );
    const [timelineEnd, setTimelineEnd] = React.useState<Date>(
        moment(latestDate).add(12, 'months').toDate()
    );
    const [numberOfMonths, setNumberOfMonths] = React.useState<number>(
        calculateNumberOfMonths(timelineStart, timelineEnd)
    );
    const [width, setWidth] = React.useState<number>(0);

    const [dateArray, setDateArray] = React.useState<string[]>([]);

    const calculatePosition = (date: Date) => {
        const scale = scaleLinear()
            .domain([timelineStart, timelineEnd])
            .range([0, width]);

        const position = scale(date);
        return position;
    };

    React.useEffect(() => {
        const { earliestDate, latestDate } = findEarliestAndLatestDate(events);
        setEarliestDate(earliestDate);
        setLatestDate(latestDate);
    }, [events]);

    React.useEffect(() => {
        setTimelineStart(
            moment(earliestDate).add(-12, 'months').startOf('month').toDate()
        );

        const latest =
            latestDate.getMilliseconds() > new Date().getMilliseconds()
                ? new Date()
                : latestDate;
        setTimelineEnd(
            moment(latest).add(12, 'months').endOf('month').toDate()
        );
    }, [earliestDate, latestDate]);

    React.useEffect(() => {
        setNumberOfMonths(calculateNumberOfMonths(timelineStart, timelineEnd));
        setDateArray(allDates(timelineStart, timelineEnd));
    }, [timelineStart, timelineEnd]);

    React.useEffect(() => {
        if (!dateArray.length) return;
        setWidth(widthFromDates(dateArray));
    }, [dateArray, numberOfMonths]);

    if (width === 0) return null;

    const sortedEvents = orderBy(
        events,
        [sortCriteria.column],
        [sortCriteria.ascending ? 'asc' : 'desc']
    );

    return (
        <div
            className="timeline-chart__content-container"
            style={{ width: width }}
        >
            <Dates
                dates={dateArray}
                goToFuture={goToFuture}
                goToPast={goToPast}
            />
            <div className="markers" style={{ maxWidth: width }}>
                {sortedEvents.map((event, index) => {
                    return (
                        <EventMarker
                            showToday={showTodayToggle}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(undefined)}
                            highlighted={
                                hoveredIndex === undefined
                                    ? true
                                    : hoveredIndex === index
                            }
                            key={event.eventId}
                            index={index}
                            id={event.eventId}
                            onClick={() => {
                                dispatch(setEventToScrollTo(event.eventId));
                            }}
                            startPosition={calculatePosition(
                                moment(event.dateStart, 'MM/DD/YYYY').toDate()
                            )}
                            endPosition={calculatePosition(
                                moment(event.dateEnd, 'MM/DD/YYYY').toDate()
                            )}
                            todayPosition={calculatePosition(moment().toDate())}
                            timelineWidth={width}
                            color={event.color}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default TimelineContent;

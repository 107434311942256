function setupExportViewport(target: string) {
    const viewport = document.querySelector('#export-viewport');
    const originalNode = document.querySelector(target);

    const clonedNode = document.querySelector(target)?.cloneNode(true);
    if (clonedNode) {
        viewport?.appendChild(clonedNode);
    }

    const node: any = viewport?.querySelector(target);

    node.style.height = originalNode?.clientHeight + 'px';

    return {
        node,
        viewport
    };
}

export default setupExportViewport;

import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'antd/lib/modal';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';

export default function DeleteReportModal(props) {
    const { isOpen, actionPending, onAction, onCancel } = props;
    return (
        <Modal
            visible={isOpen}
            width={400}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={onCancel}
            zIndex={3000}
        >
            <div className="modal modal--delete">
                <div className="modal__header">
                    <div className="modal__title">Delete Report</div>
                </div>
                <div className="modal__content">
                    Click Delete to remove this report from this dashboard.
                </div>
                <div className="modal__footer">
                    <Button
                        componentType={ButtonTypes.type.TERTIARY}
                        onClick={onCancel}
                    >
                        Cancel
                    </Button>
                    <Button loading={actionPending} onClick={onAction} danger>
                        Delete
                    </Button>
                </div>
            </div>
        </Modal>
    );
}

DeleteReportModal.propTypes = {
    isOpen: PropTypes.bool,
    onCancel: PropTypes.func,
    onAction: PropTypes.func,
    actionPending: PropTypes.bool
};

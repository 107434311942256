import orderBy from 'lodash/orderBy';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
import Urls from '../../../constants/Urls';
import { AsyncThunkConfig } from '../../../store/store';
import { Survey, SurveysState } from './types';

export const fetchSurveys = createAsyncThunk<
    SurveysState,
    void,
    AsyncThunkConfig
>('survey/fetchSurveys', async () => {
    let pageToken;
    let allData: Survey[] = [];
    do {
        const response: any = await axios.post(
            `${Urls.SurveyStudioApi}surveys`,
            {
                pageToken
            }
        );
        if (response.data.surveys?.length) {
            allData = [...allData, ...response.data.surveys];
        }
        pageToken = response.data.pageToken;
    } while (pageToken);

    const data: Survey[] = [];
    const archived: Survey[] = [];
    allData.forEach(d => {
        const uiElem = d.meta.uiElements.find(u => u.key === 'archive');
        if (uiElem?.value === 'true') {
            archived.push(d);
        } else {
            data.push(d);
        }
    });
    return { archived, data };
});

export const surveysSlice = createSlice({
    name: 'surveys',
    initialState: { data: [], archived: [] } as SurveysState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchSurveys.fulfilled, (state, action) => {
            const { archived, data } = action.payload;
            state.data = data;
            state.archived = archived;
            state.pending = false;
            state.loaded = true;
        });
        builder.addCase(fetchSurveys.pending, (state, action) => {
            state.pending = true;
            state.error = false;
        });
        builder.addCase(fetchSurveys.rejected, (state, action) => {
            state.error = true;
            state.pending = false;
        });
    }
});

export default surveysSlice.reducer;

import { ChartProps } from '../types';
import { getSVG } from './utils';

const Line = ({ type, count = 5, maxHeight }: ChartProps) => {
    const LineChart = getSVG(type, count);

    return (
        <svg
            width="100%"
            height={maxHeight ? maxHeight : '100%'}
            viewBox="0 0 304 86"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <svg
                width="100%"
                height="100%"
                viewBox="0 0 289 44"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                overflow="visible"
            >
                {LineChart}
            </svg>
        </svg>
    );
};

export default Line;

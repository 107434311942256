import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={28}
            height={28}
            viewBox="0 0 28 28"
            fill="currentColor"
            {...props}
        >
            <path d="M16 7V6h-4v1H7v2h14V7zM12 11h1v7h-1zM15 11h1v7h-1zM17 22c-.1 0 .1 0 0 0z" />
            <path d="M18 10v9c0 .6-.4 1-1 1h-6c-.6 0-1-.4-1-1v-9H8v10c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V10h-2z" />
            <g>
                <path fill="none" d="M0 0h28v28H0z" />
            </g>
        </svg>
    );
}

export default SvgComponent;

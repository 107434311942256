const html = {
    comp1: `<p>Compensation reports use Dandi's proprietary machine learning model to cluster your employee compensation data based on a number of attributes, including job level, job title, department, location and tenure. It then simultaneously processes multiple algorithms before choosing the one that delivers the highest statistical significance in normalizing, calculating, and predicting both compensation and the gaps therein based on diversity attribute(s).</p>

    <p>The dashed line is the Equity Index, which is the expected mean value calculated by Dandi's machine learning algorithms. The Confidence Score is the statistical measure of how well Dandi's proprietary machine learning model approximates the true adjusted pay gap.</p>
    
    <p>The adjusted pay gap is displayed as a percentage value as of the most recent data pull, either above or below the Equity Index. The report also surfaces the pay gap for the previous three months as an area graph in the background, based on historical data availability.</p>
    
    <p>By default, compensation is measured on an annual basis and includes the total salary as provided by your HR system.</p>
    
    <p><a class="link link--medium" href="https://support.itsdandi.com/hc/en-us/articles/360007723514-How-to-read-Compensation-reports" target="_blank">Learn more</a></p>`,
    rep1: `<p>Representation reports use a donut chart to represent the percentage of your active workforce that identify as the specified diversity segment out of your total active employees. The denominator includes all active employees company-wide unless constrained by an attribute (e.g. department, location, job title, job level, or employee type). The information is represented as of the most recent data pull.</p>

    <p>The report also displays this representation data for the previous three time periods as an area graph in the background, based on data availability. </p>`,
    hir1: `<p>Hiring reports use a donut chart to represent the percentage of your hires that identify as the specified diversity segment out of your total hires, for the time period of the most recent data pull. The denominator includes all hires company-wide unless constrained by an attribute (e.g. department, location, job title, job level, or employee type). </p>

    <p>The report also displays this hiring data for the previous three time periods as an area graph in the background, based on data availability.</p>`,
    attr1: `<p>Attrition reports use Dandi's proprietary analytics engine to calculate the Attrition Index Value (AIV) for the specified diversity segment. The AIV is an indicator of the frequency with which workforce members within a given diversity segment left the company relative to the frequency for all workforce members within a given time period. The latter includes all employees company-wide unless constrained by an attribute (e.g. department, location, job title, job level, or employee type).</p>

    <p>The dashed line represents the overall AIV. The AIV for the specified diversity segment is represented by a factor, either above or below, compared to the overall AIV, for the month of the most recent data pull.</p>
    
    <p>The report also displays this attrition data for the previous three time periods as an area graph in the background, based on historical data availability.</p>
    
    <p><a class="link link--medium" href="https://support.itsdandi.com/hc/en-us/articles/360007818733-How-to-read-Attrition-reports" target="_blank">Learn more</a></p>`,
    ret1: `<p>Retention reports display the percentage of employees of the specified diversity segment that have maintained employment with the company out of your total employee base, for the time period of the most recent data pull. The denominator includes all active employees company-wide unless constrained by an attribute (e.g. department, location, job title, job level, or employee type).</p>

    <p>The report also displays this retention data for the previous three time periods as a line graph, based on historical data availability.</p>`,
    comp2: `<p>Compensation reports use Dandi's proprietary machine learning model to cluster your employee compensation data based on a number of attributes, including job level, job title, department, location and tenure. It then simultaneously processes multiple algorithms before choosing the one that delivers the highest statistical significance in normalizing, calculating, and predicting both compensation and the gaps therein based on diversity attribute(s).</p>

    <p>The dashed line is the Equity Index, which is the expected mean value calculated by Dandi's machine learning algorithms. The Confidence Score is the statistical measure of how well Dandi's proprietary machine learning model approximates the true adjusted pay gap.</p>
    
    <p>The adjusted pay gap for each diversity segment is displayed as a line chart for the four most recent time periods, based on historical data availability.</p>
    
    <p>By default, compensation is measured on an annual basis and includes the total salary as provided by your HR system.</p>
    <p><a class="link link--medium" href="https://support.itsdandi.com/hc/en-us/articles/360007723514-How-to-read-Compensation-reports" target="_blank">Learn more</a></p>`,
    rep2: `<p>Representation reports use a line chart to represent the percentage of your active workforce that identify as the specified diversity segments out of your total active employees. The denominator includes all active employees company-wide unless constrained by an attribute (e.g. department, location, job title, job level, or employee type).</p>

    <p>The report displays this representation data for the four most recent time periods, based on data availability.</p>`,
    hir2: `<p>Hiring reports use a line chart to represent the percentage of your hires that identify as the specified diversity segments out of your total hires, for the month of the most recent data pull. The denominator includes all hires company-wide unless constrained by an attribute (e.g. department, location, job title, job level, or employee type). </p>

    <p>The report displays this hiring data for the four most recent time periods, based on data availability.</p>`,
    attr2: `<p>Attrition reports use Dandi's proprietary analytics engine to calculate the Attrition Index Value (AIV) for the specified diversity segments. The AIV is an indicator of the frequency with which workforce members within a given diversity segment left the company relative to the frequency for all workforce members within a given time period. The latter includes all employees company-wide unless constrained by an attribute (e.g. department, location, job title, job level, or employee type).</p>

    <p>The dashed line represents the overall AIV. The AIV for the specified diversity segments are represented by a factor, either above or below, compared to the overall AIV, for the month of the most recent data pull.</p>
    
    <p>The report displays this attrition data for the four most recent time periods, based on historical data availability.</p>
    <p><a class="link link--medium" href="https://support.itsdandi.com/hc/en-us/articles/360007818733-How-to-read-Attrition-reports" target="_blank">Learn more</a></p>`,
    ret2: `<p>Retention reports display the percentage of employees of the specified diversity segments that have maintained employment with the company out of your total employee base, for the month of the most recent data pull. The denominator includes all active employees company-wide unless constrained by an attribute (e.g. department, location, job title, job level, or employee type).</p>

    <p>The report displays this retention data for the four most recent time periods, based on historical data availability.</p>`
};

export default html;

import chartColors from '../constants/ChartColors';
import { getChartColor } from './getChartColor';

function getLabel(result: any, intersectionAttributes: any) {
    return intersectionAttributes
        .map((ia: any) => result[ia.attributeValueProp])
        .join('_');
}

export default function getSegment(
    result: any,
    attributes: any,
    intersectionAttributes: any,
    skipAttribute?: boolean,
    index?: number
) {
    const {
        entity1,
        entityType1,
        entity2,
        entityType2,
        main1Type,
        main1,
        main2Type,
        main2,
        main3Type,
        main3
    } = result;
    const meta = [
        { type: main1Type, value: main1 },
        { type: main2Type, value: main2 },
        { type: main3Type, value: main3 },
        { type: entityType1, value: entity1 },
        { type: entityType2, value: entity2 }
    ];
    const tokens = [];
    const label = getLabel(result, intersectionAttributes);
    const text = intersectionAttributes
        .map((ia: any) => {
            const attribute = attributes.find(
                (a: any) =>
                    a.attributeName.replace(/ /g, '') ===
                    result[ia.attributeTypeProp]
            );
            let text = result[ia.attributeValueProp];
            if (attribute) {
                const attributeValue = attribute.attributeValues.find(
                    (av: any) => av.value === text
                );
                if (attributeValue) {
                    text = attributeValue.text;
                }
            }
            return text;
        })
        .join(' ');
    tokens.push({
        text,
        color:
            index !== undefined
                ? chartColors[index % chartColors.length]
                : getChartColor(label)
    });

    const filteredMeta = meta.filter(m => {
        return (
            m.type !== skipAttribute &&
            intersectionAttributes.every(
                (ia: any) => result[ia.attributeTypeProp] !== m.type
            )
        );
    });

    filteredMeta.forEach(metaItem => {
        if (metaItem.type && metaItem.type !== skipAttribute) {
            let text = metaItem.value;
            const attribute = attributes.find(
                (a: any) => a.attributeName.replace(/ /g, '') === metaItem.type
            );
            if (attribute) {
                const attributeValue = attribute.attributeValues.find(
                    (av: any) => (av.value || av.text) === metaItem.value
                );
                if (attributeValue) {
                    text = attributeValue.text;
                }
            }
            tokens.push(text);
        }
    });

    function generateHtmlSnippet(tokens: any) {
        const mainSegment = tokens.filter((t: any) => typeof t === 'object')[0];
        const additionalSegments = tokens.filter(
            (t: any) => typeof t === 'string'
        );

        let html = `<span class="segment-label"><svg style="margin-right:8px;" width="5px" height="5px" viewBox="0 0 10 10"><circle fill="${
            mainSegment.color
        }" r="5" cx="5" cy="5" /></svg><span class="segment-label__text"><span class="segment-label__main">${
            mainSegment.text
        } ${additionalSegments.length >= 1 ? ' - ' : ''} </span>`;

        html += additionalSegments.reduce(
            (acc: any, token: any, index: number) => {
                return (acc += `${token}${
                    index !== additionalSegments.length - 1 ? ', ' : ''
                }`);
            },
            '<span class="segment-label__addl">'
        );

        html += additionalSegments.length >= 1 ? '</span>' : '';

        html += '</span></span>';

        return html;
    }

    function generateTextSnippet(tokens: any) {
        return tokens
            .reduce((acc: any, token: any) => {
                return typeof token === 'string'
                    ? (acc += token + ' ')
                    : (acc += token.text + ' ');
            }, '')
            .trim();
    }

    return {
        tokens,
        text: generateTextSnippet(tokens),
        html: generateHtmlSnippet(tokens)
    };
}

const ButtonTypes = {
    type: {
        PRIMARY: 'primary',
        SECONDARY: 'secondary',
        TERTIARY: 'tertiary',
        LIST_ITEM: 'list-item',
        DANGER: 'danger',
        AI: 'ai'
    },
    selectedStyle: {
        DEFAULT: 'default',
        UNDERLINE: 'underline'
    }
};

export default ButtonTypes;

import React, { ReactNode, useState } from 'react';
import Checkbox from '../common/Checkbox';

interface CheckboxStateWrapperProps {
    children: ReactNode;
    initialState: boolean;
    type: string;
    style: any;
}

const CheckboxStateWrapper = ({
    children,
    initialState,
    type,
    style
}: CheckboxStateWrapperProps) => {
    const [checked, setChecked] = useState(initialState);

    const onChange = (checked: boolean) => {
        setChecked(checked);
    };

    return (
        <Checkbox
            checked={checked}
            onClick={onChange}
            type={type}
            style={style}
        >
            {children}
        </Checkbox>
    );
};

export default CheckboxStateWrapper;

import Menu from 'antd/lib/menu';
import classNames from 'classnames';
import produce from 'immer';
import orderBy from 'lodash/orderBy';
import React, {
    Fragment,
    useEffect,
    useReducer,
    useRef,
    useState
} from 'react';
import { CSSTransition } from 'react-transition-group';

import ChartContainer from '../../common/ChartContainer';
import Events from '../../common/Events';
import Legend from '../../common/Legend';
import ScrollableChartArea from '../../common/ScrollableChartArea';
import XAxis from '../../common/XAxis';
import { setScrollPosition } from '../../common/actions';
import useChartDeclaration from '../../common/useChartDeclaration';
import useInteractiveChart from '../../common/useInteractiveChart';
import AnalysisTypes from '../../constants/AnalysisTypes';
import CanvasFonts from '../../constants/CanvasFonts';
import ChartTypes from '../../constants/ChartTypes';
import GridConstants from '../../constants/GridConstants';
import MixPanel from '../../constants/MixPanel';
import RbacActions from '../../constants/RbacActions';
import {
    clearActiveLabel,
    clearSelection,
    setActiveLabel,
    toggleLabel,
    toggleLockedSelection
} from '../../dashboard/view/actions';
import exportMatrixCsv from '../../explore/detail/exportCsv';
import exportMatrixAsImage from '../../explore/detail/exportDetailData';
import usePermissions from '../../hooks/usePermissions';
import getPeriod from '../../lib/getPeriod';
import hasHiddenEmployeeCount from '../../lib/hasHiddenEmployeeCount';
import measureText from '../../lib/measureText';
import { track } from '../../lib/segment';
import { useAppDispatch, useAppSelector } from '../../store/hooks';
import { textChartMachine } from './TextChartMachine';
import exportChart from './exportChart';
import exportCsv from './exportCsv';
import exportTable from './exportTable';
import GraphWrapper from '../../common/GraphWrapper';
import ChartPicker from './ChartPicker';
import DraggingTrigger from '../../dashboard/DraggingTrigger';
import ChartActions from './ChartActions';
import ChartTitle from './ChartTitle';
import ChartEmptyState from './ChartEmptyState';
import moment from 'moment';

const rowHeights = [44, 40, 36, 28];
const LEGEND_WHITESPACE = 52;
const LABEL_WHITESPACE = 16;
const COLUMN_GAP = 16;
const BENCHMARK_BOTTOM_PADDING = 40;
const BENCHMARK_TOP_PADDING = 16;

interface ChartProps {
    dashboardName: string;
    title: string;
    chartType: string;
    lifecycle: string;
    generatePending: boolean;
    generated: boolean;
    generateError: boolean;
    generateNoData: boolean;
    generateNotAllowed: boolean;
    generateInvalidVariation: boolean;
    interval: string;
    dates: any[];
    date: string;
    enterpriseName: string;
    analysisType: string;
    filters: any[];
    length: number;
    colWidth: number;
    resizeWidth: number;
    selectCurrentTime: any;
    rowHeight: number;
    showActions: boolean;
    dashboardId: string;
    reportId: string;
    editMode: boolean;
    currentIndex: number;
    source: string;
    disableInteractions: boolean;
    options: any;
    useMaxHeightOnTable: boolean;
    disableLinks: boolean;
    navigate: any;
    role: string;
    navigateToExplore: any;
    showDeleteReportModal: any;
    setRowHeightInEditor: any;
    setRowHeightInReport: any;
    tableRowHeight: number;
    setSortCriteriaInEditor: any;
    setSortCriteriaInReport: any;
    sortCriteria: any;
    setExpandedInEditor: any;
    setExpandedInReport: any;
    expanded: boolean;
    summary: any;
    employeeAttribute: string;
    attributeName: string;
    diversityAttributes: any[];
    employeeAttributes: any[];
    navigateFromMatrix: any;
    isEmptyState: boolean;
    confidence: number;
    showGroupSummary: boolean;
    groupSummary: any;
    unfilteredDiversityAttributes: any[];
    rowFilter: any[];
    columnFilter: any[];
    setFilterInEditor: any;
    setTitle: any;
    benchmark: any;
    onAttributeRemove: any;
    columnProps: any[];
    subAnalysisFields: any;
    subAnalysisMapping: any;
    hasInvalidValue: boolean;
    eventIds: string[];
    allEvents: any[];
    data: any[];
    lightMode?: boolean;
    isResizing?: boolean;
    isLocked?: boolean;
    dateAdded?: string;
}

const defaultProps = {
    data: []
};

function getMainColumn(state: any) {
    const analysisName = state.editor.chart?.lifecycle;
    if (!analysisName) return;

    const template = state.templates.dynamicTemplates.find(
        (t: any) => t.analysisName === analysisName
    );

    return template?.mainColumn;
}

export default function Chart(props: ChartProps & typeof defaultProps) {
    const {
        dashboardName,
        title,
        chartType,
        lifecycle,
        generatePending,
        generated,
        generateError,
        generateNoData,
        generateNotAllowed,
        generateInvalidVariation,
        interval,
        dates,
        date,
        enterpriseName,
        analysisType,
        length,
        colWidth,
        resizeWidth,
        selectCurrentTime,
        rowHeight,
        dashboardId,
        reportId,
        dateAdded,
        editMode,
        currentIndex,
        source,
        disableInteractions,
        options,
        useMaxHeightOnTable,
        disableLinks,
        navigate,
        role,
        navigateToExplore,
        showDeleteReportModal,
        setRowHeightInEditor,
        setRowHeightInReport,
        tableRowHeight,
        setSortCriteriaInEditor,
        setSortCriteriaInReport,
        sortCriteria,
        setExpandedInEditor,
        setExpandedInReport,
        expanded,
        summary,
        employeeAttribute,
        attributeName,
        employeeAttributes,
        diversityAttributes,
        navigateFromMatrix,
        isEmptyState,
        confidence,
        showGroupSummary,
        groupSummary,
        benchmark,
        setTitle,
        columnProps,
        subAnalysisFields,
        subAnalysisMapping,
        hasInvalidValue,
        allEvents,
        eventIds,
        lightMode,
        isResizing,
        isLocked
    } = props;

    const dispatch = useAppDispatch();
    const chart = useAppSelector(state => {
        if (source === 'editor') {
            return state.editor.chart;
        } else {
            return state.reports[reportId];
        }
    });

    const yAxisType = chart?.yAxisType;
    const indexType = chart?.indexType;

    const width = colWidth * length + GridConstants.ColumnGap * (length - 1);
    const [height, setHeight] = useState(0);
    const htmlRef = useRef<HTMLDivElement>(null);
    const scrollableAreaRef = useRef<HTMLDivElement>(null);
    const chartContainerRef = useRef<HTMLDivElement>(null);
    const chartRef = useRef<HTMLDivElement>(null);
    const titleRef = useRef<HTMLDivElement>(null);
    const resizing = typeof resizeWidth !== 'undefined';

    const disableExploreAccess = !usePermissions({
        actions: [RbacActions['Dashboards/Explore']]
    });
    const [adjustedChartHeight, setAdjustedChartHeight] = useState(0);
    const [innerChartOffsets, setInnerChartOffsets] = useState({
        left: 0,
        right: 0
    });
    const [data, setData] = useState<any[]>(props.data);
    const [moreOpen, setMoreOpen] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [exportOpen, setExportOpen] = useState(false);
    const [tableData, setTableData] = useState(data);
    const [showTooltip, setTooltip] = useState(false);
    const [multiLine, setMultiLine] = useState(false);
    const [lineWidth, setLineWidth] = useState<number | undefined>();
    const [eventsHidden, setEventsHidden] = useState(false);
    const [benchmarkRowHeight, setBenchmarkRowHeight] = useState(rowHeights[0]);
    const [benchmarkContainerHeight, setBenchmarkContainerHeight] =
        useState(rowHeight);
    const [textChartState, textChartDispatch] = useReducer(textChartMachine, {
        status: 'idle'
    });
    const mainColumn = useAppSelector(getMainColumn);
    const { dynamicPermissions } = useAppSelector(state => state.auth);
    const employeeCountHidden = hasHiddenEmployeeCount(dynamicPermissions);
    const [chartContainerBoundingBox, setChartContainerBoundingBox] = useState({
        top: 0,
        left: 0,
        width: 0,
        height: 0
    });
    const isTextChart = chartType === ChartTypes.Text;
    const isInsightsChart = chartType === ChartTypes.InsightsText;
    const isTableChart = chartType === ChartTypes.Table;
    const isFunnelChart = chartType === ChartTypes.Funnel;
    const isMatrixChart = chartType === ChartTypes.Matrix;
    const isHorizontalBarChart = chartType === ChartTypes.HorizontalBar;
    const {
        onHover,
        onHoverEnd,
        onClearSelection,
        onToggle,
        hoveredItem,
        selection,
        status
    } = useInteractiveChart({
        mode:
            source === 'editor'
                ? 'editor'
                : editMode
                ? 'dashboard-edit'
                : 'dashboard-view',
        status: 'idle',
        hoveredItem: undefined,
        selection: [],
        setActiveLabel,
        toggleLabel,
        lockedSelection: isFunnelChart,
        toggleLockedSelection,
        clearActiveLabel,
        clearSelection
    });

    const selectCurrentTime_ = disableInteractions
        ? undefined
        : source === 'dashboard'
        ? selectCurrentTime.bind(null, reportId)
        : selectCurrentTime;

    const chartDeclaration = useChartDeclaration({
        mode:
            source === 'editor'
                ? 'editor'
                : editMode
                ? 'dashboard-edit'
                : 'dashboard-view',
        chartType: ChartTypes[chartType as keyof typeof ChartTypes],
        confidence,
        benchmark,
        lifecycle,
        data,
        dates,
        currentIndex,
        exportAsCSV: exportCsv,
        exportChart,
        columnCount: GridConstants.ColumnCount,
        onHover,
        onHoverEnd,
        onToggle,
        setActiveLabel: handleLabelActive,
        clearActiveLabel: handleLabelClear,
        chartHeaderRef: htmlRef,
        lineWidth: lineWidth || 0,
        multiLine,
        chartWidth: 0,
        chartHeight: 0,
        interval,
        selectCurrentTime: selectCurrentTime_,
        hasInvalidValue,
        handleToggleLabel: handleLabelToggle,
        columnProps,
        analysisType: AnalysisTypes[analysisType as keyof typeof AnalysisTypes],
        benchmarkRowHeight,
        columnGap: COLUMN_GAP,
        status,
        hoveredItem,
        selection,
        events: allEvents.filter(e => eventIds.includes(e.eventId)),
        setInnerChartOffsets,
        innerChartOffsets,
        mainColumn
    });

    useEffect(() => {
        if (!chartContainerRef.current) return;

        function onResize() {
            if (!chartContainerRef.current) return;
            const { top, left, width, height } =
                chartContainerRef.current.getBoundingClientRect();
            setChartContainerBoundingBox({
                top: top + window.pageYOffset,
                left,
                width,
                height
            });
        }

        const observer = new ResizeObserver(onResize);
        observer.observe(chartContainerRef.current);

        return () => {
            observer.disconnect();
        };
    }, [chartContainerRef.current]);

    useEffect(() => {
        setData(prevState => {
            return produce(prevState, draftState => {
                draftState.forEach((d, i) => {
                    draftState[i].active = d.label === hoveredItem;
                    draftState[i].selected = selection.includes(d.label);
                });
            });
        });
    }, [hoveredItem, selection]);

    useEffect(() => {
        const onResize = () => {
            const scrollableArea = scrollableAreaRef.current;
            if (!scrollableArea) return;

            const { height: scrollableAreaHeight } =
                scrollableArea.getBoundingClientRect();

            const legend =
                scrollableArea.querySelector('.legend')?.getBoundingClientRect()
                    .height || 0;
            const chartEvents =
                scrollableArea
                    .querySelector('.chart-events')
                    ?.getBoundingClientRect().height || 0;
            const maxEventsVisible = 28;
            const events = Math.min(chartEvents, maxEventsVisible);
            setAdjustedChartHeight(scrollableAreaHeight - legend - events);
        };

        if (!scrollableAreaRef.current) return;
        const observer = new ResizeObserver(onResize);
        observer.observe(scrollableAreaRef.current);

        return () => {
            observer.disconnect();
        };
    }, [scrollableAreaRef.current, chartType, eventIds]);

    function setChartHeight() {
        const htmlNode = htmlRef.current;
        if (!htmlNode) return;
        if (lineWidth) {
            let height =
                rowHeight - htmlNode.offsetHeight - (isTableChart ? 40 : 0);
            let extend = false;
            if (benchmark && benchmark.data && benchmark.data.length > 0) {
                const tableHeight =
                    height - BENCHMARK_TOP_PADDING - BENCHMARK_BOTTOM_PADDING;
                let benchmarkRowHeight: number = 0;
                extend = true;
                for (let i = 0; i < rowHeights.length; i++) {
                    benchmarkRowHeight = rowHeights[i];
                    if (
                        benchmarkRowHeight * (benchmark.data.length + 1) <=
                        tableHeight
                    ) {
                        extend = false;
                        break;
                    }
                }
                setBenchmarkRowHeight(benchmarkRowHeight);
                if (extend && !disableInteractions) {
                    height =
                        benchmarkRowHeight * (benchmark.data.length + 1) +
                        BENCHMARK_TOP_PADDING +
                        BENCHMARK_BOTTOM_PADDING;
                    const benchmarkContainerHeight =
                        height + htmlNode.offsetHeight;
                    setBenchmarkContainerHeight(benchmarkContainerHeight);
                }
            }
            if (!extend || disableInteractions) {
                setBenchmarkContainerHeight(rowHeight);
            }
            setHeight(height);
        } else if (isTextChart) {
            // tmp
            setHeight(1);
        }
    }

    useEffect(() => {
        if (titleRef.current) {
            const actualWidth = titleRef.current.offsetWidth;
            const expectedWidth = measureText(
                title,
                CanvasFonts.Medium18
            ).width;
            setTooltip(expectedWidth > actualWidth);
        }
    }, [colWidth, length, title, generated]);

    useEffect(() => {
        setChartHeight();
    }, [
        length,
        rowHeight,
        colWidth,
        generatePending,
        lifecycle,
        resizing,
        chartType,
        data,
        lineWidth,
        benchmark
    ]);

    useEffect(() => {
        setData(props.data);
    }, [props.data, chartType]);

    useEffect(() => {
        if (isTableChart && props.data.length > 0 && !!height) {
            let tableRowHeight: number | undefined;
            for (let i = 0; i < rowHeights.length; i++) {
                tableRowHeight = rowHeights[i];
                if ((props.data.length + 2) * tableRowHeight < height) {
                    break;
                }
            }
            if (source === 'editor') {
                setRowHeightInEditor(tableRowHeight);
            } else {
                setRowHeightInReport(reportId, tableRowHeight);
            }
        }
    }, [height, chartType, props.data]);

    useEffect(() => {
        if (props.data.length === 0) return;
        let measuredWidth = 0;
        props.data.forEach(d => {
            if (!d.isEqualityIndex && !d.isAttritionIndex) {
                measuredWidth +=
                    measureText(d.label, CanvasFonts.Regular11).width +
                    LABEL_WHITESPACE;
            }
        });
        const legendWidth = (resizeWidth || width) - LEGEND_WHITESPACE;
        const maxLineWidth = length > 2 ? 0.7 * legendWidth : legendWidth;
        setMultiLine(measuredWidth > maxLineWidth);
        setLineWidth(maxLineWidth);
    }, [props.data, length, width, resizeWidth]);

    useEffect(() => {
        if (!interval) return;
        if (isTableChart && data.length > 0) {
            let tableData = produce(data, draftState => {
                draftState.forEach(dataItem => {
                    // fix for a crash when switching from matrix report to table report
                    if (!dataItem.series) return;
                    dataItem.series.forEach((seriesItem: any) => {
                        const label = getPeriod(
                            seriesItem.date,
                            interval?.toLowerCase()
                        );
                        const prefix = label?.toLowerCase().replace(/ /g, '-');
                        columnProps.forEach(columnProp => {
                            dataItem[prefix + '-' + columnProp.source] =
                                seriesItem[columnProp.source];
                        });
                    });
                });
            });
            let removedItems;
            if (
                analysisType === AnalysisTypes.Index ||
                analysisType === AnalysisTypes.LinearRegression
            ) {
                const index = tableData.findIndex(
                    d => d.isEqualityIndex || d.isAttritionIndex
                );
                if (index !== -1) {
                    tableData = tableData.slice();
                    removedItems = tableData.splice(index, 1);
                }
            }
            tableData = orderBy(
                tableData,
                [sortCriteria.columnName],
                [sortCriteria.ascending ? 'asc' : 'desc']
            );
            if (removedItems) {
                tableData.unshift(...removedItems);
            }
            setTableData(tableData);
        }
    }, [data, sortCriteria, chartType, columnProps, interval]);

    useEffect(() => {
        if (isMatrixChart && sortCriteria) {
            setData(prevData => {
                return orderBy(
                    prevData,
                    [sortCriteria.columnName],
                    [sortCriteria.ascending ? 'asc' : 'desc']
                );
            });
        }
    }, [props.data, sortCriteria, chartType]);

    function handleSort(columnName: string) {
        let ascending = true;
        if (columnName === sortCriteria.columnName) {
            ascending = !sortCriteria.ascending;
        }
        if (source === 'editor') {
            setSortCriteriaInEditor({
                columnName,
                ascending
            });
        } else {
            setSortCriteriaInReport(reportId, {
                columnName,
                ascending
            });
        }
    }

    function handleLabelActive(label: string) {
        if (disableInteractions) return;
        onHover(label);
    }

    function handleLabelClear() {
        if (disableInteractions) return;
        onHoverEnd();
    }

    function handleLabelToggle(label: string, e: any) {
        if (disableInteractions) return;
        e && e.stopPropagation();

        let eventName =
            MixPanel.Events.DashboardReportLineGraphLegendSegmentClick;
        if (chartType === ChartTypes.Bar) {
            eventName =
                MixPanel.Events.DashboardReportColumnGraphLegendSegmentClick;
        }
        if (chartType === ChartTypes.Pie) {
            eventName = MixPanel.Events.DashboardReportPieChartLegendClick;
        }
        if (chartType === ChartTypes.StackedBar) {
            eventName =
                MixPanel.Events.DashboardReportStackedBarChartLegentClick;
        }
        track(eventName, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });

        onToggle(label);
    }

    function handleDeselect() {
        if (disableInteractions) return;

        onClearSelection();
    }

    function handleToggleExpansion() {
        if (source === 'editor') {
            setExpandedInEditor(!expanded);
        } else {
            setExpandedInReport(reportId, !expanded);
        }
    }

    function handleCSVExport() {
        track(MixPanel.Events.DashboardReportCSVExport, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
        if (isMatrixChart || isHorizontalBarChart) {
            const period = getPeriod(date, interval);
            exportMatrixCsv(
                data,
                summary,
                groupSummary,
                lifecycle,
                analysisType,
                columnProps,
                attributeName,
                diversityAttributes,
                period
            );
        } else {
            exportCsv({
                dashboardName,
                reportName: title,
                lifecycle,
                analysisType,
                columnProps,
                data,
                dates,
                interval,
                benchmark,
                shouldReturnCsv: false,
                subAnalysisFields,
                subAnalysisMapping,
                chartType
            });
        }
        setExportOpen(false);
    }

    function handleImageExport() {
        track(MixPanel.Events.DashboardReportImageExport, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
        if (isMatrixChart) {
            const period = getPeriod(date, interval);
            exportMatrixAsImage(
                enterpriseName,
                period,
                data,
                summary,
                groupSummary,
                title,
                lifecycle,
                analysisType,
                columnProps,
                attributeName,
                diversityAttributes,
                sortCriteria,
                expanded
            );
        } else if (isTableChart) {
            exportTable(
                title,
                confidence,
                data,
                lifecycle,
                analysisType,
                columnProps,
                dates,
                interval,
                tableRowHeight,
                sortCriteria,
                expanded,
                options
            );
        } else {
            exportChart({
                reportId,
                title,
                analysisType,
                chartType,
                confidence,
                length,
                data,
                shouldReturnData: false,
                shouldReturnBlob: false
            });
        }
        setExportOpen(false);
    }

    function handleExplore() {
        track(MixPanel.Events.DashboardReportExplore, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });

        dispatch(setScrollPosition(window.scrollY));
        navigateToExplore(dashboardId, reportId);
    }

    function handleDelete() {
        track(MixPanel.Events.EditDashboardDeleteReportClick, {
            'Dashboard Name': dashboardName,
            'Report Name': title
        });
        showDeleteReportModal(reportId);
    }

    function handleExportMenuClick({ domEvent }: any) {
        domEvent.stopPropagation();
    }

    function handleNavigateToDetail(...args: any) {
        navigateFromMatrix(reportId, ...args);
    }

    function renderExportMenu() {
        return (
            <Menu
                className="widget-menu"
                selectedKeys={[]}
                onClick={handleExportMenuClick}
            >
                <Menu.Item onClick={handleCSVExport}>Export as CSV</Menu.Item>
                <Menu.Item onClick={handleImageExport}>
                    Export as Image
                </Menu.Item>
            </Menu>
        );
    }

    const isEmpty =
        !isTextChart &&
        !isInsightsChart &&
        (generatePending ||
            generateError ||
            generateNoData ||
            generateInvalidVariation ||
            !generated);

    return (
        <Fragment>
            {source !== 'editor' && <DraggingTrigger isLocked={isLocked} />}
            <div
                className={classNames('editor__chart', {
                    'editor__chart--empty':
                        generatePending ||
                        !(
                            generated ||
                            generateError ||
                            generateNoData ||
                            generateInvalidVariation ||
                            isTextChart ||
                            isInsightsChart
                        ),
                    'editor__chart--edit': editMode,
                    'editor__chart--text-editor':
                        isTextChart || isInsightsChart,
                    'editor__chart--text-editor-activated':
                        (isTextChart || isInsightsChart) &&
                        textChartState.status === 'activated',
                    'editor__chart--text-editor-selected':
                        (isTextChart || isInsightsChart) &&
                        textChartState.status === 'idle' &&
                        moreOpen,
                    'editor__chart--text-editor-hovered':
                        (isTextChart || isInsightsChart) &&
                        textChartState.status === 'idle' &&
                        !moreOpen &&
                        isHovered
                })}
                onClick={handleDeselect}
                id={`chart-${reportId}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {isEmpty ? (
                    <ChartEmptyState
                        isEmptyState={isEmptyState}
                        generatePending={generatePending}
                        generateError={generateError}
                        generateNotAllowed={generateNotAllowed}
                        onDelete={handleDelete}
                        rowHeight={rowHeight}
                        generateInvalidVariation={generateInvalidVariation}
                        employeeAttribute={employeeAttribute}
                        generateNoData={generateNoData}
                        generated={generated}
                        title={title}
                        source={source}
                    />
                ) : (
                    <Fragment>
                        <ChartContainer ref={chartContainerRef}>
                            <div
                                className="editor__chart__html"
                                ref={htmlRef}
                                style={{
                                    position: isTextChart
                                        ? 'absolute'
                                        : 'relative',
                                    top: 0,
                                    left: 0,
                                    width: '100%'
                                }}
                            >
                                <div className="editor__chart__header">
                                    <div
                                        className="editor__chart__header__title"
                                        ref={titleRef}
                                    >
                                        <ChartTitle
                                            title={
                                                isInsightsChart
                                                    ? moment
                                                          .utc(dateAdded)
                                                          .format(
                                                              'MMM DD, yyyy'
                                                          )
                                                    : title
                                            }
                                            source={source}
                                            setTitle={setTitle}
                                            isTextChart={isTextChart}
                                            isInsightsChart={isInsightsChart}
                                            showTooltip={showTooltip}
                                            editMode={editMode}
                                            disableExploreAccess={
                                                disableExploreAccess
                                            }
                                            handleExplore={handleExplore}
                                        />
                                    </div>
                                    {length > 2 && (
                                        <div className="editor__chart__header__confidence">
                                            {(lifecycle === 'Compensation' ||
                                                analysisType ===
                                                    AnalysisTypes.LinearRegression) &&
                                                confidence && (
                                                    <span>
                                                        Confidence {confidence}%
                                                    </span>
                                                )}
                                            {(isMatrixChart ||
                                                isHorizontalBarChart) && (
                                                <span
                                                    style={{
                                                        marginLeft: 8
                                                    }}
                                                >
                                                    {getPeriod(date, interval)}
                                                </span>
                                            )}
                                        </div>
                                    )}
                                    <ChartActions
                                        dashboardId={dashboardId}
                                        dashboardName={dashboardName}
                                        disableExploreAccess={
                                            disableExploreAccess
                                        }
                                        eventIds={eventIds}
                                        eventsHidden={eventsHidden}
                                        exportOpen={exportOpen}
                                        isHovered={isHovered}
                                        isTextChart={
                                            isTextChart || isInsightsChart
                                        }
                                        isInsightsChart={isInsightsChart}
                                        moreOpen={moreOpen}
                                        reportId={reportId}
                                        role={role}
                                        title={title}
                                        handleExplore={handleExplore}
                                        renderExportMenu={renderExportMenu}
                                        setEventsHidden={setEventsHidden}
                                        setExportOpen={setExportOpen}
                                        setMoreOpen={setMoreOpen}
                                        textChartDispatch={textChartDispatch}
                                    />
                                </div>
                            </div>
                            <ScrollableChartArea ref={scrollableAreaRef}>
                                {chartType === ChartTypes.HorizontalBar && (
                                    <Legend
                                        length={data?.length || 0}
                                        lineWidth={0}
                                        multiLine={false}
                                        data={diversityAttributes?.map(
                                            (attr: any) => ({
                                                ...attr,
                                                color: attr.segment.tokens[0]
                                                    .color
                                            })
                                        )}
                                        onHover={onHover}
                                        onHoverEnd={onHoverEnd}
                                        onToggle={onToggle}
                                        hoveredItem={hoveredItem}
                                        selection={selection}
                                        status={status}
                                    />
                                )}
                                {chartDeclaration.chartLegend &&
                                    chartType !== ChartTypes.HorizontalBar && (
                                        <Legend
                                            {...chartDeclaration.chartLegend}
                                            lineWidth="auto"
                                        />
                                    )}
                                <GraphWrapper
                                    className={`flex-1 ${
                                        chartType === ChartTypes.Text ||
                                        chartType === ChartTypes.InsightsText
                                            ? 'h-full'
                                            : 'h-auto'
                                    }`}
                                    ref={chartRef}
                                >
                                    <ChartPicker
                                        isResizing={isResizing}
                                        data={data}
                                        title={title}
                                        chartType={chartType}
                                        dashboardName={dashboardName}
                                        dashboardId={dashboardId}
                                        reportId={reportId}
                                        options={options}
                                        editMode={editMode}
                                        textChartDispatch={textChartDispatch}
                                        textChartState={textChartState}
                                        role={role}
                                        chartContainerBoundingBox={
                                            chartContainerBoundingBox
                                        }
                                        dates={dates}
                                        width={width}
                                        height={height}
                                        yAxisType={yAxisType}
                                        indexType={indexType}
                                        columnProps={columnProps}
                                        interval={interval}
                                        lifecycle={lifecycle}
                                        analysisType={analysisType}
                                        handleLabelActive={handleLabelActive}
                                        handleLabelClear={handleLabelClear}
                                        handleLabelToggle={handleLabelToggle}
                                        employeeCountHidden={
                                            employeeCountHidden
                                        }
                                        source={source}
                                        adjustedChartHeight={
                                            adjustedChartHeight
                                        }
                                        disableInteractions={
                                            disableInteractions
                                        }
                                        selectCurrentTime={selectCurrentTime}
                                        status={status}
                                        hasInvalidValue={hasInvalidValue}
                                        setInnerChartOffsets={
                                            setInnerChartOffsets
                                        }
                                        length={length}
                                        lightMode={lightMode}
                                        benchmark={benchmark}
                                        currentIndex={currentIndex}
                                        colWidth={colWidth}
                                        benchmarkContainerHeight={
                                            benchmarkContainerHeight
                                        }
                                        benchmarkRowHeight={benchmarkRowHeight}
                                        tableData={tableData}
                                        generatePending={generatePending}
                                        disableLinks={disableLinks}
                                        tableRowHeight={tableRowHeight}
                                        useMaxHeightOnTable={
                                            useMaxHeightOnTable
                                        }
                                        navigate={navigate}
                                        expanded={expanded}
                                        handleToggleExpansion={
                                            handleToggleExpansion
                                        }
                                        sortCriteria={sortCriteria}
                                        handleSort={handleSort}
                                        onHover={onHover}
                                        onHoverEnd={onHoverEnd}
                                        hoveredItem={hoveredItem}
                                        selection={selection}
                                        employeeAttribute={employeeAttribute}
                                        attributeName={attributeName}
                                        employeeAttributes={employeeAttributes}
                                        diversityAttributes={
                                            diversityAttributes
                                        }
                                        summary={summary}
                                        showGroupSummary={showGroupSummary}
                                        groupSummary={groupSummary}
                                        generateNoData={generateNoData}
                                        handleNavigateToDetail={
                                            handleNavigateToDetail
                                        }
                                    />
                                </GraphWrapper>

                                {chartDeclaration.events && !eventsHidden && (
                                    <Events {...chartDeclaration.events} />
                                )}
                            </ScrollableChartArea>
                            {chartDeclaration.xAxis && (
                                <XAxis
                                    {...chartDeclaration.xAxis}
                                    indexType={indexType}
                                />
                            )}
                        </ChartContainer>
                    </Fragment>
                )}
                <CSSTransition
                    in={isEmptyState}
                    timeout={200}
                    classNames="fade"
                    mountOnEnter
                    unmountOnExit
                >
                    <div className="editor__chart__empty-state">
                        <img src="/images/empty-state-bars.svg" />
                    </div>
                </CSSTransition>
            </div>
        </Fragment>
    );
}

Chart.defaultProps = defaultProps;

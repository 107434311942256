import React, { ChangeEvent, useRef } from 'react';
import Header from './Header';
import { AdminHeaderContext } from './AdminHeader';

import Button from '../../common/Button';
import Sync from './Sync';
import WithPermissions from '../../common/WithPermissions';
import RbacActions from '../../constants/RbacActions';
import ButtonTypes from '../../constants/ButtonTypes';

const UsersHeader = () => {
    const { handleShowCreate } = React.useContext(AdminHeaderContext);

    return (
        <Header>
            <Header.Title></Header.Title>
            <Header.Buttons>
                <Sync />
                <WithPermissions actions={[RbacActions['Roles/Edit']]}>
                    <Button onClick={handleShowCreate}>Create User</Button>
                </WithPermissions>
            </Header.Buttons>
        </Header>
    );
};

export default UsersHeader;

import { connectRouter } from 'connected-react-router';
import { combineReducers } from 'redux';
import adminReducer from '../admin/reducer';
import authReducer from '../auth/reducer';
import benchmarkModalReducer from '../benchmark/benchmarkModalReducer';
import benchmarksReducer from '../benchmark/benchmarksReducer';
import collectReducer from '../collect/reducer';
import commentsReducer from '../comments/reducer';
import deleteModalReducer from '../common/deleteModalReducer';
import dashboardReducer from '../dashboard/edit/dashboardReducer';
import reportsReducer from '../dashboard/view/reportsReducer';
import dashboardsReducer from '../dashboards/dashboardsReducer';
import myDashboardsReducer from '../dashboards/myDashboardsReducer';
import datastudioReducer from '../datastudio/reducer';
import editorReducer from '../editor/editorReducer';
import enterpriseFieldsReducer from '../enterprise-fields/slice';
import exploreReducer from '../explore/reducer';
import notificationsReducer from '../layout/notificationsReducer';
import measureReducer from '../measure/reducer';
import rolesReducer from '../roles/reducer';
import audiencesReducer from '../self-id/audiences/reducer';
import snapshotSurveysReducer from '../self-id/campaigns/reducer';
import surveyTemplatesReducer from '../self-id/survey-templates/reducer';
import templatesReducer from '../templates/reducer';
import timelineReducer from '../timeline/reducer';
import accountReducer from './accountReducer';
import appStateReducer from './appStateReducer';
import infoModalReducer from './infoModalReducer';
import messageReducer from './messageReducer';
import aiReducer from '../ai/reducer';

export default (history: any) =>
    combineReducers({
        router: connectRouter(history),
        account: accountReducer,
        auth: authReducer,
        roles: rolesReducer,
        admin: adminReducer,
        appState: appStateReducer,
        enterpriseFields: enterpriseFieldsReducer,
        comments: commentsReducer,
        notifications: notificationsReducer,
        messages: messageReducer,
        collect: collectReducer,
        infoModal: infoModalReducer,
        editor: editorReducer,
        dashboards: dashboardsReducer,
        dashboard: dashboardReducer,
        myDashboards: myDashboardsReducer,
        reports: reportsReducer,
        benchmarkModal: benchmarkModalReducer,
        benchmarks: benchmarksReducer,
        deleteModal: deleteModalReducer,
        explore: exploreReducer,
        timeline: timelineReducer,
        templates: templatesReducer,
        datastudio: datastudioReducer,
        measure: measureReducer,
        snapshotSurveys: snapshotSurveysReducer,
        audiences: audiencesReducer,
        surveyTemplates: surveyTemplatesReducer,
        ai: aiReducer
    });

import { ChartType } from '../../../types';
import chartColors from './chartColors';

const limitCount = (count: number): number => {
    let limitedCount = count;
    if (count < 0) {
        limitedCount = 0;
    }
    if (count > chartColors.length) {
        limitedCount = chartColors.length;
    }
    return limitedCount;
};

export const getColorArrayFromCount = (count: number): string[] => {
    const limitedCount = limitCount(count);
    return chartColors.slice(0, limitedCount);
};

export const getSVG = (type: ChartType, count: number) => {
    const limitedCount = limitCount(count);
    let svgColors = getColorArrayFromCount(count);

    if (svgColors.length === 0) {
        svgColors.push(`var(--color-graph-4`);
        svgColors.push(`var(--color-graph-5`);
    }

    if (svgColors.length === 1) {
        svgColors.push(`var(--color-graph-5`);
    }

    switch (type) {
        case 'pie':
            switch (limitedCount) {
                case 0:
                case 1:
                case 2:
                    return (
                        <>
                            <path
                                d="M33 5.00092C48.7223 5.00092 61.4677 17.7463 61.4677 33.4686C61.4677 49.1909 48.7223 61.9363 33 61.9363C17.2777 61.9363 4.53234 49.1909 4.53234 33.4686C4.53234 17.7463 17.2777 5.00092 33 5.00092Z"
                                stroke={svgColors[1]}
                                strokeWidth="9"
                                className="animated__pie-chart"
                            />
                            <path
                                d="M33 5.00092C37.3637 5.00092 41.6691 6.00411 45.5834 7.93298C49.4976 9.86185 52.9161 12.6648 55.5746 16.1252C58.2332 19.5856 60.0606 23.6108 60.9157 27.89C61.7709 32.1691 61.6308 36.5875 60.5064 40.8039C59.382 45.0203 57.3033 48.9218 54.431 52.2069C51.5586 55.4919 47.9695 58.0727 43.9409 59.7498C39.9123 61.427 35.5521 62.1555 31.1971 61.8791C26.8422 61.6028 22.609 60.3289 18.8247 58.156"
                                stroke={svgColors[0]}
                                strokeWidth="9"
                                className="animated__pie-chart"
                            />
                        </>
                    );
                case 3:
                    return (
                        <>
                            <path
                                d="M33.8906 4.85278C49.6129 4.85278 62.3583 17.5982 62.3583 33.3205C62.3583 49.0427 49.6129 61.7881 33.8906 61.7881C18.1684 61.7881 5.42298 49.0427 5.42298 33.3205C5.42298 17.5982 18.1684 4.85278 33.8906 4.85278Z"
                                stroke={svgColors[2]}
                                strokeWidth="9"
                                className="animated__pie-chart"
                            />
                            <path
                                d="M33.8906 4.85278C39.6811 4.85278 45.3338 6.61854 50.0946 9.91448C54.8555 13.2104 58.498 17.8798 60.5363 23.2996C62.5746 28.7194 62.9116 34.6319 61.5025 40.2482C60.0934 45.8645 57.0051 50.9176 52.6497 54.7332C48.2942 58.5489 42.8788 60.9456 37.1259 61.6037C31.373 62.2617 25.5562 61.1498 20.4517 58.4163C15.3471 55.6828 11.1975 51.4576 8.55648 46.3046C5.91547 41.1515 4.90865 35.3157 5.67037 29.5756"
                                stroke={svgColors[1]}
                                strokeWidth="9"
                                className="animated__pie-chart"
                            />
                            <path
                                d="M33.8906 4.85277C40.3461 4.85277 46.6098 7.0468 51.6542 11.0749C56.6987 15.103 60.2243 20.7261 61.6527 27.0215C63.0811 33.3169 62.3274 39.9108 59.5153 45.7215C56.7032 51.5322 51.9996 56.2146 46.1763 59.0006"
                                stroke={svgColors[0]}
                                strokeWidth="9"
                                className="animated__pie-chart"
                            />
                        </>
                    );
                case 4:
                    return (
                        <>
                            <path
                                d="M33.0086 4.85277C48.7309 4.85277 61.4763 17.5982 61.4763 33.3204C61.4763 49.0427 48.7309 61.7881 33.0086 61.7881C17.2863 61.7881 4.54092 49.0427 4.54092 33.3204C4.54092 17.5982 17.2863 4.85277 33.0086 4.85277Z"
                                stroke={svgColors[3]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                            <path
                                d="M33.0086 4.85278C39.2199 4.85278 45.2607 6.88423 50.2099 10.6373C55.159 14.3904 58.7449 19.6592 60.4207 25.6402C62.0964 31.6211 61.77 37.986 59.4913 43.7642C57.2126 49.5424 53.1067 54.4168 47.7996 57.644C42.4925 60.8712 36.2755 62.2741 30.0968 61.6388C23.9181 61.0035 18.1168 58.3648 13.5774 54.1252C9.03809 49.8856 6.00993 44.2776 4.95473 38.1567C3.89953 32.0357 4.87521 25.7375 7.73297 20.2227"
                                stroke={svgColors[2]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                            <path
                                d="M33.0086 4.85278C37.415 4.85278 41.7613 5.87568 45.7052 7.84095C49.6491 9.80622 53.0829 12.6602 55.7363 16.1782C58.3897 19.6961 60.1903 23.782 60.9961 28.1141C61.802 32.4462 61.5912 36.9063 60.3804 41.1431C59.1695 45.3799 56.9917 49.2777 54.0183 52.5298C51.045 55.7818 47.3573 58.2992 43.2456 59.8838C39.134 61.4683 34.7106 62.0768 30.3238 61.6612C25.937 61.2457 21.7066 59.8174 17.9656 57.489"
                                stroke={svgColors[1]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                            <path
                                d="M33.0086 4.85275C38.1191 4.85275 43.1353 6.22845 47.5308 8.83545C51.9263 11.4424 55.5389 15.1846 57.9896 19.6691C60.4403 24.1537 61.6385 29.2152 61.4586 34.3225C61.2787 39.4298 59.7273 44.3945 56.9672 48.6955"
                                stroke={svgColors[0]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                        </>
                    );
                case 5:
                    return (
                        <>
                            <path
                                d="M33.1595 4.85278C48.8817 4.85278 61.6271 17.5982 61.6271 33.3205C61.6271 49.0427 48.8817 61.7881 33.1595 61.7881C17.4372 61.7881 4.6918 49.0427 4.6918 33.3205C4.6918 17.5982 17.4372 4.85278 33.1595 4.85278Z"
                                stroke={svgColors[4]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                            <path
                                d="M33.1595 4.85278C39.7456 4.85278 46.128 7.13644 51.2192 11.3146C56.3103 15.4929 59.7952 21.3071 61.0801 27.7667C62.365 34.2263 61.3704 40.9316 58.2657 46.74C55.161 52.5485 50.1384 57.1007 44.0536 59.6211C37.9688 62.1416 31.1983 62.4742 24.8957 60.5623C18.5932 58.6505 13.1485 54.6124 9.48947 49.1362C5.8304 43.6601 4.18333 37.0846 4.82888 30.5301C5.47444 23.9757 8.37268 17.8479 13.0298 13.1908"
                                stroke={svgColors[3]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                            <path
                                d="M33.1595 4.85278C38.5843 4.85278 43.8961 6.40279 48.4696 9.32029C53.0431 12.2378 56.6877 16.4013 58.9744 21.3206C61.2611 26.2399 62.0946 31.7102 61.3768 37.0873C60.659 42.4644 58.4197 47.5245 54.9227 51.6717C51.4257 55.8189 46.8165 58.8806 41.6379 60.4963C36.4593 62.1119 30.9268 62.2143 25.692 60.7912C20.4571 59.3682 15.7378 56.4791 12.0898 52.4641C8.44181 48.4491 6.01694 43.4753 5.10074 38.1284"
                                stroke={svgColors[2]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                            <path
                                d="M33.1595 4.85278C36.9871 4.85278 40.7755 5.62468 44.298 7.12232C47.8205 8.61996 51.0048 10.8126 53.6605 13.5691C56.3162 16.3256 58.3887 19.5893 59.7542 23.1651C61.1196 26.7409 61.7499 30.5554 61.6074 34.3804C61.4649 38.2054 60.5525 41.9624 58.9247 45.4267C57.2969 48.891 54.9873 51.9914 52.1338 54.5427C49.2804 57.0939 45.9417 59.0435 42.3175 60.2748C38.6933 61.5062 34.8581 61.994 31.041 61.7092"
                                stroke={svgColors[1]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                            <path
                                d="M33.1595 4.85277C37.2306 4.85277 41.2545 5.72598 44.9594 7.41349C48.6644 9.101 51.9643 11.5635 54.6365 14.635C57.3087 17.7064 59.291 21.3153 60.4498 25.218C61.6085 29.1208 61.9166 33.2267 61.3534 37.2588"
                                stroke={svgColors[0]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                        </>
                    );
                default:
                    return (
                        <>
                            <path
                                d="M33.1152 5.06467C48.8375 5.06467 61.5829 17.8101 61.5829 33.5323C61.5829 49.2546 48.8375 62 33.1152 62C17.3929 62 4.64752 49.2546 4.64752 33.5323C4.64752 17.8101 17.3929 5.06467 33.1152 5.06467Z"
                                stroke={svgColors[5]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                            <path
                                d="M33.1152 5.06466C39.9487 5.06466 46.5542 7.52274 51.7253 11.99C56.8964 16.4572 60.288 22.6355 61.2808 29.3965C62.2736 36.1574 60.8013 43.05 57.1327 48.8152C53.4642 54.5805 47.8442 58.8337 41.2992 60.7983C34.7542 62.7628 27.7209 62.3074 21.4838 59.5154C15.2467 56.7233 10.2221 51.7809 7.32755 45.5908C4.433 39.4006 3.86169 32.3758 5.71797 25.7993C7.57424 19.2227 11.7342 13.5334 17.4382 9.77019"
                                stroke={svgColors[4]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                            <path
                                d="M33.1152 5.06466C39.1468 5.06466 45.0226 6.98043 49.895 10.5357C54.7674 14.0909 58.3847 19.1019 60.2252 24.8458C62.0657 30.5898 62.0342 36.7699 60.1352 42.4948C58.2363 48.2197 54.5681 53.1935 49.6596 56.6989C44.7512 60.2043 38.8562 62.06 32.8249 61.9985C26.7936 61.937 20.9377 59.9614 16.1018 56.3567C11.2658 52.752 7.69981 47.7044 5.91801 41.9419C4.13622 36.1795 4.23074 30.0001 6.18795 24.2948"
                                stroke={svgColors[3]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                            <path
                                d="M33.1152 5.06466C38.0698 5.06466 42.9386 6.35775 47.2402 8.81609C51.5419 11.2744 55.1275 14.8129 57.6425 19.0817C60.1575 23.3505 61.5149 28.2017 61.5804 33.1558C61.6459 38.11 60.4173 42.9954 58.0161 47.3292C55.6148 51.663 52.1241 55.2951 47.889 57.8663C43.6538 60.4376 38.8209 61.859 33.8681 61.99C28.9153 62.1211 24.014 60.9572 19.6488 58.6135C15.2836 56.2698 11.6057 52.8274 8.9787 48.6266"
                                stroke={svgColors[2]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                            <path
                                d="M33.1152 5.06466C36.8536 5.06466 40.5554 5.801 44.0093 7.23164C47.4631 8.66227 50.6014 10.7592 53.2449 13.4026C55.8883 16.0461 57.9852 19.1844 59.4159 22.6382C60.8465 26.0921 61.5828 29.7939 61.5828 33.5323C61.5828 37.2708 60.8465 40.9726 59.4159 44.4264C57.9852 47.8803 55.8883 51.0185 53.2449 53.662C50.6014 56.3055 47.4631 58.4024 44.0093 59.833C40.5554 61.2637 36.8536 62 33.1152 62"
                                stroke={svgColors[1]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                            <path
                                d="M33.1152 5.06466C37.1859 5.06466 41.2092 5.93767 44.9138 7.6248C48.6185 9.31193 51.9182 11.7739 54.5904 14.8447C57.2626 17.9155 59.2451 21.5237 60.4043 25.4259C61.5634 29.328 61.8723 33.4334 61.3099 37.4651"
                                stroke={svgColors[0]}
                                className="animated__pie-chart"
                                strokeWidth="9"
                            />
                        </>
                    );
            }
        case 'line':
            switch (limitedCount) {
                case 0:
                case 1:
                    return (
                        <>
                            <path
                                d="M0.997215 0.779663C0.997215 0.779663 51.7098 34.8716 92.9487 34.8716C134.188 34.8716 128.615 5.77587 186.572 5.77587C244.529 5.77587 287.997 24.6947 287.997 24.6947"
                                stroke={svgColors[0]}
                                className="animated__line-chart"
                            />
                        </>
                    );
                case 2:
                    return (
                        <>
                            <path
                                d="M0.997215 30.0829C0.997215 30.0829 51.7098 39.1683 87.3759 39.1683C123.042 39.1683 155.364 0.820435 197.718 0.820435C240.071 0.820435 287.997 11.777 287.997 11.777"
                                stroke={svgColors[1]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M0.997215 42.8204C0.997215 42.8204 51.7098 14.8204 92.9487 14.8204C134.188 14.8204 128.615 30.0378 186.572 30.0378C244.529 30.0378 287.997 30.0378 287.997 30.0378"
                                stroke={svgColors[0]}
                                className="animated__line-chart"
                            />
                        </>
                    );
                case 3:
                    return (
                        <>
                            <path
                                d="M0.997206 35.5433C0.997206 35.5433 35.3282 21.5347 86.0908 21.5347C136.853 21.5347 154.458 6.61059 196.811 6.61059C239.164 6.61059 287.997 17.2373 287.997 17.2373"
                                stroke={svgColors[0]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M0.997206 48.2808C0.997206 48.2808 51.425 55.5599 92.6639 55.5599C133.903 55.5599 137.062 20.2808 195.019 20.2808C252.977 20.2808 287.997 46.013 287.997 46.013"
                                stroke={svgColors[1]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M1 1.02899C1 1.02899 41.2311 5.6029 86.9729 5.6029C132.715 5.6029 151.661 45.4246 191.373 45.4246C231.085 45.4246 288 1.02899 288 1.02899"
                                stroke={svgColors[2]}
                                className="animated__line-chart"
                            />
                        </>
                    );
                case 4:
                    return (
                        <>
                            <path
                                d="M0.997204 35.2832C0.997204 35.2832 35.3282 21.2746 86.0908 21.2746C136.853 21.2746 154.458 6.35052 196.811 6.35052C239.164 6.35052 287.997 16.9773 287.997 16.9773"
                                stroke={svgColors[0]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M0.997204 48.0207C0.997204 48.0207 51.425 55.2998 92.6639 55.2998C133.903 55.2998 137.062 20.0207 195.019 20.0207C252.977 20.0207 287.997 45.7529 287.997 45.7529"
                                stroke={svgColors[1]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M0.999998 0.768921C0.999998 0.768921 41.2311 5.34283 86.9729 5.34283C132.715 5.34283 151.661 45.1646 191.373 45.1646C231.085 45.1646 288 0.768921 288 0.768921"
                                stroke={svgColors[2]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M1 56.7887C1 56.7887 41.2311 33.9582 86.9729 33.9582C132.715 33.9582 151.661 37.1193 191.373 37.1193C231.085 37.1193 288 24.4747 288 24.4747"
                                stroke={svgColors[3]}
                                className="animated__line-chart"
                            />
                        </>
                    );
                case 5:
                    return (
                        <>
                            <path
                                d="M0.997204 35.9882C0.997204 35.9882 35.3282 21.9796 86.0908 21.9796C136.853 21.9796 154.458 7.05554 196.811 7.05554C239.164 7.05554 287.997 17.6823 287.997 17.6823"
                                stroke={svgColors[0]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M0.997204 48.7257C0.997204 48.7257 51.425 40.9436 92.6639 40.9436C133.903 40.9436 137.062 20.7257 195.019 20.7257C252.977 20.7257 287.997 46.458 287.997 46.458"
                                stroke={svgColors[1]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M0.999998 1.47394C0.999998 1.47394 41.2311 6.04785 86.9729 6.04785C132.715 6.04785 151.661 45.8696 191.373 45.8696C231.085 45.8696 288 36.223 288 36.223"
                                stroke={svgColors[2]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M1 25.1797C1 25.1797 41.2311 34.6632 86.9729 34.6632C132.715 34.6632 151.661 13.4298 191.373 13.4298C231.085 13.4298 288 25.1797 288 25.1797"
                                stroke={svgColors[3]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M1 37.6789C1 37.6789 41.2311 14.5218 86.9729 14.5218C132.715 14.5218 152.979 32.9187 192.691 32.9187C232.403 32.9187 287.782 42.09 287.782 42.09"
                                stroke={svgColors[4]}
                                className="animated__line-chart"
                            />
                        </>
                    );
                default:
                    return (
                        <>
                            <path
                                d="M1.07009 29.4359C1.07009 29.4359 51.693 38.411 87.2959 38.411C122.899 38.411 152.947 1.41101 195.226 1.41101C237.504 1.41101 283.07 4.12631 288.07 4.12631"
                                stroke={svgColors[1]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M1.07009 10.5462C1.07009 10.5462 50.4041 27.786 86.0701 27.786C121.736 27.786 155.437 46.411 197.79 46.411C240.144 46.411 288.07 46.411 288.07 46.411"
                                stroke={svgColors[5]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M1.07009 12.3325C1.07009 12.3325 51.7827 10.911 87.4487 10.911C123.115 10.911 155.437 16.911 197.79 16.911C240.144 16.911 288.07 15.1967 288.07 15.1967"
                                stroke={svgColors[2]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M1.07009 37.3325C1.07009 37.3325 50.4041 32.8949 86.0701 32.8949C121.736 32.8949 155.437 41.911 197.79 41.911C240.144 41.911 288.07 40.1967 288.07 40.1967"
                                stroke={svgColors[4]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M1.07009 41.661C1.07009 41.661 51.7827 18.661 93.0215 18.661C134.26 18.661 128.688 31.161 186.645 31.161C244.602 31.161 288.07 31.161 288.07 31.161"
                                stroke={svgColors[0]}
                                className="animated__line-chart"
                            />
                            <path
                                d="M1.07009 41.3949C1.07009 41.3949 51.7827 46.3949 93.0215 46.3949C134.26 46.3949 128.688 37.6775 186.645 37.6775C244.602 37.6775 288.07 22.3949 288.07 22.3949"
                                stroke={svgColors[3]}
                                className="animated__line-chart"
                            />
                        </>
                    );
            }
        case 'vertical-bar':
            switch (limitedCount) {
                case 0:
                case 1:
                case 2:
                    return (
                        <>
                            <rect
                                className="animated__vertical-bar"
                                y="9.16806"
                                width="141"
                                height="47"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="143"
                                y="25.1681"
                                width="141"
                                height="31"
                                fill={svgColors[1]}
                            />
                        </>
                    );
                case 3:
                    return (
                        <>
                            <rect
                                className="animated__vertical-bar"
                                y="9.42979"
                                width="93.3333"
                                height="47"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="95.3333"
                                y="25.4298"
                                width="93.3333"
                                height="31"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="190.667"
                                y="15.2766"
                                width="93.3333"
                                height="41.1532"
                                fill={svgColors[2]}
                            />
                        </>
                    );
                case 4:
                    return (
                        <>
                            <rect
                                className="animated__vertical-bar"
                                y="9"
                                width="69.5"
                                height="47"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="71.5"
                                y="25"
                                width="69.5"
                                height="31"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="143"
                                y="14.8468"
                                width="69.5"
                                height="41.1532"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="214.5"
                                y="9"
                                width="69.5"
                                height="47"
                                fill={svgColors[3]}
                            />
                        </>
                    );
                case 5:
                    return (
                        <>
                            <rect
                                className="animated__vertical-bar"
                                y="9.26199"
                                width="55.2"
                                height="47"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="57.2"
                                y="25.262"
                                width="55.2"
                                height="31"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="114.4"
                                y="15.1088"
                                width="55.2"
                                height="41.1532"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="171.6"
                                y="25.262"
                                width="55.2"
                                height="31"
                                fill={svgColors[3]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="228.8"
                                y="41.4292"
                                width="55.2"
                                height="14.8327"
                                fill={svgColors[4]}
                            />
                        </>
                    );
                default:
                    return (
                        <>
                            <rect
                                className="animated__vertical-bar"
                                y="9"
                                width="45.6667"
                                height="47"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="47.6667"
                                y="25"
                                width="45.6667"
                                height="31"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="95.3333"
                                y="28.8769"
                                width="45.6667"
                                height="27.1231"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="143"
                                y="35.2032"
                                width="45.6667"
                                height="20.7968"
                                fill={svgColors[3]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="190.667"
                                y="46.4872"
                                width="45.6667"
                                height="9.51274"
                                fill={svgColors[4]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="238.333"
                                y="19.9029"
                                width="45.6667"
                                height="36.0971"
                                fill={svgColors[5]}
                            />
                        </>
                    );
            }
        case 'horizontalbar': {
            switch (limitedCount) {
                case 0:
                case 1:
                case 2:
                    return (
                        <>
                            <rect
                                className="animated__horizontal-bar"
                                y="0.473694"
                                width="248.181"
                                height="24"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="26.4737"
                                width="212.469"
                                height="24"
                                fill={svgColors[1]}
                            />
                        </>
                    );
                case 3:
                    return (
                        <>
                            <rect
                                className="animated__horizontal-bar"
                                y="0.735718"
                                width="203"
                                height="15.3333"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="18.069"
                                width="150"
                                height="15.3333"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="35.4024"
                                width="217"
                                height="15.3333"
                                fill={svgColors[2]}
                            />
                        </>
                    );
                case 4:
                    return (
                        <>
                            <rect
                                className="animated__horizontal-bar"
                                y="0.997742"
                                width="203"
                                height="11"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="13.9977"
                                width="150"
                                height="11"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="26.9977"
                                width="217"
                                height="11"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="39.9977"
                                width="104"
                                height="11"
                                fill={svgColors[3]}
                            />
                        </>
                    );
                case 5:
                    return (
                        <>
                            <rect
                                className="animated__horizontal-bar"
                                y="0.259796"
                                width="203"
                                height="8.4"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="10.6598"
                                width="244.083"
                                height="8.4"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="21.0598"
                                width="217"
                                height="8.4"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="31.4598"
                                width="126.41"
                                height="8.4"
                                fill={svgColors[3]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="41.8598"
                                width="189"
                                height="8.4"
                                fill={svgColors[4]}
                            />
                        </>
                    );
                default:
                    return (
                        <>
                            <rect
                                className="animated__horizontal-bar"
                                y="0.52179"
                                width="203"
                                height="6.66667"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="9.18845"
                                width="150"
                                height="6.66667"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="17.8551"
                                width="217"
                                height="6.66667"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="26.5218"
                                width="104"
                                height="6.66667"
                                fill={svgColors[3]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="35.1884"
                                width="189"
                                height="6.66667"
                                fill={svgColors[4]}
                            />
                            <rect
                                className="animated__horizontal-bar"
                                y="43.8551"
                                width="264"
                                height="6.66667"
                                fill={svgColors[5]}
                            />
                        </>
                    );
            }
        }
        case 'index-bar': {
            switch (limitedCount) {
                case 0:
                case 1:
                case 2:
                    return (
                        <>
                            <rect
                                className="animated__index-bar"
                                y="5.293"
                                width="141"
                                height="19.707"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="143"
                                y="25"
                                width="141"
                                height="19"
                                fill={svgColors[1]}
                            />
                        </>
                    );
                case 3:
                    return (
                        <>
                            <rect
                                className="animated__index-bar"
                                y="5.55499"
                                width="93.34"
                                height="19.707"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="95.34"
                                y="25.262"
                                width="93.34"
                                height="19"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="190.68"
                                y="12.6206"
                                width="93.34"
                                height="12.6414"
                                fill={svgColors[2]}
                            />
                        </>
                    );
                case 4:
                    return (
                        <>
                            <rect
                                className="animated__index-bar"
                                y="5.29301"
                                width="69.8176"
                                height="19.707"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="71.8175"
                                y="25"
                                width="68.5153"
                                height="19"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="142.333"
                                y="12.3586"
                                width="69.8176"
                                height="12.6414"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="214.15"
                                y="10"
                                width="69.8176"
                                height="15"
                                fill={svgColors[3]}
                            />
                        </>
                    );
                case 5:
                    return (
                        <>
                            <rect
                                className="animated__index-bar"
                                y="10.4298"
                                width="55.6133"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="57.6154"
                                y="25.4298"
                                width="54.576"
                                height="19"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="114.193"
                                y="25.4298"
                                width="55.6133"
                                height="4"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="171.809"
                                y="10.4298"
                                width="55.6133"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="229.424"
                                y="25.4298"
                                width="54.576"
                                height="13"
                                fill={svgColors[4]}
                            />
                        </>
                    );
                default:
                    return (
                        <>
                            <rect
                                className="animated__index-bar"
                                y="10.1681"
                                width="46"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="48"
                                y="25.1681"
                                width="45"
                                height="19"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="95"
                                y="25.1681"
                                width="46"
                                height="4"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="143"
                                y="10.1681"
                                width="46"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="191"
                                y="25.1681"
                                width="45"
                                height="13"
                                fill={svgColors[4]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="238.333"
                                y="0.16806"
                                width="45.6667"
                                height="25"
                                fill={svgColors[5]}
                            />
                        </>
                    );
            }
        }
        case 'index-bar-grouped': {
            switch (limitedCount) {
                case 0:
                case 1:
                case 2:
                    return (
                        <>
                            <rect
                                className="animated__index-bar"
                                y="11"
                                width="34"
                                height="14"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="36"
                                y="25"
                                width="34"
                                height="12"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="107"
                                y="4"
                                width="34"
                                height="21"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="143"
                                y="25"
                                width="34"
                                height="21"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="214"
                                y="25"
                                width="34"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="250"
                                y="11"
                                width="34"
                                height="14"
                                fill={svgColors[1]}
                            />
                        </>
                    );
                case 3:
                    return (
                        <>
                            <rect
                                className="animated__index-bar"
                                y="11.6317"
                                width="22"
                                height="14"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="24"
                                y="25.6317"
                                width="22"
                                height="12"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__index-bar"
                                width="22"
                                height="7.49954"
                                transform="matrix(1 0 0 -1 48 25.6317)"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="107"
                                y="11.6317"
                                width="22"
                                height="14"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                width="22"
                                height="6.63068"
                                transform="matrix(1 0 0 -1 131 25.6317)"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="155"
                                y="25.6317"
                                width="22"
                                height="12.3263"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__index-bar"
                                width="22"
                                height="14.756"
                                transform="matrix(1 0 0 -1 214 40.3877)"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                width="22"
                                height="10.8578"
                                transform="matrix(1 0 0 -1 238 25.6317)"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__index-bar"
                                width="22"
                                height="3.20007"
                                transform="matrix(1 0 0 -1 262 25.6317)"
                                fill={svgColors[2]}
                            />
                        </>
                    );
                default:
                    return (
                        <>
                            <rect
                                className="animated__index-bar"
                                y="11.2637"
                                width="16"
                                height="14"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="18"
                                y="25.2637"
                                width="16"
                                height="12"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__index-bar"
                                width="16"
                                height="7.49954"
                                transform="matrix(1 0 0 -1 36 25.2637)"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__index-bar"
                                width="16"
                                height="12.2655"
                                transform="matrix(1 0 0 -1 54 25.2637)"
                                fill={svgColors[3]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="107"
                                y="8.82529"
                                width="16"
                                height="16.4384"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                width="16"
                                height="11.6317"
                                transform="matrix(1 0 0 -1 125 25.2637)"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="143"
                                y="25.2637"
                                width="16"
                                height="6.35864"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__index-bar"
                                width="16"
                                height="2.3067"
                                transform="matrix(1 0 0 -1 161 25.2637)"
                                fill={svgColors[3]}
                            />
                            <rect
                                className="animated__index-bar"
                                width="16"
                                height="6.48563"
                                transform="matrix(1 0 0 -1 214 31.7493)"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__index-bar"
                                x="232"
                                y="25.2637"
                                width="16"
                                height="12"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__index-bar"
                                width="16"
                                height="6.08557"
                                transform="matrix(1 0 0 -1 250 25.2637)"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__index-bar"
                                width="16"
                                height="12.2655"
                                transform="matrix(1 0 0 -1 268 25.2637)"
                                fill={svgColors[3]}
                            />
                        </>
                    );
            }
        }
        case 'bar':
        case 'vertical-bar-grouped': {
            switch (limitedCount) {
                case 0:
                case 1:
                    return (
                        <>
                            <rect
                                className="animated__vertical-bar"
                                x="0.44397"
                                y="21.7564"
                                width="70"
                                height="35"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="107.444"
                                y="14.0226"
                                width="70"
                                height="42.7339"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="214.444"
                                y="37.8521"
                                width="70"
                                height="18.9043"
                                fill={svgColors[0]}
                            />
                        </>
                    );
                case 2:
                    return (
                        <>
                            <rect
                                className="animated__vertical-bar"
                                x="0.44397"
                                y="21.1681"
                                width="34"
                                height="35"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="36.444"
                                y="17.1681"
                                width="34"
                                height="39"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="107.444"
                                y="9.16806"
                                width="34"
                                height="47"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="143.444"
                                y="25.1681"
                                width="34"
                                height="31"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="214.444"
                                y="14.1681"
                                width="34"
                                height="42"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="250.444"
                                y="27.1681"
                                width="34"
                                height="29"
                                fill={svgColors[1]}
                            />
                        </>
                    );
                case 3:
                    return (
                        <>
                            <rect
                                className="animated__vertical-bar"
                                x="0.44397"
                                y="21"
                                width="22"
                                height="35"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="24.444"
                                y="17"
                                width="22"
                                height="39"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="48.444"
                                y="29.5805"
                                width="22"
                                height="26.4195"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="107.444"
                                y="23.7565"
                                width="22"
                                height="32.2435"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="131.444"
                                y="34.9552"
                                width="22"
                                height="21.0448"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="155.444"
                                y="17"
                                width="22"
                                height="39"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="214.444"
                                y="11.7856"
                                width="22"
                                height="44.2144"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="238.444"
                                y="29.5805"
                                width="22"
                                height="26.4195"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="262.444"
                                y="40.7476"
                                width="22"
                                height="15.2524"
                                fill={svgColors[2]}
                            />
                        </>
                    );
                default:
                    return (
                        <>
                            <rect
                                className="animated__vertical-bar"
                                x="0.44397"
                                y="21.262"
                                width="16"
                                height="35"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="18.444"
                                y="40.8007"
                                width="16"
                                height="15.4613"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="36.444"
                                y="29.8425"
                                width="16"
                                height="26.4195"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="54.444"
                                y="21.262"
                                width="16"
                                height="35"
                                fill={svgColors[3]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="107.444"
                                y="35.567"
                                width="16"
                                height="20.695"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="125.444"
                                y="25.7153"
                                width="16"
                                height="30.5467"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="143.444"
                                y="15.5557"
                                width="16"
                                height="40.7063"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="161.444"
                                y="33.4119"
                                width="16"
                                height="22.8501"
                                fill={svgColors[3]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="214.444"
                                y="45.7266"
                                width="16"
                                height="10.5354"
                                fill={svgColors[0]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="232.444"
                                y="15.5557"
                                width="16"
                                height="40.7063"
                                fill={svgColors[1]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="250.444"
                                y="29.7175"
                                width="16"
                                height="26.5445"
                                fill={svgColors[2]}
                            />
                            <rect
                                className="animated__vertical-bar"
                                x="268.444"
                                y="36.7985"
                                width="16"
                                height="19.4635"
                                fill={svgColors[3]}
                            />
                        </>
                    );
            }
        }
        case 'stacked-bar': {
            switch (limitedCount) {
                case 0:
                case 1:
                case 2:
                    return (
                        <>
                            <rect
                                width="284"
                                height="50"
                                fill={svgColors[0]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="19.3878"
                                className="animated__stacked-bar"
                                fill={svgColors[1]}
                            />
                        </>
                    );
                case 3:
                    return (
                        <>
                            <rect
                                width="284"
                                height="50"
                                fill={svgColors[2]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="36"
                                fill={svgColors[1]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="20"
                                fill={svgColors[0]}
                                className="animated__stacked-bar"
                            />
                        </>
                    );
                case 4:
                    return (
                        <>
                            <rect
                                width="284"
                                height="50"
                                fill={svgColors[3]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="39"
                                fill={svgColors[2]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="25"
                                fill={svgColors[1]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="16"
                                fill={svgColors[0]}
                                className="animated__stacked-bar"
                            />
                        </>
                    );
                case 5:
                    return (
                        <>
                            <rect
                                width="283"
                                height="50"
                                fill={svgColors[4]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="44"
                                fill={svgColors[3]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="40"
                                fill={svgColors[2]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="28.9474"
                                fill={svgColors[1]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="6"
                                fill={svgColors[0]}
                                className="animated__stacked-bar"
                            />
                        </>
                    );
                default:
                    return (
                        <>
                            <rect
                                width="284"
                                height="50"
                                fill={svgColors[5]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="42"
                                fill={svgColors[4]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="38"
                                fill={svgColors[3]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="34"
                                fill={svgColors[2]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="18"
                                fill={svgColors[1]}
                                className="animated__stacked-bar"
                            />
                            <rect
                                width="284"
                                height="6"
                                fill={svgColors[0]}
                                className="animated__stacked-bar"
                            />
                        </>
                    );
            }
        }
        case 'stackedbar':
        case 'stackedbargrouped':
        case 'stacked-bar-grouped': {
            switch (limitedCount) {
                case 0:
                case 1:
                case 2:
                    return (
                        <g className="animated__stacked-bar">
                            <rect
                                width="70"
                                height="50"
                                transform="translate(0.902466 6.3782)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="17.2727"
                                transform="translate(0.902344 6.3782)"
                                fill={svgColors[0]}
                            />
                            <rect
                                width="70"
                                height="50"
                                transform="translate(107.902 6.75641)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="35"
                                transform="translate(107.902 6.3782)"
                                fill={svgColors[0]}
                            />
                            <rect
                                width="70"
                                height="50"
                                transform="translate(214.902 6.75641)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="24"
                                transform="translate(214.902 6.3782)"
                                fill={svgColors[0]}
                            />
                        </g>
                    );
                case 3:
                    return (
                        <g className="animated__stacked-bar">
                            <rect
                                width="70"
                                height="50"
                                transform="translate(0.902344 6)"
                                fill={svgColors[2]}
                            />
                            <rect
                                width="70"
                                height="24"
                                transform="translate(0.902344 6)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="10"
                                transform="translate(0.902344 6)"
                                fill={svgColors[0]}
                            />
                            <rect
                                width="70"
                                height="50"
                                transform="translate(107.902 6)"
                                fill={svgColors[2]}
                            />
                            <rect
                                width="70"
                                height="42"
                                transform="translate(107.902 6)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="6"
                                transform="translate(107.902 6)"
                                fill={svgColors[0]}
                            />
                            <rect
                                width="70"
                                height="51"
                                transform="translate(214.902 5)"
                                fill={svgColors[2]}
                            />
                            <rect
                                width="69.9997"
                                height="28.9474"
                                transform="translate(214.902 5)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="18"
                                transform="translate(214.902 5)"
                                fill={svgColors[0]}
                            />
                        </g>
                    );
                case 4:
                    return (
                        <g className="animated__stacked-bar">
                            <rect
                                width="70"
                                height="50"
                                transform="translate(0.902344 6)"
                                fill={svgColors[3]}
                            />
                            <rect
                                width="70"
                                height="30"
                                transform="translate(0.902466 6)"
                                fill={svgColors[2]}
                            />
                            <rect
                                width="70"
                                height="24"
                                transform="translate(0.902466 6)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="10"
                                transform="translate(0.902466 6)"
                                fill={svgColors[0]}
                            />
                            <rect
                                width="70"
                                height="50"
                                transform="translate(107.902 6)"
                                fill={svgColors[3]}
                            />
                            <rect
                                width="70"
                                height="34"
                                transform="translate(107.902 6)"
                                fill={svgColors[2]}
                            />
                            <rect
                                width="70"
                                height="18"
                                transform="translate(107.902 6)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="6"
                                transform="translate(107.902 6)"
                                fill={svgColors[0]}
                            />
                            <rect
                                width="70"
                                height="50"
                                transform="translate(214.902 6)"
                                fill={svgColors[3]}
                            />
                            <rect
                                width="70"
                                height="35"
                                transform="translate(214.902 6)"
                                fill={svgColors[2]}
                            />
                            <rect
                                width="70"
                                height="28.9474"
                                transform="translate(214.902 6)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="18"
                                transform="translate(214.902 6)"
                                fill={svgColors[0]}
                            />
                        </g>
                    );
                case 5:
                    return (
                        <g className="animated__stacked-bar">
                            <rect
                                width="70"
                                height="50"
                                transform="translate(0.902344 6)"
                                fill={svgColors[4]}
                            />
                            <rect
                                width="70"
                                height="38"
                                transform="translate(0.902344 6)"
                                fill={svgColors[3]}
                            />
                            <rect
                                width="70"
                                height="30"
                                transform="translate(0.902344 6)"
                                fill={svgColors[2]}
                            />
                            <rect
                                width="70"
                                height="24"
                                transform="translate(0.902344 6)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="18"
                                transform="translate(0.902344 6)"
                                fill={svgColors[0]}
                            />
                            <rect
                                width="70"
                                height="50"
                                transform="translate(107.902 6)"
                                fill={svgColors[4]}
                            />
                            <rect
                                width="70"
                                height="42"
                                transform="translate(107.902 6)"
                                fill={svgColors[3]}
                            />
                            <rect
                                width="70"
                                height="34"
                                transform="translate(107.902 6)"
                                fill={svgColors[2]}
                            />
                            <rect
                                width="70"
                                height="18"
                                transform="translate(107.902 6)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="6"
                                transform="translate(107.902 6)"
                                fill={svgColors[0]}
                            />
                            <rect
                                width="70"
                                height="50"
                                transform="translate(214.902 6)"
                                fill={svgColors[4]}
                            />
                            <rect
                                width="70"
                                height="38"
                                transform="translate(214.902 6)"
                                fill={svgColors[3]}
                            />
                            <rect
                                width="70"
                                height="25"
                                transform="translate(214.902 6)"
                                fill={svgColors[2]}
                            />
                            <rect
                                width="70"
                                height="14"
                                transform="translate(214.902 6)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="10"
                                transform="translate(214.902 6)"
                                fill={svgColors[0]}
                            />
                        </g>
                    );
                default:
                    return (
                        <g className="animated__stacked-bar">
                            <rect
                                width="70"
                                height="50"
                                transform="translate(0.902344 6.2637)"
                                fill={svgColors[5]}
                            />
                            <rect
                                width="70"
                                height="46"
                                transform="translate(0.902344 6.2637)"
                                fill={svgColors[4]}
                            />
                            <rect
                                width="70"
                                height="38"
                                transform="translate(0.902344 6.2637)"
                                fill={svgColors[3]}
                            />
                            <rect
                                width="70"
                                height="30"
                                transform="translate(0.902344 6.2637)"
                                fill={svgColors[2]}
                            />
                            <rect
                                width="70"
                                height="24"
                                transform="translate(0.902344 6.2637)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="10"
                                transform="translate(0.902344 6.2637)"
                                fill={svgColors[0]}
                            />
                            <rect
                                width="70"
                                height="50"
                                transform="translate(107.902 6.2637)"
                                fill={svgColors[5]}
                            />
                            <rect
                                width="70"
                                height="48"
                                transform="translate(107.902 6.2637)"
                                fill={svgColors[4]}
                            />
                            <rect
                                width="70"
                                height="41"
                                transform="translate(107.902 6.2637)"
                                fill={svgColors[3]}
                            />
                            <rect
                                width="70"
                                height="35"
                                transform="translate(107.902 6.2637)"
                                fill={svgColors[2]}
                            />
                            <rect
                                width="70"
                                height="13"
                                transform="translate(107.902 6.2637)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="4"
                                transform="translate(107.902 6.2637)"
                                fill={svgColors[0]}
                            />
                            <rect
                                width="70"
                                height="50"
                                transform="translate(214.902 6.2637)"
                                fill={svgColors[5]}
                            />
                            <rect
                                width="70"
                                height="43"
                                transform="translate(214.902 6.2637)"
                                fill={svgColors[4]}
                            />
                            <rect
                                width="70"
                                height="41"
                                transform="translate(214.902 6.2637)"
                                fill={svgColors[3]}
                            />
                            <rect
                                width="70"
                                height="37"
                                transform="translate(214.902 6.2637)"
                                fill={svgColors[2]}
                            />
                            <rect
                                width="70"
                                height="26"
                                transform="translate(214.902 6.2637)"
                                fill={svgColors[1]}
                            />
                            <rect
                                width="70"
                                height="21"
                                transform="translate(214.902 6.2637)"
                                fill={svgColors[0]}
                            />
                        </g>
                    );
            }
        }
        case 'metric':
        case 'stats': {
            switch (limitedCount) {
                case 0:
                case 1:
                    return (
                        <g>
                            <rect
                                width="29"
                                height="3"
                                fill="var(--color-ui-50)"
                                fill-opacity="0.2"
                                className="animated__horizontal-bar"
                            />
                            <path
                                d="M8.36 49L1.1 38.88H4.84L10.34 46.58L15.84 38.88H19.58L12.32 49L20.24 60H16.5L10.34 51.42L4.18 60H0.44L8.36 49ZM24.8806 38.88L31.7006 56.04L38.5206 38.88H41.8206L32.1406 63.08C31.7006 64.1947 31.246 65.1333 30.7766 65.896C30.3366 66.688 29.8526 67.3187 29.3246 67.788C28.7966 68.2867 28.2246 68.6533 27.6086 68.888C26.9926 69.1227 26.3033 69.24 25.5406 69.24H22.9006V66.38H25.1006C26.098 66.38 26.9046 66.1013 27.5206 65.544C28.1366 65.016 28.65 64.1947 29.0606 63.08L30.1606 60L21.5806 38.88H24.8806ZM60.7509 41.74L47.9909 57.14H61.1909V60H44.2509V57.14L57.0109 41.74H44.6909V38.88H60.7509V41.74Z"
                                fill={svgColors[0]}
                                className="animated__metric"
                            />
                        </g>
                    );
                case 2:
                    return (
                        <g>
                            <rect
                                y="0.263702"
                                width="29"
                                height="3"
                                fill="var(--color-ui-50)"
                                fill-opacity="0.2"
                                className="animated__horizontal-bar"
                            />
                            <rect
                                x="140"
                                y="0.263702"
                                width="42"
                                height="3"
                                fill="var(--color-ui-50)"
                                fill-opacity="0.2"
                                className="animated__horizontal-bar"
                            />
                            <path
                                d="M8.36 49.2637L1.1 39.1437H4.84L10.34 46.8437L15.84 39.1437H19.58L12.32 49.2637L20.24 60.2637H16.5L10.34 51.6837L4.18 60.2637H0.44L8.36 49.2637Z"
                                fill={svgColors[0]}
                                className="animated__metric"
                            />
                            <path
                                d="M150.74 39.1437L157.56 56.3037L164.38 39.1437H167.68L158 63.3437C157.56 64.4584 157.105 65.397 156.636 66.1597C156.196 66.9517 155.712 67.5824 155.184 68.0517C154.656 68.5504 154.084 68.917 153.468 69.1517C152.852 69.3864 152.163 69.5037 151.4 69.5037H148.76V66.6437H150.96C151.957 66.6437 152.764 66.365 153.38 65.8077C153.996 65.2797 154.509 64.4584 154.92 63.3437L156.02 60.2637L147.44 39.1437H150.74Z"
                                fill={svgColors[1]}
                                className="animated__metric"
                            />
                        </g>
                    );
                default:
                    return (
                        <g>
                            <rect
                                width="29"
                                height="3"
                                fill="var(--color-ui-50)"
                                fill-opacity="0.2"
                                className="animated__horizontal-bar"
                            />
                            <rect
                                x="99"
                                width="42"
                                height="3"
                                fill="var(--color-ui-50)"
                                fill-opacity="0.2"
                                className="animated__horizontal-bar"
                            />
                            <rect
                                x="197"
                                width="33"
                                height="3"
                                fill="var(--color-ui-50)"
                                fill-opacity="0.2"
                                className="animated__horizontal-bar"
                            />
                            <path
                                d="M8.36 49L1.1 38.88H4.84L10.34 46.58L15.84 38.88H19.58L12.32 49L20.24 60H16.5L10.34 51.42L4.18 60H0.44L8.36 49Z"
                                fill={svgColors[0]}
                                className="animated__metric"
                            />
                            <path
                                d="M101.74 38.88L108.56 56.04L115.38 38.88H118.68L109 63.08C108.56 64.1947 108.105 65.1333 107.636 65.896C107.196 66.688 106.712 67.3187 106.184 67.788C105.656 68.2867 105.084 68.6533 104.468 68.888C103.852 69.1227 103.163 69.24 102.4 69.24H99.76V66.38H101.96C102.957 66.38 103.764 66.1013 104.38 65.544C104.996 65.016 105.509 64.1947 105.92 63.08L107.02 60L98.44 38.88H101.74Z"
                                fill={svgColors[1]}
                                className="animated__metric"
                            />
                            <path
                                d="M214.04 41.74L201.28 57.14H214.48V60H197.54V57.14L210.3 41.74H197.98V38.88H214.04V41.74Z"
                                fill={svgColors[2]}
                                className="animated__metric"
                            />
                        </g>
                    );
            }
        }
        case 'funnel':
        case 'funnel-center': {
            switch (limitedCount) {
                case 0:
                case 1:
                    return (
                        <g className="animated__horizontal-bar--centered">
                            <rect width="284" height="15" fill={svgColors[0]} />
                            <rect
                                x="52"
                                y="21"
                                width="180"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="108"
                                y="42"
                                width="68"
                                height="15"
                                fill={svgColors[0]}
                            />
                        </g>
                    );

                case 2:
                    return (
                        <g className="animated__horizontal-bar--centered">
                            <rect width="284" height="15" fill={svgColors[0]} />
                            <rect
                                x="198.632"
                                width="85.3677"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="52"
                                y="21"
                                width="180"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="137.764"
                                y="21"
                                width="94.2363"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="108"
                                y="42"
                                width="68"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="153.293"
                                y="42"
                                width="22.707"
                                height="15"
                                fill={svgColors[1]}
                            />
                        </g>
                    );
                case 3:
                    return (
                        <g className="animated__horizontal-bar--centered">
                            <rect width="284" height="15" fill={svgColors[0]} />
                            <rect
                                x="99.9692"
                                width="184.031"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="184.031"
                                width="99.9692"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="52"
                                y="21"
                                width="180"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="115.303"
                                y="21"
                                width="116.697"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="169.496"
                                y="21"
                                width="62.5039"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="108"
                                y="42"
                                width="68"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="130"
                                y="42"
                                width="46"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="154.373"
                                y="42"
                                width="21.6274"
                                height="15"
                                fill={svgColors[2]}
                            />
                        </g>
                    );
                case 4:
                    return (
                        <g className="animated__horizontal-bar--centered">
                            <rect width="284" height="15" fill={svgColors[0]} />
                            <rect
                                x="64.4707"
                                width="219.529"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="126.189"
                                width="157.811"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="186.51"
                                width="97.4897"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="52"
                                y="21"
                                width="180"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="109"
                                y="21"
                                width="123"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="136.328"
                                y="21"
                                width="95.6719"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="174.538"
                                y="21"
                                width="57.4624"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="108"
                                y="42"
                                width="68"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="130"
                                y="42"
                                width="46"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="140.813"
                                y="42"
                                width="35.187"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="156.217"
                                y="42"
                                width="19.7832"
                                height="15"
                                fill={svgColors[3]}
                            />
                        </g>
                    );
                case 5:
                    return (
                        <g className="animated__horizontal-bar--centered">
                            <rect width="284" height="15" fill={svgColors[0]} />
                            <rect
                                x="90"
                                width="194"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="124.545"
                                width="159.455"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="159.414"
                                width="124.586"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="233.982"
                                width="50.0181"
                                height="15"
                                fill={svgColors[4]}
                            />
                            <rect
                                x="52"
                                y="21"
                                width="180"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="109"
                                y="21"
                                width="123"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="136"
                                y="21"
                                width="96"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="148"
                                y="21"
                                width="84"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="206.255"
                                y="21"
                                width="25.7451"
                                height="15"
                                fill={svgColors[4]}
                            />
                            <rect
                                x="108"
                                y="42"
                                width="68"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="130"
                                y="42"
                                width="46"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="140"
                                y="42"
                                width="36"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="144"
                                y="42"
                                width="32"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="169.076"
                                y="42"
                                width="6.92383"
                                height="15"
                                fill={svgColors[4]}
                            />
                        </g>
                    );
                default:
                    return (
                        <g className="animated__horizontal-bar--centered">
                            <rect width="284" height="15" fill={svgColors[0]} />
                            <rect
                                x="90"
                                width="194"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="133"
                                width="151"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="151"
                                width="133"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="219"
                                width="65"
                                height="15"
                                fill={svgColors[4]}
                            />
                            <rect
                                x="262"
                                width="22"
                                height="15"
                                fill={svgColors[5]}
                            />
                            <rect
                                x="52"
                                y="21"
                                width="57"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="109"
                                y="21"
                                width="123"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="136"
                                y="21"
                                width="96"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="148"
                                y="21"
                                width="84"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="191"
                                y="21"
                                width="41"
                                height="15"
                                fill={svgColors[4]}
                            />
                            <rect
                                x="218"
                                y="21"
                                width="14"
                                height="15"
                                fill={svgColors[5]}
                            />
                            <rect
                                x="130"
                                y="42"
                                width="46"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="108"
                                y="42"
                                width="22"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="140"
                                y="42"
                                width="36"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="144"
                                y="42"
                                width="32"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="160"
                                y="42"
                                width="16"
                                height="15"
                                fill={svgColors[4]}
                            />
                            <rect
                                x="171"
                                y="42"
                                width="5"
                                height="15"
                                fill={svgColors[5]}
                            />
                        </g>
                    );
            }
        }
        case 'funnel-left': {
            switch (limitedCount) {
                case 0:
                case 1:
                    return (
                        <g className="animated__horizontal-bar">
                            <rect width="284" height="15" fill={svgColors[0]} />
                            <rect
                                y="21"
                                width="180"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                y="42"
                                width="68"
                                height="15"
                                fill={svgColors[0]}
                            />
                        </g>
                    );
                case 2:
                    return (
                        <g className="animated__horizontal-bar">
                            <rect width="284" height="15" fill={svgColors[0]} />
                            <rect
                                x="198.632"
                                width="85.3677"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                y="21"
                                width="180"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="85.7637"
                                y="21"
                                width="94.2363"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                y="42"
                                width="68"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="45.293"
                                y="42"
                                width="22.707"
                                height="15"
                                fill={svgColors[1]}
                            />
                        </g>
                    );
                case 3:
                    return (
                        <g className="animated__horizontal-bar">
                            <rect width="284" height="15" fill={svgColors[0]} />
                            <rect
                                x="99.9692"
                                width="184.031"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="184.031"
                                width="99.9692"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                y="21"
                                width="180"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="63.3032"
                                y="21"
                                width="116.697"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="117.496"
                                y="21"
                                width="62.5039"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                y="42"
                                width="68"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="22"
                                y="42"
                                width="46"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="46.3726"
                                y="42"
                                width="21.6274"
                                height="15"
                                fill={svgColors[2]}
                            />
                        </g>
                    );
                case 4:
                    return (
                        <g className="animated__horizontal-bar">
                            <rect width="284" height="15" fill={svgColors[0]} />
                            <rect
                                x="64.4707"
                                width="219.529"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="126.189"
                                width="157.811"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="186.51"
                                width="97.4897"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                y="21"
                                width="180"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="57"
                                y="21"
                                width="123"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="84.3281"
                                y="21"
                                width="95.6719"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="122.538"
                                y="21"
                                width="57.4624"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                y="42"
                                width="68"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="22"
                                y="42"
                                width="46"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="32.813"
                                y="42"
                                width="35.187"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="48.2168"
                                y="42"
                                width="19.7832"
                                height="15"
                                fill={svgColors[3]}
                            />
                        </g>
                    );
                case 5:
                    return (
                        <g className="animated__horizontal-bar">
                            <rect width="284" height="15" fill={svgColors[0]} />
                            <rect
                                x="90"
                                width="194"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="124.545"
                                width="159.455"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="159.414"
                                width="124.586"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="233.982"
                                width="50.0181"
                                height="15"
                                fill={svgColors[4]}
                            />
                            <rect
                                y="21"
                                width="180"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="57"
                                y="21"
                                width="123"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="84"
                                y="21"
                                width="96"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="96"
                                y="21"
                                width="84"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="154.255"
                                y="21"
                                width="25.7451"
                                height="15"
                                fill={svgColors[4]}
                            />
                            <rect
                                y="42"
                                width="68"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="22"
                                y="42"
                                width="46"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="32"
                                y="42"
                                width="36"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="39.5688"
                                y="42"
                                width="28.4312"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="55.7852"
                                y="42"
                                width="12.2148"
                                height="15"
                                fill={svgColors[4]}
                            />
                        </g>
                    );
                default:
                    return (
                        <g className="animated__horizontal-bar">
                            <rect width="284" height="15" fill={svgColors[0]} />
                            <rect
                                x="90"
                                width="194"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="133"
                                width="151"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="151"
                                width="133"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="219"
                                width="65"
                                height="15"
                                fill={svgColors[4]}
                            />
                            <rect
                                x="262"
                                width="22"
                                height="15"
                                fill={svgColors[5]}
                            />
                            <rect
                                y="21"
                                width="57"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="57"
                                y="21"
                                width="123"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                x="84"
                                y="21"
                                width="96"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="96"
                                y="21"
                                width="84"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="139"
                                y="21"
                                width="41"
                                height="15"
                                fill={svgColors[4]}
                            />
                            <rect
                                x="166"
                                y="21"
                                width="14"
                                height="15"
                                fill={svgColors[5]}
                            />
                            <rect
                                x="22"
                                y="42"
                                width="46"
                                height="15"
                                fill={svgColors[1]}
                            />
                            <rect
                                y="42"
                                width="22"
                                height="15"
                                fill={svgColors[0]}
                            />
                            <rect
                                x="32"
                                y="42"
                                width="36"
                                height="15"
                                fill={svgColors[2]}
                            />
                            <rect
                                x="36"
                                y="42"
                                width="32"
                                height="15"
                                fill={svgColors[3]}
                            />
                            <rect
                                x="52"
                                y="42"
                                width="16"
                                height="15"
                                fill={svgColors[4]}
                            />
                            <rect
                                x="63"
                                y="42"
                                width="5"
                                height="15"
                                fill={svgColors[5]}
                            />
                        </g>
                    );
            }
        }
        default:
            return null;
    }
};

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import './styles.scss';
import Login from './Login';
import Password from './Password';
import ForgotPassword from './ForgotPassword';
import Accounts from './Accounts';
import Welcome from './Welcome';
import IEBar from '../common/IEBar';
import MessageBar from '../common/MessageBar';
import AuthLayoutLogo from '../icons/AuthLayoutLogo';

export default function Layout() {
    return (
        <div className="auth-layout">
            <IEBar />
            <div className="auth-layout__logo">
                <AuthLayoutLogo />
            </div>

            <div className="auth-layout__footer">
                <div>&copy; 2023 Dandi Technologies, Inc.</div>
                <div>
                    <a
                        className="link link--bright"
                        href="https://www.itsdandi.com/privacy"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Privacy Policy
                    </a>
                    &nbsp;and&nbsp;
                    <a
                        className="link link--bright"
                        href="https://www.itsdandi.com/terms"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        Terms of Service
                    </a>
                </div>
            </div>
            <Switch>
                <Route path="/login" component={Login} />
                <Route path="/password" component={Password} />
                <Route path="/forgot" component={ForgotPassword} />
                <Route path="/welcome" component={Welcome} />
                <Route path="/accounts" component={Accounts} />
            </Switch>
            <MessageBar />
        </div>
    );
}

import classNames from 'classnames';
import { forwardRef } from 'react';
import { IChartLegendProps } from '../lib/getChartDeclaration';

export type Ref = HTMLDivElement;

const Legend = forwardRef<Ref, IChartLegendProps>((props, ref) => {
    const {
        length,
        lineWidth,
        multiLine,
        data,
        onHover,
        onHoverEnd,
        onToggle,
        hoveredItem,
        selection = [],
        status
    } = props;

    const type = 'default';

    const filteredData = data.filter(
        (d: any) => !d.isEqualityIndex && !d.isAttritionIndex
    );
    if (filteredData.length <= 0) return null;

    return (
        <div
            className={classNames('legend', `legend--${type}`, {
                [`legend--${type}--multi-line`]: multiLine
            })}
            ref={ref}
        >
            <div
                className={classNames('legend__inner')}
                style={
                    multiLine && length > 2 ? { width: lineWidth } : undefined
                }
            >
                {filteredData.map((item: any) => (
                    <div
                        className={classNames(`legend--${type}__item`, {
                            [`legend--${type}__item--inactive`]:
                                status !== 'idle' &&
                                !selection.includes(item.label) &&
                                hoveredItem !== item.label
                        })}
                        key={item.label}
                        onMouseOver={() => onHover(item.label)}
                        onMouseOut={() => onHoverEnd(item.label)}
                        onClick={e => {
                            e.stopPropagation();
                            onToggle(item.label);
                        }}
                    >
                        <div
                            className={`legend--${type}__circle`}
                            style={{ backgroundColor: item.color }}
                        />
                        <div
                            className={classNames(`legend--${type}__label`, {
                                [`legend--${type}__label--active`]:
                                    status !== 'idle' &&
                                    (hoveredItem === item.label ||
                                        selection.includes(item.label))
                            })}
                        >
                            {item.segment && item.segment.text}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
});

export default Legend;

import produce from 'immer';
import debounce from 'lodash/debounce';
import orderBy from 'lodash/orderBy';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import LottieAnimation from '../../common/LottieAnimation';
import AnalysisTypes from '../../constants/AnalysisTypes';
import GridConstants from '../../constants/GridConstants';
import Table from '../../editor/chart/Table';
import getPeriod from '../../lib/getPeriod';
import Loader from '../../lottie/graph-loader.json';
import {
    clearActiveLabel,
    navigate,
    setActiveLabel,
    toggleLabel
} from './actions';

const defaultSortCriteria = {
    columnName: 'text',
    ascending: true
};

function TableChart({
    data,
    lifecycle,
    currentIndex,
    generatePending,
    generated,
    generateError,
    generateNoData,
    generateInvalidVariation,
    dates,
    interval,
    analysisType,
    navigate,
    memberSince,
    setActiveLabel,
    clearActiveLabel,
    toggleLabel,
    role,
    columnProps
}) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [tableData, setTableData] = useState(data);
    const [sortCriteria, setSortCriteria] = useState(defaultSortCriteria);

    const chartComp = useRef();

    useEffect(() => {
        if (chartComp.current && generated && !generatePending) {
            setWidth(chartComp.current.offsetWidth);
            setHeight(chartComp.current.offsetHeight + 16);
            const handleResize = debounce(handleResizeDebounced, 100);
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, [tableData]);

    useEffect(() => {
        let tableData = produce(data, draftState => {
            draftState.forEach(dataItem => {
                dataItem.series.forEach(seriesItem => {
                    const { date, ...fields } = seriesItem;
                    const label = getPeriod(date, interval.toLowerCase());
                    const prefix = label.toLowerCase().replace(/ /g, '-');
                    Object.keys(fields).forEach(field => {
                        dataItem[prefix + '-' + field] = fields[field];
                    });
                });
            });
        });
        let removedItems;
        if (
            analysisType === AnalysisTypes.Index ||
            analysisType === AnalysisTypes.LinearRegression
        ) {
            const index = tableData.findIndex(
                d => d.isEqualityIndex || d.isAttritionIndex
            );
            if (index !== -1) {
                tableData = tableData.slice();
                removedItems = tableData.splice(index, 1);
            }
        }
        tableData = orderBy(
            tableData,
            [sortCriteria.columnName],
            [sortCriteria.ascending ? 'asc' : 'desc']
        );
        if (removedItems) {
            tableData.unshift(...removedItems);
        }
        setTableData(tableData);
    }, [data, sortCriteria]);

    function handleSort(columnName) {
        let ascending = true;
        if (columnName === sortCriteria.columnName) {
            ascending = !sortCriteria.ascending;
        }
        setSortCriteria({
            columnName,
            ascending
        });
    }

    function handleResizeDebounced() {
        if (chartComp.current) {
            setWidth(chartComp.current.offsetWidth);
        }
    }

    function renderChart() {
        return (
            <Table
                width={width}
                data={tableData}
                dates={dates}
                interval={interval}
                lifecycle={lifecycle}
                analysisType={analysisType}
                currentIndex={currentIndex}
                pending={generatePending}
                tableRowHeight={GridConstants.TableRowHeight}
                options={{ showSinglePeriod: true }}
                navigate={navigate}
                memberSince={memberSince}
                setActiveLabel={setActiveLabel}
                clearActiveLabel={clearActiveLabel}
                toggleLabel={toggleLabel}
                sortCriteria={sortCriteria}
                onSort={handleSort}
                role={role}
                columnProps={columnProps}
            />
        );
    }

    if (
        !generated ||
        generateError ||
        generateNoData ||
        generateInvalidVariation
    ) {
        return null;
    }

    return (
        <div
            className={
                generatePending
                    ? 'explore-chart explore-chart--table-pending'
                    : 'explore-chart explore-chart--table'
            }
        >
            <div
                className={
                    generatePending
                        ? 'explore-chart__table explore-chart__table--pending'
                        : 'explore-chart__table'
                }
                ref={chartComp}
                style={generatePending ? { height } : {}}
            >
                {generatePending ? (
                    <LottieAnimation
                        className="explore-chart__loader explore-chart__loader--table"
                        autoplay={true}
                        loop={true}
                        width={60}
                        height={60}
                        animation={Loader}
                    ></LottieAnimation>
                ) : (
                    renderChart()
                )}
            </div>
        </div>
    );
}

TableChart.propTypes = {
    data: PropTypes.array,
    lifecycle: PropTypes.string,
    currentIndex: PropTypes.number,
    setActiveLabel: PropTypes.func,
    clearActiveLabel: PropTypes.func,
    navigate: PropTypes.func,
    selectCurrentTime: PropTypes.func,
    generatePending: PropTypes.bool,
    generated: PropTypes.bool,
    generateError: PropTypes.bool,
    generateNoData: PropTypes.bool,
    generateInvalidVariation: PropTypes.bool,
    interval: PropTypes.string,
    dates: PropTypes.array,
    enterpriseName: PropTypes.string,
    memberSince: PropTypes.string,
    analysisType: PropTypes.string,
    toggleLabel: PropTypes.func,
    role: PropTypes.string,
    analysisMetaFieldsDisplayName: PropTypes.object
};

function mapState(state) {
    return {
        interval: state.explore.filter.interval,
        memberSince: state.account.memberSince,
        ...state.explore.chart,
        role: state.auth.role
    };
}

const dispatchProps = {
    navigate,
    setActiveLabel,
    clearActiveLabel,
    toggleLabel
};

export default connect(mapState, dispatchProps)(TableChart);

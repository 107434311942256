import { DraggableSyntheticListeners, useDraggable } from '@dnd-kit/core';
import { createContext, useMemo } from 'react';

interface Context {
    attributes: Record<string, any>;
    listeners: DraggableSyntheticListeners;
    ref(node: HTMLElement | null): void;
}

export const ColumnContext = createContext<Context>({
    attributes: {},
    listeners: undefined,
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    ref() {}
});

export default function Draggable(props: any) {
    const Element = props.element || 'div';
    const { attributes, listeners, setNodeRef, setActivatorNodeRef } =
        useDraggable({
            id: props.id
        });

    const context = useMemo(
        () => ({
            attributes,
            listeners,
            ref: setActivatorNodeRef
        }),
        [attributes, listeners, setActivatorNodeRef]
    );

    return (
        <ColumnContext.Provider value={context}>
            <Element ref={setNodeRef} style={{ height: '100%' }}>
                {props.children}
            </Element>
        </ColumnContext.Provider>
    );
}

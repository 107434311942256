import gsap from 'gsap';
import Scrollbar from 'perfect-scrollbar';
import { memo, PropsWithChildren, useEffect, useRef, useState } from 'react';
import Markdown from 'react-markdown';
import { useParams } from 'react-router';

import AiAvatar from '../common/AiAvatar';
import MessageArea from '../common/MessageArea';
import ArrowIcon from '../icons/Arrow';
import UserAvatarIcon from '../icons/UserAvatar';
import cn from '../lib/cn';
// import firebase from '../lib/firebase';
import Feedback from '../common/Feedback';
import getAvatarFill from '../lib/getAvatarFill';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { ChatMessage, ChatMessageRoles } from './aiTypes';
import { invokeChat } from './chat/actions';
import LeftPanel from './ConversationsList';
import './styles.scss';
import useChat from './useChat';

const TEXT_AREA_ROWS = 3;
const ERROR_RESPONSE =
    'We apologize, but there is an internal issue preventing us from displaying your AI-generated response at the moment. Please try again and if the issue persists, feel free to contact our support team for assistance.';

export default function AiPage() {
    return (
        <div
            className={cn('w-full grid grid-cols-12 gap-4 px-8 relative overflow-hidden')}
            style={{
                '--header-height': '60px',
                background: 'var(--color-shade-h1)',
                height: 'calc(100vh - var(--header-height))',
            }}
        >
            <LeftPanel />
            <ChatWindow />
        </div>
    );
}

const ChatWindow = ({}) => {
    useChat();
    const comp = useRef(null);

    useEffect(() => {
        if (comp.current) {
            let ps = new Scrollbar(comp.current);

            return () => {
                ps.destroy();
            };
        }
    }, [comp]);

    return (
        <div className={cn('col-[5/-1] h-full relative flex flex-col justify-between overflow-hidden')}>
            <div
                className={cn('absolute top-0 right-0 bottom-0 left-0 overflow-y-auto pt-[60px] pb-[160px]')}
                ref={comp}
            >
                <div className={cn('flex-1 w-[75%]')}>
                    <ChatContainer />
                </div>
            </div>
            <ChatBottom />
        </div>
    );
};

const ChatContainer = () => {
    const { conversationId } = useParams<any>();

    const newChat = !conversationId;
    return <div className={cn('flex flex-col  p-[16px]')}>{newChat ? <NewChat /> : <MessageContainer />}</div>;
};

const NewChat = () => {
    const { firstName } = useAppSelector((state) => state.auth.userInfo);
    // const [prompts, setPrompts] = useState([]);

    const PROMPTS = [
        { question: 'What is the total headcount?' },
        { question: 'What is the employee distribution by ethnicity?' },
        { question: 'How many employees did we hire last month?' },
        { question: 'Which departments did we hire employees in last month?' },
        {
            question: 'Which location had the highest retention over the last year and what was the retention rate?',
        },
        {
            question:
                'Which department has the highest attrition rate over the past year and what was the attrition rate?',
        },
    ];

    // const getPrompts = async () => {
    //   firebase
    //     .storage()
    //     .ref()
    //     .child(`ai/chat/default_chat_prompts.json`)
    //     .getDownloadURL()
    //     .then((url: string) => {
    //       console.log(url);
    //       fetch(url, { mode: 'cors' })
    //         .then(res => res.json())
    //         .then(data => setPrompts(data));
    //     })
    //     .catch((err: any) => console.log(err));
    // };
    //
    // useEffect(() => {
    //   if (!prompts.length) getPrompts();
    // }, [prompts]);

    return (
        <div className={cn('vertical ')}>
            <h1 className={cn('font-display text-ui-100 text-[5.4rem] mb-[72px]')}>Hi {firstName}!</h1>
            <div className={cn('px-[16px]')}>
                <AIPlainMessage>
                    <p>{`Welcome to Dandi AI. Please note that Dandi AI is currently in preview mode. To get started, choose a sample or enter your own question about your people data below.`}</p>
                    <p>{`After Dandi responds, feel free to ask follow up questions and continue the conversation. To start a fresh chat, click on New Chat at the top left of this page.`}</p>
                </AIPlainMessage>
                <div className={cn('grid grid-cols-3 gap-8')}>
                    {PROMPTS.map((prompt, index) => (
                        <PromptSuggestion key={index} prompt={prompt.question} />
                    ))}
                </div>
            </div>
        </div>
    );
};

const PromptSuggestion = ({ prompt }: any) => {
    const { conversationId } = useParams<any>();
    const dispatch = useAppDispatch();
    const { pending } = useAppSelector((state) => state.ai.chat);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!pending) {
            dispatch(invokeChat({ prompt: prompt, conversationId }));
        }
    };

    return (
        <div
            className={cn(
                'group p-8 rounded-[2rem] bg-shade-3 text-ui-75',
                'hover:bg-shade-4 hover:text-ui-100',
                'cursor-pointer',
                'vertical justify-between gap-[2rem]',
                'text-[1.6rem]',
                'transition-all duration-300 ease-in-out'
            )}
            onClick={handleSubmit}
        >
            <div> {prompt}</div>
            <ArrowIcon className={cn('-rotate-45 origin-center self-end text-ui-30 group-hover:text-ui-100')} />
        </div>
    );
};

const MessageContainer = () => {
    const { messages, chatResponsePending, chatResponseLoaded, chatResponseError } = useAppSelector(
        (state) => state.ai.chat
    );

    const getMessagesSplit = () => {
        if (!chatResponseLoaded && !chatResponsePending && !chatResponseError) {
            return [messages, []];
        } else {
        }
        let lastUserMessageIndex = -1;

        messages.forEach((message, index) => {
            if (message.role === ChatMessageRoles.USER) {
                lastUserMessageIndex = index;
            }
        });

        if (lastUserMessageIndex === -1) {
            return [messages, []];
        }

        const historyMessages = messages.slice(0, lastUserMessageIndex);
        const lastMessages = messages.slice(lastUserMessageIndex, messages.length);

        return [historyMessages, lastMessages];
    };

    const [historyMessages, lastMessages] = getMessagesSplit();

    return (
        <div className={cn('vertical gap-[1.6rem]')}>
            {historyMessages &&
                historyMessages.map((message, index) => {
                    if (message.role === ChatMessageRoles.USER) return <UserMessage message={message} key={index} />;
                    if (message.role === ChatMessageRoles.ASSISTANT)
                        return <AIMessage message={message} key={index} idx={index} />;
                })}
            {lastMessages.length > 0 && (
                <LastMessage
                    chatResponseLoaded={chatResponseLoaded}
                    chatResponsePending={chatResponsePending}
                    chatResponseError={chatResponseError}
                    idx={messages.length - 1}
                    lastMessages={lastMessages}
                />
            )}
        </div>
    );
};

const LastMessage = ({
    chatResponseError,
    chatResponseLoaded,
    chatResponsePending,
    idx,
    lastMessages,
}: {
    chatResponseError?: string;
    chatResponseLoaded: boolean;
    chatResponsePending: boolean;
    idx: number;
    lastMessages: ChatMessage[];
}) => {
    const scrollToRef = useRef<any>();
    const loaded = chatResponseLoaded && lastMessages?.[1];

    useEffect(() => {
        if (scrollToRef.current) {
            scrollToRef.current.scrollIntoView({
                behavior: 'smooth',
            });
        }
    }, [lastMessages]);

    return (
        <div
            ref={scrollToRef}
            className={cn('vertical gap-[1.6rem]')}
            style={{
                minHeight: 'calc(100vh - 280px)',
            }}
        >
            <UserMessage message={lastMessages[0]} />
            {chatResponsePending && (
                <div className={cn('py-[3.2rem]')}>
                    <AIPlainMessage>
                        <LoadingSkeleton />
                    </AIPlainMessage>
                </div>
            )}
            {loaded && <AIMessage message={lastMessages[1]} idx={idx} />}
            {chatResponseError && <ErrorMessage message={ERROR_RESPONSE} />}
        </div>
    );
};

const UserMessage = ({ message }: { message: ChatMessage }) => {
    const { email, lightMode } = useAppSelector((state) => state.auth.userInfo);
    const colorCode = getAvatarFill(email || '');

    return (
        <div
            className={cn(
                'w-fit pl-[2rem] pr-[2.4rem] py-[1rem] rounded-[1rem] self-end relative text-[1.6rem] z-auto '
            )}
            style={{
                '--bg': `var(--color-graph-${colorCode})`,
                '--overlayBg': lightMode ? 'rgba(255, 255, 255, 0.8)' : 'rgba(0, 0, 0, 0.8)',
                background: `linear-gradient(to right, var(--overlayBg), var(--overlayBg)), linear-gradient(to right, var(--bg), var(--bg))`,
            }}
        >
            <UserAvatarIcon
                className={cn('absolute top-[-16px] right-[-16px] w-[32px] h-[32px] z-[3]')}
                style={{
                    color: `var(--color-graph-${colorCode})`,
                }}
            />
            <span className={cn('relative z-[3]')}>{message?.content}</span>
        </div>
    );
};

const ErrorMessage = ({ message }: { message: string }) => {
    return (
        <div className={cn('py-[3.2rem] ')}>
            <AIPlainMessage>
                <PlainText>{message}</PlainText>
            </AIPlainMessage>
        </div>
    );
};

const AIMessage = ({ message, idx }: { message: ChatMessage; idx: number }) => {
    return (
        <div className={cn('py-[3.2rem] ')}>
            <AIPlainMessage>
                <PlainText>{message.content}</PlainText>
            </AIPlainMessage>
            <ActionsBar>
                <Feedback isChat={true} messageIdx={idx} feedback={message.feedback} />
            </ActionsBar>
        </div>
    );
};

const AIPlainMessage = ({ children }: PropsWithChildren) => {
    return (
        <div className={cn('relative text-[1.6rem] w-fit px-[2rem] py-[1rem]')}>
            <div className={cn('absolute top-[-16px] left-[-16px]')}>
                <AiAvatar size={32} />
            </div>
            {children}
        </div>
    );
};

const ActionsBar = ({ children }: PropsWithChildren) => {
    return <div className={cn('flex items-center justify-between py-[1rem]')}>{children}</div>;
};

const ChatBottom = () => {
    return (
        <div
            className={cn(
                'pb-[32px] flex justify-center items-center absolute bottom-0 left-0 w-[75%] z-[100] bg-shade-h1',
                'after:[content-""] after:absolute after:top-[-80px] after:left-0 after:w-full after:h-[80px] after:z-[1]',
                'after:bg-gradient-to-t after:from-shade-h1'
            )}
        >
            <AiTextField />
        </div>
    );
};

const AiTextField = () => {
    const { conversationId } = useParams<any>();
    const dispatch = useAppDispatch();
    const [value, setValue] = useState('');
    const { pending } = useAppSelector((state) => state.ai.chat);

    const handleSubmit = async (e: any) => {
        e.preventDefault();

        if (!pending) {
            dispatch(invokeChat({ prompt: value, conversationId }));

            setValue('');
        }
    };

    return (
        <div className={cn('w-full px-[16px] flex flex-col items-center relative z-[10]')}>
            <form className={cn('w-full flex items-center gap-2')} onSubmit={handleSubmit}>
                <MessageArea
                    onSubmit={handleSubmit}
                    onChange={(e) => setValue(e.target.value)}
                    placeholder="Enter a prompt for Dandi AI"
                    value={value}
                    rows={TEXT_AREA_ROWS}
                    autoFocus
                />
                <button
                    type="submit"
                    className="absolute top-[15px] right-[36px] rounded-full"
                    disabled={value.trim() === ''}
                >
                    <ArrowIcon
                        className={cn('-rotate-45 origin-center self-end text-ui-30 group-hover:text-ui-100')}
                        size={24}
                    />
                </button>
            </form>
        </div>
    );
};

const PlainText = memo(({ children }: { children: any }) => {
    useEffect(() => {
        const ctx = gsap.context(() => {
            const tl = gsap.timeline({});

            tl.fromTo(
                '.animate-insights',
                {
                    autoAlpha: 0,
                },
                {
                    autoAlpha: 1,
                    duration: 1,
                    stagger: 0.175,
                    ease: 'power1.out',
                }
            );
        });

        return () => {
            ctx.revert();
        };
    }, []);

    return (
        <Markdown
            components={{
                h2: ({ node, children, ...props }) => (
                    <p {...props} className={cn('mb-[.8rem] animate-insights invisible')}>
                        {children}
                    </p>
                ),
                strong: ({ node, ...props }) => <span className={cn('')} {...props} />,
                ol: ({ node, ...props }) => <span className={cn('mb-[.8rem] ')}>{props.children}</span>,
                li: ({ node, ...props }) => (
                    <p className={cn('mb-[.8rem] max-w-prose animate-insights invisible')}>{props.children}</p>
                ),
            }}
        >
            {children}
        </Markdown>
    );
});

const LoadingSkeleton = () => {
    return (
        <div className={cn('flex flex-col gap-[.8rem] items-start w-full')}>
            <SkeletonLine className="w-full" />
            <SkeletonLine className="w-[60%]" delay={150} />
            <SkeletonLine className="w-[80%]" delay={300} />
        </div>
    );
};

const SkeletonLine = ({ className, delay }: { className?: string; delay?: number }) => {
    return (
        <div
            className={cn('h-[10px] rounded-[.4rem] origin-left animate-skeleton scale-x-0', className)}
            style={{
                backgroundImage:
                    'linear-gradient(270deg,  var(--color-ui-10) 0%, var(--color-ui-10) 30%, var(--color-ui-30) 50%,  var(--color-ui-10) 70%,  var(--color-ui-10) 100%)',
                backgroundSize: '200% 100%',
                animationDelay: `${delay || 0}ms`,
            }}
        />
    );
};

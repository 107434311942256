import Filters from './Filters/Filters';
interface AnalysisPermissionProps {
    index: number;
    editMode?: boolean;
}

const AnalysisPermission = ({ index, editMode }: AnalysisPermissionProps) => {
    return (
        <div className="analysis-permissions">
            <Filters
                editMode={editMode}
                showBreakdown
                useRadio={[false, false, false]}
                index={index}
            />
        </div>
    );
};

export default AnalysisPermission;

import { SVGProps } from 'react';
const SvgComponent = (props: SVGProps<SVGSVGElement>) => {
    const fill = props.fill || 'currentColor';

    return (
        <svg
            width="55"
            height="86"
            viewBox="0 0 55 86"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
            fill="none"
        >
            <path
                d="M28.2 0C32.12 0 35.68 0.640001 38.88 1.92C42.16 3.12 44.96 4.8 47.28 6.96C49.6 9.04 51.4 11.44 52.68 14.16C53.96 16.88 54.6 19.76 54.6 22.8C54.6 26.56 54 29.72 52.8 32.28C51.68 34.76 50.24 36.92 48.48 38.76C46.8 40.6 44.92 42.24 42.84 43.68C40.84 45.12 38.96 46.68 37.2 48.36C35.52 50.04 34.08 52 32.88 54.24C31.76 56.4 31.2 59.12 31.2 62.4V64.8H22.32C22.32 64.24 22.32 63.68 22.32 63.12C22.24 62.64 22.2 62.08 22.2 61.44C22.2 60.72 22.2 60.04 22.2 59.4C22.2 56.2 22.76 53.44 23.88 51.12C25.08 48.8 26.56 46.76 28.32 45C30.08 43.16 31.96 41.48 33.96 39.96C35.96 38.44 37.8 36.88 39.48 35.28C41.24 33.6 42.68 31.8 43.8 29.88C45 27.88 45.6 25.52 45.6 22.8C45.6 20.88 45.2 19.04 44.4 17.28C43.6 15.52 42.44 14 40.92 12.72C39.48 11.36 37.68 10.32 35.52 9.6C33.36 8.8 30.92 8.4 28.2 8.4C23.64 8.4 20.08 9.32 17.52 11.16C14.96 13 13.08 15.08 11.88 17.4C10.36 20.04 9.44 23.04 9.12 26.4H0C0.48 21.44 1.8 17.04 3.96 13.2C4.84 11.52 5.96 9.92 7.32 8.4C8.68 6.8 10.32 5.4 12.24 4.2C14.24 2.92 16.56 1.92 19.2 1.2C21.84 0.4 24.84 0 28.2 0ZM22.08 85.2V75.6H31.56V85.2H22.08Z"
                fill={fill}
            />
        </svg>
    );
};
export default SvgComponent;

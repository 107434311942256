import React from 'react';
import Modal from 'antd/lib/modal';
import Button from './Button';
import InfoHtml from './InfoHtml';
import AnalysisTypes from '../constants/AnalysisTypes';

interface InfoModalProps {
    open: boolean;
    onCancel: () => void;
    category: string;
    analysisType: string;
    singleSegment: boolean;
}

function InfoModal(props: InfoModalProps) {
    function getInfoHtml() {
        const { category, analysisType, singleSegment } = props;
        if (singleSegment) {
            if (
                category === 'Compensation' ||
                analysisType === AnalysisTypes.LinearRegression
            ) {
                return InfoHtml.comp1;
            }
            if (category === 'Representation') {
                return InfoHtml.rep1;
            }
            if (category === 'Hiring') {
                return InfoHtml.hir1;
            }
            if (
                category === 'Attrition' ||
                analysisType === AnalysisTypes.Index
            ) {
                return InfoHtml.attr1;
            }
            if (category === 'Retention') {
                return InfoHtml.ret1;
            }
        } else {
            if (
                category === 'Compensation' ||
                analysisType === AnalysisTypes.LinearRegression
            ) {
                return InfoHtml.comp2;
            }
            if (category === 'Representation') {
                return InfoHtml.rep2;
            }
            if (category === 'Hiring') {
                return InfoHtml.hir2;
            }
            if (
                category === 'Attrition' ||
                analysisType === AnalysisTypes.Index
            ) {
                return InfoHtml.attr2;
            }
            if (category === 'Retention') {
                return InfoHtml.ret2;
            }
        }
    }

    const { open, onCancel } = props;
    return (
        <Modal
            open={open}
            width={600}
            footer={null}
            closable={false}
            maskClosable={true}
            onCancel={onCancel}
            zIndex={3000}
        >
            <div className="modal modal--delete">
                <div className="modal__header">
                    <div className="modal__title">What&apos;s the Math?</div>
                </div>
                <div className="modal__content">
                    <div
                        dangerouslySetInnerHTML={{
                            __html: getInfoHtml() || ''
                        }}
                    />
                </div>
                <div className="modal__footer">
                    <Button onClick={onCancel}>Close</Button>
                </div>
            </div>
        </Modal>
    );
}

export default InfoModal;

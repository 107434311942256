import RbacActions from '../constants/RbacActions';
import usePermissions from './usePermissions';

function useCustomLandingPage() {
    const sharedDashboardsAllowed = usePermissions({
        actions: [RbacActions['Dashboards/ViewShared']]
    });
    const allDashboardsAllowed = usePermissions({
        actions: [RbacActions['Dashboards/ViewAll']]
    });
    const dashboardsAllowed = sharedDashboardsAllowed || allDashboardsAllowed;
    const exploreAllowed = usePermissions({
        actions: [RbacActions['Explore/Generate']]
    });
    const timelineAllowed = usePermissions({
        actions: [RbacActions['Events/View']]
    });
    const measureAllowed = usePermissions({
        actions: [RbacActions['Goals/View']]
    });
    const collectAllowed = usePermissions({
        actions: [RbacActions['Collect/View']]
    });
    const usersAllowed = usePermissions({
        actions: [RbacActions['Users/View']]
    });
    const rolesAllowed = usePermissions({
        actions: [RbacActions['Roles/View']]
    });
    const groupsAllowed = usePermissions({
        actions: [RbacActions['Groups/View']]
    });
    const integrationsAllowed = usePermissions({
        actions: [RbacActions['Integrations/View']]
    });
    const adminAllowed =
        usersAllowed || rolesAllowed || groupsAllowed || integrationsAllowed;

    if (dashboardsAllowed) {
        return '/';
    }

    if (exploreAllowed) {
        return '/explore';
    }

    if (timelineAllowed) {
        return '/timeline';
    }

    if (measureAllowed) {
        return '/measure';
    }

    if (collectAllowed) {
        return '/collect';
    }

    if (adminAllowed) {
        return '/admin';
    }

    return '/';
}

export default useCustomLandingPage;

const constants = {
    ColumnCount: 6,
    AddGraphLength: 2,
    MinGraphLength: 2,
    ColumnGap: 16,
    TableRowHeight: 32,
    ExploreHeightFix: 48,
    Padding: 16
};

export default constants;

import { createContext, useEffect, useRef, useState } from 'react';
import cn from '../lib/cn';

interface IGraphWrapperProps {
    children: any;
    className?: string;
    delay?: number;
    [key: string]: any;
}

export const GraphSizeContext = createContext({
    width: 0,
    height: 0
});

const GraphWrapper = ({
    children,
    className,

    // delay = 0,
    ...restProps
}: IGraphWrapperProps) => {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const ref_ = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const onResize = () => {
            if (!ref_.current) return;

            setWidth(ref_.current.offsetWidth);
            setHeight(ref_.current.offsetHeight);
        };

        const resizeObserver = new ResizeObserver(onResize);

        if (ref_.current) {
            resizeObserver.observe(ref_.current);
        }

        onResize();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <GraphSizeContext.Provider value={{ width, height }}>
            <div
                ref={ref_}
                className={cn(
                    'flex items-center justify-center relative',
                    className
                )}
                {...restProps}
            >
                <div className="absolute inset-0 overflow-scroll hide-scrollbar">
                    {children}
                </div>
            </div>
        </GraphSizeContext.Provider>
    );
};

GraphWrapper.displayName = 'GraphWrapper';

export default GraphWrapper;

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

import { AsyncThunkConfig } from '../../store/store';
import Urls from '../../constants/Urls';
import { Group } from './models';
import ActionTypes from '../../constants/ActionTypes';

interface IGroupsState {
    allGroups: Group[];
    pending: boolean;
    error: any;
}

export const fetchGroups = createAsyncThunk<any, void, AsyncThunkConfig>(
    'groups/fetchGroups',
    async (_, { getState }) => {
        const { enterpriseId } = getState().account;

        const response = await axios.post(`${Urls.RbacApi}groups`, {
            enterpriseId
        });

        return response.data;
    }
);

const initialState: IGroupsState = {
    allGroups: [],
    pending: false,
    error: null
};

const groupsSlice = createSlice({
    name: 'groups',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addCase(fetchGroups.pending, (state, _) => {
            state.pending = true;
        });
        builder.addCase(fetchGroups.fulfilled, (state, action) => {
            state.allGroups = action.payload;
            state.pending = false;
        });
        builder.addCase(fetchGroups.rejected, (state, action) => {
            state.error = action.error;
            state.pending = false;
        });
        builder.addCase(ActionTypes.AssignGroupRolePending, (state, _) => {});
        builder.addCase(
            ActionTypes.AssignGroupRoleFulfilled,
            (state, action: any) => {
                const index = state.allGroups.findIndex(
                    (group: Group) => group.groupId === action.groupId
                );

                if (
                    !state.allGroups[index].roles.some(
                        role => role.id === action.roleId
                    )
                ) {
                    state.allGroups[index].roles = [
                        ...state.allGroups[index].roles,
                        action.allRoles.find(
                            (role: any) => role.id === action.roleId
                        )
                    ];
                }
            }
        );
        builder.addCase(
            ActionTypes.AssignGroupRoleOptimistic,
            (state, action: any) => {
                const index = state.allGroups.findIndex(
                    (group: Group) => group.groupId === action.groupId
                );

                state.allGroups[index].roles = [
                    ...state.allGroups[index].roles,
                    action.allRoles.find(
                        (role: any) => role.id === action.roleId
                    )
                ];
            }
        );
        builder.addCase(ActionTypes.AssignGroupRoleRejected, () => {});
        builder.addCase(ActionTypes.UnassignGroupRolePending, () => {});
        builder.addCase(
            ActionTypes.UnassignGroupRoleFulfilled,
            (state, action: any) => {
                const index = state.allGroups.findIndex(
                    (group: Group) => group.groupId === action.groupId
                );
                state.allGroups[index].roles = state.allGroups[
                    index
                ].roles.filter(role => role.id !== action.roleId);
            }
        );
        builder.addCase(
            ActionTypes.UnassignGroupRoleOptimistic,
            (state, action: any) => {
                const index = state.allGroups.findIndex(
                    (group: Group) => group.groupId === action.groupId
                );
                state.allGroups[index].roles = state.allGroups[
                    index
                ].roles.filter(role => role.id !== action.roleId);
            }
        );
        builder.addCase(ActionTypes.UnassignGroupRoleRejected, () => {});
    }
});

export default groupsSlice.reducer;

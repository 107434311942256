import { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import LSKeys from '../constants/LSKeys';
import WaitPage from './WaitPage';
import { setWinked } from './actions';
import { useAppDispatch } from '../store/hooks';
interface PrivateRouteProps {
    component: any;
    loggedIn: boolean;
    loaded?: boolean;
    winked?: boolean;
    path?: string;
    exact?: boolean;
    customRoute?: string;
}

export default function PrivateRoute({
    component: RouteComponent,
    loggedIn,
    loaded,
    winked,
    customRoute,
    ...rest
}: PrivateRouteProps) {
    const [wink, setWink] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        if (loaded) {
            setWink(true);
            setTimeout(() => {
                dispatch(setWinked());
                setWink(false);
            }, 3000);
        }
    }, [loaded]);

    return (
        <Route
            {...rest}
            render={routeProps => {
                const enterpriseId = localStorage.getItem(LSKeys.EnterpriseId);
                if (winked && loggedIn && !!enterpriseId) {
                    if (customRoute && customRoute !== '/') {
                        return <Redirect to={customRoute} />;
                    }
                    return <RouteComponent {...routeProps} />;
                }
                if (!winked && loggedIn && !!enterpriseId) {
                    return <WaitPage wink={wink} />;
                }
                if (!loggedIn || !enterpriseId) {
                    const {
                        location: { pathname, search }
                    } = routeProps;
                    let redirectUrl = `${pathname}${search}`;
                    if (redirectUrl === '/') {
                        return <Redirect to="/login" />;
                    } else {
                        redirectUrl = encodeURIComponent(redirectUrl);
                        return (
                            <Redirect
                                to={`/login?redirectUrl=${redirectUrl}`}
                            />
                        );
                    }
                }
            }}
        />
    );
}

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { MdAddAPhoto } from 'react-icons/md';
import { connect } from 'react-redux';
import qs from 'qs';
import { completeEdit } from './actions';
import { getActorInfo } from '../layout/actions';
import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';
import MixPanel from '../constants/MixPanel';
import { page, track } from '../lib/segment';
import sanitize from '../lib/sanitize';
import { addMessage } from '../common/actions';
import MessageBar from '../common/MessageBar';
import firebase from '../lib/firebase';
import TextField from '../common/TextField';
import TextFieldTypes from '../constants/TextFieldTypes';
import { setLightMode } from '../auth/actions';
import ThemeCard from '../common/ThemeCard';
import ThemeCardTypes from '../constants/ThemeCardTypes';
import LSKeys from '../constants/LSKeys';

function isSSOLogin() {
    const data = firebase.auth().currentUser.providerData;
    for (let i = 0; i < data.length; i++) {
        if (data[i].providerId !== 'password') {
            return true;
        }
    }
    return false;
}

class EditUser extends Component {
    constructor(props) {
        super();
        this.state = {
            firstName: props.userInfo.firstName,
            lastName: props.userInfo.lastName,
            imageURL: props.userInfo.imageURL,
            lightMode: props.userInfo.lightMode
        };
        const query = qs.parse(props.location.search, {
            ignoreQueryPrefix: true
        });
        if (query.redirectUrl) {
            this.redirectUrl = decodeURIComponent(query.redirectUrl);
        }
    }

    componentDidMount() {
        if (!this.props.userInfo.firstName) {
            this.props.onActorInfoGet();
        }
        page(MixPanel.Pages.UserProfile);
    }

    handleDone(e) {
        e.preventDefault();
        const { firstName, lastName, image, imageURL, lightMode } = this.state;
        const { userInfo, onEdit } = this.props;
        track(MixPanel.Events.SaveProfile);
        onEdit(
            userInfo.email,
            sanitize(firstName).trim(),
            sanitize(lastName).trim(),
            image,
            imageURL,
            lightMode,
            this.redirectUrl
        );
    }

    handleCancel(e) {
        const { history } = this.props;
        e.preventDefault();
        track(MixPanel.Events.CancelProfile);
        history.goBack();
    }

    handleFirstNameChange(e) {
        const firstName = e.target.value;
        this.setState({ firstName });
    }

    handleLastNameChange(e) {
        const lastName = e.target.value;
        this.setState({ lastName });
    }

    handlePhotoChange() {
        if (this.fileUpload.files.length) {
            const file = this.fileUpload.files[0];
            const regex = /(.*?)\.(jpg|bmp|jpeg|png|tif|tiff|gif)$/;
            if (!file.name.match(regex)) {
                this.props.addMessage('Please select an image', true);
                return;
            }
            if (file.size > 10000000) {
                this.props.addMessage('Selected image is too large', true);
                return;
            }
            const reader = new FileReader();
            reader.onload = e => {
                this.setState({ image: e.target.result });
            };
            reader.readAsDataURL(file);
        }
    }

    handlePasswordChange(e) {
        e.preventDefault();
        track(MixPanel.Events.ChangePasswordClick);
        const { history } = this.props;
        history.replace('/changePassword');
    }

    handleLightModeChange(lightMode) {
        this.setState({ lightMode });
        this.props.setLightMode(lightMode);
        if (lightMode) {
            localStorage.setItem(LSKeys.LightMode, 'true');
        } else {
            localStorage.removeItem(LSKeys.LightMode);
        }
    }

    render() {
        const { firstName, lastName, imageURL, image, lightMode } = this.state;
        const { pending, userInfo } = this.props;

        const sanitizedFirstName = sanitize(firstName).trim();
        const sanitizedLastName = sanitize(lastName).trim();

        const disabled = !sanitizedFirstName || !sanitizedLastName;

        return (
            <div className="edituser-container">
                <form className="user-form" onSubmit={e => e.preventDefault()}>
                    <div className="user-form__top">
                        <div className="user-form__title">
                            {this.redirectUrl
                                ? 'Enter your name'
                                : 'Edit my info'}
                        </div>
                    </div>
                    <div className="user-form__fields">
                        <div className="user-form__fields-left">
                            <div className="user-form__fname">
                                <TextField
                                    value={firstName}
                                    onChange={this.handleFirstNameChange.bind(
                                        this
                                    )}
                                    placeholder="First Name"
                                />
                            </div>
                            <div className="user-form__lname">
                                <TextField
                                    componentType={TextFieldTypes.type.DEFAULT}
                                    value={lastName}
                                    onChange={this.handleLastNameChange.bind(
                                        this
                                    )}
                                    placeholder="Last Name"
                                />
                            </div>
                        </div>
                        <div className="user-form__fields-right">
                            <div className="user-form__photo">
                                <label
                                    className="user-form__photo--label"
                                    htmlFor="photoUpload"
                                >
                                    {image || imageURL ? (
                                        <img
                                            src={image || imageURL}
                                            alt="photo"
                                            className="user-form__photo--img"
                                        />
                                    ) : (
                                        <MdAddAPhoto size={24} color="#ccc" />
                                    )}
                                </label>
                                <input
                                    type="file"
                                    id="photoUpload"
                                    ref={c => {
                                        this.fileUpload = c;
                                    }}
                                    className="user-form__photo--upload"
                                    accept="image/*"
                                    onChange={this.handlePhotoChange.bind(this)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="user-form__theme">
                        <div className="user-form__theme-title">
                            Please choose color mode
                        </div>
                        <div className="user-form__theme-options">
                            <ThemeCard
                                cardType={ThemeCardTypes.DARK}
                                lightMode={lightMode}
                                onClick={() =>
                                    this.handleLightModeChange(false)
                                }
                            />
                            <ThemeCard
                                cardType={ThemeCardTypes.LIGHT}
                                lightMode={lightMode}
                                onClick={() =>
                                    this.handleLightModeChange(true)
                                }
                            />
                        </div>
                    </div>

                    <div className="user-form__bottom">
                        {!this.redirectUrl && (
                            <Button
                                componentType={ButtonTypes.type.TERTIARY}
                                onClick={this.handleCancel.bind(this)}
                            >
                                Cancel
                            </Button>
                        )}
                        {!isSSOLogin() && (
                            <Button
                                componentType={ButtonTypes.type.TERTIARY}
                                onClick={this.handlePasswordChange.bind(this)}
                            >
                                Change Password
                            </Button>
                        )}
                        <Button
                            loading={pending}
                            disabled={disabled}
                            onClick={this.handleDone.bind(this)}
                        >
                            Done
                        </Button>
                    </div>
                </form>
                <MessageBar />
            </div>
        );
    }
}

EditUser.propTypes = {
    onEdit: PropTypes.func,
    onActorInfoGet: PropTypes.func,
    history: PropTypes.object,
    pending: PropTypes.bool,
    addMessage: PropTypes.func,
    location: PropTypes.object,
    userInfo: PropTypes.object,
    setActorInfo: PropTypes.func
};

function mapStateToProps(state) {
    const { editPending, userInfo } = state.auth;
    return {
        pending: editPending,
        userInfo
    };
}

const dispatchProps = {
    onActorInfoGet: getActorInfo,
    onEdit: completeEdit,
    addMessage,
    setLightMode
};

export default connect(mapStateToProps, dispatchProps)(EditUser);

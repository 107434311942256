import * as React from 'react';

function SvgComponent(props: any) {
    return (
        <svg
            width={48}
            height={48}
            viewBox="0 0 49 49"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
            {...props}
        >
            <rect
                fill="var(--color-shade-h3)"
                x={0.037}
                y={0.031}
                width={48}
                height={48}
                rx={4}
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M38.037 25.074h2c0-8.836-7.164-16-16-16h-.025v2h.025c7.732 0 14 6.268 14 14z"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M24.025 39.075v2c8.837 0 16-7.163 16-16v-.024h-2v.024c0 7.732-6.268 14-14 14zM24.012 9.074c-8.825.013-15.975 7.172-15.975 16 0 8.829 7.15 15.987 15.975 16v-2c-7.72-.013-13.975-6.276-13.975-14s6.254-13.987 13.975-14v-2z"
            />
            <path fill="var(--color-shade-h3)" d="M23.38 9.074h2v32h-2z" />
            <path d="M25.38 9.787h2v14.288h-2z" />
            <path d="M39.669 22.075v2H25.38v-2z" />
            <path fill="var(--color-shade-h3)" d="M40.037 24.075v2H23.38v-2z" />
        </svg>
    );
}

export default SvgComponent;

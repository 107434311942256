import React, { ChangeEvent } from 'react';
import produce from 'immer';
import { MdDelete } from 'react-icons/md';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import TextField from '../../common/TextField';

interface FormulasProps {
    index: number;
    formulas?: string[];
    setConfigurationVariables: any;
}

export default function Formulas({
    index,
    formulas,
    setConfigurationVariables
}: FormulasProps) {
    function handleFormulaChange(e: ChangeEvent<HTMLInputElement>, i: number) {
        const formula = e.target.value;
        setConfigurationVariables((configurationVariables: any) =>
            produce(configurationVariables, (draftState: any) => {
                draftState[index].formulas[i] = formula;
            })
        );
    }

    function handleNewFormula() {
        setConfigurationVariables((configurationVariables: any) =>
            produce(configurationVariables, (draftState: any) => {
                draftState[index].formulas.push('');
            })
        );
    }

    function handleDeleteFormula(i: number) {
        setConfigurationVariables((configurationVariables: any) =>
            produce(configurationVariables, (draftState: any) => {
                draftState[index].formulas.splice(i, 1);
            })
        );
    }

    return (
        <>
            <div className="ds-label" style={{ marginBottom: 8 }}>
                <span style={{ marginRight: 16 }}>Formulas</span>
                <Button
                    componentType={ButtonTypes.type.SECONDARY}
                    onClick={handleNewFormula}
                    large={false}
                >
                    Add new
                </Button>
            </div>
            {formulas?.map((formula, i) => (
                <div key={i} className="ds-input">
                    <TextField
                        value={formula}
                        onChange={ev => handleFormulaChange(ev, i)}
                    />
                    <MdDelete
                        className="icon"
                        size={16}
                        style={{ marginLeft: 8 }}
                        onClick={() => handleDeleteFormula(i)}
                    />
                </div>
            ))}
        </>
    );
}

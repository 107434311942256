import classNames from 'classnames';
import React from 'react';
import { getColorFromName } from '../../utils';
import { CARET_WIDTH, EVENT_HEIGHT, MONTH_WIDTH } from './TimelineContent';
import { useAppSelector } from '../../../store/hooks';

interface EventMarkerProps extends React.HTMLAttributes<HTMLDivElement> {
    startPosition: number;
    endPosition: number;
    todayPosition: number;
    timelineWidth: number;
    color: string;
    index: number;
    highlighted?: boolean;
    showToday?: boolean;
    id?: string;
}

const MARKER_SIZE = 16;

const EventMarker = ({
    startPosition,
    endPosition,
    todayPosition,
    timelineWidth,
    color,
    index,
    highlighted,
    showToday,
    id,
    ...rest
}: EventMarkerProps) => {
    const { eventToScrollTo } = useAppSelector(state => state.timeline);
    const stampColor = getColorFromName(color);
    const todayRef = React.useRef<HTMLDivElement>(null);
    const eventRef = React.useRef<HTMLDivElement>(null);
    const rangedEventRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        if (!todayRef) return;
        todayRef.current?.scrollIntoView({
            inline: 'center'
        });
    }, [showToday]);

    React.useEffect(() => {
        if (!eventRef && !rangedEventRef) return;
        if (!eventToScrollTo) return;
        if (eventToScrollTo !== id) return;

        if (startPosition !== endPosition) {
            rangedEventRef.current?.scrollIntoView({
                inline: 'center'
            });
            return;
        } else {
            eventRef.current?.scrollIntoView({
                inline: 'center'
            });
        }
    }, [eventToScrollTo]);

    return (
        <div
            className={classNames(
                'marker',
                {
                    'marker--odd': index % 2 === 1
                },
                { 'marker--even': index % 2 === 0 }
            )}
            style={{
                height: EVENT_HEIGHT,
                position: 'relative',
                opacity: highlighted ? 1 : 0.3,
                width: timelineWidth + 2 * CARET_WIDTH
            }}
            {...rest}
        >
            <div
                ref={todayRef}
                style={{
                    position: 'relative',
                    left: todayPosition + CARET_WIDTH,
                    height: '100%',
                    width: 1,
                    backgroundColor: 'var(--color-shade-h6)'
                }}
            ></div>
            {startPosition !== endPosition ? (
                <div
                    className="stamp"
                    style={{
                        position: 'absolute',
                        left: startPosition + CARET_WIDTH,
                        width: endPosition - startPosition + MARKER_SIZE,
                        backgroundColor: 'var(--color-ui-20)',
                        height: 16,
                        display: 'flex',
                        alignItems: 'center',
                        borderRadius: MARKER_SIZE / 2,
                        justifyContent: 'flex-end'
                    }}
                >
                    <div
                        ref={rangedEventRef}
                        style={{
                            position: 'relative',
                            right: 0,
                            width: MARKER_SIZE,
                            height: MARKER_SIZE,
                            minWidth: MARKER_SIZE,
                            borderRadius: MARKER_SIZE / 2,
                            zIndex: 300,
                            backgroundColor: stampColor
                        }}
                    ></div>
                </div>
            ) : (
                <div
                    ref={eventRef}
                    className="stamp"
                    style={{
                        position: 'absolute',
                        left: startPosition + +CARET_WIDTH,
                        maxWidth: timelineWidth - startPosition + 8
                    }}
                >
                    <div
                        style={{
                            position: 'relative',
                            right: 0,
                            width: MARKER_SIZE,
                            height: MARKER_SIZE,
                            borderRadius: MARKER_SIZE / 2,
                            zIndex: 300,
                            backgroundColor: stampColor
                        }}
                    ></div>
                </div>
            )}
        </div>
    );
};

export default EventMarker;

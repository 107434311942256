import React from 'react';
import AvatorIcon from '../icons/Avatar';
import cn from '../lib/cn';

export default function AiAvatar({
    size = 40,
    ...rest
}: {
    size?: number;
    [key: string]: any;
}) {
    return (
        <AvatorIcon
            className={cn('ai-gradient rounded-full')}
            size={size}
            {...rest}
        ></AvatorIcon>
    );
}

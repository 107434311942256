import { SortCriteria } from '../../common/SortableHead';
import ActionTypes from '../../constants/ActionTypes';
import { IGoal } from '../types';

interface IGoalsReducer {
    data: IGoal[];
    pending: boolean;
    error: any;
    toEdit: IGoal | undefined;
    toDelete: IGoal | undefined;
}

const initialState: IGoalsReducer = {
    data: [],
    pending: false,
    error: null,
    toEdit: undefined,
    toDelete: undefined
};

export default function GoalsReducer(
    state = initialState,
    action: { type: string; payload: any }
): IGoalsReducer {
    switch (action.type) {
        case ActionTypes.GetGoalsPending: {
            return {
                ...state,
                pending: true
            };
        }
        case ActionTypes.GetGoalsFulfilled: {
            const goals = action.payload;
            return {
                ...state,
                data: goals
            };
        }
        case ActionTypes.GetGoalsRejected: {
            return {
                ...state,
                error: action.payload
            };
        }
        case ActionTypes.AddGoalPending: {
            return {
                ...state,
                pending: true
            };
        }
        case ActionTypes.AddGoalFulfilled: {
            const goal = action.payload;
            return {
                ...state,
                data: [...state.data, goal],
                pending: false
            };
        }
        case ActionTypes.AddGoalRejected: {
            return {
                ...state,
                error: action.payload,
                pending: false
            };
        }
        case ActionTypes.EditGoalPending: {
            return {
                ...state,
                pending: true
            };
        }
        case ActionTypes.EditGoalFulfilled: {
            const goal = action.payload;
            const goals = state.data.map(g => {
                if (g.goalId === goal.goalId) {
                    return goal;
                }
                return g;
            });
            return {
                ...state,
                data: goals,
                pending: false
            };
        }
        case ActionTypes.DeleteGoalPending: {
            return {
                ...state,
                pending: true
            };
        }
        case ActionTypes.EditGoalRejected: {
            return {
                ...state,
                error: action.payload,
                pending: false
            };
        }
        case ActionTypes.DeleteGoalPending: {
            return {
                ...state,
                pending: true
            };
        }
        case ActionTypes.DeleteGoalFulfilled: {
            const goalId = action.payload;
            const goals = state.data.filter(g => g.goalId !== goalId);
            return {
                ...state,
                data: goals,
                pending: false
            };
        }
        case ActionTypes.DeleteGoalRejected: {
            return {
                ...state,
                error: action.payload,
                pending: false
            };
        }
        case ActionTypes.SetGoalToEdit: {
            const goal = action.payload;
            return {
                ...state,
                toEdit: goal
            };
        }
        case ActionTypes.SetGoalToDelete: {
            const goal = action.payload;
            return {
                ...state,
                toDelete: goal
            };
        }
        case ActionTypes.SetEnterpriseId: {
            return initialState;
        }
        default: {
            return state;
        }
    }
}

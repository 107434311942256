import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import FilterButton from './FilterButton';

interface FilterDropdownProps extends PropsWithChildren {
    label: string;
    placeholder: string;
    isOpen: boolean;
    setIsOpen: (isOpen: boolean) => void;
    disabled?: boolean;
    isSecondary?: boolean;
    allowCancel: boolean;
    onClearSelection: () => void;
}

export default function FilterDropdown({
    children,
    isOpen,
    setIsOpen,
    label,
    placeholder,
    disabled,
    isSecondary,
    allowCancel,
    onClearSelection
}: FilterDropdownProps) {
    useEffect(() => {
        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('mousedown', handleClickOutside);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
                window.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [isOpen]);

    const containerRef = useRef<HTMLDivElement>(null);

    function handleClick() {
        setIsOpen(!isOpen);
    }

    function handleKeyDown(e: any) {
        if (e.keyCode === 27) {
            setIsOpen(false);
        }
    }

    function handleClickOutside(e: any) {
        if (isOpen) {
            if (
                containerRef.current &&
                !containerRef.current.contains(e.target)
            ) {
                e.stopPropagation();
                setIsOpen(false);
            }
        }
    }

    return (
        <div
            className="filter-dropdown"
            ref={containerRef}
            data-test={`dropdown-${placeholder}`}
        >
            <FilterButton
                label={label}
                placeholder={placeholder}
                onClick={handleClick}
                disabled={disabled}
                isOpen={isOpen}
                isSecondary={isSecondary}
                allowCancel={allowCancel}
                onClearSelection={onClearSelection}
            />
            <CSSTransition
                in={isOpen}
                timeout={300}
                mountOnEnter
                unmountOnExit
                classNames="fade-scale"
            >
                <div
                    className="filter-dropdown__menu"
                    data-test={`dropdown-menu-${placeholder}`}
                >
                    {children}
                </div>
            </CSSTransition>
        </div>
    );
}

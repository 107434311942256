import * as React from 'react';
import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg
        width={11}
        height={12}
        viewBox="0 0 11 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M5.5 5.231c-.635 0-1.12-.203-1.497-.592-.38-.39-.581-.898-.581-1.565 0-.667.202-1.174.58-1.565.378-.39.863-.592 1.498-.592s1.12.203 1.497.592c.38.39.581.898.581 1.565 0 .667-.202 1.174-.58 1.565-.378.39-.863.592-1.498.592Zm-5 5.686V9.752c0-.365.087-.647.24-.87.168-.246.38-.429.642-.555A12.162 12.162 0 0 1 3.5 7.562a8.177 8.177 0 0 1 2.001-.25c.667 0 1.33.085 1.987.258.668.175 1.374.427 2.12.76.273.127.488.31.654.553.152.222.239.504.239.869v1.165H.5Z"
            stroke="currentColor"
        />
    </svg>
);

export default SvgComponent;

import React, { useEffect, useState } from 'react';
import TextField from '../../../common/TextField';
import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { fetchSurveys } from './reducer';
import { RouteComponentProps } from 'react-router';
import LottieAnimation from '../../../common/LottieAnimation';
import Loader from '../../../lottie/graph-loader.json';
import Template from './Template';
import './styles.scss';
import Stack from '../../../common/Stack';
import Button from '../../../common/Button';
import ButtonTypes from '../../../constants/ButtonTypes';

export default function Templates() {
    const [search, setSearch] = useState('');
    const {
        data: activeData,
        archived: archivedData,
        pending
    } = useAppSelector(state => state.datastudio.survey.surveys);
    const [showArchived, setShowArchived] = useState(false);
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(fetchSurveys());
    }, []);

    const data = showArchived ? archivedData : activeData;

    const filteredData = data.filter(d =>
        d.title.toLowerCase().includes(search.toLowerCase())
    );

    return (
        <div className="survey-content">
            <Stack
                flexDirection="row"
                justifyContent="space-between"
                style={{ margin: '1.6rem 0' }}
            >
                <div style={{ flex: 1, maxWidth: '600px' }}>
                    <TextField
                        placeholder="Search campaign"
                        isSearch
                        value={search}
                        onChange={e => setSearch(e.target.value)}
                        showClose
                        onClose={() => setSearch('')}
                    />
                </div>
                <Stack
                    flexDirection="row"
                    justifyContent="flex-end"
                    gap=".8rem"
                    style={{ marginLeft: '1.6rem', marginBottom: '1.6rem' }}
                >
                    <Button
                        activated={!showArchived}
                        onClick={() => setShowArchived(false)}
                        componentType={ButtonTypes.type.SECONDARY}
                    >
                        Active
                    </Button>
                    <Button
                        activated={showArchived}
                        onClick={() => setShowArchived(true)}
                        componentType={ButtonTypes.type.SECONDARY}
                    >
                        Archived
                    </Button>
                </Stack>
            </Stack>
            {!!search && !filteredData.length && (
                <div className="empty-search-container">
                    Sorry, no surveys match your search query
                </div>
            )}
            {pending ? (
                <div className="ds-loader">
                    <LottieAnimation
                        animation={Loader}
                        autoplay={true}
                        loop={true}
                        width={100}
                        height={100}
                    ></LottieAnimation>
                </div>
            ) : (
                <div className="surveys-grid">
                    {filteredData.map(d => (
                        <Template survey={d} archived={showArchived} />
                    ))}
                </div>
            )}
        </div>
    );
}

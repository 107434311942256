import { createSlice } from '@reduxjs/toolkit';

import { ChatMessage, ChatMessageRoles } from '../aiTypes';
import {
    getChatConfigSchema,
    getChatInputSchema,
    getChatMessagesList,
    getChatOutputSchema,
    invokeChat,
    streamInvokeChat,
} from './actions';

interface ChatState {
    chatResponse?: any;
    chatResponseError?: string;
    chatResponseLoaded: boolean;
    chatResponsePending: boolean;
    configSchema: any;
    conversationId?: string;
    error: string | null;
    inputSchema: any;
    loaded: boolean;
    messages: ChatMessage[];
    outputSchema: any;
    pending: boolean;
    prompts: string[];
}

const initialState: ChatState = {
    chatResponseLoaded: false,
    chatResponsePending: false,
    configSchema: null,
    conversationId: undefined,
    error: null,
    inputSchema: null,
    loaded: false,
    messages: [],
    outputSchema: null,
    pending: false,
    prompts: [],
};

const chatSlice = createSlice({
    name: 'ai/chat',
    initialState,
    reducers: {
        addUserMessage(state, action) {
            state.messages.push({
                role: ChatMessageRoles.USER,
                content: action.payload,
            });
        },
        appendChatMessage(state, action) {
            state.messages[state.messages.length] = action.payload as ChatMessage;
        },
        deleteConversationId(state) {
            state.conversationId = undefined;
        },
        resetChat() {
            return initialState;
        },
        setConversationId(state, action) {
            state.conversationId = action.payload;
            // TODO: how should this logic be done?
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getChatInputSchema.pending, (state) => {
                state.pending = true;
            })
            .addCase(getChatInputSchema.fulfilled, (state, action) => {
                state.pending = false;
                state.inputSchema = action.payload;
            })
            .addCase(getChatOutputSchema.pending, (state) => {
                state.pending = true;
            })
            .addCase(getChatOutputSchema.fulfilled, (state, action) => {
                state.pending = false;
                state.outputSchema = action.payload;
            })
            .addCase(getChatConfigSchema.pending, (state) => {
                state.pending = true;
            })
            .addCase(getChatConfigSchema.fulfilled, (state, action) => {
                state.pending = false;
                state.configSchema = action.payload;
            })
            .addCase(invokeChat.pending, (state) => {
                state.chatResponsePending = true;
                state.chatResponseError = undefined;
                state.chatResponseLoaded = false;
            })
            .addCase(invokeChat.fulfilled, (state, action) => {
                state.chatResponsePending = false;
                state.chatResponseLoaded = true;
                state.chatResponse = action.payload;
            })
            .addCase(invokeChat.rejected, (state, action) => {
                state.chatResponsePending = false;
                state.chatResponseLoaded = true;
                state.chatResponseError = action.error.message;
            })
            .addCase(streamInvokeChat.pending, (state) => {
                state.pending = true;
            })
            .addCase(streamInvokeChat.fulfilled, (state, action) => {
                state.pending = false;
                state.messages[state.messages.length] = action.payload;
            })
            .addCase(getChatMessagesList.pending, (state) => {
                state.pending = true;
            })
            .addCase(getChatMessagesList.fulfilled, (state, action) => {
                state.pending = false;
                state.messages = action.payload;
                state.loaded = true;
            });
    },
});

export const { addUserMessage, appendChatMessage, deleteConversationId, resetChat, setConversationId } =
    chatSlice.actions;
export default chatSlice.reducer;

export function getSegmentsFromV1Report(
    v1Attributes: any,
    employeeAttribute: any,
    rowFilter: any,
    columnFilter: any,
    attributes: any,
    variations: any[] = []
) {
    // TODO: fix getting variations
    // columnFilter is not supported anymore
    const variationArray = v1Attributes.map((s: any) =>
        s.name.replace(/ /g, '')
    );
    let found = false;
    let filterInvalid = false;
    let filterAttribute: any;
    for (let index = 0; index < variations.length; index++) {
        const variation = variations[index];
        const nonEmptyVariation = variation.slice(0, 4).filter((v: any) => !!v);
        found = nonEmptyVariation.length === variationArray.length;
        if (found) {
            nonEmptyVariation.forEach((variationItem: any) => {
                if (variationItem) {
                    found = found && variationArray.includes(variationItem);
                }
            });
        }
        if (found && variation[3]) {
            filterAttribute = v1Attributes.find(
                (s: any) => s.name.replace(/ /g, '') === variation[3]
            );
            if (filterAttribute.value.length > 1) {
                filterInvalid = true;
                found = false;
            }
        }
        if (found) {
            break;
        }
    }
    let mainSegments = v1Attributes.map((s: any) => ({
        name: s.name,
        values: s.value
    }));
    if (variations.length && !found && !filterInvalid) {
        console.log('This is an invalid V1 report');
    }
    let filterSegment;
    if (filterAttribute) {
        filterSegment = {
            name: filterAttribute.name,
            values: [filterAttribute.value[0]]
        };
        mainSegments = mainSegments.filter(
            (m: any) => m.name !== filterAttribute.name
        );
    }
    let breakdownSegment;
    if (employeeAttribute) {
        const attribute = attributes.find(
            (a: any) =>
                a.attributeName.replace(/ /g, '').toLowerCase() ===
                employeeAttribute.toLowerCase()
        );
        if (attribute) {
            let values = attribute.attributeValues.map((av: any) => av.value);
            if (rowFilter && rowFilter.length) {
                values = values.filter((v: any) => rowFilter.includes(v));
            }
            breakdownSegment = {
                name: attribute.attributeName,
                values
            };
        }
    }
    return {
        mainSegments,
        filterSegment,
        breakdownSegment
    };
}

export function getSegmentsFromV2Report(
    attributes: any,
    filter: any,
    breakdown: any
) {
    const mainSegments = attributes.map((a: any) => ({
        name: a.name,
        values: a.value
    }));
    let filterSegment;
    let breakdownSegment;
    if (filter) {
        filterSegment = {
            name: filter.name,
            values: [filter.value]
        };
    }
    if (breakdown) {
        breakdownSegment = {
            name: breakdown.name,
            values: breakdown.value
        };
    }
    return {
        mainSegments,
        filterSegment,
        breakdownSegment
    };
}

export function getConfig(config: any, key: any, defaultValue?: any) {
    const configItem = config.find((c: any) => c.name === key);
    return configItem ? configItem.value : defaultValue;
}

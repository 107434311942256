import { Menu } from 'antd';
import React, { Fragment } from 'react';
import { showAddEventModal, setEditEvent, setDeleteEvent } from '../../actions';
import { useAppDispatch } from '../../../store/hooks';

interface EventMoreItemsProps {
    eventId: string;
    setOpen: (open: boolean) => void;
}

const EventMoreItems = ({ eventId, setOpen }: EventMoreItemsProps) => {
    const dispatch = useAppDispatch();
    const handleEdit = (e: any) => {
        e.domEvent.stopPropagation();
        dispatch(setEditEvent(eventId));
        setOpen(false);
        dispatch(showAddEventModal());
    };

    const handleDelete = (e: any) => {
        setOpen(false);
        e.domEvent.stopPropagation();
        dispatch(setDeleteEvent(eventId));
    };

    return (
        <Menu
            onMouseLeave={() => {
                if (setOpen) {
                    setOpen(false);
                }
            }}
            className="widget-menu dashboards-menu"
            selectedKeys={[]}
        >
            <Fragment>
                <Menu.Item key="edit-modal" onClick={handleEdit}>
                    Edit
                </Menu.Item>
                <Menu.Item
                    key="delete-modal"
                    onClick={handleDelete}
                    data-test="delete-event"
                >
                    Delete
                </Menu.Item>
            </Fragment>
        </Menu>
    );
};

export default EventMoreItems;

import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../../../common/Checkbox';
import RadioButton from '../../../common/RadioButton';
import { MdSearch } from 'react-icons/md';
import Close from '../../../icons/Close';

export default function SegmentValues({
    values,
    onChange,
    onSelectAll,
    useRadio,
    search,
    onSearch
}) {
    let listHeight = window.screen.availHeight * 0.75 - 160;
    const maxRowCount = Math.floor(listHeight / 28);
    listHeight = maxRowCount * 28;
    const showSearch = values.length + 1 > maxRowCount;
    let filteredValues = values;
    if (search) {
        filteredValues = values.filter(v =>
            v.text.toLowerCase().includes(search.toLowerCase())
        );
    }
    const checkAll = filteredValues.every(v => v.checked);

    return (
        <div className="filter-dropdown__list">
            {showSearch && (
                <div className="filter-dropdown__search">
                    <MdSearch size={24} color="rgba(242, 242, 242, 0.5)" />
                    <input
                        className="large-text"
                        type="text"
                        placeholder="Search"
                        value={search}
                        onChange={e => onSearch(e.target.value)}
                    />
                    {search && (
                        <Close
                            width={24}
                            className="btn-icon"
                            onClick={() => onSearch('')}
                        />
                    )}
                </div>
            )}
            {search && filteredValues.length === 0 && (
                <div className="filter-dropdown__empty-message">
                    There are no segments for the search
                </div>
            )}
            <ul style={{ maxHeight: listHeight }}>
                {!useRadio && filteredValues.length > 1 && (
                    <li>
                        <Checkbox
                            value="all"
                            checked={checkAll}
                            onClick={checked => onSelectAll(checked)}
                        >
                            All
                        </Checkbox>
                    </li>
                )}
                {filteredValues.map(v => (
                    <li key={v.value}>
                        {useRadio ? (
                            <RadioButton
                                checked={v.checked}
                                onClick={() => onChange(v.value)}
                            >
                                {v.text}
                            </RadioButton>
                        ) : (
                            <Checkbox
                                value={v.value}
                                checked={v.checked}
                                onClick={() => onChange(v.value)}
                            >
                                {v.text}
                            </Checkbox>
                        )}
                    </li>
                ))}
            </ul>
        </div>
    );
}

SegmentValues.propTypes = {
    values: PropTypes.array,
    onChange: PropTypes.func,
    useRadio: PropTypes.bool,
    onSelectAll: PropTypes.func,
    search: PropTypes.string,
    onSearch: PropTypes.func
};

import { Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Menu } from 'antd';

import Button from '../../../common/Button';
import ConfirmationModal from '../../../common/ConfirmationModal';
import DeleteModal from '../../../common/DeleteModal';
import Grid from '../../../common/Grid';
import InfoCard from '../../../common/InfoCard';
import Popover from '../../../common/Popover';
import Stack from '../../../common/Stack';
import WithLoadingStates from '../../../common/WithLoadingStates';
import WithPermissions from '../../../common/WithPermissions';
import ButtonTypes from '../../../constants/ButtonTypes';
import RbacActions from '../../../constants/RbacActions';
import urls from '../../../constants/Urls';
import usePermissions from '../../../hooks/usePermissions';
import Chain from '../../../icons/Chain';
import Info from '../../../icons/Info';
import Mail from '../../../icons/Mail';
import More from '../../../icons/More';
import getAccessToken from '../../../lib/getAccessToken';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { getAudiences } from '../../audiences/list/actions';
import ContentWrapper from '../../components/ContentWrapper';
import precisionRound from '../../../lib/precisionRound';
import {
    deleteSnapshot,
    publishSnapshot,
    sendTestEmail,
    archiveSnapshot
} from '../edit/actions';
import SendTestEmailModal from '../../../common/Modals/SendTestEmailModal';
import SendReminderModal from './SendReminderModal';
import { addMessage } from '../../../common/actions';
import MixPanel from '../../../constants/MixPanel';
import { track } from '../../../lib/segment';
import { cadenceOptions } from '../constants';
import {
    getEnterpriseSurveys,
    getSurveyMetrics,
    sendReminder
} from './actions';
import { updateSnapshotWithReminder } from '../edit/actions';
import InfoModal from './InfoModal';
import { ReminderEmail, Survey } from './types';

const CAMPAIGNS_SCROLL_POSITION = 'CAMPAIGNS_SCROLL_POSITION';

const roundTcr = (tcr: any) => {
    if (!tcr?.value || tcr?.value <= -1) {
        return 0;
    }

    return precisionRound(tcr.value * 100, 1);
};

const EmptyContent = ({ archived }: { archived: boolean }) => {
    const history = useHistory();

    return (
        <div
            style={{
                minHeight: '50rem',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '1.6rem'
            }}
        >
            <div
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: '.8rem'
                }}
            >
                <h3
                    className="font-subtitle"
                    style={{
                        color: 'var(--color-ui-50)',
                        height: 'fit-content',
                        maxWidth: '40ch',
                        textAlign: 'center'
                    }}
                >
                    {archived ? (
                        <span>No archived campaigns yet</span>
                    ) : (
                        <span>
                            No campaigns yet. Create a new campaign to get
                            started.
                        </span>
                    )}
                </h3>
                {!archived && (
                    <p>
                        <a
                            className="link link--medium"
                            href="https://support.itsdandi.com/hc/en-us/sections/17117832105751-Self-ID"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Learn more about Dandi Self-ID campaigns.
                        </a>
                    </p>
                )}
            </div>
            {!archived && (
                <WithPermissions actions={[RbacActions['Campaign/Edit']]}>
                    <Button
                        onClick={() =>
                            history.push('/self-id/survey-templates')
                        }
                    >
                        Create campaign
                    </Button>
                </WithPermissions>
            )}
        </div>
    );
};

const Campaigns = () => {
    const {
        data: activeData,
        archived: archivedData,
        loaded,
        error,
        pending
    } = useAppSelector(state => state.snapshotSurveys.list);
    const [hoverIndex, setHoverIndex] = useState(-1);
    const [scrollValue, setScrollValue] = useState(0);
    const {
        loaded: audiencesLoaded,
        pending: audiencesPending,
        error: audiencesError
    } = useAppSelector(state => state.audiences.list);
    const dispatch = useAppDispatch();
    const [showArchived, setShowArchived] = useState(false);
    const data = showArchived ? archivedData : activeData;

    useEffect(() => {
        const onScroll = (e: any) => {
            setScrollValue(e.target.documentElement.scrollTop);
        };
        window.addEventListener('scroll', onScroll);

        return () => {
            sessionStorage.setItem(
                CAMPAIGNS_SCROLL_POSITION,
                scrollValue.toString()
            );
            window.removeEventListener('scroll', onScroll);
        };
    }, [scrollValue]);

    useEffect(() => {
        const scrollPosition = sessionStorage.getItem(
            CAMPAIGNS_SCROLL_POSITION
        );
        if (scrollPosition) {
            setScrollValue(parseInt(scrollPosition));
            window.scrollTo(0, parseInt(scrollPosition));
            sessionStorage.removeItem(CAMPAIGNS_SCROLL_POSITION);
        }
    }, []);

    useEffect(() => {
        if (!loaded && !pending && !error) {
            dispatch(getEnterpriseSurveys());
        }
        if (!audiencesLoaded && !audiencesPending && !audiencesError) {
            dispatch(getAudiences());
        }
    }, [
        loaded,
        pending,
        error,
        audiencesLoaded,
        audiencesPending,
        audiencesError
    ]);

    return (
        <WithPermissions
            actions={[RbacActions['Campaign/View']]}
            showMessage={true}
        >
            <ContentWrapper>
                <Stack
                    flexDirection="row"
                    justifyContent="flex-end"
                    gap=".8rem"
                    style={{ marginBottom: '2.4rem' }}
                >
                    <Button
                        activated={!showArchived}
                        onClick={() => setShowArchived(false)}
                        componentType={ButtonTypes.type.SECONDARY}
                    >
                        Active
                    </Button>
                    <Button
                        activated={showArchived}
                        onClick={() => setShowArchived(true)}
                        componentType={ButtonTypes.type.SECONDARY}
                    >
                        Archived
                    </Button>
                </Stack>
                <WithLoadingStates
                    isEmpty={!pending && loaded && data.length === 0}
                    isLoading={pending}
                    isError={!!error}
                    errorMessage="Sorry! We've encountered an issue in retrieving this information."
                    emptyContent={<EmptyContent archived={showArchived} />}
                >
                    <Grid base={1} gap="1.6rem">
                        {data.map((survey: Survey, index: number) => (
                            <Grid.Item key={survey.surveyId}>
                                <Card
                                    survey={survey}
                                    hoverIndex={hoverIndex}
                                    setHoverIndex={setHoverIndex}
                                    index={index}
                                    archived={showArchived}
                                />
                            </Grid.Item>
                        ))}
                    </Grid>
                </WithLoadingStates>
            </ContentWrapper>
        </WithPermissions>
    );
};

const Card = ({
    survey,
    hoverIndex,
    setHoverIndex,
    index,
    selectionExists,
    archived
}: {
    survey: Survey;
    hoverIndex: number;
    setHoverIndex: any;
    index: number;
    selectionExists?: boolean;
    archived: boolean;
}) => {
    const history = useHistory();
    const dispatch = useAppDispatch();
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [testEmailModalOpen, setTestEmailModalOpen] = useState(false);
    const [archiveConfirmationOpen, setArchiveConfirmationOpen] =
        useState(false);
    const [deletePending, setDeletePending] = useState(false);
    const [publishInfoOpen, setPublishInfoOpen] = useState(false);
    const [publishConfirmationOpen, setPublishConfirmationOpen] =
        useState(false);
    const [publishPending, setPublishPending] = useState(false);
    const [reminderModalOpen, setReminderModalOpen] = useState(false);

    const { enterpriseId, surveyId } = survey;

    const [archivePending, setArchivePending] = useState(false);
    const { tcr, tscnt, pending, loaded, error } = useAppSelector(
        state => state.snapshotSurveys.list.metrics[surveyId] || {}
    );
    const [menuOpen, setMenuOpen] = useState(false);

    const handleAction = (emails: string[]) => {
        dispatch(
            sendTestEmail({
                surveyId,
                enterpriseId,
                emails
            })
        )
            .then(() => {
                track(MixPanel.Events.SendTestEmail);
                setTestEmailModalOpen(false);
                dispatch(
                    addMessage(
                        `A test email has been sent for ${survey.title} campaign`
                    )
                );
            })
            .catch(() => {
                setTestEmailModalOpen(false);
            });
    };

    const handleClose = () => setTestEmailModalOpen(false);

    async function handlePreviewClick(e: any) {
        e.stopPropagation();
        const accessToken = await getAccessToken();
        const rbacToken = localStorage.getItem('rbacToken');
        const link = `${urls.LoggedOutApp}survey?id=${surveyId}&enterpriseId=${enterpriseId}&accessToken=${accessToken}&rbacToken=${rbacToken}`;
        window.open(link);
    }

    function handleSendTestEmail() {
        setTestEmailModalOpen(true);
        track(MixPanel.Events.ClickSendTestEmail);
    }

    function handleTogglePublish(e: any, survey: Survey) {
        e.stopPropagation();

        return dispatch(
            publishSnapshot({
                surveyIdOrGlobalSurveyId: surveyId,
                isPublished: !survey.isPublished
            })
        );
    }

    function handleArchive(e: any, survey: Survey) {
        e.stopPropagation();

        return dispatch(
            archiveSnapshot({
                survey,
                shouldArchive: !archived
            })
        );
    }

    async function handleSendReminder(reminderEmail: ReminderEmail) {
        await dispatch(
            updateSnapshotWithReminder({
                survey,
                reminderEmail
            })
        );
        await dispatch(sendReminder(survey.surveyId));
        dispatch(getEnterpriseSurveys());
    }

    useEffect(() => {
        if (!pending && !error && !loaded) {
            dispatch(getSurveyMetrics(surveyId));
        }
    }, [pending, loaded, error, surveyId]);

    return (
        <InfoCard
            onClick={() => history.push(`/self-id/campaigns/view/${surveyId}`)}
            state={
                selectionExists
                    ? 'selectionExists'
                    : hoverIndex !== -1
                    ? 'hoverExists'
                    : 'default'
            }
            onMouseEnter={() => setHoverIndex(index)}
            onMouseLeave={() => setHoverIndex(-1)}
            isHovering={hoverIndex === index}
        >
            <Stack
                style={{
                    padding: '1.6rem',
                    height: '100%',
                    cursor: 'pointer'
                }}
                gap="1.6rem"
                justifyContent="space-between"
            >
                <Stack
                    flexDirection="row"
                    alignItems="center"
                    gap="0.8rem"
                    justifyContent="space-between"
                >
                    <SurveyStatus
                        isPublished={survey.isPublished}
                        publishDate={survey.publishDate}
                        title={survey.title}
                        distributionType={
                            !!survey.audienceId ? 'email' : 'link'
                        }
                    />
                    <Stack
                        flexDirection="row"
                        alignItems="center"
                        gap="0.8rem"
                        justifyContent="space-between"
                    >
                        {!archived &&
                            survey.isPublished &&
                            !!survey.audienceId && (
                                <Button
                                    large={false}
                                    componentType={ButtonTypes.type.PRIMARY}
                                    onClick={(e: any) => {
                                        e.stopPropagation();
                                        setReminderModalOpen(true);
                                    }}
                                >
                                    Send Reminder
                                </Button>
                            )}

                        {!archived && (
                            <Stack flexDirection="row">
                                <WithPermissions
                                    actions={[RbacActions['Campaign/Edit']]}
                                >
                                    <Button
                                        large={false}
                                        componentType={
                                            survey.isPublished
                                                ? ButtonTypes.type.SECONDARY
                                                : ButtonTypes.type.PRIMARY
                                        }
                                        onClick={(e: any) => {
                                            e.stopPropagation();
                                            setPublishConfirmationOpen(true);
                                        }}
                                    >
                                        {survey.isPublished
                                            ? 'Unpublish'
                                            : 'Publish'}
                                    </Button>
                                    <sup>
                                        <Info
                                            className="btn-icon"
                                            width={20}
                                            height={20}
                                            onClick={(e: any) => {
                                                e.stopPropagation();
                                                setPublishInfoOpen(true);
                                            }}
                                        />
                                    </sup>
                                    <InfoModal
                                        isOpen={publishInfoOpen}
                                        onClose={() => {
                                            setPublishInfoOpen(false);
                                        }}
                                    >
                                        {survey.isPublished ? (
                                            <p>
                                                Unpublishing will pause this
                                                campaign and it will stop
                                                accepting responses.
                                            </p>
                                        ) : (
                                            <p>
                                                Publishing will activate this
                                                campaign to start accepting
                                                responses. If you chose email
                                                distribution, all recipients
                                                will be emailed instructions to
                                                access the campaign.
                                            </p>
                                        )}
                                    </InfoModal>
                                </WithPermissions>
                            </Stack>
                        )}

                        {!archived && (
                            <Button
                                large={false}
                                componentType={ButtonTypes.type.SECONDARY}
                                onClick={handlePreviewClick}
                            >
                                Preview
                            </Button>
                        )}

                        <WithPermissions
                            actions={[RbacActions['Campaign/Edit']]}
                        >
                            <div onClick={e => e.stopPropagation()}>
                                <Popover
                                    closeOnScroll={true}
                                    placement="bottom"
                                    overlayClassName="popover--no-padding"
                                    destroyTooltipOnHide
                                    showArrow={false}
                                    visible={menuOpen}
                                    onVisibleChange={() =>
                                        setMenuOpen(!menuOpen)
                                    }
                                    content={
                                        <Menu
                                            className="widget-menu dashboards-menu"
                                            onMouseLeave={() => {
                                                if (menuOpen) {
                                                    setMenuOpen(false);
                                                }
                                            }}
                                            selectedKeys={[]}
                                        >
                                            {!!survey.audienceId && (
                                                <Menu.Item
                                                    key="test-email"
                                                    onClick={
                                                        handleSendTestEmail
                                                    }
                                                >
                                                    Send test email
                                                </Menu.Item>
                                            )}
                                            {!archived && (
                                                <Menu.Item
                                                    key="edit-campaign"
                                                    onClick={() =>
                                                        history.push(
                                                            `/self-id/campaigns/edit/${survey.surveyId}`
                                                        )
                                                    }
                                                >
                                                    Edit
                                                </Menu.Item>
                                            )}
                                            <Menu.Item
                                                disabled={survey.isPublished}
                                                key="archive-campaign"
                                                onClick={() => {
                                                    setArchiveConfirmationOpen(
                                                        true
                                                    );
                                                }}
                                            >
                                                {archived
                                                    ? 'Unarchive'
                                                    : 'Archive'}
                                            </Menu.Item>
                                            <Menu.Item
                                                disabled={survey.isPublished}
                                                key="delete-campaign"
                                                onClick={() => {
                                                    setDeleteConfirmationOpen(
                                                        true
                                                    );
                                                }}
                                            >
                                                Delete
                                            </Menu.Item>
                                        </Menu>
                                    }
                                >
                                    <div
                                        onClick={e => e.stopPropagation()}
                                        style={{
                                            paddingTop: 4,
                                            height: '28px'
                                        }}
                                    >
                                        <More
                                            width={20}
                                            height={20}
                                            className="icon"
                                        />
                                    </div>
                                </Popover>
                            </div>
                        </WithPermissions>
                    </Stack>
                </Stack>
                <SurveyInformation survey={survey} tcr={tcr} tscnt={tscnt} />
            </Stack>
            <DeleteModal
                isOpen={deleteConfirmationOpen}
                pending={deletePending}
                onAction={e => {
                    e.stopPropagation();
                    setDeletePending(true);
                    dispatch(deleteSnapshot(surveyId))
                        .then(() => {
                            setDeletePending(false);
                            setDeleteConfirmationOpen(false);
                        })
                        .catch(() => {
                            setDeletePending(false);
                            setDeleteConfirmationOpen(false);
                        });
                }}
                onCancel={e => {
                    e.stopPropagation();
                    setDeletePending(false);
                    setDeleteConfirmationOpen(false);
                }}
                title="Delete campaign"
            >
                <p>Click Delete to permanently delete this campaign.</p>
                <p>IMPORTANT: This action cannot be undone.</p>
            </DeleteModal>
            <SendTestEmailModal
                isOpen={testEmailModalOpen}
                onAction={handleAction}
                onCancel={handleClose}
            ></SendTestEmailModal>
            <SendReminderModal
                survey={survey}
                isOpen={reminderModalOpen}
                onAction={handleSendReminder}
                onCancel={() => setReminderModalOpen(false)}
            />
            <ConfirmationModal
                title={
                    survey.isPublished
                        ? 'Unpublish Campaign'
                        : 'Publish Campaign'
                }
                isOpen={publishConfirmationOpen}
                pending={publishPending}
                confirmationText={survey.isPublished ? 'Unpublish' : 'Publish'}
                onAction={(e: any) => {
                    e.stopPropagation();
                    setPublishPending(true);
                    handleTogglePublish(e, survey)
                        .then(() => {
                            setPublishPending(false);
                            setPublishConfirmationOpen(false);
                        })
                        .catch(() => {
                            setPublishPending(false);
                            setPublishConfirmationOpen(false);
                        });
                }}
                onCancel={(e: any) => {
                    e.stopPropagation();
                    setPublishPending(false);
                    setPublishConfirmationOpen(false);
                }}
            >
                {survey.isPublished ? (
                    <p>
                        Unpublishing will pause this campaign and it will stop
                        accepting responses.
                    </p>
                ) : (
                    <p>
                        Publishing will activate this campaign to start
                        accepting responses. If you chose email distribution,
                        all recipients will be emailed instructions to access
                        the campaign.
                    </p>
                )}
            </ConfirmationModal>
            <ConfirmationModal
                title={archived ? 'Unarchive Campaign' : 'Archive Campaign'}
                isOpen={archiveConfirmationOpen}
                pending={archivePending}
                confirmationText={archived ? 'Unarchive' : 'Archive'}
                onAction={(e: any) => {
                    e.stopPropagation();
                    setArchivePending(true);
                    handleArchive(e, survey)
                        .then(() => {
                            setArchivePending(false);
                            setArchiveConfirmationOpen(false);
                        })
                        .catch(() => {
                            setArchivePending(false);
                            setArchiveConfirmationOpen(false);
                        });
                }}
                onCancel={(e: any) => {
                    e.stopPropagation();
                    setArchivePending(false);
                    setArchiveConfirmationOpen(false);
                }}
            >
                {!archived ? (
                    <p>
                        Archiving removes the campaign from the active view. Do
                        you want to archive?
                    </p>
                ) : (
                    <p>
                        Unarchiving restores the campaign to the active view. Do
                        you want to unarchive?
                    </p>
                )}
            </ConfirmationModal>
        </InfoCard>
    );
};

const SurveyInformation = ({
    survey,
    tcr,
    tscnt
}: {
    survey: Survey;
    tcr: any;
    tscnt: any;
}) => {
    const dateFormatter = new Intl.DateTimeFormat('en-US');
    const hasViewMetricsAccess = usePermissions({
        actions: [RbacActions['Campaign/ViewMetrics']]
    });
    const audience = useAppSelector(state =>
        state.audiences?.list?.data.find(
            a => a.audienceId === survey.audienceId
        )
    );

    return (
        <Stack gap=".2rem">
            <Stack gap="3.2rem">
                <Stack>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '3fr 1fr 2fr 2fr 2fr 2fr',
                            gap: '1.6rem'
                        }}
                    >
                        {hasViewMetricsAccess && (
                            <Fragment>
                                <div
                                    className="font-micro-text"
                                    style={{
                                        color: 'var(--color-ui-50)'
                                    }}
                                >
                                    {!audience ? (
                                        <span>Total submissions</span>
                                    ) : (
                                        <span>Completion</span>
                                    )}
                                </div>
                                <div />
                            </Fragment>
                        )}
                        <div
                            className="font-micro-text"
                            style={{
                                color: 'var(--color-ui-50)'
                            }}
                        >
                            Audience
                        </div>
                        <div
                            className="font-micro-text"
                            style={{
                                color: 'var(--color-ui-50)'
                            }}
                        >
                            Status
                        </div>
                        <div
                            className="font-micro-text"
                            style={{
                                color: 'var(--color-ui-50)'
                            }}
                        >
                            Cadence
                        </div>
                        <div
                            className="font-micro-text"
                            style={{
                                color: 'var(--color-ui-50)'
                            }}
                        >
                            End date
                        </div>
                    </div>
                    <div
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '3fr 1fr 2fr 2fr 2fr 2fr',
                            borderTop: '1px solid var(--color-ui-10)',
                            alignItems: 'center',
                            gap: '1.6rem'
                        }}
                    >
                        {hasViewMetricsAccess && (
                            <Fragment>
                                {!audience ? (
                                    <div
                                        style={{
                                            padding: '.8rem 0'
                                        }}
                                    >
                                        {tscnt?.value && tscnt?.value > -1
                                            ? tscnt.value
                                            : 0}
                                    </div>
                                ) : (
                                    <Bar completionRate={roundTcr(tcr)} />
                                )}
                                <div>
                                    {!audience ? (
                                        <span />
                                    ) : (
                                        <span>{roundTcr(tcr)}%</span>
                                    )}
                                </div>
                            </Fragment>
                        )}

                        <div>{audience?.title || '-'}</div>
                        <div>
                            {survey.isPublished
                                ? 'Published'
                                : !!survey.publishDate
                                ? 'Unpublished'
                                : 'Draft'}
                        </div>
                        <div>
                            {
                                cadenceOptions.find(
                                    o => o.id === survey.cadence.type
                                )?.label
                            }
                        </div>
                        <div>
                            {survey.endDate
                                ? dateFormatter.format(new Date(survey.endDate))
                                : ''}
                        </div>
                    </div>
                </Stack>
                {!survey.audienceId && (
                    <Stack flexDirection="row" alignItems="row" gap=".8rem">
                        <SmallPill
                            confirmationText="Copied!"
                            clipboardContent={
                                survey.link.startsWith('http')
                                    ? survey.link
                                    : `https://${survey.link}`
                            }
                        >
                            Copy link
                        </SmallPill>
                        <SmallPill
                            confirmationText="Copied!"
                            clipboardContent={survey.password}
                        >
                            Copy password
                        </SmallPill>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};

const SmallPill = ({
    children,
    confirmationText,
    clipboardContent
}: {
    children: React.ReactNode;
    confirmationText: string;
    clipboardContent: string;
}) => {
    const [copied, setCopied] = useState(false);
    function handleClipboard() {
        setCopied(true);
        navigator.clipboard.writeText(clipboardContent);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    return (
        <Stack
            justifyContent="center"
            onClick={(e: any) => {
                e.stopPropagation();
                handleClipboard();
            }}
            style={{
                background: 'var(--color-shade-3)',
                borderRadius: '1.6rem',
                padding: '.4rem 1.0rem',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden'
            }}
        >
            <span className="font-micro-text">
                {copied ? confirmationText : children}
            </span>
        </Stack>
    );
};

const SurveyStatus = ({
    isPublished,
    publishDate,
    distributionType,
    title
}: {
    isPublished: boolean;
    publishDate?: string;
    distributionType: 'email' | 'link';
    title: string;
}) => {
    const lightMode = useAppSelector(state => state.auth.userInfo.lightMode);

    return (
        <Stack flexDirection="row" alignItems="center" gap="1.6rem">
            {distributionType === 'link' ? (
                <Chain width={20} height={20} />
            ) : (
                <Mail width={20} height={20} />
            )}
            <h3 className="font-subtitle">{title}</h3>
            <div
                style={{
                    padding: '0.2rem .8rem',
                    borderRadius: '1.6rem',
                    background: isPublished
                        ? 'var(--color-graph-1)'
                        : 'var(--color-ui-75)'
                }}
            >
                <span
                    className="font-micro-text"
                    style={{
                        color: lightMode
                            ? 'var(--cd-color-ui-100'
                            : 'var(--cl-color-ui-100)'
                    }}
                >
                    {isPublished
                        ? 'Published'
                        : !!publishDate
                        ? 'Unpublished'
                        : 'Draft'}
                </span>
            </div>
        </Stack>
    );
};

const Bar = ({ completionRate }: { completionRate: number }) => (
    <div
        style={{
            position: 'relative',
            background: 'var(--color-shade-4)',
            minHeight: '5.2rem'
        }}
    >
        <div
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: `${completionRate}%`,
                height: '100%',
                background: 'var(--color-graph-1)'
            }}
        ></div>
    </div>
);

export default Campaigns;

import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import ContentWrapper from '../../components/ContentWrapper';
import { setEditModeInitialState } from './slice';
import { useEffect } from 'react';
import { Survey } from '../list/types';
import ValidCheck from '../../../icons/ValidCheck';
import { useParams } from 'react-router';
import { getEnterpriseSurveys } from '../list/actions';
import Tabs from './Components/Tabs';
import WithPermissions from '../../../common/WithPermissions';
import RbacActions from '../../../constants/RbacActions';

const CampaignEdit = () => {
    const dispatch = useAppDispatch();
    const { data, loaded, pending, error } = useAppSelector(
        state => state.snapshotSurveys.list
    );
    const params = useParams<any>();

    useEffect(() => {
        if (!loaded && !pending && !error) {
            dispatch(getEnterpriseSurveys());
        }
    }, [loaded, pending, error]);

    useEffect(() => {
        if (!data) return;

        const campaign: Survey | undefined = data.find(
            (survey: Survey) => survey.surveyId === params.id
        );

        if (!campaign) {
            return;
        }

        dispatch(setEditModeInitialState(campaign));
    }, [data]);

    return (
        <WithPermissions
            actions={[RbacActions['Campaign/Edit']]}
            showMessage={true}
        >
            <ContentWrapper>
                <Tabs />
            </ContentWrapper>
        </WithPermissions>
    );
};

export default CampaignEdit;

import ActionTypes from '../../constants/ActionTypes';
import uniq from 'lodash/uniq';
import _ from 'lodash';

const defaultSortCriteria = {
    columnName: 'dateAdded',
    ascending: false
};

const initialState = {
    searches: [],
    showDelete: false,
    showEdit: false,
    sortCriteria: defaultSortCriteria
};

interface ReducerState {
    searches: any[];
    showDelete: boolean;
    showEdit: boolean;
    sortCriteria: any;
    pending?: boolean;
    error?: boolean;
    deleteReportId?: string;
    editReportId?: string;
}

export default function searchesReducer(
    state: ReducerState = initialState,
    action: any
): ReducerState {
    switch (action.type) {
        case ActionTypes.GetSearchesPending: {
            return {
                ...state,
                pending: true,
                error: false
            };
        }
        case ActionTypes.GetSearchesFulfilled: {
            const { searches } = action.payload;
            return {
                ...state,
                pending: false,
                error: false,
                searches
            };
        }
        case ActionTypes.GetSearchesRejected: {
            return {
                ...state,
                pending: false,
                error: true
            };
        }
        case ActionTypes.GetSearchPending: {
            return {
                ...state,
                pending: true,
                error: false
            };
        }
        case ActionTypes.GetSearchFulfilled: {
            const { searches } = action.payload;
            return {
                ...state,
                pending: false,
                error: false,
                searches
            };
        }
        case ActionTypes.GetSearchRejected: {
            return {
                ...state,
                pending: false,
                error: true
            };
        }
        case ActionTypes.CreateSearchPending: {
            return {
                ...state,
                pending: true,
                error: false
            };
        }
        case ActionTypes.CreateSearchFulfilled: {
            // convert to immer?
            const { search } = action.payload;
            let searches = _.cloneDeep(state.searches);
            searches = searches.filter(s => {
                return s.reportId !== search.reportId;
            });
            searches = uniq([search, ...searches]);
            return {
                ...state,
                pending: false,
                error: false,
                searches
            };
        }
        case ActionTypes.CreateSearchRejected: {
            return {
                ...state,
                pending: false,
                error: true
            };
        }
        case ActionTypes.RemoveSearchPending: {
            return {
                ...state,
                pending: true,
                error: false
            };
        }
        case ActionTypes.RemoveSearchFulfilled: {
            const { reportId } = action.payload;
            const searches = state.searches.filter(
                search => search.reportId !== reportId
            );
            return {
                ...state,
                pending: false,
                error: false,
                searches
            };
        }
        case ActionTypes.RemoveSearchRejected: {
            const { error } = action.payload;
            return {
                ...state,
                pending: false,
                error: true
            };
        }
        case ActionTypes.ShowDeleteSearchModal: {
            return {
                ...state,
                showDelete: true,
                deleteReportId: action.payload.reportId
            };
        }
        case ActionTypes.CloseDeleteSearchModal: {
            return {
                ...state,
                showDelete: false,
                deleteReportId: undefined
            };
        }
        case ActionTypes.ShowEditSearchModal: {
            return {
                ...state,
                showEdit: true,
                editReportId: action.payload.reportId
            };
        }
        case ActionTypes.CloseEditSearchModal: {
            return {
                ...state,
                showEdit: false,
                editReportId: undefined
            };
        }
        default:
            return state;
    }
}

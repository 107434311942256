import { useReducer } from 'react';
import { initialState, stateMachine } from './reducer';

const useSegmentPicker = ({
    options,
    editState
}: {
    options: any[];
    editState?: any;
}) => {
    const [state, dispatch] = useReducer(stateMachine, {
        ...initialState,
        ...editState,
        options
    });

    return [state, dispatch];
};

export default useSegmentPicker;

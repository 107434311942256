import React from 'react';
import { Switch, Route } from 'react-router-dom';
import NotFound from '../routes/NotFound';
import Configurations from './configuration/Configurations';
import ConfigurationDetail from './configuration/ConfigurationDetail';
import Templates from './template/Templates';
import TemplateDetail from './template/TemplateDetail';
import TemplateEdit from './template/TemplateEdit';
import Packages from './package/Packages';
import PackageEdit from './package/PackageEdit';
import PackageDetail from './package/PackageDetail';
import Enterprises from './enterprise/Enterprises';
import EnterpriseDetail from './enterprise/EnterpriseDetail';
import EnterpriseEdit from './enterprise/EnterpriseEdit';
import ConfigurationEdit from './configuration/ConfigurationEdit';
import ScrollToTop from './ScrollToTop';
import CreateAccount from './account/CreateAccount';
import SurveyTemplates from './survey/template/Templates';
import SurveyTemplateEdit from './survey/template/TemplateEdit';
import Questions from './survey/question/Questions';
import QuestionEdit from './survey/question/QuestionEdit';
import Categories from './survey/category/Categories';
import CategoryEdit from './survey/category/CategoryEdit';
import SurveyTemplateQuestions from './survey/template/Questions';

export default function ContentArea() {
    return (
        <div className="layout__content overflow-hidden pt-[120px]">
            <ScrollToTop />
            <Switch>
                <Route
                    path="/datastudio/templates/new"
                    component={TemplateEdit}
                />
                <Route
                    path="/datastudio/templates/:id/edit"
                    component={TemplateEdit}
                />
                <Route
                    path="/datastudio/templates/:id"
                    component={TemplateDetail}
                />
                <Route path="/datastudio/templates" component={Templates} />
                <Route
                    path="/datastudio/packages/new"
                    component={PackageEdit}
                />
                <Route
                    path="/datastudio/packages/:id/edit"
                    component={PackageEdit}
                />
                <Route
                    path="/datastudio/packages/:id"
                    component={PackageDetail}
                />
                <Route path="/datastudio/packages" component={Packages} />
                <Route
                    path="/datastudio/enterprises/:id/edit"
                    component={EnterpriseEdit}
                />
                <Route
                    path="/datastudio/enterprises/:id"
                    component={EnterpriseDetail}
                />
                <Route path="/datastudio/enterprises" component={Enterprises} />
                <Route
                    path="/datastudio/config/new"
                    component={ConfigurationEdit}
                />
                <Route
                    path="/datastudio/config/:id/edit"
                    component={ConfigurationEdit}
                />
                <Route
                    path="/datastudio/config/:id"
                    component={ConfigurationDetail}
                />
                <Route
                    path="/datastudio/createAccount"
                    component={CreateAccount}
                />
                <Route
                    path="/datastudio/survey/templates"
                    component={SurveyTemplates}
                />
                <Route
                    path="/datastudio/survey/questions"
                    component={Questions}
                />
                <Route
                    path="/datastudio/survey/question/edit"
                    component={QuestionEdit}
                />
                <Route
                    path="/datastudio/survey/categories"
                    component={Categories}
                />
                <Route
                    path="/datastudio/survey/category/edit"
                    component={CategoryEdit}
                />
                <Route
                    path="/datastudio/survey/template/edit"
                    component={SurveyTemplateEdit}
                />
                <Route
                    path="/datastudio/survey/template/questions"
                    component={SurveyTemplateQuestions}
                />
                <Route path="/datastudio" component={Configurations} />
                <Route component={NotFound} />
            </Switch>
        </div>
    );
}

import { useParams } from 'react-router';

import Stack from '../../../../common/Stack';
import { useAppSelector } from '../../../../store/hooks';
import { Survey } from '../../../../datastudio/survey/template/types';

const Header = () => {
    const { data } = useAppSelector(state => state.surveyTemplates.list);

    const params = useParams<any>();

    const survey = data.find(
        (survey: Survey) => survey.globalSurveyId === params.id
    );

    if (!survey) {
        return null;
    }

    return (
        <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            style={{
                width: '100%',
                paddingRight: '1.6rem'
            }}
        >
            <div className="nav__header__heading">
                <h3 className="font-title">{survey.title}</h3>
            </div>
        </Stack>
    );
};

export default Header;

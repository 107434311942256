import produce from 'immer';
import React, { ChangeEvent, useState } from 'react';
import Accordion from '../Accordion';
import Dropdown from '../../common/Dropdown';
import { exclusionTypeOptions } from '../constants';
import { ExclusionRule as ExclusionRuleModel } from './model';
import TextField from '../../common/TextField';

interface ExclusionRuleProps {
    index: number;
    innerIndex: number;
    setConfigurationVariables: any;
    exclusionRule: ExclusionRuleModel;
}

export default function ExclusionRule({
    index,
    exclusionRule,
    setConfigurationVariables,
    innerIndex
}: ExclusionRuleProps) {
    const [expanded, setExpanded] = useState(exclusionRule.limitMain === 0);

    function handleRemove() {
        setConfigurationVariables((configurationVariables: any) =>
            produce(configurationVariables, (draftState: any) => {
                draftState[index].exclusionRules.splice(innerIndex, 1);
            })
        );
    }

    function handleTypeChange(value: string) {
        setConfigurationVariables((configurationVariables: any) =>
            produce(configurationVariables, (draftState: any) => {
                const propName =
                    draftState[index].exclusionRules[innerIndex]
                        .exclusionType === 'LIMIT_MAIN'
                        ? 'limitMain'
                        : 'limitControl';
                const otherPropName =
                    propName === 'limitMain' ? 'limitControl' : 'limitMain';
                const limit =
                    draftState[index].exclusionRules[innerIndex][propName];
                draftState[index].exclusionRules[innerIndex].exclusionType =
                    value;
                draftState[index].exclusionRules[innerIndex][otherPropName] =
                    limit;
                delete draftState[index].exclusionRules[innerIndex][propName];
            })
        );
    }

    function handleLimitChange(e: ChangeEvent<HTMLInputElement>) {
        setConfigurationVariables((configurationVariables: any) =>
            produce(configurationVariables, (draftState: any) => {
                const propName =
                    draftState[index].exclusionRules[innerIndex]
                        .exclusionType === 'LIMIT_MAIN'
                        ? 'limitMain'
                        : 'limitControl';
                draftState[index].exclusionRules[innerIndex][propName] =
                    parseInt(e.target.value) || 0;
            })
        );
    }

    return (
        <Accordion
            label={`Exclusion rule ${innerIndex + 1}`}
            expanded={expanded}
            setExpanded={setExpanded}
            remove={handleRemove}
        >
            <div className="ds-label">Exclusion type</div>
            <div className="ds-input" style={{ marginTop: 8 }}>
                <Dropdown
                    placeholder="Select"
                    options={exclusionTypeOptions}
                    selectedOption={exclusionRule.exclusionType}
                    onClick={handleTypeChange}
                />
            </div>
            <div className="ds-label">Limit</div>
            <div className="ds-input" style={{ marginTop: 8 }}>
                <TextField
                    value={
                        (exclusionRule.exclusionType === 'LIMIT_MAIN'
                            ? exclusionRule.limitMain?.toString()
                            : exclusionRule.limitControl?.toString()) || ''
                    }
                    onChange={handleLimitChange}
                />
            </div>
        </Accordion>
    );
}

import IEBar from '../common/IEBar';
import MessageBar from '../common/MessageBar';
import PermanentBar from '../common/PermanentBar';
import Banner from './Banner';
import ContentArea from './ContentArea';
import Footer from './Footer';
import TopBar from './TopBar';
import TopMenu from './TopMenu';
import './styles.scss';
import cn from '../lib/cn';

function Layout({ aiLayout }: { aiLayout: boolean }) {
    return (
        <div className="layout">
            <IEBar />
            <Banner />
            <TopBar TopMenu={TopMenu} aiLayout={aiLayout} />
            <ContentArea aiLayout={aiLayout} />
            <MessageBar />
            <PermanentBar />
            <Footer />
            <canvas id="the-canvas" className={cn('hidden')} />
        </div>
    );
}

export default Layout;

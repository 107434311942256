import { useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import { getAllChatConversationMetadata, getChatConversationsList } from './conversations/actions';

export default function useConversations() {
    const dispatch = useAppDispatch();
    const {
        loaded: conversationsLoaded,
        pending: conversationsPending,
        error: conversationsError,
        conversations,
        conversationsIds,
    } = useAppSelector((state) => state.ai.conversations);

    useEffect(() => {
        if (!conversationsLoaded && !conversationsPending && !conversationsError) {
            dispatch(getAllChatConversationMetadata());
            dispatch(getChatConversationsList());
        }
    }, [conversations, conversationsError, conversationsIds, conversationsLoaded, conversationsPending, dispatch]);

    return {
        conversations,
        conversationsIds,
    };
}

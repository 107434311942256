import { createSlice } from '@reduxjs/toolkit';
import actionTypes from '../../../constants/ActionTypes';
import { createSnapshot } from './actions';
import { CadenceRunType, ISnapshotSurveyEditState } from './types';
import { reconstructCategories, validateAll, validateStep } from './utils';

const initialState: ISnapshotSurveyEditState = {
    selectedIndex: 0,
    isBeginning: true,
    isEnd: false,
    validated: false,
    steps: [
        {
            label: 'Privacy',
            validated: false,
            disabled: false
        },
        {
            label: 'Distribution & Audience',
            validated: false,
            disabled: true
        },
        {
            label: 'Data Storage',
            validated: false,
            disabled: true
        },
        {
            label: 'Messaging',
            validated: false,
            disabled: true
        }
    ],
    mode: 'create',
    title: '',
    description: '',
    enterpriseId: '',
    globalSurveyId: '',
    audienceId: '',
    link: '',
    password: '',
    cadenceRunType: undefined,
    cadenceRunEachNDays: undefined,
    score: 0,
    categories: [],
    emailSubject: '',
    welcomeSubject: '',
    thankYouSubject: '',
    questionOrder: ''
};

const slice = createSlice({
    name: 'snapshotSurveyEdit',
    initialState,
    reducers: {
        nextStep: state => {
            const nextIndex = Math.min(
                state.selectedIndex + 1,
                state.steps.length - 1
            );
            state.selectedIndex = nextIndex;
            state.isBeginning = state.selectedIndex === 0;
            state.isEnd = state.selectedIndex === state.steps.length - 1;
            state.steps[nextIndex].validated = validateStep({
                index: nextIndex,
                state
            });
            state.validated = validateAll({ state });
        },
        previousStep: state => {
            state.selectedIndex = Math.max(state.selectedIndex - 1, 0);
            state.isBeginning = state.selectedIndex === 0;
            state.isEnd = state.selectedIndex === state.steps.length - 1;
        },
        setStep: (state, action) => {
            state.selectedIndex = action.payload;
            state.isBeginning = state.selectedIndex === 0;
            state.isEnd = state.selectedIndex === state.steps.length - 1;
            state.steps[action.payload].validated = validateStep({
                index: action.payload,
                state
            });
            state.validated = validateAll({ state });
        },
        copyTemplate: (state, action) => {
            state.title = action.payload.title;
            state.description = action.payload.description;
            state.globalSurveyId = action.payload.globalSurveyId;
            state.enterpriseId = action.payload.enterpriseId;
            state.categories = reconstructCategories(action.payload.categories);
            state.emailSubject = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'emailSubject'
            )?.value;
            state.emailBody = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'emailBody'
            )?.value;
            state.emailTitle = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'emailTitle'
            )?.value;
            state.welcomeSubject = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'welcomeTitle'
            )?.value;
            state.welcomeBody = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'welcomeDesc'
            )?.value;
            state.thankYouSubject = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'thankTitle'
            )?.value;
            state.thankYouBody = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'thankDesc'
            )?.value;
            state.thankImage = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'thankImage'
            )?.value;
            state.questionOrder = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'questionOrder'
            )?.value;
            state.reminderEmailSubject = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'remindEmailSubject'
            )?.value;
            state.reminderEmailTitle = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'remindEmailTitle'
            )?.value;
            state.reminderEmailBody = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'remindEmailBody'
            )?.value;
        },
        setMode: (state, action) => {
            state.mode = action.payload;
        },
        setPrivacy: (state, action) => {
            state.privacy = action.payload;
            state.distributionType = undefined;
            state.cadenceRunType = undefined;
            state.cadenceRunEachNDays = undefined;
            state.endDate = undefined;
            state.audienceId = undefined;
            const validated = validateStep({ index: 0, state });
            state.steps[0].validated = validated;
            state.steps[1].validated = validateStep({
                index: 1,
                state
            });
            state.steps[1].disabled = !validated;
            state.validated = validateAll({ state });
        },
        setDistributionType: (state, action) => {
            state.distributionType = action.payload;
            if (action.payload === 'link') {
                state.audienceId = undefined;
                state.cadenceRunType = undefined;
                state.cadenceRunEachNDays = undefined;
                state.endDate = undefined;
            }
            const validated = validateStep({ index: 1, state });
            state.steps[1].validated = validateStep({
                index: 1,
                state
            });
            state.steps[2].disabled = !validated;
            state.validated = validateAll({ state });
        },
        setAudience: (state, action) => {
            state.audienceId = action.payload;
            const validated = validateStep({
                index: 1,
                state
            });
            state.steps[1].validated = validated;
            state.steps[2].disabled = !validated;
            state.validated = validateAll({ state });
        },
        setCadence: (state, action) => {
            state.cadenceRunType = action.payload;
            if (state.cadenceRunType !== CadenceRunType.n_days) {
                state.cadenceRunEachNDays = undefined;
            }

            const validated = validateStep({
                index: 1,
                state
            });
            state.steps[1].validated = validated;
            state.steps[2].disabled = !validated;
            state.validated = validateAll({ state });
        },
        setCadenceNDays: (state, action) => {
            state.cadenceRunEachNDays = Number(action.payload);
            const validated = validateStep({
                index: 1,
                state
            });
            state.steps[1].validated = validated;
            state.validated = validateAll({ state });
        },
        setEndDate: (state, action) => {
            state.endDate = action.payload;
            const validated = validateStep({
                index: 1,
                state
            });
            state.steps[1].validated = validated;
            state.steps[2].disabled = !validated;
            state.validated = validateAll({ state });
        },
        setStorage: (state, action) => {
            state.storage = action.payload;
            const validated = validateStep({
                index: 2,
                state
            });
            state.steps[2].validated = validated;
            state.steps[3].disabled = !validated;
            state.validated = validateAll({ state });
        },
        setEmailSubject: (state, action) => {
            state.emailSubject = action.payload;
            state.steps[3].validated = validateStep({
                index: 3,
                state
            });
            state.validated = validateAll({ state });
        },
        setEmailTitle: (state, action) => {
            state.emailTitle = action.payload;
            state.steps[3].validated = validateStep({
                index: 3,
                state
            });
            state.validated = validateAll({ state });
        },
        setEmailBody: (state, action) => {
            state.emailBody = action.payload;
        },
        setWelcomeSubject: (state, action) => {
            state.welcomeSubject = action.payload;
            state.steps[3].validated = validateStep({
                index: 3,
                state
            });
            state.validated = validateAll({ state });
        },
        setWelcomeBody: (state, action) => {
            state.welcomeBody = action.payload;
        },
        setThankYouSubject: (state, action) => {
            state.thankYouSubject = action.payload;
            state.steps[3].validated = validateStep({
                index: 3,
                state
            });
            state.validated = validateAll({ state });
        },
        setThankYouBody: (state, action) => {
            state.thankYouBody = action.payload;
        },
        setReminderEmailSubject: (state, action) => {
            state.reminderEmailSubject = action.payload;
        },
        setReminderEmailTitle: (state, action) => {
            state.reminderEmailTitle = action.payload;
        },
        setReminderEmailBody: (state, action) => {
            state.reminderEmailBody = action.payload;
        },
        setTitle: (state, action) => {
            state.title = action.payload;
        },
        setDescription: (state, action) => {
            state.description = action.payload;
        },
        setEnterpriseId: (state, action) => {
            state.enterpriseId = action.payload;
        },
        setGlobalSurveyId: (state, action) => {
            state.globalSurveyId = action.payload;
        },
        setEditModeInitialState: (state, action) => {
            state.mode = 'edit';
            state.selectedIndex = 1;
            state.isPublished = action.payload.isPublished;
            state.emailMappingId = action.payload.emailMappingId;
            state.title = action.payload.title;
            state.description = action.payload.description;
            state.globalSurveyId = action.payload.globalSurveyId;
            state.surveyId = action.payload.surveyId;
            state.audienceId = action.payload.audienceId;
            state.privacy = action.payload.privacy.type;
            state.storage = action.payload.storage.type;
            state.cadenceRunType = action.payload.cadence.type;
            state.categories = action.payload.categories;
            state.distributionType = action.payload.audienceId
                ? 'email'
                : 'link';
            state.endDate = action.payload.endDate;
            state.emailSubject = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'emailSubject'
            )?.value;
            state.emailBody = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'emailBody'
            )?.value;
            state.emailTitle = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'emailTitle'
            )?.value;
            state.welcomeSubject = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'welcomeSubject'
            )?.value;
            state.welcomeBody = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'welcomeBody'
            )?.value;
            state.thankYouSubject = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'thankYouSubject'
            )?.value;
            state.thankYouBody = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'thankYouBody'
            )?.value;
            state.thankImage = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'thankImage'
            )?.value;
            state.questionOrder = action.payload.meta.uiElements.find(
                (el: any) => el.key === 'questionOrder'
            )?.value;
            state.steps[0].validated = validateStep({
                index: 0,
                state
            });
            state.steps[0].disabled = false;
            state.steps[1].validated = validateStep({
                index: 1,
                state
            });
            state.steps[1].disabled = false;
            state.steps[2].validated = validateStep({
                index: 2,
                state
            });
            state.steps[2].disabled = false;
            state.steps[3].validated = validateStep({
                index: 3,
                state
            });
            state.steps[3].disabled = false;
            state.validated = validateAll({ state });
        },
        reset: () => initialState
    },
    extraReducers: builder => {
        builder.addCase(createSnapshot.pending, (state, action) => {
            state.pending = true;
        });
        builder.addCase(createSnapshot.fulfilled, (state, action) => {
            state.pending = false;
            state.finished = true;
        });
        builder.addCase(createSnapshot.rejected, (state, action) => {
            state.pending = false;
            state.error = action.error.message;
        });
        // reset create form on route change
        builder.addCase(actionTypes.ChangeLocation, () => initialState);
    }
});

export const {
    nextStep,
    previousStep,
    setStep,
    setMode,
    setPrivacy,
    setDistributionType,
    setStorage,
    setTitle,
    setDescription,
    setEnterpriseId,
    setGlobalSurveyId,
    setEmailSubject,
    setEmailTitle,
    setEmailBody,
    setWelcomeSubject,
    setWelcomeBody,
    setThankYouSubject,
    setThankYouBody,
    copyTemplate,
    setAudience,
    setCadence,
    setCadenceNDays,
    setEndDate,
    setEditModeInitialState,
    reset
} = slice.actions;
export default slice.reducer;

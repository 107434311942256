import ActionTypes from '../../constants/ActionTypes';
import qs from 'qs';
import { push } from 'connected-react-router';
import { getCommentCount } from '../../comments/actions';
import { fetchBySegment } from '../detail/actions';
import Constants from '../../constants/Constants';
import { generate, getTemplateFromAnalysisName } from '../../store/actionUtils';
import { Segment } from '../../types';

export function clearFilters() {
    return (dispatch: any) => {
        // TODO: does order matter here?
        dispatch({
            type: ActionTypes.ClearFilters
        });
        dispatch(push('/explore'));
    };
}

export function generateInExplore() {
    return (dispatch: any, getState: any) => {
        let { interval, intervalCount, startDate, endDate, date } =
            getState().explore.filter;

        const query = qs.parse(location.search, { ignoreQueryPrefix: true });
        const { chartType, yAxisType, indexType, subtableChartType } = query;

        const {
            analysisDisplayName,
            analysisName,
            subAnalyses,
            mainSegments,
            supportedGraphs,
            filterSegment,
            breakdownSegment,
            attributes
        } = getState().explore.filter;

        const state = {
            interval,
            intervalCount,
            startDate,
            endDate,
            date,
            analysisDisplayName,
            analysisName,
            subAnalyses,
            supportedGraphs,
            mainSegments,
            filterSegment,
            breakdownSegment,
            attributes,
            eventIds: [],
            yAxisType,
            indexType,
            chartType,
            subtableChartType
        };

        const actionTypes = {
            reportPending: ActionTypes.GeneratePending,
            subtablePending: ActionTypes.GenerateSubtablePending,
            reportRejected: ActionTypes.GenerateRejected,
            subtableRejected: ActionTypes.GenerateSubtableRejected,
            invalidVariation: ActionTypes.GenerateInvalidVariation,
            noData: ActionTypes.GenerateNoData,
            subtableFulfilled: ActionTypes.GenerateSubtableFulfilled,
            reportFulfilled: ActionTypes.GenerateFulfilled,
            setSections: ActionTypes.SetDynamicSections,
            clearSections: ActionTypes.ClearDynamicSections,
            primaryDataFulfilled:
                ActionTypes.GeneratePrimaryDataFulfilledInExplore
        };

        return generate(getState, dispatch, state, actionTypes).then(() => {
            dispatch(getCommentCount());

            const noData = getState().explore.chart.generateNoData;
            getState().explore.detail.dynamic.sections.forEach(
                (section: any) => {
                    const { open, employeeAttribute } = section;
                    if (open) {
                        if (noData) {
                            dispatch({
                                type: ActionTypes.CloseSegment,
                                employeeAttribute: employeeAttribute
                            });
                        } else {
                            dispatch(fetchBySegment(employeeAttribute));
                        }
                    }
                }
            );
        });
    };
}

export function navigate() {
    return (dispatch: any, getState: any) => {
        const {
            analysisName,
            subAnalyses,
            mainSegments,
            filterSegment,
            breakdownSegment,
            interval,
            intervalCount,
            startDate,
            endDate
        } = getState().explore.filter;

        const { yAxisType, indexType, chartType } = getState().explore.chart;
        const { subtableChartType } = getState().explore.subtable;

        const queryObject: any = {
            lifecycle: analysisName,
            mainSegments,
            filterSegment,
            breakdownSegment,
            subAnalyses: subAnalyses.map((sub: any) => ({
                name: sub.name,
                source: sub.source
            })),
            yAxisType,
            indexType,
            chartType,
            subtableChartType
        };

        if (startDate) {
            queryObject.startDate = startDate;
            queryObject.endDate = endDate;
        } else if (intervalCount !== Constants.DefaultIntervalCount) {
            queryObject.intervalCount = intervalCount;
        }

        if (interval !== Constants.DefaultInterval) {
            queryObject.interval = interval;
        }

        let url = '/explore?';

        const queryString = qs.stringify(queryObject, {
            encodeValuesOnly: true
        });

        url += queryString;
        dispatch(push(url));
    };
}

export function setAnalysisName({
    analysisDisplayName,
    analysisName,
    subAnalyses
}: any) {
    return (dispatch: any) => {
        if (!analysisName) {
            return dispatch(clearFilters());
        }

        return dispatch(getTemplateFromAnalysisName(analysisName)).then(
            (template: any) => {
                const {
                    mainNames,
                    filterNames,
                    breakdownNames,
                    variations,
                    analysisType,
                    supportedGraphs
                } = template;
                dispatch({
                    type: ActionTypes.SetAnalysisName,
                    analysisDisplayName,
                    analysisName,
                    analysisType,
                    subAnalyses,
                    mainNames,
                    filterNames,
                    breakdownNames,
                    variations,
                    supportedGraphs
                });
            }
        );
    };
}

export function updateMainSegments(fields: Segment[]) {
    return {
        type: ActionTypes.UpdateMainSegments,
        fields
    };
}

export function updateFilterSegment(fields: Segment[]) {
    return {
        type: ActionTypes.UpdateFilterSegment,
        segment: fields.length ? fields[0] : undefined
    };
}

export function updateBreakdownSegment(fields: Segment[]) {
    return {
        type: ActionTypes.UpdateBreakdownSegment,
        segment: fields.length ? fields[0] : undefined
    };
}

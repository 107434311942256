import React, { useEffect } from 'react';
import { fetchCollect } from './reducer';
import MessageBar from '../common/MessageBar';
import Forbidden from '../routes/Forbidden';
import Loader from '../lottie/graph-loader.json';
import Card from './Card';
import './styles.scss';
import { page } from '../lib/segment';
import MixPanel from '../constants/MixPanel';
import LottieAnimation from '../common/LottieAnimation';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { RouteComponentProps } from 'react-router-dom';
import WithPermissions from '../common/WithPermissions';
import RbacActions from '../constants/RbacActions';

export default function Collect({ history }: RouteComponentProps<{}>) {
    const { loaded, packages } = useAppSelector(state => state.collect);
    const dispatch = useAppDispatch();

    useEffect(() => {
        page(MixPanel.Pages.Collect);
        dispatch(fetchCollect());
    }, []);

    return (
        <WithPermissions
            actions={[RbacActions['Collect/View']]}
            showMessage={true}
        >
            {loaded ? (
                !!packages?.length ? (
                    <div className="collect">
                        {packages.map((p, i) => (
                            <Card
                                key={i}
                                title={p.title}
                                summary={p.summary}
                                attributes={p.attributes}
                            />
                        ))}
                        <MessageBar />
                    </div>
                ) : (
                    <div className="collect--center">
                        <div className="collect--center__empty-state">
                            Your data completion analysis will be ready after we
                            complete processing your first data set
                        </div>
                    </div>
                )
            ) : (
                <div className="collect--center">
                    <LottieAnimation
                        autoplay={true}
                        loop={true}
                        width={60}
                        height={60}
                        animation={Loader}
                    ></LottieAnimation>
                </div>
            )}
        </WithPermissions>
    );
}

import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import { useEffect, useState } from 'react';

import MixPanel from '../../constants/MixPanel';
import FilterDropdown from '../../explore/filter/FilterDropdown';
import { track } from '../../lib/segment';
import stripSpaces from '../../lib/stripSpaces';
import {
    Attribute,
    AttributeValue,
    FilterSegment,
    SegmentFilter,
    SegmentName
} from '../../types';
import DashboardFilterValues from './DashboardFilterValues';
import DashboardFiltersList, { isSegmentChecked } from './DashboardFiltersList';

type DashboardFilterDropdownProps = {
    attributes: Attribute[];
    dashboardName: string;
    dynamicPermissionsFilter: SegmentFilter[];
    placeholder: string;
    segmentNames: SegmentName[];
    selection: FilterSegment | undefined;
    update: (segments?: FilterSegment) => void;
};

export default function DashboardFilterDropdown({
    attributes,
    dashboardName,
    dynamicPermissionsFilter,
    placeholder,
    segmentNames,
    selection,
    update
}: DashboardFilterDropdownProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [segments, setSegments] = useState(cloneDeep(attributes));

    let segmentValues: AttributeValue[] = [];
    const activeSegment = segments?.find((s: Attribute) => s.active);
    if (activeSegment) {
        segmentValues = activeSegment.attributeValues;
    }

    const checkedSegment = segments?.find(isSegmentChecked);
    const label =
        checkedSegment?.attributeValues.find(av => av.checked)?.text || '';

    useEffect(() => {
        if (isOpen) {
            track(MixPanel.Events.DashboardOpenSegmentFilter, {
                'Dashboard Name': dashboardName
            });
        }
    }, [isOpen]);

    useEffect(() => {
        const fieldNames = dynamicPermissionsFilter.map(sn => sn.fieldName);
        const hasWildcard = fieldNames.includes('*');

        setSegments(
            cloneDeep(attributes)
                .filter((attr: Attribute) => {
                    const wildCard = hasWildcard ? segmentNames : [];

                    return (
                        fieldNames.includes(stripSpaces(attr.attributeName)) ||
                        wildCard
                            .map(d => d.name)
                            .includes(stripSpaces(attr.attributeName))
                    );
                })
                .map((attr: Attribute) => ({
                    ...attr,
                    attributeValues: attr.attributeValues.filter(av => {
                        if (hasWildcard) return true;

                        const segmentName = dynamicPermissionsFilter.find(
                            sn =>
                                sn.fieldName === stripSpaces(attr.attributeName)
                        );

                        return (
                            segmentName &&
                            (segmentName.fieldValues.includes(av.value) ||
                                segmentName.fieldValues.includes('*'))
                        );
                    })
                }))
        );
    }, [attributes, dynamicPermissionsFilter, segmentNames]);

    useEffect(() => {
        setSegments((segments: Attribute[]) => {
            return produce(segments, (segments: Attribute[]) => {
                segments?.forEach((s: Attribute) => {
                    s.checked = false;
                    s.attributeValues.forEach((av: AttributeValue) => {
                        av.checked = false;
                    });
                    if (segmentNames) {
                        const segmentName = segmentNames.find(
                            sn => sn.name === s.attributeName.replace(/ /g, '')
                        );

                        s.removed = !segmentName;
                        s.disabled = segmentName && segmentName.disabled;
                        if (s.disabled || s.removed) {
                            s.active = false;
                        }
                    }
                });
                const segment = segments?.find(
                    (s: Attribute) =>
                        s.attributeName?.replace(/ /g, '').toLowerCase() ===
                        selection?.name?.replace(/ /g, '').toLowerCase()
                );
                if (segment) {
                    segment.disabled = false;
                    const checkedValue = segment.attributeValues.find(
                        (av: AttributeValue) =>
                            av.value?.replace(/ /g, '').toLowerCase() ===
                            selection?.value?.replace(/ /g, '').toLowerCase()
                    );
                    if (checkedValue) {
                        checkedValue.checked = true;
                        segment.checked = true;
                    }
                }
            });
        });
    }, [selection, segmentNames]);

    function updateSelection(segments: Attribute[]) {
        const isSelected = segments?.some((s: Attribute) => {
            const attributeValue = s.attributeValues.find(
                (av: AttributeValue) => av.checked
            );

            if (attributeValue) {
                update({
                    name: s.attributeName,
                    value: attributeValue.value
                });
                return true;
            }

            return false;
        });

        if (!isSelected) update();
    }

    function handleSegmentChange(segmentName: string, boxClicked?: boolean) {
        const newSegments = produce(segments, (segments: Attribute[]) => {
            const newSegment = segments?.find(
                (s: Attribute) =>
                    s.attributeName?.replace(/ /g, '').toLowerCase() ===
                    segmentName?.replace(/ /g, '').toLowerCase()
            );
            const activeSegment = segments?.find((s: Attribute) => s.active);
            if (activeSegment) {
                activeSegment.active = false;
            }
            if (boxClicked) {
                const checkedSegment = segments?.find(
                    (s: Attribute) =>
                        s.attributeName?.replace(/ /g, '').toLowerCase() ===
                        selection?.name?.replace(/ /g, '').toLowerCase()
                );
                if (
                    checkedSegment &&
                    checkedSegment.attributeName
                        ?.replace(/ /g, '')
                        .toLowerCase() !==
                        segmentName?.replace(/ /g, '').toLowerCase()
                ) {
                    checkedSegment.checked = false;
                    const attributeValue = checkedSegment.attributeValues.find(
                        (av: AttributeValue) => av.checked
                    );
                    if (attributeValue) {
                        attributeValue.checked = false;
                    }
                }
            }

            if (newSegment) {
                newSegment.active = true;
                if (boxClicked) {
                    newSegment.checked = !newSegment.checked;
                    if (newSegment.checked) {
                        newSegment.attributeValues[0].checked = true;
                    } else {
                        const attributeValue = newSegment.attributeValues.find(
                            (av: AttributeValue) => av.checked
                        );
                        if (attributeValue) {
                            attributeValue.checked = false;
                        }
                    }
                }
            }
        });
        setSegments(newSegments);
        if (boxClicked) {
            updateSelection(newSegments);
            setIsOpen(false);
        }
    }

    function handleValueChange(value: string) {
        const newSegments = produce(segments, (segments: Attribute[]) => {
            const segment = segments?.find((s: Attribute) => s.active);
            if (selection) {
                const prevSegment = segments?.find(
                    (s: Attribute) => s.attributeName === selection.name
                );
                const checkedValue = prevSegment?.attributeValues.find(
                    (av: AttributeValue) => av.checked
                );
                if (
                    checkedValue &&
                    (prevSegment !== segment || checkedValue?.value !== value)
                ) {
                    checkedValue.checked = false;
                }
            }
            if (segment) {
                const foundValue = segment.attributeValues.find(
                    (v: AttributeValue) => v.value === value
                );
                if (foundValue) {
                    foundValue.checked = !foundValue.checked;

                    if (selection) {
                        if (selection.value === foundValue.value) {
                            handleClearSelection();
                        }
                    }
                }
            }
        });
        track(MixPanel.Events.DashboardChangeIntervalRangeFilter, {
            'Dashboard Name': dashboardName,
            filter: `${activeSegment?.attributeName}: ${
                newSegments
                    ?.find((s: Attribute) => s.active)
                    ?.attributeValues.find((v: AttributeValue) => v.checked)
                    ?.text
            }`
        });
        setSegments(newSegments);
        updateSelection(newSegments);
    }

    function handleClearSelection() {
        const newSegments = produce(segments, (segments: Attribute[]) => {
            segments.forEach((s: Attribute) => {
                s.checked = false;
                s.attributeValues.forEach((av: AttributeValue) => {
                    av.checked = false;
                });
            });
        });
        setSegments(newSegments);
        updateSelection(newSegments);
    }

    return (
        <FilterDropdown
            label={label}
            placeholder={placeholder}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            allowCancel={true}
            onClearSelection={handleClearSelection}
        >
            <DashboardFiltersList
                filters={segments}
                onChange={handleSegmentChange}
            />
            {activeSegment && (
                <DashboardFilterValues
                    values={segmentValues}
                    onChange={handleValueChange}
                />
            )}
        </FilterDropdown>
    );
}

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Checkbox from '../../../common/Checkbox';
import Radio from '../../../common/RadioButton';
import DropdownArrow from '../../../icons/DropdownArrow';

export function isSegmentChecked(segment) {
    return segment.attributeValues.some(a => a.checked);
}

export function getSegmentCount(segment) {
    return segment.attributeValues.filter(av => av.checked).length;
}

export function getSegmentSelection(segment) {
    const selected = segment.attributeValues.find(av => av.checked);
    if (selected) {
        return selected.text;
    }
}

export default function SegmentList({ segments, onChange, useRadio }) {
    let listHeight = window.screen.availHeight * 0.75 - 160;
    const maxRowCount = Math.floor(listHeight / 28);
    listHeight = maxRowCount * 28;

    return (
        <div className="filter-dropdown__list">
            <ul style={{ maxHeight: listHeight }}>
                {segments.map(
                    segment =>
                        !segment.removed && (
                            <li
                                key={segment.attributeName}
                                onClick={() => {
                                    if (!segment.disabled) {
                                        onChange(segment.attributeName);
                                    }
                                }}
                                className={classNames({
                                    'l-active':
                                        isSegmentChecked(segment) ||
                                        segment.active,
                                    'l-disabled': segment.disabled
                                })}
                            >
                                {useRadio ? (
                                    <Radio
                                        disabled={segment.disabled}
                                        checked={segment.checked}
                                        onClick={() =>
                                            onChange(
                                                segment.attributeName,
                                                true
                                            )
                                        }
                                    />
                                ) : (
                                    <Checkbox
                                        checked={segment.checked}
                                        onClick={() =>
                                            onChange(
                                                segment.attributeName,
                                                true
                                            )
                                        }
                                        disabled={segment.disabled}
                                    />
                                )}
                                <span>{segment.attributeName}</span>
                                {isSegmentChecked(segment) && (
                                    <span style={{ marginLeft: 4 }}>
                                        ({getSegmentCount(segment)})
                                    </span>
                                )}
                                <span className="arrow">
                                    <DropdownArrow
                                        width={18}
                                        height={18}
                                        style={
                                            segment.active
                                                ? {
                                                      transform:
                                                          'rotate(-90deg)'
                                                  }
                                                : {}
                                        }
                                    />
                                </span>
                            </li>
                        )
                )}
            </ul>
        </div>
    );
}

SegmentList.propTypes = {
    segments: PropTypes.array,
    onChange: PropTypes.func,
    useRadio: PropTypes.bool
};

import { useState } from 'react';
import DatePicker from 'react-datepicker';
import ChevronLeft from '../../icons/ChevronLeft';
import Stack from '../Stack';
import './styles.scss';

const yearFormat = new Intl.DateTimeFormat('en-US', {
    year: 'numeric'
});

const monthFormat = new Intl.DateTimeFormat('en-US', {
    month: 'short'
});

function quarterToDate(quaterString: string) {
    if (!quaterString) return;
    const [q, year] = quaterString.split(' ');
    const quarterMonthMap: any = {
        Q1: 1,
        Q2: 4,
        Q3: 7,
        Q4: 10
    };

    if (!quarterMonthMap[q]) {
        throw new Error('Invalid quarter');
    }

    const month = quarterMonthMap[q];

    return new Date(`${year}-${month}-02`);
}

function getInitialDate(date: any, formatType: any) {
    if (!date) return null;
    switch (formatType) {
        case 'Quarter':
            return quarterToDate(date);
        case 'Year':
            // Adjust to the first day of the year to avoid timezone issues
            return new Date(`${date}-02-01`);
        default:
            return new Date(date);
    }
}

interface CalendarProps {
    startDate?: string;
    endDate?: string;
    formatType: 'Day' | 'Month' | 'Quarter' | 'Year';
    onApply: (props: any) => void;
    selectsRange?: boolean;
}

export default function Calendar({
    startDate,
    endDate,
    onApply,
    formatType,
    selectsRange = true
}: CalendarProps) {
    const [startDateInternal, setStartDateInternal] = useState(
        getInitialDate(startDate, formatType)
    );
    const [endDateInternal, setEndDateInternal] = useState(
        getInitialDate(endDate, formatType)
    );

    const yearItemNumber = 12;

    const handleCalendarClose = () => console.log('handleCalendarClose');
    const handleCalendarOpen = () => console.log('handleCalendarOpen');
    return (
        <DatePicker
            showIcon={false}
            onChange={(dates: any[] | any) => {
                // check if dates is an array or a single date
                if (Array.isArray(dates)) {
                    setStartDateInternal(dates[0]);
                    setEndDateInternal(dates[1]);
                    if (dates[0] && dates[1]) {
                        onApply([dates[0], dates[1]]);
                    }
                } else {
                    setStartDateInternal(dates);
                    setEndDateInternal(dates);
                    onApply(dates);
                }
            }}
            calendarContainer={CalendarContainer}
            open={true}
            renderCustomHeader={({
                date,
                customHeaderCount,
                decreaseMonth,
                increaseMonth,
                increaseYear,
                decreaseYear,
                ...rest
            }) => (
                <Header
                    date={date}
                    decreaseMonth={decreaseMonth}
                    increaseMonth={increaseMonth}
                    increaseYear={increaseYear}
                    decreaseYear={decreaseYear}
                    customHeaderCount={customHeaderCount}
                    formatType={formatType}
                    {...rest}
                />
            )}
            selected={startDateInternal}
            startDate={startDateInternal}
            endDate={endDateInternal}
            selectsRange={selectsRange}
            yearItemNumber={yearItemNumber}
            onCalendarOpen={handleCalendarOpen}
            onCalendarClose={handleCalendarClose}
            inline
            showMonthYearPicker={formatType === 'Month'}
            showQuarterYearPicker={formatType === 'Quarter'}
            showYearPicker={formatType === 'Year'}
            maxDate={new Date()}
        ></DatePicker>
    );
}

const Header = (props: any) => {
    const {
        date,
        decreaseMonth,
        increaseMonth,
        increaseYear,
        decreaseYear,
        formatType
    } = props;

    const yearViews = [
        {
            min: 1969,
            max: 1980
        },
        {
            min: 1981,
            max: 1992
        },
        {
            min: 1993,
            max: 2004
        },
        {
            min: 2005,
            max: 2016
        },
        {
            min: 2017,
            max: 2028
        }
    ];

    const canIncreaseYear =
        formatType === 'Year'
            ? date.getFullYear() + 1 <= 2017
            : date.getFullYear() + 1 <= new Date().getFullYear();
    const canDecreaseYear =
        formatType === 'Year'
            ? date.getFullYear() - 1 >= 1980
            : date.getFullYear() - 1 >= 1969;

    const formatLabel = (formatType: any, date: any) => {
        switch (formatType) {
            case 'Year':
                for (let index = yearViews.length - 1; index >= 0; index--) {
                    const yearRange = yearViews[index];
                    if (
                        date.getFullYear() >= yearRange.min &&
                        date.getFullYear() <= yearRange.max
                    ) {
                        return `${yearRange.min} - ${yearRange.max}`;
                    }
                }
                return 'Error';
            case 'Day':
                return monthFormat.format(date);
            default:
                return yearFormat.format(date);
        }
    };

    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            flexDirection="row"
            style={{
                position: 'relative',
                width: '100%',
                paddingBottom: '.4rem',
                paddingRight: '.4rem',
                borderBottom: '1px solid var(--color-ui-20)'
            }}
        >
            <button
                className="react-datepicker__navigation-icon"
                disabled={!canDecreaseYear}
                onClick={decreaseYear}
                style={{
                    left: 0
                }}
            >
                <ChevronLeft />
            </button>
            <span>{formatLabel(formatType, date)}</span>
            <button
                className="react-datepicker__navigation-icon"
                disabled={!canIncreaseYear}
                onClick={increaseYear}
                style={{
                    right: 0,
                    transform: 'rotate(180deg)',
                    transformOrigin: 'center'
                }}
            >
                <ChevronLeft />
            </button>
        </Stack>
    );
};

const CalendarContainer = ({ children }: any) => {
    return (
        <div
            className="react-datepicker"
            style={{
                background: 'var(--color-shade-h4)',
                borderRadius: '.8rem',
                overflow: 'hidden',
                padding: '1.6rem'
            }}
        >
            {children}
        </div>
    );
};

import { useHistory } from 'react-router';

import Button from '../../../../common/Button';
import Stack from '../../../../common/Stack';
import WithPermissions from '../../../../common/WithPermissions';
import ButtonTypes from '../../../../constants/ButtonTypes';
import RbacActions from '../../../../constants/RbacActions';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import HeaderWrapper from '../../../../common/HeaderWrapper';
import { createSnapshot, updateSnapshot } from '../actions';
import { nextStep, previousStep } from '../slice';

const Header = () => {
    const {
        isBeginning,
        isEnd,
        title,
        validated,
        mode,
        selectedIndex,
        steps,
        pending
    } = useAppSelector(state => state.snapshotSurveys.edit);
    const dispatch = useAppDispatch();
    const history = useHistory();

    return (
        <WithPermissions actions={[RbacActions['Campaign/Edit']]}>
            <HeaderWrapper>
                {title ? (
                    <h3>
                        {title}{' '}
                        <span style={{ color: 'var(--color-ui-50)' }}>
                            - {selectedIndex + 1}/4
                        </span>
                    </h3>
                ) : (
                    <span>Loading...</span>
                )}
                <Stack gap=".8rem" flexDirection="row" alignItems="center">
                    <Button
                        onClick={() => dispatch(previousStep())}
                        disabled={isBeginning}
                        componentType={ButtonTypes.type.SECONDARY}
                    >
                        Previous
                    </Button>
                    <Button
                        disabled={isEnd || !steps[selectedIndex].validated}
                        componentType={ButtonTypes.type.PRIMARY}
                        onClick={() => dispatch(nextStep())}
                    >
                        Next
                    </Button>
                    <Button
                        onClick={() => {
                            if (mode === 'edit') {
                                dispatch(updateSnapshot()).then(() => {
                                    history.push('/self-id/campaigns');
                                });
                            } else if (mode === 'create') {
                                dispatch(createSnapshot()).then(() => {
                                    history.push('/self-id/campaigns');
                                });
                            }
                        }}
                        disabled={!validated || pending}
                    >
                        {pending ? 'Saving...' : 'Save'}
                    </Button>
                </Stack>
            </HeaderWrapper>
        </WithPermissions>
    );
};

export default Header;

import { useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';

import Button from '../../../../common/Button';
import Dropdown from '../../../../common/DropdownV2';
import ButtonTypes from '../../../../constants/ButtonTypes';
import Close from '../../../../icons/Close';
import DropdownArrow from '../../../../icons/DropdownArrow';

interface DropdownProps extends React.HTMLAttributes<HTMLDivElement> {
    onClick: (option: any) => void;
    placeholder?: string;
    buttonLarge?: boolean;
    allowCancel?: boolean;
    disabled?: boolean;
    isOpen?: boolean;
    setIsOpen: (isOpen: boolean) => void;
    label?: string;
    children?: React.ReactNode;
    onClearSelection?: () => void;
    onMouseLeaveDropdown?: () => void;
    limitHeight?: number;
    alignDropdownRight?: boolean;
}

export default function AnalysisDropdown({
    onClick,
    placeholder,
    allowCancel,
    disabled,
    isOpen,
    setIsOpen,
    label,
    onClearSelection,
    buttonLarge,
    children,
    onMouseLeaveDropdown,
    limitHeight,
    alignDropdownRight,
    ...rest
}: DropdownProps) {
    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen) {
            window.addEventListener('keydown', handleKeyDown);
            window.addEventListener('mousedown', handleClickOutside);
            return () => {
                window.removeEventListener('keydown', handleKeyDown);
                window.removeEventListener('mousedown', handleClickOutside);
            };
        }
    }, [isOpen]);

    function handleKeyDown(e: any) {
        if (e.keyCode === 27) {
            setIsOpen(false);
        }
    }

    function handleClickOutside(e: any) {
        if (isOpen) {
            if (
                dropdownRef.current &&
                !dropdownRef.current.contains(e.target)
            ) {
                e.stopPropagation();
                setIsOpen(false);
            }
        }
    }

    return (
        <div className="filter-dropdown" {...rest}>
            <Dropdown
                onClick={onClick}
                isOpen={isOpen}
                disabled={disabled}
                limitHeight={limitHeight && '36vh'}
                buttonLarge={true}
                label={label}
                placeholder={placeholder}
                alignDropdownRight={alignDropdownRight}
                onMouseLeaveDropdown={onMouseLeaveDropdown}
                onClearSelection={onClearSelection}
            >
                {children}
            </Dropdown>
        </div>
    );
}

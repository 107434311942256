import produce from 'immer';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';

import stripSpaces from '../../lib/stripSpaces';
import { Attribute, Segment, SegmentFilter } from '../../types';
import FilterDropdown from './FilterDropdown';
import SegmentList, {
    getSegmentCount,
    getSegmentSelection,
    isSegmentChecked
} from './SegmentList';
import SegmentValues from './SegmentValues';

interface SegmentDropdownProps {
    attributes: Attribute[];
    dynamicPermissionsFilter: SegmentFilter[];
    availableSegmentNames: {
        name: string;
        disabled?: boolean;
    }[];
    placeholder: string;
    update: (attributes: Segment[]) => void;
    selection: Segment[];
    disabled: boolean;
    useRadioOnSegment?: boolean;
    useRadioOnValue?: boolean;
    isSecondary?: boolean;
    shouldOpen?: boolean;
    setShouldOpen?: any;
}

export function getDynamicPermissionsFilterFlattened(
    dynamicPermissionsFilter: SegmentFilter[]
) {
    // used as a way to check if the filter has changed
    const uniqueValues = new Set<string>();
    dynamicPermissionsFilter.forEach((filter: SegmentFilter) => {
        uniqueValues.add(filter.fieldName);
        filter.fieldValues.forEach(value => uniqueValues.add(value));
    });

    return Array.from(uniqueValues);
}

export default function SegmentDropdown({
    attributes,
    dynamicPermissionsFilter,
    availableSegmentNames,
    placeholder,
    update,
    selection,
    disabled,
    useRadioOnSegment,
    useRadioOnValue,
    isSecondary,
    shouldOpen,
    setShouldOpen
}: SegmentDropdownProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [segments, setSegments] = useState<any[]>([]);
    const prevSegmentsName = useRef<string[]>([]);
    const prevDynamicPermissionsFilterFlattened = useRef<string[]>([]);

    const [search, setSearch] = useState('');

    let segmentValues = [];
    const activeSegment = segments.find(s => s.active);
    if (activeSegment) {
        segmentValues = activeSegment.attributeValues;
    }

    const parts: string[] = [];
    segments.forEach(s => {
        if (isSegmentChecked(s)) {
            const count = getSegmentCount(s);
            const text = count > 1 ? count.toString() : getSegmentSelection(s);
            const part = `${s.attributeName} (${text})`;
            if (count > 0) {
                parts.push(part);
            }
        }
    });
    const label = parts.join(', ');

    useEffect(() => {
        if (
            isEqual(
                availableSegmentNames.map(s => s.name),
                prevSegmentsName.current
            ) &&
            isEqual(
                getDynamicPermissionsFilterFlattened(dynamicPermissionsFilter),
                prevDynamicPermissionsFilterFlattened.current
            )
        ) {
            return;
        }
        const fieldNames = dynamicPermissionsFilter.map(sn => sn.fieldName);
        const hasWildcard = fieldNames.includes('*');

        setSegments(
            cloneDeep(attributes)
                .filter((attr: Attribute) => {
                    const wildCard = hasWildcard ? availableSegmentNames : [];

                    return (
                        fieldNames.includes(stripSpaces(attr.attributeName)) ||
                        wildCard
                            .map(d => d.name)
                            .includes(stripSpaces(attr.attributeName))
                    );
                })
                .map((attr: Attribute) => ({
                    ...attr,
                    attributeValues: attr.attributeValues.filter(av => {
                        if (hasWildcard) return true;

                        const segmentName = dynamicPermissionsFilter.find(
                            sn =>
                                sn.fieldName === stripSpaces(attr.attributeName)
                        );

                        return (
                            segmentName &&
                            (segmentName.fieldValues.includes(av.value) ||
                                segmentName.fieldValues.includes('*'))
                        );
                    })
                }))
        );
        prevSegmentsName.current = availableSegmentNames.map(s => s.name);
        prevDynamicPermissionsFilterFlattened.current =
            getDynamicPermissionsFilterFlattened(dynamicPermissionsFilter);
    }, [attributes, dynamicPermissionsFilter, availableSegmentNames]);

    useEffect(() => {
        if (shouldOpen) {
            setIsOpen(true);
            // set it back to false immediately
            setShouldOpen(false);
        }
    }, [shouldOpen]);

    useEffect(() => {
        setSegments(segments => {
            return produce(segments, segments => {
                segments.forEach(s => {
                    s.checked = false;
                    s.attributeValues.forEach(
                        (av: Attribute['attributeValues'][0]) => {
                            av.checked = false;
                        }
                    );
                    if (availableSegmentNames) {
                        const segmentName = availableSegmentNames.find(
                            sn => sn.name === stripSpaces(s.attributeName)
                        );

                        s.removed = !segmentName;
                        s.disabled = segmentName && segmentName.disabled;
                        if (s.disabled || s.removed) {
                            s.active = false;
                        }
                    }
                });
                selection.forEach(sel => {
                    const segment = segments.find(
                        s => s.attributeName === sel.name
                    );
                    if (segment) {
                        segment.disabled = false;
                        sel.values.forEach(v => {
                            const value = segment.attributeValues.find(
                                (av: Attribute['attributeValues'][0]) =>
                                    av.value === v
                            );
                            if (value) {
                                value.checked = true;
                                segment.checked = true;
                            }
                        });
                    }
                });
                if (disabled) {
                    const segment = segments.find(s => s.active);
                    if (segment) {
                        segment.active = false;
                    }
                }
            });
        });
    }, [selection, availableSegmentNames]);

    function updateSelection(segments: Attribute[]) {
        const fields: any[] = [];
        segments.forEach(s => {
            const values = s.attributeValues
                .filter(av => av.checked)
                .map(av => av.value);
            if (values.length > 0) {
                fields.push({
                    name: s.attributeName,
                    values
                });
            }
        });
        update(fields);
    }

    function handleSegmentChange(segmentName: string, boxClicked?: boolean) {
        setSearch('');
        const newSegments = produce(segments, segments => {
            const activeSegment = segments.find(s => s.active);
            if (activeSegment) {
                activeSegment.active = false;
            }
            if (useRadioOnSegment && boxClicked) {
                const checkedSegment = segments.find(s => s.checked);
                if (
                    checkedSegment &&
                    checkedSegment.attributeName !== segmentName
                ) {
                    checkedSegment.checked = false;
                    if (useRadioOnValue) {
                        const attributeValue =
                            checkedSegment.attributeValues.find(
                                (av: Attribute['attributeValues'][0]) =>
                                    av.checked
                            );
                        if (attributeValue) {
                            attributeValue.checked = false;
                        }
                    } else {
                        checkedSegment.attributeValues.forEach(
                            (av: Attribute['attributeValues'][0]) => {
                                av.checked = false;
                            }
                        );
                    }
                }
            }
            const segment = segments.find(s => s.attributeName === segmentName);
            segment.active = true;
            if (boxClicked) {
                segment.checked = !segment.checked;
                if (useRadioOnValue) {
                    if (segment.checked) {
                        segment.attributeValues[0].checked = true;
                    } else {
                        const attributeValue = segment.attributeValues.find(
                            (av: Attribute['attributeValues'][0]) => av.checked
                        );
                        attributeValue.checked = false;
                    }
                } else {
                    segment.attributeValues.forEach(
                        (av: Attribute['attributeValues'][0]) => {
                            av.checked = segment.checked;
                        }
                    );
                }
            }
        });
        setSegments(newSegments);
        if (boxClicked) {
            updateSelection(newSegments);
        }
    }

    function handleValueChange(value: string) {
        const newSegments = produce(segments, segments => {
            const segment = segments.find(s => s.active);
            if (useRadioOnValue && selection.length === 1) {
                const prevSegment = segments.find(
                    s => s.attributeName === selection[0].name
                );
                const checkedValue = prevSegment.attributeValues.find(
                    (av: Attribute['attributeValues'][0]) => av.checked
                );
                if (prevSegment !== segment || checkedValue.value !== value) {
                    checkedValue.checked = false;
                }
            }
            if (segment) {
                const foundValue = segment.attributeValues.find(
                    (v: Attribute['attributeValues'][0]) => v.value === value
                );
                if (foundValue) {
                    foundValue.checked = !foundValue.checked;
                }
            }
        });
        setSegments(newSegments);
        updateSelection(newSegments);
    }

    function handleSelectAll(selectAll: boolean) {
        const newSegments = produce(segments, segments => {
            const segment = segments.find(s => s.active);
            if (segment) {
                let filteredValues: Attribute['attributeValues'] =
                    segment.attributeValues;
                if (search) {
                    filteredValues = segment.attributeValues.filter(
                        (av: Attribute['attributeValues'][0]) =>
                            av.text.toLowerCase().includes(search.toLowerCase())
                    );
                }
                filteredValues.forEach(v => {
                    v.checked = selectAll;
                });
            }
        });
        setSegments(newSegments);
        updateSelection(newSegments);
    }

    function handleClearSelection() {
        const newSegments = produce(segments, segments => {
            segments.forEach(s => {
                s.checked = false;
                s.attributeValues.forEach(
                    (av: Attribute['attributeValues'][0]) => {
                        av.checked = false;
                    }
                );
            });
        });
        setSegments(newSegments);
        updateSelection(newSegments);
    }

    return (
        <FilterDropdown
            label={label}
            placeholder={placeholder}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            disabled={disabled}
            isSecondary={isSecondary}
            allowCancel={true}
            onClearSelection={handleClearSelection}
        >
            <SegmentList
                useRadio={useRadioOnSegment}
                segments={segments}
                onChange={handleSegmentChange}
            />
            {activeSegment && (
                <SegmentValues
                    values={segmentValues}
                    onChange={handleValueChange}
                    useRadio={useRadioOnValue}
                    onSelectAll={handleSelectAll}
                    search={search}
                    onSearch={setSearch}
                />
            )}
        </FilterDropdown>
    );
}

SegmentDropdown.defaultProps = {
    selection: []
};

import React from 'react';
import { MdMore } from 'react-icons/md';
import Popover from '../../../common/Popover';
import More from '../../../icons/More';
import EventMoreItems from './EventMoreItems';
import WithPermissions from '../../../common/WithPermissions';
import RbacActions from '../../../constants/RbacActions';

interface EventMoreProps {
    open: boolean;
    setOpen: (open: boolean) => void;
    eventId: string;
}

const EventMore = ({ setOpen, open, eventId }: EventMoreProps) => {
    return (
        <WithPermissions actions={[RbacActions['Events/Edit']]}>
            <Popover
                placement="right"
                overlayClassName="popover--no-padding"
                destroyTooltipOnHide
                showArrow={false}
                visible={open}
                onVisibleChange={() => {
                    setOpen(!open);
                }}
                content={<EventMoreItems setOpen={setOpen} eventId={eventId} />}
            >
                <div
                    onClick={e => e.stopPropagation()}
                    data-test="event-more-menu"
                >
                    <More width={20} height={20} className="icon" />
                </div>
            </Popover>
        </WithPermissions>
    );
};

export default EventMore;

import moment from 'moment';
import getDateFromPeriod from './getDateFromPeriod';
import { getMomentIntervalKey } from './getMomentIntervalKey';

export default function getDates(
    latestDataUpdate: any,
    interval = 'Monthly',
    intervalCount = 4,
    startDate: any,
    endDate: any
) {
    if (startDate) {
        return getDatesByRange(interval, startDate, endDate);
    }
    return getDatesByIntervalCount(latestDataUpdate, interval, intervalCount);
}

function getDatesByRange(interval: string, startPeriod: any, endPeriod: any) {
    const dates = [];
    const startDate = getDateFromPeriod(startPeriod, interval);
    const endDate = getDateFromPeriod(endPeriod, interval);
    let currentDate = startDate;
    while (currentDate.isSameOrBefore(endDate)) {
        dates.push(currentDate);
        const intervalKey = getMomentIntervalKey(interval);
        currentDate = currentDate.clone().add(1, intervalKey);
    }
    return dates;
}

function getDatesByIntervalCount(
    latestDataUpdate: any,
    interval: string,
    intervalCount: number
) {
    const now = moment.utc(latestDataUpdate);
    let momentInterval: moment.unitOfTime.StartOf = 'month';
    switch (interval) {
        case 'Quarterly':
        case 'Quarter':
            momentInterval = 'quarter';
            break;
        case 'Yearly':
        case 'Year':
            momentInterval = 'year';
            break;
        default:
            momentInterval = 'month';
    }
    now.startOf(momentInterval);
    const dates = [];
    for (let i = intervalCount; i > 0; i--) {
        dates.push(now.clone().subtract(i - 1, momentInterval));
    }
    return dates;
}

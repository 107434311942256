import { SVGProps } from 'react';

const SvgComponent = (props: SVGProps<SVGSVGElement>) => (
    <svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
        <path
            fill="currentColor"
            d="M5.44883 4.00684H13.8142V13.1735L8.58987 18.3337L8.22133 17.9652C8.14536 17.8893 8.08147 17.7921 8.02966 17.6735C7.97786 17.5549 7.95196 17.4459 7.95196 17.3466V17.2152L8.80466 13.1735H3.013C2.66466 13.1735 2.35321 13.0362 2.07862 12.7616C1.80404 12.487 1.66675 12.1756 1.66675 11.8273V10.8016C1.66675 10.7258 1.67529 10.643 1.69237 10.5533C1.70946 10.4636 1.73189 10.3808 1.75966 10.305L4.03529 4.91059C4.1496 4.6542 4.34189 4.43948 4.61216 4.26642C4.88258 4.09336 5.16147 4.00684 5.44883 4.00684ZM12.9809 4.84017H5.44883C5.33133 4.84017 5.21112 4.87218 5.08821 4.93621C4.96529 5.00038 4.87182 5.10725 4.80779 5.25684L2.50008 10.6735V11.8273C2.50008 11.9768 2.54814 12.0997 2.64425 12.1958C2.7405 12.292 2.86341 12.3402 3.013 12.3402H9.83987L8.87508 16.8914L12.9809 12.8177V4.84017ZM13.8142 13.1735V12.3402H16.6667V4.84017H13.8142V4.00684H17.5001V13.1735H13.8142Z"
        />
    </svg>
);
export default SvgComponent;

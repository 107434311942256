import React from 'react';
import Button from '../../common/Button';
import ButtonTypes from '../../constants/ButtonTypes';
import EventName from './EventName';
import TimelineContent from './TimelineContent';
import { EVENT_HEIGHT, MONTH_WIDTH } from './TimelineContent/TimelineContent';
import { setEventToScrollTo, removeEventToScrollTo } from '../actions';
import orderBy from 'lodash/orderBy';
import { useAppSelector, useAppDispatch } from '../../store/hooks';

const TimelineChart = () => {
    const dispatch = useAppDispatch();
    const { events, sortCriteria, eventToScrollTo } = useAppSelector(
        state => state.timeline
    );
    const [hoveredIndex, setHoveredIndex] = React.useState<number | undefined>(
        undefined
    );
    const [showTodayToggle, setShowTodayToggle] =
        React.useState<boolean>(false);
    const containerRef = React.useRef<HTMLDivElement>(null);
    const goToPast = () => {
        if (!containerRef.current) return;
        containerRef.current.scrollBy(-MONTH_WIDTH, 0);
    };
    const goToFuture = () => {
        if (!containerRef.current) return;
        containerRef.current.scrollBy(MONTH_WIDTH, 0);
    };

    if (!events || events.length === 0) return null;

    const sortedEvents = orderBy(
        events,
        [sortCriteria?.column],
        [sortCriteria?.ascending ? 'asc' : 'desc']
    );

    return (
        <div className="timeline-chart" data-test="timeline-chart">
            <div className="timeline-chart__header">
                <Button
                    componentType={ButtonTypes.type.SECONDARY}
                    large={false}
                    onClick={() => setShowTodayToggle(!showTodayToggle)}
                >
                    Show today
                </Button>
            </div>
            <div
                className="timeline-chart__events"
                data-test="timeline-chart-events"
            >
                <div className="timeline-chart__event-names">
                    <div style={{ height: EVENT_HEIGHT }}></div>
                    {sortedEvents.map((event: any, index) => (
                        <EventName
                            key={event.eventId}
                            highlighted={
                                hoveredIndex === undefined
                                    ? true
                                    : hoveredIndex === index
                            }
                            name={event.name}
                            index={index}
                            id={event.eventId}
                            onMouseEnter={() => setHoveredIndex(index)}
                            onMouseLeave={() => setHoveredIndex(undefined)}
                            onClick={() =>
                                dispatch(setEventToScrollTo(event.eventId))
                            }
                        />
                    ))}
                </div>
                <div
                    ref={containerRef}
                    onScroll={() => {
                        if (eventToScrollTo) {
                            dispatch(removeEventToScrollTo());
                        }
                    }}
                    className="timeline-chart__event-stamps"
                >
                    <TimelineContent
                        goToPast={goToPast}
                        goToFuture={goToFuture}
                        hoveredIndex={hoveredIndex}
                        setHoveredIndex={setHoveredIndex}
                        showTodayToggle={showTodayToggle}
                    />
                </div>
            </div>
        </div>
    );
};

export default TimelineChart;

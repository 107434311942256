import React from 'react';
import cn from '../lib/cn';

export default function Avatar({ type, onClick, colorCode, size = 40 }: any) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={size}
            height={size}
            viewBox="0 0 24 24"
            fill="var(--color)"
            className={cn('avatar', `avatar--${type}`)}
            onClick={onClick}
            style={{
                '--color': `var(--color-graph-${colorCode})`
            }}
        >
            <circle cx={12} cy={12} r={12} />
            <path
                fill="#000"
                d="M12 11.77c-.722 0-1.34-.258-1.854-.772a2.528 2.528 0 0 1-.771-1.854c0-.722.257-1.34.771-1.854A2.528 2.528 0 0 1 12 6.52c.722 0 1.34.256 1.854.77s.771 1.132.771 1.854c0 .722-.257 1.34-.771 1.854A2.528 2.528 0 0 1 12 11.77Zm-5.625 5.71v-1.667c0-.367.1-.707.3-1.02.199-.313.465-.554.799-.722.741-.363 1.49-.636 2.244-.818a9.714 9.714 0 0 1 4.564 0c.755.182 1.503.455 2.244.818.334.168.6.41.8.722.2.313.299.653.299 1.02v1.668H6.375Z"
            />
        </svg>
    );
}

Avatar.defaultProps = {
    type: 'default',
    colorCode: 16
};

import { useContext } from 'react';
import { ChartContext } from '../../contexts/ChartContext';
import { LineChartContext } from './LineChart';

const LinearRegressionBackground = () => {
    const {
        chartState: { chartWidth, chartHeight }
    } = useContext(ChartContext);

    const { yScale } = useContext(LineChartContext);

    return (
        <rect
            className="linechartv2__linear-regression-background"
            x={0}
            y={yScale(0)}
            width={chartWidth}
            height={chartHeight - yScale(0)}
        />
    );
};

export default LinearRegressionBackground;

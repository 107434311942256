import React, { ChangeEvent, KeyboardEvent } from 'react';
import classNames from 'classnames';

interface TextAreaProps {
    value: string;
    onChange: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    onFocus?: () => void;
    onKeyDown?: (event: KeyboardEvent) => void;
    onBlur?: () => void;
    autoFocus?: boolean;
    placeholder?: string;
    danger?: boolean;
    readOnly?: boolean;
    componentType?: string;
    rows?: number;
}

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
    (
        {
            onChange,
            onFocus,
            onKeyDown,
            onBlur,
            autoFocus,
            value,
            placeholder,
            danger,
            readOnly,
            componentType,
            rows
        },
        ref
    ) => {
        return (
            <textarea
                className={classNames(
                    'text-area',
                    `text-area--${componentType}`,
                    {
                        'text-area--danger': danger
                    }
                )}
                rows={rows}
                onChange={onChange}
                value={value}
                placeholder={placeholder}
                autoFocus={autoFocus}
                onFocus={onFocus}
                onKeyDown={onKeyDown}
                onBlur={onBlur}
                readOnly={readOnly}
                ref={ref}
            />
        );
    }
);

TextArea.defaultProps = {
    componentType: 'default',
    onBlur: () => {},
    onFocus: () => {},
    onKeyDown: () => {},
    rows: 4
};

TextArea.displayName = 'TextArea';

export default TextArea;

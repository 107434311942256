import React from 'react';
import { CSSTransition } from 'react-transition-group';
import { useAppSelector } from '../../store/hooks';

interface EmptyStateTextProps {
    queryString: string;
}

function EmptyStateText({ queryString }: EmptyStateTextProps) {
    const isEmptyState = useAppSelector(
        state =>
            !state.explore.filter.analysisName &&
            state.explore.filter.mainSegments.length === 0
    );
    const show = !queryString && isEmptyState;

    return (
        <div className="explore__empty-state-text">
            <CSSTransition
                in={show}
                timeout={200}
                classNames="fade"
                mountOnEnter
                unmountOnExit
            >
                <span>Start by choosing an analysis.</span>
            </CSSTransition>
        </div>
    );
}

export default EmptyStateText;

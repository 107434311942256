import getValueWithUnit from '../lib/getValueWithUnit';
import isMasked from '../lib/isMasked';
import { units } from '../types';

export default function Masking({
    value,
    unit,
    hiddenDisplay
}: {
    value: number | string;
    unit?: units;
    hiddenDisplay: any;
}) {
    if (isMasked(value, unit)) {
        return hiddenDisplay;
    }

    if (!unit) {
        return <span>{value}</span>;
    }

    return <span>{getValueWithUnit(value, unit)}</span>;
}

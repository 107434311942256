import ActionTypes from '../../constants/ActionTypes';
import produce from 'immer';
import sortBy from 'lodash/sortBy';
import { Configuration } from './model';

interface ReducerState {
    pending?: boolean;
    error?: boolean;
    data?: Configuration[];
    loaded?: boolean;
}

export default function reducer(
    state: ReducerState = {},
    action: any
): ReducerState {
    switch (action.type) {
        case ActionTypes.GetConfigurationsPending: {
            return {
                ...state,
                pending: true,
                error: false
            };
        }
        case ActionTypes.GetConfigurationsFulfilled: {
            const data = sortBy(action.data, d => d.templateName);
            return {
                ...state,
                pending: false,
                data,
                loaded: true
            };
        }
        case ActionTypes.GetConfigurationsRejected: {
            return {
                ...state,
                pending: false,
                error: true
            };
        }
        case ActionTypes.SaveConfigurationFulfilled: {
            const { configuration } = action;
            return produce(state, draftState => {
                if (!draftState.data) {
                    draftState.data = [];
                }
                const index = draftState.data.findIndex(
                    d =>
                        d.templateConfigurationId ===
                        configuration.templateConfigurationId
                );
                if (index >= 0) {
                    draftState.data[index] = configuration;
                } else {
                    draftState.data.push(configuration);
                }
            });
        }
        default:
            return state;
    }
}

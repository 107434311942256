import React, { useState, useRef, useEffect } from 'react';
import { getNotifications, markNotificationAsRead } from './actions';
import NotificationsIcon from '../icons/Notifications';
import Popover from '../common/Popover';
import NotificationsPopover from './NotificationsPopover';
import MixPanel from '../constants/MixPanel';
import { track } from '../lib/segment';
import Button from '../common/Button';
import ButtonTypes from '../constants/ButtonTypes';
import { useAppSelector, useAppDispatch } from '../store/hooks';

function Notifications() {
    const dispatch = useAppDispatch();
    const { list, unread } = useAppSelector(state => state.notifications);
    const [visible, setVisible] = useState<boolean>();
    const comp = useRef<HTMLDivElement>(null);

    useEffect(() => {
        dispatch(getNotifications());
    }, []);

    function handleRead(notificationId: string) {
        setVisible(false);
        dispatch(markNotificationAsRead(notificationId));
    }

    function handleVisibleChange(visible: boolean) {
        if (visible) {
            track(MixPanel.Events.ViewNotifications);
        }
        setVisible(visible);
    }

    return (
        <div
            style={{ marginRight: 24, marginLeft: 14, lineHeight: 1 }}
            ref={comp}
        >
            <Popover
                placement="bottomRight"
                visible={visible}
                onVisibleChange={handleVisibleChange}
                overlayClassName="popover--no-padding"
                content={
                    <NotificationsPopover
                        notifications={list ?? []}
                        onRead={handleRead}
                    />
                }
                getPopupContainer={() => comp.current}
                closeOnScroll={false}
            >
                {unread ? (
                    <Button
                        componentType={ButtonTypes.type.SECONDARY}
                        large={false}
                    >
                        <NotificationsIcon
                            className="header-links__notification-icon"
                            width={24}
                            height={24}
                        />
                        <span style={{ marginLeft: 8 }}>
                            {unread} New Notification{unread > 1 && 's'}
                        </span>
                    </Button>
                ) : (
                    <NotificationsIcon
                        className="btn-icon"
                        width={24}
                        height={24}
                    />
                )}
            </Popover>
        </div>
    );
}

export default Notifications;
